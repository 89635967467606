export const PLACEHOLDER_EMPTY_AVATAR =
  '/assets/placeholders/inf-mobile-profile-pic-emty.jpg'

export const PLACEHOLDER_EMPTY_COVER =
  '/assets/placeholders/inf-mobile-profile-cover.jpg'

export const PLACEHOLDER_INFX_AVATAR = '/assets/Influexer-Icon.png'

export const VOTE_INITIAL_STEP = 'vote'

export const SUPPORTED_LANGUAGES = [
  {
    locale: 'en',
    flag: '🇺🇸',
    text: 'English',
  },
  {
    locale: 'es',
    flag: '🇪🇸',
    text: 'Español',
  },
]

export const VALID_SIGNUP_CONTEXT = [
  'order',
  'chat',
  'price-request',
  'profile-like',
]
