import { useAuth } from 'clients/auth.client'
import mapValues from 'lodash/fp/mapValues'
import { useRouter } from 'next/router'
import getFullName from 'utils/getFullName'
import logger, { Level, Ctx } from 'utils/logger'

const useLogger = () => {
  const { locale, pathname } = useRouter()
  const { authenticating, account, isAuthenticated } = useAuth()

  const log = (level: Level, message: string, ctx?: Ctx) => {
    logger.log(
      level,
      message,
      {
        ...mapValues((value) => {
          if (value instanceof Error) {
            return value.message
          } else {
            return value
          }
        }, ctx),
        locale,
        pathname,
        origin: 'front-end',
      },
      {
        userId: account?.id,
        email: account?.email,
        name: getFullName(account?.firstName, account?.lastName),
        country: account?.country,
        isSeller: account?.isSeller,
        type: account?.type,
        username: account?.username,
        role: account?.role,
        platformBrand: account?.platformBrand,
        isImpersonator: !!account?.impersonator,
        hasSubscription: !!account?.subscription,
        isAuthenticated,
        authenticating,
      },
    )
  }

  const logUnhandledCase = (message: string, ctx?: Record<string, any>) => {
    log('error', ['UNHANDLED CASE', message].join(' :: '), ctx)
  }

  return { log, logUnhandledCase }
}

export default useLogger
