import Loading from 'components/Loading'
import { createContext, useContext, useState } from 'react'

type Store = {
  show: boolean
  open: () => void
  close: () => void
}

const Context = createContext<Store>({
  show: false,
  open: () => undefined,
  close: () => undefined,
})

export const useLoadingScreen = () => useContext(Context)

export const LoadingScreenProvider = ({ children }: { children: any }) => {
  const [show, setShow] = useState(false)

  return (
    <Context.Provider
      value={{
        show,
        open: () => {
          setShow(true)
        },
        close: () => {
          setShow(false)
        },
      }}
    >
      {children}

      {show && (
        <div className="z-20 fixed inset-0 flex items-center justify-center bg-primary bg-opacity-50 fade-in">
          <Loading $size="lg" $type="logo" />
        </div>
      )}
    </Context.Provider>
  )
}
