import Modal, { BodySize, Size } from 'components/Modal'
import { createContext, ReactNode, useContext, useState, FC } from 'react'

export type Close = () => void

const Context = createContext<any>({
  isOpen: false,
  toggle: () => undefined,
  setIsOpen: () => undefined,
  open: () => undefined,
})

type Body = ReactNode | (() => ReactNode)

type Options = {
  $size?: Size
  $bodySize?: BodySize
}

export const useGlobalModal = (
  context = Context,
): {
  isOpen: boolean
  open: (body: Body, opts?: Options) => void
  close: Close
} => {
  const { isOpen, open, setIsOpen } = useContext(context)

  return {
    isOpen,
    open,
    close: () => {
      setIsOpen(false)
    },
  }
}

export const GlobalModalProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [body, setBody] = useState<ReactNode>('N/A')
  const [options, setOptions] = useState<Options>({})

  const toggle = () => {
    setIsOpen((state) => !state)
  }
  const open = (body: ReactNode, opts: Options = {}) => {
    setOptions(opts)
    setBody(body)
    setIsOpen(true)
  }

  return (
    <Context.Provider
      value={{
        isOpen,
        setIsOpen,
        toggle,
        open,
      }}
    >
      {children}
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        $size={options.$size}
        $bodySize={options.$bodySize}
      >
        {body}
      </Modal>
    </Context.Provider>
  )
}

export function withGlobalModal<T = unknown>(Component: FC<T>) {
  // eslint-disable-next-line react/display-name
  return (props: T) => (
    <GlobalModalProvider>
      <Component {...props} />
    </GlobalModalProvider>
  )
}
