import appendQuery from 'append-query'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useGlobalStore } from 'stores/global.store'
import appendQueryToCallbackUrl from 'utils/appendQueryToCallbackUrl'

type Props = {
  type: 'login' | 'signup'
  children: any
  queryParams?: appendQuery.Query
  options?: appendQuery.Options
}

const LinkAuth = ({ type, children, queryParams, options }: Props) => {
  const { asPath, query } = useRouter()
  const { platform } = useGlobalStore()

  return (
    <Link
      passHref
      href={appendQueryToCallbackUrl(
        `/auth/${type}`,
        {
          callbackUrl: asPath.replace(/(\?.*)|(#.*)/g, ''),
          brand: platform,
          ...query,
          ...queryParams,
        },
        options,
      )}
      legacyBehavior
    >
      {children}
    </Link>
  )
}

export default LinkAuth

export const LinkAuthLogin = (props: Omit<Props, 'type'>) => (
  <LinkAuth type="login" {...props} />
)

export const LinkAuthSignup = (props: Omit<Props, 'type'>) => (
  <LinkAuth type="signup" {...props} />
)
