import cc from 'classcat'
import Link from 'components/Link'
import IconArrow from 'assets/icons/arrow-payment.svg'
import Container from 'components/Container'
import { FC, useCallback } from 'react'
import withAside from 'hocs/withAside'
import { useAuth } from 'clients/auth.client'
import { useFeatureTesting } from 'stores/featureTesting.store'
import withRouterReady from 'hocs/withRouterReady'
import { useRouter } from 'next/router'

type AsidePageMenuType = {
  to: string
  text: string
}

type UseMenu = () => AsidePageMenuType[] | (() => AsidePageMenuType[])

const AsidePageMenu = ({ useMenu }: { useMenu: UseMenu }) => {
  const router = useRouter()
  const menu = useMenu()

  return (
    <Container topAndBottom>
      <ul className="h-full bg-white bg-opacity-50 overflow-hidden rounded-lg">
        {(typeof menu === 'function' ? menu() : menu).map(
          ({ to, text }, index) => (
            <li key={`settings-menu-${index}`}>
              {index > 0 && <hr className="border-gray-400" />}
              <Link
                to={to}
                active={router.asPath === to}
                className={cc([
                  'flex items-center justify-between px-6 py-4 text-lg hover:bg-white',
                  {
                    'bg-white border-primary border-l-2 shadow-lg':
                      router.asPath.includes(to),
                  },
                ])}
              >
                <span>{text}</span>
                <IconArrow className="w-2" />
              </Link>
            </li>
          ),
        )}
      </ul>
    </Container>
  )
}

export default withRouterReady(AsidePageMenu)

export const withAsidePageMenu = (useMenu: UseMenu, Component: FC) =>
  withAside(Component, () => <AsidePageMenu useMenu={useMenu} />)

export const useProfileMenu = (): (() => AsidePageMenuType[]) => {
  const { account } = useAuth()

  const links = useCallback(
    () =>
      [
        {
          to: '/profile/edit',
          text: 'Edit Profile',
        },
        account?.isSeller && {
          to: '/profile/demographics',
          text: 'Demographics',
        },
        {
          to: '/profile/links',
          text: 'Links',
        },
        account?.isSeller && {
          to: '/profile/packages',
          text: 'Packages',
        },
      ].filter(Boolean) as AsidePageMenuType[],
    [account?.isSeller],
  )

  return links
}

export const useSettingsMenu = (): (() => AsidePageMenuType[]) => {
  const { account } = useAuth()
  const proposalsFeatureTesting = useFeatureTesting('proposals')
  const adsFeatureTesting = useFeatureTesting('ads')
  const proFeatureTesting = useFeatureTesting('pro')

  const links = useCallback(
    () =>
      [
        proposalsFeatureTesting.enabled && {
          to: '/settings/manage-proposals',
          text: 'Manage Proposals',
        },
        {
          to: '/settings/legal-information',
          text: 'Identity Verification',
        },
        adsFeatureTesting.enabled && {
          to: '/settings/manage-ads',
          text: 'Manage Ads',
        },
        {
          to: '/settings/credentials',
          text: 'Credentials',
        },

        proFeatureTesting.enabled &&
          account?.subscription && {
            to: '/pro',
            text: 'Pro Subscription',
          },
      ].filter(Boolean) as AsidePageMenuType[],
    [
      adsFeatureTesting.enabled,
      proposalsFeatureTesting.enabled,
      proFeatureTesting.enabled,
      account?.subscription,
    ],
  )

  return links
}

export const useAdmin = () => {
  return [
    {
      to: '/admin/users',
      text: 'Users',
    },
  ]
}
