import cc from 'classcat'

type Size = 'sm' | 'md'

const ProBadge = ({
  $size,
  $inverted,
}: {
  $size: Size
  $inverted: boolean
}) => {
  return (
    <div
      data-testid="pro-badge"
      className={cc([
        'inline-block font-bold rounded-md',
        {
          'boujee-pro text-white': !$inverted,
          'boujee-pro boujee-pro-text': $inverted,
          'text-xs px-2 py-0.5': $size === 'sm',
          'text-sm px-2 py-1': $size === 'md',
        },
      ])}
    >
      PRO
    </div>
  )
}

ProBadge.defaultProps = {
  $size: 'md',
  $inverted: false,
}

export default ProBadge
