const combineComponents = (...components: any[]) =>
  components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      const Component = ({ children }: any) => (
        <AccumulatedComponents>
          <CurrentComponent>{children}</CurrentComponent>
        </AccumulatedComponents>
      )

      return Component
    },
    ({ children }: any) => <>{children}</>,
  )

export default combineComponents
