import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  json: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** columns and relationships of "Ambassador" */
export type Ambassador = {
  __typename?: 'Ambassador';
  /** An object relationship */
  WUI_User: Wui_User;
  created_at: Scalars['timestamptz'];
  custom_rate: Scalars['numeric'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['bigint'];
};

/** aggregated selection of "Ambassador" */
export type Ambassador_Aggregate = {
  __typename?: 'Ambassador_aggregate';
  aggregate?: Maybe<Ambassador_Aggregate_Fields>;
  nodes: Array<Ambassador>;
};

/** aggregate fields of "Ambassador" */
export type Ambassador_Aggregate_Fields = {
  __typename?: 'Ambassador_aggregate_fields';
  avg?: Maybe<Ambassador_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ambassador_Max_Fields>;
  min?: Maybe<Ambassador_Min_Fields>;
  stddev?: Maybe<Ambassador_Stddev_Fields>;
  stddev_pop?: Maybe<Ambassador_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ambassador_Stddev_Samp_Fields>;
  sum?: Maybe<Ambassador_Sum_Fields>;
  var_pop?: Maybe<Ambassador_Var_Pop_Fields>;
  var_samp?: Maybe<Ambassador_Var_Samp_Fields>;
  variance?: Maybe<Ambassador_Variance_Fields>;
};


/** aggregate fields of "Ambassador" */
export type Ambassador_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ambassador_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ambassador_Avg_Fields = {
  __typename?: 'Ambassador_avg_fields';
  custom_rate?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Ambassador". All fields are combined with a logical 'AND'. */
export type Ambassador_Bool_Exp = {
  WUI_User?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<Ambassador_Bool_Exp>>;
  _not?: InputMaybe<Ambassador_Bool_Exp>;
  _or?: InputMaybe<Array<Ambassador_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_rate?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "Ambassador" */
export enum Ambassador_Constraint {
  /** unique or primary key constraint on columns "id" */
  AmbassadorPkey = 'Ambassador_pkey',
  /** unique or primary key constraint on columns "user_id" */
  AmbassadorUserIdKey = 'Ambassador_user_id_key'
}

/** input type for incrementing numeric columns in table "Ambassador" */
export type Ambassador_Inc_Input = {
  custom_rate?: InputMaybe<Scalars['numeric']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Ambassador" */
export type Ambassador_Insert_Input = {
  WUI_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_rate?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Ambassador_Max_Fields = {
  __typename?: 'Ambassador_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_rate?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Ambassador_Min_Fields = {
  __typename?: 'Ambassador_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_rate?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "Ambassador" */
export type Ambassador_Mutation_Response = {
  __typename?: 'Ambassador_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ambassador>;
};

/** input type for inserting object relation for remote table "Ambassador" */
export type Ambassador_Obj_Rel_Insert_Input = {
  data: Ambassador_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ambassador_On_Conflict>;
};

/** on_conflict condition type for table "Ambassador" */
export type Ambassador_On_Conflict = {
  constraint: Ambassador_Constraint;
  update_columns?: Array<Ambassador_Update_Column>;
  where?: InputMaybe<Ambassador_Bool_Exp>;
};

/** Ordering options when selecting data from "Ambassador". */
export type Ambassador_Order_By = {
  WUI_User?: InputMaybe<Wui_User_Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Ambassador */
export type Ambassador_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Ambassador" */
export enum Ambassador_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRate = 'custom_rate',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "Ambassador" */
export type Ambassador_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_rate?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Ambassador_Stddev_Fields = {
  __typename?: 'Ambassador_stddev_fields';
  custom_rate?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ambassador_Stddev_Pop_Fields = {
  __typename?: 'Ambassador_stddev_pop_fields';
  custom_rate?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ambassador_Stddev_Samp_Fields = {
  __typename?: 'Ambassador_stddev_samp_fields';
  custom_rate?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "Ambassador" */
export type Ambassador_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ambassador_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ambassador_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_rate?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Ambassador_Sum_Fields = {
  __typename?: 'Ambassador_sum_fields';
  custom_rate?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Ambassador" */
export enum Ambassador_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRate = 'custom_rate',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Ambassador_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ambassador_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ambassador_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ambassador_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ambassador_Var_Pop_Fields = {
  __typename?: 'Ambassador_var_pop_fields';
  custom_rate?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ambassador_Var_Samp_Fields = {
  __typename?: 'Ambassador_var_samp_fields';
  custom_rate?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ambassador_Variance_Fields = {
  __typename?: 'Ambassador_variance_fields';
  custom_rate?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

export type BankAccountOutput = {
  __typename?: 'BankAccountOutput';
  account: Scalars['String'];
  account_holder_name: Scalars['String'];
  account_holder_type: Scalars['String'];
  bank_name: Scalars['String'];
  country: Scalars['String'];
  currency: Scalars['String'];
  fingerprint: Scalars['String'];
  id: Scalars['String'];
  last4: Scalars['String'];
  metadata: Scalars['json'];
  object: Scalars['String'];
  routing_number: Scalars['String'];
  status: Scalars['String'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CompanyAddress = {
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  primary: Scalars['String'];
  secondary?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type CountrySpecsOutput = {
  __typename?: 'CountrySpecsOutput';
  company: Scalars['jsonb'];
  individual: Scalars['jsonb'];
};

export type CountrySupportedOutput = {
  __typename?: 'CountrySupportedOutput';
  data: Scalars['jsonb'];
};

export type CreateCompanyInfoOutput = {
  __typename?: 'CreateCompanyInfoOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateIndividualAccountOutput = {
  __typename?: 'CreateIndividualAccountOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type DefaultBankAccountOutput = {
  __typename?: 'DefaultBankAccountOutput';
  id: Scalars['String'];
};

/** columns and relationships of "Demographic" */
export type Demographic = {
  __typename?: 'Demographic';
  value: Scalars['String'];
};

/** aggregated selection of "Demographic" */
export type Demographic_Aggregate = {
  __typename?: 'Demographic_aggregate';
  aggregate?: Maybe<Demographic_Aggregate_Fields>;
  nodes: Array<Demographic>;
};

/** aggregate fields of "Demographic" */
export type Demographic_Aggregate_Fields = {
  __typename?: 'Demographic_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Demographic_Max_Fields>;
  min?: Maybe<Demographic_Min_Fields>;
};


/** aggregate fields of "Demographic" */
export type Demographic_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Demographic_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "Demographic". All fields are combined with a logical 'AND'. */
export type Demographic_Bool_Exp = {
  _and?: InputMaybe<Array<Demographic_Bool_Exp>>;
  _not?: InputMaybe<Demographic_Bool_Exp>;
  _or?: InputMaybe<Array<Demographic_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Demographic" */
export enum Demographic_Constraint {
  /** unique or primary key constraint on columns "value" */
  SellerSocialMediaItemPkey = 'SellerSocialMediaItem_pkey'
}

export enum Demographic_Enum {
  AudienceAges = 'audience_ages',
  AudienceGender = 'audience_gender',
  AudienceSize = 'audience_size',
  AverageEngagement = 'average_engagement',
  AverageLikesPerPost = 'average_likes_per_post',
  InfluencerCategory = 'influencer_category',
  Location = 'location'
}

/** Boolean expression to compare columns of type "Demographic_enum". All fields are combined with logical 'AND'. */
export type Demographic_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Demographic_Enum>;
  _in?: InputMaybe<Array<Demographic_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Demographic_Enum>;
  _nin?: InputMaybe<Array<Demographic_Enum>>;
};

/** input type for inserting data into table "Demographic" */
export type Demographic_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Demographic_Max_Fields = {
  __typename?: 'Demographic_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Demographic_Min_Fields = {
  __typename?: 'Demographic_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Demographic" */
export type Demographic_Mutation_Response = {
  __typename?: 'Demographic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Demographic>;
};

/** input type for inserting object relation for remote table "Demographic" */
export type Demographic_Obj_Rel_Insert_Input = {
  data: Demographic_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Demographic_On_Conflict>;
};

/** on_conflict condition type for table "Demographic" */
export type Demographic_On_Conflict = {
  constraint: Demographic_Constraint;
  update_columns?: Array<Demographic_Update_Column>;
  where?: InputMaybe<Demographic_Bool_Exp>;
};

/** Ordering options when selecting data from "Demographic". */
export type Demographic_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Demographic */
export type Demographic_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "Demographic" */
export enum Demographic_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "Demographic" */
export type Demographic_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "Demographic" */
export type Demographic_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Demographic_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Demographic_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "Demographic" */
export enum Demographic_Update_Column {
  /** column name */
  Value = 'value'
}

export type Demographic_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Demographic_Set_Input>;
  /** filter the rows which have to be updated */
  where: Demographic_Bool_Exp;
};

/** columns and relationships of "EmailDomainLookup" */
export type EmailDomainLookup = {
  __typename?: 'EmailDomainLookup';
  banned: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  deliverable_email?: Maybe<Scalars['Boolean']>;
  disposable?: Maybe<Scalars['Boolean']>;
  domain: Scalars['String'];
  email_example: Scalars['String'];
  email_provider?: Maybe<Scalars['String']>;
  mx?: Maybe<Scalars['Boolean']>;
  seen: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "EmailDomainLookup" */
export type EmailDomainLookup_Aggregate = {
  __typename?: 'EmailDomainLookup_aggregate';
  aggregate?: Maybe<EmailDomainLookup_Aggregate_Fields>;
  nodes: Array<EmailDomainLookup>;
};

/** aggregate fields of "EmailDomainLookup" */
export type EmailDomainLookup_Aggregate_Fields = {
  __typename?: 'EmailDomainLookup_aggregate_fields';
  avg?: Maybe<EmailDomainLookup_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<EmailDomainLookup_Max_Fields>;
  min?: Maybe<EmailDomainLookup_Min_Fields>;
  stddev?: Maybe<EmailDomainLookup_Stddev_Fields>;
  stddev_pop?: Maybe<EmailDomainLookup_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<EmailDomainLookup_Stddev_Samp_Fields>;
  sum?: Maybe<EmailDomainLookup_Sum_Fields>;
  var_pop?: Maybe<EmailDomainLookup_Var_Pop_Fields>;
  var_samp?: Maybe<EmailDomainLookup_Var_Samp_Fields>;
  variance?: Maybe<EmailDomainLookup_Variance_Fields>;
};


/** aggregate fields of "EmailDomainLookup" */
export type EmailDomainLookup_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmailDomainLookup_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type EmailDomainLookup_Avg_Fields = {
  __typename?: 'EmailDomainLookup_avg_fields';
  seen?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "EmailDomainLookup". All fields are combined with a logical 'AND'. */
export type EmailDomainLookup_Bool_Exp = {
  _and?: InputMaybe<Array<EmailDomainLookup_Bool_Exp>>;
  _not?: InputMaybe<EmailDomainLookup_Bool_Exp>;
  _or?: InputMaybe<Array<EmailDomainLookup_Bool_Exp>>;
  banned?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deliverable_email?: InputMaybe<Boolean_Comparison_Exp>;
  disposable?: InputMaybe<Boolean_Comparison_Exp>;
  domain?: InputMaybe<String_Comparison_Exp>;
  email_example?: InputMaybe<String_Comparison_Exp>;
  email_provider?: InputMaybe<String_Comparison_Exp>;
  mx?: InputMaybe<Boolean_Comparison_Exp>;
  seen?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "EmailDomainLookup" */
export enum EmailDomainLookup_Constraint {
  /** unique or primary key constraint on columns "domain" */
  EmailDomainLookupDomainKey = 'EmailDomainLookup_domain_key',
  /** unique or primary key constraint on columns "domain" */
  EmailDomainLookupPkey = 'EmailDomainLookup_pkey'
}

/** input type for incrementing numeric columns in table "EmailDomainLookup" */
export type EmailDomainLookup_Inc_Input = {
  seen?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "EmailDomainLookup" */
export type EmailDomainLookup_Insert_Input = {
  banned?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deliverable_email?: InputMaybe<Scalars['Boolean']>;
  disposable?: InputMaybe<Scalars['Boolean']>;
  domain?: InputMaybe<Scalars['String']>;
  email_example?: InputMaybe<Scalars['String']>;
  email_provider?: InputMaybe<Scalars['String']>;
  mx?: InputMaybe<Scalars['Boolean']>;
  seen?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EmailDomainLookup_Max_Fields = {
  __typename?: 'EmailDomainLookup_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  email_example?: Maybe<Scalars['String']>;
  email_provider?: Maybe<Scalars['String']>;
  seen?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type EmailDomainLookup_Min_Fields = {
  __typename?: 'EmailDomainLookup_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  email_example?: Maybe<Scalars['String']>;
  email_provider?: Maybe<Scalars['String']>;
  seen?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "EmailDomainLookup" */
export type EmailDomainLookup_Mutation_Response = {
  __typename?: 'EmailDomainLookup_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmailDomainLookup>;
};

/** on_conflict condition type for table "EmailDomainLookup" */
export type EmailDomainLookup_On_Conflict = {
  constraint: EmailDomainLookup_Constraint;
  update_columns?: Array<EmailDomainLookup_Update_Column>;
  where?: InputMaybe<EmailDomainLookup_Bool_Exp>;
};

/** Ordering options when selecting data from "EmailDomainLookup". */
export type EmailDomainLookup_Order_By = {
  banned?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deliverable_email?: InputMaybe<Order_By>;
  disposable?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  email_example?: InputMaybe<Order_By>;
  email_provider?: InputMaybe<Order_By>;
  mx?: InputMaybe<Order_By>;
  seen?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: EmailDomainLookup */
export type EmailDomainLookup_Pk_Columns_Input = {
  domain: Scalars['String'];
};

/** select columns of table "EmailDomainLookup" */
export enum EmailDomainLookup_Select_Column {
  /** column name */
  Banned = 'banned',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeliverableEmail = 'deliverable_email',
  /** column name */
  Disposable = 'disposable',
  /** column name */
  Domain = 'domain',
  /** column name */
  EmailExample = 'email_example',
  /** column name */
  EmailProvider = 'email_provider',
  /** column name */
  Mx = 'mx',
  /** column name */
  Seen = 'seen',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "EmailDomainLookup" */
export type EmailDomainLookup_Set_Input = {
  banned?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deliverable_email?: InputMaybe<Scalars['Boolean']>;
  disposable?: InputMaybe<Scalars['Boolean']>;
  domain?: InputMaybe<Scalars['String']>;
  email_example?: InputMaybe<Scalars['String']>;
  email_provider?: InputMaybe<Scalars['String']>;
  mx?: InputMaybe<Scalars['Boolean']>;
  seen?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EmailDomainLookup_Stddev_Fields = {
  __typename?: 'EmailDomainLookup_stddev_fields';
  seen?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type EmailDomainLookup_Stddev_Pop_Fields = {
  __typename?: 'EmailDomainLookup_stddev_pop_fields';
  seen?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type EmailDomainLookup_Stddev_Samp_Fields = {
  __typename?: 'EmailDomainLookup_stddev_samp_fields';
  seen?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "EmailDomainLookup" */
export type EmailDomainLookup_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmailDomainLookup_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmailDomainLookup_Stream_Cursor_Value_Input = {
  banned?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deliverable_email?: InputMaybe<Scalars['Boolean']>;
  disposable?: InputMaybe<Scalars['Boolean']>;
  domain?: InputMaybe<Scalars['String']>;
  email_example?: InputMaybe<Scalars['String']>;
  email_provider?: InputMaybe<Scalars['String']>;
  mx?: InputMaybe<Scalars['Boolean']>;
  seen?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type EmailDomainLookup_Sum_Fields = {
  __typename?: 'EmailDomainLookup_sum_fields';
  seen?: Maybe<Scalars['Int']>;
};

/** update columns of table "EmailDomainLookup" */
export enum EmailDomainLookup_Update_Column {
  /** column name */
  Banned = 'banned',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeliverableEmail = 'deliverable_email',
  /** column name */
  Disposable = 'disposable',
  /** column name */
  Domain = 'domain',
  /** column name */
  EmailExample = 'email_example',
  /** column name */
  EmailProvider = 'email_provider',
  /** column name */
  Mx = 'mx',
  /** column name */
  Seen = 'seen',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EmailDomainLookup_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EmailDomainLookup_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmailDomainLookup_Set_Input>;
  /** filter the rows which have to be updated */
  where: EmailDomainLookup_Bool_Exp;
};

/** aggregate var_pop on columns */
export type EmailDomainLookup_Var_Pop_Fields = {
  __typename?: 'EmailDomainLookup_var_pop_fields';
  seen?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type EmailDomainLookup_Var_Samp_Fields = {
  __typename?: 'EmailDomainLookup_var_samp_fields';
  seen?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type EmailDomainLookup_Variance_Fields = {
  __typename?: 'EmailDomainLookup_variance_fields';
  seen?: Maybe<Scalars['Float']>;
};

export type FileOutput = {
  __typename?: 'FileOutput';
  fileId: Scalars['bigint'];
  fileType: Scalars['String'];
  imageUrl: Scalars['String'];
};

/** columns and relationships of "HeroBanner" */
export type HeroBanner = {
  __typename?: 'HeroBanner';
  /** An object relationship */
  WUI_File_Lg?: Maybe<Wui_File>;
  /** An object relationship */
  WUI_File_Md?: Maybe<Wui_File>;
  /** An object relationship */
  WUI_File_Sm?: Maybe<Wui_File>;
  active?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  imageLg?: Maybe<Scalars['bigint']>;
  imageMd?: Maybe<Scalars['bigint']>;
  imageSm?: Maybe<Scalars['bigint']>;
  order: Scalars['Int'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "HeroBanner" */
export type HeroBanner_Aggregate = {
  __typename?: 'HeroBanner_aggregate';
  aggregate?: Maybe<HeroBanner_Aggregate_Fields>;
  nodes: Array<HeroBanner>;
};

/** aggregate fields of "HeroBanner" */
export type HeroBanner_Aggregate_Fields = {
  __typename?: 'HeroBanner_aggregate_fields';
  avg?: Maybe<HeroBanner_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<HeroBanner_Max_Fields>;
  min?: Maybe<HeroBanner_Min_Fields>;
  stddev?: Maybe<HeroBanner_Stddev_Fields>;
  stddev_pop?: Maybe<HeroBanner_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<HeroBanner_Stddev_Samp_Fields>;
  sum?: Maybe<HeroBanner_Sum_Fields>;
  var_pop?: Maybe<HeroBanner_Var_Pop_Fields>;
  var_samp?: Maybe<HeroBanner_Var_Samp_Fields>;
  variance?: Maybe<HeroBanner_Variance_Fields>;
};


/** aggregate fields of "HeroBanner" */
export type HeroBanner_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<HeroBanner_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type HeroBanner_Avg_Fields = {
  __typename?: 'HeroBanner_avg_fields';
  id?: Maybe<Scalars['Float']>;
  imageLg?: Maybe<Scalars['Float']>;
  imageMd?: Maybe<Scalars['Float']>;
  imageSm?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "HeroBanner". All fields are combined with a logical 'AND'. */
export type HeroBanner_Bool_Exp = {
  WUI_File_Lg?: InputMaybe<Wui_File_Bool_Exp>;
  WUI_File_Md?: InputMaybe<Wui_File_Bool_Exp>;
  WUI_File_Sm?: InputMaybe<Wui_File_Bool_Exp>;
  _and?: InputMaybe<Array<HeroBanner_Bool_Exp>>;
  _not?: InputMaybe<HeroBanner_Bool_Exp>;
  _or?: InputMaybe<Array<HeroBanner_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  imageLg?: InputMaybe<Bigint_Comparison_Exp>;
  imageMd?: InputMaybe<Bigint_Comparison_Exp>;
  imageSm?: InputMaybe<Bigint_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "HeroBanner" */
export enum HeroBanner_Constraint {
  /** unique or primary key constraint on columns "id" */
  HeroBannerPkey = 'HeroBanner_pkey'
}

/** input type for incrementing numeric columns in table "HeroBanner" */
export type HeroBanner_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  imageLg?: InputMaybe<Scalars['bigint']>;
  imageMd?: InputMaybe<Scalars['bigint']>;
  imageSm?: InputMaybe<Scalars['bigint']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "HeroBanner" */
export type HeroBanner_Insert_Input = {
  WUI_File_Lg?: InputMaybe<Wui_File_Obj_Rel_Insert_Input>;
  WUI_File_Md?: InputMaybe<Wui_File_Obj_Rel_Insert_Input>;
  WUI_File_Sm?: InputMaybe<Wui_File_Obj_Rel_Insert_Input>;
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  imageLg?: InputMaybe<Scalars['bigint']>;
  imageMd?: InputMaybe<Scalars['bigint']>;
  imageSm?: InputMaybe<Scalars['bigint']>;
  order?: InputMaybe<Scalars['Int']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type HeroBanner_Max_Fields = {
  __typename?: 'HeroBanner_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  imageLg?: Maybe<Scalars['bigint']>;
  imageMd?: Maybe<Scalars['bigint']>;
  imageSm?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type HeroBanner_Min_Fields = {
  __typename?: 'HeroBanner_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  imageLg?: Maybe<Scalars['bigint']>;
  imageMd?: Maybe<Scalars['bigint']>;
  imageSm?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "HeroBanner" */
export type HeroBanner_Mutation_Response = {
  __typename?: 'HeroBanner_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HeroBanner>;
};

/** on_conflict condition type for table "HeroBanner" */
export type HeroBanner_On_Conflict = {
  constraint: HeroBanner_Constraint;
  update_columns?: Array<HeroBanner_Update_Column>;
  where?: InputMaybe<HeroBanner_Bool_Exp>;
};

/** Ordering options when selecting data from "HeroBanner". */
export type HeroBanner_Order_By = {
  WUI_File_Lg?: InputMaybe<Wui_File_Order_By>;
  WUI_File_Md?: InputMaybe<Wui_File_Order_By>;
  WUI_File_Sm?: InputMaybe<Wui_File_Order_By>;
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageLg?: InputMaybe<Order_By>;
  imageMd?: InputMaybe<Order_By>;
  imageSm?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: HeroBanner */
export type HeroBanner_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "HeroBanner" */
export enum HeroBanner_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageLg = 'imageLg',
  /** column name */
  ImageMd = 'imageMd',
  /** column name */
  ImageSm = 'imageSm',
  /** column name */
  Order = 'order',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "HeroBanner" */
export type HeroBanner_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  imageLg?: InputMaybe<Scalars['bigint']>;
  imageMd?: InputMaybe<Scalars['bigint']>;
  imageSm?: InputMaybe<Scalars['bigint']>;
  order?: InputMaybe<Scalars['Int']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type HeroBanner_Stddev_Fields = {
  __typename?: 'HeroBanner_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  imageLg?: Maybe<Scalars['Float']>;
  imageMd?: Maybe<Scalars['Float']>;
  imageSm?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type HeroBanner_Stddev_Pop_Fields = {
  __typename?: 'HeroBanner_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  imageLg?: Maybe<Scalars['Float']>;
  imageMd?: Maybe<Scalars['Float']>;
  imageSm?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type HeroBanner_Stddev_Samp_Fields = {
  __typename?: 'HeroBanner_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  imageLg?: Maybe<Scalars['Float']>;
  imageMd?: Maybe<Scalars['Float']>;
  imageSm?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "HeroBanner" */
export type HeroBanner_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: HeroBanner_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type HeroBanner_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  imageLg?: InputMaybe<Scalars['bigint']>;
  imageMd?: InputMaybe<Scalars['bigint']>;
  imageSm?: InputMaybe<Scalars['bigint']>;
  order?: InputMaybe<Scalars['Int']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type HeroBanner_Sum_Fields = {
  __typename?: 'HeroBanner_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  imageLg?: Maybe<Scalars['bigint']>;
  imageMd?: Maybe<Scalars['bigint']>;
  imageSm?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "HeroBanner" */
export enum HeroBanner_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageLg = 'imageLg',
  /** column name */
  ImageMd = 'imageMd',
  /** column name */
  ImageSm = 'imageSm',
  /** column name */
  Order = 'order',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type HeroBanner_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<HeroBanner_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<HeroBanner_Set_Input>;
  /** filter the rows which have to be updated */
  where: HeroBanner_Bool_Exp;
};

/** aggregate var_pop on columns */
export type HeroBanner_Var_Pop_Fields = {
  __typename?: 'HeroBanner_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  imageLg?: Maybe<Scalars['Float']>;
  imageMd?: Maybe<Scalars['Float']>;
  imageSm?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type HeroBanner_Var_Samp_Fields = {
  __typename?: 'HeroBanner_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  imageLg?: Maybe<Scalars['Float']>;
  imageMd?: Maybe<Scalars['Float']>;
  imageSm?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type HeroBanner_Variance_Fields = {
  __typename?: 'HeroBanner_variance_fields';
  id?: Maybe<Scalars['Float']>;
  imageLg?: Maybe<Scalars['Float']>;
  imageMd?: Maybe<Scalars['Float']>;
  imageSm?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export type IndividualAddress = {
  city: Scalars['String'];
  country: Scalars['String'];
  primary: Scalars['String'];
  secondary?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

/** columns and relationships of "Industry" */
export type Industry = {
  __typename?: 'Industry';
  value: Scalars['String'];
};

/** aggregated selection of "Industry" */
export type Industry_Aggregate = {
  __typename?: 'Industry_aggregate';
  aggregate?: Maybe<Industry_Aggregate_Fields>;
  nodes: Array<Industry>;
};

/** aggregate fields of "Industry" */
export type Industry_Aggregate_Fields = {
  __typename?: 'Industry_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Industry_Max_Fields>;
  min?: Maybe<Industry_Min_Fields>;
};


/** aggregate fields of "Industry" */
export type Industry_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Industry_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "Industry". All fields are combined with a logical 'AND'. */
export type Industry_Bool_Exp = {
  _and?: InputMaybe<Array<Industry_Bool_Exp>>;
  _not?: InputMaybe<Industry_Bool_Exp>;
  _or?: InputMaybe<Array<Industry_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Industry" */
export enum Industry_Constraint {
  /** unique or primary key constraint on columns "value" */
  IndustryPkey = 'Industry_pkey'
}

export enum Industry_Enum {
  Animals = 'animals',
  Art = 'art',
  Beauty = 'beauty',
  Blogger = 'blogger',
  Business = 'business',
  Crafts = 'crafts',
  Entertainment = 'entertainment',
  Family = 'family',
  Fashion = 'fashion',
  Fitness = 'fitness',
  Food = 'food',
  Gaming = 'gaming',
  Health = 'health',
  Lifestyle = 'lifestyle',
  Memes = 'memes',
  Motivational = 'motivational',
  Other = 'other',
  Parenting = 'parenting',
  Photography = 'photography',
  Travel = 'travel'
}

/** Boolean expression to compare columns of type "Industry_enum". All fields are combined with logical 'AND'. */
export type Industry_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Industry_Enum>;
  _in?: InputMaybe<Array<Industry_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Industry_Enum>;
  _nin?: InputMaybe<Array<Industry_Enum>>;
};

/** input type for inserting data into table "Industry" */
export type Industry_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Industry_Max_Fields = {
  __typename?: 'Industry_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Industry_Min_Fields = {
  __typename?: 'Industry_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Industry" */
export type Industry_Mutation_Response = {
  __typename?: 'Industry_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Industry>;
};

/** on_conflict condition type for table "Industry" */
export type Industry_On_Conflict = {
  constraint: Industry_Constraint;
  update_columns?: Array<Industry_Update_Column>;
  where?: InputMaybe<Industry_Bool_Exp>;
};

/** Ordering options when selecting data from "Industry". */
export type Industry_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Industry */
export type Industry_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "Industry" */
export enum Industry_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "Industry" */
export type Industry_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "Industry" */
export type Industry_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Industry_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Industry_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "Industry" */
export enum Industry_Update_Column {
  /** column name */
  Value = 'value'
}

export type Industry_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Industry_Set_Input>;
  /** filter the rows which have to be updated */
  where: Industry_Bool_Exp;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type LegalInformationOutput = {
  __typename?: 'LegalInformationOutput';
  address?: Maybe<Scalars['jsonb']>;
  data?: Maybe<Scalars['jsonb']>;
  type: Scalars['String'];
};

export type LoginAdminOutput = {
  __typename?: 'LoginAdminOutput';
  country?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['bigint'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  accepted_terms: Scalars['Boolean'];
  country?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  hasCompletedLegalInformation: Scalars['Boolean'];
  id: Scalars['bigint'];
  isSeller: Scalars['Boolean'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

/** columns and relationships of "MarketplaceWaitlist" */
export type MarketplaceWaitlist = {
  __typename?: 'MarketplaceWaitlist';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['bigint'];
};

/** aggregated selection of "MarketplaceWaitlist" */
export type MarketplaceWaitlist_Aggregate = {
  __typename?: 'MarketplaceWaitlist_aggregate';
  aggregate?: Maybe<MarketplaceWaitlist_Aggregate_Fields>;
  nodes: Array<MarketplaceWaitlist>;
};

/** aggregate fields of "MarketplaceWaitlist" */
export type MarketplaceWaitlist_Aggregate_Fields = {
  __typename?: 'MarketplaceWaitlist_aggregate_fields';
  avg?: Maybe<MarketplaceWaitlist_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<MarketplaceWaitlist_Max_Fields>;
  min?: Maybe<MarketplaceWaitlist_Min_Fields>;
  stddev?: Maybe<MarketplaceWaitlist_Stddev_Fields>;
  stddev_pop?: Maybe<MarketplaceWaitlist_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MarketplaceWaitlist_Stddev_Samp_Fields>;
  sum?: Maybe<MarketplaceWaitlist_Sum_Fields>;
  var_pop?: Maybe<MarketplaceWaitlist_Var_Pop_Fields>;
  var_samp?: Maybe<MarketplaceWaitlist_Var_Samp_Fields>;
  variance?: Maybe<MarketplaceWaitlist_Variance_Fields>;
};


/** aggregate fields of "MarketplaceWaitlist" */
export type MarketplaceWaitlist_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MarketplaceWaitlist_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MarketplaceWaitlist_Avg_Fields = {
  __typename?: 'MarketplaceWaitlist_avg_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "MarketplaceWaitlist". All fields are combined with a logical 'AND'. */
export type MarketplaceWaitlist_Bool_Exp = {
  _and?: InputMaybe<Array<MarketplaceWaitlist_Bool_Exp>>;
  _not?: InputMaybe<MarketplaceWaitlist_Bool_Exp>;
  _or?: InputMaybe<Array<MarketplaceWaitlist_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "MarketplaceWaitlist" */
export enum MarketplaceWaitlist_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  MarketplaceWaitlistPkey = 'MarketplaceWaitlist_pkey'
}

/** input type for incrementing numeric columns in table "MarketplaceWaitlist" */
export type MarketplaceWaitlist_Inc_Input = {
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "MarketplaceWaitlist" */
export type MarketplaceWaitlist_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type MarketplaceWaitlist_Max_Fields = {
  __typename?: 'MarketplaceWaitlist_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type MarketplaceWaitlist_Min_Fields = {
  __typename?: 'MarketplaceWaitlist_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "MarketplaceWaitlist" */
export type MarketplaceWaitlist_Mutation_Response = {
  __typename?: 'MarketplaceWaitlist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MarketplaceWaitlist>;
};

/** on_conflict condition type for table "MarketplaceWaitlist" */
export type MarketplaceWaitlist_On_Conflict = {
  constraint: MarketplaceWaitlist_Constraint;
  update_columns?: Array<MarketplaceWaitlist_Update_Column>;
  where?: InputMaybe<MarketplaceWaitlist_Bool_Exp>;
};

/** Ordering options when selecting data from "MarketplaceWaitlist". */
export type MarketplaceWaitlist_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: MarketplaceWaitlist */
export type MarketplaceWaitlist_Pk_Columns_Input = {
  user_id: Scalars['bigint'];
};

/** select columns of table "MarketplaceWaitlist" */
export enum MarketplaceWaitlist_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "MarketplaceWaitlist" */
export type MarketplaceWaitlist_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type MarketplaceWaitlist_Stddev_Fields = {
  __typename?: 'MarketplaceWaitlist_stddev_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MarketplaceWaitlist_Stddev_Pop_Fields = {
  __typename?: 'MarketplaceWaitlist_stddev_pop_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MarketplaceWaitlist_Stddev_Samp_Fields = {
  __typename?: 'MarketplaceWaitlist_stddev_samp_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "MarketplaceWaitlist" */
export type MarketplaceWaitlist_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MarketplaceWaitlist_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MarketplaceWaitlist_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type MarketplaceWaitlist_Sum_Fields = {
  __typename?: 'MarketplaceWaitlist_sum_fields';
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "MarketplaceWaitlist" */
export enum MarketplaceWaitlist_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type MarketplaceWaitlist_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MarketplaceWaitlist_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MarketplaceWaitlist_Set_Input>;
  /** filter the rows which have to be updated */
  where: MarketplaceWaitlist_Bool_Exp;
};

/** aggregate var_pop on columns */
export type MarketplaceWaitlist_Var_Pop_Fields = {
  __typename?: 'MarketplaceWaitlist_var_pop_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MarketplaceWaitlist_Var_Samp_Fields = {
  __typename?: 'MarketplaceWaitlist_var_samp_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MarketplaceWaitlist_Variance_Fields = {
  __typename?: 'MarketplaceWaitlist_variance_fields';
  user_id?: Maybe<Scalars['Float']>;
};

export type MissingAccountItemsOutput = {
  __typename?: 'MissingAccountItemsOutput';
  missing: Array<Maybe<Scalars['String']>>;
};

export type OrderDetailsOutput = {
  __typename?: 'OrderDetailsOutput';
  id: Scalars['bigint'];
};

export type OrderOutput = {
  __typename?: 'OrderOutput';
  buyer_id: Scalars['bigint'];
  created_by: Scalars['bigint'];
  description: Scalars['String'];
  id: Scalars['bigint'];
  items: Scalars['jsonb'];
  metadata: Scalars['jsonb'];
  package_type: Scalars['String'];
  price: Scalars['Int'];
  seller_id: Scalars['bigint'];
  status: Scalars['String'];
  subscription_id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type PaymentIntentOutput = {
  __typename?: 'PaymentIntentOutput';
  clientSecret: Scalars['String'];
  intentID: Scalars['String'];
};

export type PaymentMethodCardOutput = {
  __typename?: 'PaymentMethodCardOutput';
  allow_3d_secure: Scalars['Boolean'];
  brand: Scalars['String'];
  country_code: Scalars['String'];
  exp_month: Scalars['Int'];
  exp_year: Scalars['Int'];
  external_id: Scalars['String'];
  fingerprint: Scalars['String'];
  funding: Scalars['String'];
  id: Scalars['bigint'];
  last4: Scalars['String'];
};

/** columns and relationships of "PlatformAlert" */
export type PlatformAlert = {
  __typename?: 'PlatformAlert';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  message: Scalars['String'];
  message_es: Scalars['String'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "PlatformAlert" */
export type PlatformAlert_Aggregate = {
  __typename?: 'PlatformAlert_aggregate';
  aggregate?: Maybe<PlatformAlert_Aggregate_Fields>;
  nodes: Array<PlatformAlert>;
};

/** aggregate fields of "PlatformAlert" */
export type PlatformAlert_Aggregate_Fields = {
  __typename?: 'PlatformAlert_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PlatformAlert_Max_Fields>;
  min?: Maybe<PlatformAlert_Min_Fields>;
};


/** aggregate fields of "PlatformAlert" */
export type PlatformAlert_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PlatformAlert_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "PlatformAlert". All fields are combined with a logical 'AND'. */
export type PlatformAlert_Bool_Exp = {
  _and?: InputMaybe<Array<PlatformAlert_Bool_Exp>>;
  _not?: InputMaybe<PlatformAlert_Bool_Exp>;
  _or?: InputMaybe<Array<PlatformAlert_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  message_es?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "PlatformAlert" */
export enum PlatformAlert_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlatformAlertPkey = 'PlatformAlert_pkey'
}

/** input type for inserting data into table "PlatformAlert" */
export type PlatformAlert_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  message_es?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type PlatformAlert_Max_Fields = {
  __typename?: 'PlatformAlert_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  message_es?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type PlatformAlert_Min_Fields = {
  __typename?: 'PlatformAlert_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  message_es?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "PlatformAlert" */
export type PlatformAlert_Mutation_Response = {
  __typename?: 'PlatformAlert_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PlatformAlert>;
};

/** on_conflict condition type for table "PlatformAlert" */
export type PlatformAlert_On_Conflict = {
  constraint: PlatformAlert_Constraint;
  update_columns?: Array<PlatformAlert_Update_Column>;
  where?: InputMaybe<PlatformAlert_Bool_Exp>;
};

/** Ordering options when selecting data from "PlatformAlert". */
export type PlatformAlert_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  message_es?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PlatformAlert */
export type PlatformAlert_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "PlatformAlert" */
export enum PlatformAlert_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  MessageEs = 'message_es',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "PlatformAlert" */
export type PlatformAlert_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  message_es?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "PlatformAlert" */
export type PlatformAlert_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PlatformAlert_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PlatformAlert_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  message_es?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "PlatformAlert" */
export enum PlatformAlert_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  MessageEs = 'message_es',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type PlatformAlert_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PlatformAlert_Set_Input>;
  /** filter the rows which have to be updated */
  where: PlatformAlert_Bool_Exp;
};

export type PortfolioFileOutput = {
  __typename?: 'PortfolioFileOutput';
  fileId: Scalars['bigint'];
  imageUrl: Scalars['String'];
  portfolioId: Scalars['bigint'];
};

/** columns and relationships of "ProjectDemographic" */
export type ProjectDemographic = {
  __typename?: 'ProjectDemographic';
  /** An object relationship */
  Demographic: Demographic;
  /** An object relationship */
  WUI_SellerProject: Wui_SellerProject;
  /** An object relationship */
  WUI_SocialMediaSource: Wui_SocialMediaSource;
  demographic: Demographic_Enum;
  id: Scalars['bigint'];
  project_id: Scalars['bigint'];
  source: Wui_SocialMediaSource_Enum;
  value: Scalars['String'];
};

/** aggregated selection of "ProjectDemographic" */
export type ProjectDemographic_Aggregate = {
  __typename?: 'ProjectDemographic_aggregate';
  aggregate?: Maybe<ProjectDemographic_Aggregate_Fields>;
  nodes: Array<ProjectDemographic>;
};

export type ProjectDemographic_Aggregate_Bool_Exp = {
  count?: InputMaybe<ProjectDemographic_Aggregate_Bool_Exp_Count>;
};

export type ProjectDemographic_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ProjectDemographic_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProjectDemographic_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ProjectDemographic" */
export type ProjectDemographic_Aggregate_Fields = {
  __typename?: 'ProjectDemographic_aggregate_fields';
  avg?: Maybe<ProjectDemographic_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectDemographic_Max_Fields>;
  min?: Maybe<ProjectDemographic_Min_Fields>;
  stddev?: Maybe<ProjectDemographic_Stddev_Fields>;
  stddev_pop?: Maybe<ProjectDemographic_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProjectDemographic_Stddev_Samp_Fields>;
  sum?: Maybe<ProjectDemographic_Sum_Fields>;
  var_pop?: Maybe<ProjectDemographic_Var_Pop_Fields>;
  var_samp?: Maybe<ProjectDemographic_Var_Samp_Fields>;
  variance?: Maybe<ProjectDemographic_Variance_Fields>;
};


/** aggregate fields of "ProjectDemographic" */
export type ProjectDemographic_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ProjectDemographic_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ProjectDemographic" */
export type ProjectDemographic_Aggregate_Order_By = {
  avg?: InputMaybe<ProjectDemographic_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ProjectDemographic_Max_Order_By>;
  min?: InputMaybe<ProjectDemographic_Min_Order_By>;
  stddev?: InputMaybe<ProjectDemographic_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ProjectDemographic_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ProjectDemographic_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ProjectDemographic_Sum_Order_By>;
  var_pop?: InputMaybe<ProjectDemographic_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ProjectDemographic_Var_Samp_Order_By>;
  variance?: InputMaybe<ProjectDemographic_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ProjectDemographic" */
export type ProjectDemographic_Arr_Rel_Insert_Input = {
  data: Array<ProjectDemographic_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ProjectDemographic_On_Conflict>;
};

/** aggregate avg on columns */
export type ProjectDemographic_Avg_Fields = {
  __typename?: 'ProjectDemographic_avg_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ProjectDemographic". All fields are combined with a logical 'AND'. */
export type ProjectDemographic_Bool_Exp = {
  Demographic?: InputMaybe<Demographic_Bool_Exp>;
  WUI_SellerProject?: InputMaybe<Wui_SellerProject_Bool_Exp>;
  WUI_SocialMediaSource?: InputMaybe<Wui_SocialMediaSource_Bool_Exp>;
  _and?: InputMaybe<Array<ProjectDemographic_Bool_Exp>>;
  _not?: InputMaybe<ProjectDemographic_Bool_Exp>;
  _or?: InputMaybe<Array<ProjectDemographic_Bool_Exp>>;
  demographic?: InputMaybe<Demographic_Enum_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  project_id?: InputMaybe<Bigint_Comparison_Exp>;
  source?: InputMaybe<Wui_SocialMediaSource_Enum_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ProjectDemographic" */
export enum ProjectDemographic_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectDemographicPkey = 'ProjectDemographic_pkey',
  /** unique or primary key constraint on columns "project_id", "source", "demographic" */
  ProjectDemographicProjectIdDemographicSourceKey = 'ProjectDemographic_project_id_demographic_source_key'
}

/** input type for incrementing numeric columns in table "ProjectDemographic" */
export type ProjectDemographic_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "ProjectDemographic" */
export type ProjectDemographic_Insert_Input = {
  Demographic?: InputMaybe<Demographic_Obj_Rel_Insert_Input>;
  WUI_SellerProject?: InputMaybe<Wui_SellerProject_Obj_Rel_Insert_Input>;
  WUI_SocialMediaSource?: InputMaybe<Wui_SocialMediaSource_Obj_Rel_Insert_Input>;
  demographic?: InputMaybe<Demographic_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['bigint']>;
  source?: InputMaybe<Wui_SocialMediaSource_Enum>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ProjectDemographic_Max_Fields = {
  __typename?: 'ProjectDemographic_max_fields';
  id?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ProjectDemographic_Min_Fields = {
  __typename?: 'ProjectDemographic_min_fields';
  id?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ProjectDemographic" */
export type ProjectDemographic_Mutation_Response = {
  __typename?: 'ProjectDemographic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectDemographic>;
};

/** on_conflict condition type for table "ProjectDemographic" */
export type ProjectDemographic_On_Conflict = {
  constraint: ProjectDemographic_Constraint;
  update_columns?: Array<ProjectDemographic_Update_Column>;
  where?: InputMaybe<ProjectDemographic_Bool_Exp>;
};

/** Ordering options when selecting data from "ProjectDemographic". */
export type ProjectDemographic_Order_By = {
  Demographic?: InputMaybe<Demographic_Order_By>;
  WUI_SellerProject?: InputMaybe<Wui_SellerProject_Order_By>;
  WUI_SocialMediaSource?: InputMaybe<Wui_SocialMediaSource_Order_By>;
  demographic?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ProjectDemographic */
export type ProjectDemographic_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "ProjectDemographic" */
export enum ProjectDemographic_Select_Column {
  /** column name */
  Demographic = 'demographic',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Source = 'source',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ProjectDemographic" */
export type ProjectDemographic_Set_Input = {
  demographic?: InputMaybe<Demographic_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['bigint']>;
  source?: InputMaybe<Wui_SocialMediaSource_Enum>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProjectDemographic_Stddev_Fields = {
  __typename?: 'ProjectDemographic_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ProjectDemographic_Stddev_Pop_Fields = {
  __typename?: 'ProjectDemographic_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ProjectDemographic_Stddev_Samp_Fields = {
  __typename?: 'ProjectDemographic_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ProjectDemographic" */
export type ProjectDemographic_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProjectDemographic_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProjectDemographic_Stream_Cursor_Value_Input = {
  demographic?: InputMaybe<Demographic_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['bigint']>;
  source?: InputMaybe<Wui_SocialMediaSource_Enum>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ProjectDemographic_Sum_Fields = {
  __typename?: 'ProjectDemographic_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** update columns of table "ProjectDemographic" */
export enum ProjectDemographic_Update_Column {
  /** column name */
  Demographic = 'demographic',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Source = 'source',
  /** column name */
  Value = 'value'
}

export type ProjectDemographic_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProjectDemographic_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProjectDemographic_Set_Input>;
  /** filter the rows which have to be updated */
  where: ProjectDemographic_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ProjectDemographic_Var_Pop_Fields = {
  __typename?: 'ProjectDemographic_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ProjectDemographic_Var_Samp_Fields = {
  __typename?: 'ProjectDemographic_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ProjectDemographic_Variance_Fields = {
  __typename?: 'ProjectDemographic_variance_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

export type ProjectFileOutput = {
  __typename?: 'ProjectFileOutput';
  fileId: Scalars['bigint'];
  imageUrl: Scalars['String'];
  projectId: Scalars['bigint'];
};

export type RemoveBankAccountOutput = {
  __typename?: 'RemoveBankAccountOutput';
  id: Scalars['String'];
};

export type RemovePaymentMethodCardOutput = {
  __typename?: 'RemovePaymentMethodCardOutput';
  id: Scalars['bigint'];
};

export type ResetVotesOutput = {
  __typename?: 'ResetVotesOutput';
  success: Scalars['Boolean'];
};

export type SearchInfluencersFilters = {
  tbd?: InputMaybe<Scalars['Boolean']>;
};

export type SearchInfluencersOutput = {
  __typename?: 'SearchInfluencersOutput';
  first_name: Scalars['String'];
  from_price: Scalars['Int'];
  highlights: Array<Maybe<Scalars['jsonb']>>;
  is_seller: Scalars['Boolean'];
  last_name: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  review_avg: Scalars['Float'];
  social_media: Array<Maybe<Scalars['String']>>;
  user_id: Scalars['bigint'];
  username: Scalars['String'];
};

export type SignupOutput = {
  __typename?: 'SignupOutput';
  accepted_terms: Scalars['Boolean'];
  country?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  hasCompletedLegalInformation: Scalars['Boolean'];
  id: Scalars['bigint'];
  isSeller: Scalars['Boolean'];
  language?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  username: Scalars['String'];
};

/** columns and relationships of "SignupSource" */
export type SignupSource = {
  __typename?: 'SignupSource';
  value: Scalars['String'];
};

/** columns and relationships of "SignupSourceTracker" */
export type SignupSourceTracker = {
  __typename?: 'SignupSourceTracker';
  created_at: Scalars['timestamptz'];
  from_user_id: Scalars['bigint'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['bigint'];
};

/** aggregated selection of "SignupSourceTracker" */
export type SignupSourceTracker_Aggregate = {
  __typename?: 'SignupSourceTracker_aggregate';
  aggregate?: Maybe<SignupSourceTracker_Aggregate_Fields>;
  nodes: Array<SignupSourceTracker>;
};

/** aggregate fields of "SignupSourceTracker" */
export type SignupSourceTracker_Aggregate_Fields = {
  __typename?: 'SignupSourceTracker_aggregate_fields';
  avg?: Maybe<SignupSourceTracker_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SignupSourceTracker_Max_Fields>;
  min?: Maybe<SignupSourceTracker_Min_Fields>;
  stddev?: Maybe<SignupSourceTracker_Stddev_Fields>;
  stddev_pop?: Maybe<SignupSourceTracker_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SignupSourceTracker_Stddev_Samp_Fields>;
  sum?: Maybe<SignupSourceTracker_Sum_Fields>;
  var_pop?: Maybe<SignupSourceTracker_Var_Pop_Fields>;
  var_samp?: Maybe<SignupSourceTracker_Var_Samp_Fields>;
  variance?: Maybe<SignupSourceTracker_Variance_Fields>;
};


/** aggregate fields of "SignupSourceTracker" */
export type SignupSourceTracker_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SignupSourceTracker_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SignupSourceTracker_Avg_Fields = {
  __typename?: 'SignupSourceTracker_avg_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SignupSourceTracker". All fields are combined with a logical 'AND'. */
export type SignupSourceTracker_Bool_Exp = {
  _and?: InputMaybe<Array<SignupSourceTracker_Bool_Exp>>;
  _not?: InputMaybe<SignupSourceTracker_Bool_Exp>;
  _or?: InputMaybe<Array<SignupSourceTracker_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  from_user_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "SignupSourceTracker" */
export enum SignupSourceTracker_Constraint {
  /** unique or primary key constraint on columns "id" */
  SignupSourceTrackerPkey = 'SignupSourceTracker_pkey'
}

/** input type for incrementing numeric columns in table "SignupSourceTracker" */
export type SignupSourceTracker_Inc_Input = {
  from_user_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "SignupSourceTracker" */
export type SignupSourceTracker_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_user_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type SignupSourceTracker_Max_Fields = {
  __typename?: 'SignupSourceTracker_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  from_user_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type SignupSourceTracker_Min_Fields = {
  __typename?: 'SignupSourceTracker_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  from_user_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "SignupSourceTracker" */
export type SignupSourceTracker_Mutation_Response = {
  __typename?: 'SignupSourceTracker_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SignupSourceTracker>;
};

/** on_conflict condition type for table "SignupSourceTracker" */
export type SignupSourceTracker_On_Conflict = {
  constraint: SignupSourceTracker_Constraint;
  update_columns?: Array<SignupSourceTracker_Update_Column>;
  where?: InputMaybe<SignupSourceTracker_Bool_Exp>;
};

/** Ordering options when selecting data from "SignupSourceTracker". */
export type SignupSourceTracker_Order_By = {
  created_at?: InputMaybe<Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: SignupSourceTracker */
export type SignupSourceTracker_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "SignupSourceTracker" */
export enum SignupSourceTracker_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromUserId = 'from_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "SignupSourceTracker" */
export type SignupSourceTracker_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_user_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type SignupSourceTracker_Stddev_Fields = {
  __typename?: 'SignupSourceTracker_stddev_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SignupSourceTracker_Stddev_Pop_Fields = {
  __typename?: 'SignupSourceTracker_stddev_pop_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SignupSourceTracker_Stddev_Samp_Fields = {
  __typename?: 'SignupSourceTracker_stddev_samp_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "SignupSourceTracker" */
export type SignupSourceTracker_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SignupSourceTracker_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SignupSourceTracker_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_user_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type SignupSourceTracker_Sum_Fields = {
  __typename?: 'SignupSourceTracker_sum_fields';
  from_user_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "SignupSourceTracker" */
export enum SignupSourceTracker_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromUserId = 'from_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type SignupSourceTracker_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SignupSourceTracker_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SignupSourceTracker_Set_Input>;
  /** filter the rows which have to be updated */
  where: SignupSourceTracker_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SignupSourceTracker_Var_Pop_Fields = {
  __typename?: 'SignupSourceTracker_var_pop_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SignupSourceTracker_Var_Samp_Fields = {
  __typename?: 'SignupSourceTracker_var_samp_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SignupSourceTracker_Variance_Fields = {
  __typename?: 'SignupSourceTracker_variance_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "SignupSource" */
export type SignupSource_Aggregate = {
  __typename?: 'SignupSource_aggregate';
  aggregate?: Maybe<SignupSource_Aggregate_Fields>;
  nodes: Array<SignupSource>;
};

/** aggregate fields of "SignupSource" */
export type SignupSource_Aggregate_Fields = {
  __typename?: 'SignupSource_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SignupSource_Max_Fields>;
  min?: Maybe<SignupSource_Min_Fields>;
};


/** aggregate fields of "SignupSource" */
export type SignupSource_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SignupSource_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "SignupSource". All fields are combined with a logical 'AND'. */
export type SignupSource_Bool_Exp = {
  _and?: InputMaybe<Array<SignupSource_Bool_Exp>>;
  _not?: InputMaybe<SignupSource_Bool_Exp>;
  _or?: InputMaybe<Array<SignupSource_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "SignupSource" */
export enum SignupSource_Constraint {
  /** unique or primary key constraint on columns "value" */
  SignupSourcePkey = 'SignupSource_pkey'
}

export enum SignupSource_Enum {
  Admin = 'admin',
  Organic = 'organic',
  Profile = 'profile'
}

/** Boolean expression to compare columns of type "SignupSource_enum". All fields are combined with logical 'AND'. */
export type SignupSource_Enum_Comparison_Exp = {
  _eq?: InputMaybe<SignupSource_Enum>;
  _in?: InputMaybe<Array<SignupSource_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<SignupSource_Enum>;
  _nin?: InputMaybe<Array<SignupSource_Enum>>;
};

/** input type for inserting data into table "SignupSource" */
export type SignupSource_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SignupSource_Max_Fields = {
  __typename?: 'SignupSource_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SignupSource_Min_Fields = {
  __typename?: 'SignupSource_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "SignupSource" */
export type SignupSource_Mutation_Response = {
  __typename?: 'SignupSource_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SignupSource>;
};

/** on_conflict condition type for table "SignupSource" */
export type SignupSource_On_Conflict = {
  constraint: SignupSource_Constraint;
  update_columns?: Array<SignupSource_Update_Column>;
  where?: InputMaybe<SignupSource_Bool_Exp>;
};

/** Ordering options when selecting data from "SignupSource". */
export type SignupSource_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: SignupSource */
export type SignupSource_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "SignupSource" */
export enum SignupSource_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "SignupSource" */
export type SignupSource_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "SignupSource" */
export type SignupSource_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SignupSource_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SignupSource_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "SignupSource" */
export enum SignupSource_Update_Column {
  /** column name */
  Value = 'value'
}

export type SignupSource_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SignupSource_Set_Input>;
  /** filter the rows which have to be updated */
  where: SignupSource_Bool_Exp;
};

/** social media information */
export type SocialMedia = {
  __typename?: 'SocialMedia';
  /** An object relationship */
  User?: Maybe<Wui_User>;
  created_at: Scalars['timestamptz'];
  engagement: Scalars['numeric'];
  followers: Scalars['bigint'];
  following: Scalars['bigint'];
  handle: Scalars['String'];
  id: Scalars['uuid'];
  likes: Scalars['bigint'];
  platform: SocialMediaPlatform_Enum;
  posts: Scalars['bigint'];
  reason?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['bigint'];
  valid?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "SocialMediaDemographic" */
export type SocialMediaDemographic = {
  __typename?: 'SocialMediaDemographic';
  /** An object relationship */
  Demographic: Demographic;
  /** An object relationship */
  WUI_SocialMediaSource: Wui_SocialMediaSource;
  /** An object relationship */
  WUI_User: Wui_User;
  demographic: Demographic_Enum;
  id: Scalars['bigint'];
  max?: Maybe<Scalars['numeric']>;
  min?: Maybe<Scalars['numeric']>;
  source: Wui_SocialMediaSource_Enum;
  user_id: Scalars['bigint'];
  value: Scalars['String'];
};

/** aggregated selection of "SocialMediaDemographic" */
export type SocialMediaDemographic_Aggregate = {
  __typename?: 'SocialMediaDemographic_aggregate';
  aggregate?: Maybe<SocialMediaDemographic_Aggregate_Fields>;
  nodes: Array<SocialMediaDemographic>;
};

export type SocialMediaDemographic_Aggregate_Bool_Exp = {
  count?: InputMaybe<SocialMediaDemographic_Aggregate_Bool_Exp_Count>;
};

export type SocialMediaDemographic_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<SocialMediaDemographic_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SocialMediaDemographic_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "SocialMediaDemographic" */
export type SocialMediaDemographic_Aggregate_Fields = {
  __typename?: 'SocialMediaDemographic_aggregate_fields';
  avg?: Maybe<SocialMediaDemographic_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SocialMediaDemographic_Max_Fields>;
  min?: Maybe<SocialMediaDemographic_Min_Fields>;
  stddev?: Maybe<SocialMediaDemographic_Stddev_Fields>;
  stddev_pop?: Maybe<SocialMediaDemographic_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SocialMediaDemographic_Stddev_Samp_Fields>;
  sum?: Maybe<SocialMediaDemographic_Sum_Fields>;
  var_pop?: Maybe<SocialMediaDemographic_Var_Pop_Fields>;
  var_samp?: Maybe<SocialMediaDemographic_Var_Samp_Fields>;
  variance?: Maybe<SocialMediaDemographic_Variance_Fields>;
};


/** aggregate fields of "SocialMediaDemographic" */
export type SocialMediaDemographic_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SocialMediaDemographic_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Aggregate_Order_By = {
  avg?: InputMaybe<SocialMediaDemographic_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<SocialMediaDemographic_Max_Order_By>;
  min?: InputMaybe<SocialMediaDemographic_Min_Order_By>;
  stddev?: InputMaybe<SocialMediaDemographic_Stddev_Order_By>;
  stddev_pop?: InputMaybe<SocialMediaDemographic_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<SocialMediaDemographic_Stddev_Samp_Order_By>;
  sum?: InputMaybe<SocialMediaDemographic_Sum_Order_By>;
  var_pop?: InputMaybe<SocialMediaDemographic_Var_Pop_Order_By>;
  var_samp?: InputMaybe<SocialMediaDemographic_Var_Samp_Order_By>;
  variance?: InputMaybe<SocialMediaDemographic_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "SocialMediaDemographic" */
export type SocialMediaDemographic_Arr_Rel_Insert_Input = {
  data: Array<SocialMediaDemographic_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<SocialMediaDemographic_On_Conflict>;
};

/** aggregate avg on columns */
export type SocialMediaDemographic_Avg_Fields = {
  __typename?: 'SocialMediaDemographic_avg_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "SocialMediaDemographic". All fields are combined with a logical 'AND'. */
export type SocialMediaDemographic_Bool_Exp = {
  Demographic?: InputMaybe<Demographic_Bool_Exp>;
  WUI_SocialMediaSource?: InputMaybe<Wui_SocialMediaSource_Bool_Exp>;
  WUI_User?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<SocialMediaDemographic_Bool_Exp>>;
  _not?: InputMaybe<SocialMediaDemographic_Bool_Exp>;
  _or?: InputMaybe<Array<SocialMediaDemographic_Bool_Exp>>;
  demographic?: InputMaybe<Demographic_Enum_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  max?: InputMaybe<Numeric_Comparison_Exp>;
  min?: InputMaybe<Numeric_Comparison_Exp>;
  source?: InputMaybe<Wui_SocialMediaSource_Enum_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "SocialMediaDemographic" */
export enum SocialMediaDemographic_Constraint {
  /** unique or primary key constraint on columns "id" */
  SellerSocialMediaAudiencePkey = 'SellerSocialMediaAudience_pkey',
  /** unique or primary key constraint on columns "source", "user_id", "demographic" */
  SellerSocialMediaDataUserIdSourceSocialMediaItemKey = 'SellerSocialMediaData_user_id_source_social_media_item_key'
}

/** input type for incrementing numeric columns in table "SocialMediaDemographic" */
export type SocialMediaDemographic_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  max?: InputMaybe<Scalars['numeric']>;
  min?: InputMaybe<Scalars['numeric']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "SocialMediaDemographic" */
export type SocialMediaDemographic_Insert_Input = {
  Demographic?: InputMaybe<Demographic_Obj_Rel_Insert_Input>;
  WUI_SocialMediaSource?: InputMaybe<Wui_SocialMediaSource_Obj_Rel_Insert_Input>;
  WUI_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  demographic?: InputMaybe<Demographic_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  max?: InputMaybe<Scalars['numeric']>;
  min?: InputMaybe<Scalars['numeric']>;
  source?: InputMaybe<Wui_SocialMediaSource_Enum>;
  user_id?: InputMaybe<Scalars['bigint']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SocialMediaDemographic_Max_Fields = {
  __typename?: 'SocialMediaDemographic_max_fields';
  id?: Maybe<Scalars['bigint']>;
  max?: Maybe<Scalars['numeric']>;
  min?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type SocialMediaDemographic_Min_Fields = {
  __typename?: 'SocialMediaDemographic_min_fields';
  id?: Maybe<Scalars['bigint']>;
  max?: Maybe<Scalars['numeric']>;
  min?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "SocialMediaDemographic" */
export type SocialMediaDemographic_Mutation_Response = {
  __typename?: 'SocialMediaDemographic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SocialMediaDemographic>;
};

/** on_conflict condition type for table "SocialMediaDemographic" */
export type SocialMediaDemographic_On_Conflict = {
  constraint: SocialMediaDemographic_Constraint;
  update_columns?: Array<SocialMediaDemographic_Update_Column>;
  where?: InputMaybe<SocialMediaDemographic_Bool_Exp>;
};

/** Ordering options when selecting data from "SocialMediaDemographic". */
export type SocialMediaDemographic_Order_By = {
  Demographic?: InputMaybe<Demographic_Order_By>;
  WUI_SocialMediaSource?: InputMaybe<Wui_SocialMediaSource_Order_By>;
  WUI_User?: InputMaybe<Wui_User_Order_By>;
  demographic?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: SocialMediaDemographic */
export type SocialMediaDemographic_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "SocialMediaDemographic" */
export enum SocialMediaDemographic_Select_Column {
  /** column name */
  Demographic = 'demographic',
  /** column name */
  Id = 'id',
  /** column name */
  Max = 'max',
  /** column name */
  Min = 'min',
  /** column name */
  Source = 'source',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "SocialMediaDemographic" */
export type SocialMediaDemographic_Set_Input = {
  demographic?: InputMaybe<Demographic_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  max?: InputMaybe<Scalars['numeric']>;
  min?: InputMaybe<Scalars['numeric']>;
  source?: InputMaybe<Wui_SocialMediaSource_Enum>;
  user_id?: InputMaybe<Scalars['bigint']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type SocialMediaDemographic_Stddev_Fields = {
  __typename?: 'SocialMediaDemographic_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SocialMediaDemographic_Stddev_Pop_Fields = {
  __typename?: 'SocialMediaDemographic_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SocialMediaDemographic_Stddev_Samp_Fields = {
  __typename?: 'SocialMediaDemographic_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "SocialMediaDemographic" */
export type SocialMediaDemographic_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SocialMediaDemographic_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SocialMediaDemographic_Stream_Cursor_Value_Input = {
  demographic?: InputMaybe<Demographic_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  max?: InputMaybe<Scalars['numeric']>;
  min?: InputMaybe<Scalars['numeric']>;
  source?: InputMaybe<Wui_SocialMediaSource_Enum>;
  user_id?: InputMaybe<Scalars['bigint']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type SocialMediaDemographic_Sum_Fields = {
  __typename?: 'SocialMediaDemographic_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  max?: Maybe<Scalars['numeric']>;
  min?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "SocialMediaDemographic" */
export enum SocialMediaDemographic_Update_Column {
  /** column name */
  Demographic = 'demographic',
  /** column name */
  Id = 'id',
  /** column name */
  Max = 'max',
  /** column name */
  Min = 'min',
  /** column name */
  Source = 'source',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type SocialMediaDemographic_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SocialMediaDemographic_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SocialMediaDemographic_Set_Input>;
  /** filter the rows which have to be updated */
  where: SocialMediaDemographic_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SocialMediaDemographic_Var_Pop_Fields = {
  __typename?: 'SocialMediaDemographic_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SocialMediaDemographic_Var_Samp_Fields = {
  __typename?: 'SocialMediaDemographic_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type SocialMediaDemographic_Variance_Fields = {
  __typename?: 'SocialMediaDemographic_variance_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** SocialMedia clone with multiple records to maintain a history */
export type SocialMediaHistory = {
  __typename?: 'SocialMediaHistory';
  created_at: Scalars['timestamptz'];
  engagement: Scalars['numeric'];
  followers: Scalars['bigint'];
  following: Scalars['bigint'];
  handle: Scalars['String'];
  id: Scalars['uuid'];
  likes: Scalars['bigint'];
  platform: SocialMediaPlatform_Enum;
  posts: Scalars['bigint'];
  social_media_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['bigint'];
};

/** aggregated selection of "SocialMediaHistory" */
export type SocialMediaHistory_Aggregate = {
  __typename?: 'SocialMediaHistory_aggregate';
  aggregate?: Maybe<SocialMediaHistory_Aggregate_Fields>;
  nodes: Array<SocialMediaHistory>;
};

/** aggregate fields of "SocialMediaHistory" */
export type SocialMediaHistory_Aggregate_Fields = {
  __typename?: 'SocialMediaHistory_aggregate_fields';
  avg?: Maybe<SocialMediaHistory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SocialMediaHistory_Max_Fields>;
  min?: Maybe<SocialMediaHistory_Min_Fields>;
  stddev?: Maybe<SocialMediaHistory_Stddev_Fields>;
  stddev_pop?: Maybe<SocialMediaHistory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SocialMediaHistory_Stddev_Samp_Fields>;
  sum?: Maybe<SocialMediaHistory_Sum_Fields>;
  var_pop?: Maybe<SocialMediaHistory_Var_Pop_Fields>;
  var_samp?: Maybe<SocialMediaHistory_Var_Samp_Fields>;
  variance?: Maybe<SocialMediaHistory_Variance_Fields>;
};


/** aggregate fields of "SocialMediaHistory" */
export type SocialMediaHistory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SocialMediaHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SocialMediaHistory_Avg_Fields = {
  __typename?: 'SocialMediaHistory_avg_fields';
  engagement?: Maybe<Scalars['Float']>;
  followers?: Maybe<Scalars['Float']>;
  following?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
  posts?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SocialMediaHistory". All fields are combined with a logical 'AND'. */
export type SocialMediaHistory_Bool_Exp = {
  _and?: InputMaybe<Array<SocialMediaHistory_Bool_Exp>>;
  _not?: InputMaybe<SocialMediaHistory_Bool_Exp>;
  _or?: InputMaybe<Array<SocialMediaHistory_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  engagement?: InputMaybe<Numeric_Comparison_Exp>;
  followers?: InputMaybe<Bigint_Comparison_Exp>;
  following?: InputMaybe<Bigint_Comparison_Exp>;
  handle?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  likes?: InputMaybe<Bigint_Comparison_Exp>;
  platform?: InputMaybe<SocialMediaPlatform_Enum_Comparison_Exp>;
  posts?: InputMaybe<Bigint_Comparison_Exp>;
  social_media_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "SocialMediaHistory" */
export enum SocialMediaHistory_Constraint {
  /** unique or primary key constraint on columns "id" */
  SocialMediaHistoryPkey = 'SocialMediaHistory_pkey'
}

/** input type for incrementing numeric columns in table "SocialMediaHistory" */
export type SocialMediaHistory_Inc_Input = {
  engagement?: InputMaybe<Scalars['numeric']>;
  followers?: InputMaybe<Scalars['bigint']>;
  following?: InputMaybe<Scalars['bigint']>;
  likes?: InputMaybe<Scalars['bigint']>;
  posts?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "SocialMediaHistory" */
export type SocialMediaHistory_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  engagement?: InputMaybe<Scalars['numeric']>;
  followers?: InputMaybe<Scalars['bigint']>;
  following?: InputMaybe<Scalars['bigint']>;
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  likes?: InputMaybe<Scalars['bigint']>;
  platform?: InputMaybe<SocialMediaPlatform_Enum>;
  posts?: InputMaybe<Scalars['bigint']>;
  social_media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type SocialMediaHistory_Max_Fields = {
  __typename?: 'SocialMediaHistory_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  engagement?: Maybe<Scalars['numeric']>;
  followers?: Maybe<Scalars['bigint']>;
  following?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  likes?: Maybe<Scalars['bigint']>;
  posts?: Maybe<Scalars['bigint']>;
  social_media_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type SocialMediaHistory_Min_Fields = {
  __typename?: 'SocialMediaHistory_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  engagement?: Maybe<Scalars['numeric']>;
  followers?: Maybe<Scalars['bigint']>;
  following?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  likes?: Maybe<Scalars['bigint']>;
  posts?: Maybe<Scalars['bigint']>;
  social_media_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "SocialMediaHistory" */
export type SocialMediaHistory_Mutation_Response = {
  __typename?: 'SocialMediaHistory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SocialMediaHistory>;
};

/** on_conflict condition type for table "SocialMediaHistory" */
export type SocialMediaHistory_On_Conflict = {
  constraint: SocialMediaHistory_Constraint;
  update_columns?: Array<SocialMediaHistory_Update_Column>;
  where?: InputMaybe<SocialMediaHistory_Bool_Exp>;
};

/** Ordering options when selecting data from "SocialMediaHistory". */
export type SocialMediaHistory_Order_By = {
  created_at?: InputMaybe<Order_By>;
  engagement?: InputMaybe<Order_By>;
  followers?: InputMaybe<Order_By>;
  following?: InputMaybe<Order_By>;
  handle?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
  platform?: InputMaybe<Order_By>;
  posts?: InputMaybe<Order_By>;
  social_media_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: SocialMediaHistory */
export type SocialMediaHistory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "SocialMediaHistory" */
export enum SocialMediaHistory_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Engagement = 'engagement',
  /** column name */
  Followers = 'followers',
  /** column name */
  Following = 'following',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  Likes = 'likes',
  /** column name */
  Platform = 'platform',
  /** column name */
  Posts = 'posts',
  /** column name */
  SocialMediaId = 'social_media_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "SocialMediaHistory" */
export type SocialMediaHistory_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  engagement?: InputMaybe<Scalars['numeric']>;
  followers?: InputMaybe<Scalars['bigint']>;
  following?: InputMaybe<Scalars['bigint']>;
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  likes?: InputMaybe<Scalars['bigint']>;
  platform?: InputMaybe<SocialMediaPlatform_Enum>;
  posts?: InputMaybe<Scalars['bigint']>;
  social_media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type SocialMediaHistory_Stddev_Fields = {
  __typename?: 'SocialMediaHistory_stddev_fields';
  engagement?: Maybe<Scalars['Float']>;
  followers?: Maybe<Scalars['Float']>;
  following?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
  posts?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SocialMediaHistory_Stddev_Pop_Fields = {
  __typename?: 'SocialMediaHistory_stddev_pop_fields';
  engagement?: Maybe<Scalars['Float']>;
  followers?: Maybe<Scalars['Float']>;
  following?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
  posts?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SocialMediaHistory_Stddev_Samp_Fields = {
  __typename?: 'SocialMediaHistory_stddev_samp_fields';
  engagement?: Maybe<Scalars['Float']>;
  followers?: Maybe<Scalars['Float']>;
  following?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
  posts?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "SocialMediaHistory" */
export type SocialMediaHistory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SocialMediaHistory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SocialMediaHistory_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  engagement?: InputMaybe<Scalars['numeric']>;
  followers?: InputMaybe<Scalars['bigint']>;
  following?: InputMaybe<Scalars['bigint']>;
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  likes?: InputMaybe<Scalars['bigint']>;
  platform?: InputMaybe<SocialMediaPlatform_Enum>;
  posts?: InputMaybe<Scalars['bigint']>;
  social_media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type SocialMediaHistory_Sum_Fields = {
  __typename?: 'SocialMediaHistory_sum_fields';
  engagement?: Maybe<Scalars['numeric']>;
  followers?: Maybe<Scalars['bigint']>;
  following?: Maybe<Scalars['bigint']>;
  likes?: Maybe<Scalars['bigint']>;
  posts?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "SocialMediaHistory" */
export enum SocialMediaHistory_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Engagement = 'engagement',
  /** column name */
  Followers = 'followers',
  /** column name */
  Following = 'following',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  Likes = 'likes',
  /** column name */
  Platform = 'platform',
  /** column name */
  Posts = 'posts',
  /** column name */
  SocialMediaId = 'social_media_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type SocialMediaHistory_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SocialMediaHistory_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SocialMediaHistory_Set_Input>;
  /** filter the rows which have to be updated */
  where: SocialMediaHistory_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SocialMediaHistory_Var_Pop_Fields = {
  __typename?: 'SocialMediaHistory_var_pop_fields';
  engagement?: Maybe<Scalars['Float']>;
  followers?: Maybe<Scalars['Float']>;
  following?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
  posts?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SocialMediaHistory_Var_Samp_Fields = {
  __typename?: 'SocialMediaHistory_var_samp_fields';
  engagement?: Maybe<Scalars['Float']>;
  followers?: Maybe<Scalars['Float']>;
  following?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
  posts?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SocialMediaHistory_Variance_Fields = {
  __typename?: 'SocialMediaHistory_variance_fields';
  engagement?: Maybe<Scalars['Float']>;
  followers?: Maybe<Scalars['Float']>;
  following?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
  posts?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "SocialMediaLink" */
export type SocialMediaLink = {
  __typename?: 'SocialMediaLink';
  /** An array relationship */
  SessionEvents: Array<Wui_SessionEvent>;
  /** An aggregate relationship */
  SessionEvents_aggregate: Wui_SessionEvent_Aggregate;
  /** An object relationship */
  WUI_User: Wui_User;
  enabled: Scalars['Boolean'];
  id: Scalars['bigint'];
  order: Scalars['numeric'];
  primary: Scalars['Boolean'];
  solid: Scalars['Boolean'];
  title: Scalars['String'];
  url: Scalars['String'];
  user_id: Scalars['bigint'];
};


/** columns and relationships of "SocialMediaLink" */
export type SocialMediaLinkSessionEventsArgs = {
  distinct_on?: InputMaybe<Array<Wui_SessionEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SessionEvent_Order_By>>;
  where?: InputMaybe<Wui_SessionEvent_Bool_Exp>;
};


/** columns and relationships of "SocialMediaLink" */
export type SocialMediaLinkSessionEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SessionEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SessionEvent_Order_By>>;
  where?: InputMaybe<Wui_SessionEvent_Bool_Exp>;
};

/** aggregated selection of "SocialMediaLink" */
export type SocialMediaLink_Aggregate = {
  __typename?: 'SocialMediaLink_aggregate';
  aggregate?: Maybe<SocialMediaLink_Aggregate_Fields>;
  nodes: Array<SocialMediaLink>;
};

export type SocialMediaLink_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<SocialMediaLink_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<SocialMediaLink_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<SocialMediaLink_Aggregate_Bool_Exp_Count>;
};

export type SocialMediaLink_Aggregate_Bool_Exp_Bool_And = {
  arguments: SocialMediaLink_Select_Column_SocialMediaLink_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SocialMediaLink_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type SocialMediaLink_Aggregate_Bool_Exp_Bool_Or = {
  arguments: SocialMediaLink_Select_Column_SocialMediaLink_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SocialMediaLink_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type SocialMediaLink_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<SocialMediaLink_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SocialMediaLink_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "SocialMediaLink" */
export type SocialMediaLink_Aggregate_Fields = {
  __typename?: 'SocialMediaLink_aggregate_fields';
  avg?: Maybe<SocialMediaLink_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SocialMediaLink_Max_Fields>;
  min?: Maybe<SocialMediaLink_Min_Fields>;
  stddev?: Maybe<SocialMediaLink_Stddev_Fields>;
  stddev_pop?: Maybe<SocialMediaLink_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SocialMediaLink_Stddev_Samp_Fields>;
  sum?: Maybe<SocialMediaLink_Sum_Fields>;
  var_pop?: Maybe<SocialMediaLink_Var_Pop_Fields>;
  var_samp?: Maybe<SocialMediaLink_Var_Samp_Fields>;
  variance?: Maybe<SocialMediaLink_Variance_Fields>;
};


/** aggregate fields of "SocialMediaLink" */
export type SocialMediaLink_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SocialMediaLink_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SocialMediaLink" */
export type SocialMediaLink_Aggregate_Order_By = {
  avg?: InputMaybe<SocialMediaLink_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<SocialMediaLink_Max_Order_By>;
  min?: InputMaybe<SocialMediaLink_Min_Order_By>;
  stddev?: InputMaybe<SocialMediaLink_Stddev_Order_By>;
  stddev_pop?: InputMaybe<SocialMediaLink_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<SocialMediaLink_Stddev_Samp_Order_By>;
  sum?: InputMaybe<SocialMediaLink_Sum_Order_By>;
  var_pop?: InputMaybe<SocialMediaLink_Var_Pop_Order_By>;
  var_samp?: InputMaybe<SocialMediaLink_Var_Samp_Order_By>;
  variance?: InputMaybe<SocialMediaLink_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "SocialMediaLink" */
export type SocialMediaLink_Arr_Rel_Insert_Input = {
  data: Array<SocialMediaLink_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<SocialMediaLink_On_Conflict>;
};

/** aggregate avg on columns */
export type SocialMediaLink_Avg_Fields = {
  __typename?: 'SocialMediaLink_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "SocialMediaLink" */
export type SocialMediaLink_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "SocialMediaLink". All fields are combined with a logical 'AND'. */
export type SocialMediaLink_Bool_Exp = {
  SessionEvents?: InputMaybe<Wui_SessionEvent_Bool_Exp>;
  SessionEvents_aggregate?: InputMaybe<Wui_SessionEvent_Aggregate_Bool_Exp>;
  WUI_User?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<SocialMediaLink_Bool_Exp>>;
  _not?: InputMaybe<SocialMediaLink_Bool_Exp>;
  _or?: InputMaybe<Array<SocialMediaLink_Bool_Exp>>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  order?: InputMaybe<Numeric_Comparison_Exp>;
  primary?: InputMaybe<Boolean_Comparison_Exp>;
  solid?: InputMaybe<Boolean_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "SocialMediaLink" */
export enum SocialMediaLink_Constraint {
  /** unique or primary key constraint on columns "id" */
  SocialMediaLinkPkey = 'SocialMediaLink_pkey'
}

/** input type for incrementing numeric columns in table "SocialMediaLink" */
export type SocialMediaLink_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  order?: InputMaybe<Scalars['numeric']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "SocialMediaLink" */
export type SocialMediaLink_Insert_Input = {
  SessionEvents?: InputMaybe<Wui_SessionEvent_Arr_Rel_Insert_Input>;
  WUI_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  order?: InputMaybe<Scalars['numeric']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  solid?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type SocialMediaLink_Max_Fields = {
  __typename?: 'SocialMediaLink_max_fields';
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "SocialMediaLink" */
export type SocialMediaLink_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type SocialMediaLink_Min_Fields = {
  __typename?: 'SocialMediaLink_min_fields';
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "SocialMediaLink" */
export type SocialMediaLink_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "SocialMediaLink" */
export type SocialMediaLink_Mutation_Response = {
  __typename?: 'SocialMediaLink_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SocialMediaLink>;
};

/** input type for inserting object relation for remote table "SocialMediaLink" */
export type SocialMediaLink_Obj_Rel_Insert_Input = {
  data: SocialMediaLink_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<SocialMediaLink_On_Conflict>;
};

/** on_conflict condition type for table "SocialMediaLink" */
export type SocialMediaLink_On_Conflict = {
  constraint: SocialMediaLink_Constraint;
  update_columns?: Array<SocialMediaLink_Update_Column>;
  where?: InputMaybe<SocialMediaLink_Bool_Exp>;
};

/** Ordering options when selecting data from "SocialMediaLink". */
export type SocialMediaLink_Order_By = {
  SessionEvents_aggregate?: InputMaybe<Wui_SessionEvent_Aggregate_Order_By>;
  WUI_User?: InputMaybe<Wui_User_Order_By>;
  enabled?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  primary?: InputMaybe<Order_By>;
  solid?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: SocialMediaLink */
export type SocialMediaLink_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "SocialMediaLink" */
export enum SocialMediaLink_Select_Column {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Primary = 'primary',
  /** column name */
  Solid = 'solid',
  /** column name */
  Title = 'title',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

/** select "SocialMediaLink_aggregate_bool_exp_bool_and_arguments_columns" columns of table "SocialMediaLink" */
export enum SocialMediaLink_Select_Column_SocialMediaLink_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Primary = 'primary',
  /** column name */
  Solid = 'solid'
}

/** select "SocialMediaLink_aggregate_bool_exp_bool_or_arguments_columns" columns of table "SocialMediaLink" */
export enum SocialMediaLink_Select_Column_SocialMediaLink_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Primary = 'primary',
  /** column name */
  Solid = 'solid'
}

/** input type for updating data in table "SocialMediaLink" */
export type SocialMediaLink_Set_Input = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  order?: InputMaybe<Scalars['numeric']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  solid?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type SocialMediaLink_Stddev_Fields = {
  __typename?: 'SocialMediaLink_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "SocialMediaLink" */
export type SocialMediaLink_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SocialMediaLink_Stddev_Pop_Fields = {
  __typename?: 'SocialMediaLink_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "SocialMediaLink" */
export type SocialMediaLink_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SocialMediaLink_Stddev_Samp_Fields = {
  __typename?: 'SocialMediaLink_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "SocialMediaLink" */
export type SocialMediaLink_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "SocialMediaLink" */
export type SocialMediaLink_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SocialMediaLink_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SocialMediaLink_Stream_Cursor_Value_Input = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  order?: InputMaybe<Scalars['numeric']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  solid?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type SocialMediaLink_Sum_Fields = {
  __typename?: 'SocialMediaLink_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "SocialMediaLink" */
export type SocialMediaLink_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "SocialMediaLink" */
export enum SocialMediaLink_Update_Column {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Primary = 'primary',
  /** column name */
  Solid = 'solid',
  /** column name */
  Title = 'title',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

export type SocialMediaLink_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SocialMediaLink_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SocialMediaLink_Set_Input>;
  /** filter the rows which have to be updated */
  where: SocialMediaLink_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SocialMediaLink_Var_Pop_Fields = {
  __typename?: 'SocialMediaLink_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "SocialMediaLink" */
export type SocialMediaLink_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SocialMediaLink_Var_Samp_Fields = {
  __typename?: 'SocialMediaLink_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "SocialMediaLink" */
export type SocialMediaLink_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type SocialMediaLink_Variance_Fields = {
  __typename?: 'SocialMediaLink_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "SocialMediaLink" */
export type SocialMediaLink_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Enum containing all supported social media platforms */
export type SocialMediaPlatform = {
  __typename?: 'SocialMediaPlatform';
  value: Scalars['String'];
};

/** aggregated selection of "SocialMediaPlatform" */
export type SocialMediaPlatform_Aggregate = {
  __typename?: 'SocialMediaPlatform_aggregate';
  aggregate?: Maybe<SocialMediaPlatform_Aggregate_Fields>;
  nodes: Array<SocialMediaPlatform>;
};

/** aggregate fields of "SocialMediaPlatform" */
export type SocialMediaPlatform_Aggregate_Fields = {
  __typename?: 'SocialMediaPlatform_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SocialMediaPlatform_Max_Fields>;
  min?: Maybe<SocialMediaPlatform_Min_Fields>;
};


/** aggregate fields of "SocialMediaPlatform" */
export type SocialMediaPlatform_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SocialMediaPlatform_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "SocialMediaPlatform". All fields are combined with a logical 'AND'. */
export type SocialMediaPlatform_Bool_Exp = {
  _and?: InputMaybe<Array<SocialMediaPlatform_Bool_Exp>>;
  _not?: InputMaybe<SocialMediaPlatform_Bool_Exp>;
  _or?: InputMaybe<Array<SocialMediaPlatform_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "SocialMediaPlatform" */
export enum SocialMediaPlatform_Constraint {
  /** unique or primary key constraint on columns "value" */
  SocialMediaPlatformPkey = 'SocialMediaPlatform_pkey'
}

export enum SocialMediaPlatform_Enum {
  Facebook = 'facebook',
  Instagram = 'instagram',
  Tiktok = 'tiktok',
  Twitch = 'twitch',
  Twitter = 'twitter',
  Youtube = 'youtube'
}

/** Boolean expression to compare columns of type "SocialMediaPlatform_enum". All fields are combined with logical 'AND'. */
export type SocialMediaPlatform_Enum_Comparison_Exp = {
  _eq?: InputMaybe<SocialMediaPlatform_Enum>;
  _in?: InputMaybe<Array<SocialMediaPlatform_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<SocialMediaPlatform_Enum>;
  _nin?: InputMaybe<Array<SocialMediaPlatform_Enum>>;
};

/** input type for inserting data into table "SocialMediaPlatform" */
export type SocialMediaPlatform_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SocialMediaPlatform_Max_Fields = {
  __typename?: 'SocialMediaPlatform_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SocialMediaPlatform_Min_Fields = {
  __typename?: 'SocialMediaPlatform_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "SocialMediaPlatform" */
export type SocialMediaPlatform_Mutation_Response = {
  __typename?: 'SocialMediaPlatform_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SocialMediaPlatform>;
};

/** on_conflict condition type for table "SocialMediaPlatform" */
export type SocialMediaPlatform_On_Conflict = {
  constraint: SocialMediaPlatform_Constraint;
  update_columns?: Array<SocialMediaPlatform_Update_Column>;
  where?: InputMaybe<SocialMediaPlatform_Bool_Exp>;
};

/** Ordering options when selecting data from "SocialMediaPlatform". */
export type SocialMediaPlatform_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: SocialMediaPlatform */
export type SocialMediaPlatform_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "SocialMediaPlatform" */
export enum SocialMediaPlatform_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "SocialMediaPlatform" */
export type SocialMediaPlatform_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "SocialMediaPlatform" */
export type SocialMediaPlatform_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SocialMediaPlatform_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SocialMediaPlatform_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "SocialMediaPlatform" */
export enum SocialMediaPlatform_Update_Column {
  /** column name */
  Value = 'value'
}

export type SocialMediaPlatform_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SocialMediaPlatform_Set_Input>;
  /** filter the rows which have to be updated */
  where: SocialMediaPlatform_Bool_Exp;
};

/** aggregated selection of "SocialMedia" */
export type SocialMedia_Aggregate = {
  __typename?: 'SocialMedia_aggregate';
  aggregate?: Maybe<SocialMedia_Aggregate_Fields>;
  nodes: Array<SocialMedia>;
};

/** aggregate fields of "SocialMedia" */
export type SocialMedia_Aggregate_Fields = {
  __typename?: 'SocialMedia_aggregate_fields';
  avg?: Maybe<SocialMedia_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SocialMedia_Max_Fields>;
  min?: Maybe<SocialMedia_Min_Fields>;
  stddev?: Maybe<SocialMedia_Stddev_Fields>;
  stddev_pop?: Maybe<SocialMedia_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SocialMedia_Stddev_Samp_Fields>;
  sum?: Maybe<SocialMedia_Sum_Fields>;
  var_pop?: Maybe<SocialMedia_Var_Pop_Fields>;
  var_samp?: Maybe<SocialMedia_Var_Samp_Fields>;
  variance?: Maybe<SocialMedia_Variance_Fields>;
};


/** aggregate fields of "SocialMedia" */
export type SocialMedia_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SocialMedia_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SocialMedia_Avg_Fields = {
  __typename?: 'SocialMedia_avg_fields';
  engagement?: Maybe<Scalars['Float']>;
  followers?: Maybe<Scalars['Float']>;
  following?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
  posts?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SocialMedia". All fields are combined with a logical 'AND'. */
export type SocialMedia_Bool_Exp = {
  User?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<SocialMedia_Bool_Exp>>;
  _not?: InputMaybe<SocialMedia_Bool_Exp>;
  _or?: InputMaybe<Array<SocialMedia_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  engagement?: InputMaybe<Numeric_Comparison_Exp>;
  followers?: InputMaybe<Bigint_Comparison_Exp>;
  following?: InputMaybe<Bigint_Comparison_Exp>;
  handle?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  likes?: InputMaybe<Bigint_Comparison_Exp>;
  platform?: InputMaybe<SocialMediaPlatform_Enum_Comparison_Exp>;
  posts?: InputMaybe<Bigint_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  valid?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "SocialMedia" */
export enum SocialMedia_Constraint {
  /** unique or primary key constraint on columns "id" */
  SocialMediaPkey = 'SocialMedia_pkey'
}

/** input type for incrementing numeric columns in table "SocialMedia" */
export type SocialMedia_Inc_Input = {
  engagement?: InputMaybe<Scalars['numeric']>;
  followers?: InputMaybe<Scalars['bigint']>;
  following?: InputMaybe<Scalars['bigint']>;
  likes?: InputMaybe<Scalars['bigint']>;
  posts?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "SocialMedia" */
export type SocialMedia_Insert_Input = {
  User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  engagement?: InputMaybe<Scalars['numeric']>;
  followers?: InputMaybe<Scalars['bigint']>;
  following?: InputMaybe<Scalars['bigint']>;
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  likes?: InputMaybe<Scalars['bigint']>;
  platform?: InputMaybe<SocialMediaPlatform_Enum>;
  posts?: InputMaybe<Scalars['bigint']>;
  reason?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type SocialMedia_Max_Fields = {
  __typename?: 'SocialMedia_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  engagement?: Maybe<Scalars['numeric']>;
  followers?: Maybe<Scalars['bigint']>;
  following?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  likes?: Maybe<Scalars['bigint']>;
  posts?: Maybe<Scalars['bigint']>;
  reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type SocialMedia_Min_Fields = {
  __typename?: 'SocialMedia_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  engagement?: Maybe<Scalars['numeric']>;
  followers?: Maybe<Scalars['bigint']>;
  following?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  likes?: Maybe<Scalars['bigint']>;
  posts?: Maybe<Scalars['bigint']>;
  reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "SocialMedia" */
export type SocialMedia_Mutation_Response = {
  __typename?: 'SocialMedia_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SocialMedia>;
};

/** on_conflict condition type for table "SocialMedia" */
export type SocialMedia_On_Conflict = {
  constraint: SocialMedia_Constraint;
  update_columns?: Array<SocialMedia_Update_Column>;
  where?: InputMaybe<SocialMedia_Bool_Exp>;
};

/** Ordering options when selecting data from "SocialMedia". */
export type SocialMedia_Order_By = {
  User?: InputMaybe<Wui_User_Order_By>;
  created_at?: InputMaybe<Order_By>;
  engagement?: InputMaybe<Order_By>;
  followers?: InputMaybe<Order_By>;
  following?: InputMaybe<Order_By>;
  handle?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
  platform?: InputMaybe<Order_By>;
  posts?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: SocialMedia */
export type SocialMedia_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "SocialMedia" */
export enum SocialMedia_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Engagement = 'engagement',
  /** column name */
  Followers = 'followers',
  /** column name */
  Following = 'following',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  Likes = 'likes',
  /** column name */
  Platform = 'platform',
  /** column name */
  Posts = 'posts',
  /** column name */
  Reason = 'reason',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Valid = 'valid'
}

/** input type for updating data in table "SocialMedia" */
export type SocialMedia_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  engagement?: InputMaybe<Scalars['numeric']>;
  followers?: InputMaybe<Scalars['bigint']>;
  following?: InputMaybe<Scalars['bigint']>;
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  likes?: InputMaybe<Scalars['bigint']>;
  platform?: InputMaybe<SocialMediaPlatform_Enum>;
  posts?: InputMaybe<Scalars['bigint']>;
  reason?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type SocialMedia_Stddev_Fields = {
  __typename?: 'SocialMedia_stddev_fields';
  engagement?: Maybe<Scalars['Float']>;
  followers?: Maybe<Scalars['Float']>;
  following?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
  posts?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SocialMedia_Stddev_Pop_Fields = {
  __typename?: 'SocialMedia_stddev_pop_fields';
  engagement?: Maybe<Scalars['Float']>;
  followers?: Maybe<Scalars['Float']>;
  following?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
  posts?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SocialMedia_Stddev_Samp_Fields = {
  __typename?: 'SocialMedia_stddev_samp_fields';
  engagement?: Maybe<Scalars['Float']>;
  followers?: Maybe<Scalars['Float']>;
  following?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
  posts?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "SocialMedia" */
export type SocialMedia_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SocialMedia_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SocialMedia_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  engagement?: InputMaybe<Scalars['numeric']>;
  followers?: InputMaybe<Scalars['bigint']>;
  following?: InputMaybe<Scalars['bigint']>;
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  likes?: InputMaybe<Scalars['bigint']>;
  platform?: InputMaybe<SocialMediaPlatform_Enum>;
  posts?: InputMaybe<Scalars['bigint']>;
  reason?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type SocialMedia_Sum_Fields = {
  __typename?: 'SocialMedia_sum_fields';
  engagement?: Maybe<Scalars['numeric']>;
  followers?: Maybe<Scalars['bigint']>;
  following?: Maybe<Scalars['bigint']>;
  likes?: Maybe<Scalars['bigint']>;
  posts?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "SocialMedia" */
export enum SocialMedia_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Engagement = 'engagement',
  /** column name */
  Followers = 'followers',
  /** column name */
  Following = 'following',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  Likes = 'likes',
  /** column name */
  Platform = 'platform',
  /** column name */
  Posts = 'posts',
  /** column name */
  Reason = 'reason',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Valid = 'valid'
}

export type SocialMedia_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SocialMedia_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SocialMedia_Set_Input>;
  /** filter the rows which have to be updated */
  where: SocialMedia_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SocialMedia_Var_Pop_Fields = {
  __typename?: 'SocialMedia_var_pop_fields';
  engagement?: Maybe<Scalars['Float']>;
  followers?: Maybe<Scalars['Float']>;
  following?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
  posts?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SocialMedia_Var_Samp_Fields = {
  __typename?: 'SocialMedia_var_samp_fields';
  engagement?: Maybe<Scalars['Float']>;
  followers?: Maybe<Scalars['Float']>;
  following?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
  posts?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SocialMedia_Variance_Fields = {
  __typename?: 'SocialMedia_variance_fields';
  engagement?: Maybe<Scalars['Float']>;
  followers?: Maybe<Scalars['Float']>;
  following?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
  posts?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "TempLoginWithPassword" */
export type TempLoginWithPassword = {
  __typename?: 'TempLoginWithPassword';
  /** An object relationship */
  WUI_User: Wui_User;
  id: Scalars['uuid'];
  password: Scalars['String'];
  user_id: Scalars['bigint'];
};

/** aggregated selection of "TempLoginWithPassword" */
export type TempLoginWithPassword_Aggregate = {
  __typename?: 'TempLoginWithPassword_aggregate';
  aggregate?: Maybe<TempLoginWithPassword_Aggregate_Fields>;
  nodes: Array<TempLoginWithPassword>;
};

/** aggregate fields of "TempLoginWithPassword" */
export type TempLoginWithPassword_Aggregate_Fields = {
  __typename?: 'TempLoginWithPassword_aggregate_fields';
  avg?: Maybe<TempLoginWithPassword_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<TempLoginWithPassword_Max_Fields>;
  min?: Maybe<TempLoginWithPassword_Min_Fields>;
  stddev?: Maybe<TempLoginWithPassword_Stddev_Fields>;
  stddev_pop?: Maybe<TempLoginWithPassword_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TempLoginWithPassword_Stddev_Samp_Fields>;
  sum?: Maybe<TempLoginWithPassword_Sum_Fields>;
  var_pop?: Maybe<TempLoginWithPassword_Var_Pop_Fields>;
  var_samp?: Maybe<TempLoginWithPassword_Var_Samp_Fields>;
  variance?: Maybe<TempLoginWithPassword_Variance_Fields>;
};


/** aggregate fields of "TempLoginWithPassword" */
export type TempLoginWithPassword_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TempLoginWithPassword_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type TempLoginWithPassword_Avg_Fields = {
  __typename?: 'TempLoginWithPassword_avg_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "TempLoginWithPassword". All fields are combined with a logical 'AND'. */
export type TempLoginWithPassword_Bool_Exp = {
  WUI_User?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<TempLoginWithPassword_Bool_Exp>>;
  _not?: InputMaybe<TempLoginWithPassword_Bool_Exp>;
  _or?: InputMaybe<Array<TempLoginWithPassword_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "TempLoginWithPassword" */
export enum TempLoginWithPassword_Constraint {
  /** unique or primary key constraint on columns "id" */
  TempLoginWithPasswordPkey = 'TempLoginWithPassword_pkey'
}

/** input type for incrementing numeric columns in table "TempLoginWithPassword" */
export type TempLoginWithPassword_Inc_Input = {
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "TempLoginWithPassword" */
export type TempLoginWithPassword_Insert_Input = {
  WUI_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  password?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type TempLoginWithPassword_Max_Fields = {
  __typename?: 'TempLoginWithPassword_max_fields';
  id?: Maybe<Scalars['uuid']>;
  password?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type TempLoginWithPassword_Min_Fields = {
  __typename?: 'TempLoginWithPassword_min_fields';
  id?: Maybe<Scalars['uuid']>;
  password?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "TempLoginWithPassword" */
export type TempLoginWithPassword_Mutation_Response = {
  __typename?: 'TempLoginWithPassword_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TempLoginWithPassword>;
};

/** on_conflict condition type for table "TempLoginWithPassword" */
export type TempLoginWithPassword_On_Conflict = {
  constraint: TempLoginWithPassword_Constraint;
  update_columns?: Array<TempLoginWithPassword_Update_Column>;
  where?: InputMaybe<TempLoginWithPassword_Bool_Exp>;
};

/** Ordering options when selecting data from "TempLoginWithPassword". */
export type TempLoginWithPassword_Order_By = {
  WUI_User?: InputMaybe<Wui_User_Order_By>;
  id?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: TempLoginWithPassword */
export type TempLoginWithPassword_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "TempLoginWithPassword" */
export enum TempLoginWithPassword_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Password = 'password',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "TempLoginWithPassword" */
export type TempLoginWithPassword_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  password?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type TempLoginWithPassword_Stddev_Fields = {
  __typename?: 'TempLoginWithPassword_stddev_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TempLoginWithPassword_Stddev_Pop_Fields = {
  __typename?: 'TempLoginWithPassword_stddev_pop_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TempLoginWithPassword_Stddev_Samp_Fields = {
  __typename?: 'TempLoginWithPassword_stddev_samp_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "TempLoginWithPassword" */
export type TempLoginWithPassword_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TempLoginWithPassword_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TempLoginWithPassword_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  password?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type TempLoginWithPassword_Sum_Fields = {
  __typename?: 'TempLoginWithPassword_sum_fields';
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "TempLoginWithPassword" */
export enum TempLoginWithPassword_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Password = 'password',
  /** column name */
  UserId = 'user_id'
}

export type TempLoginWithPassword_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TempLoginWithPassword_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TempLoginWithPassword_Set_Input>;
  /** filter the rows which have to be updated */
  where: TempLoginWithPassword_Bool_Exp;
};

/** aggregate var_pop on columns */
export type TempLoginWithPassword_Var_Pop_Fields = {
  __typename?: 'TempLoginWithPassword_var_pop_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TempLoginWithPassword_Var_Samp_Fields = {
  __typename?: 'TempLoginWithPassword_var_samp_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TempLoginWithPassword_Variance_Fields = {
  __typename?: 'TempLoginWithPassword_variance_fields';
  user_id?: Maybe<Scalars['Float']>;
};

export type UpdateOrderOutput = {
  __typename?: 'UpdateOrderOutput';
  success?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "UserVideo" */
export type UserVideo = {
  __typename?: 'UserVideo';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
  user_id: Scalars['bigint'];
};

/** aggregated selection of "UserVideo" */
export type UserVideo_Aggregate = {
  __typename?: 'UserVideo_aggregate';
  aggregate?: Maybe<UserVideo_Aggregate_Fields>;
  nodes: Array<UserVideo>;
};

export type UserVideo_Aggregate_Bool_Exp = {
  count?: InputMaybe<UserVideo_Aggregate_Bool_Exp_Count>;
};

export type UserVideo_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserVideo_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserVideo_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "UserVideo" */
export type UserVideo_Aggregate_Fields = {
  __typename?: 'UserVideo_aggregate_fields';
  avg?: Maybe<UserVideo_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<UserVideo_Max_Fields>;
  min?: Maybe<UserVideo_Min_Fields>;
  stddev?: Maybe<UserVideo_Stddev_Fields>;
  stddev_pop?: Maybe<UserVideo_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserVideo_Stddev_Samp_Fields>;
  sum?: Maybe<UserVideo_Sum_Fields>;
  var_pop?: Maybe<UserVideo_Var_Pop_Fields>;
  var_samp?: Maybe<UserVideo_Var_Samp_Fields>;
  variance?: Maybe<UserVideo_Variance_Fields>;
};


/** aggregate fields of "UserVideo" */
export type UserVideo_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserVideo_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "UserVideo" */
export type UserVideo_Aggregate_Order_By = {
  avg?: InputMaybe<UserVideo_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserVideo_Max_Order_By>;
  min?: InputMaybe<UserVideo_Min_Order_By>;
  stddev?: InputMaybe<UserVideo_Stddev_Order_By>;
  stddev_pop?: InputMaybe<UserVideo_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<UserVideo_Stddev_Samp_Order_By>;
  sum?: InputMaybe<UserVideo_Sum_Order_By>;
  var_pop?: InputMaybe<UserVideo_Var_Pop_Order_By>;
  var_samp?: InputMaybe<UserVideo_Var_Samp_Order_By>;
  variance?: InputMaybe<UserVideo_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "UserVideo" */
export type UserVideo_Arr_Rel_Insert_Input = {
  data: Array<UserVideo_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserVideo_On_Conflict>;
};

/** aggregate avg on columns */
export type UserVideo_Avg_Fields = {
  __typename?: 'UserVideo_avg_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "UserVideo" */
export type UserVideo_Avg_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "UserVideo". All fields are combined with a logical 'AND'. */
export type UserVideo_Bool_Exp = {
  _and?: InputMaybe<Array<UserVideo_Bool_Exp>>;
  _not?: InputMaybe<UserVideo_Bool_Exp>;
  _or?: InputMaybe<Array<UserVideo_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserVideo" */
export enum UserVideo_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserVideoPkey = 'UserVideo_pkey'
}

/** input type for incrementing numeric columns in table "UserVideo" */
export type UserVideo_Inc_Input = {
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "UserVideo" */
export type UserVideo_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type UserVideo_Max_Fields = {
  __typename?: 'UserVideo_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "UserVideo" */
export type UserVideo_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserVideo_Min_Fields = {
  __typename?: 'UserVideo_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "UserVideo" */
export type UserVideo_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "UserVideo" */
export type UserVideo_Mutation_Response = {
  __typename?: 'UserVideo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserVideo>;
};

/** on_conflict condition type for table "UserVideo" */
export type UserVideo_On_Conflict = {
  constraint: UserVideo_Constraint;
  update_columns?: Array<UserVideo_Update_Column>;
  where?: InputMaybe<UserVideo_Bool_Exp>;
};

/** Ordering options when selecting data from "UserVideo". */
export type UserVideo_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserVideo */
export type UserVideo_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "UserVideo" */
export enum UserVideo_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "UserVideo" */
export type UserVideo_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type UserVideo_Stddev_Fields = {
  __typename?: 'UserVideo_stddev_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "UserVideo" */
export type UserVideo_Stddev_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type UserVideo_Stddev_Pop_Fields = {
  __typename?: 'UserVideo_stddev_pop_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "UserVideo" */
export type UserVideo_Stddev_Pop_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type UserVideo_Stddev_Samp_Fields = {
  __typename?: 'UserVideo_stddev_samp_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "UserVideo" */
export type UserVideo_Stddev_Samp_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "UserVideo" */
export type UserVideo_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserVideo_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserVideo_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type UserVideo_Sum_Fields = {
  __typename?: 'UserVideo_sum_fields';
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "UserVideo" */
export type UserVideo_Sum_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "UserVideo" */
export enum UserVideo_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

export type UserVideo_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserVideo_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserVideo_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserVideo_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserVideo_Var_Pop_Fields = {
  __typename?: 'UserVideo_var_pop_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "UserVideo" */
export type UserVideo_Var_Pop_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type UserVideo_Var_Samp_Fields = {
  __typename?: 'UserVideo_var_samp_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "UserVideo" */
export type UserVideo_Var_Samp_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type UserVideo_Variance_Fields = {
  __typename?: 'UserVideo_variance_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "UserVideo" */
export type UserVideo_Variance_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** tracking votes */
export type Vote = {
  __typename?: 'Vote';
  /** An object relationship */
  VoteUserCategory: VoteUserCategory;
  /** An object relationship */
  Voter: Wui_User;
  category_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  external_payment_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  industry?: Maybe<Industry_Enum>;
  updated_at: Scalars['timestamptz'];
  user_category_id: Scalars['uuid'];
  voter_id: Scalars['bigint'];
  votes: Scalars['Int'];
};

/** categories available to vote */
export type VoteCategory = {
  __typename?: 'VoteCategory';
  value: Scalars['String'];
};

/** aggregated selection of "VoteCategory" */
export type VoteCategory_Aggregate = {
  __typename?: 'VoteCategory_aggregate';
  aggregate?: Maybe<VoteCategory_Aggregate_Fields>;
  nodes: Array<VoteCategory>;
};

/** aggregate fields of "VoteCategory" */
export type VoteCategory_Aggregate_Fields = {
  __typename?: 'VoteCategory_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<VoteCategory_Max_Fields>;
  min?: Maybe<VoteCategory_Min_Fields>;
};


/** aggregate fields of "VoteCategory" */
export type VoteCategory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<VoteCategory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "VoteCategory". All fields are combined with a logical 'AND'. */
export type VoteCategory_Bool_Exp = {
  _and?: InputMaybe<Array<VoteCategory_Bool_Exp>>;
  _not?: InputMaybe<VoteCategory_Bool_Exp>;
  _or?: InputMaybe<Array<VoteCategory_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "VoteCategory" */
export enum VoteCategory_Constraint {
  /** unique or primary key constraint on columns "value" */
  VoteCategoryPkey = 'VoteCategory_pkey'
}

/** input type for inserting data into table "VoteCategory" */
export type VoteCategory_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type VoteCategory_Max_Fields = {
  __typename?: 'VoteCategory_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type VoteCategory_Min_Fields = {
  __typename?: 'VoteCategory_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "VoteCategory" */
export type VoteCategory_Mutation_Response = {
  __typename?: 'VoteCategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VoteCategory>;
};

/** on_conflict condition type for table "VoteCategory" */
export type VoteCategory_On_Conflict = {
  constraint: VoteCategory_Constraint;
  update_columns?: Array<VoteCategory_Update_Column>;
  where?: InputMaybe<VoteCategory_Bool_Exp>;
};

/** Ordering options when selecting data from "VoteCategory". */
export type VoteCategory_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: VoteCategory */
export type VoteCategory_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "VoteCategory" */
export enum VoteCategory_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "VoteCategory" */
export type VoteCategory_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "VoteCategory" */
export type VoteCategory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: VoteCategory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type VoteCategory_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "VoteCategory" */
export enum VoteCategory_Update_Column {
  /** column name */
  Value = 'value'
}

export type VoteCategory_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VoteCategory_Set_Input>;
  /** filter the rows which have to be updated */
  where: VoteCategory_Bool_Exp;
};

/** tracking who can receive votes */
export type VoteUser = {
  __typename?: 'VoteUser';
  /** An array relationship */
  VoteUserCategories: Array<VoteUserCategory>;
  /** An aggregate relationship */
  VoteUserCategories_aggregate: VoteUserCategory_Aggregate;
  /** An object relationship */
  WUI_User: Wui_User;
  created_at: Scalars['timestamptz'];
  ended_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  primary_category_id: Scalars['String'];
  started_at: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['bigint'];
};


/** tracking who can receive votes */
export type VoteUserVoteUserCategoriesArgs = {
  distinct_on?: InputMaybe<Array<VoteUserCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VoteUserCategory_Order_By>>;
  where?: InputMaybe<VoteUserCategory_Bool_Exp>;
};


/** tracking who can receive votes */
export type VoteUserVoteUserCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VoteUserCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VoteUserCategory_Order_By>>;
  where?: InputMaybe<VoteUserCategory_Bool_Exp>;
};

/** many (user) to one (category) */
export type VoteUserCategory = {
  __typename?: 'VoteUserCategory';
  /** An object relationship */
  VoteUser: VoteUser;
  category_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  vote_user_id: Scalars['uuid'];
};

/** aggregated selection of "VoteUserCategory" */
export type VoteUserCategory_Aggregate = {
  __typename?: 'VoteUserCategory_aggregate';
  aggregate?: Maybe<VoteUserCategory_Aggregate_Fields>;
  nodes: Array<VoteUserCategory>;
};

export type VoteUserCategory_Aggregate_Bool_Exp = {
  count?: InputMaybe<VoteUserCategory_Aggregate_Bool_Exp_Count>;
};

export type VoteUserCategory_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<VoteUserCategory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<VoteUserCategory_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "VoteUserCategory" */
export type VoteUserCategory_Aggregate_Fields = {
  __typename?: 'VoteUserCategory_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<VoteUserCategory_Max_Fields>;
  min?: Maybe<VoteUserCategory_Min_Fields>;
};


/** aggregate fields of "VoteUserCategory" */
export type VoteUserCategory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<VoteUserCategory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "VoteUserCategory" */
export type VoteUserCategory_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<VoteUserCategory_Max_Order_By>;
  min?: InputMaybe<VoteUserCategory_Min_Order_By>;
};

/** input type for inserting array relation for remote table "VoteUserCategory" */
export type VoteUserCategory_Arr_Rel_Insert_Input = {
  data: Array<VoteUserCategory_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<VoteUserCategory_On_Conflict>;
};

/** Boolean expression to filter rows from the table "VoteUserCategory". All fields are combined with a logical 'AND'. */
export type VoteUserCategory_Bool_Exp = {
  VoteUser?: InputMaybe<VoteUser_Bool_Exp>;
  _and?: InputMaybe<Array<VoteUserCategory_Bool_Exp>>;
  _not?: InputMaybe<VoteUserCategory_Bool_Exp>;
  _or?: InputMaybe<Array<VoteUserCategory_Bool_Exp>>;
  category_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vote_user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "VoteUserCategory" */
export enum VoteUserCategory_Constraint {
  /** unique or primary key constraint on columns "id" */
  VoteUserCategoryPkey = 'VoteUserCategory_pkey',
  /** unique or primary key constraint on columns "vote_user_id", "category_id" */
  VoteUserCategoryVoteUserIdCategoryIdKey = 'VoteUserCategory_vote_user_id_category_id_key'
}

/** input type for inserting data into table "VoteUserCategory" */
export type VoteUserCategory_Insert_Input = {
  VoteUser?: InputMaybe<VoteUser_Obj_Rel_Insert_Input>;
  category_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vote_user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type VoteUserCategory_Max_Fields = {
  __typename?: 'VoteUserCategory_max_fields';
  category_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vote_user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "VoteUserCategory" */
export type VoteUserCategory_Max_Order_By = {
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vote_user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type VoteUserCategory_Min_Fields = {
  __typename?: 'VoteUserCategory_min_fields';
  category_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vote_user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "VoteUserCategory" */
export type VoteUserCategory_Min_Order_By = {
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vote_user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "VoteUserCategory" */
export type VoteUserCategory_Mutation_Response = {
  __typename?: 'VoteUserCategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VoteUserCategory>;
};

/** input type for inserting object relation for remote table "VoteUserCategory" */
export type VoteUserCategory_Obj_Rel_Insert_Input = {
  data: VoteUserCategory_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<VoteUserCategory_On_Conflict>;
};

/** on_conflict condition type for table "VoteUserCategory" */
export type VoteUserCategory_On_Conflict = {
  constraint: VoteUserCategory_Constraint;
  update_columns?: Array<VoteUserCategory_Update_Column>;
  where?: InputMaybe<VoteUserCategory_Bool_Exp>;
};

/** Ordering options when selecting data from "VoteUserCategory". */
export type VoteUserCategory_Order_By = {
  VoteUser?: InputMaybe<VoteUser_Order_By>;
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vote_user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: VoteUserCategory */
export type VoteUserCategory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "VoteUserCategory" */
export enum VoteUserCategory_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoteUserId = 'vote_user_id'
}

/** input type for updating data in table "VoteUserCategory" */
export type VoteUserCategory_Set_Input = {
  category_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vote_user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "VoteUserCategory" */
export type VoteUserCategory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: VoteUserCategory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type VoteUserCategory_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vote_user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "VoteUserCategory" */
export enum VoteUserCategory_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoteUserId = 'vote_user_id'
}

export type VoteUserCategory_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VoteUserCategory_Set_Input>;
  /** filter the rows which have to be updated */
  where: VoteUserCategory_Bool_Exp;
};

/** aggregated selection of "VoteUser" */
export type VoteUser_Aggregate = {
  __typename?: 'VoteUser_aggregate';
  aggregate?: Maybe<VoteUser_Aggregate_Fields>;
  nodes: Array<VoteUser>;
};

/** aggregate fields of "VoteUser" */
export type VoteUser_Aggregate_Fields = {
  __typename?: 'VoteUser_aggregate_fields';
  avg?: Maybe<VoteUser_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VoteUser_Max_Fields>;
  min?: Maybe<VoteUser_Min_Fields>;
  stddev?: Maybe<VoteUser_Stddev_Fields>;
  stddev_pop?: Maybe<VoteUser_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VoteUser_Stddev_Samp_Fields>;
  sum?: Maybe<VoteUser_Sum_Fields>;
  var_pop?: Maybe<VoteUser_Var_Pop_Fields>;
  var_samp?: Maybe<VoteUser_Var_Samp_Fields>;
  variance?: Maybe<VoteUser_Variance_Fields>;
};


/** aggregate fields of "VoteUser" */
export type VoteUser_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<VoteUser_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type VoteUser_Avg_Fields = {
  __typename?: 'VoteUser_avg_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "VoteUser". All fields are combined with a logical 'AND'. */
export type VoteUser_Bool_Exp = {
  VoteUserCategories?: InputMaybe<VoteUserCategory_Bool_Exp>;
  VoteUserCategories_aggregate?: InputMaybe<VoteUserCategory_Aggregate_Bool_Exp>;
  WUI_User?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<VoteUser_Bool_Exp>>;
  _not?: InputMaybe<VoteUser_Bool_Exp>;
  _or?: InputMaybe<Array<VoteUser_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ended_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  primary_category_id?: InputMaybe<String_Comparison_Exp>;
  started_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "VoteUser" */
export enum VoteUser_Constraint {
  /** unique or primary key constraint on columns "id" */
  VoteUserIdKey = 'VoteUser_id_key',
  /** unique or primary key constraint on columns "user_id" */
  VoteUserPkey = 'VoteUser_pkey',
  /** unique or primary key constraint on columns "user_id" */
  VoteUserUserIdKey = 'VoteUser_user_id_key'
}

/** input type for incrementing numeric columns in table "VoteUser" */
export type VoteUser_Inc_Input = {
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "VoteUser" */
export type VoteUser_Insert_Input = {
  VoteUserCategories?: InputMaybe<VoteUserCategory_Arr_Rel_Insert_Input>;
  WUI_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  primary_category_id?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type VoteUser_Max_Fields = {
  __typename?: 'VoteUser_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  primary_category_id?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type VoteUser_Min_Fields = {
  __typename?: 'VoteUser_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  primary_category_id?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "VoteUser" */
export type VoteUser_Mutation_Response = {
  __typename?: 'VoteUser_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VoteUser>;
};

/** input type for inserting object relation for remote table "VoteUser" */
export type VoteUser_Obj_Rel_Insert_Input = {
  data: VoteUser_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<VoteUser_On_Conflict>;
};

/** on_conflict condition type for table "VoteUser" */
export type VoteUser_On_Conflict = {
  constraint: VoteUser_Constraint;
  update_columns?: Array<VoteUser_Update_Column>;
  where?: InputMaybe<VoteUser_Bool_Exp>;
};

/** Ordering options when selecting data from "VoteUser". */
export type VoteUser_Order_By = {
  VoteUserCategories_aggregate?: InputMaybe<VoteUserCategory_Aggregate_Order_By>;
  WUI_User?: InputMaybe<Wui_User_Order_By>;
  created_at?: InputMaybe<Order_By>;
  ended_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  primary_category_id?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: VoteUser */
export type VoteUser_Pk_Columns_Input = {
  user_id: Scalars['bigint'];
};

/** select columns of table "VoteUser" */
export enum VoteUser_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  PrimaryCategoryId = 'primary_category_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "VoteUser" */
export type VoteUser_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  primary_category_id?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type VoteUser_Stddev_Fields = {
  __typename?: 'VoteUser_stddev_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type VoteUser_Stddev_Pop_Fields = {
  __typename?: 'VoteUser_stddev_pop_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type VoteUser_Stddev_Samp_Fields = {
  __typename?: 'VoteUser_stddev_samp_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "VoteUser" */
export type VoteUser_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: VoteUser_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type VoteUser_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  primary_category_id?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type VoteUser_Sum_Fields = {
  __typename?: 'VoteUser_sum_fields';
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "VoteUser" */
export enum VoteUser_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  PrimaryCategoryId = 'primary_category_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type VoteUser_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VoteUser_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VoteUser_Set_Input>;
  /** filter the rows which have to be updated */
  where: VoteUser_Bool_Exp;
};

/** aggregate var_pop on columns */
export type VoteUser_Var_Pop_Fields = {
  __typename?: 'VoteUser_var_pop_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type VoteUser_Var_Samp_Fields = {
  __typename?: 'VoteUser_var_samp_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type VoteUser_Variance_Fields = {
  __typename?: 'VoteUser_variance_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Vote" */
export type Vote_Aggregate = {
  __typename?: 'Vote_aggregate';
  aggregate?: Maybe<Vote_Aggregate_Fields>;
  nodes: Array<Vote>;
};

/** aggregate fields of "Vote" */
export type Vote_Aggregate_Fields = {
  __typename?: 'Vote_aggregate_fields';
  avg?: Maybe<Vote_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vote_Max_Fields>;
  min?: Maybe<Vote_Min_Fields>;
  stddev?: Maybe<Vote_Stddev_Fields>;
  stddev_pop?: Maybe<Vote_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vote_Stddev_Samp_Fields>;
  sum?: Maybe<Vote_Sum_Fields>;
  var_pop?: Maybe<Vote_Var_Pop_Fields>;
  var_samp?: Maybe<Vote_Var_Samp_Fields>;
  variance?: Maybe<Vote_Variance_Fields>;
};


/** aggregate fields of "Vote" */
export type Vote_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vote_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Vote_Avg_Fields = {
  __typename?: 'Vote_avg_fields';
  voter_id?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Vote". All fields are combined with a logical 'AND'. */
export type Vote_Bool_Exp = {
  VoteUserCategory?: InputMaybe<VoteUserCategory_Bool_Exp>;
  Voter?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<Vote_Bool_Exp>>;
  _not?: InputMaybe<Vote_Bool_Exp>;
  _or?: InputMaybe<Array<Vote_Bool_Exp>>;
  category_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  external_payment_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  industry?: InputMaybe<Industry_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_category_id?: InputMaybe<Uuid_Comparison_Exp>;
  voter_id?: InputMaybe<Bigint_Comparison_Exp>;
  votes?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Vote" */
export enum Vote_Constraint {
  /** unique or primary key constraint on columns "id" */
  VotePkey = 'Vote_pkey'
}

/** input type for incrementing numeric columns in table "Vote" */
export type Vote_Inc_Input = {
  voter_id?: InputMaybe<Scalars['bigint']>;
  votes?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Vote" */
export type Vote_Insert_Input = {
  VoteUserCategory?: InputMaybe<VoteUserCategory_Obj_Rel_Insert_Input>;
  Voter?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  category_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_payment_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  industry?: InputMaybe<Industry_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_category_id?: InputMaybe<Scalars['uuid']>;
  voter_id?: InputMaybe<Scalars['bigint']>;
  votes?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Vote_Max_Fields = {
  __typename?: 'Vote_max_fields';
  category_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_payment_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_category_id?: Maybe<Scalars['uuid']>;
  voter_id?: Maybe<Scalars['bigint']>;
  votes?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Vote_Min_Fields = {
  __typename?: 'Vote_min_fields';
  category_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_payment_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_category_id?: Maybe<Scalars['uuid']>;
  voter_id?: Maybe<Scalars['bigint']>;
  votes?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "Vote" */
export type Vote_Mutation_Response = {
  __typename?: 'Vote_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vote>;
};

/** on_conflict condition type for table "Vote" */
export type Vote_On_Conflict = {
  constraint: Vote_Constraint;
  update_columns?: Array<Vote_Update_Column>;
  where?: InputMaybe<Vote_Bool_Exp>;
};

/** Ordering options when selecting data from "Vote". */
export type Vote_Order_By = {
  VoteUserCategory?: InputMaybe<VoteUserCategory_Order_By>;
  Voter?: InputMaybe<Wui_User_Order_By>;
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_payment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  industry?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_category_id?: InputMaybe<Order_By>;
  voter_id?: InputMaybe<Order_By>;
  votes?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Vote */
export type Vote_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Vote" */
export enum Vote_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalPaymentId = 'external_payment_id',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserCategoryId = 'user_category_id',
  /** column name */
  VoterId = 'voter_id',
  /** column name */
  Votes = 'votes'
}

/** input type for updating data in table "Vote" */
export type Vote_Set_Input = {
  category_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_payment_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  industry?: InputMaybe<Industry_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_category_id?: InputMaybe<Scalars['uuid']>;
  voter_id?: InputMaybe<Scalars['bigint']>;
  votes?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Vote_Stddev_Fields = {
  __typename?: 'Vote_stddev_fields';
  voter_id?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Vote_Stddev_Pop_Fields = {
  __typename?: 'Vote_stddev_pop_fields';
  voter_id?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Vote_Stddev_Samp_Fields = {
  __typename?: 'Vote_stddev_samp_fields';
  voter_id?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "Vote" */
export type Vote_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vote_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vote_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_payment_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  industry?: InputMaybe<Industry_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_category_id?: InputMaybe<Scalars['uuid']>;
  voter_id?: InputMaybe<Scalars['bigint']>;
  votes?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Vote_Sum_Fields = {
  __typename?: 'Vote_sum_fields';
  voter_id?: Maybe<Scalars['bigint']>;
  votes?: Maybe<Scalars['Int']>;
};

/** update columns of table "Vote" */
export enum Vote_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalPaymentId = 'external_payment_id',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserCategoryId = 'user_category_id',
  /** column name */
  VoterId = 'voter_id',
  /** column name */
  Votes = 'votes'
}

export type Vote_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Vote_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vote_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vote_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Vote_Var_Pop_Fields = {
  __typename?: 'Vote_var_pop_fields';
  voter_id?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Vote_Var_Samp_Fields = {
  __typename?: 'Vote_var_samp_fields';
  voter_id?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Vote_Variance_Fields = {
  __typename?: 'Vote_variance_fields';
  voter_id?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "VotesByUserCategoryId" */
export type VotesByUserCategoryId = {
  __typename?: 'VotesByUserCategoryId';
  count?: Maybe<Scalars['bigint']>;
  user_category_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "VotesByUserCategoryId" */
export type VotesByUserCategoryId_Aggregate = {
  __typename?: 'VotesByUserCategoryId_aggregate';
  aggregate?: Maybe<VotesByUserCategoryId_Aggregate_Fields>;
  nodes: Array<VotesByUserCategoryId>;
};

/** aggregate fields of "VotesByUserCategoryId" */
export type VotesByUserCategoryId_Aggregate_Fields = {
  __typename?: 'VotesByUserCategoryId_aggregate_fields';
  avg?: Maybe<VotesByUserCategoryId_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VotesByUserCategoryId_Max_Fields>;
  min?: Maybe<VotesByUserCategoryId_Min_Fields>;
  stddev?: Maybe<VotesByUserCategoryId_Stddev_Fields>;
  stddev_pop?: Maybe<VotesByUserCategoryId_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VotesByUserCategoryId_Stddev_Samp_Fields>;
  sum?: Maybe<VotesByUserCategoryId_Sum_Fields>;
  var_pop?: Maybe<VotesByUserCategoryId_Var_Pop_Fields>;
  var_samp?: Maybe<VotesByUserCategoryId_Var_Samp_Fields>;
  variance?: Maybe<VotesByUserCategoryId_Variance_Fields>;
};


/** aggregate fields of "VotesByUserCategoryId" */
export type VotesByUserCategoryId_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<VotesByUserCategoryId_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type VotesByUserCategoryId_Avg_Fields = {
  __typename?: 'VotesByUserCategoryId_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "VotesByUserCategoryId". All fields are combined with a logical 'AND'. */
export type VotesByUserCategoryId_Bool_Exp = {
  _and?: InputMaybe<Array<VotesByUserCategoryId_Bool_Exp>>;
  _not?: InputMaybe<VotesByUserCategoryId_Bool_Exp>;
  _or?: InputMaybe<Array<VotesByUserCategoryId_Bool_Exp>>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  user_category_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type VotesByUserCategoryId_Max_Fields = {
  __typename?: 'VotesByUserCategoryId_max_fields';
  count?: Maybe<Scalars['bigint']>;
  user_category_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type VotesByUserCategoryId_Min_Fields = {
  __typename?: 'VotesByUserCategoryId_min_fields';
  count?: Maybe<Scalars['bigint']>;
  user_category_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "VotesByUserCategoryId". */
export type VotesByUserCategoryId_Order_By = {
  count?: InputMaybe<Order_By>;
  user_category_id?: InputMaybe<Order_By>;
};

/** select columns of table "VotesByUserCategoryId" */
export enum VotesByUserCategoryId_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  UserCategoryId = 'user_category_id'
}

/** aggregate stddev on columns */
export type VotesByUserCategoryId_Stddev_Fields = {
  __typename?: 'VotesByUserCategoryId_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type VotesByUserCategoryId_Stddev_Pop_Fields = {
  __typename?: 'VotesByUserCategoryId_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type VotesByUserCategoryId_Stddev_Samp_Fields = {
  __typename?: 'VotesByUserCategoryId_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "VotesByUserCategoryId" */
export type VotesByUserCategoryId_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: VotesByUserCategoryId_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type VotesByUserCategoryId_Stream_Cursor_Value_Input = {
  count?: InputMaybe<Scalars['bigint']>;
  user_category_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type VotesByUserCategoryId_Sum_Fields = {
  __typename?: 'VotesByUserCategoryId_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type VotesByUserCategoryId_Var_Pop_Fields = {
  __typename?: 'VotesByUserCategoryId_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type VotesByUserCategoryId_Var_Samp_Fields = {
  __typename?: 'VotesByUserCategoryId_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type VotesByUserCategoryId_Variance_Fields = {
  __typename?: 'VotesByUserCategoryId_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_Account" */
export type Wui_Account = {
  __typename?: 'WUI_Account';
  /** An object relationship */
  WUI_User: Wui_User;
  access_token?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['bigint']>;
  id: Scalars['uuid'];
  id_token?: Maybe<Scalars['String']>;
  oauth_token?: Maybe<Scalars['String']>;
  oauth_token_secret?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  providerAccountId: Scalars['String'];
  refresh_token?: Maybe<Scalars['String']>;
  refresh_token_expires_in?: Maybe<Scalars['Int']>;
  scope?: Maybe<Scalars['String']>;
  session_state?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  userId: Scalars['bigint'];
};

/** aggregated selection of "WUI_Account" */
export type Wui_Account_Aggregate = {
  __typename?: 'WUI_Account_aggregate';
  aggregate?: Maybe<Wui_Account_Aggregate_Fields>;
  nodes: Array<Wui_Account>;
};

export type Wui_Account_Aggregate_Bool_Exp = {
  count?: InputMaybe<Wui_Account_Aggregate_Bool_Exp_Count>;
};

export type Wui_Account_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wui_Account_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_Account_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WUI_Account" */
export type Wui_Account_Aggregate_Fields = {
  __typename?: 'WUI_Account_aggregate_fields';
  avg?: Maybe<Wui_Account_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_Account_Max_Fields>;
  min?: Maybe<Wui_Account_Min_Fields>;
  stddev?: Maybe<Wui_Account_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_Account_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_Account_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_Account_Sum_Fields>;
  var_pop?: Maybe<Wui_Account_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_Account_Var_Samp_Fields>;
  variance?: Maybe<Wui_Account_Variance_Fields>;
};


/** aggregate fields of "WUI_Account" */
export type Wui_Account_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_Account_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_Account" */
export type Wui_Account_Aggregate_Order_By = {
  avg?: InputMaybe<Wui_Account_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wui_Account_Max_Order_By>;
  min?: InputMaybe<Wui_Account_Min_Order_By>;
  stddev?: InputMaybe<Wui_Account_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wui_Account_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wui_Account_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wui_Account_Sum_Order_By>;
  var_pop?: InputMaybe<Wui_Account_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wui_Account_Var_Samp_Order_By>;
  variance?: InputMaybe<Wui_Account_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_Account" */
export type Wui_Account_Arr_Rel_Insert_Input = {
  data: Array<Wui_Account_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_Account_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_Account_Avg_Fields = {
  __typename?: 'WUI_Account_avg_fields';
  expires_at?: Maybe<Scalars['Float']>;
  refresh_token_expires_in?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_Account" */
export type Wui_Account_Avg_Order_By = {
  expires_at?: InputMaybe<Order_By>;
  refresh_token_expires_in?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_Account". All fields are combined with a logical 'AND'. */
export type Wui_Account_Bool_Exp = {
  WUI_User?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_Account_Bool_Exp>>;
  _not?: InputMaybe<Wui_Account_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_Account_Bool_Exp>>;
  access_token?: InputMaybe<String_Comparison_Exp>;
  expires_at?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  id_token?: InputMaybe<String_Comparison_Exp>;
  oauth_token?: InputMaybe<String_Comparison_Exp>;
  oauth_token_secret?: InputMaybe<String_Comparison_Exp>;
  provider?: InputMaybe<String_Comparison_Exp>;
  providerAccountId?: InputMaybe<String_Comparison_Exp>;
  refresh_token?: InputMaybe<String_Comparison_Exp>;
  refresh_token_expires_in?: InputMaybe<Int_Comparison_Exp>;
  scope?: InputMaybe<String_Comparison_Exp>;
  session_state?: InputMaybe<String_Comparison_Exp>;
  token_type?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_Account" */
export enum Wui_Account_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiAccountPkey = 'wui_account_pkey'
}

/** input type for incrementing numeric columns in table "WUI_Account" */
export type Wui_Account_Inc_Input = {
  expires_at?: InputMaybe<Scalars['bigint']>;
  refresh_token_expires_in?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_Account" */
export type Wui_Account_Insert_Input = {
  WUI_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  access_token?: InputMaybe<Scalars['String']>;
  expires_at?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  id_token?: InputMaybe<Scalars['String']>;
  oauth_token?: InputMaybe<Scalars['String']>;
  oauth_token_secret?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  providerAccountId?: InputMaybe<Scalars['String']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  refresh_token_expires_in?: InputMaybe<Scalars['Int']>;
  scope?: InputMaybe<Scalars['String']>;
  session_state?: InputMaybe<Scalars['String']>;
  token_type?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_Account_Max_Fields = {
  __typename?: 'WUI_Account_max_fields';
  access_token?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  id_token?: Maybe<Scalars['String']>;
  oauth_token?: Maybe<Scalars['String']>;
  oauth_token_secret?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  providerAccountId?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['String']>;
  refresh_token_expires_in?: Maybe<Scalars['Int']>;
  scope?: Maybe<Scalars['String']>;
  session_state?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_Account" */
export type Wui_Account_Max_Order_By = {
  access_token?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_token?: InputMaybe<Order_By>;
  oauth_token?: InputMaybe<Order_By>;
  oauth_token_secret?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  providerAccountId?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  refresh_token_expires_in?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  session_state?: InputMaybe<Order_By>;
  token_type?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_Account_Min_Fields = {
  __typename?: 'WUI_Account_min_fields';
  access_token?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  id_token?: Maybe<Scalars['String']>;
  oauth_token?: Maybe<Scalars['String']>;
  oauth_token_secret?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  providerAccountId?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['String']>;
  refresh_token_expires_in?: Maybe<Scalars['Int']>;
  scope?: Maybe<Scalars['String']>;
  session_state?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_Account" */
export type Wui_Account_Min_Order_By = {
  access_token?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_token?: InputMaybe<Order_By>;
  oauth_token?: InputMaybe<Order_By>;
  oauth_token_secret?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  providerAccountId?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  refresh_token_expires_in?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  session_state?: InputMaybe<Order_By>;
  token_type?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WUI_Account" */
export type Wui_Account_Mutation_Response = {
  __typename?: 'WUI_Account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_Account>;
};

/** on_conflict condition type for table "WUI_Account" */
export type Wui_Account_On_Conflict = {
  constraint: Wui_Account_Constraint;
  update_columns?: Array<Wui_Account_Update_Column>;
  where?: InputMaybe<Wui_Account_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_Account". */
export type Wui_Account_Order_By = {
  WUI_User?: InputMaybe<Wui_User_Order_By>;
  access_token?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_token?: InputMaybe<Order_By>;
  oauth_token?: InputMaybe<Order_By>;
  oauth_token_secret?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  providerAccountId?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  refresh_token_expires_in?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  session_state?: InputMaybe<Order_By>;
  token_type?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_Account */
export type Wui_Account_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "WUI_Account" */
export enum Wui_Account_Select_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdToken = 'id_token',
  /** column name */
  OauthToken = 'oauth_token',
  /** column name */
  OauthTokenSecret = 'oauth_token_secret',
  /** column name */
  Provider = 'provider',
  /** column name */
  ProviderAccountId = 'providerAccountId',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  RefreshTokenExpiresIn = 'refresh_token_expires_in',
  /** column name */
  Scope = 'scope',
  /** column name */
  SessionState = 'session_state',
  /** column name */
  TokenType = 'token_type',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "WUI_Account" */
export type Wui_Account_Set_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  expires_at?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  id_token?: InputMaybe<Scalars['String']>;
  oauth_token?: InputMaybe<Scalars['String']>;
  oauth_token_secret?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  providerAccountId?: InputMaybe<Scalars['String']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  refresh_token_expires_in?: InputMaybe<Scalars['Int']>;
  scope?: InputMaybe<Scalars['String']>;
  session_state?: InputMaybe<Scalars['String']>;
  token_type?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_Account_Stddev_Fields = {
  __typename?: 'WUI_Account_stddev_fields';
  expires_at?: Maybe<Scalars['Float']>;
  refresh_token_expires_in?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_Account" */
export type Wui_Account_Stddev_Order_By = {
  expires_at?: InputMaybe<Order_By>;
  refresh_token_expires_in?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_Account_Stddev_Pop_Fields = {
  __typename?: 'WUI_Account_stddev_pop_fields';
  expires_at?: Maybe<Scalars['Float']>;
  refresh_token_expires_in?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_Account" */
export type Wui_Account_Stddev_Pop_Order_By = {
  expires_at?: InputMaybe<Order_By>;
  refresh_token_expires_in?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_Account_Stddev_Samp_Fields = {
  __typename?: 'WUI_Account_stddev_samp_fields';
  expires_at?: Maybe<Scalars['Float']>;
  refresh_token_expires_in?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_Account" */
export type Wui_Account_Stddev_Samp_Order_By = {
  expires_at?: InputMaybe<Order_By>;
  refresh_token_expires_in?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WUI_Account" */
export type Wui_Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_Account_Stream_Cursor_Value_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  expires_at?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  id_token?: InputMaybe<Scalars['String']>;
  oauth_token?: InputMaybe<Scalars['String']>;
  oauth_token_secret?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  providerAccountId?: InputMaybe<Scalars['String']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  refresh_token_expires_in?: InputMaybe<Scalars['Int']>;
  scope?: InputMaybe<Scalars['String']>;
  session_state?: InputMaybe<Scalars['String']>;
  token_type?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_Account_Sum_Fields = {
  __typename?: 'WUI_Account_sum_fields';
  expires_at?: Maybe<Scalars['bigint']>;
  refresh_token_expires_in?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_Account" */
export type Wui_Account_Sum_Order_By = {
  expires_at?: InputMaybe<Order_By>;
  refresh_token_expires_in?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** update columns of table "WUI_Account" */
export enum Wui_Account_Update_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdToken = 'id_token',
  /** column name */
  OauthToken = 'oauth_token',
  /** column name */
  OauthTokenSecret = 'oauth_token_secret',
  /** column name */
  Provider = 'provider',
  /** column name */
  ProviderAccountId = 'providerAccountId',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  RefreshTokenExpiresIn = 'refresh_token_expires_in',
  /** column name */
  Scope = 'scope',
  /** column name */
  SessionState = 'session_state',
  /** column name */
  TokenType = 'token_type',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

export type Wui_Account_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_Account_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_Account_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_Account_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_Account_Var_Pop_Fields = {
  __typename?: 'WUI_Account_var_pop_fields';
  expires_at?: Maybe<Scalars['Float']>;
  refresh_token_expires_in?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_Account" */
export type Wui_Account_Var_Pop_Order_By = {
  expires_at?: InputMaybe<Order_By>;
  refresh_token_expires_in?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_Account_Var_Samp_Fields = {
  __typename?: 'WUI_Account_var_samp_fields';
  expires_at?: Maybe<Scalars['Float']>;
  refresh_token_expires_in?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_Account" */
export type Wui_Account_Var_Samp_Order_By = {
  expires_at?: InputMaybe<Order_By>;
  refresh_token_expires_in?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_Account_Variance_Fields = {
  __typename?: 'WUI_Account_variance_fields';
  expires_at?: Maybe<Scalars['Float']>;
  refresh_token_expires_in?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_Account" */
export type Wui_Account_Variance_Order_By = {
  expires_at?: InputMaybe<Order_By>;
  refresh_token_expires_in?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "WUI_Affiliate" */
export type Wui_Affiliate = {
  __typename?: 'WUI_Affiliate';
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  level_a: Scalars['Int'];
  level_aa: Scalars['Int'];
  pro: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['bigint'];
};

/** columns and relationships of "WUI_AffiliateLevel" */
export type Wui_AffiliateLevel = {
  __typename?: 'WUI_AffiliateLevel';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_AffiliateLevel" */
export type Wui_AffiliateLevel_Aggregate = {
  __typename?: 'WUI_AffiliateLevel_aggregate';
  aggregate?: Maybe<Wui_AffiliateLevel_Aggregate_Fields>;
  nodes: Array<Wui_AffiliateLevel>;
};

/** aggregate fields of "WUI_AffiliateLevel" */
export type Wui_AffiliateLevel_Aggregate_Fields = {
  __typename?: 'WUI_AffiliateLevel_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_AffiliateLevel_Max_Fields>;
  min?: Maybe<Wui_AffiliateLevel_Min_Fields>;
};


/** aggregate fields of "WUI_AffiliateLevel" */
export type Wui_AffiliateLevel_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_AffiliateLevel_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_AffiliateLevel". All fields are combined with a logical 'AND'. */
export type Wui_AffiliateLevel_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_AffiliateLevel_Bool_Exp>>;
  _not?: InputMaybe<Wui_AffiliateLevel_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_AffiliateLevel_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_AffiliateLevel" */
export enum Wui_AffiliateLevel_Constraint {
  /** unique or primary key constraint on columns "value" */
  WuiAffiliateLevelPkey = 'WUI_AffiliateLevel_pkey'
}

export enum Wui_AffiliateLevel_Enum {
  A = 'A',
  Aa = 'AA'
}

/** Boolean expression to compare columns of type "WUI_AffiliateLevel_enum". All fields are combined with logical 'AND'. */
export type Wui_AffiliateLevel_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Wui_AffiliateLevel_Enum>;
  _in?: InputMaybe<Array<Wui_AffiliateLevel_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Wui_AffiliateLevel_Enum>;
  _nin?: InputMaybe<Array<Wui_AffiliateLevel_Enum>>;
};

/** input type for inserting data into table "WUI_AffiliateLevel" */
export type Wui_AffiliateLevel_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_AffiliateLevel_Max_Fields = {
  __typename?: 'WUI_AffiliateLevel_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_AffiliateLevel_Min_Fields = {
  __typename?: 'WUI_AffiliateLevel_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_AffiliateLevel" */
export type Wui_AffiliateLevel_Mutation_Response = {
  __typename?: 'WUI_AffiliateLevel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_AffiliateLevel>;
};

/** on_conflict condition type for table "WUI_AffiliateLevel" */
export type Wui_AffiliateLevel_On_Conflict = {
  constraint: Wui_AffiliateLevel_Constraint;
  update_columns?: Array<Wui_AffiliateLevel_Update_Column>;
  where?: InputMaybe<Wui_AffiliateLevel_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_AffiliateLevel". */
export type Wui_AffiliateLevel_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_AffiliateLevel */
export type Wui_AffiliateLevel_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_AffiliateLevel" */
export enum Wui_AffiliateLevel_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_AffiliateLevel" */
export type Wui_AffiliateLevel_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "WUI_AffiliateLevel" */
export type Wui_AffiliateLevel_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_AffiliateLevel_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_AffiliateLevel_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "WUI_AffiliateLevel" */
export enum Wui_AffiliateLevel_Update_Column {
  /** column name */
  Value = 'value'
}

export type Wui_AffiliateLevel_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_AffiliateLevel_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_AffiliateLevel_Bool_Exp;
};

/** columns and relationships of "WUI_AffiliateStatus" */
export type Wui_AffiliateStatus = {
  __typename?: 'WUI_AffiliateStatus';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_AffiliateStatus" */
export type Wui_AffiliateStatus_Aggregate = {
  __typename?: 'WUI_AffiliateStatus_aggregate';
  aggregate?: Maybe<Wui_AffiliateStatus_Aggregate_Fields>;
  nodes: Array<Wui_AffiliateStatus>;
};

/** aggregate fields of "WUI_AffiliateStatus" */
export type Wui_AffiliateStatus_Aggregate_Fields = {
  __typename?: 'WUI_AffiliateStatus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_AffiliateStatus_Max_Fields>;
  min?: Maybe<Wui_AffiliateStatus_Min_Fields>;
};


/** aggregate fields of "WUI_AffiliateStatus" */
export type Wui_AffiliateStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_AffiliateStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_AffiliateStatus". All fields are combined with a logical 'AND'. */
export type Wui_AffiliateStatus_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_AffiliateStatus_Bool_Exp>>;
  _not?: InputMaybe<Wui_AffiliateStatus_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_AffiliateStatus_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_AffiliateStatus" */
export enum Wui_AffiliateStatus_Constraint {
  /** unique or primary key constraint on columns "value" */
  WuiAffiliateStatusPkey = 'WUI_AffiliateStatus_pkey'
}

export enum Wui_AffiliateStatus_Enum {
  OrderCancelled = 'order_cancelled',
  OrderInProgress = 'order_in_progress',
  Paid = 'paid',
  Processing = 'processing',
  Unpaid = 'unpaid'
}

/** Boolean expression to compare columns of type "WUI_AffiliateStatus_enum". All fields are combined with logical 'AND'. */
export type Wui_AffiliateStatus_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Wui_AffiliateStatus_Enum>;
  _in?: InputMaybe<Array<Wui_AffiliateStatus_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Wui_AffiliateStatus_Enum>;
  _nin?: InputMaybe<Array<Wui_AffiliateStatus_Enum>>;
};

/** input type for inserting data into table "WUI_AffiliateStatus" */
export type Wui_AffiliateStatus_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_AffiliateStatus_Max_Fields = {
  __typename?: 'WUI_AffiliateStatus_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_AffiliateStatus_Min_Fields = {
  __typename?: 'WUI_AffiliateStatus_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_AffiliateStatus" */
export type Wui_AffiliateStatus_Mutation_Response = {
  __typename?: 'WUI_AffiliateStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_AffiliateStatus>;
};

/** on_conflict condition type for table "WUI_AffiliateStatus" */
export type Wui_AffiliateStatus_On_Conflict = {
  constraint: Wui_AffiliateStatus_Constraint;
  update_columns?: Array<Wui_AffiliateStatus_Update_Column>;
  where?: InputMaybe<Wui_AffiliateStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_AffiliateStatus". */
export type Wui_AffiliateStatus_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_AffiliateStatus */
export type Wui_AffiliateStatus_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_AffiliateStatus" */
export enum Wui_AffiliateStatus_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_AffiliateStatus" */
export type Wui_AffiliateStatus_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "WUI_AffiliateStatus" */
export type Wui_AffiliateStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_AffiliateStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_AffiliateStatus_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "WUI_AffiliateStatus" */
export enum Wui_AffiliateStatus_Update_Column {
  /** column name */
  Value = 'value'
}

export type Wui_AffiliateStatus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_AffiliateStatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_AffiliateStatus_Bool_Exp;
};

/** columns and relationships of "WUI_AffiliateTransaction" */
export type Wui_AffiliateTransaction = {
  __typename?: 'WUI_AffiliateTransaction';
  /** An object relationship */
  Seller?: Maybe<Wui_User>;
  affiliate_id: Scalars['uuid'];
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  gross_amount: Scalars['numeric'];
  id: Scalars['uuid'];
  level: Wui_AffiliateLevel_Enum;
  order_id?: Maybe<Scalars['bigint']>;
  rate: Scalars['numeric'];
  seller_id?: Maybe<Scalars['bigint']>;
  status: Wui_AffiliateStatus_Enum;
  stripe_transfer_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['bigint'];
};

/** aggregated selection of "WUI_AffiliateTransaction" */
export type Wui_AffiliateTransaction_Aggregate = {
  __typename?: 'WUI_AffiliateTransaction_aggregate';
  aggregate?: Maybe<Wui_AffiliateTransaction_Aggregate_Fields>;
  nodes: Array<Wui_AffiliateTransaction>;
};

/** aggregate fields of "WUI_AffiliateTransaction" */
export type Wui_AffiliateTransaction_Aggregate_Fields = {
  __typename?: 'WUI_AffiliateTransaction_aggregate_fields';
  avg?: Maybe<Wui_AffiliateTransaction_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_AffiliateTransaction_Max_Fields>;
  min?: Maybe<Wui_AffiliateTransaction_Min_Fields>;
  stddev?: Maybe<Wui_AffiliateTransaction_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_AffiliateTransaction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_AffiliateTransaction_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_AffiliateTransaction_Sum_Fields>;
  var_pop?: Maybe<Wui_AffiliateTransaction_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_AffiliateTransaction_Var_Samp_Fields>;
  variance?: Maybe<Wui_AffiliateTransaction_Variance_Fields>;
};


/** aggregate fields of "WUI_AffiliateTransaction" */
export type Wui_AffiliateTransaction_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_AffiliateTransaction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_AffiliateTransaction_Avg_Fields = {
  __typename?: 'WUI_AffiliateTransaction_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  gross_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_AffiliateTransaction". All fields are combined with a logical 'AND'. */
export type Wui_AffiliateTransaction_Bool_Exp = {
  Seller?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_AffiliateTransaction_Bool_Exp>>;
  _not?: InputMaybe<Wui_AffiliateTransaction_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_AffiliateTransaction_Bool_Exp>>;
  affiliate_id?: InputMaybe<Uuid_Comparison_Exp>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  gross_amount?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  level?: InputMaybe<Wui_AffiliateLevel_Enum_Comparison_Exp>;
  order_id?: InputMaybe<Bigint_Comparison_Exp>;
  rate?: InputMaybe<Numeric_Comparison_Exp>;
  seller_id?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<Wui_AffiliateStatus_Enum_Comparison_Exp>;
  stripe_transfer_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_AffiliateTransaction" */
export enum Wui_AffiliateTransaction_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiAffiliateTransactionPkey = 'WUI_AffiliateTransaction_pkey'
}

/** input type for incrementing numeric columns in table "WUI_AffiliateTransaction" */
export type Wui_AffiliateTransaction_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  gross_amount?: InputMaybe<Scalars['numeric']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  rate?: InputMaybe<Scalars['numeric']>;
  seller_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_AffiliateTransaction" */
export type Wui_AffiliateTransaction_Insert_Input = {
  Seller?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  affiliate_id?: InputMaybe<Scalars['uuid']>;
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gross_amount?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  level?: InputMaybe<Wui_AffiliateLevel_Enum>;
  order_id?: InputMaybe<Scalars['bigint']>;
  rate?: InputMaybe<Scalars['numeric']>;
  seller_id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Wui_AffiliateStatus_Enum>;
  stripe_transfer_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_AffiliateTransaction_Max_Fields = {
  __typename?: 'WUI_AffiliateTransaction_max_fields';
  affiliate_id?: Maybe<Scalars['uuid']>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gross_amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  order_id?: Maybe<Scalars['bigint']>;
  rate?: Maybe<Scalars['numeric']>;
  seller_id?: Maybe<Scalars['bigint']>;
  stripe_transfer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Wui_AffiliateTransaction_Min_Fields = {
  __typename?: 'WUI_AffiliateTransaction_min_fields';
  affiliate_id?: Maybe<Scalars['uuid']>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gross_amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  order_id?: Maybe<Scalars['bigint']>;
  rate?: Maybe<Scalars['numeric']>;
  seller_id?: Maybe<Scalars['bigint']>;
  stripe_transfer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "WUI_AffiliateTransaction" */
export type Wui_AffiliateTransaction_Mutation_Response = {
  __typename?: 'WUI_AffiliateTransaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_AffiliateTransaction>;
};

/** on_conflict condition type for table "WUI_AffiliateTransaction" */
export type Wui_AffiliateTransaction_On_Conflict = {
  constraint: Wui_AffiliateTransaction_Constraint;
  update_columns?: Array<Wui_AffiliateTransaction_Update_Column>;
  where?: InputMaybe<Wui_AffiliateTransaction_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_AffiliateTransaction". */
export type Wui_AffiliateTransaction_Order_By = {
  Seller?: InputMaybe<Wui_User_Order_By>;
  affiliate_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  gross_amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  seller_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  stripe_transfer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_AffiliateTransaction */
export type Wui_AffiliateTransaction_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "WUI_AffiliateTransaction" */
export enum Wui_AffiliateTransaction_Select_Column {
  /** column name */
  AffiliateId = 'affiliate_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GrossAmount = 'gross_amount',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Rate = 'rate',
  /** column name */
  SellerId = 'seller_id',
  /** column name */
  Status = 'status',
  /** column name */
  StripeTransferId = 'stripe_transfer_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "WUI_AffiliateTransaction" */
export type Wui_AffiliateTransaction_Set_Input = {
  affiliate_id?: InputMaybe<Scalars['uuid']>;
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gross_amount?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  level?: InputMaybe<Wui_AffiliateLevel_Enum>;
  order_id?: InputMaybe<Scalars['bigint']>;
  rate?: InputMaybe<Scalars['numeric']>;
  seller_id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Wui_AffiliateStatus_Enum>;
  stripe_transfer_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_AffiliateTransaction_Stddev_Fields = {
  __typename?: 'WUI_AffiliateTransaction_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  gross_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_AffiliateTransaction_Stddev_Pop_Fields = {
  __typename?: 'WUI_AffiliateTransaction_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  gross_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_AffiliateTransaction_Stddev_Samp_Fields = {
  __typename?: 'WUI_AffiliateTransaction_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  gross_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WUI_AffiliateTransaction" */
export type Wui_AffiliateTransaction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_AffiliateTransaction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_AffiliateTransaction_Stream_Cursor_Value_Input = {
  affiliate_id?: InputMaybe<Scalars['uuid']>;
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gross_amount?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  level?: InputMaybe<Wui_AffiliateLevel_Enum>;
  order_id?: InputMaybe<Scalars['bigint']>;
  rate?: InputMaybe<Scalars['numeric']>;
  seller_id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Wui_AffiliateStatus_Enum>;
  stripe_transfer_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_AffiliateTransaction_Sum_Fields = {
  __typename?: 'WUI_AffiliateTransaction_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  gross_amount?: Maybe<Scalars['numeric']>;
  order_id?: Maybe<Scalars['bigint']>;
  rate?: Maybe<Scalars['numeric']>;
  seller_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_AffiliateTransaction" */
export enum Wui_AffiliateTransaction_Update_Column {
  /** column name */
  AffiliateId = 'affiliate_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GrossAmount = 'gross_amount',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Rate = 'rate',
  /** column name */
  SellerId = 'seller_id',
  /** column name */
  Status = 'status',
  /** column name */
  StripeTransferId = 'stripe_transfer_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Wui_AffiliateTransaction_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_AffiliateTransaction_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_AffiliateTransaction_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_AffiliateTransaction_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_AffiliateTransaction_Var_Pop_Fields = {
  __typename?: 'WUI_AffiliateTransaction_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  gross_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_AffiliateTransaction_Var_Samp_Fields = {
  __typename?: 'WUI_AffiliateTransaction_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  gross_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_AffiliateTransaction_Variance_Fields = {
  __typename?: 'WUI_AffiliateTransaction_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  gross_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_AffiliateUsers" */
export type Wui_AffiliateUsers = {
  __typename?: 'WUI_AffiliateUsers';
  /** An object relationship */
  WUI_Affiliate: Wui_Affiliate;
  /** An object relationship */
  WUI_User: Wui_User;
  /** An object relationship */
  WUI_User_Affiliate: Wui_User;
  affiliate_id: Scalars['uuid'];
  affiliate_user_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  level: Wui_AffiliateLevel_Enum;
  parent_user_id?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['bigint'];
};

/** aggregated selection of "WUI_AffiliateUsers" */
export type Wui_AffiliateUsers_Aggregate = {
  __typename?: 'WUI_AffiliateUsers_aggregate';
  aggregate?: Maybe<Wui_AffiliateUsers_Aggregate_Fields>;
  nodes: Array<Wui_AffiliateUsers>;
};

/** aggregate fields of "WUI_AffiliateUsers" */
export type Wui_AffiliateUsers_Aggregate_Fields = {
  __typename?: 'WUI_AffiliateUsers_aggregate_fields';
  avg?: Maybe<Wui_AffiliateUsers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_AffiliateUsers_Max_Fields>;
  min?: Maybe<Wui_AffiliateUsers_Min_Fields>;
  stddev?: Maybe<Wui_AffiliateUsers_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_AffiliateUsers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_AffiliateUsers_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_AffiliateUsers_Sum_Fields>;
  var_pop?: Maybe<Wui_AffiliateUsers_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_AffiliateUsers_Var_Samp_Fields>;
  variance?: Maybe<Wui_AffiliateUsers_Variance_Fields>;
};


/** aggregate fields of "WUI_AffiliateUsers" */
export type Wui_AffiliateUsers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_AffiliateUsers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_AffiliateUsers_Avg_Fields = {
  __typename?: 'WUI_AffiliateUsers_avg_fields';
  affiliate_user_id?: Maybe<Scalars['Float']>;
  parent_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_AffiliateUsers". All fields are combined with a logical 'AND'. */
export type Wui_AffiliateUsers_Bool_Exp = {
  WUI_Affiliate?: InputMaybe<Wui_Affiliate_Bool_Exp>;
  WUI_User?: InputMaybe<Wui_User_Bool_Exp>;
  WUI_User_Affiliate?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_AffiliateUsers_Bool_Exp>>;
  _not?: InputMaybe<Wui_AffiliateUsers_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_AffiliateUsers_Bool_Exp>>;
  affiliate_id?: InputMaybe<Uuid_Comparison_Exp>;
  affiliate_user_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  level?: InputMaybe<Wui_AffiliateLevel_Enum_Comparison_Exp>;
  parent_user_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_AffiliateUsers" */
export enum Wui_AffiliateUsers_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiAffiliateUsersPkey = 'WUI_AffiliateUsers_pkey'
}

/** input type for incrementing numeric columns in table "WUI_AffiliateUsers" */
export type Wui_AffiliateUsers_Inc_Input = {
  affiliate_user_id?: InputMaybe<Scalars['bigint']>;
  parent_user_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_AffiliateUsers" */
export type Wui_AffiliateUsers_Insert_Input = {
  WUI_Affiliate?: InputMaybe<Wui_Affiliate_Obj_Rel_Insert_Input>;
  WUI_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  WUI_User_Affiliate?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  affiliate_id?: InputMaybe<Scalars['uuid']>;
  affiliate_user_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  level?: InputMaybe<Wui_AffiliateLevel_Enum>;
  parent_user_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_AffiliateUsers_Max_Fields = {
  __typename?: 'WUI_AffiliateUsers_max_fields';
  affiliate_id?: Maybe<Scalars['uuid']>;
  affiliate_user_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  parent_user_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Wui_AffiliateUsers_Min_Fields = {
  __typename?: 'WUI_AffiliateUsers_min_fields';
  affiliate_id?: Maybe<Scalars['uuid']>;
  affiliate_user_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  parent_user_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "WUI_AffiliateUsers" */
export type Wui_AffiliateUsers_Mutation_Response = {
  __typename?: 'WUI_AffiliateUsers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_AffiliateUsers>;
};

/** on_conflict condition type for table "WUI_AffiliateUsers" */
export type Wui_AffiliateUsers_On_Conflict = {
  constraint: Wui_AffiliateUsers_Constraint;
  update_columns?: Array<Wui_AffiliateUsers_Update_Column>;
  where?: InputMaybe<Wui_AffiliateUsers_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_AffiliateUsers". */
export type Wui_AffiliateUsers_Order_By = {
  WUI_Affiliate?: InputMaybe<Wui_Affiliate_Order_By>;
  WUI_User?: InputMaybe<Wui_User_Order_By>;
  WUI_User_Affiliate?: InputMaybe<Wui_User_Order_By>;
  affiliate_id?: InputMaybe<Order_By>;
  affiliate_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  parent_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_AffiliateUsers */
export type Wui_AffiliateUsers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "WUI_AffiliateUsers" */
export enum Wui_AffiliateUsers_Select_Column {
  /** column name */
  AffiliateId = 'affiliate_id',
  /** column name */
  AffiliateUserId = 'affiliate_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  ParentUserId = 'parent_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "WUI_AffiliateUsers" */
export type Wui_AffiliateUsers_Set_Input = {
  affiliate_id?: InputMaybe<Scalars['uuid']>;
  affiliate_user_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  level?: InputMaybe<Wui_AffiliateLevel_Enum>;
  parent_user_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_AffiliateUsers_Stddev_Fields = {
  __typename?: 'WUI_AffiliateUsers_stddev_fields';
  affiliate_user_id?: Maybe<Scalars['Float']>;
  parent_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_AffiliateUsers_Stddev_Pop_Fields = {
  __typename?: 'WUI_AffiliateUsers_stddev_pop_fields';
  affiliate_user_id?: Maybe<Scalars['Float']>;
  parent_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_AffiliateUsers_Stddev_Samp_Fields = {
  __typename?: 'WUI_AffiliateUsers_stddev_samp_fields';
  affiliate_user_id?: Maybe<Scalars['Float']>;
  parent_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WUI_AffiliateUsers" */
export type Wui_AffiliateUsers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_AffiliateUsers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_AffiliateUsers_Stream_Cursor_Value_Input = {
  affiliate_id?: InputMaybe<Scalars['uuid']>;
  affiliate_user_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  level?: InputMaybe<Wui_AffiliateLevel_Enum>;
  parent_user_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_AffiliateUsers_Sum_Fields = {
  __typename?: 'WUI_AffiliateUsers_sum_fields';
  affiliate_user_id?: Maybe<Scalars['bigint']>;
  parent_user_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_AffiliateUsers" */
export enum Wui_AffiliateUsers_Update_Column {
  /** column name */
  AffiliateId = 'affiliate_id',
  /** column name */
  AffiliateUserId = 'affiliate_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  ParentUserId = 'parent_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Wui_AffiliateUsers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_AffiliateUsers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_AffiliateUsers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_AffiliateUsers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_AffiliateUsers_Var_Pop_Fields = {
  __typename?: 'WUI_AffiliateUsers_var_pop_fields';
  affiliate_user_id?: Maybe<Scalars['Float']>;
  parent_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_AffiliateUsers_Var_Samp_Fields = {
  __typename?: 'WUI_AffiliateUsers_var_samp_fields';
  affiliate_user_id?: Maybe<Scalars['Float']>;
  parent_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_AffiliateUsers_Variance_Fields = {
  __typename?: 'WUI_AffiliateUsers_variance_fields';
  affiliate_user_id?: Maybe<Scalars['Float']>;
  parent_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "WUI_Affiliate" */
export type Wui_Affiliate_Aggregate = {
  __typename?: 'WUI_Affiliate_aggregate';
  aggregate?: Maybe<Wui_Affiliate_Aggregate_Fields>;
  nodes: Array<Wui_Affiliate>;
};

/** aggregate fields of "WUI_Affiliate" */
export type Wui_Affiliate_Aggregate_Fields = {
  __typename?: 'WUI_Affiliate_aggregate_fields';
  avg?: Maybe<Wui_Affiliate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_Affiliate_Max_Fields>;
  min?: Maybe<Wui_Affiliate_Min_Fields>;
  stddev?: Maybe<Wui_Affiliate_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_Affiliate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_Affiliate_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_Affiliate_Sum_Fields>;
  var_pop?: Maybe<Wui_Affiliate_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_Affiliate_Var_Samp_Fields>;
  variance?: Maybe<Wui_Affiliate_Variance_Fields>;
};


/** aggregate fields of "WUI_Affiliate" */
export type Wui_Affiliate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_Affiliate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_Affiliate_Avg_Fields = {
  __typename?: 'WUI_Affiliate_avg_fields';
  level_a?: Maybe<Scalars['Float']>;
  level_aa?: Maybe<Scalars['Float']>;
  pro?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_Affiliate". All fields are combined with a logical 'AND'. */
export type Wui_Affiliate_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_Affiliate_Bool_Exp>>;
  _not?: InputMaybe<Wui_Affiliate_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_Affiliate_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  level_a?: InputMaybe<Int_Comparison_Exp>;
  level_aa?: InputMaybe<Int_Comparison_Exp>;
  pro?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_Affiliate" */
export enum Wui_Affiliate_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiAffiliatePkey = 'WUI_Affiliate_pkey',
  /** unique or primary key constraint on columns "user_id" */
  WuiAffiliateUserIdKey = 'WUI_Affiliate_user_id_key'
}

/** input type for incrementing numeric columns in table "WUI_Affiliate" */
export type Wui_Affiliate_Inc_Input = {
  level_a?: InputMaybe<Scalars['Int']>;
  level_aa?: InputMaybe<Scalars['Int']>;
  pro?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_Affiliate" */
export type Wui_Affiliate_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  level_a?: InputMaybe<Scalars['Int']>;
  level_aa?: InputMaybe<Scalars['Int']>;
  pro?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_Affiliate_Max_Fields = {
  __typename?: 'WUI_Affiliate_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  level_a?: Maybe<Scalars['Int']>;
  level_aa?: Maybe<Scalars['Int']>;
  pro?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Wui_Affiliate_Min_Fields = {
  __typename?: 'WUI_Affiliate_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  level_a?: Maybe<Scalars['Int']>;
  level_aa?: Maybe<Scalars['Int']>;
  pro?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "WUI_Affiliate" */
export type Wui_Affiliate_Mutation_Response = {
  __typename?: 'WUI_Affiliate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_Affiliate>;
};

/** input type for inserting object relation for remote table "WUI_Affiliate" */
export type Wui_Affiliate_Obj_Rel_Insert_Input = {
  data: Wui_Affiliate_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_Affiliate_On_Conflict>;
};

/** on_conflict condition type for table "WUI_Affiliate" */
export type Wui_Affiliate_On_Conflict = {
  constraint: Wui_Affiliate_Constraint;
  update_columns?: Array<Wui_Affiliate_Update_Column>;
  where?: InputMaybe<Wui_Affiliate_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_Affiliate". */
export type Wui_Affiliate_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_a?: InputMaybe<Order_By>;
  level_aa?: InputMaybe<Order_By>;
  pro?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_Affiliate */
export type Wui_Affiliate_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "WUI_Affiliate" */
export enum Wui_Affiliate_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LevelA = 'level_a',
  /** column name */
  LevelAa = 'level_aa',
  /** column name */
  Pro = 'pro',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "WUI_Affiliate" */
export type Wui_Affiliate_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  level_a?: InputMaybe<Scalars['Int']>;
  level_aa?: InputMaybe<Scalars['Int']>;
  pro?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_Affiliate_Stddev_Fields = {
  __typename?: 'WUI_Affiliate_stddev_fields';
  level_a?: Maybe<Scalars['Float']>;
  level_aa?: Maybe<Scalars['Float']>;
  pro?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_Affiliate_Stddev_Pop_Fields = {
  __typename?: 'WUI_Affiliate_stddev_pop_fields';
  level_a?: Maybe<Scalars['Float']>;
  level_aa?: Maybe<Scalars['Float']>;
  pro?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_Affiliate_Stddev_Samp_Fields = {
  __typename?: 'WUI_Affiliate_stddev_samp_fields';
  level_a?: Maybe<Scalars['Float']>;
  level_aa?: Maybe<Scalars['Float']>;
  pro?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WUI_Affiliate" */
export type Wui_Affiliate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_Affiliate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_Affiliate_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  level_a?: InputMaybe<Scalars['Int']>;
  level_aa?: InputMaybe<Scalars['Int']>;
  pro?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_Affiliate_Sum_Fields = {
  __typename?: 'WUI_Affiliate_sum_fields';
  level_a?: Maybe<Scalars['Int']>;
  level_aa?: Maybe<Scalars['Int']>;
  pro?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_Affiliate" */
export enum Wui_Affiliate_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LevelA = 'level_a',
  /** column name */
  LevelAa = 'level_aa',
  /** column name */
  Pro = 'pro',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Wui_Affiliate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_Affiliate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_Affiliate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_Affiliate_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_Affiliate_Var_Pop_Fields = {
  __typename?: 'WUI_Affiliate_var_pop_fields';
  level_a?: Maybe<Scalars['Float']>;
  level_aa?: Maybe<Scalars['Float']>;
  pro?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_Affiliate_Var_Samp_Fields = {
  __typename?: 'WUI_Affiliate_var_samp_fields';
  level_a?: Maybe<Scalars['Float']>;
  level_aa?: Maybe<Scalars['Float']>;
  pro?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_Affiliate_Variance_Fields = {
  __typename?: 'WUI_Affiliate_variance_fields';
  level_a?: Maybe<Scalars['Float']>;
  level_aa?: Maybe<Scalars['Float']>;
  pro?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_AuthProvider" */
export type Wui_AuthProvider = {
  __typename?: 'WUI_AuthProvider';
  /** An object relationship */
  WUI_User: Wui_User;
  created_at: Scalars['timestamptz'];
  external_id: Scalars['String'];
  id: Scalars['bigint'];
  refresh_token?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user_id: Scalars['bigint'];
  username?: Maybe<Scalars['String']>;
};

/** aggregated selection of "WUI_AuthProvider" */
export type Wui_AuthProvider_Aggregate = {
  __typename?: 'WUI_AuthProvider_aggregate';
  aggregate?: Maybe<Wui_AuthProvider_Aggregate_Fields>;
  nodes: Array<Wui_AuthProvider>;
};

/** aggregate fields of "WUI_AuthProvider" */
export type Wui_AuthProvider_Aggregate_Fields = {
  __typename?: 'WUI_AuthProvider_aggregate_fields';
  avg?: Maybe<Wui_AuthProvider_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_AuthProvider_Max_Fields>;
  min?: Maybe<Wui_AuthProvider_Min_Fields>;
  stddev?: Maybe<Wui_AuthProvider_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_AuthProvider_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_AuthProvider_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_AuthProvider_Sum_Fields>;
  var_pop?: Maybe<Wui_AuthProvider_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_AuthProvider_Var_Samp_Fields>;
  variance?: Maybe<Wui_AuthProvider_Variance_Fields>;
};


/** aggregate fields of "WUI_AuthProvider" */
export type Wui_AuthProvider_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_AuthProvider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_AuthProvider_Avg_Fields = {
  __typename?: 'WUI_AuthProvider_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_AuthProvider". All fields are combined with a logical 'AND'. */
export type Wui_AuthProvider_Bool_Exp = {
  WUI_User?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_AuthProvider_Bool_Exp>>;
  _not?: InputMaybe<Wui_AuthProvider_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_AuthProvider_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  refresh_token?: InputMaybe<String_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_AuthProvider" */
export enum Wui_AuthProvider_Constraint {
  /** unique or primary key constraint on columns "external_id" */
  WuiAuthProviderExternalIdKey = 'WUI_AuthProvider_external_id_key',
  /** unique or primary key constraint on columns "user_id" */
  WuiAuthProviderUserIdKey = 'WUI_AuthProvider_user_id_key',
  /** unique or primary key constraint on columns "id" */
  WuiAuthSocialMediaPkey = 'WUI_AuthSocialMedia_pkey'
}

/** input type for incrementing numeric columns in table "WUI_AuthProvider" */
export type Wui_AuthProvider_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_AuthProvider" */
export type Wui_AuthProvider_Insert_Input = {
  WUI_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_AuthProvider_Max_Fields = {
  __typename?: 'WUI_AuthProvider_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  refresh_token?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_AuthProvider_Min_Fields = {
  __typename?: 'WUI_AuthProvider_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  refresh_token?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_AuthProvider" */
export type Wui_AuthProvider_Mutation_Response = {
  __typename?: 'WUI_AuthProvider_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_AuthProvider>;
};

/** input type for inserting object relation for remote table "WUI_AuthProvider" */
export type Wui_AuthProvider_Obj_Rel_Insert_Input = {
  data: Wui_AuthProvider_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_AuthProvider_On_Conflict>;
};

/** on_conflict condition type for table "WUI_AuthProvider" */
export type Wui_AuthProvider_On_Conflict = {
  constraint: Wui_AuthProvider_Constraint;
  update_columns?: Array<Wui_AuthProvider_Update_Column>;
  where?: InputMaybe<Wui_AuthProvider_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_AuthProvider". */
export type Wui_AuthProvider_Order_By = {
  WUI_User?: InputMaybe<Wui_User_Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_AuthProvider */
export type Wui_AuthProvider_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_AuthProvider" */
export enum Wui_AuthProvider_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  Token = 'token',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "WUI_AuthProvider" */
export type Wui_AuthProvider_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Wui_AuthProvider_Stddev_Fields = {
  __typename?: 'WUI_AuthProvider_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_AuthProvider_Stddev_Pop_Fields = {
  __typename?: 'WUI_AuthProvider_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_AuthProvider_Stddev_Samp_Fields = {
  __typename?: 'WUI_AuthProvider_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WUI_AuthProvider" */
export type Wui_AuthProvider_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_AuthProvider_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_AuthProvider_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Wui_AuthProvider_Sum_Fields = {
  __typename?: 'WUI_AuthProvider_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_AuthProvider" */
export enum Wui_AuthProvider_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  Token = 'token',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Username = 'username'
}

export type Wui_AuthProvider_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_AuthProvider_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_AuthProvider_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_AuthProvider_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_AuthProvider_Var_Pop_Fields = {
  __typename?: 'WUI_AuthProvider_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_AuthProvider_Var_Samp_Fields = {
  __typename?: 'WUI_AuthProvider_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_AuthProvider_Variance_Fields = {
  __typename?: 'WUI_AuthProvider_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_AuthSession" */
export type Wui_AuthSession = {
  __typename?: 'WUI_AuthSession';
  /** An object relationship */
  WUI_User: Wui_User;
  expires?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  sessionToken: Scalars['String'];
  userId: Scalars['bigint'];
};

/** aggregated selection of "WUI_AuthSession" */
export type Wui_AuthSession_Aggregate = {
  __typename?: 'WUI_AuthSession_aggregate';
  aggregate?: Maybe<Wui_AuthSession_Aggregate_Fields>;
  nodes: Array<Wui_AuthSession>;
};

export type Wui_AuthSession_Aggregate_Bool_Exp = {
  count?: InputMaybe<Wui_AuthSession_Aggregate_Bool_Exp_Count>;
};

export type Wui_AuthSession_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wui_AuthSession_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_AuthSession_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WUI_AuthSession" */
export type Wui_AuthSession_Aggregate_Fields = {
  __typename?: 'WUI_AuthSession_aggregate_fields';
  avg?: Maybe<Wui_AuthSession_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_AuthSession_Max_Fields>;
  min?: Maybe<Wui_AuthSession_Min_Fields>;
  stddev?: Maybe<Wui_AuthSession_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_AuthSession_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_AuthSession_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_AuthSession_Sum_Fields>;
  var_pop?: Maybe<Wui_AuthSession_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_AuthSession_Var_Samp_Fields>;
  variance?: Maybe<Wui_AuthSession_Variance_Fields>;
};


/** aggregate fields of "WUI_AuthSession" */
export type Wui_AuthSession_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_AuthSession_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_AuthSession" */
export type Wui_AuthSession_Aggregate_Order_By = {
  avg?: InputMaybe<Wui_AuthSession_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wui_AuthSession_Max_Order_By>;
  min?: InputMaybe<Wui_AuthSession_Min_Order_By>;
  stddev?: InputMaybe<Wui_AuthSession_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wui_AuthSession_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wui_AuthSession_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wui_AuthSession_Sum_Order_By>;
  var_pop?: InputMaybe<Wui_AuthSession_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wui_AuthSession_Var_Samp_Order_By>;
  variance?: InputMaybe<Wui_AuthSession_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_AuthSession" */
export type Wui_AuthSession_Arr_Rel_Insert_Input = {
  data: Array<Wui_AuthSession_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_AuthSession_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_AuthSession_Avg_Fields = {
  __typename?: 'WUI_AuthSession_avg_fields';
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_AuthSession" */
export type Wui_AuthSession_Avg_Order_By = {
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_AuthSession". All fields are combined with a logical 'AND'. */
export type Wui_AuthSession_Bool_Exp = {
  WUI_User?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_AuthSession_Bool_Exp>>;
  _not?: InputMaybe<Wui_AuthSession_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_AuthSession_Bool_Exp>>;
  expires?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  sessionToken?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_AuthSession" */
export enum Wui_AuthSession_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiAuthsessionPkey = 'wui_authsession_pkey'
}

/** input type for incrementing numeric columns in table "WUI_AuthSession" */
export type Wui_AuthSession_Inc_Input = {
  userId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_AuthSession" */
export type Wui_AuthSession_Insert_Input = {
  WUI_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  expires?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_AuthSession_Max_Fields = {
  __typename?: 'WUI_AuthSession_max_fields';
  expires?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sessionToken?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_AuthSession" */
export type Wui_AuthSession_Max_Order_By = {
  expires?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sessionToken?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_AuthSession_Min_Fields = {
  __typename?: 'WUI_AuthSession_min_fields';
  expires?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sessionToken?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_AuthSession" */
export type Wui_AuthSession_Min_Order_By = {
  expires?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sessionToken?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WUI_AuthSession" */
export type Wui_AuthSession_Mutation_Response = {
  __typename?: 'WUI_AuthSession_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_AuthSession>;
};

/** on_conflict condition type for table "WUI_AuthSession" */
export type Wui_AuthSession_On_Conflict = {
  constraint: Wui_AuthSession_Constraint;
  update_columns?: Array<Wui_AuthSession_Update_Column>;
  where?: InputMaybe<Wui_AuthSession_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_AuthSession". */
export type Wui_AuthSession_Order_By = {
  WUI_User?: InputMaybe<Wui_User_Order_By>;
  expires?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sessionToken?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_AuthSession */
export type Wui_AuthSession_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "WUI_AuthSession" */
export enum Wui_AuthSession_Select_Column {
  /** column name */
  Expires = 'expires',
  /** column name */
  Id = 'id',
  /** column name */
  SessionToken = 'sessionToken',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "WUI_AuthSession" */
export type Wui_AuthSession_Set_Input = {
  expires?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_AuthSession_Stddev_Fields = {
  __typename?: 'WUI_AuthSession_stddev_fields';
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_AuthSession" */
export type Wui_AuthSession_Stddev_Order_By = {
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_AuthSession_Stddev_Pop_Fields = {
  __typename?: 'WUI_AuthSession_stddev_pop_fields';
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_AuthSession" */
export type Wui_AuthSession_Stddev_Pop_Order_By = {
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_AuthSession_Stddev_Samp_Fields = {
  __typename?: 'WUI_AuthSession_stddev_samp_fields';
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_AuthSession" */
export type Wui_AuthSession_Stddev_Samp_Order_By = {
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WUI_AuthSession" */
export type Wui_AuthSession_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_AuthSession_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_AuthSession_Stream_Cursor_Value_Input = {
  expires?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_AuthSession_Sum_Fields = {
  __typename?: 'WUI_AuthSession_sum_fields';
  userId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_AuthSession" */
export type Wui_AuthSession_Sum_Order_By = {
  userId?: InputMaybe<Order_By>;
};

/** update columns of table "WUI_AuthSession" */
export enum Wui_AuthSession_Update_Column {
  /** column name */
  Expires = 'expires',
  /** column name */
  Id = 'id',
  /** column name */
  SessionToken = 'sessionToken',
  /** column name */
  UserId = 'userId'
}

export type Wui_AuthSession_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_AuthSession_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_AuthSession_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_AuthSession_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_AuthSession_Var_Pop_Fields = {
  __typename?: 'WUI_AuthSession_var_pop_fields';
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_AuthSession" */
export type Wui_AuthSession_Var_Pop_Order_By = {
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_AuthSession_Var_Samp_Fields = {
  __typename?: 'WUI_AuthSession_var_samp_fields';
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_AuthSession" */
export type Wui_AuthSession_Var_Samp_Order_By = {
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_AuthSession_Variance_Fields = {
  __typename?: 'WUI_AuthSession_variance_fields';
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_AuthSession" */
export type Wui_AuthSession_Variance_Order_By = {
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "WUI_ChatRoom" */
export type Wui_ChatRoom = {
  __typename?: 'WUI_ChatRoom';
  /** An object relationship */
  LastMessage?: Maybe<Wui_ChatRoomMessage>;
  /** An array relationship */
  WUI_ChatRoomMessages: Array<Wui_ChatRoomMessage>;
  /** An aggregate relationship */
  WUI_ChatRoomMessages_aggregate: Wui_ChatRoomMessage_Aggregate;
  /** An array relationship */
  WUI_ChatRoomOrders: Array<Wui_ChatRoomOrder>;
  /** An aggregate relationship */
  WUI_ChatRoomOrders_aggregate: Wui_ChatRoomOrder_Aggregate;
  /** An object relationship */
  WUI_ChatRoomType: Wui_ChatRoomType;
  /** An array relationship */
  WUI_ChatRoomUsers: Array<Wui_ChatRoomUser>;
  /** An aggregate relationship */
  WUI_ChatRoomUsers_aggregate: Wui_ChatRoomUser_Aggregate;
  id: Scalars['bigint'];
  last_message_id?: Maybe<Scalars['bigint']>;
  type: Wui_ChatRoomType_Enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "WUI_ChatRoom" */
export type Wui_ChatRoomWui_ChatRoomMessagesArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomMessage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomMessage_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomMessage_Bool_Exp>;
};


/** columns and relationships of "WUI_ChatRoom" */
export type Wui_ChatRoomWui_ChatRoomMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomMessage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomMessage_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomMessage_Bool_Exp>;
};


/** columns and relationships of "WUI_ChatRoom" */
export type Wui_ChatRoomWui_ChatRoomOrdersArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomOrder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomOrder_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomOrder_Bool_Exp>;
};


/** columns and relationships of "WUI_ChatRoom" */
export type Wui_ChatRoomWui_ChatRoomOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomOrder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomOrder_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomOrder_Bool_Exp>;
};


/** columns and relationships of "WUI_ChatRoom" */
export type Wui_ChatRoomWui_ChatRoomUsersArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomUser_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomUser_Bool_Exp>;
};


/** columns and relationships of "WUI_ChatRoom" */
export type Wui_ChatRoomWui_ChatRoomUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomUser_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomUser_Bool_Exp>;
};

/** columns and relationships of "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage = {
  __typename?: 'WUI_ChatRoomMessage';
  /** An object relationship */
  WUI_ChatRoom: Wui_ChatRoom;
  /** An object relationship */
  WUI_User: Wui_User;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id: Scalars['bigint'];
  message: Scalars['String'];
  room_id: Scalars['bigint'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id: Scalars['bigint'];
};


/** columns and relationships of "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessageDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Aggregate = {
  __typename?: 'WUI_ChatRoomMessage_aggregate';
  aggregate?: Maybe<Wui_ChatRoomMessage_Aggregate_Fields>;
  nodes: Array<Wui_ChatRoomMessage>;
};

export type Wui_ChatRoomMessage_Aggregate_Bool_Exp = {
  count?: InputMaybe<Wui_ChatRoomMessage_Aggregate_Bool_Exp_Count>;
};

export type Wui_ChatRoomMessage_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wui_ChatRoomMessage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_ChatRoomMessage_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Aggregate_Fields = {
  __typename?: 'WUI_ChatRoomMessage_aggregate_fields';
  avg?: Maybe<Wui_ChatRoomMessage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_ChatRoomMessage_Max_Fields>;
  min?: Maybe<Wui_ChatRoomMessage_Min_Fields>;
  stddev?: Maybe<Wui_ChatRoomMessage_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_ChatRoomMessage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_ChatRoomMessage_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_ChatRoomMessage_Sum_Fields>;
  var_pop?: Maybe<Wui_ChatRoomMessage_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_ChatRoomMessage_Var_Samp_Fields>;
  variance?: Maybe<Wui_ChatRoomMessage_Variance_Fields>;
};


/** aggregate fields of "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_ChatRoomMessage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Aggregate_Order_By = {
  avg?: InputMaybe<Wui_ChatRoomMessage_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wui_ChatRoomMessage_Max_Order_By>;
  min?: InputMaybe<Wui_ChatRoomMessage_Min_Order_By>;
  stddev?: InputMaybe<Wui_ChatRoomMessage_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wui_ChatRoomMessage_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wui_ChatRoomMessage_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wui_ChatRoomMessage_Sum_Order_By>;
  var_pop?: InputMaybe<Wui_ChatRoomMessage_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wui_ChatRoomMessage_Var_Samp_Order_By>;
  variance?: InputMaybe<Wui_ChatRoomMessage_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Wui_ChatRoomMessage_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Arr_Rel_Insert_Input = {
  data: Array<Wui_ChatRoomMessage_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_ChatRoomMessage_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_ChatRoomMessage_Avg_Fields = {
  __typename?: 'WUI_ChatRoomMessage_avg_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_ChatRoomMessage". All fields are combined with a logical 'AND'. */
export type Wui_ChatRoomMessage_Bool_Exp = {
  WUI_ChatRoom?: InputMaybe<Wui_ChatRoom_Bool_Exp>;
  WUI_User?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_ChatRoomMessage_Bool_Exp>>;
  _not?: InputMaybe<Wui_ChatRoomMessage_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_ChatRoomMessage_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  room_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_ChatRoomMessage" */
export enum Wui_ChatRoomMessage_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiChatRoomMessagePkey = 'WUI_ChatRoomMessage_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Wui_ChatRoomMessage_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Wui_ChatRoomMessage_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Wui_ChatRoomMessage_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  room_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Insert_Input = {
  WUI_ChatRoom?: InputMaybe<Wui_ChatRoom_Obj_Rel_Insert_Input>;
  WUI_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  message?: InputMaybe<Scalars['String']>;
  room_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_ChatRoomMessage_Max_Fields = {
  __typename?: 'WUI_ChatRoomMessage_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  room_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_ChatRoomMessage_Min_Fields = {
  __typename?: 'WUI_ChatRoomMessage_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  room_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Mutation_Response = {
  __typename?: 'WUI_ChatRoomMessage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_ChatRoomMessage>;
};

/** input type for inserting object relation for remote table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Obj_Rel_Insert_Input = {
  data: Wui_ChatRoomMessage_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_ChatRoomMessage_On_Conflict>;
};

/** on_conflict condition type for table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_On_Conflict = {
  constraint: Wui_ChatRoomMessage_Constraint;
  update_columns?: Array<Wui_ChatRoomMessage_Update_Column>;
  where?: InputMaybe<Wui_ChatRoomMessage_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_ChatRoomMessage". */
export type Wui_ChatRoomMessage_Order_By = {
  WUI_ChatRoom?: InputMaybe<Wui_ChatRoom_Order_By>;
  WUI_User?: InputMaybe<Wui_User_Order_By>;
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_ChatRoomMessage */
export type Wui_ChatRoomMessage_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Wui_ChatRoomMessage_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "WUI_ChatRoomMessage" */
export enum Wui_ChatRoomMessage_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  message?: InputMaybe<Scalars['String']>;
  room_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_ChatRoomMessage_Stddev_Fields = {
  __typename?: 'WUI_ChatRoomMessage_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_ChatRoomMessage_Stddev_Pop_Fields = {
  __typename?: 'WUI_ChatRoomMessage_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_ChatRoomMessage_Stddev_Samp_Fields = {
  __typename?: 'WUI_ChatRoomMessage_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_ChatRoomMessage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_ChatRoomMessage_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  message?: InputMaybe<Scalars['String']>;
  room_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_ChatRoomMessage_Sum_Fields = {
  __typename?: 'WUI_ChatRoomMessage_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "WUI_ChatRoomMessage" */
export enum Wui_ChatRoomMessage_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Wui_ChatRoomMessage_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Wui_ChatRoomMessage_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Wui_ChatRoomMessage_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Wui_ChatRoomMessage_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Wui_ChatRoomMessage_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_ChatRoomMessage_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Wui_ChatRoomMessage_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_ChatRoomMessage_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_ChatRoomMessage_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_ChatRoomMessage_Var_Pop_Fields = {
  __typename?: 'WUI_ChatRoomMessage_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_ChatRoomMessage_Var_Samp_Fields = {
  __typename?: 'WUI_ChatRoomMessage_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_ChatRoomMessage_Variance_Fields = {
  __typename?: 'WUI_ChatRoomMessage_variance_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder = {
  __typename?: 'WUI_ChatRoomOrder';
  /** An object relationship */
  WUI_ChatRoom: Wui_ChatRoom;
  /** An object relationship */
  WUI_Order: Wui_Order;
  id: Scalars['bigint'];
  order_id: Scalars['bigint'];
  room_id: Scalars['bigint'];
};

/** aggregated selection of "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Aggregate = {
  __typename?: 'WUI_ChatRoomOrder_aggregate';
  aggregate?: Maybe<Wui_ChatRoomOrder_Aggregate_Fields>;
  nodes: Array<Wui_ChatRoomOrder>;
};

export type Wui_ChatRoomOrder_Aggregate_Bool_Exp = {
  count?: InputMaybe<Wui_ChatRoomOrder_Aggregate_Bool_Exp_Count>;
};

export type Wui_ChatRoomOrder_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wui_ChatRoomOrder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_ChatRoomOrder_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Aggregate_Fields = {
  __typename?: 'WUI_ChatRoomOrder_aggregate_fields';
  avg?: Maybe<Wui_ChatRoomOrder_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_ChatRoomOrder_Max_Fields>;
  min?: Maybe<Wui_ChatRoomOrder_Min_Fields>;
  stddev?: Maybe<Wui_ChatRoomOrder_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_ChatRoomOrder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_ChatRoomOrder_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_ChatRoomOrder_Sum_Fields>;
  var_pop?: Maybe<Wui_ChatRoomOrder_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_ChatRoomOrder_Var_Samp_Fields>;
  variance?: Maybe<Wui_ChatRoomOrder_Variance_Fields>;
};


/** aggregate fields of "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_ChatRoomOrder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Aggregate_Order_By = {
  avg?: InputMaybe<Wui_ChatRoomOrder_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wui_ChatRoomOrder_Max_Order_By>;
  min?: InputMaybe<Wui_ChatRoomOrder_Min_Order_By>;
  stddev?: InputMaybe<Wui_ChatRoomOrder_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wui_ChatRoomOrder_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wui_ChatRoomOrder_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wui_ChatRoomOrder_Sum_Order_By>;
  var_pop?: InputMaybe<Wui_ChatRoomOrder_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wui_ChatRoomOrder_Var_Samp_Order_By>;
  variance?: InputMaybe<Wui_ChatRoomOrder_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Arr_Rel_Insert_Input = {
  data: Array<Wui_ChatRoomOrder_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_ChatRoomOrder_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_ChatRoomOrder_Avg_Fields = {
  __typename?: 'WUI_ChatRoomOrder_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_ChatRoomOrder". All fields are combined with a logical 'AND'. */
export type Wui_ChatRoomOrder_Bool_Exp = {
  WUI_ChatRoom?: InputMaybe<Wui_ChatRoom_Bool_Exp>;
  WUI_Order?: InputMaybe<Wui_Order_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_ChatRoomOrder_Bool_Exp>>;
  _not?: InputMaybe<Wui_ChatRoomOrder_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_ChatRoomOrder_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  order_id?: InputMaybe<Bigint_Comparison_Exp>;
  room_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_ChatRoomOrder" */
export enum Wui_ChatRoomOrder_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiChatRoomOrderPkey = 'WUI_ChatRoomOrder_pkey'
}

/** input type for incrementing numeric columns in table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  room_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Insert_Input = {
  WUI_ChatRoom?: InputMaybe<Wui_ChatRoom_Obj_Rel_Insert_Input>;
  WUI_Order?: InputMaybe<Wui_Order_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  room_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_ChatRoomOrder_Max_Fields = {
  __typename?: 'WUI_ChatRoomOrder_max_fields';
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_ChatRoomOrder_Min_Fields = {
  __typename?: 'WUI_ChatRoomOrder_min_fields';
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Mutation_Response = {
  __typename?: 'WUI_ChatRoomOrder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_ChatRoomOrder>;
};

/** on_conflict condition type for table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_On_Conflict = {
  constraint: Wui_ChatRoomOrder_Constraint;
  update_columns?: Array<Wui_ChatRoomOrder_Update_Column>;
  where?: InputMaybe<Wui_ChatRoomOrder_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_ChatRoomOrder". */
export type Wui_ChatRoomOrder_Order_By = {
  WUI_ChatRoom?: InputMaybe<Wui_ChatRoom_Order_By>;
  WUI_Order?: InputMaybe<Wui_Order_Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_ChatRoomOrder */
export type Wui_ChatRoomOrder_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_ChatRoomOrder" */
export enum Wui_ChatRoomOrder_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  RoomId = 'room_id'
}

/** input type for updating data in table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  room_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_ChatRoomOrder_Stddev_Fields = {
  __typename?: 'WUI_ChatRoomOrder_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_ChatRoomOrder_Stddev_Pop_Fields = {
  __typename?: 'WUI_ChatRoomOrder_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_ChatRoomOrder_Stddev_Samp_Fields = {
  __typename?: 'WUI_ChatRoomOrder_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_ChatRoomOrder_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_ChatRoomOrder_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  room_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_ChatRoomOrder_Sum_Fields = {
  __typename?: 'WUI_ChatRoomOrder_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** update columns of table "WUI_ChatRoomOrder" */
export enum Wui_ChatRoomOrder_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  RoomId = 'room_id'
}

export type Wui_ChatRoomOrder_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_ChatRoomOrder_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_ChatRoomOrder_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_ChatRoomOrder_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_ChatRoomOrder_Var_Pop_Fields = {
  __typename?: 'WUI_ChatRoomOrder_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_ChatRoomOrder_Var_Samp_Fields = {
  __typename?: 'WUI_ChatRoomOrder_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_ChatRoomOrder_Variance_Fields = {
  __typename?: 'WUI_ChatRoomOrder_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "WUI_ChatRoomType" */
export type Wui_ChatRoomType = {
  __typename?: 'WUI_ChatRoomType';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_ChatRoomType" */
export type Wui_ChatRoomType_Aggregate = {
  __typename?: 'WUI_ChatRoomType_aggregate';
  aggregate?: Maybe<Wui_ChatRoomType_Aggregate_Fields>;
  nodes: Array<Wui_ChatRoomType>;
};

/** aggregate fields of "WUI_ChatRoomType" */
export type Wui_ChatRoomType_Aggregate_Fields = {
  __typename?: 'WUI_ChatRoomType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_ChatRoomType_Max_Fields>;
  min?: Maybe<Wui_ChatRoomType_Min_Fields>;
};


/** aggregate fields of "WUI_ChatRoomType" */
export type Wui_ChatRoomType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_ChatRoomType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_ChatRoomType". All fields are combined with a logical 'AND'. */
export type Wui_ChatRoomType_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_ChatRoomType_Bool_Exp>>;
  _not?: InputMaybe<Wui_ChatRoomType_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_ChatRoomType_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_ChatRoomType" */
export enum Wui_ChatRoomType_Constraint {
  /** unique or primary key constraint on columns "value" */
  WuiChatRoomTypePkey = 'WUI_ChatRoomType_pkey'
}

export enum Wui_ChatRoomType_Enum {
  Conversation = 'conversation',
  Order = 'order'
}

/** Boolean expression to compare columns of type "WUI_ChatRoomType_enum". All fields are combined with logical 'AND'. */
export type Wui_ChatRoomType_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Wui_ChatRoomType_Enum>;
  _in?: InputMaybe<Array<Wui_ChatRoomType_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Wui_ChatRoomType_Enum>;
  _nin?: InputMaybe<Array<Wui_ChatRoomType_Enum>>;
};

/** input type for inserting data into table "WUI_ChatRoomType" */
export type Wui_ChatRoomType_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_ChatRoomType_Max_Fields = {
  __typename?: 'WUI_ChatRoomType_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_ChatRoomType_Min_Fields = {
  __typename?: 'WUI_ChatRoomType_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_ChatRoomType" */
export type Wui_ChatRoomType_Mutation_Response = {
  __typename?: 'WUI_ChatRoomType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_ChatRoomType>;
};

/** input type for inserting object relation for remote table "WUI_ChatRoomType" */
export type Wui_ChatRoomType_Obj_Rel_Insert_Input = {
  data: Wui_ChatRoomType_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_ChatRoomType_On_Conflict>;
};

/** on_conflict condition type for table "WUI_ChatRoomType" */
export type Wui_ChatRoomType_On_Conflict = {
  constraint: Wui_ChatRoomType_Constraint;
  update_columns?: Array<Wui_ChatRoomType_Update_Column>;
  where?: InputMaybe<Wui_ChatRoomType_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_ChatRoomType". */
export type Wui_ChatRoomType_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_ChatRoomType */
export type Wui_ChatRoomType_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_ChatRoomType" */
export enum Wui_ChatRoomType_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_ChatRoomType" */
export type Wui_ChatRoomType_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "WUI_ChatRoomType" */
export type Wui_ChatRoomType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_ChatRoomType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_ChatRoomType_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "WUI_ChatRoomType" */
export enum Wui_ChatRoomType_Update_Column {
  /** column name */
  Value = 'value'
}

export type Wui_ChatRoomType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_ChatRoomType_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_ChatRoomType_Bool_Exp;
};

/** columns and relationships of "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser = {
  __typename?: 'WUI_ChatRoomUser';
  /** An object relationship */
  WUI_ChatRoom: Wui_ChatRoom;
  /** An object relationship */
  WUI_User?: Maybe<Wui_User>;
  id: Scalars['bigint'];
  last_seen?: Maybe<Scalars['timestamp']>;
  last_typing?: Maybe<Scalars['timestamptz']>;
  room_id: Scalars['bigint'];
  seen?: Maybe<Scalars['Boolean']>;
  typing?: Maybe<Scalars['Boolean']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Aggregate = {
  __typename?: 'WUI_ChatRoomUser_aggregate';
  aggregate?: Maybe<Wui_ChatRoomUser_Aggregate_Fields>;
  nodes: Array<Wui_ChatRoomUser>;
};

export type Wui_ChatRoomUser_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Wui_ChatRoomUser_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Wui_ChatRoomUser_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Wui_ChatRoomUser_Aggregate_Bool_Exp_Count>;
};

export type Wui_ChatRoomUser_Aggregate_Bool_Exp_Bool_And = {
  arguments: Wui_ChatRoomUser_Select_Column_Wui_ChatRoomUser_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_ChatRoomUser_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Wui_ChatRoomUser_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Wui_ChatRoomUser_Select_Column_Wui_ChatRoomUser_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_ChatRoomUser_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Wui_ChatRoomUser_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wui_ChatRoomUser_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_ChatRoomUser_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Aggregate_Fields = {
  __typename?: 'WUI_ChatRoomUser_aggregate_fields';
  avg?: Maybe<Wui_ChatRoomUser_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_ChatRoomUser_Max_Fields>;
  min?: Maybe<Wui_ChatRoomUser_Min_Fields>;
  stddev?: Maybe<Wui_ChatRoomUser_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_ChatRoomUser_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_ChatRoomUser_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_ChatRoomUser_Sum_Fields>;
  var_pop?: Maybe<Wui_ChatRoomUser_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_ChatRoomUser_Var_Samp_Fields>;
  variance?: Maybe<Wui_ChatRoomUser_Variance_Fields>;
};


/** aggregate fields of "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_ChatRoomUser_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Aggregate_Order_By = {
  avg?: InputMaybe<Wui_ChatRoomUser_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wui_ChatRoomUser_Max_Order_By>;
  min?: InputMaybe<Wui_ChatRoomUser_Min_Order_By>;
  stddev?: InputMaybe<Wui_ChatRoomUser_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wui_ChatRoomUser_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wui_ChatRoomUser_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wui_ChatRoomUser_Sum_Order_By>;
  var_pop?: InputMaybe<Wui_ChatRoomUser_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wui_ChatRoomUser_Var_Samp_Order_By>;
  variance?: InputMaybe<Wui_ChatRoomUser_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Arr_Rel_Insert_Input = {
  data: Array<Wui_ChatRoomUser_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_ChatRoomUser_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_ChatRoomUser_Avg_Fields = {
  __typename?: 'WUI_ChatRoomUser_avg_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_ChatRoomUser". All fields are combined with a logical 'AND'. */
export type Wui_ChatRoomUser_Bool_Exp = {
  WUI_ChatRoom?: InputMaybe<Wui_ChatRoom_Bool_Exp>;
  WUI_User?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_ChatRoomUser_Bool_Exp>>;
  _not?: InputMaybe<Wui_ChatRoomUser_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_ChatRoomUser_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  last_seen?: InputMaybe<Timestamp_Comparison_Exp>;
  last_typing?: InputMaybe<Timestamptz_Comparison_Exp>;
  room_id?: InputMaybe<Bigint_Comparison_Exp>;
  seen?: InputMaybe<Boolean_Comparison_Exp>;
  typing?: InputMaybe<Boolean_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_ChatRoomUser" */
export enum Wui_ChatRoomUser_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiChatRoomUserPkey = 'WUI_ChatRoomUser_pkey',
  /** unique or primary key constraint on columns "user_id", "room_id" */
  WuiChatRoomUserRoomIdUserIdKey = 'WUI_ChatRoomUser_room_id_user_id_key'
}

/** input type for incrementing numeric columns in table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  room_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Insert_Input = {
  WUI_ChatRoom?: InputMaybe<Wui_ChatRoom_Obj_Rel_Insert_Input>;
  WUI_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  last_seen?: InputMaybe<Scalars['timestamp']>;
  last_typing?: InputMaybe<Scalars['timestamptz']>;
  room_id?: InputMaybe<Scalars['bigint']>;
  seen?: InputMaybe<Scalars['Boolean']>;
  typing?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_ChatRoomUser_Max_Fields = {
  __typename?: 'WUI_ChatRoomUser_max_fields';
  id?: Maybe<Scalars['bigint']>;
  last_seen?: Maybe<Scalars['timestamp']>;
  last_typing?: Maybe<Scalars['timestamptz']>;
  room_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  last_seen?: InputMaybe<Order_By>;
  last_typing?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_ChatRoomUser_Min_Fields = {
  __typename?: 'WUI_ChatRoomUser_min_fields';
  id?: Maybe<Scalars['bigint']>;
  last_seen?: Maybe<Scalars['timestamp']>;
  last_typing?: Maybe<Scalars['timestamptz']>;
  room_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  last_seen?: InputMaybe<Order_By>;
  last_typing?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Mutation_Response = {
  __typename?: 'WUI_ChatRoomUser_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_ChatRoomUser>;
};

/** on_conflict condition type for table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_On_Conflict = {
  constraint: Wui_ChatRoomUser_Constraint;
  update_columns?: Array<Wui_ChatRoomUser_Update_Column>;
  where?: InputMaybe<Wui_ChatRoomUser_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_ChatRoomUser". */
export type Wui_ChatRoomUser_Order_By = {
  WUI_ChatRoom?: InputMaybe<Wui_ChatRoom_Order_By>;
  WUI_User?: InputMaybe<Wui_User_Order_By>;
  id?: InputMaybe<Order_By>;
  last_seen?: InputMaybe<Order_By>;
  last_typing?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  seen?: InputMaybe<Order_By>;
  typing?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_ChatRoomUser */
export type Wui_ChatRoomUser_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_ChatRoomUser" */
export enum Wui_ChatRoomUser_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  LastTyping = 'last_typing',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  Seen = 'seen',
  /** column name */
  Typing = 'typing',
  /** column name */
  UserId = 'user_id'
}

/** select "WUI_ChatRoomUser_aggregate_bool_exp_bool_and_arguments_columns" columns of table "WUI_ChatRoomUser" */
export enum Wui_ChatRoomUser_Select_Column_Wui_ChatRoomUser_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Seen = 'seen',
  /** column name */
  Typing = 'typing'
}

/** select "WUI_ChatRoomUser_aggregate_bool_exp_bool_or_arguments_columns" columns of table "WUI_ChatRoomUser" */
export enum Wui_ChatRoomUser_Select_Column_Wui_ChatRoomUser_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Seen = 'seen',
  /** column name */
  Typing = 'typing'
}

/** input type for updating data in table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  last_seen?: InputMaybe<Scalars['timestamp']>;
  last_typing?: InputMaybe<Scalars['timestamptz']>;
  room_id?: InputMaybe<Scalars['bigint']>;
  seen?: InputMaybe<Scalars['Boolean']>;
  typing?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_ChatRoomUser_Stddev_Fields = {
  __typename?: 'WUI_ChatRoomUser_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_ChatRoomUser_Stddev_Pop_Fields = {
  __typename?: 'WUI_ChatRoomUser_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_ChatRoomUser_Stddev_Samp_Fields = {
  __typename?: 'WUI_ChatRoomUser_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_ChatRoomUser_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_ChatRoomUser_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  last_seen?: InputMaybe<Scalars['timestamp']>;
  last_typing?: InputMaybe<Scalars['timestamptz']>;
  room_id?: InputMaybe<Scalars['bigint']>;
  seen?: InputMaybe<Scalars['Boolean']>;
  typing?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_ChatRoomUser_Sum_Fields = {
  __typename?: 'WUI_ChatRoomUser_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "WUI_ChatRoomUser" */
export enum Wui_ChatRoomUser_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  LastTyping = 'last_typing',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  Seen = 'seen',
  /** column name */
  Typing = 'typing',
  /** column name */
  UserId = 'user_id'
}

export type Wui_ChatRoomUser_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_ChatRoomUser_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_ChatRoomUser_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_ChatRoomUser_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_ChatRoomUser_Var_Pop_Fields = {
  __typename?: 'WUI_ChatRoomUser_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_ChatRoomUser_Var_Samp_Fields = {
  __typename?: 'WUI_ChatRoomUser_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_ChatRoomUser_Variance_Fields = {
  __typename?: 'WUI_ChatRoomUser_variance_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregated selection of "WUI_ChatRoom" */
export type Wui_ChatRoom_Aggregate = {
  __typename?: 'WUI_ChatRoom_aggregate';
  aggregate?: Maybe<Wui_ChatRoom_Aggregate_Fields>;
  nodes: Array<Wui_ChatRoom>;
};

/** aggregate fields of "WUI_ChatRoom" */
export type Wui_ChatRoom_Aggregate_Fields = {
  __typename?: 'WUI_ChatRoom_aggregate_fields';
  avg?: Maybe<Wui_ChatRoom_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_ChatRoom_Max_Fields>;
  min?: Maybe<Wui_ChatRoom_Min_Fields>;
  stddev?: Maybe<Wui_ChatRoom_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_ChatRoom_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_ChatRoom_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_ChatRoom_Sum_Fields>;
  var_pop?: Maybe<Wui_ChatRoom_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_ChatRoom_Var_Samp_Fields>;
  variance?: Maybe<Wui_ChatRoom_Variance_Fields>;
};


/** aggregate fields of "WUI_ChatRoom" */
export type Wui_ChatRoom_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_ChatRoom_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_ChatRoom_Avg_Fields = {
  __typename?: 'WUI_ChatRoom_avg_fields';
  id?: Maybe<Scalars['Float']>;
  last_message_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_ChatRoom". All fields are combined with a logical 'AND'. */
export type Wui_ChatRoom_Bool_Exp = {
  LastMessage?: InputMaybe<Wui_ChatRoomMessage_Bool_Exp>;
  WUI_ChatRoomMessages?: InputMaybe<Wui_ChatRoomMessage_Bool_Exp>;
  WUI_ChatRoomMessages_aggregate?: InputMaybe<Wui_ChatRoomMessage_Aggregate_Bool_Exp>;
  WUI_ChatRoomOrders?: InputMaybe<Wui_ChatRoomOrder_Bool_Exp>;
  WUI_ChatRoomOrders_aggregate?: InputMaybe<Wui_ChatRoomOrder_Aggregate_Bool_Exp>;
  WUI_ChatRoomType?: InputMaybe<Wui_ChatRoomType_Bool_Exp>;
  WUI_ChatRoomUsers?: InputMaybe<Wui_ChatRoomUser_Bool_Exp>;
  WUI_ChatRoomUsers_aggregate?: InputMaybe<Wui_ChatRoomUser_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_ChatRoom_Bool_Exp>>;
  _not?: InputMaybe<Wui_ChatRoom_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_ChatRoom_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  last_message_id?: InputMaybe<Bigint_Comparison_Exp>;
  type?: InputMaybe<Wui_ChatRoomType_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_ChatRoom" */
export enum Wui_ChatRoom_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiChatRoomPkey = 'WUI_Chat_Room_pkey'
}

/** input type for incrementing numeric columns in table "WUI_ChatRoom" */
export type Wui_ChatRoom_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  last_message_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_ChatRoom" */
export type Wui_ChatRoom_Insert_Input = {
  LastMessage?: InputMaybe<Wui_ChatRoomMessage_Obj_Rel_Insert_Input>;
  WUI_ChatRoomMessages?: InputMaybe<Wui_ChatRoomMessage_Arr_Rel_Insert_Input>;
  WUI_ChatRoomOrders?: InputMaybe<Wui_ChatRoomOrder_Arr_Rel_Insert_Input>;
  WUI_ChatRoomType?: InputMaybe<Wui_ChatRoomType_Obj_Rel_Insert_Input>;
  WUI_ChatRoomUsers?: InputMaybe<Wui_ChatRoomUser_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  last_message_id?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<Wui_ChatRoomType_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Wui_ChatRoom_Max_Fields = {
  __typename?: 'WUI_ChatRoom_max_fields';
  id?: Maybe<Scalars['bigint']>;
  last_message_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Wui_ChatRoom_Min_Fields = {
  __typename?: 'WUI_ChatRoom_min_fields';
  id?: Maybe<Scalars['bigint']>;
  last_message_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "WUI_ChatRoom" */
export type Wui_ChatRoom_Mutation_Response = {
  __typename?: 'WUI_ChatRoom_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_ChatRoom>;
};

/** input type for inserting object relation for remote table "WUI_ChatRoom" */
export type Wui_ChatRoom_Obj_Rel_Insert_Input = {
  data: Wui_ChatRoom_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_ChatRoom_On_Conflict>;
};

/** on_conflict condition type for table "WUI_ChatRoom" */
export type Wui_ChatRoom_On_Conflict = {
  constraint: Wui_ChatRoom_Constraint;
  update_columns?: Array<Wui_ChatRoom_Update_Column>;
  where?: InputMaybe<Wui_ChatRoom_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_ChatRoom". */
export type Wui_ChatRoom_Order_By = {
  LastMessage?: InputMaybe<Wui_ChatRoomMessage_Order_By>;
  WUI_ChatRoomMessages_aggregate?: InputMaybe<Wui_ChatRoomMessage_Aggregate_Order_By>;
  WUI_ChatRoomOrders_aggregate?: InputMaybe<Wui_ChatRoomOrder_Aggregate_Order_By>;
  WUI_ChatRoomType?: InputMaybe<Wui_ChatRoomType_Order_By>;
  WUI_ChatRoomUsers_aggregate?: InputMaybe<Wui_ChatRoomUser_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  last_message_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_ChatRoom */
export type Wui_ChatRoom_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_ChatRoom" */
export enum Wui_ChatRoom_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LastMessageId = 'last_message_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "WUI_ChatRoom" */
export type Wui_ChatRoom_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  last_message_id?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<Wui_ChatRoomType_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Wui_ChatRoom_Stddev_Fields = {
  __typename?: 'WUI_ChatRoom_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  last_message_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_ChatRoom_Stddev_Pop_Fields = {
  __typename?: 'WUI_ChatRoom_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  last_message_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_ChatRoom_Stddev_Samp_Fields = {
  __typename?: 'WUI_ChatRoom_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  last_message_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WUI_ChatRoom" */
export type Wui_ChatRoom_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_ChatRoom_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_ChatRoom_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  last_message_id?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<Wui_ChatRoomType_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Wui_ChatRoom_Sum_Fields = {
  __typename?: 'WUI_ChatRoom_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  last_message_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_ChatRoom" */
export enum Wui_ChatRoom_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LastMessageId = 'last_message_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Wui_ChatRoom_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_ChatRoom_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_ChatRoom_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_ChatRoom_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_ChatRoom_Var_Pop_Fields = {
  __typename?: 'WUI_ChatRoom_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  last_message_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_ChatRoom_Var_Samp_Fields = {
  __typename?: 'WUI_ChatRoom_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  last_message_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_ChatRoom_Variance_Fields = {
  __typename?: 'WUI_ChatRoom_variance_fields';
  id?: Maybe<Scalars['Float']>;
  last_message_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_File" */
export type Wui_File = {
  __typename?: 'WUI_File';
  created_at: Scalars['timestamptz'];
  file_type: Wui_FileType_Enum;
  id: Scalars['bigint'];
  url: Scalars['String'];
};

/** columns and relationships of "WUI_FileType" */
export type Wui_FileType = {
  __typename?: 'WUI_FileType';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_FileType" */
export type Wui_FileType_Aggregate = {
  __typename?: 'WUI_FileType_aggregate';
  aggregate?: Maybe<Wui_FileType_Aggregate_Fields>;
  nodes: Array<Wui_FileType>;
};

/** aggregate fields of "WUI_FileType" */
export type Wui_FileType_Aggregate_Fields = {
  __typename?: 'WUI_FileType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_FileType_Max_Fields>;
  min?: Maybe<Wui_FileType_Min_Fields>;
};


/** aggregate fields of "WUI_FileType" */
export type Wui_FileType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_FileType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_FileType". All fields are combined with a logical 'AND'. */
export type Wui_FileType_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_FileType_Bool_Exp>>;
  _not?: InputMaybe<Wui_FileType_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_FileType_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_FileType" */
export enum Wui_FileType_Constraint {
  /** unique or primary key constraint on columns "value" */
  WuiFileTypeEnumPkey = 'WUI_File_Type_Enum_pkey'
}

export enum Wui_FileType_Enum {
  Document = 'document',
  Image = 'image',
  Video = 'video'
}

/** Boolean expression to compare columns of type "WUI_FileType_enum". All fields are combined with logical 'AND'. */
export type Wui_FileType_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Wui_FileType_Enum>;
  _in?: InputMaybe<Array<Wui_FileType_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Wui_FileType_Enum>;
  _nin?: InputMaybe<Array<Wui_FileType_Enum>>;
};

/** input type for inserting data into table "WUI_FileType" */
export type Wui_FileType_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_FileType_Max_Fields = {
  __typename?: 'WUI_FileType_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_FileType_Min_Fields = {
  __typename?: 'WUI_FileType_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_FileType" */
export type Wui_FileType_Mutation_Response = {
  __typename?: 'WUI_FileType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_FileType>;
};

/** on_conflict condition type for table "WUI_FileType" */
export type Wui_FileType_On_Conflict = {
  constraint: Wui_FileType_Constraint;
  update_columns?: Array<Wui_FileType_Update_Column>;
  where?: InputMaybe<Wui_FileType_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_FileType". */
export type Wui_FileType_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_FileType */
export type Wui_FileType_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_FileType" */
export enum Wui_FileType_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_FileType" */
export type Wui_FileType_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "WUI_FileType" */
export type Wui_FileType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_FileType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_FileType_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "WUI_FileType" */
export enum Wui_FileType_Update_Column {
  /** column name */
  Value = 'value'
}

export type Wui_FileType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_FileType_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_FileType_Bool_Exp;
};

/** aggregated selection of "WUI_File" */
export type Wui_File_Aggregate = {
  __typename?: 'WUI_File_aggregate';
  aggregate?: Maybe<Wui_File_Aggregate_Fields>;
  nodes: Array<Wui_File>;
};

/** aggregate fields of "WUI_File" */
export type Wui_File_Aggregate_Fields = {
  __typename?: 'WUI_File_aggregate_fields';
  avg?: Maybe<Wui_File_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_File_Max_Fields>;
  min?: Maybe<Wui_File_Min_Fields>;
  stddev?: Maybe<Wui_File_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_File_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_File_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_File_Sum_Fields>;
  var_pop?: Maybe<Wui_File_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_File_Var_Samp_Fields>;
  variance?: Maybe<Wui_File_Variance_Fields>;
};


/** aggregate fields of "WUI_File" */
export type Wui_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_File_Avg_Fields = {
  __typename?: 'WUI_File_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_File". All fields are combined with a logical 'AND'. */
export type Wui_File_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_File_Bool_Exp>>;
  _not?: InputMaybe<Wui_File_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_File_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_type?: InputMaybe<Wui_FileType_Enum_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_File" */
export enum Wui_File_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiFilePkey = 'WUI_File_pkey'
}

/** input type for incrementing numeric columns in table "WUI_File" */
export type Wui_File_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_File" */
export type Wui_File_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_type?: InputMaybe<Wui_FileType_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_File_Max_Fields = {
  __typename?: 'WUI_File_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_File_Min_Fields = {
  __typename?: 'WUI_File_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_File" */
export type Wui_File_Mutation_Response = {
  __typename?: 'WUI_File_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_File>;
};

/** input type for inserting object relation for remote table "WUI_File" */
export type Wui_File_Obj_Rel_Insert_Input = {
  data: Wui_File_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_File_On_Conflict>;
};

/** on_conflict condition type for table "WUI_File" */
export type Wui_File_On_Conflict = {
  constraint: Wui_File_Constraint;
  update_columns?: Array<Wui_File_Update_Column>;
  where?: InputMaybe<Wui_File_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_File". */
export type Wui_File_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_File */
export type Wui_File_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_File" */
export enum Wui_File_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Id = 'id',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "WUI_File" */
export type Wui_File_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_type?: InputMaybe<Wui_FileType_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Wui_File_Stddev_Fields = {
  __typename?: 'WUI_File_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_File_Stddev_Pop_Fields = {
  __typename?: 'WUI_File_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_File_Stddev_Samp_Fields = {
  __typename?: 'WUI_File_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WUI_File" */
export type Wui_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_File_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_type?: InputMaybe<Wui_FileType_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Wui_File_Sum_Fields = {
  __typename?: 'WUI_File_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_File" */
export enum Wui_File_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Id = 'id',
  /** column name */
  Url = 'url'
}

export type Wui_File_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_File_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_File_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_File_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_File_Var_Pop_Fields = {
  __typename?: 'WUI_File_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_File_Var_Samp_Fields = {
  __typename?: 'WUI_File_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_File_Variance_Fields = {
  __typename?: 'WUI_File_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_Notification" */
export type Wui_Notification = {
  __typename?: 'WUI_Notification';
  /** An object relationship */
  WUI_User: Wui_User;
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  message: Scalars['String'];
  metadata: Scalars['jsonb'];
  seen?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  user_id: Scalars['bigint'];
};


/** columns and relationships of "WUI_Notification" */
export type Wui_NotificationMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "WUI_Notification" */
export type Wui_Notification_Aggregate = {
  __typename?: 'WUI_Notification_aggregate';
  aggregate?: Maybe<Wui_Notification_Aggregate_Fields>;
  nodes: Array<Wui_Notification>;
};

export type Wui_Notification_Aggregate_Bool_Exp = {
  count?: InputMaybe<Wui_Notification_Aggregate_Bool_Exp_Count>;
};

export type Wui_Notification_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wui_Notification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_Notification_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WUI_Notification" */
export type Wui_Notification_Aggregate_Fields = {
  __typename?: 'WUI_Notification_aggregate_fields';
  avg?: Maybe<Wui_Notification_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_Notification_Max_Fields>;
  min?: Maybe<Wui_Notification_Min_Fields>;
  stddev?: Maybe<Wui_Notification_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_Notification_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_Notification_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_Notification_Sum_Fields>;
  var_pop?: Maybe<Wui_Notification_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_Notification_Var_Samp_Fields>;
  variance?: Maybe<Wui_Notification_Variance_Fields>;
};


/** aggregate fields of "WUI_Notification" */
export type Wui_Notification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_Notification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_Notification" */
export type Wui_Notification_Aggregate_Order_By = {
  avg?: InputMaybe<Wui_Notification_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wui_Notification_Max_Order_By>;
  min?: InputMaybe<Wui_Notification_Min_Order_By>;
  stddev?: InputMaybe<Wui_Notification_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wui_Notification_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wui_Notification_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wui_Notification_Sum_Order_By>;
  var_pop?: InputMaybe<Wui_Notification_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wui_Notification_Var_Samp_Order_By>;
  variance?: InputMaybe<Wui_Notification_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Wui_Notification_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "WUI_Notification" */
export type Wui_Notification_Arr_Rel_Insert_Input = {
  data: Array<Wui_Notification_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_Notification_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_Notification_Avg_Fields = {
  __typename?: 'WUI_Notification_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_Notification" */
export type Wui_Notification_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_Notification". All fields are combined with a logical 'AND'. */
export type Wui_Notification_Bool_Exp = {
  WUI_User?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_Notification_Bool_Exp>>;
  _not?: InputMaybe<Wui_Notification_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_Notification_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  seen?: InputMaybe<Timestamptz_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_Notification" */
export enum Wui_Notification_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiNotificationPkey = 'WUI_Notification_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Wui_Notification_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Wui_Notification_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Wui_Notification_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "WUI_Notification" */
export type Wui_Notification_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_Notification" */
export type Wui_Notification_Insert_Input = {
  WUI_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  message?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  seen?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_Notification_Max_Fields = {
  __typename?: 'WUI_Notification_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  seen?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_Notification" */
export type Wui_Notification_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  seen?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_Notification_Min_Fields = {
  __typename?: 'WUI_Notification_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  seen?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_Notification" */
export type Wui_Notification_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  seen?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WUI_Notification" */
export type Wui_Notification_Mutation_Response = {
  __typename?: 'WUI_Notification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_Notification>;
};

/** on_conflict condition type for table "WUI_Notification" */
export type Wui_Notification_On_Conflict = {
  constraint: Wui_Notification_Constraint;
  update_columns?: Array<Wui_Notification_Update_Column>;
  where?: InputMaybe<Wui_Notification_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_Notification". */
export type Wui_Notification_Order_By = {
  WUI_User?: InputMaybe<Wui_User_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  seen?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_Notification */
export type Wui_Notification_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Wui_Notification_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "WUI_Notification" */
export enum Wui_Notification_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Seen = 'seen',
  /** column name */
  Title = 'title',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "WUI_Notification" */
export type Wui_Notification_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  message?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  seen?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_Notification_Stddev_Fields = {
  __typename?: 'WUI_Notification_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_Notification" */
export type Wui_Notification_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_Notification_Stddev_Pop_Fields = {
  __typename?: 'WUI_Notification_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_Notification" */
export type Wui_Notification_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_Notification_Stddev_Samp_Fields = {
  __typename?: 'WUI_Notification_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_Notification" */
export type Wui_Notification_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WUI_Notification" */
export type Wui_Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_Notification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_Notification_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  message?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  seen?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_Notification_Sum_Fields = {
  __typename?: 'WUI_Notification_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_Notification" */
export type Wui_Notification_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "WUI_Notification" */
export enum Wui_Notification_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Seen = 'seen',
  /** column name */
  Title = 'title',
  /** column name */
  UserId = 'user_id'
}

export type Wui_Notification_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Wui_Notification_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Wui_Notification_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Wui_Notification_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Wui_Notification_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_Notification_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Wui_Notification_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_Notification_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_Notification_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_Notification_Var_Pop_Fields = {
  __typename?: 'WUI_Notification_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_Notification" */
export type Wui_Notification_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_Notification_Var_Samp_Fields = {
  __typename?: 'WUI_Notification_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_Notification" */
export type Wui_Notification_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_Notification_Variance_Fields = {
  __typename?: 'WUI_Notification_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_Notification" */
export type Wui_Notification_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "WUI_Order" */
export type Wui_Order = {
  __typename?: 'WUI_Order';
  /** An object relationship */
  WUI_Payment?: Maybe<Wui_Payment>;
  /** An array relationship */
  WUI_Payments: Array<Wui_Payment>;
  /** An aggregate relationship */
  WUI_Payments_aggregate: Wui_Payment_Aggregate;
  /** An object relationship */
  WUI_User_Buyer: Wui_User;
  /** An object relationship */
  WUI_User_Seller: Wui_User;
  buyer_id: Scalars['bigint'];
  closed_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['bigint'];
  description: Scalars['String'];
  id: Scalars['bigint'];
  items: Scalars['jsonb'];
  metadata?: Maybe<Scalars['jsonb']>;
  package_type: Wui_SellerPackageType_Enum;
  price: Scalars['numeric'];
  seller_id: Scalars['bigint'];
  status: Wui_OrderStatus_Enum;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "WUI_Order" */
export type Wui_OrderWui_PaymentsArgs = {
  distinct_on?: InputMaybe<Array<Wui_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Payment_Order_By>>;
  where?: InputMaybe<Wui_Payment_Bool_Exp>;
};


/** columns and relationships of "WUI_Order" */
export type Wui_OrderWui_Payments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Payment_Order_By>>;
  where?: InputMaybe<Wui_Payment_Bool_Exp>;
};


/** columns and relationships of "WUI_Order" */
export type Wui_OrderItemsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "WUI_Order" */
export type Wui_OrderMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "WUI_OrderReview" */
export type Wui_OrderReview = {
  __typename?: 'WUI_OrderReview';
  /** An object relationship */
  WUI_Order: Wui_Order;
  /** An object relationship */
  WUI_UserReview: Wui_UserReview;
  id: Scalars['bigint'];
  order_id: Scalars['bigint'];
  review_id: Scalars['bigint'];
};

/** aggregated selection of "WUI_OrderReview" */
export type Wui_OrderReview_Aggregate = {
  __typename?: 'WUI_OrderReview_aggregate';
  aggregate?: Maybe<Wui_OrderReview_Aggregate_Fields>;
  nodes: Array<Wui_OrderReview>;
};

/** aggregate fields of "WUI_OrderReview" */
export type Wui_OrderReview_Aggregate_Fields = {
  __typename?: 'WUI_OrderReview_aggregate_fields';
  avg?: Maybe<Wui_OrderReview_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_OrderReview_Max_Fields>;
  min?: Maybe<Wui_OrderReview_Min_Fields>;
  stddev?: Maybe<Wui_OrderReview_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_OrderReview_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_OrderReview_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_OrderReview_Sum_Fields>;
  var_pop?: Maybe<Wui_OrderReview_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_OrderReview_Var_Samp_Fields>;
  variance?: Maybe<Wui_OrderReview_Variance_Fields>;
};


/** aggregate fields of "WUI_OrderReview" */
export type Wui_OrderReview_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_OrderReview_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_OrderReview_Avg_Fields = {
  __typename?: 'WUI_OrderReview_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_OrderReview". All fields are combined with a logical 'AND'. */
export type Wui_OrderReview_Bool_Exp = {
  WUI_Order?: InputMaybe<Wui_Order_Bool_Exp>;
  WUI_UserReview?: InputMaybe<Wui_UserReview_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_OrderReview_Bool_Exp>>;
  _not?: InputMaybe<Wui_OrderReview_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_OrderReview_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  order_id?: InputMaybe<Bigint_Comparison_Exp>;
  review_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_OrderReview" */
export enum Wui_OrderReview_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiOrderReviewPkey = 'WUI_OrderReview_pkey'
}

/** input type for incrementing numeric columns in table "WUI_OrderReview" */
export type Wui_OrderReview_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  review_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_OrderReview" */
export type Wui_OrderReview_Insert_Input = {
  WUI_Order?: InputMaybe<Wui_Order_Obj_Rel_Insert_Input>;
  WUI_UserReview?: InputMaybe<Wui_UserReview_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  review_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_OrderReview_Max_Fields = {
  __typename?: 'WUI_OrderReview_max_fields';
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  review_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Wui_OrderReview_Min_Fields = {
  __typename?: 'WUI_OrderReview_min_fields';
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  review_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "WUI_OrderReview" */
export type Wui_OrderReview_Mutation_Response = {
  __typename?: 'WUI_OrderReview_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_OrderReview>;
};

/** on_conflict condition type for table "WUI_OrderReview" */
export type Wui_OrderReview_On_Conflict = {
  constraint: Wui_OrderReview_Constraint;
  update_columns?: Array<Wui_OrderReview_Update_Column>;
  where?: InputMaybe<Wui_OrderReview_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_OrderReview". */
export type Wui_OrderReview_Order_By = {
  WUI_Order?: InputMaybe<Wui_Order_Order_By>;
  WUI_UserReview?: InputMaybe<Wui_UserReview_Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_OrderReview */
export type Wui_OrderReview_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_OrderReview" */
export enum Wui_OrderReview_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ReviewId = 'review_id'
}

/** input type for updating data in table "WUI_OrderReview" */
export type Wui_OrderReview_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  review_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_OrderReview_Stddev_Fields = {
  __typename?: 'WUI_OrderReview_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_OrderReview_Stddev_Pop_Fields = {
  __typename?: 'WUI_OrderReview_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_OrderReview_Stddev_Samp_Fields = {
  __typename?: 'WUI_OrderReview_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WUI_OrderReview" */
export type Wui_OrderReview_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_OrderReview_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_OrderReview_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  review_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_OrderReview_Sum_Fields = {
  __typename?: 'WUI_OrderReview_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  review_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_OrderReview" */
export enum Wui_OrderReview_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ReviewId = 'review_id'
}

export type Wui_OrderReview_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_OrderReview_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_OrderReview_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_OrderReview_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_OrderReview_Var_Pop_Fields = {
  __typename?: 'WUI_OrderReview_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_OrderReview_Var_Samp_Fields = {
  __typename?: 'WUI_OrderReview_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_OrderReview_Variance_Fields = {
  __typename?: 'WUI_OrderReview_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_OrderStatus" */
export type Wui_OrderStatus = {
  __typename?: 'WUI_OrderStatus';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_OrderStatus" */
export type Wui_OrderStatus_Aggregate = {
  __typename?: 'WUI_OrderStatus_aggregate';
  aggregate?: Maybe<Wui_OrderStatus_Aggregate_Fields>;
  nodes: Array<Wui_OrderStatus>;
};

/** aggregate fields of "WUI_OrderStatus" */
export type Wui_OrderStatus_Aggregate_Fields = {
  __typename?: 'WUI_OrderStatus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_OrderStatus_Max_Fields>;
  min?: Maybe<Wui_OrderStatus_Min_Fields>;
};


/** aggregate fields of "WUI_OrderStatus" */
export type Wui_OrderStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_OrderStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_OrderStatus". All fields are combined with a logical 'AND'. */
export type Wui_OrderStatus_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_OrderStatus_Bool_Exp>>;
  _not?: InputMaybe<Wui_OrderStatus_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_OrderStatus_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_OrderStatus" */
export enum Wui_OrderStatus_Constraint {
  /** unique or primary key constraint on columns "value" */
  WuiOrderStatusPkey = 'WUI_OrderStatus_pkey'
}

export enum Wui_OrderStatus_Enum {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Delivered = 'delivered',
  InProgress = 'inProgress',
  Open = 'open',
  Pending = 'pending',
  Rejected = 'rejected',
  WaitingForContent = 'waitingForContent'
}

/** Boolean expression to compare columns of type "WUI_OrderStatus_enum". All fields are combined with logical 'AND'. */
export type Wui_OrderStatus_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Wui_OrderStatus_Enum>;
  _in?: InputMaybe<Array<Wui_OrderStatus_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Wui_OrderStatus_Enum>;
  _nin?: InputMaybe<Array<Wui_OrderStatus_Enum>>;
};

/** input type for inserting data into table "WUI_OrderStatus" */
export type Wui_OrderStatus_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_OrderStatus_Max_Fields = {
  __typename?: 'WUI_OrderStatus_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_OrderStatus_Min_Fields = {
  __typename?: 'WUI_OrderStatus_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_OrderStatus" */
export type Wui_OrderStatus_Mutation_Response = {
  __typename?: 'WUI_OrderStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_OrderStatus>;
};

/** on_conflict condition type for table "WUI_OrderStatus" */
export type Wui_OrderStatus_On_Conflict = {
  constraint: Wui_OrderStatus_Constraint;
  update_columns?: Array<Wui_OrderStatus_Update_Column>;
  where?: InputMaybe<Wui_OrderStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_OrderStatus". */
export type Wui_OrderStatus_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_OrderStatus */
export type Wui_OrderStatus_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_OrderStatus" */
export enum Wui_OrderStatus_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_OrderStatus" */
export type Wui_OrderStatus_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "WUI_OrderStatus" */
export type Wui_OrderStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_OrderStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_OrderStatus_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "WUI_OrderStatus" */
export enum Wui_OrderStatus_Update_Column {
  /** column name */
  Value = 'value'
}

export type Wui_OrderStatus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_OrderStatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_OrderStatus_Bool_Exp;
};

/** columns and relationships of "WUI_OrderSubscription" */
export type Wui_OrderSubscription = {
  __typename?: 'WUI_OrderSubscription';
  /** An object relationship */
  WUI_Order: Wui_Order;
  /** An object relationship */
  WUI_PaymentMethodCard: Wui_PaymentMethodCard;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  next_billing_date: Scalars['timestamptz'];
  order_id: Scalars['bigint'];
  payment_attempts: Scalars['Int'];
  payment_method_id: Scalars['bigint'];
  recurring_period: Wui_OrderSubscriptionPeriod_Enum;
  status: Wui_OrderSubscriptionStatus_Enum;
};

/** columns and relationships of "WUI_OrderSubscriptionPeriod" */
export type Wui_OrderSubscriptionPeriod = {
  __typename?: 'WUI_OrderSubscriptionPeriod';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_OrderSubscriptionPeriod" */
export type Wui_OrderSubscriptionPeriod_Aggregate = {
  __typename?: 'WUI_OrderSubscriptionPeriod_aggregate';
  aggregate?: Maybe<Wui_OrderSubscriptionPeriod_Aggregate_Fields>;
  nodes: Array<Wui_OrderSubscriptionPeriod>;
};

/** aggregate fields of "WUI_OrderSubscriptionPeriod" */
export type Wui_OrderSubscriptionPeriod_Aggregate_Fields = {
  __typename?: 'WUI_OrderSubscriptionPeriod_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_OrderSubscriptionPeriod_Max_Fields>;
  min?: Maybe<Wui_OrderSubscriptionPeriod_Min_Fields>;
};


/** aggregate fields of "WUI_OrderSubscriptionPeriod" */
export type Wui_OrderSubscriptionPeriod_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_OrderSubscriptionPeriod_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_OrderSubscriptionPeriod". All fields are combined with a logical 'AND'. */
export type Wui_OrderSubscriptionPeriod_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_OrderSubscriptionPeriod_Bool_Exp>>;
  _not?: InputMaybe<Wui_OrderSubscriptionPeriod_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_OrderSubscriptionPeriod_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_OrderSubscriptionPeriod" */
export enum Wui_OrderSubscriptionPeriod_Constraint {
  /** unique or primary key constraint on columns "value" */
  WuiOrderSubscriptionPeriodPkey = 'WUI_OrderSubscriptionPeriod_pkey'
}

export enum Wui_OrderSubscriptionPeriod_Enum {
  Monthly = 'monthly',
  Weekly = 'weekly'
}

/** Boolean expression to compare columns of type "WUI_OrderSubscriptionPeriod_enum". All fields are combined with logical 'AND'. */
export type Wui_OrderSubscriptionPeriod_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Wui_OrderSubscriptionPeriod_Enum>;
  _in?: InputMaybe<Array<Wui_OrderSubscriptionPeriod_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Wui_OrderSubscriptionPeriod_Enum>;
  _nin?: InputMaybe<Array<Wui_OrderSubscriptionPeriod_Enum>>;
};

/** input type for inserting data into table "WUI_OrderSubscriptionPeriod" */
export type Wui_OrderSubscriptionPeriod_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_OrderSubscriptionPeriod_Max_Fields = {
  __typename?: 'WUI_OrderSubscriptionPeriod_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_OrderSubscriptionPeriod_Min_Fields = {
  __typename?: 'WUI_OrderSubscriptionPeriod_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_OrderSubscriptionPeriod" */
export type Wui_OrderSubscriptionPeriod_Mutation_Response = {
  __typename?: 'WUI_OrderSubscriptionPeriod_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_OrderSubscriptionPeriod>;
};

/** on_conflict condition type for table "WUI_OrderSubscriptionPeriod" */
export type Wui_OrderSubscriptionPeriod_On_Conflict = {
  constraint: Wui_OrderSubscriptionPeriod_Constraint;
  update_columns?: Array<Wui_OrderSubscriptionPeriod_Update_Column>;
  where?: InputMaybe<Wui_OrderSubscriptionPeriod_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_OrderSubscriptionPeriod". */
export type Wui_OrderSubscriptionPeriod_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_OrderSubscriptionPeriod */
export type Wui_OrderSubscriptionPeriod_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_OrderSubscriptionPeriod" */
export enum Wui_OrderSubscriptionPeriod_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_OrderSubscriptionPeriod" */
export type Wui_OrderSubscriptionPeriod_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "WUI_OrderSubscriptionPeriod" */
export type Wui_OrderSubscriptionPeriod_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_OrderSubscriptionPeriod_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_OrderSubscriptionPeriod_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "WUI_OrderSubscriptionPeriod" */
export enum Wui_OrderSubscriptionPeriod_Update_Column {
  /** column name */
  Value = 'value'
}

export type Wui_OrderSubscriptionPeriod_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_OrderSubscriptionPeriod_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_OrderSubscriptionPeriod_Bool_Exp;
};

/** columns and relationships of "WUI_OrderSubscriptionStatus" */
export type Wui_OrderSubscriptionStatus = {
  __typename?: 'WUI_OrderSubscriptionStatus';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_OrderSubscriptionStatus" */
export type Wui_OrderSubscriptionStatus_Aggregate = {
  __typename?: 'WUI_OrderSubscriptionStatus_aggregate';
  aggregate?: Maybe<Wui_OrderSubscriptionStatus_Aggregate_Fields>;
  nodes: Array<Wui_OrderSubscriptionStatus>;
};

/** aggregate fields of "WUI_OrderSubscriptionStatus" */
export type Wui_OrderSubscriptionStatus_Aggregate_Fields = {
  __typename?: 'WUI_OrderSubscriptionStatus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_OrderSubscriptionStatus_Max_Fields>;
  min?: Maybe<Wui_OrderSubscriptionStatus_Min_Fields>;
};


/** aggregate fields of "WUI_OrderSubscriptionStatus" */
export type Wui_OrderSubscriptionStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_OrderSubscriptionStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_OrderSubscriptionStatus". All fields are combined with a logical 'AND'. */
export type Wui_OrderSubscriptionStatus_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_OrderSubscriptionStatus_Bool_Exp>>;
  _not?: InputMaybe<Wui_OrderSubscriptionStatus_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_OrderSubscriptionStatus_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_OrderSubscriptionStatus" */
export enum Wui_OrderSubscriptionStatus_Constraint {
  /** unique or primary key constraint on columns "value" */
  WuiOrderSubscriptionStatusPkey = 'WUI_OrderSubscriptionStatus_pkey'
}

export enum Wui_OrderSubscriptionStatus_Enum {
  Active = 'active',
  Inactive = 'inactive'
}

/** Boolean expression to compare columns of type "WUI_OrderSubscriptionStatus_enum". All fields are combined with logical 'AND'. */
export type Wui_OrderSubscriptionStatus_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Wui_OrderSubscriptionStatus_Enum>;
  _in?: InputMaybe<Array<Wui_OrderSubscriptionStatus_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Wui_OrderSubscriptionStatus_Enum>;
  _nin?: InputMaybe<Array<Wui_OrderSubscriptionStatus_Enum>>;
};

/** input type for inserting data into table "WUI_OrderSubscriptionStatus" */
export type Wui_OrderSubscriptionStatus_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_OrderSubscriptionStatus_Max_Fields = {
  __typename?: 'WUI_OrderSubscriptionStatus_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_OrderSubscriptionStatus_Min_Fields = {
  __typename?: 'WUI_OrderSubscriptionStatus_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_OrderSubscriptionStatus" */
export type Wui_OrderSubscriptionStatus_Mutation_Response = {
  __typename?: 'WUI_OrderSubscriptionStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_OrderSubscriptionStatus>;
};

/** on_conflict condition type for table "WUI_OrderSubscriptionStatus" */
export type Wui_OrderSubscriptionStatus_On_Conflict = {
  constraint: Wui_OrderSubscriptionStatus_Constraint;
  update_columns?: Array<Wui_OrderSubscriptionStatus_Update_Column>;
  where?: InputMaybe<Wui_OrderSubscriptionStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_OrderSubscriptionStatus". */
export type Wui_OrderSubscriptionStatus_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_OrderSubscriptionStatus */
export type Wui_OrderSubscriptionStatus_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_OrderSubscriptionStatus" */
export enum Wui_OrderSubscriptionStatus_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_OrderSubscriptionStatus" */
export type Wui_OrderSubscriptionStatus_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "WUI_OrderSubscriptionStatus" */
export type Wui_OrderSubscriptionStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_OrderSubscriptionStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_OrderSubscriptionStatus_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "WUI_OrderSubscriptionStatus" */
export enum Wui_OrderSubscriptionStatus_Update_Column {
  /** column name */
  Value = 'value'
}

export type Wui_OrderSubscriptionStatus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_OrderSubscriptionStatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_OrderSubscriptionStatus_Bool_Exp;
};

/** aggregated selection of "WUI_OrderSubscription" */
export type Wui_OrderSubscription_Aggregate = {
  __typename?: 'WUI_OrderSubscription_aggregate';
  aggregate?: Maybe<Wui_OrderSubscription_Aggregate_Fields>;
  nodes: Array<Wui_OrderSubscription>;
};

/** aggregate fields of "WUI_OrderSubscription" */
export type Wui_OrderSubscription_Aggregate_Fields = {
  __typename?: 'WUI_OrderSubscription_aggregate_fields';
  avg?: Maybe<Wui_OrderSubscription_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_OrderSubscription_Max_Fields>;
  min?: Maybe<Wui_OrderSubscription_Min_Fields>;
  stddev?: Maybe<Wui_OrderSubscription_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_OrderSubscription_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_OrderSubscription_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_OrderSubscription_Sum_Fields>;
  var_pop?: Maybe<Wui_OrderSubscription_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_OrderSubscription_Var_Samp_Fields>;
  variance?: Maybe<Wui_OrderSubscription_Variance_Fields>;
};


/** aggregate fields of "WUI_OrderSubscription" */
export type Wui_OrderSubscription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_OrderSubscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_OrderSubscription_Avg_Fields = {
  __typename?: 'WUI_OrderSubscription_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  payment_attempts?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_OrderSubscription". All fields are combined with a logical 'AND'. */
export type Wui_OrderSubscription_Bool_Exp = {
  WUI_Order?: InputMaybe<Wui_Order_Bool_Exp>;
  WUI_PaymentMethodCard?: InputMaybe<Wui_PaymentMethodCard_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_OrderSubscription_Bool_Exp>>;
  _not?: InputMaybe<Wui_OrderSubscription_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_OrderSubscription_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  next_billing_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  order_id?: InputMaybe<Bigint_Comparison_Exp>;
  payment_attempts?: InputMaybe<Int_Comparison_Exp>;
  payment_method_id?: InputMaybe<Bigint_Comparison_Exp>;
  recurring_period?: InputMaybe<Wui_OrderSubscriptionPeriod_Enum_Comparison_Exp>;
  status?: InputMaybe<Wui_OrderSubscriptionStatus_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_OrderSubscription" */
export enum Wui_OrderSubscription_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiOrderSubscriptionPkey = 'WUI_OrderSubscription_pkey'
}

/** input type for incrementing numeric columns in table "WUI_OrderSubscription" */
export type Wui_OrderSubscription_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  payment_attempts?: InputMaybe<Scalars['Int']>;
  payment_method_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_OrderSubscription" */
export type Wui_OrderSubscription_Insert_Input = {
  WUI_Order?: InputMaybe<Wui_Order_Obj_Rel_Insert_Input>;
  WUI_PaymentMethodCard?: InputMaybe<Wui_PaymentMethodCard_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  next_billing_date?: InputMaybe<Scalars['timestamptz']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  payment_attempts?: InputMaybe<Scalars['Int']>;
  payment_method_id?: InputMaybe<Scalars['bigint']>;
  recurring_period?: InputMaybe<Wui_OrderSubscriptionPeriod_Enum>;
  status?: InputMaybe<Wui_OrderSubscriptionStatus_Enum>;
};

/** aggregate max on columns */
export type Wui_OrderSubscription_Max_Fields = {
  __typename?: 'WUI_OrderSubscription_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  next_billing_date?: Maybe<Scalars['timestamptz']>;
  order_id?: Maybe<Scalars['bigint']>;
  payment_attempts?: Maybe<Scalars['Int']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Wui_OrderSubscription_Min_Fields = {
  __typename?: 'WUI_OrderSubscription_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  next_billing_date?: Maybe<Scalars['timestamptz']>;
  order_id?: Maybe<Scalars['bigint']>;
  payment_attempts?: Maybe<Scalars['Int']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "WUI_OrderSubscription" */
export type Wui_OrderSubscription_Mutation_Response = {
  __typename?: 'WUI_OrderSubscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_OrderSubscription>;
};

/** on_conflict condition type for table "WUI_OrderSubscription" */
export type Wui_OrderSubscription_On_Conflict = {
  constraint: Wui_OrderSubscription_Constraint;
  update_columns?: Array<Wui_OrderSubscription_Update_Column>;
  where?: InputMaybe<Wui_OrderSubscription_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_OrderSubscription". */
export type Wui_OrderSubscription_Order_By = {
  WUI_Order?: InputMaybe<Wui_Order_Order_By>;
  WUI_PaymentMethodCard?: InputMaybe<Wui_PaymentMethodCard_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_billing_date?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  payment_attempts?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
  recurring_period?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_OrderSubscription */
export type Wui_OrderSubscription_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_OrderSubscription" */
export enum Wui_OrderSubscription_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NextBillingDate = 'next_billing_date',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PaymentAttempts = 'payment_attempts',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  RecurringPeriod = 'recurring_period',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "WUI_OrderSubscription" */
export type Wui_OrderSubscription_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  next_billing_date?: InputMaybe<Scalars['timestamptz']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  payment_attempts?: InputMaybe<Scalars['Int']>;
  payment_method_id?: InputMaybe<Scalars['bigint']>;
  recurring_period?: InputMaybe<Wui_OrderSubscriptionPeriod_Enum>;
  status?: InputMaybe<Wui_OrderSubscriptionStatus_Enum>;
};

/** aggregate stddev on columns */
export type Wui_OrderSubscription_Stddev_Fields = {
  __typename?: 'WUI_OrderSubscription_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  payment_attempts?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_OrderSubscription_Stddev_Pop_Fields = {
  __typename?: 'WUI_OrderSubscription_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  payment_attempts?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_OrderSubscription_Stddev_Samp_Fields = {
  __typename?: 'WUI_OrderSubscription_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  payment_attempts?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WUI_OrderSubscription" */
export type Wui_OrderSubscription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_OrderSubscription_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_OrderSubscription_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  next_billing_date?: InputMaybe<Scalars['timestamptz']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  payment_attempts?: InputMaybe<Scalars['Int']>;
  payment_method_id?: InputMaybe<Scalars['bigint']>;
  recurring_period?: InputMaybe<Wui_OrderSubscriptionPeriod_Enum>;
  status?: InputMaybe<Wui_OrderSubscriptionStatus_Enum>;
};

/** aggregate sum on columns */
export type Wui_OrderSubscription_Sum_Fields = {
  __typename?: 'WUI_OrderSubscription_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  payment_attempts?: Maybe<Scalars['Int']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_OrderSubscription" */
export enum Wui_OrderSubscription_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NextBillingDate = 'next_billing_date',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PaymentAttempts = 'payment_attempts',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  RecurringPeriod = 'recurring_period',
  /** column name */
  Status = 'status'
}

export type Wui_OrderSubscription_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_OrderSubscription_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_OrderSubscription_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_OrderSubscription_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_OrderSubscription_Var_Pop_Fields = {
  __typename?: 'WUI_OrderSubscription_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  payment_attempts?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_OrderSubscription_Var_Samp_Fields = {
  __typename?: 'WUI_OrderSubscription_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  payment_attempts?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_OrderSubscription_Variance_Fields = {
  __typename?: 'WUI_OrderSubscription_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  payment_attempts?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "WUI_Order" */
export type Wui_Order_Aggregate = {
  __typename?: 'WUI_Order_aggregate';
  aggregate?: Maybe<Wui_Order_Aggregate_Fields>;
  nodes: Array<Wui_Order>;
};

/** aggregate fields of "WUI_Order" */
export type Wui_Order_Aggregate_Fields = {
  __typename?: 'WUI_Order_aggregate_fields';
  avg?: Maybe<Wui_Order_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_Order_Max_Fields>;
  min?: Maybe<Wui_Order_Min_Fields>;
  stddev?: Maybe<Wui_Order_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_Order_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_Order_Sum_Fields>;
  var_pop?: Maybe<Wui_Order_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_Order_Var_Samp_Fields>;
  variance?: Maybe<Wui_Order_Variance_Fields>;
};


/** aggregate fields of "WUI_Order" */
export type Wui_Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Wui_Order_Append_Input = {
  items?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Wui_Order_Avg_Fields = {
  __typename?: 'WUI_Order_avg_fields';
  buyer_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_Order". All fields are combined with a logical 'AND'. */
export type Wui_Order_Bool_Exp = {
  WUI_Payment?: InputMaybe<Wui_Payment_Bool_Exp>;
  WUI_Payments?: InputMaybe<Wui_Payment_Bool_Exp>;
  WUI_Payments_aggregate?: InputMaybe<Wui_Payment_Aggregate_Bool_Exp>;
  WUI_User_Buyer?: InputMaybe<Wui_User_Bool_Exp>;
  WUI_User_Seller?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_Order_Bool_Exp>>;
  _not?: InputMaybe<Wui_Order_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_Order_Bool_Exp>>;
  buyer_id?: InputMaybe<Bigint_Comparison_Exp>;
  closed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Bigint_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  items?: InputMaybe<Jsonb_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  package_type?: InputMaybe<Wui_SellerPackageType_Enum_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  seller_id?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<Wui_OrderStatus_Enum_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_Order" */
export enum Wui_Order_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiOrderPkey = 'WUI_Order_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Wui_Order_Delete_At_Path_Input = {
  items?: InputMaybe<Array<Scalars['String']>>;
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Wui_Order_Delete_Elem_Input = {
  items?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Wui_Order_Delete_Key_Input = {
  items?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "WUI_Order" */
export type Wui_Order_Inc_Input = {
  buyer_id?: InputMaybe<Scalars['bigint']>;
  created_by?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  seller_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_Order" */
export type Wui_Order_Insert_Input = {
  WUI_Payment?: InputMaybe<Wui_Payment_Obj_Rel_Insert_Input>;
  WUI_Payments?: InputMaybe<Wui_Payment_Arr_Rel_Insert_Input>;
  WUI_User_Buyer?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  WUI_User_Seller?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  buyer_id?: InputMaybe<Scalars['bigint']>;
  closed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['bigint']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  items?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  package_type?: InputMaybe<Wui_SellerPackageType_Enum>;
  price?: InputMaybe<Scalars['numeric']>;
  seller_id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Wui_OrderStatus_Enum>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Wui_Order_Max_Fields = {
  __typename?: 'WUI_Order_max_fields';
  buyer_id?: Maybe<Scalars['bigint']>;
  closed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  seller_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Wui_Order_Min_Fields = {
  __typename?: 'WUI_Order_min_fields';
  buyer_id?: Maybe<Scalars['bigint']>;
  closed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  seller_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "WUI_Order" */
export type Wui_Order_Mutation_Response = {
  __typename?: 'WUI_Order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_Order>;
};

/** input type for inserting object relation for remote table "WUI_Order" */
export type Wui_Order_Obj_Rel_Insert_Input = {
  data: Wui_Order_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_Order_On_Conflict>;
};

/** on_conflict condition type for table "WUI_Order" */
export type Wui_Order_On_Conflict = {
  constraint: Wui_Order_Constraint;
  update_columns?: Array<Wui_Order_Update_Column>;
  where?: InputMaybe<Wui_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_Order". */
export type Wui_Order_Order_By = {
  WUI_Payment?: InputMaybe<Wui_Payment_Order_By>;
  WUI_Payments_aggregate?: InputMaybe<Wui_Payment_Aggregate_Order_By>;
  WUI_User_Buyer?: InputMaybe<Wui_User_Order_By>;
  WUI_User_Seller?: InputMaybe<Wui_User_Order_By>;
  buyer_id?: InputMaybe<Order_By>;
  closed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  items?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  package_type?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  seller_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_Order */
export type Wui_Order_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Wui_Order_Prepend_Input = {
  items?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "WUI_Order" */
export enum Wui_Order_Select_Column {
  /** column name */
  BuyerId = 'buyer_id',
  /** column name */
  ClosedAt = 'closed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Items = 'items',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PackageType = 'package_type',
  /** column name */
  Price = 'price',
  /** column name */
  SellerId = 'seller_id',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "WUI_Order" */
export type Wui_Order_Set_Input = {
  buyer_id?: InputMaybe<Scalars['bigint']>;
  closed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['bigint']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  items?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  package_type?: InputMaybe<Wui_SellerPackageType_Enum>;
  price?: InputMaybe<Scalars['numeric']>;
  seller_id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Wui_OrderStatus_Enum>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Wui_Order_Stddev_Fields = {
  __typename?: 'WUI_Order_stddev_fields';
  buyer_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_Order_Stddev_Pop_Fields = {
  __typename?: 'WUI_Order_stddev_pop_fields';
  buyer_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_Order_Stddev_Samp_Fields = {
  __typename?: 'WUI_Order_stddev_samp_fields';
  buyer_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WUI_Order" */
export type Wui_Order_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_Order_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_Order_Stream_Cursor_Value_Input = {
  buyer_id?: InputMaybe<Scalars['bigint']>;
  closed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['bigint']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  items?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  package_type?: InputMaybe<Wui_SellerPackageType_Enum>;
  price?: InputMaybe<Scalars['numeric']>;
  seller_id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Wui_OrderStatus_Enum>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Wui_Order_Sum_Fields = {
  __typename?: 'WUI_Order_sum_fields';
  buyer_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  seller_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_Order" */
export enum Wui_Order_Update_Column {
  /** column name */
  BuyerId = 'buyer_id',
  /** column name */
  ClosedAt = 'closed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Items = 'items',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PackageType = 'package_type',
  /** column name */
  Price = 'price',
  /** column name */
  SellerId = 'seller_id',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Wui_Order_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Wui_Order_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Wui_Order_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Wui_Order_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Wui_Order_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_Order_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Wui_Order_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_Order_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_Order_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_Order_Var_Pop_Fields = {
  __typename?: 'WUI_Order_var_pop_fields';
  buyer_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_Order_Var_Samp_Fields = {
  __typename?: 'WUI_Order_var_samp_fields';
  buyer_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_Order_Variance_Fields = {
  __typename?: 'WUI_Order_variance_fields';
  buyer_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_PackageRequest" */
export type Wui_PackageRequest = {
  __typename?: 'WUI_PackageRequest';
  /** An object relationship */
  WUI_Requester_User: Wui_User;
  /** An object relationship */
  WUI_SellerPackage: Wui_SellerPackage;
  /** An object relationship */
  WUI_Seller_User: Wui_User;
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  package_id: Scalars['bigint'];
  requester_id: Scalars['bigint'];
  seller_id: Scalars['bigint'];
  status: Wui_RequestStatus_Enum;
  tries: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "WUI_PackageRequest" */
export type Wui_PackageRequest_Aggregate = {
  __typename?: 'WUI_PackageRequest_aggregate';
  aggregate?: Maybe<Wui_PackageRequest_Aggregate_Fields>;
  nodes: Array<Wui_PackageRequest>;
};

/** aggregate fields of "WUI_PackageRequest" */
export type Wui_PackageRequest_Aggregate_Fields = {
  __typename?: 'WUI_PackageRequest_aggregate_fields';
  avg?: Maybe<Wui_PackageRequest_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_PackageRequest_Max_Fields>;
  min?: Maybe<Wui_PackageRequest_Min_Fields>;
  stddev?: Maybe<Wui_PackageRequest_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_PackageRequest_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_PackageRequest_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_PackageRequest_Sum_Fields>;
  var_pop?: Maybe<Wui_PackageRequest_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_PackageRequest_Var_Samp_Fields>;
  variance?: Maybe<Wui_PackageRequest_Variance_Fields>;
};


/** aggregate fields of "WUI_PackageRequest" */
export type Wui_PackageRequest_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_PackageRequest_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_PackageRequest_Avg_Fields = {
  __typename?: 'WUI_PackageRequest_avg_fields';
  id?: Maybe<Scalars['Float']>;
  package_id?: Maybe<Scalars['Float']>;
  requester_id?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  tries?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_PackageRequest". All fields are combined with a logical 'AND'. */
export type Wui_PackageRequest_Bool_Exp = {
  WUI_Requester_User?: InputMaybe<Wui_User_Bool_Exp>;
  WUI_SellerPackage?: InputMaybe<Wui_SellerPackage_Bool_Exp>;
  WUI_Seller_User?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_PackageRequest_Bool_Exp>>;
  _not?: InputMaybe<Wui_PackageRequest_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_PackageRequest_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  package_id?: InputMaybe<Bigint_Comparison_Exp>;
  requester_id?: InputMaybe<Bigint_Comparison_Exp>;
  seller_id?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<Wui_RequestStatus_Enum_Comparison_Exp>;
  tries?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_PackageRequest" */
export enum Wui_PackageRequest_Constraint {
  /** unique or primary key constraint on columns "package_id", "requester_id", "seller_id" */
  WuiPackageRequestPackageIdRequesterIdSellerIdKey = 'WUI_PackageRequest_package_id_requester_id_seller_id_key',
  /** unique or primary key constraint on columns "id" */
  WuiSellerPackageRequestPkey = 'WUI_SellerPackageRequest_pkey'
}

/** input type for incrementing numeric columns in table "WUI_PackageRequest" */
export type Wui_PackageRequest_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  package_id?: InputMaybe<Scalars['bigint']>;
  requester_id?: InputMaybe<Scalars['bigint']>;
  seller_id?: InputMaybe<Scalars['bigint']>;
  tries?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "WUI_PackageRequest" */
export type Wui_PackageRequest_Insert_Input = {
  WUI_Requester_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  WUI_SellerPackage?: InputMaybe<Wui_SellerPackage_Obj_Rel_Insert_Input>;
  WUI_Seller_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  package_id?: InputMaybe<Scalars['bigint']>;
  requester_id?: InputMaybe<Scalars['bigint']>;
  seller_id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Wui_RequestStatus_Enum>;
  tries?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Wui_PackageRequest_Max_Fields = {
  __typename?: 'WUI_PackageRequest_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  package_id?: Maybe<Scalars['bigint']>;
  requester_id?: Maybe<Scalars['bigint']>;
  seller_id?: Maybe<Scalars['bigint']>;
  tries?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Wui_PackageRequest_Min_Fields = {
  __typename?: 'WUI_PackageRequest_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  package_id?: Maybe<Scalars['bigint']>;
  requester_id?: Maybe<Scalars['bigint']>;
  seller_id?: Maybe<Scalars['bigint']>;
  tries?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "WUI_PackageRequest" */
export type Wui_PackageRequest_Mutation_Response = {
  __typename?: 'WUI_PackageRequest_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_PackageRequest>;
};

/** on_conflict condition type for table "WUI_PackageRequest" */
export type Wui_PackageRequest_On_Conflict = {
  constraint: Wui_PackageRequest_Constraint;
  update_columns?: Array<Wui_PackageRequest_Update_Column>;
  where?: InputMaybe<Wui_PackageRequest_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_PackageRequest". */
export type Wui_PackageRequest_Order_By = {
  WUI_Requester_User?: InputMaybe<Wui_User_Order_By>;
  WUI_SellerPackage?: InputMaybe<Wui_SellerPackage_Order_By>;
  WUI_Seller_User?: InputMaybe<Wui_User_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  package_id?: InputMaybe<Order_By>;
  requester_id?: InputMaybe<Order_By>;
  seller_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tries?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_PackageRequest */
export type Wui_PackageRequest_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_PackageRequest" */
export enum Wui_PackageRequest_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PackageId = 'package_id',
  /** column name */
  RequesterId = 'requester_id',
  /** column name */
  SellerId = 'seller_id',
  /** column name */
  Status = 'status',
  /** column name */
  Tries = 'tries',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "WUI_PackageRequest" */
export type Wui_PackageRequest_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  package_id?: InputMaybe<Scalars['bigint']>;
  requester_id?: InputMaybe<Scalars['bigint']>;
  seller_id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Wui_RequestStatus_Enum>;
  tries?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Wui_PackageRequest_Stddev_Fields = {
  __typename?: 'WUI_PackageRequest_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  package_id?: Maybe<Scalars['Float']>;
  requester_id?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  tries?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_PackageRequest_Stddev_Pop_Fields = {
  __typename?: 'WUI_PackageRequest_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  package_id?: Maybe<Scalars['Float']>;
  requester_id?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  tries?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_PackageRequest_Stddev_Samp_Fields = {
  __typename?: 'WUI_PackageRequest_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  package_id?: Maybe<Scalars['Float']>;
  requester_id?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  tries?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WUI_PackageRequest" */
export type Wui_PackageRequest_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_PackageRequest_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_PackageRequest_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  package_id?: InputMaybe<Scalars['bigint']>;
  requester_id?: InputMaybe<Scalars['bigint']>;
  seller_id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Wui_RequestStatus_Enum>;
  tries?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Wui_PackageRequest_Sum_Fields = {
  __typename?: 'WUI_PackageRequest_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  package_id?: Maybe<Scalars['bigint']>;
  requester_id?: Maybe<Scalars['bigint']>;
  seller_id?: Maybe<Scalars['bigint']>;
  tries?: Maybe<Scalars['Int']>;
};

/** update columns of table "WUI_PackageRequest" */
export enum Wui_PackageRequest_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PackageId = 'package_id',
  /** column name */
  RequesterId = 'requester_id',
  /** column name */
  SellerId = 'seller_id',
  /** column name */
  Status = 'status',
  /** column name */
  Tries = 'tries',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Wui_PackageRequest_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_PackageRequest_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_PackageRequest_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_PackageRequest_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_PackageRequest_Var_Pop_Fields = {
  __typename?: 'WUI_PackageRequest_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  package_id?: Maybe<Scalars['Float']>;
  requester_id?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  tries?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_PackageRequest_Var_Samp_Fields = {
  __typename?: 'WUI_PackageRequest_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  package_id?: Maybe<Scalars['Float']>;
  requester_id?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  tries?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_PackageRequest_Variance_Fields = {
  __typename?: 'WUI_PackageRequest_variance_fields';
  id?: Maybe<Scalars['Float']>;
  package_id?: Maybe<Scalars['Float']>;
  requester_id?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  tries?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_Payment" */
export type Wui_Payment = {
  __typename?: 'WUI_Payment';
  /** An object relationship */
  WUI_Order: Wui_Order;
  /** An object relationship */
  WUI_PaymentStatus: Wui_PaymentStatus;
  buyer_fees: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  currency_code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  error_code?: Maybe<Scalars['String']>;
  error_msg?: Maybe<Scalars['String']>;
  external_id: Scalars['String'];
  id: Scalars['bigint'];
  metadata?: Maybe<Scalars['jsonb']>;
  order_id: Scalars['bigint'];
  price: Scalars['numeric'];
  seller_fees: Scalars['numeric'];
  statement_description?: Maybe<Scalars['String']>;
  status: Wui_PaymentStatus_Enum;
  transaction_fees: Scalars['numeric'];
  transferred_to_acc?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "WUI_Payment" */
export type Wui_PaymentMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "WUI_PaymentMethod" */
export type Wui_PaymentMethod = {
  __typename?: 'WUI_PaymentMethod';
  /** An array relationship */
  WUI_PaymentMethodBankAccounts: Array<Wui_PaymentMethodBankAccount>;
  /** An aggregate relationship */
  WUI_PaymentMethodBankAccounts_aggregate: Wui_PaymentMethodBankAccount_Aggregate;
  /** An array relationship */
  WUI_PaymentMethodCards: Array<Wui_PaymentMethodCard>;
  /** An aggregate relationship */
  WUI_PaymentMethodCards_aggregate: Wui_PaymentMethodCard_Aggregate;
  /** An object relationship */
  WUI_PaymentMethodSource: Wui_PaymentMethodSource;
  /** An object relationship */
  WUI_User: Wui_User;
  id: Scalars['bigint'];
  source: Wui_PaymentMethodSource_Enum;
  user_id: Scalars['bigint'];
};


/** columns and relationships of "WUI_PaymentMethod" */
export type Wui_PaymentMethodWui_PaymentMethodBankAccountsArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethodBankAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethodBankAccount_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethodBankAccount_Bool_Exp>;
};


/** columns and relationships of "WUI_PaymentMethod" */
export type Wui_PaymentMethodWui_PaymentMethodBankAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethodBankAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethodBankAccount_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethodBankAccount_Bool_Exp>;
};


/** columns and relationships of "WUI_PaymentMethod" */
export type Wui_PaymentMethodWui_PaymentMethodCardsArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethodCard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethodCard_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethodCard_Bool_Exp>;
};


/** columns and relationships of "WUI_PaymentMethod" */
export type Wui_PaymentMethodWui_PaymentMethodCards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethodCard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethodCard_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethodCard_Bool_Exp>;
};

/** columns and relationships of "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount = {
  __typename?: 'WUI_PaymentMethodBankAccount';
  id: Scalars['Int'];
  payment_method_id: Scalars['bigint'];
};

/** aggregated selection of "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Aggregate = {
  __typename?: 'WUI_PaymentMethodBankAccount_aggregate';
  aggregate?: Maybe<Wui_PaymentMethodBankAccount_Aggregate_Fields>;
  nodes: Array<Wui_PaymentMethodBankAccount>;
};

export type Wui_PaymentMethodBankAccount_Aggregate_Bool_Exp = {
  count?: InputMaybe<Wui_PaymentMethodBankAccount_Aggregate_Bool_Exp_Count>;
};

export type Wui_PaymentMethodBankAccount_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wui_PaymentMethodBankAccount_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_PaymentMethodBankAccount_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Aggregate_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_aggregate_fields';
  avg?: Maybe<Wui_PaymentMethodBankAccount_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_PaymentMethodBankAccount_Max_Fields>;
  min?: Maybe<Wui_PaymentMethodBankAccount_Min_Fields>;
  stddev?: Maybe<Wui_PaymentMethodBankAccount_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_PaymentMethodBankAccount_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_PaymentMethodBankAccount_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_PaymentMethodBankAccount_Sum_Fields>;
  var_pop?: Maybe<Wui_PaymentMethodBankAccount_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_PaymentMethodBankAccount_Var_Samp_Fields>;
  variance?: Maybe<Wui_PaymentMethodBankAccount_Variance_Fields>;
};


/** aggregate fields of "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_PaymentMethodBankAccount_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Aggregate_Order_By = {
  avg?: InputMaybe<Wui_PaymentMethodBankAccount_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wui_PaymentMethodBankAccount_Max_Order_By>;
  min?: InputMaybe<Wui_PaymentMethodBankAccount_Min_Order_By>;
  stddev?: InputMaybe<Wui_PaymentMethodBankAccount_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wui_PaymentMethodBankAccount_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wui_PaymentMethodBankAccount_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wui_PaymentMethodBankAccount_Sum_Order_By>;
  var_pop?: InputMaybe<Wui_PaymentMethodBankAccount_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wui_PaymentMethodBankAccount_Var_Samp_Order_By>;
  variance?: InputMaybe<Wui_PaymentMethodBankAccount_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Arr_Rel_Insert_Input = {
  data: Array<Wui_PaymentMethodBankAccount_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_PaymentMethodBankAccount_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_PaymentMethodBankAccount_Avg_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_avg_fields';
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_PaymentMethodBankAccount". All fields are combined with a logical 'AND'. */
export type Wui_PaymentMethodBankAccount_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_PaymentMethodBankAccount_Bool_Exp>>;
  _not?: InputMaybe<Wui_PaymentMethodBankAccount_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_PaymentMethodBankAccount_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  payment_method_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_PaymentMethodBankAccount" */
export enum Wui_PaymentMethodBankAccount_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiPaymentMethodBankAccountPkey = 'WUI_PaymentMethodBankAccount_pkey'
}

/** input type for incrementing numeric columns in table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  payment_method_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  payment_method_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_PaymentMethodBankAccount_Max_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_max_fields';
  id?: Maybe<Scalars['Int']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_PaymentMethodBankAccount_Min_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_min_fields';
  id?: Maybe<Scalars['Int']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Mutation_Response = {
  __typename?: 'WUI_PaymentMethodBankAccount_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_PaymentMethodBankAccount>;
};

/** on_conflict condition type for table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_On_Conflict = {
  constraint: Wui_PaymentMethodBankAccount_Constraint;
  update_columns?: Array<Wui_PaymentMethodBankAccount_Update_Column>;
  where?: InputMaybe<Wui_PaymentMethodBankAccount_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_PaymentMethodBankAccount". */
export type Wui_PaymentMethodBankAccount_Order_By = {
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_PaymentMethodBankAccount */
export type Wui_PaymentMethodBankAccount_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "WUI_PaymentMethodBankAccount" */
export enum Wui_PaymentMethodBankAccount_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMethodId = 'payment_method_id'
}

/** input type for updating data in table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  payment_method_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_PaymentMethodBankAccount_Stddev_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_PaymentMethodBankAccount_Stddev_Pop_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_PaymentMethodBankAccount_Stddev_Samp_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_PaymentMethodBankAccount_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_PaymentMethodBankAccount_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  payment_method_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_PaymentMethodBankAccount_Sum_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_sum_fields';
  id?: Maybe<Scalars['Int']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** update columns of table "WUI_PaymentMethodBankAccount" */
export enum Wui_PaymentMethodBankAccount_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMethodId = 'payment_method_id'
}

export type Wui_PaymentMethodBankAccount_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_PaymentMethodBankAccount_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_PaymentMethodBankAccount_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_PaymentMethodBankAccount_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_PaymentMethodBankAccount_Var_Pop_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_PaymentMethodBankAccount_Var_Samp_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_PaymentMethodBankAccount_Variance_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_variance_fields';
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard = {
  __typename?: 'WUI_PaymentMethodCard';
  /** An object relationship */
  WUI_PaymentMethod: Wui_PaymentMethod;
  allow_3d_secure: Scalars['Boolean'];
  brand: Scalars['String'];
  country_code: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  exp_month: Scalars['Int'];
  exp_year: Scalars['Int'];
  external_id: Scalars['String'];
  fingerprint: Scalars['String'];
  funding: Scalars['String'];
  id: Scalars['bigint'];
  last4: Scalars['String'];
  payment_method_id: Scalars['bigint'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Aggregate = {
  __typename?: 'WUI_PaymentMethodCard_aggregate';
  aggregate?: Maybe<Wui_PaymentMethodCard_Aggregate_Fields>;
  nodes: Array<Wui_PaymentMethodCard>;
};

export type Wui_PaymentMethodCard_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Wui_PaymentMethodCard_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Wui_PaymentMethodCard_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Wui_PaymentMethodCard_Aggregate_Bool_Exp_Count>;
};

export type Wui_PaymentMethodCard_Aggregate_Bool_Exp_Bool_And = {
  arguments: Wui_PaymentMethodCard_Select_Column_Wui_PaymentMethodCard_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_PaymentMethodCard_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Wui_PaymentMethodCard_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Wui_PaymentMethodCard_Select_Column_Wui_PaymentMethodCard_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_PaymentMethodCard_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Wui_PaymentMethodCard_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wui_PaymentMethodCard_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_PaymentMethodCard_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Aggregate_Fields = {
  __typename?: 'WUI_PaymentMethodCard_aggregate_fields';
  avg?: Maybe<Wui_PaymentMethodCard_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_PaymentMethodCard_Max_Fields>;
  min?: Maybe<Wui_PaymentMethodCard_Min_Fields>;
  stddev?: Maybe<Wui_PaymentMethodCard_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_PaymentMethodCard_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_PaymentMethodCard_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_PaymentMethodCard_Sum_Fields>;
  var_pop?: Maybe<Wui_PaymentMethodCard_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_PaymentMethodCard_Var_Samp_Fields>;
  variance?: Maybe<Wui_PaymentMethodCard_Variance_Fields>;
};


/** aggregate fields of "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_PaymentMethodCard_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Aggregate_Order_By = {
  avg?: InputMaybe<Wui_PaymentMethodCard_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wui_PaymentMethodCard_Max_Order_By>;
  min?: InputMaybe<Wui_PaymentMethodCard_Min_Order_By>;
  stddev?: InputMaybe<Wui_PaymentMethodCard_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wui_PaymentMethodCard_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wui_PaymentMethodCard_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wui_PaymentMethodCard_Sum_Order_By>;
  var_pop?: InputMaybe<Wui_PaymentMethodCard_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wui_PaymentMethodCard_Var_Samp_Order_By>;
  variance?: InputMaybe<Wui_PaymentMethodCard_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Arr_Rel_Insert_Input = {
  data: Array<Wui_PaymentMethodCard_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_PaymentMethodCard_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_PaymentMethodCard_Avg_Fields = {
  __typename?: 'WUI_PaymentMethodCard_avg_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Avg_Order_By = {
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_PaymentMethodCard". All fields are combined with a logical 'AND'. */
export type Wui_PaymentMethodCard_Bool_Exp = {
  WUI_PaymentMethod?: InputMaybe<Wui_PaymentMethod_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_PaymentMethodCard_Bool_Exp>>;
  _not?: InputMaybe<Wui_PaymentMethodCard_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_PaymentMethodCard_Bool_Exp>>;
  allow_3d_secure?: InputMaybe<Boolean_Comparison_Exp>;
  brand?: InputMaybe<String_Comparison_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  exp_month?: InputMaybe<Int_Comparison_Exp>;
  exp_year?: InputMaybe<Int_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  fingerprint?: InputMaybe<String_Comparison_Exp>;
  funding?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  last4?: InputMaybe<String_Comparison_Exp>;
  payment_method_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_PaymentMethodCard" */
export enum Wui_PaymentMethodCard_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiCardPkey = 'WUI_Card_pkey'
}

/** input type for incrementing numeric columns in table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Inc_Input = {
  exp_month?: InputMaybe<Scalars['Int']>;
  exp_year?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  payment_method_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Insert_Input = {
  WUI_PaymentMethod?: InputMaybe<Wui_PaymentMethod_Obj_Rel_Insert_Input>;
  allow_3d_secure?: InputMaybe<Scalars['Boolean']>;
  brand?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  exp_month?: InputMaybe<Scalars['Int']>;
  exp_year?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  fingerprint?: InputMaybe<Scalars['String']>;
  funding?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  last4?: InputMaybe<Scalars['String']>;
  payment_method_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Wui_PaymentMethodCard_Max_Fields = {
  __typename?: 'WUI_PaymentMethodCard_max_fields';
  brand?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  external_id?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last4?: Maybe<Scalars['String']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Max_Order_By = {
  brand?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fingerprint?: InputMaybe<Order_By>;
  funding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last4?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_PaymentMethodCard_Min_Fields = {
  __typename?: 'WUI_PaymentMethodCard_min_fields';
  brand?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  external_id?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last4?: Maybe<Scalars['String']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Min_Order_By = {
  brand?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fingerprint?: InputMaybe<Order_By>;
  funding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last4?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Mutation_Response = {
  __typename?: 'WUI_PaymentMethodCard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_PaymentMethodCard>;
};

/** input type for inserting object relation for remote table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Obj_Rel_Insert_Input = {
  data: Wui_PaymentMethodCard_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_PaymentMethodCard_On_Conflict>;
};

/** on_conflict condition type for table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_On_Conflict = {
  constraint: Wui_PaymentMethodCard_Constraint;
  update_columns?: Array<Wui_PaymentMethodCard_Update_Column>;
  where?: InputMaybe<Wui_PaymentMethodCard_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_PaymentMethodCard". */
export type Wui_PaymentMethodCard_Order_By = {
  WUI_PaymentMethod?: InputMaybe<Wui_PaymentMethod_Order_By>;
  allow_3d_secure?: InputMaybe<Order_By>;
  brand?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fingerprint?: InputMaybe<Order_By>;
  funding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last4?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_PaymentMethodCard */
export type Wui_PaymentMethodCard_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_PaymentMethodCard" */
export enum Wui_PaymentMethodCard_Select_Column {
  /** column name */
  Allow_3dSecure = 'allow_3d_secure',
  /** column name */
  Brand = 'brand',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpMonth = 'exp_month',
  /** column name */
  ExpYear = 'exp_year',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Fingerprint = 'fingerprint',
  /** column name */
  Funding = 'funding',
  /** column name */
  Id = 'id',
  /** column name */
  Last4 = 'last4',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "WUI_PaymentMethodCard_aggregate_bool_exp_bool_and_arguments_columns" columns of table "WUI_PaymentMethodCard" */
export enum Wui_PaymentMethodCard_Select_Column_Wui_PaymentMethodCard_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Allow_3dSecure = 'allow_3d_secure'
}

/** select "WUI_PaymentMethodCard_aggregate_bool_exp_bool_or_arguments_columns" columns of table "WUI_PaymentMethodCard" */
export enum Wui_PaymentMethodCard_Select_Column_Wui_PaymentMethodCard_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Allow_3dSecure = 'allow_3d_secure'
}

/** input type for updating data in table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Set_Input = {
  allow_3d_secure?: InputMaybe<Scalars['Boolean']>;
  brand?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  exp_month?: InputMaybe<Scalars['Int']>;
  exp_year?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  fingerprint?: InputMaybe<Scalars['String']>;
  funding?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  last4?: InputMaybe<Scalars['String']>;
  payment_method_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Wui_PaymentMethodCard_Stddev_Fields = {
  __typename?: 'WUI_PaymentMethodCard_stddev_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Stddev_Order_By = {
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_PaymentMethodCard_Stddev_Pop_Fields = {
  __typename?: 'WUI_PaymentMethodCard_stddev_pop_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Stddev_Pop_Order_By = {
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_PaymentMethodCard_Stddev_Samp_Fields = {
  __typename?: 'WUI_PaymentMethodCard_stddev_samp_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Stddev_Samp_Order_By = {
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_PaymentMethodCard_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_PaymentMethodCard_Stream_Cursor_Value_Input = {
  allow_3d_secure?: InputMaybe<Scalars['Boolean']>;
  brand?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  exp_month?: InputMaybe<Scalars['Int']>;
  exp_year?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  fingerprint?: InputMaybe<Scalars['String']>;
  funding?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  last4?: InputMaybe<Scalars['String']>;
  payment_method_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Wui_PaymentMethodCard_Sum_Fields = {
  __typename?: 'WUI_PaymentMethodCard_sum_fields';
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Sum_Order_By = {
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** update columns of table "WUI_PaymentMethodCard" */
export enum Wui_PaymentMethodCard_Update_Column {
  /** column name */
  Allow_3dSecure = 'allow_3d_secure',
  /** column name */
  Brand = 'brand',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpMonth = 'exp_month',
  /** column name */
  ExpYear = 'exp_year',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Fingerprint = 'fingerprint',
  /** column name */
  Funding = 'funding',
  /** column name */
  Id = 'id',
  /** column name */
  Last4 = 'last4',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Wui_PaymentMethodCard_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_PaymentMethodCard_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_PaymentMethodCard_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_PaymentMethodCard_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_PaymentMethodCard_Var_Pop_Fields = {
  __typename?: 'WUI_PaymentMethodCard_var_pop_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Var_Pop_Order_By = {
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_PaymentMethodCard_Var_Samp_Fields = {
  __typename?: 'WUI_PaymentMethodCard_var_samp_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Var_Samp_Order_By = {
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_PaymentMethodCard_Variance_Fields = {
  __typename?: 'WUI_PaymentMethodCard_variance_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Variance_Order_By = {
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource = {
  __typename?: 'WUI_PaymentMethodSource';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource_Aggregate = {
  __typename?: 'WUI_PaymentMethodSource_aggregate';
  aggregate?: Maybe<Wui_PaymentMethodSource_Aggregate_Fields>;
  nodes: Array<Wui_PaymentMethodSource>;
};

/** aggregate fields of "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource_Aggregate_Fields = {
  __typename?: 'WUI_PaymentMethodSource_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_PaymentMethodSource_Max_Fields>;
  min?: Maybe<Wui_PaymentMethodSource_Min_Fields>;
};


/** aggregate fields of "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_PaymentMethodSource_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_PaymentMethodSource". All fields are combined with a logical 'AND'. */
export type Wui_PaymentMethodSource_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_PaymentMethodSource_Bool_Exp>>;
  _not?: InputMaybe<Wui_PaymentMethodSource_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_PaymentMethodSource_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_PaymentMethodSource" */
export enum Wui_PaymentMethodSource_Constraint {
  /** unique or primary key constraint on columns "value" */
  WuiPaymentMethodSourceEnumPkey = 'WUI_PaymentMethodSourceEnum_pkey'
}

export enum Wui_PaymentMethodSource_Enum {
  Stripe = 'stripe'
}

/** Boolean expression to compare columns of type "WUI_PaymentMethodSource_enum". All fields are combined with logical 'AND'. */
export type Wui_PaymentMethodSource_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Wui_PaymentMethodSource_Enum>;
  _in?: InputMaybe<Array<Wui_PaymentMethodSource_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Wui_PaymentMethodSource_Enum>;
  _nin?: InputMaybe<Array<Wui_PaymentMethodSource_Enum>>;
};

/** input type for inserting data into table "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_PaymentMethodSource_Max_Fields = {
  __typename?: 'WUI_PaymentMethodSource_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_PaymentMethodSource_Min_Fields = {
  __typename?: 'WUI_PaymentMethodSource_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource_Mutation_Response = {
  __typename?: 'WUI_PaymentMethodSource_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_PaymentMethodSource>;
};

/** input type for inserting object relation for remote table "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource_Obj_Rel_Insert_Input = {
  data: Wui_PaymentMethodSource_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_PaymentMethodSource_On_Conflict>;
};

/** on_conflict condition type for table "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource_On_Conflict = {
  constraint: Wui_PaymentMethodSource_Constraint;
  update_columns?: Array<Wui_PaymentMethodSource_Update_Column>;
  where?: InputMaybe<Wui_PaymentMethodSource_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_PaymentMethodSource". */
export type Wui_PaymentMethodSource_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_PaymentMethodSource */
export type Wui_PaymentMethodSource_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_PaymentMethodSource" */
export enum Wui_PaymentMethodSource_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_PaymentMethodSource_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_PaymentMethodSource_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "WUI_PaymentMethodSource" */
export enum Wui_PaymentMethodSource_Update_Column {
  /** column name */
  Value = 'value'
}

export type Wui_PaymentMethodSource_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_PaymentMethodSource_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_PaymentMethodSource_Bool_Exp;
};

/** aggregated selection of "WUI_PaymentMethod" */
export type Wui_PaymentMethod_Aggregate = {
  __typename?: 'WUI_PaymentMethod_aggregate';
  aggregate?: Maybe<Wui_PaymentMethod_Aggregate_Fields>;
  nodes: Array<Wui_PaymentMethod>;
};

/** aggregate fields of "WUI_PaymentMethod" */
export type Wui_PaymentMethod_Aggregate_Fields = {
  __typename?: 'WUI_PaymentMethod_aggregate_fields';
  avg?: Maybe<Wui_PaymentMethod_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_PaymentMethod_Max_Fields>;
  min?: Maybe<Wui_PaymentMethod_Min_Fields>;
  stddev?: Maybe<Wui_PaymentMethod_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_PaymentMethod_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_PaymentMethod_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_PaymentMethod_Sum_Fields>;
  var_pop?: Maybe<Wui_PaymentMethod_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_PaymentMethod_Var_Samp_Fields>;
  variance?: Maybe<Wui_PaymentMethod_Variance_Fields>;
};


/** aggregate fields of "WUI_PaymentMethod" */
export type Wui_PaymentMethod_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_PaymentMethod_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_PaymentMethod_Avg_Fields = {
  __typename?: 'WUI_PaymentMethod_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_PaymentMethod". All fields are combined with a logical 'AND'. */
export type Wui_PaymentMethod_Bool_Exp = {
  WUI_PaymentMethodBankAccounts?: InputMaybe<Wui_PaymentMethodBankAccount_Bool_Exp>;
  WUI_PaymentMethodBankAccounts_aggregate?: InputMaybe<Wui_PaymentMethodBankAccount_Aggregate_Bool_Exp>;
  WUI_PaymentMethodCards?: InputMaybe<Wui_PaymentMethodCard_Bool_Exp>;
  WUI_PaymentMethodCards_aggregate?: InputMaybe<Wui_PaymentMethodCard_Aggregate_Bool_Exp>;
  WUI_PaymentMethodSource?: InputMaybe<Wui_PaymentMethodSource_Bool_Exp>;
  WUI_User?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_PaymentMethod_Bool_Exp>>;
  _not?: InputMaybe<Wui_PaymentMethod_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_PaymentMethod_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  source?: InputMaybe<Wui_PaymentMethodSource_Enum_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_PaymentMethod" */
export enum Wui_PaymentMethod_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiPaymentMethodPkey = 'WUI_PaymentMethod_pkey'
}

/** input type for incrementing numeric columns in table "WUI_PaymentMethod" */
export type Wui_PaymentMethod_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_PaymentMethod" */
export type Wui_PaymentMethod_Insert_Input = {
  WUI_PaymentMethodBankAccounts?: InputMaybe<Wui_PaymentMethodBankAccount_Arr_Rel_Insert_Input>;
  WUI_PaymentMethodCards?: InputMaybe<Wui_PaymentMethodCard_Arr_Rel_Insert_Input>;
  WUI_PaymentMethodSource?: InputMaybe<Wui_PaymentMethodSource_Obj_Rel_Insert_Input>;
  WUI_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  source?: InputMaybe<Wui_PaymentMethodSource_Enum>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_PaymentMethod_Max_Fields = {
  __typename?: 'WUI_PaymentMethod_max_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Wui_PaymentMethod_Min_Fields = {
  __typename?: 'WUI_PaymentMethod_min_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "WUI_PaymentMethod" */
export type Wui_PaymentMethod_Mutation_Response = {
  __typename?: 'WUI_PaymentMethod_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_PaymentMethod>;
};

/** input type for inserting object relation for remote table "WUI_PaymentMethod" */
export type Wui_PaymentMethod_Obj_Rel_Insert_Input = {
  data: Wui_PaymentMethod_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_PaymentMethod_On_Conflict>;
};

/** on_conflict condition type for table "WUI_PaymentMethod" */
export type Wui_PaymentMethod_On_Conflict = {
  constraint: Wui_PaymentMethod_Constraint;
  update_columns?: Array<Wui_PaymentMethod_Update_Column>;
  where?: InputMaybe<Wui_PaymentMethod_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_PaymentMethod". */
export type Wui_PaymentMethod_Order_By = {
  WUI_PaymentMethodBankAccounts_aggregate?: InputMaybe<Wui_PaymentMethodBankAccount_Aggregate_Order_By>;
  WUI_PaymentMethodCards_aggregate?: InputMaybe<Wui_PaymentMethodCard_Aggregate_Order_By>;
  WUI_PaymentMethodSource?: InputMaybe<Wui_PaymentMethodSource_Order_By>;
  WUI_User?: InputMaybe<Wui_User_Order_By>;
  id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_PaymentMethod */
export type Wui_PaymentMethod_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_PaymentMethod" */
export enum Wui_PaymentMethod_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "WUI_PaymentMethod" */
export type Wui_PaymentMethod_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  source?: InputMaybe<Wui_PaymentMethodSource_Enum>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_PaymentMethod_Stddev_Fields = {
  __typename?: 'WUI_PaymentMethod_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_PaymentMethod_Stddev_Pop_Fields = {
  __typename?: 'WUI_PaymentMethod_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_PaymentMethod_Stddev_Samp_Fields = {
  __typename?: 'WUI_PaymentMethod_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WUI_PaymentMethod" */
export type Wui_PaymentMethod_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_PaymentMethod_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_PaymentMethod_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  source?: InputMaybe<Wui_PaymentMethodSource_Enum>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_PaymentMethod_Sum_Fields = {
  __typename?: 'WUI_PaymentMethod_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_PaymentMethod" */
export enum Wui_PaymentMethod_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source',
  /** column name */
  UserId = 'user_id'
}

export type Wui_PaymentMethod_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_PaymentMethod_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_PaymentMethod_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_PaymentMethod_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_PaymentMethod_Var_Pop_Fields = {
  __typename?: 'WUI_PaymentMethod_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_PaymentMethod_Var_Samp_Fields = {
  __typename?: 'WUI_PaymentMethod_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_PaymentMethod_Variance_Fields = {
  __typename?: 'WUI_PaymentMethod_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_PaymentStatus" */
export type Wui_PaymentStatus = {
  __typename?: 'WUI_PaymentStatus';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_PaymentStatus" */
export type Wui_PaymentStatus_Aggregate = {
  __typename?: 'WUI_PaymentStatus_aggregate';
  aggregate?: Maybe<Wui_PaymentStatus_Aggregate_Fields>;
  nodes: Array<Wui_PaymentStatus>;
};

/** aggregate fields of "WUI_PaymentStatus" */
export type Wui_PaymentStatus_Aggregate_Fields = {
  __typename?: 'WUI_PaymentStatus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_PaymentStatus_Max_Fields>;
  min?: Maybe<Wui_PaymentStatus_Min_Fields>;
};


/** aggregate fields of "WUI_PaymentStatus" */
export type Wui_PaymentStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_PaymentStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_PaymentStatus". All fields are combined with a logical 'AND'. */
export type Wui_PaymentStatus_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_PaymentStatus_Bool_Exp>>;
  _not?: InputMaybe<Wui_PaymentStatus_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_PaymentStatus_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_PaymentStatus" */
export enum Wui_PaymentStatus_Constraint {
  /** unique or primary key constraint on columns "value" */
  WuiPaymentStatusPkey = 'WUI_PaymentStatus_pkey'
}

export enum Wui_PaymentStatus_Enum {
  Captured = 'captured',
  Declined = 'declined',
  Pending = 'pending',
  Refunded = 'refunded',
  Uncaptured = 'uncaptured'
}

/** Boolean expression to compare columns of type "WUI_PaymentStatus_enum". All fields are combined with logical 'AND'. */
export type Wui_PaymentStatus_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Wui_PaymentStatus_Enum>;
  _in?: InputMaybe<Array<Wui_PaymentStatus_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Wui_PaymentStatus_Enum>;
  _nin?: InputMaybe<Array<Wui_PaymentStatus_Enum>>;
};

/** input type for inserting data into table "WUI_PaymentStatus" */
export type Wui_PaymentStatus_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_PaymentStatus_Max_Fields = {
  __typename?: 'WUI_PaymentStatus_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_PaymentStatus_Min_Fields = {
  __typename?: 'WUI_PaymentStatus_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_PaymentStatus" */
export type Wui_PaymentStatus_Mutation_Response = {
  __typename?: 'WUI_PaymentStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_PaymentStatus>;
};

/** input type for inserting object relation for remote table "WUI_PaymentStatus" */
export type Wui_PaymentStatus_Obj_Rel_Insert_Input = {
  data: Wui_PaymentStatus_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_PaymentStatus_On_Conflict>;
};

/** on_conflict condition type for table "WUI_PaymentStatus" */
export type Wui_PaymentStatus_On_Conflict = {
  constraint: Wui_PaymentStatus_Constraint;
  update_columns?: Array<Wui_PaymentStatus_Update_Column>;
  where?: InputMaybe<Wui_PaymentStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_PaymentStatus". */
export type Wui_PaymentStatus_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_PaymentStatus */
export type Wui_PaymentStatus_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_PaymentStatus" */
export enum Wui_PaymentStatus_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_PaymentStatus" */
export type Wui_PaymentStatus_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "WUI_PaymentStatus" */
export type Wui_PaymentStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_PaymentStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_PaymentStatus_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "WUI_PaymentStatus" */
export enum Wui_PaymentStatus_Update_Column {
  /** column name */
  Value = 'value'
}

export type Wui_PaymentStatus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_PaymentStatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_PaymentStatus_Bool_Exp;
};

/** aggregated selection of "WUI_Payment" */
export type Wui_Payment_Aggregate = {
  __typename?: 'WUI_Payment_aggregate';
  aggregate?: Maybe<Wui_Payment_Aggregate_Fields>;
  nodes: Array<Wui_Payment>;
};

export type Wui_Payment_Aggregate_Bool_Exp = {
  count?: InputMaybe<Wui_Payment_Aggregate_Bool_Exp_Count>;
};

export type Wui_Payment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wui_Payment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_Payment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WUI_Payment" */
export type Wui_Payment_Aggregate_Fields = {
  __typename?: 'WUI_Payment_aggregate_fields';
  avg?: Maybe<Wui_Payment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_Payment_Max_Fields>;
  min?: Maybe<Wui_Payment_Min_Fields>;
  stddev?: Maybe<Wui_Payment_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_Payment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_Payment_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_Payment_Sum_Fields>;
  var_pop?: Maybe<Wui_Payment_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_Payment_Var_Samp_Fields>;
  variance?: Maybe<Wui_Payment_Variance_Fields>;
};


/** aggregate fields of "WUI_Payment" */
export type Wui_Payment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_Payment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_Payment" */
export type Wui_Payment_Aggregate_Order_By = {
  avg?: InputMaybe<Wui_Payment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wui_Payment_Max_Order_By>;
  min?: InputMaybe<Wui_Payment_Min_Order_By>;
  stddev?: InputMaybe<Wui_Payment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wui_Payment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wui_Payment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wui_Payment_Sum_Order_By>;
  var_pop?: InputMaybe<Wui_Payment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wui_Payment_Var_Samp_Order_By>;
  variance?: InputMaybe<Wui_Payment_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Wui_Payment_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "WUI_Payment" */
export type Wui_Payment_Arr_Rel_Insert_Input = {
  data: Array<Wui_Payment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_Payment_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_Payment_Avg_Fields = {
  __typename?: 'WUI_Payment_avg_fields';
  buyer_fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_fees?: Maybe<Scalars['Float']>;
  transaction_fees?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_Payment" */
export type Wui_Payment_Avg_Order_By = {
  buyer_fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  seller_fees?: InputMaybe<Order_By>;
  transaction_fees?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_Payment". All fields are combined with a logical 'AND'. */
export type Wui_Payment_Bool_Exp = {
  WUI_Order?: InputMaybe<Wui_Order_Bool_Exp>;
  WUI_PaymentStatus?: InputMaybe<Wui_PaymentStatus_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_Payment_Bool_Exp>>;
  _not?: InputMaybe<Wui_Payment_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_Payment_Bool_Exp>>;
  buyer_fees?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency_code?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  error_code?: InputMaybe<String_Comparison_Exp>;
  error_msg?: InputMaybe<String_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  order_id?: InputMaybe<Bigint_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  seller_fees?: InputMaybe<Numeric_Comparison_Exp>;
  statement_description?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Wui_PaymentStatus_Enum_Comparison_Exp>;
  transaction_fees?: InputMaybe<Numeric_Comparison_Exp>;
  transferred_to_acc?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_Payment" */
export enum Wui_Payment_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiPaymentPkey = 'WUI_Payment_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Wui_Payment_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Wui_Payment_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Wui_Payment_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "WUI_Payment" */
export type Wui_Payment_Inc_Input = {
  buyer_fees?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  seller_fees?: InputMaybe<Scalars['numeric']>;
  transaction_fees?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "WUI_Payment" */
export type Wui_Payment_Insert_Input = {
  WUI_Order?: InputMaybe<Wui_Order_Obj_Rel_Insert_Input>;
  WUI_PaymentStatus?: InputMaybe<Wui_PaymentStatus_Obj_Rel_Insert_Input>;
  buyer_fees?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency_code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  error_code?: InputMaybe<Scalars['String']>;
  error_msg?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  seller_fees?: InputMaybe<Scalars['numeric']>;
  statement_description?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Wui_PaymentStatus_Enum>;
  transaction_fees?: InputMaybe<Scalars['numeric']>;
  transferred_to_acc?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Wui_Payment_Max_Fields = {
  __typename?: 'WUI_Payment_max_fields';
  buyer_fees?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  error_code?: Maybe<Scalars['String']>;
  error_msg?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  seller_fees?: Maybe<Scalars['numeric']>;
  statement_description?: Maybe<Scalars['String']>;
  transaction_fees?: Maybe<Scalars['numeric']>;
  transferred_to_acc?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "WUI_Payment" */
export type Wui_Payment_Max_Order_By = {
  buyer_fees?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency_code?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  error_code?: InputMaybe<Order_By>;
  error_msg?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  seller_fees?: InputMaybe<Order_By>;
  statement_description?: InputMaybe<Order_By>;
  transaction_fees?: InputMaybe<Order_By>;
  transferred_to_acc?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_Payment_Min_Fields = {
  __typename?: 'WUI_Payment_min_fields';
  buyer_fees?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  error_code?: Maybe<Scalars['String']>;
  error_msg?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  seller_fees?: Maybe<Scalars['numeric']>;
  statement_description?: Maybe<Scalars['String']>;
  transaction_fees?: Maybe<Scalars['numeric']>;
  transferred_to_acc?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "WUI_Payment" */
export type Wui_Payment_Min_Order_By = {
  buyer_fees?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency_code?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  error_code?: InputMaybe<Order_By>;
  error_msg?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  seller_fees?: InputMaybe<Order_By>;
  statement_description?: InputMaybe<Order_By>;
  transaction_fees?: InputMaybe<Order_By>;
  transferred_to_acc?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WUI_Payment" */
export type Wui_Payment_Mutation_Response = {
  __typename?: 'WUI_Payment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_Payment>;
};

/** input type for inserting object relation for remote table "WUI_Payment" */
export type Wui_Payment_Obj_Rel_Insert_Input = {
  data: Wui_Payment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_Payment_On_Conflict>;
};

/** on_conflict condition type for table "WUI_Payment" */
export type Wui_Payment_On_Conflict = {
  constraint: Wui_Payment_Constraint;
  update_columns?: Array<Wui_Payment_Update_Column>;
  where?: InputMaybe<Wui_Payment_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_Payment". */
export type Wui_Payment_Order_By = {
  WUI_Order?: InputMaybe<Wui_Order_Order_By>;
  WUI_PaymentStatus?: InputMaybe<Wui_PaymentStatus_Order_By>;
  buyer_fees?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency_code?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  error_code?: InputMaybe<Order_By>;
  error_msg?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  seller_fees?: InputMaybe<Order_By>;
  statement_description?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transaction_fees?: InputMaybe<Order_By>;
  transferred_to_acc?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_Payment */
export type Wui_Payment_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Wui_Payment_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "WUI_Payment" */
export enum Wui_Payment_Select_Column {
  /** column name */
  BuyerFees = 'buyer_fees',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  Description = 'description',
  /** column name */
  ErrorCode = 'error_code',
  /** column name */
  ErrorMsg = 'error_msg',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Price = 'price',
  /** column name */
  SellerFees = 'seller_fees',
  /** column name */
  StatementDescription = 'statement_description',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionFees = 'transaction_fees',
  /** column name */
  TransferredToAcc = 'transferred_to_acc',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "WUI_Payment" */
export type Wui_Payment_Set_Input = {
  buyer_fees?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency_code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  error_code?: InputMaybe<Scalars['String']>;
  error_msg?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  seller_fees?: InputMaybe<Scalars['numeric']>;
  statement_description?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Wui_PaymentStatus_Enum>;
  transaction_fees?: InputMaybe<Scalars['numeric']>;
  transferred_to_acc?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Wui_Payment_Stddev_Fields = {
  __typename?: 'WUI_Payment_stddev_fields';
  buyer_fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_fees?: Maybe<Scalars['Float']>;
  transaction_fees?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_Payment" */
export type Wui_Payment_Stddev_Order_By = {
  buyer_fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  seller_fees?: InputMaybe<Order_By>;
  transaction_fees?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_Payment_Stddev_Pop_Fields = {
  __typename?: 'WUI_Payment_stddev_pop_fields';
  buyer_fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_fees?: Maybe<Scalars['Float']>;
  transaction_fees?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_Payment" */
export type Wui_Payment_Stddev_Pop_Order_By = {
  buyer_fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  seller_fees?: InputMaybe<Order_By>;
  transaction_fees?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_Payment_Stddev_Samp_Fields = {
  __typename?: 'WUI_Payment_stddev_samp_fields';
  buyer_fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_fees?: Maybe<Scalars['Float']>;
  transaction_fees?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_Payment" */
export type Wui_Payment_Stddev_Samp_Order_By = {
  buyer_fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  seller_fees?: InputMaybe<Order_By>;
  transaction_fees?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WUI_Payment" */
export type Wui_Payment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_Payment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_Payment_Stream_Cursor_Value_Input = {
  buyer_fees?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency_code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  error_code?: InputMaybe<Scalars['String']>;
  error_msg?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  seller_fees?: InputMaybe<Scalars['numeric']>;
  statement_description?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Wui_PaymentStatus_Enum>;
  transaction_fees?: InputMaybe<Scalars['numeric']>;
  transferred_to_acc?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Wui_Payment_Sum_Fields = {
  __typename?: 'WUI_Payment_sum_fields';
  buyer_fees?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  seller_fees?: Maybe<Scalars['numeric']>;
  transaction_fees?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "WUI_Payment" */
export type Wui_Payment_Sum_Order_By = {
  buyer_fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  seller_fees?: InputMaybe<Order_By>;
  transaction_fees?: InputMaybe<Order_By>;
};

/** update columns of table "WUI_Payment" */
export enum Wui_Payment_Update_Column {
  /** column name */
  BuyerFees = 'buyer_fees',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  Description = 'description',
  /** column name */
  ErrorCode = 'error_code',
  /** column name */
  ErrorMsg = 'error_msg',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Price = 'price',
  /** column name */
  SellerFees = 'seller_fees',
  /** column name */
  StatementDescription = 'statement_description',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionFees = 'transaction_fees',
  /** column name */
  TransferredToAcc = 'transferred_to_acc',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Wui_Payment_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Wui_Payment_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Wui_Payment_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Wui_Payment_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Wui_Payment_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_Payment_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Wui_Payment_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_Payment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_Payment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_Payment_Var_Pop_Fields = {
  __typename?: 'WUI_Payment_var_pop_fields';
  buyer_fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_fees?: Maybe<Scalars['Float']>;
  transaction_fees?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_Payment" */
export type Wui_Payment_Var_Pop_Order_By = {
  buyer_fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  seller_fees?: InputMaybe<Order_By>;
  transaction_fees?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_Payment_Var_Samp_Fields = {
  __typename?: 'WUI_Payment_var_samp_fields';
  buyer_fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_fees?: Maybe<Scalars['Float']>;
  transaction_fees?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_Payment" */
export type Wui_Payment_Var_Samp_Order_By = {
  buyer_fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  seller_fees?: InputMaybe<Order_By>;
  transaction_fees?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_Payment_Variance_Fields = {
  __typename?: 'WUI_Payment_variance_fields';
  buyer_fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_fees?: Maybe<Scalars['Float']>;
  transaction_fees?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_Payment" */
export type Wui_Payment_Variance_Order_By = {
  buyer_fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  seller_fees?: InputMaybe<Order_By>;
  transaction_fees?: InputMaybe<Order_By>;
};

/** columns and relationships of "WUI_RequestStatus" */
export type Wui_RequestStatus = {
  __typename?: 'WUI_RequestStatus';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_RequestStatus" */
export type Wui_RequestStatus_Aggregate = {
  __typename?: 'WUI_RequestStatus_aggregate';
  aggregate?: Maybe<Wui_RequestStatus_Aggregate_Fields>;
  nodes: Array<Wui_RequestStatus>;
};

/** aggregate fields of "WUI_RequestStatus" */
export type Wui_RequestStatus_Aggregate_Fields = {
  __typename?: 'WUI_RequestStatus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_RequestStatus_Max_Fields>;
  min?: Maybe<Wui_RequestStatus_Min_Fields>;
};


/** aggregate fields of "WUI_RequestStatus" */
export type Wui_RequestStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_RequestStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_RequestStatus". All fields are combined with a logical 'AND'. */
export type Wui_RequestStatus_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_RequestStatus_Bool_Exp>>;
  _not?: InputMaybe<Wui_RequestStatus_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_RequestStatus_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_RequestStatus" */
export enum Wui_RequestStatus_Constraint {
  /** unique or primary key constraint on columns "value" */
  WuiRequestStatusPkey = 'WUI_RequestStatus_pkey'
}

export enum Wui_RequestStatus_Enum {
  Accepted = 'accepted',
  Cancelled = 'cancelled',
  Pending = 'pending',
  Rejected = 'rejected'
}

/** Boolean expression to compare columns of type "WUI_RequestStatus_enum". All fields are combined with logical 'AND'. */
export type Wui_RequestStatus_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Wui_RequestStatus_Enum>;
  _in?: InputMaybe<Array<Wui_RequestStatus_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Wui_RequestStatus_Enum>;
  _nin?: InputMaybe<Array<Wui_RequestStatus_Enum>>;
};

/** input type for inserting data into table "WUI_RequestStatus" */
export type Wui_RequestStatus_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_RequestStatus_Max_Fields = {
  __typename?: 'WUI_RequestStatus_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_RequestStatus_Min_Fields = {
  __typename?: 'WUI_RequestStatus_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_RequestStatus" */
export type Wui_RequestStatus_Mutation_Response = {
  __typename?: 'WUI_RequestStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_RequestStatus>;
};

/** on_conflict condition type for table "WUI_RequestStatus" */
export type Wui_RequestStatus_On_Conflict = {
  constraint: Wui_RequestStatus_Constraint;
  update_columns?: Array<Wui_RequestStatus_Update_Column>;
  where?: InputMaybe<Wui_RequestStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_RequestStatus". */
export type Wui_RequestStatus_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_RequestStatus */
export type Wui_RequestStatus_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_RequestStatus" */
export enum Wui_RequestStatus_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_RequestStatus" */
export type Wui_RequestStatus_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "WUI_RequestStatus" */
export type Wui_RequestStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_RequestStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_RequestStatus_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "WUI_RequestStatus" */
export enum Wui_RequestStatus_Update_Column {
  /** column name */
  Value = 'value'
}

export type Wui_RequestStatus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_RequestStatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_RequestStatus_Bool_Exp;
};

/** columns and relationships of "WUI_Role" */
export type Wui_Role = {
  __typename?: 'WUI_Role';
  id: Scalars['Int'];
  value: Scalars['String'];
};

/** aggregated selection of "WUI_Role" */
export type Wui_Role_Aggregate = {
  __typename?: 'WUI_Role_aggregate';
  aggregate?: Maybe<Wui_Role_Aggregate_Fields>;
  nodes: Array<Wui_Role>;
};

/** aggregate fields of "WUI_Role" */
export type Wui_Role_Aggregate_Fields = {
  __typename?: 'WUI_Role_aggregate_fields';
  avg?: Maybe<Wui_Role_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_Role_Max_Fields>;
  min?: Maybe<Wui_Role_Min_Fields>;
  stddev?: Maybe<Wui_Role_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_Role_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_Role_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_Role_Sum_Fields>;
  var_pop?: Maybe<Wui_Role_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_Role_Var_Samp_Fields>;
  variance?: Maybe<Wui_Role_Variance_Fields>;
};


/** aggregate fields of "WUI_Role" */
export type Wui_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_Role_Avg_Fields = {
  __typename?: 'WUI_Role_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_Role". All fields are combined with a logical 'AND'. */
export type Wui_Role_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_Role_Bool_Exp>>;
  _not?: InputMaybe<Wui_Role_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_Role_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_Role" */
export enum Wui_Role_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiRolePkey = 'WUI_Role_pkey',
  /** unique or primary key constraint on columns "value" */
  WuiRoleValueKey = 'WUI_Role_value_key'
}

/** input type for incrementing numeric columns in table "WUI_Role" */
export type Wui_Role_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "WUI_Role" */
export type Wui_Role_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_Role_Max_Fields = {
  __typename?: 'WUI_Role_max_fields';
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_Role_Min_Fields = {
  __typename?: 'WUI_Role_min_fields';
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_Role" */
export type Wui_Role_Mutation_Response = {
  __typename?: 'WUI_Role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_Role>;
};

/** on_conflict condition type for table "WUI_Role" */
export type Wui_Role_On_Conflict = {
  constraint: Wui_Role_Constraint;
  update_columns?: Array<Wui_Role_Update_Column>;
  where?: InputMaybe<Wui_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_Role". */
export type Wui_Role_Order_By = {
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_Role */
export type Wui_Role_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "WUI_Role" */
export enum Wui_Role_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_Role" */
export type Wui_Role_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Wui_Role_Stddev_Fields = {
  __typename?: 'WUI_Role_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_Role_Stddev_Pop_Fields = {
  __typename?: 'WUI_Role_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_Role_Stddev_Samp_Fields = {
  __typename?: 'WUI_Role_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WUI_Role" */
export type Wui_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_Role_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Wui_Role_Sum_Fields = {
  __typename?: 'WUI_Role_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "WUI_Role" */
export enum Wui_Role_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

export type Wui_Role_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_Role_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_Role_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_Role_Var_Pop_Fields = {
  __typename?: 'WUI_Role_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_Role_Var_Samp_Fields = {
  __typename?: 'WUI_Role_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_Role_Variance_Fields = {
  __typename?: 'WUI_Role_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_SellerPackage" */
export type Wui_SellerPackage = {
  __typename?: 'WUI_SellerPackage';
  /** An array relationship */
  WUI_SellerPackageItems: Array<Wui_SellerPackageItem>;
  /** An aggregate relationship */
  WUI_SellerPackageItems_aggregate: Wui_SellerPackageItem_Aggregate;
  /** An object relationship */
  WUI_User: Wui_User;
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  hide_price: Scalars['Boolean'];
  id: Scalars['bigint'];
  metadata?: Maybe<Scalars['jsonb']>;
  package_type: Wui_SellerPackageType_Enum;
  price: Scalars['numeric'];
  price_request_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  user_id: Scalars['bigint'];
};


/** columns and relationships of "WUI_SellerPackage" */
export type Wui_SellerPackageWui_SellerPackageItemsArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerPackageItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerPackageItem_Order_By>>;
  where?: InputMaybe<Wui_SellerPackageItem_Bool_Exp>;
};


/** columns and relationships of "WUI_SellerPackage" */
export type Wui_SellerPackageWui_SellerPackageItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerPackageItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerPackageItem_Order_By>>;
  where?: InputMaybe<Wui_SellerPackageItem_Bool_Exp>;
};


/** columns and relationships of "WUI_SellerPackage" */
export type Wui_SellerPackageMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem = {
  __typename?: 'WUI_SellerPackageItem';
  /** An object relationship */
  WUI_SellerPackage: Wui_SellerPackage;
  comment?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  quantity?: Maybe<Scalars['Int']>;
  seller_package_id: Scalars['bigint'];
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Aggregate = {
  __typename?: 'WUI_SellerPackageItem_aggregate';
  aggregate?: Maybe<Wui_SellerPackageItem_Aggregate_Fields>;
  nodes: Array<Wui_SellerPackageItem>;
};

export type Wui_SellerPackageItem_Aggregate_Bool_Exp = {
  count?: InputMaybe<Wui_SellerPackageItem_Aggregate_Bool_Exp_Count>;
};

export type Wui_SellerPackageItem_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wui_SellerPackageItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_SellerPackageItem_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Aggregate_Fields = {
  __typename?: 'WUI_SellerPackageItem_aggregate_fields';
  avg?: Maybe<Wui_SellerPackageItem_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_SellerPackageItem_Max_Fields>;
  min?: Maybe<Wui_SellerPackageItem_Min_Fields>;
  stddev?: Maybe<Wui_SellerPackageItem_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_SellerPackageItem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_SellerPackageItem_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_SellerPackageItem_Sum_Fields>;
  var_pop?: Maybe<Wui_SellerPackageItem_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_SellerPackageItem_Var_Samp_Fields>;
  variance?: Maybe<Wui_SellerPackageItem_Variance_Fields>;
};


/** aggregate fields of "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_SellerPackageItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Aggregate_Order_By = {
  avg?: InputMaybe<Wui_SellerPackageItem_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wui_SellerPackageItem_Max_Order_By>;
  min?: InputMaybe<Wui_SellerPackageItem_Min_Order_By>;
  stddev?: InputMaybe<Wui_SellerPackageItem_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wui_SellerPackageItem_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wui_SellerPackageItem_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wui_SellerPackageItem_Sum_Order_By>;
  var_pop?: InputMaybe<Wui_SellerPackageItem_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wui_SellerPackageItem_Var_Samp_Order_By>;
  variance?: InputMaybe<Wui_SellerPackageItem_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Arr_Rel_Insert_Input = {
  data: Array<Wui_SellerPackageItem_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_SellerPackageItem_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_SellerPackageItem_Avg_Fields = {
  __typename?: 'WUI_SellerPackageItem_avg_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  seller_package_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  seller_package_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_SellerPackageItem". All fields are combined with a logical 'AND'. */
export type Wui_SellerPackageItem_Bool_Exp = {
  WUI_SellerPackage?: InputMaybe<Wui_SellerPackage_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_SellerPackageItem_Bool_Exp>>;
  _not?: InputMaybe<Wui_SellerPackageItem_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_SellerPackageItem_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  seller_package_id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SellerPackageItem" */
export enum Wui_SellerPackageItem_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiSellerPackageItemPkey = 'WUI_SellerPackageItem_pkey'
}

/** input type for incrementing numeric columns in table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['Int']>;
  seller_package_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Insert_Input = {
  WUI_SellerPackage?: InputMaybe<Wui_SellerPackage_Obj_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['Int']>;
  seller_package_id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_SellerPackageItem_Max_Fields = {
  __typename?: 'WUI_SellerPackageItem_max_fields';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['Int']>;
  seller_package_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  seller_package_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_SellerPackageItem_Min_Fields = {
  __typename?: 'WUI_SellerPackageItem_min_fields';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['Int']>;
  seller_package_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  seller_package_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Mutation_Response = {
  __typename?: 'WUI_SellerPackageItem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SellerPackageItem>;
};

/** on_conflict condition type for table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_On_Conflict = {
  constraint: Wui_SellerPackageItem_Constraint;
  update_columns?: Array<Wui_SellerPackageItem_Update_Column>;
  where?: InputMaybe<Wui_SellerPackageItem_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SellerPackageItem". */
export type Wui_SellerPackageItem_Order_By = {
  WUI_SellerPackage?: InputMaybe<Wui_SellerPackage_Order_By>;
  comment?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  seller_package_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_SellerPackageItem */
export type Wui_SellerPackageItem_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_SellerPackageItem" */
export enum Wui_SellerPackageItem_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Id = 'id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SellerPackageId = 'seller_package_id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['Int']>;
  seller_package_id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Wui_SellerPackageItem_Stddev_Fields = {
  __typename?: 'WUI_SellerPackageItem_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  seller_package_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  seller_package_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_SellerPackageItem_Stddev_Pop_Fields = {
  __typename?: 'WUI_SellerPackageItem_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  seller_package_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  seller_package_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_SellerPackageItem_Stddev_Samp_Fields = {
  __typename?: 'WUI_SellerPackageItem_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  seller_package_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  seller_package_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_SellerPackageItem_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_SellerPackageItem_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['Int']>;
  seller_package_id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Wui_SellerPackageItem_Sum_Fields = {
  __typename?: 'WUI_SellerPackageItem_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['Int']>;
  seller_package_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  seller_package_id?: InputMaybe<Order_By>;
};

/** update columns of table "WUI_SellerPackageItem" */
export enum Wui_SellerPackageItem_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Id = 'id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SellerPackageId = 'seller_package_id',
  /** column name */
  Title = 'title'
}

export type Wui_SellerPackageItem_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_SellerPackageItem_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_SellerPackageItem_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_SellerPackageItem_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_SellerPackageItem_Var_Pop_Fields = {
  __typename?: 'WUI_SellerPackageItem_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  seller_package_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  seller_package_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_SellerPackageItem_Var_Samp_Fields = {
  __typename?: 'WUI_SellerPackageItem_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  seller_package_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  seller_package_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_SellerPackageItem_Variance_Fields = {
  __typename?: 'WUI_SellerPackageItem_variance_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  seller_package_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  seller_package_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "WUI_SellerPackageType" */
export type Wui_SellerPackageType = {
  __typename?: 'WUI_SellerPackageType';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_SellerPackageType" */
export type Wui_SellerPackageType_Aggregate = {
  __typename?: 'WUI_SellerPackageType_aggregate';
  aggregate?: Maybe<Wui_SellerPackageType_Aggregate_Fields>;
  nodes: Array<Wui_SellerPackageType>;
};

/** aggregate fields of "WUI_SellerPackageType" */
export type Wui_SellerPackageType_Aggregate_Fields = {
  __typename?: 'WUI_SellerPackageType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_SellerPackageType_Max_Fields>;
  min?: Maybe<Wui_SellerPackageType_Min_Fields>;
};


/** aggregate fields of "WUI_SellerPackageType" */
export type Wui_SellerPackageType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_SellerPackageType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_SellerPackageType". All fields are combined with a logical 'AND'. */
export type Wui_SellerPackageType_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_SellerPackageType_Bool_Exp>>;
  _not?: InputMaybe<Wui_SellerPackageType_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_SellerPackageType_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SellerPackageType" */
export enum Wui_SellerPackageType_Constraint {
  /** unique or primary key constraint on columns "value" */
  WuiSellerPackageTypeEnumPkey = 'WUI_SellerPackageTypeEnum_pkey'
}

export enum Wui_SellerPackageType_Enum {
  Basic = 'basic',
  Custom = 'custom',
  Monthly = 'monthly',
  Standard = 'standard'
}

/** Boolean expression to compare columns of type "WUI_SellerPackageType_enum". All fields are combined with logical 'AND'. */
export type Wui_SellerPackageType_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Wui_SellerPackageType_Enum>;
  _in?: InputMaybe<Array<Wui_SellerPackageType_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Wui_SellerPackageType_Enum>;
  _nin?: InputMaybe<Array<Wui_SellerPackageType_Enum>>;
};

/** input type for inserting data into table "WUI_SellerPackageType" */
export type Wui_SellerPackageType_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_SellerPackageType_Max_Fields = {
  __typename?: 'WUI_SellerPackageType_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_SellerPackageType_Min_Fields = {
  __typename?: 'WUI_SellerPackageType_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_SellerPackageType" */
export type Wui_SellerPackageType_Mutation_Response = {
  __typename?: 'WUI_SellerPackageType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SellerPackageType>;
};

/** on_conflict condition type for table "WUI_SellerPackageType" */
export type Wui_SellerPackageType_On_Conflict = {
  constraint: Wui_SellerPackageType_Constraint;
  update_columns?: Array<Wui_SellerPackageType_Update_Column>;
  where?: InputMaybe<Wui_SellerPackageType_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SellerPackageType". */
export type Wui_SellerPackageType_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_SellerPackageType */
export type Wui_SellerPackageType_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_SellerPackageType" */
export enum Wui_SellerPackageType_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_SellerPackageType" */
export type Wui_SellerPackageType_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "WUI_SellerPackageType" */
export type Wui_SellerPackageType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_SellerPackageType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_SellerPackageType_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "WUI_SellerPackageType" */
export enum Wui_SellerPackageType_Update_Column {
  /** column name */
  Value = 'value'
}

export type Wui_SellerPackageType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_SellerPackageType_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_SellerPackageType_Bool_Exp;
};

/** aggregated selection of "WUI_SellerPackage" */
export type Wui_SellerPackage_Aggregate = {
  __typename?: 'WUI_SellerPackage_aggregate';
  aggregate?: Maybe<Wui_SellerPackage_Aggregate_Fields>;
  nodes: Array<Wui_SellerPackage>;
};

export type Wui_SellerPackage_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Wui_SellerPackage_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Wui_SellerPackage_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Wui_SellerPackage_Aggregate_Bool_Exp_Count>;
};

export type Wui_SellerPackage_Aggregate_Bool_Exp_Bool_And = {
  arguments: Wui_SellerPackage_Select_Column_Wui_SellerPackage_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_SellerPackage_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Wui_SellerPackage_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Wui_SellerPackage_Select_Column_Wui_SellerPackage_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_SellerPackage_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Wui_SellerPackage_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wui_SellerPackage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_SellerPackage_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WUI_SellerPackage" */
export type Wui_SellerPackage_Aggregate_Fields = {
  __typename?: 'WUI_SellerPackage_aggregate_fields';
  avg?: Maybe<Wui_SellerPackage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_SellerPackage_Max_Fields>;
  min?: Maybe<Wui_SellerPackage_Min_Fields>;
  stddev?: Maybe<Wui_SellerPackage_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_SellerPackage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_SellerPackage_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_SellerPackage_Sum_Fields>;
  var_pop?: Maybe<Wui_SellerPackage_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_SellerPackage_Var_Samp_Fields>;
  variance?: Maybe<Wui_SellerPackage_Variance_Fields>;
};


/** aggregate fields of "WUI_SellerPackage" */
export type Wui_SellerPackage_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_SellerPackage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Aggregate_Order_By = {
  avg?: InputMaybe<Wui_SellerPackage_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wui_SellerPackage_Max_Order_By>;
  min?: InputMaybe<Wui_SellerPackage_Min_Order_By>;
  stddev?: InputMaybe<Wui_SellerPackage_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wui_SellerPackage_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wui_SellerPackage_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wui_SellerPackage_Sum_Order_By>;
  var_pop?: InputMaybe<Wui_SellerPackage_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wui_SellerPackage_Var_Samp_Order_By>;
  variance?: InputMaybe<Wui_SellerPackage_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Wui_SellerPackage_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "WUI_SellerPackage" */
export type Wui_SellerPackage_Arr_Rel_Insert_Input = {
  data: Array<Wui_SellerPackage_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_SellerPackage_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_SellerPackage_Avg_Fields = {
  __typename?: 'WUI_SellerPackage_avg_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_request_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  price_request_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_SellerPackage". All fields are combined with a logical 'AND'. */
export type Wui_SellerPackage_Bool_Exp = {
  WUI_SellerPackageItems?: InputMaybe<Wui_SellerPackageItem_Bool_Exp>;
  WUI_SellerPackageItems_aggregate?: InputMaybe<Wui_SellerPackageItem_Aggregate_Bool_Exp>;
  WUI_User?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_SellerPackage_Bool_Exp>>;
  _not?: InputMaybe<Wui_SellerPackage_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_SellerPackage_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  hide_price?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  package_type?: InputMaybe<Wui_SellerPackageType_Enum_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  price_request_id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SellerPackage" */
export enum Wui_SellerPackage_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiSellerPackagePkey = 'WUI_SellerPackage_pkey',
  /** unique or primary key constraint on columns "package_type", "user_id" */
  WuiSellerPackageUserIdPackageTypeKey = 'WUI_SellerPackage_user_id_package_type_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Wui_SellerPackage_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Wui_SellerPackage_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Wui_SellerPackage_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "WUI_SellerPackage" */
export type Wui_SellerPackage_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  price_request_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_SellerPackage" */
export type Wui_SellerPackage_Insert_Input = {
  WUI_SellerPackageItems?: InputMaybe<Wui_SellerPackageItem_Arr_Rel_Insert_Input>;
  WUI_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  hide_price?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  package_type?: InputMaybe<Wui_SellerPackageType_Enum>;
  price?: InputMaybe<Scalars['numeric']>;
  price_request_id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_SellerPackage_Max_Fields = {
  __typename?: 'WUI_SellerPackage_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  price_request_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  price_request_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_SellerPackage_Min_Fields = {
  __typename?: 'WUI_SellerPackage_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  price_request_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  price_request_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WUI_SellerPackage" */
export type Wui_SellerPackage_Mutation_Response = {
  __typename?: 'WUI_SellerPackage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SellerPackage>;
};

/** input type for inserting object relation for remote table "WUI_SellerPackage" */
export type Wui_SellerPackage_Obj_Rel_Insert_Input = {
  data: Wui_SellerPackage_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_SellerPackage_On_Conflict>;
};

/** on_conflict condition type for table "WUI_SellerPackage" */
export type Wui_SellerPackage_On_Conflict = {
  constraint: Wui_SellerPackage_Constraint;
  update_columns?: Array<Wui_SellerPackage_Update_Column>;
  where?: InputMaybe<Wui_SellerPackage_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SellerPackage". */
export type Wui_SellerPackage_Order_By = {
  WUI_SellerPackageItems_aggregate?: InputMaybe<Wui_SellerPackageItem_Aggregate_Order_By>;
  WUI_User?: InputMaybe<Wui_User_Order_By>;
  description?: InputMaybe<Order_By>;
  enabled?: InputMaybe<Order_By>;
  hide_price?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  package_type?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  price_request_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_SellerPackage */
export type Wui_SellerPackage_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Wui_SellerPackage_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "WUI_SellerPackage" */
export enum Wui_SellerPackage_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  HidePrice = 'hide_price',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PackageType = 'package_type',
  /** column name */
  Price = 'price',
  /** column name */
  PriceRequestId = 'price_request_id',
  /** column name */
  Title = 'title',
  /** column name */
  UserId = 'user_id'
}

/** select "WUI_SellerPackage_aggregate_bool_exp_bool_and_arguments_columns" columns of table "WUI_SellerPackage" */
export enum Wui_SellerPackage_Select_Column_Wui_SellerPackage_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  HidePrice = 'hide_price'
}

/** select "WUI_SellerPackage_aggregate_bool_exp_bool_or_arguments_columns" columns of table "WUI_SellerPackage" */
export enum Wui_SellerPackage_Select_Column_Wui_SellerPackage_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  HidePrice = 'hide_price'
}

/** input type for updating data in table "WUI_SellerPackage" */
export type Wui_SellerPackage_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  hide_price?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  package_type?: InputMaybe<Wui_SellerPackageType_Enum>;
  price?: InputMaybe<Scalars['numeric']>;
  price_request_id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_SellerPackage_Stddev_Fields = {
  __typename?: 'WUI_SellerPackage_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_request_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  price_request_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_SellerPackage_Stddev_Pop_Fields = {
  __typename?: 'WUI_SellerPackage_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_request_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  price_request_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_SellerPackage_Stddev_Samp_Fields = {
  __typename?: 'WUI_SellerPackage_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_request_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  price_request_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WUI_SellerPackage" */
export type Wui_SellerPackage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_SellerPackage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_SellerPackage_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  hide_price?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  package_type?: InputMaybe<Wui_SellerPackageType_Enum>;
  price?: InputMaybe<Scalars['numeric']>;
  price_request_id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_SellerPackage_Sum_Fields = {
  __typename?: 'WUI_SellerPackage_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  price_request_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  price_request_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "WUI_SellerPackage" */
export enum Wui_SellerPackage_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  HidePrice = 'hide_price',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PackageType = 'package_type',
  /** column name */
  Price = 'price',
  /** column name */
  PriceRequestId = 'price_request_id',
  /** column name */
  Title = 'title',
  /** column name */
  UserId = 'user_id'
}

export type Wui_SellerPackage_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Wui_SellerPackage_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Wui_SellerPackage_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Wui_SellerPackage_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Wui_SellerPackage_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_SellerPackage_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Wui_SellerPackage_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_SellerPackage_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_SellerPackage_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_SellerPackage_Var_Pop_Fields = {
  __typename?: 'WUI_SellerPackage_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_request_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  price_request_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_SellerPackage_Var_Samp_Fields = {
  __typename?: 'WUI_SellerPackage_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_request_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  price_request_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_SellerPackage_Variance_Fields = {
  __typename?: 'WUI_SellerPackage_variance_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_request_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  price_request_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "WUI_SellerProject" */
export type Wui_SellerProject = {
  __typename?: 'WUI_SellerProject';
  /** An array relationship */
  WUI_SellerProjectFiles: Array<Wui_SellerProjectFile>;
  /** An aggregate relationship */
  WUI_SellerProjectFiles_aggregate: Wui_SellerProjectFile_Aggregate;
  /** An array relationship */
  WUI_SellerProjectItems: Array<Wui_SellerProjectItem>;
  /** An aggregate relationship */
  WUI_SellerProjectItems_aggregate: Wui_SellerProjectItem_Aggregate;
  /** An object relationship */
  WUI_User: Wui_User;
  /** An array relationship */
  demographics: Array<ProjectDemographic>;
  /** An aggregate relationship */
  demographics_aggregate: ProjectDemographic_Aggregate;
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['bigint'];
  price: Scalars['numeric'];
  title: Scalars['String'];
  user_id: Scalars['bigint'];
};


/** columns and relationships of "WUI_SellerProject" */
export type Wui_SellerProjectWui_SellerProjectFilesArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerProjectFile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerProjectFile_Order_By>>;
  where?: InputMaybe<Wui_SellerProjectFile_Bool_Exp>;
};


/** columns and relationships of "WUI_SellerProject" */
export type Wui_SellerProjectWui_SellerProjectFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerProjectFile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerProjectFile_Order_By>>;
  where?: InputMaybe<Wui_SellerProjectFile_Bool_Exp>;
};


/** columns and relationships of "WUI_SellerProject" */
export type Wui_SellerProjectWui_SellerProjectItemsArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerProjectItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerProjectItem_Order_By>>;
  where?: InputMaybe<Wui_SellerProjectItem_Bool_Exp>;
};


/** columns and relationships of "WUI_SellerProject" */
export type Wui_SellerProjectWui_SellerProjectItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerProjectItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerProjectItem_Order_By>>;
  where?: InputMaybe<Wui_SellerProjectItem_Bool_Exp>;
};


/** columns and relationships of "WUI_SellerProject" */
export type Wui_SellerProjectDemographicsArgs = {
  distinct_on?: InputMaybe<Array<ProjectDemographic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProjectDemographic_Order_By>>;
  where?: InputMaybe<ProjectDemographic_Bool_Exp>;
};


/** columns and relationships of "WUI_SellerProject" */
export type Wui_SellerProjectDemographics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProjectDemographic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProjectDemographic_Order_By>>;
  where?: InputMaybe<ProjectDemographic_Bool_Exp>;
};

/** columns and relationships of "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile = {
  __typename?: 'WUI_SellerProjectFile';
  /** An object relationship */
  WUI_File: Wui_File;
  created_at: Scalars['timestamptz'];
  file_id: Scalars['bigint'];
  id: Scalars['Int'];
  project_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Aggregate = {
  __typename?: 'WUI_SellerProjectFile_aggregate';
  aggregate?: Maybe<Wui_SellerProjectFile_Aggregate_Fields>;
  nodes: Array<Wui_SellerProjectFile>;
};

export type Wui_SellerProjectFile_Aggregate_Bool_Exp = {
  count?: InputMaybe<Wui_SellerProjectFile_Aggregate_Bool_Exp_Count>;
};

export type Wui_SellerProjectFile_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wui_SellerProjectFile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_SellerProjectFile_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Aggregate_Fields = {
  __typename?: 'WUI_SellerProjectFile_aggregate_fields';
  avg?: Maybe<Wui_SellerProjectFile_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_SellerProjectFile_Max_Fields>;
  min?: Maybe<Wui_SellerProjectFile_Min_Fields>;
  stddev?: Maybe<Wui_SellerProjectFile_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_SellerProjectFile_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_SellerProjectFile_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_SellerProjectFile_Sum_Fields>;
  var_pop?: Maybe<Wui_SellerProjectFile_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_SellerProjectFile_Var_Samp_Fields>;
  variance?: Maybe<Wui_SellerProjectFile_Variance_Fields>;
};


/** aggregate fields of "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_SellerProjectFile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Aggregate_Order_By = {
  avg?: InputMaybe<Wui_SellerProjectFile_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wui_SellerProjectFile_Max_Order_By>;
  min?: InputMaybe<Wui_SellerProjectFile_Min_Order_By>;
  stddev?: InputMaybe<Wui_SellerProjectFile_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wui_SellerProjectFile_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wui_SellerProjectFile_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wui_SellerProjectFile_Sum_Order_By>;
  var_pop?: InputMaybe<Wui_SellerProjectFile_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wui_SellerProjectFile_Var_Samp_Order_By>;
  variance?: InputMaybe<Wui_SellerProjectFile_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Arr_Rel_Insert_Input = {
  data: Array<Wui_SellerProjectFile_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_SellerProjectFile_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_SellerProjectFile_Avg_Fields = {
  __typename?: 'WUI_SellerProjectFile_avg_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Avg_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_SellerProjectFile". All fields are combined with a logical 'AND'. */
export type Wui_SellerProjectFile_Bool_Exp = {
  WUI_File?: InputMaybe<Wui_File_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_SellerProjectFile_Bool_Exp>>;
  _not?: InputMaybe<Wui_SellerProjectFile_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_SellerProjectFile_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  project_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SellerProjectFile" */
export enum Wui_SellerProjectFile_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectFilePkey = 'ProjectFile_pkey'
}

/** input type for incrementing numeric columns in table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Inc_Input = {
  file_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Insert_Input = {
  WUI_File?: InputMaybe<Wui_File_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_SellerProjectFile_Max_Fields = {
  __typename?: 'WUI_SellerProjectFile_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_SellerProjectFile_Min_Fields = {
  __typename?: 'WUI_SellerProjectFile_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Mutation_Response = {
  __typename?: 'WUI_SellerProjectFile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SellerProjectFile>;
};

/** on_conflict condition type for table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_On_Conflict = {
  constraint: Wui_SellerProjectFile_Constraint;
  update_columns?: Array<Wui_SellerProjectFile_Update_Column>;
  where?: InputMaybe<Wui_SellerProjectFile_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SellerProjectFile". */
export type Wui_SellerProjectFile_Order_By = {
  WUI_File?: InputMaybe<Wui_File_Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_SellerProjectFile */
export type Wui_SellerProjectFile_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "WUI_SellerProjectFile" */
export enum Wui_SellerProjectFile_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_SellerProjectFile_Stddev_Fields = {
  __typename?: 'WUI_SellerProjectFile_stddev_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Stddev_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_SellerProjectFile_Stddev_Pop_Fields = {
  __typename?: 'WUI_SellerProjectFile_stddev_pop_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Stddev_Pop_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_SellerProjectFile_Stddev_Samp_Fields = {
  __typename?: 'WUI_SellerProjectFile_stddev_samp_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Stddev_Samp_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_SellerProjectFile_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_SellerProjectFile_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_SellerProjectFile_Sum_Fields = {
  __typename?: 'WUI_SellerProjectFile_sum_fields';
  file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Sum_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** update columns of table "WUI_SellerProjectFile" */
export enum Wui_SellerProjectFile_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id'
}

export type Wui_SellerProjectFile_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_SellerProjectFile_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_SellerProjectFile_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_SellerProjectFile_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_SellerProjectFile_Var_Pop_Fields = {
  __typename?: 'WUI_SellerProjectFile_var_pop_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Var_Pop_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_SellerProjectFile_Var_Samp_Fields = {
  __typename?: 'WUI_SellerProjectFile_var_samp_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Var_Samp_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_SellerProjectFile_Variance_Fields = {
  __typename?: 'WUI_SellerProjectFile_variance_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Variance_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem = {
  __typename?: 'WUI_SellerProjectItem';
  /** An object relationship */
  WUI_SellerProject: Wui_SellerProject;
  details: Scalars['String'];
  id: Scalars['bigint'];
  seller_project_id: Scalars['bigint'];
};

/** aggregated selection of "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Aggregate = {
  __typename?: 'WUI_SellerProjectItem_aggregate';
  aggregate?: Maybe<Wui_SellerProjectItem_Aggregate_Fields>;
  nodes: Array<Wui_SellerProjectItem>;
};

export type Wui_SellerProjectItem_Aggregate_Bool_Exp = {
  count?: InputMaybe<Wui_SellerProjectItem_Aggregate_Bool_Exp_Count>;
};

export type Wui_SellerProjectItem_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wui_SellerProjectItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_SellerProjectItem_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Aggregate_Fields = {
  __typename?: 'WUI_SellerProjectItem_aggregate_fields';
  avg?: Maybe<Wui_SellerProjectItem_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_SellerProjectItem_Max_Fields>;
  min?: Maybe<Wui_SellerProjectItem_Min_Fields>;
  stddev?: Maybe<Wui_SellerProjectItem_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_SellerProjectItem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_SellerProjectItem_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_SellerProjectItem_Sum_Fields>;
  var_pop?: Maybe<Wui_SellerProjectItem_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_SellerProjectItem_Var_Samp_Fields>;
  variance?: Maybe<Wui_SellerProjectItem_Variance_Fields>;
};


/** aggregate fields of "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_SellerProjectItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Aggregate_Order_By = {
  avg?: InputMaybe<Wui_SellerProjectItem_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wui_SellerProjectItem_Max_Order_By>;
  min?: InputMaybe<Wui_SellerProjectItem_Min_Order_By>;
  stddev?: InputMaybe<Wui_SellerProjectItem_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wui_SellerProjectItem_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wui_SellerProjectItem_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wui_SellerProjectItem_Sum_Order_By>;
  var_pop?: InputMaybe<Wui_SellerProjectItem_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wui_SellerProjectItem_Var_Samp_Order_By>;
  variance?: InputMaybe<Wui_SellerProjectItem_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Arr_Rel_Insert_Input = {
  data: Array<Wui_SellerProjectItem_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_SellerProjectItem_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_SellerProjectItem_Avg_Fields = {
  __typename?: 'WUI_SellerProjectItem_avg_fields';
  id?: Maybe<Scalars['Float']>;
  seller_project_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  seller_project_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_SellerProjectItem". All fields are combined with a logical 'AND'. */
export type Wui_SellerProjectItem_Bool_Exp = {
  WUI_SellerProject?: InputMaybe<Wui_SellerProject_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_SellerProjectItem_Bool_Exp>>;
  _not?: InputMaybe<Wui_SellerProjectItem_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_SellerProjectItem_Bool_Exp>>;
  details?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  seller_project_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SellerProjectItem" */
export enum Wui_SellerProjectItem_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiSellerProjectItemPkey = 'WUI_SellerProjectItem_pkey'
}

/** input type for incrementing numeric columns in table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  seller_project_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Insert_Input = {
  WUI_SellerProject?: InputMaybe<Wui_SellerProject_Obj_Rel_Insert_Input>;
  details?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  seller_project_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_SellerProjectItem_Max_Fields = {
  __typename?: 'WUI_SellerProjectItem_max_fields';
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  seller_project_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Max_Order_By = {
  details?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  seller_project_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_SellerProjectItem_Min_Fields = {
  __typename?: 'WUI_SellerProjectItem_min_fields';
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  seller_project_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Min_Order_By = {
  details?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  seller_project_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Mutation_Response = {
  __typename?: 'WUI_SellerProjectItem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SellerProjectItem>;
};

/** on_conflict condition type for table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_On_Conflict = {
  constraint: Wui_SellerProjectItem_Constraint;
  update_columns?: Array<Wui_SellerProjectItem_Update_Column>;
  where?: InputMaybe<Wui_SellerProjectItem_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SellerProjectItem". */
export type Wui_SellerProjectItem_Order_By = {
  WUI_SellerProject?: InputMaybe<Wui_SellerProject_Order_By>;
  details?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  seller_project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_SellerProjectItem */
export type Wui_SellerProjectItem_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_SellerProjectItem" */
export enum Wui_SellerProjectItem_Select_Column {
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  SellerProjectId = 'seller_project_id'
}

/** input type for updating data in table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Set_Input = {
  details?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  seller_project_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_SellerProjectItem_Stddev_Fields = {
  __typename?: 'WUI_SellerProjectItem_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  seller_project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  seller_project_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_SellerProjectItem_Stddev_Pop_Fields = {
  __typename?: 'WUI_SellerProjectItem_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  seller_project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  seller_project_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_SellerProjectItem_Stddev_Samp_Fields = {
  __typename?: 'WUI_SellerProjectItem_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  seller_project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  seller_project_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_SellerProjectItem_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_SellerProjectItem_Stream_Cursor_Value_Input = {
  details?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  seller_project_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_SellerProjectItem_Sum_Fields = {
  __typename?: 'WUI_SellerProjectItem_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  seller_project_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  seller_project_id?: InputMaybe<Order_By>;
};

/** update columns of table "WUI_SellerProjectItem" */
export enum Wui_SellerProjectItem_Update_Column {
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  SellerProjectId = 'seller_project_id'
}

export type Wui_SellerProjectItem_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_SellerProjectItem_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_SellerProjectItem_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_SellerProjectItem_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_SellerProjectItem_Var_Pop_Fields = {
  __typename?: 'WUI_SellerProjectItem_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  seller_project_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  seller_project_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_SellerProjectItem_Var_Samp_Fields = {
  __typename?: 'WUI_SellerProjectItem_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  seller_project_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  seller_project_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_SellerProjectItem_Variance_Fields = {
  __typename?: 'WUI_SellerProjectItem_variance_fields';
  id?: Maybe<Scalars['Float']>;
  seller_project_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  seller_project_id?: InputMaybe<Order_By>;
};

/** aggregated selection of "WUI_SellerProject" */
export type Wui_SellerProject_Aggregate = {
  __typename?: 'WUI_SellerProject_aggregate';
  aggregate?: Maybe<Wui_SellerProject_Aggregate_Fields>;
  nodes: Array<Wui_SellerProject>;
};

/** aggregate fields of "WUI_SellerProject" */
export type Wui_SellerProject_Aggregate_Fields = {
  __typename?: 'WUI_SellerProject_aggregate_fields';
  avg?: Maybe<Wui_SellerProject_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_SellerProject_Max_Fields>;
  min?: Maybe<Wui_SellerProject_Min_Fields>;
  stddev?: Maybe<Wui_SellerProject_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_SellerProject_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_SellerProject_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_SellerProject_Sum_Fields>;
  var_pop?: Maybe<Wui_SellerProject_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_SellerProject_Var_Samp_Fields>;
  variance?: Maybe<Wui_SellerProject_Variance_Fields>;
};


/** aggregate fields of "WUI_SellerProject" */
export type Wui_SellerProject_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_SellerProject_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_SellerProject_Avg_Fields = {
  __typename?: 'WUI_SellerProject_avg_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_SellerProject". All fields are combined with a logical 'AND'. */
export type Wui_SellerProject_Bool_Exp = {
  WUI_SellerProjectFiles?: InputMaybe<Wui_SellerProjectFile_Bool_Exp>;
  WUI_SellerProjectFiles_aggregate?: InputMaybe<Wui_SellerProjectFile_Aggregate_Bool_Exp>;
  WUI_SellerProjectItems?: InputMaybe<Wui_SellerProjectItem_Bool_Exp>;
  WUI_SellerProjectItems_aggregate?: InputMaybe<Wui_SellerProjectItem_Aggregate_Bool_Exp>;
  WUI_User?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_SellerProject_Bool_Exp>>;
  _not?: InputMaybe<Wui_SellerProject_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_SellerProject_Bool_Exp>>;
  demographics?: InputMaybe<ProjectDemographic_Bool_Exp>;
  demographics_aggregate?: InputMaybe<ProjectDemographic_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SellerProject" */
export enum Wui_SellerProject_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiSellerProjectPkey = 'WUI_SellerProject_pkey'
}

/** input type for incrementing numeric columns in table "WUI_SellerProject" */
export type Wui_SellerProject_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_SellerProject" */
export type Wui_SellerProject_Insert_Input = {
  WUI_SellerProjectFiles?: InputMaybe<Wui_SellerProjectFile_Arr_Rel_Insert_Input>;
  WUI_SellerProjectItems?: InputMaybe<Wui_SellerProjectItem_Arr_Rel_Insert_Input>;
  WUI_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  demographics?: InputMaybe<ProjectDemographic_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  title?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_SellerProject_Max_Fields = {
  __typename?: 'WUI_SellerProject_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Wui_SellerProject_Min_Fields = {
  __typename?: 'WUI_SellerProject_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "WUI_SellerProject" */
export type Wui_SellerProject_Mutation_Response = {
  __typename?: 'WUI_SellerProject_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SellerProject>;
};

/** input type for inserting object relation for remote table "WUI_SellerProject" */
export type Wui_SellerProject_Obj_Rel_Insert_Input = {
  data: Wui_SellerProject_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_SellerProject_On_Conflict>;
};

/** on_conflict condition type for table "WUI_SellerProject" */
export type Wui_SellerProject_On_Conflict = {
  constraint: Wui_SellerProject_Constraint;
  update_columns?: Array<Wui_SellerProject_Update_Column>;
  where?: InputMaybe<Wui_SellerProject_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SellerProject". */
export type Wui_SellerProject_Order_By = {
  WUI_SellerProjectFiles_aggregate?: InputMaybe<Wui_SellerProjectFile_Aggregate_Order_By>;
  WUI_SellerProjectItems_aggregate?: InputMaybe<Wui_SellerProjectItem_Aggregate_Order_By>;
  WUI_User?: InputMaybe<Wui_User_Order_By>;
  demographics_aggregate?: InputMaybe<ProjectDemographic_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  enabled?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_SellerProject */
export type Wui_SellerProject_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_SellerProject" */
export enum Wui_SellerProject_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  Title = 'title',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "WUI_SellerProject" */
export type Wui_SellerProject_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  title?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_SellerProject_Stddev_Fields = {
  __typename?: 'WUI_SellerProject_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_SellerProject_Stddev_Pop_Fields = {
  __typename?: 'WUI_SellerProject_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_SellerProject_Stddev_Samp_Fields = {
  __typename?: 'WUI_SellerProject_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WUI_SellerProject" */
export type Wui_SellerProject_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_SellerProject_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_SellerProject_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  title?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_SellerProject_Sum_Fields = {
  __typename?: 'WUI_SellerProject_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_SellerProject" */
export enum Wui_SellerProject_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  Title = 'title',
  /** column name */
  UserId = 'user_id'
}

export type Wui_SellerProject_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_SellerProject_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_SellerProject_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_SellerProject_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_SellerProject_Var_Pop_Fields = {
  __typename?: 'WUI_SellerProject_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_SellerProject_Var_Samp_Fields = {
  __typename?: 'WUI_SellerProject_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_SellerProject_Variance_Fields = {
  __typename?: 'WUI_SellerProject_variance_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_Session" */
export type Wui_Session = {
  __typename?: 'WUI_Session';
  id: Scalars['bigint'];
  session_id: Scalars['String'];
  user_id?: Maybe<Scalars['bigint']>;
};

/** columns and relationships of "WUI_SessionEvent" */
export type Wui_SessionEvent = {
  __typename?: 'WUI_SessionEvent';
  /** An object relationship */
  SocialMediaLink?: Maybe<SocialMediaLink>;
  /** An object relationship */
  WUI_Session?: Maybe<Wui_Session>;
  /** An object relationship */
  WUI_SessionEventType: Wui_SessionEventType;
  /** An object relationship */
  WUI_User: Wui_User;
  created_at: Scalars['timestamptz'];
  event_type: Wui_SessionEventType_Enum;
  id: Scalars['bigint'];
  link_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  target_user_id: Scalars['bigint'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
  visitor_id?: Maybe<Scalars['String']>;
};

/** columns and relationships of "WUI_SessionEventType" */
export type Wui_SessionEventType = {
  __typename?: 'WUI_SessionEventType';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_SessionEventType" */
export type Wui_SessionEventType_Aggregate = {
  __typename?: 'WUI_SessionEventType_aggregate';
  aggregate?: Maybe<Wui_SessionEventType_Aggregate_Fields>;
  nodes: Array<Wui_SessionEventType>;
};

/** aggregate fields of "WUI_SessionEventType" */
export type Wui_SessionEventType_Aggregate_Fields = {
  __typename?: 'WUI_SessionEventType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_SessionEventType_Max_Fields>;
  min?: Maybe<Wui_SessionEventType_Min_Fields>;
};


/** aggregate fields of "WUI_SessionEventType" */
export type Wui_SessionEventType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_SessionEventType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_SessionEventType". All fields are combined with a logical 'AND'. */
export type Wui_SessionEventType_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_SessionEventType_Bool_Exp>>;
  _not?: InputMaybe<Wui_SessionEventType_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_SessionEventType_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SessionEventType" */
export enum Wui_SessionEventType_Constraint {
  /** unique or primary key constraint on columns "value" */
  WuiSessionEventTypePkey = 'WUI_SessionEventType_pkey'
}

export enum Wui_SessionEventType_Enum {
  Click = 'click',
  PageView = 'page_view'
}

/** Boolean expression to compare columns of type "WUI_SessionEventType_enum". All fields are combined with logical 'AND'. */
export type Wui_SessionEventType_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Wui_SessionEventType_Enum>;
  _in?: InputMaybe<Array<Wui_SessionEventType_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Wui_SessionEventType_Enum>;
  _nin?: InputMaybe<Array<Wui_SessionEventType_Enum>>;
};

/** input type for inserting data into table "WUI_SessionEventType" */
export type Wui_SessionEventType_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_SessionEventType_Max_Fields = {
  __typename?: 'WUI_SessionEventType_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_SessionEventType_Min_Fields = {
  __typename?: 'WUI_SessionEventType_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_SessionEventType" */
export type Wui_SessionEventType_Mutation_Response = {
  __typename?: 'WUI_SessionEventType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SessionEventType>;
};

/** input type for inserting object relation for remote table "WUI_SessionEventType" */
export type Wui_SessionEventType_Obj_Rel_Insert_Input = {
  data: Wui_SessionEventType_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_SessionEventType_On_Conflict>;
};

/** on_conflict condition type for table "WUI_SessionEventType" */
export type Wui_SessionEventType_On_Conflict = {
  constraint: Wui_SessionEventType_Constraint;
  update_columns?: Array<Wui_SessionEventType_Update_Column>;
  where?: InputMaybe<Wui_SessionEventType_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SessionEventType". */
export type Wui_SessionEventType_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_SessionEventType */
export type Wui_SessionEventType_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_SessionEventType" */
export enum Wui_SessionEventType_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_SessionEventType" */
export type Wui_SessionEventType_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "WUI_SessionEventType" */
export type Wui_SessionEventType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_SessionEventType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_SessionEventType_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "WUI_SessionEventType" */
export enum Wui_SessionEventType_Update_Column {
  /** column name */
  Value = 'value'
}

export type Wui_SessionEventType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_SessionEventType_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_SessionEventType_Bool_Exp;
};

/** aggregated selection of "WUI_SessionEvent" */
export type Wui_SessionEvent_Aggregate = {
  __typename?: 'WUI_SessionEvent_aggregate';
  aggregate?: Maybe<Wui_SessionEvent_Aggregate_Fields>;
  nodes: Array<Wui_SessionEvent>;
};

export type Wui_SessionEvent_Aggregate_Bool_Exp = {
  count?: InputMaybe<Wui_SessionEvent_Aggregate_Bool_Exp_Count>;
};

export type Wui_SessionEvent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wui_SessionEvent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_SessionEvent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WUI_SessionEvent" */
export type Wui_SessionEvent_Aggregate_Fields = {
  __typename?: 'WUI_SessionEvent_aggregate_fields';
  avg?: Maybe<Wui_SessionEvent_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_SessionEvent_Max_Fields>;
  min?: Maybe<Wui_SessionEvent_Min_Fields>;
  stddev?: Maybe<Wui_SessionEvent_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_SessionEvent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_SessionEvent_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_SessionEvent_Sum_Fields>;
  var_pop?: Maybe<Wui_SessionEvent_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_SessionEvent_Var_Samp_Fields>;
  variance?: Maybe<Wui_SessionEvent_Variance_Fields>;
};


/** aggregate fields of "WUI_SessionEvent" */
export type Wui_SessionEvent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_SessionEvent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_SessionEvent" */
export type Wui_SessionEvent_Aggregate_Order_By = {
  avg?: InputMaybe<Wui_SessionEvent_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wui_SessionEvent_Max_Order_By>;
  min?: InputMaybe<Wui_SessionEvent_Min_Order_By>;
  stddev?: InputMaybe<Wui_SessionEvent_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wui_SessionEvent_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wui_SessionEvent_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wui_SessionEvent_Sum_Order_By>;
  var_pop?: InputMaybe<Wui_SessionEvent_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wui_SessionEvent_Var_Samp_Order_By>;
  variance?: InputMaybe<Wui_SessionEvent_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_SessionEvent" */
export type Wui_SessionEvent_Arr_Rel_Insert_Input = {
  data: Array<Wui_SessionEvent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_SessionEvent_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_SessionEvent_Avg_Fields = {
  __typename?: 'WUI_SessionEvent_avg_fields';
  id?: Maybe<Scalars['Float']>;
  link_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  target_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_SessionEvent" */
export type Wui_SessionEvent_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  link_id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  target_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_SessionEvent". All fields are combined with a logical 'AND'. */
export type Wui_SessionEvent_Bool_Exp = {
  SocialMediaLink?: InputMaybe<SocialMediaLink_Bool_Exp>;
  WUI_Session?: InputMaybe<Wui_Session_Bool_Exp>;
  WUI_SessionEventType?: InputMaybe<Wui_SessionEventType_Bool_Exp>;
  WUI_User?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_SessionEvent_Bool_Exp>>;
  _not?: InputMaybe<Wui_SessionEvent_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_SessionEvent_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_type?: InputMaybe<Wui_SessionEventType_Enum_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  link_id?: InputMaybe<Bigint_Comparison_Exp>;
  session_id?: InputMaybe<Bigint_Comparison_Exp>;
  target_user_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  visitor_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SessionEvent" */
export enum Wui_SessionEvent_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiSessionEventPkey = 'WUI_SessionEvent_pkey'
}

/** input type for incrementing numeric columns in table "WUI_SessionEvent" */
export type Wui_SessionEvent_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  link_id?: InputMaybe<Scalars['bigint']>;
  session_id?: InputMaybe<Scalars['bigint']>;
  target_user_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_SessionEvent" */
export type Wui_SessionEvent_Insert_Input = {
  SocialMediaLink?: InputMaybe<SocialMediaLink_Obj_Rel_Insert_Input>;
  WUI_Session?: InputMaybe<Wui_Session_Obj_Rel_Insert_Input>;
  WUI_SessionEventType?: InputMaybe<Wui_SessionEventType_Obj_Rel_Insert_Input>;
  WUI_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_type?: InputMaybe<Wui_SessionEventType_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  link_id?: InputMaybe<Scalars['bigint']>;
  session_id?: InputMaybe<Scalars['bigint']>;
  target_user_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
  visitor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_SessionEvent_Max_Fields = {
  __typename?: 'WUI_SessionEvent_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  link_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  target_user_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
  visitor_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "WUI_SessionEvent" */
export type Wui_SessionEvent_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link_id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  target_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  visitor_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_SessionEvent_Min_Fields = {
  __typename?: 'WUI_SessionEvent_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  link_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  target_user_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
  visitor_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "WUI_SessionEvent" */
export type Wui_SessionEvent_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link_id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  target_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  visitor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WUI_SessionEvent" */
export type Wui_SessionEvent_Mutation_Response = {
  __typename?: 'WUI_SessionEvent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SessionEvent>;
};

/** on_conflict condition type for table "WUI_SessionEvent" */
export type Wui_SessionEvent_On_Conflict = {
  constraint: Wui_SessionEvent_Constraint;
  update_columns?: Array<Wui_SessionEvent_Update_Column>;
  where?: InputMaybe<Wui_SessionEvent_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SessionEvent". */
export type Wui_SessionEvent_Order_By = {
  SocialMediaLink?: InputMaybe<SocialMediaLink_Order_By>;
  WUI_Session?: InputMaybe<Wui_Session_Order_By>;
  WUI_SessionEventType?: InputMaybe<Wui_SessionEventType_Order_By>;
  WUI_User?: InputMaybe<Wui_User_Order_By>;
  created_at?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link_id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  target_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  visitor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_SessionEvent */
export type Wui_SessionEvent_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_SessionEvent" */
export enum Wui_SessionEvent_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  LinkId = 'link_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  TargetUserId = 'target_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VisitorId = 'visitor_id'
}

/** input type for updating data in table "WUI_SessionEvent" */
export type Wui_SessionEvent_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_type?: InputMaybe<Wui_SessionEventType_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  link_id?: InputMaybe<Scalars['bigint']>;
  session_id?: InputMaybe<Scalars['bigint']>;
  target_user_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
  visitor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Wui_SessionEvent_Stddev_Fields = {
  __typename?: 'WUI_SessionEvent_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  link_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  target_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_SessionEvent" */
export type Wui_SessionEvent_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  link_id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  target_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_SessionEvent_Stddev_Pop_Fields = {
  __typename?: 'WUI_SessionEvent_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  link_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  target_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_SessionEvent" */
export type Wui_SessionEvent_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  link_id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  target_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_SessionEvent_Stddev_Samp_Fields = {
  __typename?: 'WUI_SessionEvent_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  link_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  target_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_SessionEvent" */
export type Wui_SessionEvent_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  link_id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  target_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WUI_SessionEvent" */
export type Wui_SessionEvent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_SessionEvent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_SessionEvent_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_type?: InputMaybe<Wui_SessionEventType_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  link_id?: InputMaybe<Scalars['bigint']>;
  session_id?: InputMaybe<Scalars['bigint']>;
  target_user_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
  visitor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Wui_SessionEvent_Sum_Fields = {
  __typename?: 'WUI_SessionEvent_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  link_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  target_user_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_SessionEvent" */
export type Wui_SessionEvent_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  link_id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  target_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "WUI_SessionEvent" */
export enum Wui_SessionEvent_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  LinkId = 'link_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  TargetUserId = 'target_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VisitorId = 'visitor_id'
}

export type Wui_SessionEvent_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_SessionEvent_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_SessionEvent_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_SessionEvent_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_SessionEvent_Var_Pop_Fields = {
  __typename?: 'WUI_SessionEvent_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  link_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  target_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_SessionEvent" */
export type Wui_SessionEvent_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  link_id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  target_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_SessionEvent_Var_Samp_Fields = {
  __typename?: 'WUI_SessionEvent_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  link_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  target_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_SessionEvent" */
export type Wui_SessionEvent_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  link_id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  target_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_SessionEvent_Variance_Fields = {
  __typename?: 'WUI_SessionEvent_variance_fields';
  id?: Maybe<Scalars['Float']>;
  link_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  target_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_SessionEvent" */
export type Wui_SessionEvent_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  link_id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  target_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregated selection of "WUI_Session" */
export type Wui_Session_Aggregate = {
  __typename?: 'WUI_Session_aggregate';
  aggregate?: Maybe<Wui_Session_Aggregate_Fields>;
  nodes: Array<Wui_Session>;
};

export type Wui_Session_Aggregate_Bool_Exp = {
  count?: InputMaybe<Wui_Session_Aggregate_Bool_Exp_Count>;
};

export type Wui_Session_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wui_Session_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_Session_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WUI_Session" */
export type Wui_Session_Aggregate_Fields = {
  __typename?: 'WUI_Session_aggregate_fields';
  avg?: Maybe<Wui_Session_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_Session_Max_Fields>;
  min?: Maybe<Wui_Session_Min_Fields>;
  stddev?: Maybe<Wui_Session_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_Session_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_Session_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_Session_Sum_Fields>;
  var_pop?: Maybe<Wui_Session_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_Session_Var_Samp_Fields>;
  variance?: Maybe<Wui_Session_Variance_Fields>;
};


/** aggregate fields of "WUI_Session" */
export type Wui_Session_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_Session_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_Session" */
export type Wui_Session_Aggregate_Order_By = {
  avg?: InputMaybe<Wui_Session_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wui_Session_Max_Order_By>;
  min?: InputMaybe<Wui_Session_Min_Order_By>;
  stddev?: InputMaybe<Wui_Session_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wui_Session_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wui_Session_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wui_Session_Sum_Order_By>;
  var_pop?: InputMaybe<Wui_Session_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wui_Session_Var_Samp_Order_By>;
  variance?: InputMaybe<Wui_Session_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_Session" */
export type Wui_Session_Arr_Rel_Insert_Input = {
  data: Array<Wui_Session_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_Session_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_Session_Avg_Fields = {
  __typename?: 'WUI_Session_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_Session" */
export type Wui_Session_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_Session". All fields are combined with a logical 'AND'. */
export type Wui_Session_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_Session_Bool_Exp>>;
  _not?: InputMaybe<Wui_Session_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_Session_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  session_id?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_Session" */
export enum Wui_Session_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiSessionPkey = 'WUI_Session_pkey'
}

/** input type for incrementing numeric columns in table "WUI_Session" */
export type Wui_Session_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_Session" */
export type Wui_Session_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  session_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_Session_Max_Fields = {
  __typename?: 'WUI_Session_max_fields';
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_Session" */
export type Wui_Session_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_Session_Min_Fields = {
  __typename?: 'WUI_Session_min_fields';
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_Session" */
export type Wui_Session_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WUI_Session" */
export type Wui_Session_Mutation_Response = {
  __typename?: 'WUI_Session_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_Session>;
};

/** input type for inserting object relation for remote table "WUI_Session" */
export type Wui_Session_Obj_Rel_Insert_Input = {
  data: Wui_Session_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_Session_On_Conflict>;
};

/** on_conflict condition type for table "WUI_Session" */
export type Wui_Session_On_Conflict = {
  constraint: Wui_Session_Constraint;
  update_columns?: Array<Wui_Session_Update_Column>;
  where?: InputMaybe<Wui_Session_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_Session". */
export type Wui_Session_Order_By = {
  id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_Session */
export type Wui_Session_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_Session" */
export enum Wui_Session_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "WUI_Session" */
export type Wui_Session_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  session_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_Session_Stddev_Fields = {
  __typename?: 'WUI_Session_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_Session" */
export type Wui_Session_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_Session_Stddev_Pop_Fields = {
  __typename?: 'WUI_Session_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_Session" */
export type Wui_Session_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_Session_Stddev_Samp_Fields = {
  __typename?: 'WUI_Session_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_Session" */
export type Wui_Session_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WUI_Session" */
export type Wui_Session_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_Session_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_Session_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  session_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_Session_Sum_Fields = {
  __typename?: 'WUI_Session_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_Session" */
export type Wui_Session_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "WUI_Session" */
export enum Wui_Session_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UserId = 'user_id'
}

export type Wui_Session_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_Session_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_Session_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_Session_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_Session_Var_Pop_Fields = {
  __typename?: 'WUI_Session_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_Session" */
export type Wui_Session_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_Session_Var_Samp_Fields = {
  __typename?: 'WUI_Session_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_Session" */
export type Wui_Session_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_Session_Variance_Fields = {
  __typename?: 'WUI_Session_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_Session" */
export type Wui_Session_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource = {
  __typename?: 'WUI_SocialMediaSource';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource_Aggregate = {
  __typename?: 'WUI_SocialMediaSource_aggregate';
  aggregate?: Maybe<Wui_SocialMediaSource_Aggregate_Fields>;
  nodes: Array<Wui_SocialMediaSource>;
};

/** aggregate fields of "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource_Aggregate_Fields = {
  __typename?: 'WUI_SocialMediaSource_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_SocialMediaSource_Max_Fields>;
  min?: Maybe<Wui_SocialMediaSource_Min_Fields>;
};


/** aggregate fields of "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_SocialMediaSource_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_SocialMediaSource". All fields are combined with a logical 'AND'. */
export type Wui_SocialMediaSource_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_SocialMediaSource_Bool_Exp>>;
  _not?: InputMaybe<Wui_SocialMediaSource_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_SocialMediaSource_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SocialMediaSource" */
export enum Wui_SocialMediaSource_Constraint {
  /** unique or primary key constraint on columns "value" */
  WuiSocialMediaSourceEnumPkey = 'WUI_SocialMediaSourceEnum_pkey'
}

export enum Wui_SocialMediaSource_Enum {
  Facebook = 'facebook',
  General = 'general',
  Instagram = 'instagram',
  Tiktok = 'tiktok',
  Twitch = 'twitch',
  Twitter = 'twitter',
  Youtube = 'youtube'
}

/** Boolean expression to compare columns of type "WUI_SocialMediaSource_enum". All fields are combined with logical 'AND'. */
export type Wui_SocialMediaSource_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Wui_SocialMediaSource_Enum>;
  _in?: InputMaybe<Array<Wui_SocialMediaSource_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Wui_SocialMediaSource_Enum>;
  _nin?: InputMaybe<Array<Wui_SocialMediaSource_Enum>>;
};

/** input type for inserting data into table "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_SocialMediaSource_Max_Fields = {
  __typename?: 'WUI_SocialMediaSource_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_SocialMediaSource_Min_Fields = {
  __typename?: 'WUI_SocialMediaSource_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource_Mutation_Response = {
  __typename?: 'WUI_SocialMediaSource_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SocialMediaSource>;
};

/** input type for inserting object relation for remote table "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource_Obj_Rel_Insert_Input = {
  data: Wui_SocialMediaSource_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_SocialMediaSource_On_Conflict>;
};

/** on_conflict condition type for table "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource_On_Conflict = {
  constraint: Wui_SocialMediaSource_Constraint;
  update_columns?: Array<Wui_SocialMediaSource_Update_Column>;
  where?: InputMaybe<Wui_SocialMediaSource_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SocialMediaSource". */
export type Wui_SocialMediaSource_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_SocialMediaSource */
export type Wui_SocialMediaSource_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_SocialMediaSource" */
export enum Wui_SocialMediaSource_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_SocialMediaSource_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_SocialMediaSource_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "WUI_SocialMediaSource" */
export enum Wui_SocialMediaSource_Update_Column {
  /** column name */
  Value = 'value'
}

export type Wui_SocialMediaSource_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_SocialMediaSource_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_SocialMediaSource_Bool_Exp;
};

/** columns and relationships of "WUI_SubscriptionPlan" */
export type Wui_SubscriptionPlan = {
  __typename?: 'WUI_SubscriptionPlan';
  frequency: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  order: Scalars['Int'];
  original_price?: Maybe<Scalars['Int']>;
  price: Scalars['Int'];
  stripe_price_id: Scalars['String'];
  stripe_product_id: Scalars['String'];
};

/** aggregated selection of "WUI_SubscriptionPlan" */
export type Wui_SubscriptionPlan_Aggregate = {
  __typename?: 'WUI_SubscriptionPlan_aggregate';
  aggregate?: Maybe<Wui_SubscriptionPlan_Aggregate_Fields>;
  nodes: Array<Wui_SubscriptionPlan>;
};

/** aggregate fields of "WUI_SubscriptionPlan" */
export type Wui_SubscriptionPlan_Aggregate_Fields = {
  __typename?: 'WUI_SubscriptionPlan_aggregate_fields';
  avg?: Maybe<Wui_SubscriptionPlan_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_SubscriptionPlan_Max_Fields>;
  min?: Maybe<Wui_SubscriptionPlan_Min_Fields>;
  stddev?: Maybe<Wui_SubscriptionPlan_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_SubscriptionPlan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_SubscriptionPlan_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_SubscriptionPlan_Sum_Fields>;
  var_pop?: Maybe<Wui_SubscriptionPlan_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_SubscriptionPlan_Var_Samp_Fields>;
  variance?: Maybe<Wui_SubscriptionPlan_Variance_Fields>;
};


/** aggregate fields of "WUI_SubscriptionPlan" */
export type Wui_SubscriptionPlan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_SubscriptionPlan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_SubscriptionPlan_Avg_Fields = {
  __typename?: 'WUI_SubscriptionPlan_avg_fields';
  order?: Maybe<Scalars['Float']>;
  original_price?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_SubscriptionPlan". All fields are combined with a logical 'AND'. */
export type Wui_SubscriptionPlan_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_SubscriptionPlan_Bool_Exp>>;
  _not?: InputMaybe<Wui_SubscriptionPlan_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_SubscriptionPlan_Bool_Exp>>;
  frequency?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  original_price?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  stripe_price_id?: InputMaybe<String_Comparison_Exp>;
  stripe_product_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SubscriptionPlan" */
export enum Wui_SubscriptionPlan_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiSubscriptionPlanPkey = 'WUI_SubscriptionPlan_pkey'
}

/** input type for incrementing numeric columns in table "WUI_SubscriptionPlan" */
export type Wui_SubscriptionPlan_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
  original_price?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "WUI_SubscriptionPlan" */
export type Wui_SubscriptionPlan_Insert_Input = {
  frequency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  original_price?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  stripe_price_id?: InputMaybe<Scalars['String']>;
  stripe_product_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_SubscriptionPlan_Max_Fields = {
  __typename?: 'WUI_SubscriptionPlan_max_fields';
  frequency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  original_price?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  stripe_price_id?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_SubscriptionPlan_Min_Fields = {
  __typename?: 'WUI_SubscriptionPlan_min_fields';
  frequency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  original_price?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  stripe_price_id?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_SubscriptionPlan" */
export type Wui_SubscriptionPlan_Mutation_Response = {
  __typename?: 'WUI_SubscriptionPlan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SubscriptionPlan>;
};

/** on_conflict condition type for table "WUI_SubscriptionPlan" */
export type Wui_SubscriptionPlan_On_Conflict = {
  constraint: Wui_SubscriptionPlan_Constraint;
  update_columns?: Array<Wui_SubscriptionPlan_Update_Column>;
  where?: InputMaybe<Wui_SubscriptionPlan_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SubscriptionPlan". */
export type Wui_SubscriptionPlan_Order_By = {
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  original_price?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  stripe_price_id?: InputMaybe<Order_By>;
  stripe_product_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_SubscriptionPlan */
export type Wui_SubscriptionPlan_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "WUI_SubscriptionPlan" */
export enum Wui_SubscriptionPlan_Select_Column {
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  OriginalPrice = 'original_price',
  /** column name */
  Price = 'price',
  /** column name */
  StripePriceId = 'stripe_price_id',
  /** column name */
  StripeProductId = 'stripe_product_id'
}

/** input type for updating data in table "WUI_SubscriptionPlan" */
export type Wui_SubscriptionPlan_Set_Input = {
  frequency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  original_price?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  stripe_price_id?: InputMaybe<Scalars['String']>;
  stripe_product_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Wui_SubscriptionPlan_Stddev_Fields = {
  __typename?: 'WUI_SubscriptionPlan_stddev_fields';
  order?: Maybe<Scalars['Float']>;
  original_price?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_SubscriptionPlan_Stddev_Pop_Fields = {
  __typename?: 'WUI_SubscriptionPlan_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
  original_price?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_SubscriptionPlan_Stddev_Samp_Fields = {
  __typename?: 'WUI_SubscriptionPlan_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
  original_price?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WUI_SubscriptionPlan" */
export type Wui_SubscriptionPlan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_SubscriptionPlan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_SubscriptionPlan_Stream_Cursor_Value_Input = {
  frequency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  original_price?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  stripe_price_id?: InputMaybe<Scalars['String']>;
  stripe_product_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Wui_SubscriptionPlan_Sum_Fields = {
  __typename?: 'WUI_SubscriptionPlan_sum_fields';
  order?: Maybe<Scalars['Int']>;
  original_price?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
};

/** update columns of table "WUI_SubscriptionPlan" */
export enum Wui_SubscriptionPlan_Update_Column {
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  OriginalPrice = 'original_price',
  /** column name */
  Price = 'price',
  /** column name */
  StripePriceId = 'stripe_price_id',
  /** column name */
  StripeProductId = 'stripe_product_id'
}

export type Wui_SubscriptionPlan_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_SubscriptionPlan_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_SubscriptionPlan_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_SubscriptionPlan_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_SubscriptionPlan_Var_Pop_Fields = {
  __typename?: 'WUI_SubscriptionPlan_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
  original_price?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_SubscriptionPlan_Var_Samp_Fields = {
  __typename?: 'WUI_SubscriptionPlan_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
  original_price?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_SubscriptionPlan_Variance_Fields = {
  __typename?: 'WUI_SubscriptionPlan_variance_fields';
  order?: Maybe<Scalars['Float']>;
  original_price?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_User" */
export type Wui_User = {
  __typename?: 'WUI_User';
  /** An object relationship */
  Ambassador?: Maybe<Ambassador>;
  /** An array relationship */
  Links: Array<SocialMediaLink>;
  /** An aggregate relationship */
  Links_aggregate: SocialMediaLink_Aggregate;
  /** An array relationship */
  PortfolioFiles: Array<Wui_UserFilePortfolio>;
  /** An aggregate relationship */
  PortfolioFiles_aggregate: Wui_UserFilePortfolio_Aggregate;
  /** An array relationship */
  SocialMediaDemographics: Array<SocialMediaDemographic>;
  /** An aggregate relationship */
  SocialMediaDemographics_aggregate: SocialMediaDemographic_Aggregate;
  /** An array relationship */
  UserVideos: Array<UserVideo>;
  /** An aggregate relationship */
  UserVideos_aggregate: UserVideo_Aggregate;
  /** An array relationship */
  WUI_Accounts: Array<Wui_Account>;
  /** An aggregate relationship */
  WUI_Accounts_aggregate: Wui_Account_Aggregate;
  /** An object relationship */
  WUI_Affiliate?: Maybe<Wui_Affiliate>;
  /** An object relationship */
  WUI_AuthProvider?: Maybe<Wui_AuthProvider>;
  /** An array relationship */
  WUI_AuthSessions: Array<Wui_AuthSession>;
  /** An aggregate relationship */
  WUI_AuthSessions_aggregate: Wui_AuthSession_Aggregate;
  /** An object relationship */
  WUI_File_Cover_Picture?: Maybe<Wui_File>;
  /** An object relationship */
  WUI_File_Profile_Picture?: Maybe<Wui_File>;
  /** An array relationship */
  WUI_Notification: Array<Wui_Notification>;
  /** An aggregate relationship */
  WUI_Notification_aggregate: Wui_Notification_Aggregate;
  /** An array relationship */
  WUI_SellerPackages: Array<Wui_SellerPackage>;
  /** An aggregate relationship */
  WUI_SellerPackages_aggregate: Wui_SellerPackage_Aggregate;
  /** An array relationship */
  WUI_SessionEvents: Array<Wui_SessionEvent>;
  /** An aggregate relationship */
  WUI_SessionEvents_aggregate: Wui_SessionEvent_Aggregate;
  /** An array relationship */
  WUI_Sessions: Array<Wui_Session>;
  /** An aggregate relationship */
  WUI_Sessions_aggregate: Wui_Session_Aggregate;
  /** An array relationship */
  WUI_UserReviews: Array<Wui_UserReview>;
  /** An aggregate relationship */
  WUI_UserReviews_aggregate: Wui_UserReview_Aggregate;
  /** An array relationship */
  WUI_UserSubscriptions: Array<Wui_UserSubscription>;
  /** An aggregate relationship */
  WUI_UserSubscriptions_aggregate: Wui_UserSubscription_Aggregate;
  about_me?: Maybe<Scalars['String']>;
  accepted_terms?: Maybe<Scalars['Boolean']>;
  allow_contact_without_order?: Maybe<Scalars['Boolean']>;
  ambassador_id?: Maybe<Scalars['uuid']>;
  auth_provider_id?: Maybe<Scalars['bigint']>;
  banned?: Maybe<Scalars['Boolean']>;
  completed_legal_information: Scalars['Boolean'];
  completed_profile?: Maybe<Scalars['Boolean']>;
  contact_email: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  cover_picture_file_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  custom_color?: Maybe<Scalars['String']>;
  entity_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "user_full_name" */
  full_name?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  industry?: Maybe<Industry_Enum>;
  is_seller: Scalars['Boolean'];
  language: Scalars['String'];
  last_access_time?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  last_seen_date?: Maybe<Scalars['timestamptz']>;
  phone_number?: Maybe<Scalars['String']>;
  platform_brand: Scalars['String'];
  profile_picture_file_id?: Maybe<Scalars['bigint']>;
  /** A computed field, executes function "user_public_name" */
  public_name?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  source: SignupSource_Enum;
  stripe_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  type?: Maybe<Wui_UserType_Enum>;
  username?: Maybe<Scalars['String']>;
  uuid: Scalars['uuid'];
  verified?: Maybe<Scalars['Boolean']>;
  video_url?: Maybe<Scalars['String']>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserLinksArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaLink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaLink_Order_By>>;
  where?: InputMaybe<SocialMediaLink_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaLink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaLink_Order_By>>;
  where?: InputMaybe<SocialMediaLink_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserPortfolioFilesArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserFilePortfolio_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserFilePortfolio_Order_By>>;
  where?: InputMaybe<Wui_UserFilePortfolio_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserPortfolioFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserFilePortfolio_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserFilePortfolio_Order_By>>;
  where?: InputMaybe<Wui_UserFilePortfolio_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserSocialMediaDemographicsArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaDemographic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaDemographic_Order_By>>;
  where?: InputMaybe<SocialMediaDemographic_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserSocialMediaDemographics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaDemographic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaDemographic_Order_By>>;
  where?: InputMaybe<SocialMediaDemographic_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserUserVideosArgs = {
  distinct_on?: InputMaybe<Array<UserVideo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserVideo_Order_By>>;
  where?: InputMaybe<UserVideo_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserUserVideos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserVideo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserVideo_Order_By>>;
  where?: InputMaybe<UserVideo_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Wui_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Account_Order_By>>;
  where?: InputMaybe<Wui_Account_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Account_Order_By>>;
  where?: InputMaybe<Wui_Account_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_AuthSessionsArgs = {
  distinct_on?: InputMaybe<Array<Wui_AuthSession_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AuthSession_Order_By>>;
  where?: InputMaybe<Wui_AuthSession_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_AuthSessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_AuthSession_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AuthSession_Order_By>>;
  where?: InputMaybe<Wui_AuthSession_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_NotificationArgs = {
  distinct_on?: InputMaybe<Array<Wui_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Notification_Order_By>>;
  where?: InputMaybe<Wui_Notification_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Notification_Order_By>>;
  where?: InputMaybe<Wui_Notification_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_SellerPackagesArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerPackage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerPackage_Order_By>>;
  where?: InputMaybe<Wui_SellerPackage_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_SellerPackages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerPackage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerPackage_Order_By>>;
  where?: InputMaybe<Wui_SellerPackage_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_SessionEventsArgs = {
  distinct_on?: InputMaybe<Array<Wui_SessionEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SessionEvent_Order_By>>;
  where?: InputMaybe<Wui_SessionEvent_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_SessionEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SessionEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SessionEvent_Order_By>>;
  where?: InputMaybe<Wui_SessionEvent_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Wui_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Session_Order_By>>;
  where?: InputMaybe<Wui_Session_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Session_Order_By>>;
  where?: InputMaybe<Wui_Session_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_UserReviewsArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserReview_Order_By>>;
  where?: InputMaybe<Wui_UserReview_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_UserReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserReview_Order_By>>;
  where?: InputMaybe<Wui_UserReview_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_UserSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserSubscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserSubscription_Order_By>>;
  where?: InputMaybe<Wui_UserSubscription_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_UserSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserSubscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserSubscription_Order_By>>;
  where?: InputMaybe<Wui_UserSubscription_Bool_Exp>;
};

/** columns and relationships of "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio = {
  __typename?: 'WUI_UserFilePortfolio';
  /** An object relationship */
  WUI_File?: Maybe<Wui_File>;
  /** An object relationship */
  WUI_User: Wui_User;
  file_id?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  order?: Maybe<Scalars['Int']>;
  user_id: Scalars['bigint'];
};

/** aggregated selection of "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Aggregate = {
  __typename?: 'WUI_UserFilePortfolio_aggregate';
  aggregate?: Maybe<Wui_UserFilePortfolio_Aggregate_Fields>;
  nodes: Array<Wui_UserFilePortfolio>;
};

export type Wui_UserFilePortfolio_Aggregate_Bool_Exp = {
  count?: InputMaybe<Wui_UserFilePortfolio_Aggregate_Bool_Exp_Count>;
};

export type Wui_UserFilePortfolio_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wui_UserFilePortfolio_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_UserFilePortfolio_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Aggregate_Fields = {
  __typename?: 'WUI_UserFilePortfolio_aggregate_fields';
  avg?: Maybe<Wui_UserFilePortfolio_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_UserFilePortfolio_Max_Fields>;
  min?: Maybe<Wui_UserFilePortfolio_Min_Fields>;
  stddev?: Maybe<Wui_UserFilePortfolio_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_UserFilePortfolio_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_UserFilePortfolio_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_UserFilePortfolio_Sum_Fields>;
  var_pop?: Maybe<Wui_UserFilePortfolio_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_UserFilePortfolio_Var_Samp_Fields>;
  variance?: Maybe<Wui_UserFilePortfolio_Variance_Fields>;
};


/** aggregate fields of "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_UserFilePortfolio_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Aggregate_Order_By = {
  avg?: InputMaybe<Wui_UserFilePortfolio_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wui_UserFilePortfolio_Max_Order_By>;
  min?: InputMaybe<Wui_UserFilePortfolio_Min_Order_By>;
  stddev?: InputMaybe<Wui_UserFilePortfolio_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wui_UserFilePortfolio_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wui_UserFilePortfolio_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wui_UserFilePortfolio_Sum_Order_By>;
  var_pop?: InputMaybe<Wui_UserFilePortfolio_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wui_UserFilePortfolio_Var_Samp_Order_By>;
  variance?: InputMaybe<Wui_UserFilePortfolio_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Arr_Rel_Insert_Input = {
  data: Array<Wui_UserFilePortfolio_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_UserFilePortfolio_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_UserFilePortfolio_Avg_Fields = {
  __typename?: 'WUI_UserFilePortfolio_avg_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Avg_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_UserFilePortfolio". All fields are combined with a logical 'AND'. */
export type Wui_UserFilePortfolio_Bool_Exp = {
  WUI_File?: InputMaybe<Wui_File_Bool_Exp>;
  WUI_User?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_UserFilePortfolio_Bool_Exp>>;
  _not?: InputMaybe<Wui_UserFilePortfolio_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_UserFilePortfolio_Bool_Exp>>;
  file_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_UserFilePortfolio" */
export enum Wui_UserFilePortfolio_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiUserImagePortfolioPkey = 'WUI_UserImagePortfolio_pkey'
}

/** input type for incrementing numeric columns in table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Inc_Input = {
  file_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  order?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Insert_Input = {
  WUI_File?: InputMaybe<Wui_File_Obj_Rel_Insert_Input>;
  WUI_User?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  order?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_UserFilePortfolio_Max_Fields = {
  __typename?: 'WUI_UserFilePortfolio_max_fields';
  file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Max_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_UserFilePortfolio_Min_Fields = {
  __typename?: 'WUI_UserFilePortfolio_min_fields';
  file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Min_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Mutation_Response = {
  __typename?: 'WUI_UserFilePortfolio_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_UserFilePortfolio>;
};

/** on_conflict condition type for table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_On_Conflict = {
  constraint: Wui_UserFilePortfolio_Constraint;
  update_columns?: Array<Wui_UserFilePortfolio_Update_Column>;
  where?: InputMaybe<Wui_UserFilePortfolio_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_UserFilePortfolio". */
export type Wui_UserFilePortfolio_Order_By = {
  WUI_File?: InputMaybe<Wui_File_Order_By>;
  WUI_User?: InputMaybe<Wui_User_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_UserFilePortfolio */
export type Wui_UserFilePortfolio_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_UserFilePortfolio" */
export enum Wui_UserFilePortfolio_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Set_Input = {
  file_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  order?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_UserFilePortfolio_Stddev_Fields = {
  __typename?: 'WUI_UserFilePortfolio_stddev_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Stddev_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_UserFilePortfolio_Stddev_Pop_Fields = {
  __typename?: 'WUI_UserFilePortfolio_stddev_pop_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Stddev_Pop_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_UserFilePortfolio_Stddev_Samp_Fields = {
  __typename?: 'WUI_UserFilePortfolio_stddev_samp_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Stddev_Samp_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_UserFilePortfolio_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_UserFilePortfolio_Stream_Cursor_Value_Input = {
  file_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  order?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_UserFilePortfolio_Sum_Fields = {
  __typename?: 'WUI_UserFilePortfolio_sum_fields';
  file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Sum_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "WUI_UserFilePortfolio" */
export enum Wui_UserFilePortfolio_Update_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UserId = 'user_id'
}

export type Wui_UserFilePortfolio_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_UserFilePortfolio_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_UserFilePortfolio_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_UserFilePortfolio_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_UserFilePortfolio_Var_Pop_Fields = {
  __typename?: 'WUI_UserFilePortfolio_var_pop_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Var_Pop_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_UserFilePortfolio_Var_Samp_Fields = {
  __typename?: 'WUI_UserFilePortfolio_var_samp_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Var_Samp_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_UserFilePortfolio_Variance_Fields = {
  __typename?: 'WUI_UserFilePortfolio_variance_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Variance_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "WUI_UserReview" */
export type Wui_UserReview = {
  __typename?: 'WUI_UserReview';
  /** An object relationship */
  WUI_User_From: Wui_User;
  /** An object relationship */
  WUI_User_To: Wui_User;
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  from_user_id: Scalars['bigint'];
  id: Scalars['bigint'];
  metadata?: Maybe<Scalars['jsonb']>;
  stars: Scalars['Int'];
  to_user_id: Scalars['bigint'];
};


/** columns and relationships of "WUI_UserReview" */
export type Wui_UserReviewMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "WUI_UserReview" */
export type Wui_UserReview_Aggregate = {
  __typename?: 'WUI_UserReview_aggregate';
  aggregate?: Maybe<Wui_UserReview_Aggregate_Fields>;
  nodes: Array<Wui_UserReview>;
};

export type Wui_UserReview_Aggregate_Bool_Exp = {
  count?: InputMaybe<Wui_UserReview_Aggregate_Bool_Exp_Count>;
};

export type Wui_UserReview_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wui_UserReview_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_UserReview_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WUI_UserReview" */
export type Wui_UserReview_Aggregate_Fields = {
  __typename?: 'WUI_UserReview_aggregate_fields';
  avg?: Maybe<Wui_UserReview_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_UserReview_Max_Fields>;
  min?: Maybe<Wui_UserReview_Min_Fields>;
  stddev?: Maybe<Wui_UserReview_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_UserReview_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_UserReview_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_UserReview_Sum_Fields>;
  var_pop?: Maybe<Wui_UserReview_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_UserReview_Var_Samp_Fields>;
  variance?: Maybe<Wui_UserReview_Variance_Fields>;
};


/** aggregate fields of "WUI_UserReview" */
export type Wui_UserReview_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_UserReview_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_UserReview" */
export type Wui_UserReview_Aggregate_Order_By = {
  avg?: InputMaybe<Wui_UserReview_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wui_UserReview_Max_Order_By>;
  min?: InputMaybe<Wui_UserReview_Min_Order_By>;
  stddev?: InputMaybe<Wui_UserReview_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wui_UserReview_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wui_UserReview_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wui_UserReview_Sum_Order_By>;
  var_pop?: InputMaybe<Wui_UserReview_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wui_UserReview_Var_Samp_Order_By>;
  variance?: InputMaybe<Wui_UserReview_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Wui_UserReview_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "WUI_UserReview" */
export type Wui_UserReview_Arr_Rel_Insert_Input = {
  data: Array<Wui_UserReview_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_UserReview_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_UserReview_Avg_Fields = {
  __typename?: 'WUI_UserReview_avg_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stars?: Maybe<Scalars['Float']>;
  to_user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Avg_Order_By = {
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stars?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_UserReview". All fields are combined with a logical 'AND'. */
export type Wui_UserReview_Bool_Exp = {
  WUI_User_From?: InputMaybe<Wui_User_Bool_Exp>;
  WUI_User_To?: InputMaybe<Wui_User_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_UserReview_Bool_Exp>>;
  _not?: InputMaybe<Wui_UserReview_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_UserReview_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  from_user_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  stars?: InputMaybe<Int_Comparison_Exp>;
  to_user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_UserReview" */
export enum Wui_UserReview_Constraint {
  /** unique or primary key constraint on columns "id" */
  WuiUserReviewPkey = 'WUI_UserReview_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Wui_UserReview_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Wui_UserReview_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Wui_UserReview_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "WUI_UserReview" */
export type Wui_UserReview_Inc_Input = {
  from_user_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  stars?: InputMaybe<Scalars['Int']>;
  to_user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_UserReview" */
export type Wui_UserReview_Insert_Input = {
  WUI_User_From?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  WUI_User_To?: InputMaybe<Wui_User_Obj_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_user_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  stars?: InputMaybe<Scalars['Int']>;
  to_user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_UserReview_Max_Fields = {
  __typename?: 'WUI_UserReview_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_user_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  stars?: Maybe<Scalars['Int']>;
  to_user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stars?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_UserReview_Min_Fields = {
  __typename?: 'WUI_UserReview_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_user_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  stars?: Maybe<Scalars['Int']>;
  to_user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stars?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WUI_UserReview" */
export type Wui_UserReview_Mutation_Response = {
  __typename?: 'WUI_UserReview_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_UserReview>;
};

/** input type for inserting object relation for remote table "WUI_UserReview" */
export type Wui_UserReview_Obj_Rel_Insert_Input = {
  data: Wui_UserReview_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_UserReview_On_Conflict>;
};

/** on_conflict condition type for table "WUI_UserReview" */
export type Wui_UserReview_On_Conflict = {
  constraint: Wui_UserReview_Constraint;
  update_columns?: Array<Wui_UserReview_Update_Column>;
  where?: InputMaybe<Wui_UserReview_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_UserReview". */
export type Wui_UserReview_Order_By = {
  WUI_User_From?: InputMaybe<Wui_User_Order_By>;
  WUI_User_To?: InputMaybe<Wui_User_Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  stars?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_UserReview */
export type Wui_UserReview_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Wui_UserReview_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "WUI_UserReview" */
export enum Wui_UserReview_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromUserId = 'from_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Stars = 'stars',
  /** column name */
  ToUserId = 'to_user_id'
}

/** input type for updating data in table "WUI_UserReview" */
export type Wui_UserReview_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_user_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  stars?: InputMaybe<Scalars['Int']>;
  to_user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_UserReview_Stddev_Fields = {
  __typename?: 'WUI_UserReview_stddev_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stars?: Maybe<Scalars['Float']>;
  to_user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Stddev_Order_By = {
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stars?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_UserReview_Stddev_Pop_Fields = {
  __typename?: 'WUI_UserReview_stddev_pop_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stars?: Maybe<Scalars['Float']>;
  to_user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Stddev_Pop_Order_By = {
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stars?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_UserReview_Stddev_Samp_Fields = {
  __typename?: 'WUI_UserReview_stddev_samp_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stars?: Maybe<Scalars['Float']>;
  to_user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Stddev_Samp_Order_By = {
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stars?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WUI_UserReview" */
export type Wui_UserReview_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_UserReview_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_UserReview_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_user_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  stars?: InputMaybe<Scalars['Int']>;
  to_user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_UserReview_Sum_Fields = {
  __typename?: 'WUI_UserReview_sum_fields';
  from_user_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  stars?: Maybe<Scalars['Int']>;
  to_user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Sum_Order_By = {
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stars?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
};

/** update columns of table "WUI_UserReview" */
export enum Wui_UserReview_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromUserId = 'from_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Stars = 'stars',
  /** column name */
  ToUserId = 'to_user_id'
}

export type Wui_UserReview_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Wui_UserReview_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Wui_UserReview_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Wui_UserReview_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Wui_UserReview_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_UserReview_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Wui_UserReview_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_UserReview_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_UserReview_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_UserReview_Var_Pop_Fields = {
  __typename?: 'WUI_UserReview_var_pop_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stars?: Maybe<Scalars['Float']>;
  to_user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Var_Pop_Order_By = {
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stars?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_UserReview_Var_Samp_Fields = {
  __typename?: 'WUI_UserReview_var_samp_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stars?: Maybe<Scalars['Float']>;
  to_user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Var_Samp_Order_By = {
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stars?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_UserReview_Variance_Fields = {
  __typename?: 'WUI_UserReview_variance_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stars?: Maybe<Scalars['Float']>;
  to_user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Variance_Order_By = {
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stars?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "WUI_UserRole" */
export type Wui_UserRole = {
  __typename?: 'WUI_UserRole';
  role_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "WUI_UserRole" */
export type Wui_UserRole_Aggregate = {
  __typename?: 'WUI_UserRole_aggregate';
  aggregate?: Maybe<Wui_UserRole_Aggregate_Fields>;
  nodes: Array<Wui_UserRole>;
};

/** aggregate fields of "WUI_UserRole" */
export type Wui_UserRole_Aggregate_Fields = {
  __typename?: 'WUI_UserRole_aggregate_fields';
  avg?: Maybe<Wui_UserRole_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_UserRole_Max_Fields>;
  min?: Maybe<Wui_UserRole_Min_Fields>;
  stddev?: Maybe<Wui_UserRole_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_UserRole_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_UserRole_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_UserRole_Sum_Fields>;
  var_pop?: Maybe<Wui_UserRole_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_UserRole_Var_Samp_Fields>;
  variance?: Maybe<Wui_UserRole_Variance_Fields>;
};


/** aggregate fields of "WUI_UserRole" */
export type Wui_UserRole_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_UserRole_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_UserRole_Avg_Fields = {
  __typename?: 'WUI_UserRole_avg_fields';
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_UserRole". All fields are combined with a logical 'AND'. */
export type Wui_UserRole_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_UserRole_Bool_Exp>>;
  _not?: InputMaybe<Wui_UserRole_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_UserRole_Bool_Exp>>;
  role_id?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Wui_UserRole_Max_Fields = {
  __typename?: 'WUI_UserRole_max_fields';
  role_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Wui_UserRole_Min_Fields = {
  __typename?: 'WUI_UserRole_min_fields';
  role_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "WUI_UserRole". */
export type Wui_UserRole_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "WUI_UserRole" */
export enum Wui_UserRole_Select_Column {
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Wui_UserRole_Stddev_Fields = {
  __typename?: 'WUI_UserRole_stddev_fields';
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_UserRole_Stddev_Pop_Fields = {
  __typename?: 'WUI_UserRole_stddev_pop_fields';
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_UserRole_Stddev_Samp_Fields = {
  __typename?: 'WUI_UserRole_stddev_samp_fields';
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WUI_UserRole" */
export type Wui_UserRole_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_UserRole_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_UserRole_Stream_Cursor_Value_Input = {
  role_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_UserRole_Sum_Fields = {
  __typename?: 'WUI_UserRole_sum_fields';
  role_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Wui_UserRole_Var_Pop_Fields = {
  __typename?: 'WUI_UserRole_var_pop_fields';
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_UserRole_Var_Samp_Fields = {
  __typename?: 'WUI_UserRole_var_samp_fields';
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_UserRole_Variance_Fields = {
  __typename?: 'WUI_UserRole_variance_fields';
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_UserSubscription" */
export type Wui_UserSubscription = {
  __typename?: 'WUI_UserSubscription';
  cancel_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  expires_at: Scalars['timestamptz'];
  external_id: Scalars['String'];
  id: Scalars['uuid'];
  paid: Scalars['Boolean'];
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['bigint'];
};

/** aggregated selection of "WUI_UserSubscription" */
export type Wui_UserSubscription_Aggregate = {
  __typename?: 'WUI_UserSubscription_aggregate';
  aggregate?: Maybe<Wui_UserSubscription_Aggregate_Fields>;
  nodes: Array<Wui_UserSubscription>;
};

export type Wui_UserSubscription_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Wui_UserSubscription_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Wui_UserSubscription_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Wui_UserSubscription_Aggregate_Bool_Exp_Count>;
};

export type Wui_UserSubscription_Aggregate_Bool_Exp_Bool_And = {
  arguments: Wui_UserSubscription_Select_Column_Wui_UserSubscription_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_UserSubscription_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Wui_UserSubscription_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Wui_UserSubscription_Select_Column_Wui_UserSubscription_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_UserSubscription_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Wui_UserSubscription_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wui_UserSubscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wui_UserSubscription_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WUI_UserSubscription" */
export type Wui_UserSubscription_Aggregate_Fields = {
  __typename?: 'WUI_UserSubscription_aggregate_fields';
  avg?: Maybe<Wui_UserSubscription_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_UserSubscription_Max_Fields>;
  min?: Maybe<Wui_UserSubscription_Min_Fields>;
  stddev?: Maybe<Wui_UserSubscription_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_UserSubscription_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_UserSubscription_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_UserSubscription_Sum_Fields>;
  var_pop?: Maybe<Wui_UserSubscription_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_UserSubscription_Var_Samp_Fields>;
  variance?: Maybe<Wui_UserSubscription_Variance_Fields>;
};


/** aggregate fields of "WUI_UserSubscription" */
export type Wui_UserSubscription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_UserSubscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_UserSubscription" */
export type Wui_UserSubscription_Aggregate_Order_By = {
  avg?: InputMaybe<Wui_UserSubscription_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wui_UserSubscription_Max_Order_By>;
  min?: InputMaybe<Wui_UserSubscription_Min_Order_By>;
  stddev?: InputMaybe<Wui_UserSubscription_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wui_UserSubscription_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wui_UserSubscription_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wui_UserSubscription_Sum_Order_By>;
  var_pop?: InputMaybe<Wui_UserSubscription_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wui_UserSubscription_Var_Samp_Order_By>;
  variance?: InputMaybe<Wui_UserSubscription_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_UserSubscription" */
export type Wui_UserSubscription_Arr_Rel_Insert_Input = {
  data: Array<Wui_UserSubscription_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_UserSubscription_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_UserSubscription_Avg_Fields = {
  __typename?: 'WUI_UserSubscription_avg_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_UserSubscription" */
export type Wui_UserSubscription_Avg_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_UserSubscription". All fields are combined with a logical 'AND'. */
export type Wui_UserSubscription_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_UserSubscription_Bool_Exp>>;
  _not?: InputMaybe<Wui_UserSubscription_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_UserSubscription_Bool_Exp>>;
  cancel_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  paid?: InputMaybe<Boolean_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_UserSubscription" */
export enum Wui_UserSubscription_Constraint {
  /** unique or primary key constraint on columns "external_id" */
  WuiUserSubscriptionPkey = 'WUI_UserSubscription_pkey'
}

/** input type for incrementing numeric columns in table "WUI_UserSubscription" */
export type Wui_UserSubscription_Inc_Input = {
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_UserSubscription" */
export type Wui_UserSubscription_Insert_Input = {
  cancel_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  paid?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_UserSubscription_Max_Fields = {
  __typename?: 'WUI_UserSubscription_max_fields';
  cancel_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_UserSubscription" */
export type Wui_UserSubscription_Max_Order_By = {
  cancel_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_UserSubscription_Min_Fields = {
  __typename?: 'WUI_UserSubscription_min_fields';
  cancel_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_UserSubscription" */
export type Wui_UserSubscription_Min_Order_By = {
  cancel_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WUI_UserSubscription" */
export type Wui_UserSubscription_Mutation_Response = {
  __typename?: 'WUI_UserSubscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_UserSubscription>;
};

/** on_conflict condition type for table "WUI_UserSubscription" */
export type Wui_UserSubscription_On_Conflict = {
  constraint: Wui_UserSubscription_Constraint;
  update_columns?: Array<Wui_UserSubscription_Update_Column>;
  where?: InputMaybe<Wui_UserSubscription_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_UserSubscription". */
export type Wui_UserSubscription_Order_By = {
  cancel_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paid?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_UserSubscription */
export type Wui_UserSubscription_Pk_Columns_Input = {
  external_id: Scalars['String'];
};

/** select columns of table "WUI_UserSubscription" */
export enum Wui_UserSubscription_Select_Column {
  /** column name */
  CancelAt = 'cancel_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Paid = 'paid',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "WUI_UserSubscription_aggregate_bool_exp_bool_and_arguments_columns" columns of table "WUI_UserSubscription" */
export enum Wui_UserSubscription_Select_Column_Wui_UserSubscription_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Paid = 'paid'
}

/** select "WUI_UserSubscription_aggregate_bool_exp_bool_or_arguments_columns" columns of table "WUI_UserSubscription" */
export enum Wui_UserSubscription_Select_Column_Wui_UserSubscription_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Paid = 'paid'
}

/** input type for updating data in table "WUI_UserSubscription" */
export type Wui_UserSubscription_Set_Input = {
  cancel_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  paid?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_UserSubscription_Stddev_Fields = {
  __typename?: 'WUI_UserSubscription_stddev_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_UserSubscription" */
export type Wui_UserSubscription_Stddev_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_UserSubscription_Stddev_Pop_Fields = {
  __typename?: 'WUI_UserSubscription_stddev_pop_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_UserSubscription" */
export type Wui_UserSubscription_Stddev_Pop_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_UserSubscription_Stddev_Samp_Fields = {
  __typename?: 'WUI_UserSubscription_stddev_samp_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_UserSubscription" */
export type Wui_UserSubscription_Stddev_Samp_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WUI_UserSubscription" */
export type Wui_UserSubscription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_UserSubscription_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_UserSubscription_Stream_Cursor_Value_Input = {
  cancel_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  paid?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Wui_UserSubscription_Sum_Fields = {
  __typename?: 'WUI_UserSubscription_sum_fields';
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_UserSubscription" */
export type Wui_UserSubscription_Sum_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "WUI_UserSubscription" */
export enum Wui_UserSubscription_Update_Column {
  /** column name */
  CancelAt = 'cancel_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Paid = 'paid',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Wui_UserSubscription_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_UserSubscription_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_UserSubscription_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_UserSubscription_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_UserSubscription_Var_Pop_Fields = {
  __typename?: 'WUI_UserSubscription_var_pop_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_UserSubscription" */
export type Wui_UserSubscription_Var_Pop_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_UserSubscription_Var_Samp_Fields = {
  __typename?: 'WUI_UserSubscription_var_samp_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_UserSubscription" */
export type Wui_UserSubscription_Var_Samp_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_UserSubscription_Variance_Fields = {
  __typename?: 'WUI_UserSubscription_variance_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_UserSubscription" */
export type Wui_UserSubscription_Variance_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "WUI_UserType" */
export type Wui_UserType = {
  __typename?: 'WUI_UserType';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_UserType" */
export type Wui_UserType_Aggregate = {
  __typename?: 'WUI_UserType_aggregate';
  aggregate?: Maybe<Wui_UserType_Aggregate_Fields>;
  nodes: Array<Wui_UserType>;
};

/** aggregate fields of "WUI_UserType" */
export type Wui_UserType_Aggregate_Fields = {
  __typename?: 'WUI_UserType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_UserType_Max_Fields>;
  min?: Maybe<Wui_UserType_Min_Fields>;
};


/** aggregate fields of "WUI_UserType" */
export type Wui_UserType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_UserType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_UserType". All fields are combined with a logical 'AND'. */
export type Wui_UserType_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_UserType_Bool_Exp>>;
  _not?: InputMaybe<Wui_UserType_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_UserType_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_UserType" */
export enum Wui_UserType_Constraint {
  /** unique or primary key constraint on columns "value" */
  WuiUserTypePkey = 'WUI_UserType_pkey'
}

export enum Wui_UserType_Enum {
  Brand = 'brand',
  Client = 'client',
  Fan = 'fan',
  Influencer = 'influencer'
}

/** Boolean expression to compare columns of type "WUI_UserType_enum". All fields are combined with logical 'AND'. */
export type Wui_UserType_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Wui_UserType_Enum>;
  _in?: InputMaybe<Array<Wui_UserType_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Wui_UserType_Enum>;
  _nin?: InputMaybe<Array<Wui_UserType_Enum>>;
};

/** input type for inserting data into table "WUI_UserType" */
export type Wui_UserType_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_UserType_Max_Fields = {
  __typename?: 'WUI_UserType_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_UserType_Min_Fields = {
  __typename?: 'WUI_UserType_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_UserType" */
export type Wui_UserType_Mutation_Response = {
  __typename?: 'WUI_UserType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_UserType>;
};

/** on_conflict condition type for table "WUI_UserType" */
export type Wui_UserType_On_Conflict = {
  constraint: Wui_UserType_Constraint;
  update_columns?: Array<Wui_UserType_Update_Column>;
  where?: InputMaybe<Wui_UserType_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_UserType". */
export type Wui_UserType_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_UserType */
export type Wui_UserType_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_UserType" */
export enum Wui_UserType_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_UserType" */
export type Wui_UserType_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "WUI_UserType" */
export type Wui_UserType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_UserType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_UserType_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "WUI_UserType" */
export enum Wui_UserType_Update_Column {
  /** column name */
  Value = 'value'
}

export type Wui_UserType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_UserType_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_UserType_Bool_Exp;
};

/** aggregated selection of "WUI_User" */
export type Wui_User_Aggregate = {
  __typename?: 'WUI_User_aggregate';
  aggregate?: Maybe<Wui_User_Aggregate_Fields>;
  nodes: Array<Wui_User>;
};

/** aggregate fields of "WUI_User" */
export type Wui_User_Aggregate_Fields = {
  __typename?: 'WUI_User_aggregate_fields';
  avg?: Maybe<Wui_User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_User_Max_Fields>;
  min?: Maybe<Wui_User_Min_Fields>;
  stddev?: Maybe<Wui_User_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_User_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_User_Sum_Fields>;
  var_pop?: Maybe<Wui_User_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_User_Var_Samp_Fields>;
  variance?: Maybe<Wui_User_Variance_Fields>;
};


/** aggregate fields of "WUI_User" */
export type Wui_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_User_Avg_Fields = {
  __typename?: 'WUI_User_avg_fields';
  auth_provider_id?: Maybe<Scalars['Float']>;
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_User". All fields are combined with a logical 'AND'. */
export type Wui_User_Bool_Exp = {
  Ambassador?: InputMaybe<Ambassador_Bool_Exp>;
  Links?: InputMaybe<SocialMediaLink_Bool_Exp>;
  Links_aggregate?: InputMaybe<SocialMediaLink_Aggregate_Bool_Exp>;
  PortfolioFiles?: InputMaybe<Wui_UserFilePortfolio_Bool_Exp>;
  PortfolioFiles_aggregate?: InputMaybe<Wui_UserFilePortfolio_Aggregate_Bool_Exp>;
  SocialMediaDemographics?: InputMaybe<SocialMediaDemographic_Bool_Exp>;
  SocialMediaDemographics_aggregate?: InputMaybe<SocialMediaDemographic_Aggregate_Bool_Exp>;
  UserVideos?: InputMaybe<UserVideo_Bool_Exp>;
  UserVideos_aggregate?: InputMaybe<UserVideo_Aggregate_Bool_Exp>;
  WUI_Accounts?: InputMaybe<Wui_Account_Bool_Exp>;
  WUI_Accounts_aggregate?: InputMaybe<Wui_Account_Aggregate_Bool_Exp>;
  WUI_Affiliate?: InputMaybe<Wui_Affiliate_Bool_Exp>;
  WUI_AuthProvider?: InputMaybe<Wui_AuthProvider_Bool_Exp>;
  WUI_AuthSessions?: InputMaybe<Wui_AuthSession_Bool_Exp>;
  WUI_AuthSessions_aggregate?: InputMaybe<Wui_AuthSession_Aggregate_Bool_Exp>;
  WUI_File_Cover_Picture?: InputMaybe<Wui_File_Bool_Exp>;
  WUI_File_Profile_Picture?: InputMaybe<Wui_File_Bool_Exp>;
  WUI_Notification?: InputMaybe<Wui_Notification_Bool_Exp>;
  WUI_Notification_aggregate?: InputMaybe<Wui_Notification_Aggregate_Bool_Exp>;
  WUI_SellerPackages?: InputMaybe<Wui_SellerPackage_Bool_Exp>;
  WUI_SellerPackages_aggregate?: InputMaybe<Wui_SellerPackage_Aggregate_Bool_Exp>;
  WUI_SessionEvents?: InputMaybe<Wui_SessionEvent_Bool_Exp>;
  WUI_SessionEvents_aggregate?: InputMaybe<Wui_SessionEvent_Aggregate_Bool_Exp>;
  WUI_Sessions?: InputMaybe<Wui_Session_Bool_Exp>;
  WUI_Sessions_aggregate?: InputMaybe<Wui_Session_Aggregate_Bool_Exp>;
  WUI_UserReviews?: InputMaybe<Wui_UserReview_Bool_Exp>;
  WUI_UserReviews_aggregate?: InputMaybe<Wui_UserReview_Aggregate_Bool_Exp>;
  WUI_UserSubscriptions?: InputMaybe<Wui_UserSubscription_Bool_Exp>;
  WUI_UserSubscriptions_aggregate?: InputMaybe<Wui_UserSubscription_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Wui_User_Bool_Exp>>;
  _not?: InputMaybe<Wui_User_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_User_Bool_Exp>>;
  about_me?: InputMaybe<String_Comparison_Exp>;
  accepted_terms?: InputMaybe<Boolean_Comparison_Exp>;
  allow_contact_without_order?: InputMaybe<Boolean_Comparison_Exp>;
  ambassador_id?: InputMaybe<Uuid_Comparison_Exp>;
  auth_provider_id?: InputMaybe<Bigint_Comparison_Exp>;
  banned?: InputMaybe<Boolean_Comparison_Exp>;
  completed_legal_information?: InputMaybe<Boolean_Comparison_Exp>;
  completed_profile?: InputMaybe<Boolean_Comparison_Exp>;
  contact_email?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  cover_picture_file_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_color?: InputMaybe<String_Comparison_Exp>;
  entity_name?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  industry?: InputMaybe<Industry_Enum_Comparison_Exp>;
  is_seller?: InputMaybe<Boolean_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  last_access_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  last_seen_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  platform_brand?: InputMaybe<String_Comparison_Exp>;
  profile_picture_file_id?: InputMaybe<Bigint_Comparison_Exp>;
  public_name?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<SignupSource_Enum_Comparison_Exp>;
  stripe_account_id?: InputMaybe<String_Comparison_Exp>;
  stripe_customer_id?: InputMaybe<String_Comparison_Exp>;
  theme_color?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Wui_UserType_Enum_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
  uuid?: InputMaybe<Uuid_Comparison_Exp>;
  verified?: InputMaybe<Boolean_Comparison_Exp>;
  video_url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_User" */
export enum Wui_User_Constraint {
  /** unique or primary key constraint on columns "stripe_customer_id" */
  WuiUserStripeCustomerIdKey = 'WUI_User_stripe_customer_id_key',
  /** unique or primary key constraint on columns "contact_email" */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey',
  /** unique or primary key constraint on columns "username" */
  UserUsernameKey = 'user_username_key',
  /** unique or primary key constraint on columns "contact_email" */
  WuiUserContactEmailKey = 'wui_user_contact_email_key'
}

/** input type for incrementing numeric columns in table "WUI_User" */
export type Wui_User_Inc_Input = {
  auth_provider_id?: InputMaybe<Scalars['bigint']>;
  cover_picture_file_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  profile_picture_file_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_User" */
export type Wui_User_Insert_Input = {
  Ambassador?: InputMaybe<Ambassador_Obj_Rel_Insert_Input>;
  Links?: InputMaybe<SocialMediaLink_Arr_Rel_Insert_Input>;
  PortfolioFiles?: InputMaybe<Wui_UserFilePortfolio_Arr_Rel_Insert_Input>;
  SocialMediaDemographics?: InputMaybe<SocialMediaDemographic_Arr_Rel_Insert_Input>;
  UserVideos?: InputMaybe<UserVideo_Arr_Rel_Insert_Input>;
  WUI_Accounts?: InputMaybe<Wui_Account_Arr_Rel_Insert_Input>;
  WUI_Affiliate?: InputMaybe<Wui_Affiliate_Obj_Rel_Insert_Input>;
  WUI_AuthProvider?: InputMaybe<Wui_AuthProvider_Obj_Rel_Insert_Input>;
  WUI_AuthSessions?: InputMaybe<Wui_AuthSession_Arr_Rel_Insert_Input>;
  WUI_File_Cover_Picture?: InputMaybe<Wui_File_Obj_Rel_Insert_Input>;
  WUI_File_Profile_Picture?: InputMaybe<Wui_File_Obj_Rel_Insert_Input>;
  WUI_Notification?: InputMaybe<Wui_Notification_Arr_Rel_Insert_Input>;
  WUI_SellerPackages?: InputMaybe<Wui_SellerPackage_Arr_Rel_Insert_Input>;
  WUI_SessionEvents?: InputMaybe<Wui_SessionEvent_Arr_Rel_Insert_Input>;
  WUI_Sessions?: InputMaybe<Wui_Session_Arr_Rel_Insert_Input>;
  WUI_UserReviews?: InputMaybe<Wui_UserReview_Arr_Rel_Insert_Input>;
  WUI_UserSubscriptions?: InputMaybe<Wui_UserSubscription_Arr_Rel_Insert_Input>;
  about_me?: InputMaybe<Scalars['String']>;
  accepted_terms?: InputMaybe<Scalars['Boolean']>;
  allow_contact_without_order?: InputMaybe<Scalars['Boolean']>;
  ambassador_id?: InputMaybe<Scalars['uuid']>;
  auth_provider_id?: InputMaybe<Scalars['bigint']>;
  banned?: InputMaybe<Scalars['Boolean']>;
  completed_legal_information?: InputMaybe<Scalars['Boolean']>;
  completed_profile?: InputMaybe<Scalars['Boolean']>;
  contact_email?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  cover_picture_file_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_color?: InputMaybe<Scalars['String']>;
  entity_name?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  industry?: InputMaybe<Industry_Enum>;
  is_seller?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  last_access_time?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  last_seen_date?: InputMaybe<Scalars['timestamptz']>;
  phone_number?: InputMaybe<Scalars['String']>;
  platform_brand?: InputMaybe<Scalars['String']>;
  profile_picture_file_id?: InputMaybe<Scalars['bigint']>;
  role?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<SignupSource_Enum>;
  stripe_account_id?: InputMaybe<Scalars['String']>;
  stripe_customer_id?: InputMaybe<Scalars['String']>;
  theme_color?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Wui_UserType_Enum>;
  username?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['uuid']>;
  verified?: InputMaybe<Scalars['Boolean']>;
  video_url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_User_Max_Fields = {
  __typename?: 'WUI_User_max_fields';
  about_me?: Maybe<Scalars['String']>;
  ambassador_id?: Maybe<Scalars['uuid']>;
  auth_provider_id?: Maybe<Scalars['bigint']>;
  contact_email?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  cover_picture_file_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_color?: Maybe<Scalars['String']>;
  entity_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "user_full_name" */
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Scalars['String']>;
  last_access_time?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  last_seen_date?: Maybe<Scalars['timestamptz']>;
  phone_number?: Maybe<Scalars['String']>;
  platform_brand?: Maybe<Scalars['String']>;
  profile_picture_file_id?: Maybe<Scalars['bigint']>;
  /** A computed field, executes function "user_public_name" */
  public_name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  stripe_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
  video_url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_User_Min_Fields = {
  __typename?: 'WUI_User_min_fields';
  about_me?: Maybe<Scalars['String']>;
  ambassador_id?: Maybe<Scalars['uuid']>;
  auth_provider_id?: Maybe<Scalars['bigint']>;
  contact_email?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  cover_picture_file_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_color?: Maybe<Scalars['String']>;
  entity_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "user_full_name" */
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Scalars['String']>;
  last_access_time?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  last_seen_date?: Maybe<Scalars['timestamptz']>;
  phone_number?: Maybe<Scalars['String']>;
  platform_brand?: Maybe<Scalars['String']>;
  profile_picture_file_id?: Maybe<Scalars['bigint']>;
  /** A computed field, executes function "user_public_name" */
  public_name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  stripe_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
  video_url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_User" */
export type Wui_User_Mutation_Response = {
  __typename?: 'WUI_User_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_User>;
};

/** input type for inserting object relation for remote table "WUI_User" */
export type Wui_User_Obj_Rel_Insert_Input = {
  data: Wui_User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wui_User_On_Conflict>;
};

/** on_conflict condition type for table "WUI_User" */
export type Wui_User_On_Conflict = {
  constraint: Wui_User_Constraint;
  update_columns?: Array<Wui_User_Update_Column>;
  where?: InputMaybe<Wui_User_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_User". */
export type Wui_User_Order_By = {
  Ambassador?: InputMaybe<Ambassador_Order_By>;
  Links_aggregate?: InputMaybe<SocialMediaLink_Aggregate_Order_By>;
  PortfolioFiles_aggregate?: InputMaybe<Wui_UserFilePortfolio_Aggregate_Order_By>;
  SocialMediaDemographics_aggregate?: InputMaybe<SocialMediaDemographic_Aggregate_Order_By>;
  UserVideos_aggregate?: InputMaybe<UserVideo_Aggregate_Order_By>;
  WUI_Accounts_aggregate?: InputMaybe<Wui_Account_Aggregate_Order_By>;
  WUI_Affiliate?: InputMaybe<Wui_Affiliate_Order_By>;
  WUI_AuthProvider?: InputMaybe<Wui_AuthProvider_Order_By>;
  WUI_AuthSessions_aggregate?: InputMaybe<Wui_AuthSession_Aggregate_Order_By>;
  WUI_File_Cover_Picture?: InputMaybe<Wui_File_Order_By>;
  WUI_File_Profile_Picture?: InputMaybe<Wui_File_Order_By>;
  WUI_Notification_aggregate?: InputMaybe<Wui_Notification_Aggregate_Order_By>;
  WUI_SellerPackages_aggregate?: InputMaybe<Wui_SellerPackage_Aggregate_Order_By>;
  WUI_SessionEvents_aggregate?: InputMaybe<Wui_SessionEvent_Aggregate_Order_By>;
  WUI_Sessions_aggregate?: InputMaybe<Wui_Session_Aggregate_Order_By>;
  WUI_UserReviews_aggregate?: InputMaybe<Wui_UserReview_Aggregate_Order_By>;
  WUI_UserSubscriptions_aggregate?: InputMaybe<Wui_UserSubscription_Aggregate_Order_By>;
  about_me?: InputMaybe<Order_By>;
  accepted_terms?: InputMaybe<Order_By>;
  allow_contact_without_order?: InputMaybe<Order_By>;
  ambassador_id?: InputMaybe<Order_By>;
  auth_provider_id?: InputMaybe<Order_By>;
  banned?: InputMaybe<Order_By>;
  completed_legal_information?: InputMaybe<Order_By>;
  completed_profile?: InputMaybe<Order_By>;
  contact_email?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  cover_picture_file_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_color?: InputMaybe<Order_By>;
  entity_name?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  industry?: InputMaybe<Order_By>;
  is_seller?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  last_access_time?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  last_seen_date?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  platform_brand?: InputMaybe<Order_By>;
  profile_picture_file_id?: InputMaybe<Order_By>;
  public_name?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  stripe_account_id?: InputMaybe<Order_By>;
  stripe_customer_id?: InputMaybe<Order_By>;
  theme_color?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
  verified?: InputMaybe<Order_By>;
  video_url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_User */
export type Wui_User_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_User" */
export enum Wui_User_Select_Column {
  /** column name */
  AboutMe = 'about_me',
  /** column name */
  AcceptedTerms = 'accepted_terms',
  /** column name */
  AllowContactWithoutOrder = 'allow_contact_without_order',
  /** column name */
  AmbassadorId = 'ambassador_id',
  /** column name */
  AuthProviderId = 'auth_provider_id',
  /** column name */
  Banned = 'banned',
  /** column name */
  CompletedLegalInformation = 'completed_legal_information',
  /** column name */
  CompletedProfile = 'completed_profile',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  Country = 'country',
  /** column name */
  CoverPictureFileId = 'cover_picture_file_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomColor = 'custom_color',
  /** column name */
  EntityName = 'entity_name',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  IsSeller = 'is_seller',
  /** column name */
  Language = 'language',
  /** column name */
  LastAccessTime = 'last_access_time',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSeenDate = 'last_seen_date',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PlatformBrand = 'platform_brand',
  /** column name */
  ProfilePictureFileId = 'profile_picture_file_id',
  /** column name */
  Role = 'role',
  /** column name */
  Source = 'source',
  /** column name */
  StripeAccountId = 'stripe_account_id',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  ThemeColor = 'theme_color',
  /** column name */
  Type = 'type',
  /** column name */
  Username = 'username',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  Verified = 'verified',
  /** column name */
  VideoUrl = 'video_url'
}

/** input type for updating data in table "WUI_User" */
export type Wui_User_Set_Input = {
  about_me?: InputMaybe<Scalars['String']>;
  accepted_terms?: InputMaybe<Scalars['Boolean']>;
  allow_contact_without_order?: InputMaybe<Scalars['Boolean']>;
  ambassador_id?: InputMaybe<Scalars['uuid']>;
  auth_provider_id?: InputMaybe<Scalars['bigint']>;
  banned?: InputMaybe<Scalars['Boolean']>;
  completed_legal_information?: InputMaybe<Scalars['Boolean']>;
  completed_profile?: InputMaybe<Scalars['Boolean']>;
  contact_email?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  cover_picture_file_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_color?: InputMaybe<Scalars['String']>;
  entity_name?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  industry?: InputMaybe<Industry_Enum>;
  is_seller?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  last_access_time?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  last_seen_date?: InputMaybe<Scalars['timestamptz']>;
  phone_number?: InputMaybe<Scalars['String']>;
  platform_brand?: InputMaybe<Scalars['String']>;
  profile_picture_file_id?: InputMaybe<Scalars['bigint']>;
  role?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<SignupSource_Enum>;
  stripe_account_id?: InputMaybe<Scalars['String']>;
  stripe_customer_id?: InputMaybe<Scalars['String']>;
  theme_color?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Wui_UserType_Enum>;
  username?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['uuid']>;
  verified?: InputMaybe<Scalars['Boolean']>;
  video_url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Wui_User_Stddev_Fields = {
  __typename?: 'WUI_User_stddev_fields';
  auth_provider_id?: Maybe<Scalars['Float']>;
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_User_Stddev_Pop_Fields = {
  __typename?: 'WUI_User_stddev_pop_fields';
  auth_provider_id?: Maybe<Scalars['Float']>;
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_User_Stddev_Samp_Fields = {
  __typename?: 'WUI_User_stddev_samp_fields';
  auth_provider_id?: Maybe<Scalars['Float']>;
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WUI_User" */
export type Wui_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_User_Stream_Cursor_Value_Input = {
  about_me?: InputMaybe<Scalars['String']>;
  accepted_terms?: InputMaybe<Scalars['Boolean']>;
  allow_contact_without_order?: InputMaybe<Scalars['Boolean']>;
  ambassador_id?: InputMaybe<Scalars['uuid']>;
  auth_provider_id?: InputMaybe<Scalars['bigint']>;
  banned?: InputMaybe<Scalars['Boolean']>;
  completed_legal_information?: InputMaybe<Scalars['Boolean']>;
  completed_profile?: InputMaybe<Scalars['Boolean']>;
  contact_email?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  cover_picture_file_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_color?: InputMaybe<Scalars['String']>;
  entity_name?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  industry?: InputMaybe<Industry_Enum>;
  is_seller?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  last_access_time?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  last_seen_date?: InputMaybe<Scalars['timestamptz']>;
  phone_number?: InputMaybe<Scalars['String']>;
  platform_brand?: InputMaybe<Scalars['String']>;
  profile_picture_file_id?: InputMaybe<Scalars['bigint']>;
  role?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<SignupSource_Enum>;
  stripe_account_id?: InputMaybe<Scalars['String']>;
  stripe_customer_id?: InputMaybe<Scalars['String']>;
  theme_color?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Wui_UserType_Enum>;
  username?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['uuid']>;
  verified?: InputMaybe<Scalars['Boolean']>;
  video_url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Wui_User_Sum_Fields = {
  __typename?: 'WUI_User_sum_fields';
  auth_provider_id?: Maybe<Scalars['bigint']>;
  cover_picture_file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  profile_picture_file_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_User" */
export enum Wui_User_Update_Column {
  /** column name */
  AboutMe = 'about_me',
  /** column name */
  AcceptedTerms = 'accepted_terms',
  /** column name */
  AllowContactWithoutOrder = 'allow_contact_without_order',
  /** column name */
  AmbassadorId = 'ambassador_id',
  /** column name */
  AuthProviderId = 'auth_provider_id',
  /** column name */
  Banned = 'banned',
  /** column name */
  CompletedLegalInformation = 'completed_legal_information',
  /** column name */
  CompletedProfile = 'completed_profile',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  Country = 'country',
  /** column name */
  CoverPictureFileId = 'cover_picture_file_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomColor = 'custom_color',
  /** column name */
  EntityName = 'entity_name',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  IsSeller = 'is_seller',
  /** column name */
  Language = 'language',
  /** column name */
  LastAccessTime = 'last_access_time',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSeenDate = 'last_seen_date',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PlatformBrand = 'platform_brand',
  /** column name */
  ProfilePictureFileId = 'profile_picture_file_id',
  /** column name */
  Role = 'role',
  /** column name */
  Source = 'source',
  /** column name */
  StripeAccountId = 'stripe_account_id',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  ThemeColor = 'theme_color',
  /** column name */
  Type = 'type',
  /** column name */
  Username = 'username',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  Verified = 'verified',
  /** column name */
  VideoUrl = 'video_url'
}

export type Wui_User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_User_Var_Pop_Fields = {
  __typename?: 'WUI_User_var_pop_fields';
  auth_provider_id?: Maybe<Scalars['Float']>;
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_User_Var_Samp_Fields = {
  __typename?: 'WUI_User_var_samp_fields';
  auth_provider_id?: Maybe<Scalars['Float']>;
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_User_Variance_Fields = {
  __typename?: 'WUI_User_variance_fields';
  auth_provider_id?: Maybe<Scalars['Float']>;
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_VerificationToken" */
export type Wui_VerificationToken = {
  __typename?: 'WUI_VerificationToken';
  expires?: Maybe<Scalars['timestamptz']>;
  identifier: Scalars['String'];
  token: Scalars['String'];
};

/** aggregated selection of "WUI_VerificationToken" */
export type Wui_VerificationToken_Aggregate = {
  __typename?: 'WUI_VerificationToken_aggregate';
  aggregate?: Maybe<Wui_VerificationToken_Aggregate_Fields>;
  nodes: Array<Wui_VerificationToken>;
};

/** aggregate fields of "WUI_VerificationToken" */
export type Wui_VerificationToken_Aggregate_Fields = {
  __typename?: 'WUI_VerificationToken_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_VerificationToken_Max_Fields>;
  min?: Maybe<Wui_VerificationToken_Min_Fields>;
};


/** aggregate fields of "WUI_VerificationToken" */
export type Wui_VerificationToken_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_VerificationToken_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_VerificationToken". All fields are combined with a logical 'AND'. */
export type Wui_VerificationToken_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_VerificationToken_Bool_Exp>>;
  _not?: InputMaybe<Wui_VerificationToken_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_VerificationToken_Bool_Exp>>;
  expires?: InputMaybe<Timestamptz_Comparison_Exp>;
  identifier?: InputMaybe<String_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_VerificationToken" */
export enum Wui_VerificationToken_Constraint {
  /** unique or primary key constraint on columns "token" */
  WuiVerificationtokenPkey = 'wui_verificationtoken_pkey'
}

/** input type for inserting data into table "WUI_VerificationToken" */
export type Wui_VerificationToken_Insert_Input = {
  expires?: InputMaybe<Scalars['timestamptz']>;
  identifier?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_VerificationToken_Max_Fields = {
  __typename?: 'WUI_VerificationToken_max_fields';
  expires?: Maybe<Scalars['timestamptz']>;
  identifier?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_VerificationToken_Min_Fields = {
  __typename?: 'WUI_VerificationToken_min_fields';
  expires?: Maybe<Scalars['timestamptz']>;
  identifier?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_VerificationToken" */
export type Wui_VerificationToken_Mutation_Response = {
  __typename?: 'WUI_VerificationToken_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_VerificationToken>;
};

/** on_conflict condition type for table "WUI_VerificationToken" */
export type Wui_VerificationToken_On_Conflict = {
  constraint: Wui_VerificationToken_Constraint;
  update_columns?: Array<Wui_VerificationToken_Update_Column>;
  where?: InputMaybe<Wui_VerificationToken_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_VerificationToken". */
export type Wui_VerificationToken_Order_By = {
  expires?: InputMaybe<Order_By>;
  identifier?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WUI_VerificationToken */
export type Wui_VerificationToken_Pk_Columns_Input = {
  token: Scalars['String'];
};

/** select columns of table "WUI_VerificationToken" */
export enum Wui_VerificationToken_Select_Column {
  /** column name */
  Expires = 'expires',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  Token = 'token'
}

/** input type for updating data in table "WUI_VerificationToken" */
export type Wui_VerificationToken_Set_Input = {
  expires?: InputMaybe<Scalars['timestamptz']>;
  identifier?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "WUI_VerificationToken" */
export type Wui_VerificationToken_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_VerificationToken_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_VerificationToken_Stream_Cursor_Value_Input = {
  expires?: InputMaybe<Scalars['timestamptz']>;
  identifier?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

/** update columns of table "WUI_VerificationToken" */
export enum Wui_VerificationToken_Update_Column {
  /** column name */
  Expires = 'expires',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  Token = 'token'
}

export type Wui_VerificationToken_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_VerificationToken_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_VerificationToken_Bool_Exp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  createBankAccount?: Maybe<BankAccountOutput>;
  createCompanyAccount?: Maybe<CreateCompanyInfoOutput>;
  createIndividualAccount?: Maybe<CreateIndividualAccountOutput>;
  create_WUI_Order?: Maybe<OrderOutput>;
  create_WUI_OrderDetails?: Maybe<OrderDetailsOutput>;
  create_WUI_PaymentIntent?: Maybe<PaymentIntentOutput>;
  create_WUI_PaymentMethodCard?: Maybe<PaymentMethodCardOutput>;
  defaultBankAccount?: Maybe<DefaultBankAccountOutput>;
  /** delete data from the table: "Ambassador" */
  delete_Ambassador?: Maybe<Ambassador_Mutation_Response>;
  /** delete single row from the table: "Ambassador" */
  delete_Ambassador_by_pk?: Maybe<Ambassador>;
  /** delete data from the table: "Demographic" */
  delete_Demographic?: Maybe<Demographic_Mutation_Response>;
  /** delete single row from the table: "Demographic" */
  delete_Demographic_by_pk?: Maybe<Demographic>;
  /** delete data from the table: "EmailDomainLookup" */
  delete_EmailDomainLookup?: Maybe<EmailDomainLookup_Mutation_Response>;
  /** delete single row from the table: "EmailDomainLookup" */
  delete_EmailDomainLookup_by_pk?: Maybe<EmailDomainLookup>;
  /** delete data from the table: "HeroBanner" */
  delete_HeroBanner?: Maybe<HeroBanner_Mutation_Response>;
  /** delete single row from the table: "HeroBanner" */
  delete_HeroBanner_by_pk?: Maybe<HeroBanner>;
  /** delete data from the table: "Industry" */
  delete_Industry?: Maybe<Industry_Mutation_Response>;
  /** delete single row from the table: "Industry" */
  delete_Industry_by_pk?: Maybe<Industry>;
  /** delete data from the table: "MarketplaceWaitlist" */
  delete_MarketplaceWaitlist?: Maybe<MarketplaceWaitlist_Mutation_Response>;
  /** delete single row from the table: "MarketplaceWaitlist" */
  delete_MarketplaceWaitlist_by_pk?: Maybe<MarketplaceWaitlist>;
  /** delete data from the table: "PlatformAlert" */
  delete_PlatformAlert?: Maybe<PlatformAlert_Mutation_Response>;
  /** delete single row from the table: "PlatformAlert" */
  delete_PlatformAlert_by_pk?: Maybe<PlatformAlert>;
  /** delete data from the table: "ProjectDemographic" */
  delete_ProjectDemographic?: Maybe<ProjectDemographic_Mutation_Response>;
  /** delete single row from the table: "ProjectDemographic" */
  delete_ProjectDemographic_by_pk?: Maybe<ProjectDemographic>;
  /** delete data from the table: "SignupSource" */
  delete_SignupSource?: Maybe<SignupSource_Mutation_Response>;
  /** delete data from the table: "SignupSourceTracker" */
  delete_SignupSourceTracker?: Maybe<SignupSourceTracker_Mutation_Response>;
  /** delete single row from the table: "SignupSourceTracker" */
  delete_SignupSourceTracker_by_pk?: Maybe<SignupSourceTracker>;
  /** delete single row from the table: "SignupSource" */
  delete_SignupSource_by_pk?: Maybe<SignupSource>;
  /** delete data from the table: "SocialMedia" */
  delete_SocialMedia?: Maybe<SocialMedia_Mutation_Response>;
  /** delete data from the table: "SocialMediaDemographic" */
  delete_SocialMediaDemographic?: Maybe<SocialMediaDemographic_Mutation_Response>;
  /** delete single row from the table: "SocialMediaDemographic" */
  delete_SocialMediaDemographic_by_pk?: Maybe<SocialMediaDemographic>;
  /** delete data from the table: "SocialMediaHistory" */
  delete_SocialMediaHistory?: Maybe<SocialMediaHistory_Mutation_Response>;
  /** delete single row from the table: "SocialMediaHistory" */
  delete_SocialMediaHistory_by_pk?: Maybe<SocialMediaHistory>;
  /** delete data from the table: "SocialMediaLink" */
  delete_SocialMediaLink?: Maybe<SocialMediaLink_Mutation_Response>;
  /** delete single row from the table: "SocialMediaLink" */
  delete_SocialMediaLink_by_pk?: Maybe<SocialMediaLink>;
  /** delete data from the table: "SocialMediaPlatform" */
  delete_SocialMediaPlatform?: Maybe<SocialMediaPlatform_Mutation_Response>;
  /** delete single row from the table: "SocialMediaPlatform" */
  delete_SocialMediaPlatform_by_pk?: Maybe<SocialMediaPlatform>;
  /** delete single row from the table: "SocialMedia" */
  delete_SocialMedia_by_pk?: Maybe<SocialMedia>;
  /** delete data from the table: "TempLoginWithPassword" */
  delete_TempLoginWithPassword?: Maybe<TempLoginWithPassword_Mutation_Response>;
  /** delete single row from the table: "TempLoginWithPassword" */
  delete_TempLoginWithPassword_by_pk?: Maybe<TempLoginWithPassword>;
  /** delete data from the table: "UserVideo" */
  delete_UserVideo?: Maybe<UserVideo_Mutation_Response>;
  /** delete single row from the table: "UserVideo" */
  delete_UserVideo_by_pk?: Maybe<UserVideo>;
  /** delete data from the table: "Vote" */
  delete_Vote?: Maybe<Vote_Mutation_Response>;
  /** delete data from the table: "VoteCategory" */
  delete_VoteCategory?: Maybe<VoteCategory_Mutation_Response>;
  /** delete single row from the table: "VoteCategory" */
  delete_VoteCategory_by_pk?: Maybe<VoteCategory>;
  /** delete data from the table: "VoteUser" */
  delete_VoteUser?: Maybe<VoteUser_Mutation_Response>;
  /** delete data from the table: "VoteUserCategory" */
  delete_VoteUserCategory?: Maybe<VoteUserCategory_Mutation_Response>;
  /** delete single row from the table: "VoteUserCategory" */
  delete_VoteUserCategory_by_pk?: Maybe<VoteUserCategory>;
  /** delete single row from the table: "VoteUser" */
  delete_VoteUser_by_pk?: Maybe<VoteUser>;
  /** delete single row from the table: "Vote" */
  delete_Vote_by_pk?: Maybe<Vote>;
  /** delete data from the table: "WUI_Account" */
  delete_WUI_Account?: Maybe<Wui_Account_Mutation_Response>;
  /** delete single row from the table: "WUI_Account" */
  delete_WUI_Account_by_pk?: Maybe<Wui_Account>;
  /** delete data from the table: "WUI_Affiliate" */
  delete_WUI_Affiliate?: Maybe<Wui_Affiliate_Mutation_Response>;
  /** delete data from the table: "WUI_AffiliateLevel" */
  delete_WUI_AffiliateLevel?: Maybe<Wui_AffiliateLevel_Mutation_Response>;
  /** delete single row from the table: "WUI_AffiliateLevel" */
  delete_WUI_AffiliateLevel_by_pk?: Maybe<Wui_AffiliateLevel>;
  /** delete data from the table: "WUI_AffiliateStatus" */
  delete_WUI_AffiliateStatus?: Maybe<Wui_AffiliateStatus_Mutation_Response>;
  /** delete single row from the table: "WUI_AffiliateStatus" */
  delete_WUI_AffiliateStatus_by_pk?: Maybe<Wui_AffiliateStatus>;
  /** delete data from the table: "WUI_AffiliateTransaction" */
  delete_WUI_AffiliateTransaction?: Maybe<Wui_AffiliateTransaction_Mutation_Response>;
  /** delete single row from the table: "WUI_AffiliateTransaction" */
  delete_WUI_AffiliateTransaction_by_pk?: Maybe<Wui_AffiliateTransaction>;
  /** delete data from the table: "WUI_AffiliateUsers" */
  delete_WUI_AffiliateUsers?: Maybe<Wui_AffiliateUsers_Mutation_Response>;
  /** delete single row from the table: "WUI_AffiliateUsers" */
  delete_WUI_AffiliateUsers_by_pk?: Maybe<Wui_AffiliateUsers>;
  /** delete single row from the table: "WUI_Affiliate" */
  delete_WUI_Affiliate_by_pk?: Maybe<Wui_Affiliate>;
  /** delete data from the table: "WUI_AuthProvider" */
  delete_WUI_AuthProvider?: Maybe<Wui_AuthProvider_Mutation_Response>;
  /** delete single row from the table: "WUI_AuthProvider" */
  delete_WUI_AuthProvider_by_pk?: Maybe<Wui_AuthProvider>;
  /** delete data from the table: "WUI_AuthSession" */
  delete_WUI_AuthSession?: Maybe<Wui_AuthSession_Mutation_Response>;
  /** delete single row from the table: "WUI_AuthSession" */
  delete_WUI_AuthSession_by_pk?: Maybe<Wui_AuthSession>;
  /** delete data from the table: "WUI_ChatRoom" */
  delete_WUI_ChatRoom?: Maybe<Wui_ChatRoom_Mutation_Response>;
  /** delete data from the table: "WUI_ChatRoomMessage" */
  delete_WUI_ChatRoomMessage?: Maybe<Wui_ChatRoomMessage_Mutation_Response>;
  /** delete single row from the table: "WUI_ChatRoomMessage" */
  delete_WUI_ChatRoomMessage_by_pk?: Maybe<Wui_ChatRoomMessage>;
  /** delete data from the table: "WUI_ChatRoomOrder" */
  delete_WUI_ChatRoomOrder?: Maybe<Wui_ChatRoomOrder_Mutation_Response>;
  /** delete single row from the table: "WUI_ChatRoomOrder" */
  delete_WUI_ChatRoomOrder_by_pk?: Maybe<Wui_ChatRoomOrder>;
  /** delete data from the table: "WUI_ChatRoomType" */
  delete_WUI_ChatRoomType?: Maybe<Wui_ChatRoomType_Mutation_Response>;
  /** delete single row from the table: "WUI_ChatRoomType" */
  delete_WUI_ChatRoomType_by_pk?: Maybe<Wui_ChatRoomType>;
  /** delete data from the table: "WUI_ChatRoomUser" */
  delete_WUI_ChatRoomUser?: Maybe<Wui_ChatRoomUser_Mutation_Response>;
  /** delete single row from the table: "WUI_ChatRoomUser" */
  delete_WUI_ChatRoomUser_by_pk?: Maybe<Wui_ChatRoomUser>;
  /** delete single row from the table: "WUI_ChatRoom" */
  delete_WUI_ChatRoom_by_pk?: Maybe<Wui_ChatRoom>;
  /** delete data from the table: "WUI_File" */
  delete_WUI_File?: Maybe<Wui_File_Mutation_Response>;
  /** delete data from the table: "WUI_FileType" */
  delete_WUI_FileType?: Maybe<Wui_FileType_Mutation_Response>;
  /** delete single row from the table: "WUI_FileType" */
  delete_WUI_FileType_by_pk?: Maybe<Wui_FileType>;
  /** delete single row from the table: "WUI_File" */
  delete_WUI_File_by_pk?: Maybe<Wui_File>;
  /** delete data from the table: "WUI_Notification" */
  delete_WUI_Notification?: Maybe<Wui_Notification_Mutation_Response>;
  /** delete single row from the table: "WUI_Notification" */
  delete_WUI_Notification_by_pk?: Maybe<Wui_Notification>;
  /** delete data from the table: "WUI_Order" */
  delete_WUI_Order?: Maybe<Wui_Order_Mutation_Response>;
  /** delete data from the table: "WUI_OrderReview" */
  delete_WUI_OrderReview?: Maybe<Wui_OrderReview_Mutation_Response>;
  /** delete single row from the table: "WUI_OrderReview" */
  delete_WUI_OrderReview_by_pk?: Maybe<Wui_OrderReview>;
  /** delete data from the table: "WUI_OrderStatus" */
  delete_WUI_OrderStatus?: Maybe<Wui_OrderStatus_Mutation_Response>;
  /** delete single row from the table: "WUI_OrderStatus" */
  delete_WUI_OrderStatus_by_pk?: Maybe<Wui_OrderStatus>;
  /** delete data from the table: "WUI_OrderSubscription" */
  delete_WUI_OrderSubscription?: Maybe<Wui_OrderSubscription_Mutation_Response>;
  /** delete data from the table: "WUI_OrderSubscriptionPeriod" */
  delete_WUI_OrderSubscriptionPeriod?: Maybe<Wui_OrderSubscriptionPeriod_Mutation_Response>;
  /** delete single row from the table: "WUI_OrderSubscriptionPeriod" */
  delete_WUI_OrderSubscriptionPeriod_by_pk?: Maybe<Wui_OrderSubscriptionPeriod>;
  /** delete data from the table: "WUI_OrderSubscriptionStatus" */
  delete_WUI_OrderSubscriptionStatus?: Maybe<Wui_OrderSubscriptionStatus_Mutation_Response>;
  /** delete single row from the table: "WUI_OrderSubscriptionStatus" */
  delete_WUI_OrderSubscriptionStatus_by_pk?: Maybe<Wui_OrderSubscriptionStatus>;
  /** delete single row from the table: "WUI_OrderSubscription" */
  delete_WUI_OrderSubscription_by_pk?: Maybe<Wui_OrderSubscription>;
  /** delete single row from the table: "WUI_Order" */
  delete_WUI_Order_by_pk?: Maybe<Wui_Order>;
  /** delete data from the table: "WUI_PackageRequest" */
  delete_WUI_PackageRequest?: Maybe<Wui_PackageRequest_Mutation_Response>;
  /** delete single row from the table: "WUI_PackageRequest" */
  delete_WUI_PackageRequest_by_pk?: Maybe<Wui_PackageRequest>;
  /** delete data from the table: "WUI_Payment" */
  delete_WUI_Payment?: Maybe<Wui_Payment_Mutation_Response>;
  /** delete data from the table: "WUI_PaymentMethod" */
  delete_WUI_PaymentMethod?: Maybe<Wui_PaymentMethod_Mutation_Response>;
  /** delete data from the table: "WUI_PaymentMethodBankAccount" */
  delete_WUI_PaymentMethodBankAccount?: Maybe<Wui_PaymentMethodBankAccount_Mutation_Response>;
  /** delete single row from the table: "WUI_PaymentMethodBankAccount" */
  delete_WUI_PaymentMethodBankAccount_by_pk?: Maybe<Wui_PaymentMethodBankAccount>;
  /** delete data from the table: "WUI_PaymentMethodCard" */
  delete_WUI_PaymentMethodCard?: Maybe<Wui_PaymentMethodCard_Mutation_Response>;
  /** delete single row from the table: "WUI_PaymentMethodCard" */
  delete_WUI_PaymentMethodCard_by_pk?: Maybe<Wui_PaymentMethodCard>;
  /** delete data from the table: "WUI_PaymentMethodSource" */
  delete_WUI_PaymentMethodSource?: Maybe<Wui_PaymentMethodSource_Mutation_Response>;
  /** delete single row from the table: "WUI_PaymentMethodSource" */
  delete_WUI_PaymentMethodSource_by_pk?: Maybe<Wui_PaymentMethodSource>;
  /** delete single row from the table: "WUI_PaymentMethod" */
  delete_WUI_PaymentMethod_by_pk?: Maybe<Wui_PaymentMethod>;
  /** delete data from the table: "WUI_PaymentStatus" */
  delete_WUI_PaymentStatus?: Maybe<Wui_PaymentStatus_Mutation_Response>;
  /** delete single row from the table: "WUI_PaymentStatus" */
  delete_WUI_PaymentStatus_by_pk?: Maybe<Wui_PaymentStatus>;
  /** delete single row from the table: "WUI_Payment" */
  delete_WUI_Payment_by_pk?: Maybe<Wui_Payment>;
  /** delete data from the table: "WUI_RequestStatus" */
  delete_WUI_RequestStatus?: Maybe<Wui_RequestStatus_Mutation_Response>;
  /** delete single row from the table: "WUI_RequestStatus" */
  delete_WUI_RequestStatus_by_pk?: Maybe<Wui_RequestStatus>;
  /** delete data from the table: "WUI_Role" */
  delete_WUI_Role?: Maybe<Wui_Role_Mutation_Response>;
  /** delete single row from the table: "WUI_Role" */
  delete_WUI_Role_by_pk?: Maybe<Wui_Role>;
  /** delete data from the table: "WUI_SellerPackage" */
  delete_WUI_SellerPackage?: Maybe<Wui_SellerPackage_Mutation_Response>;
  /** delete data from the table: "WUI_SellerPackageItem" */
  delete_WUI_SellerPackageItem?: Maybe<Wui_SellerPackageItem_Mutation_Response>;
  /** delete single row from the table: "WUI_SellerPackageItem" */
  delete_WUI_SellerPackageItem_by_pk?: Maybe<Wui_SellerPackageItem>;
  /** delete data from the table: "WUI_SellerPackageType" */
  delete_WUI_SellerPackageType?: Maybe<Wui_SellerPackageType_Mutation_Response>;
  /** delete single row from the table: "WUI_SellerPackageType" */
  delete_WUI_SellerPackageType_by_pk?: Maybe<Wui_SellerPackageType>;
  /** delete single row from the table: "WUI_SellerPackage" */
  delete_WUI_SellerPackage_by_pk?: Maybe<Wui_SellerPackage>;
  /** delete data from the table: "WUI_SellerProject" */
  delete_WUI_SellerProject?: Maybe<Wui_SellerProject_Mutation_Response>;
  /** delete data from the table: "WUI_SellerProjectFile" */
  delete_WUI_SellerProjectFile?: Maybe<Wui_SellerProjectFile_Mutation_Response>;
  /** delete single row from the table: "WUI_SellerProjectFile" */
  delete_WUI_SellerProjectFile_by_pk?: Maybe<Wui_SellerProjectFile>;
  /** delete data from the table: "WUI_SellerProjectItem" */
  delete_WUI_SellerProjectItem?: Maybe<Wui_SellerProjectItem_Mutation_Response>;
  /** delete single row from the table: "WUI_SellerProjectItem" */
  delete_WUI_SellerProjectItem_by_pk?: Maybe<Wui_SellerProjectItem>;
  /** delete single row from the table: "WUI_SellerProject" */
  delete_WUI_SellerProject_by_pk?: Maybe<Wui_SellerProject>;
  /** delete data from the table: "WUI_Session" */
  delete_WUI_Session?: Maybe<Wui_Session_Mutation_Response>;
  /** delete data from the table: "WUI_SessionEvent" */
  delete_WUI_SessionEvent?: Maybe<Wui_SessionEvent_Mutation_Response>;
  /** delete data from the table: "WUI_SessionEventType" */
  delete_WUI_SessionEventType?: Maybe<Wui_SessionEventType_Mutation_Response>;
  /** delete single row from the table: "WUI_SessionEventType" */
  delete_WUI_SessionEventType_by_pk?: Maybe<Wui_SessionEventType>;
  /** delete single row from the table: "WUI_SessionEvent" */
  delete_WUI_SessionEvent_by_pk?: Maybe<Wui_SessionEvent>;
  /** delete single row from the table: "WUI_Session" */
  delete_WUI_Session_by_pk?: Maybe<Wui_Session>;
  /** delete data from the table: "WUI_SocialMediaSource" */
  delete_WUI_SocialMediaSource?: Maybe<Wui_SocialMediaSource_Mutation_Response>;
  /** delete single row from the table: "WUI_SocialMediaSource" */
  delete_WUI_SocialMediaSource_by_pk?: Maybe<Wui_SocialMediaSource>;
  /** delete data from the table: "WUI_SubscriptionPlan" */
  delete_WUI_SubscriptionPlan?: Maybe<Wui_SubscriptionPlan_Mutation_Response>;
  /** delete single row from the table: "WUI_SubscriptionPlan" */
  delete_WUI_SubscriptionPlan_by_pk?: Maybe<Wui_SubscriptionPlan>;
  /** delete data from the table: "WUI_User" */
  delete_WUI_User?: Maybe<Wui_User_Mutation_Response>;
  /** delete data from the table: "WUI_UserFilePortfolio" */
  delete_WUI_UserFilePortfolio?: Maybe<Wui_UserFilePortfolio_Mutation_Response>;
  /** delete single row from the table: "WUI_UserFilePortfolio" */
  delete_WUI_UserFilePortfolio_by_pk?: Maybe<Wui_UserFilePortfolio>;
  /** delete data from the table: "WUI_UserReview" */
  delete_WUI_UserReview?: Maybe<Wui_UserReview_Mutation_Response>;
  /** delete single row from the table: "WUI_UserReview" */
  delete_WUI_UserReview_by_pk?: Maybe<Wui_UserReview>;
  /** delete data from the table: "WUI_UserSubscription" */
  delete_WUI_UserSubscription?: Maybe<Wui_UserSubscription_Mutation_Response>;
  /** delete single row from the table: "WUI_UserSubscription" */
  delete_WUI_UserSubscription_by_pk?: Maybe<Wui_UserSubscription>;
  /** delete data from the table: "WUI_UserType" */
  delete_WUI_UserType?: Maybe<Wui_UserType_Mutation_Response>;
  /** delete single row from the table: "WUI_UserType" */
  delete_WUI_UserType_by_pk?: Maybe<Wui_UserType>;
  /** delete single row from the table: "WUI_User" */
  delete_WUI_User_by_pk?: Maybe<Wui_User>;
  /** delete data from the table: "WUI_VerificationToken" */
  delete_WUI_VerificationToken?: Maybe<Wui_VerificationToken_Mutation_Response>;
  /** delete single row from the table: "WUI_VerificationToken" */
  delete_WUI_VerificationToken_by_pk?: Maybe<Wui_VerificationToken>;
  /** delete data from the table: "unclaimed_demographics" */
  delete_unclaimed_demographics?: Maybe<Unclaimed_Demographics_Mutation_Response>;
  /** delete single row from the table: "unclaimed_demographics" */
  delete_unclaimed_demographics_by_pk?: Maybe<Unclaimed_Demographics>;
  /** delete data from the table: "unclaimed_profile" */
  delete_unclaimed_profile?: Maybe<Unclaimed_Profile_Mutation_Response>;
  /** delete single row from the table: "unclaimed_profile" */
  delete_unclaimed_profile_by_pk?: Maybe<Unclaimed_Profile>;
  /** delete data from the table: "wui_userlastseen" */
  delete_wui_userlastseen?: Maybe<Wui_Userlastseen_Mutation_Response>;
  /** insert data into the table: "Ambassador" */
  insert_Ambassador?: Maybe<Ambassador_Mutation_Response>;
  /** insert a single row into the table: "Ambassador" */
  insert_Ambassador_one?: Maybe<Ambassador>;
  /** insert data into the table: "Demographic" */
  insert_Demographic?: Maybe<Demographic_Mutation_Response>;
  /** insert a single row into the table: "Demographic" */
  insert_Demographic_one?: Maybe<Demographic>;
  /** insert data into the table: "EmailDomainLookup" */
  insert_EmailDomainLookup?: Maybe<EmailDomainLookup_Mutation_Response>;
  /** insert a single row into the table: "EmailDomainLookup" */
  insert_EmailDomainLookup_one?: Maybe<EmailDomainLookup>;
  /** insert data into the table: "HeroBanner" */
  insert_HeroBanner?: Maybe<HeroBanner_Mutation_Response>;
  /** insert a single row into the table: "HeroBanner" */
  insert_HeroBanner_one?: Maybe<HeroBanner>;
  /** insert data into the table: "Industry" */
  insert_Industry?: Maybe<Industry_Mutation_Response>;
  /** insert a single row into the table: "Industry" */
  insert_Industry_one?: Maybe<Industry>;
  /** insert data into the table: "MarketplaceWaitlist" */
  insert_MarketplaceWaitlist?: Maybe<MarketplaceWaitlist_Mutation_Response>;
  /** insert a single row into the table: "MarketplaceWaitlist" */
  insert_MarketplaceWaitlist_one?: Maybe<MarketplaceWaitlist>;
  /** insert data into the table: "PlatformAlert" */
  insert_PlatformAlert?: Maybe<PlatformAlert_Mutation_Response>;
  /** insert a single row into the table: "PlatformAlert" */
  insert_PlatformAlert_one?: Maybe<PlatformAlert>;
  /** insert data into the table: "ProjectDemographic" */
  insert_ProjectDemographic?: Maybe<ProjectDemographic_Mutation_Response>;
  /** insert a single row into the table: "ProjectDemographic" */
  insert_ProjectDemographic_one?: Maybe<ProjectDemographic>;
  /** insert data into the table: "SignupSource" */
  insert_SignupSource?: Maybe<SignupSource_Mutation_Response>;
  /** insert data into the table: "SignupSourceTracker" */
  insert_SignupSourceTracker?: Maybe<SignupSourceTracker_Mutation_Response>;
  /** insert a single row into the table: "SignupSourceTracker" */
  insert_SignupSourceTracker_one?: Maybe<SignupSourceTracker>;
  /** insert a single row into the table: "SignupSource" */
  insert_SignupSource_one?: Maybe<SignupSource>;
  /** insert data into the table: "SocialMedia" */
  insert_SocialMedia?: Maybe<SocialMedia_Mutation_Response>;
  /** insert data into the table: "SocialMediaDemographic" */
  insert_SocialMediaDemographic?: Maybe<SocialMediaDemographic_Mutation_Response>;
  /** insert a single row into the table: "SocialMediaDemographic" */
  insert_SocialMediaDemographic_one?: Maybe<SocialMediaDemographic>;
  /** insert data into the table: "SocialMediaHistory" */
  insert_SocialMediaHistory?: Maybe<SocialMediaHistory_Mutation_Response>;
  /** insert a single row into the table: "SocialMediaHistory" */
  insert_SocialMediaHistory_one?: Maybe<SocialMediaHistory>;
  /** insert data into the table: "SocialMediaLink" */
  insert_SocialMediaLink?: Maybe<SocialMediaLink_Mutation_Response>;
  /** insert a single row into the table: "SocialMediaLink" */
  insert_SocialMediaLink_one?: Maybe<SocialMediaLink>;
  /** insert data into the table: "SocialMediaPlatform" */
  insert_SocialMediaPlatform?: Maybe<SocialMediaPlatform_Mutation_Response>;
  /** insert a single row into the table: "SocialMediaPlatform" */
  insert_SocialMediaPlatform_one?: Maybe<SocialMediaPlatform>;
  /** insert a single row into the table: "SocialMedia" */
  insert_SocialMedia_one?: Maybe<SocialMedia>;
  /** insert data into the table: "TempLoginWithPassword" */
  insert_TempLoginWithPassword?: Maybe<TempLoginWithPassword_Mutation_Response>;
  /** insert a single row into the table: "TempLoginWithPassword" */
  insert_TempLoginWithPassword_one?: Maybe<TempLoginWithPassword>;
  /** insert data into the table: "UserVideo" */
  insert_UserVideo?: Maybe<UserVideo_Mutation_Response>;
  /** insert a single row into the table: "UserVideo" */
  insert_UserVideo_one?: Maybe<UserVideo>;
  /** insert data into the table: "Vote" */
  insert_Vote?: Maybe<Vote_Mutation_Response>;
  /** insert data into the table: "VoteCategory" */
  insert_VoteCategory?: Maybe<VoteCategory_Mutation_Response>;
  /** insert a single row into the table: "VoteCategory" */
  insert_VoteCategory_one?: Maybe<VoteCategory>;
  /** insert data into the table: "VoteUser" */
  insert_VoteUser?: Maybe<VoteUser_Mutation_Response>;
  /** insert data into the table: "VoteUserCategory" */
  insert_VoteUserCategory?: Maybe<VoteUserCategory_Mutation_Response>;
  /** insert a single row into the table: "VoteUserCategory" */
  insert_VoteUserCategory_one?: Maybe<VoteUserCategory>;
  /** insert a single row into the table: "VoteUser" */
  insert_VoteUser_one?: Maybe<VoteUser>;
  /** insert a single row into the table: "Vote" */
  insert_Vote_one?: Maybe<Vote>;
  /** insert data into the table: "WUI_Account" */
  insert_WUI_Account?: Maybe<Wui_Account_Mutation_Response>;
  /** insert a single row into the table: "WUI_Account" */
  insert_WUI_Account_one?: Maybe<Wui_Account>;
  /** insert data into the table: "WUI_Affiliate" */
  insert_WUI_Affiliate?: Maybe<Wui_Affiliate_Mutation_Response>;
  /** insert data into the table: "WUI_AffiliateLevel" */
  insert_WUI_AffiliateLevel?: Maybe<Wui_AffiliateLevel_Mutation_Response>;
  /** insert a single row into the table: "WUI_AffiliateLevel" */
  insert_WUI_AffiliateLevel_one?: Maybe<Wui_AffiliateLevel>;
  /** insert data into the table: "WUI_AffiliateStatus" */
  insert_WUI_AffiliateStatus?: Maybe<Wui_AffiliateStatus_Mutation_Response>;
  /** insert a single row into the table: "WUI_AffiliateStatus" */
  insert_WUI_AffiliateStatus_one?: Maybe<Wui_AffiliateStatus>;
  /** insert data into the table: "WUI_AffiliateTransaction" */
  insert_WUI_AffiliateTransaction?: Maybe<Wui_AffiliateTransaction_Mutation_Response>;
  /** insert a single row into the table: "WUI_AffiliateTransaction" */
  insert_WUI_AffiliateTransaction_one?: Maybe<Wui_AffiliateTransaction>;
  /** insert data into the table: "WUI_AffiliateUsers" */
  insert_WUI_AffiliateUsers?: Maybe<Wui_AffiliateUsers_Mutation_Response>;
  /** insert a single row into the table: "WUI_AffiliateUsers" */
  insert_WUI_AffiliateUsers_one?: Maybe<Wui_AffiliateUsers>;
  /** insert a single row into the table: "WUI_Affiliate" */
  insert_WUI_Affiliate_one?: Maybe<Wui_Affiliate>;
  /** insert data into the table: "WUI_AuthProvider" */
  insert_WUI_AuthProvider?: Maybe<Wui_AuthProvider_Mutation_Response>;
  /** insert a single row into the table: "WUI_AuthProvider" */
  insert_WUI_AuthProvider_one?: Maybe<Wui_AuthProvider>;
  /** insert data into the table: "WUI_AuthSession" */
  insert_WUI_AuthSession?: Maybe<Wui_AuthSession_Mutation_Response>;
  /** insert a single row into the table: "WUI_AuthSession" */
  insert_WUI_AuthSession_one?: Maybe<Wui_AuthSession>;
  /** insert data into the table: "WUI_ChatRoom" */
  insert_WUI_ChatRoom?: Maybe<Wui_ChatRoom_Mutation_Response>;
  /** insert data into the table: "WUI_ChatRoomMessage" */
  insert_WUI_ChatRoomMessage?: Maybe<Wui_ChatRoomMessage_Mutation_Response>;
  /** insert a single row into the table: "WUI_ChatRoomMessage" */
  insert_WUI_ChatRoomMessage_one?: Maybe<Wui_ChatRoomMessage>;
  /** insert data into the table: "WUI_ChatRoomOrder" */
  insert_WUI_ChatRoomOrder?: Maybe<Wui_ChatRoomOrder_Mutation_Response>;
  /** insert a single row into the table: "WUI_ChatRoomOrder" */
  insert_WUI_ChatRoomOrder_one?: Maybe<Wui_ChatRoomOrder>;
  /** insert data into the table: "WUI_ChatRoomType" */
  insert_WUI_ChatRoomType?: Maybe<Wui_ChatRoomType_Mutation_Response>;
  /** insert a single row into the table: "WUI_ChatRoomType" */
  insert_WUI_ChatRoomType_one?: Maybe<Wui_ChatRoomType>;
  /** insert data into the table: "WUI_ChatRoomUser" */
  insert_WUI_ChatRoomUser?: Maybe<Wui_ChatRoomUser_Mutation_Response>;
  /** insert a single row into the table: "WUI_ChatRoomUser" */
  insert_WUI_ChatRoomUser_one?: Maybe<Wui_ChatRoomUser>;
  /** insert a single row into the table: "WUI_ChatRoom" */
  insert_WUI_ChatRoom_one?: Maybe<Wui_ChatRoom>;
  /** insert data into the table: "WUI_File" */
  insert_WUI_File?: Maybe<Wui_File_Mutation_Response>;
  /** insert data into the table: "WUI_FileType" */
  insert_WUI_FileType?: Maybe<Wui_FileType_Mutation_Response>;
  /** insert a single row into the table: "WUI_FileType" */
  insert_WUI_FileType_one?: Maybe<Wui_FileType>;
  /** insert a single row into the table: "WUI_File" */
  insert_WUI_File_one?: Maybe<Wui_File>;
  /** insert data into the table: "WUI_Notification" */
  insert_WUI_Notification?: Maybe<Wui_Notification_Mutation_Response>;
  /** insert a single row into the table: "WUI_Notification" */
  insert_WUI_Notification_one?: Maybe<Wui_Notification>;
  /** insert data into the table: "WUI_Order" */
  insert_WUI_Order?: Maybe<Wui_Order_Mutation_Response>;
  /** insert data into the table: "WUI_OrderReview" */
  insert_WUI_OrderReview?: Maybe<Wui_OrderReview_Mutation_Response>;
  /** insert a single row into the table: "WUI_OrderReview" */
  insert_WUI_OrderReview_one?: Maybe<Wui_OrderReview>;
  /** insert data into the table: "WUI_OrderStatus" */
  insert_WUI_OrderStatus?: Maybe<Wui_OrderStatus_Mutation_Response>;
  /** insert a single row into the table: "WUI_OrderStatus" */
  insert_WUI_OrderStatus_one?: Maybe<Wui_OrderStatus>;
  /** insert data into the table: "WUI_OrderSubscription" */
  insert_WUI_OrderSubscription?: Maybe<Wui_OrderSubscription_Mutation_Response>;
  /** insert data into the table: "WUI_OrderSubscriptionPeriod" */
  insert_WUI_OrderSubscriptionPeriod?: Maybe<Wui_OrderSubscriptionPeriod_Mutation_Response>;
  /** insert a single row into the table: "WUI_OrderSubscriptionPeriod" */
  insert_WUI_OrderSubscriptionPeriod_one?: Maybe<Wui_OrderSubscriptionPeriod>;
  /** insert data into the table: "WUI_OrderSubscriptionStatus" */
  insert_WUI_OrderSubscriptionStatus?: Maybe<Wui_OrderSubscriptionStatus_Mutation_Response>;
  /** insert a single row into the table: "WUI_OrderSubscriptionStatus" */
  insert_WUI_OrderSubscriptionStatus_one?: Maybe<Wui_OrderSubscriptionStatus>;
  /** insert a single row into the table: "WUI_OrderSubscription" */
  insert_WUI_OrderSubscription_one?: Maybe<Wui_OrderSubscription>;
  /** insert a single row into the table: "WUI_Order" */
  insert_WUI_Order_one?: Maybe<Wui_Order>;
  /** insert data into the table: "WUI_PackageRequest" */
  insert_WUI_PackageRequest?: Maybe<Wui_PackageRequest_Mutation_Response>;
  /** insert a single row into the table: "WUI_PackageRequest" */
  insert_WUI_PackageRequest_one?: Maybe<Wui_PackageRequest>;
  /** insert data into the table: "WUI_Payment" */
  insert_WUI_Payment?: Maybe<Wui_Payment_Mutation_Response>;
  /** insert data into the table: "WUI_PaymentMethod" */
  insert_WUI_PaymentMethod?: Maybe<Wui_PaymentMethod_Mutation_Response>;
  /** insert data into the table: "WUI_PaymentMethodBankAccount" */
  insert_WUI_PaymentMethodBankAccount?: Maybe<Wui_PaymentMethodBankAccount_Mutation_Response>;
  /** insert a single row into the table: "WUI_PaymentMethodBankAccount" */
  insert_WUI_PaymentMethodBankAccount_one?: Maybe<Wui_PaymentMethodBankAccount>;
  /** insert data into the table: "WUI_PaymentMethodCard" */
  insert_WUI_PaymentMethodCard?: Maybe<Wui_PaymentMethodCard_Mutation_Response>;
  /** insert a single row into the table: "WUI_PaymentMethodCard" */
  insert_WUI_PaymentMethodCard_one?: Maybe<Wui_PaymentMethodCard>;
  /** insert data into the table: "WUI_PaymentMethodSource" */
  insert_WUI_PaymentMethodSource?: Maybe<Wui_PaymentMethodSource_Mutation_Response>;
  /** insert a single row into the table: "WUI_PaymentMethodSource" */
  insert_WUI_PaymentMethodSource_one?: Maybe<Wui_PaymentMethodSource>;
  /** insert a single row into the table: "WUI_PaymentMethod" */
  insert_WUI_PaymentMethod_one?: Maybe<Wui_PaymentMethod>;
  /** insert data into the table: "WUI_PaymentStatus" */
  insert_WUI_PaymentStatus?: Maybe<Wui_PaymentStatus_Mutation_Response>;
  /** insert a single row into the table: "WUI_PaymentStatus" */
  insert_WUI_PaymentStatus_one?: Maybe<Wui_PaymentStatus>;
  /** insert a single row into the table: "WUI_Payment" */
  insert_WUI_Payment_one?: Maybe<Wui_Payment>;
  /** insert data into the table: "WUI_RequestStatus" */
  insert_WUI_RequestStatus?: Maybe<Wui_RequestStatus_Mutation_Response>;
  /** insert a single row into the table: "WUI_RequestStatus" */
  insert_WUI_RequestStatus_one?: Maybe<Wui_RequestStatus>;
  /** insert data into the table: "WUI_Role" */
  insert_WUI_Role?: Maybe<Wui_Role_Mutation_Response>;
  /** insert a single row into the table: "WUI_Role" */
  insert_WUI_Role_one?: Maybe<Wui_Role>;
  /** insert data into the table: "WUI_SellerPackage" */
  insert_WUI_SellerPackage?: Maybe<Wui_SellerPackage_Mutation_Response>;
  /** insert data into the table: "WUI_SellerPackageItem" */
  insert_WUI_SellerPackageItem?: Maybe<Wui_SellerPackageItem_Mutation_Response>;
  /** insert a single row into the table: "WUI_SellerPackageItem" */
  insert_WUI_SellerPackageItem_one?: Maybe<Wui_SellerPackageItem>;
  /** insert data into the table: "WUI_SellerPackageType" */
  insert_WUI_SellerPackageType?: Maybe<Wui_SellerPackageType_Mutation_Response>;
  /** insert a single row into the table: "WUI_SellerPackageType" */
  insert_WUI_SellerPackageType_one?: Maybe<Wui_SellerPackageType>;
  /** insert a single row into the table: "WUI_SellerPackage" */
  insert_WUI_SellerPackage_one?: Maybe<Wui_SellerPackage>;
  /** insert data into the table: "WUI_SellerProject" */
  insert_WUI_SellerProject?: Maybe<Wui_SellerProject_Mutation_Response>;
  /** insert data into the table: "WUI_SellerProjectFile" */
  insert_WUI_SellerProjectFile?: Maybe<Wui_SellerProjectFile_Mutation_Response>;
  /** insert a single row into the table: "WUI_SellerProjectFile" */
  insert_WUI_SellerProjectFile_one?: Maybe<Wui_SellerProjectFile>;
  /** insert data into the table: "WUI_SellerProjectItem" */
  insert_WUI_SellerProjectItem?: Maybe<Wui_SellerProjectItem_Mutation_Response>;
  /** insert a single row into the table: "WUI_SellerProjectItem" */
  insert_WUI_SellerProjectItem_one?: Maybe<Wui_SellerProjectItem>;
  /** insert a single row into the table: "WUI_SellerProject" */
  insert_WUI_SellerProject_one?: Maybe<Wui_SellerProject>;
  /** insert data into the table: "WUI_Session" */
  insert_WUI_Session?: Maybe<Wui_Session_Mutation_Response>;
  /** insert data into the table: "WUI_SessionEvent" */
  insert_WUI_SessionEvent?: Maybe<Wui_SessionEvent_Mutation_Response>;
  /** insert data into the table: "WUI_SessionEventType" */
  insert_WUI_SessionEventType?: Maybe<Wui_SessionEventType_Mutation_Response>;
  /** insert a single row into the table: "WUI_SessionEventType" */
  insert_WUI_SessionEventType_one?: Maybe<Wui_SessionEventType>;
  /** insert a single row into the table: "WUI_SessionEvent" */
  insert_WUI_SessionEvent_one?: Maybe<Wui_SessionEvent>;
  /** insert a single row into the table: "WUI_Session" */
  insert_WUI_Session_one?: Maybe<Wui_Session>;
  /** insert data into the table: "WUI_SocialMediaSource" */
  insert_WUI_SocialMediaSource?: Maybe<Wui_SocialMediaSource_Mutation_Response>;
  /** insert a single row into the table: "WUI_SocialMediaSource" */
  insert_WUI_SocialMediaSource_one?: Maybe<Wui_SocialMediaSource>;
  /** insert data into the table: "WUI_SubscriptionPlan" */
  insert_WUI_SubscriptionPlan?: Maybe<Wui_SubscriptionPlan_Mutation_Response>;
  /** insert a single row into the table: "WUI_SubscriptionPlan" */
  insert_WUI_SubscriptionPlan_one?: Maybe<Wui_SubscriptionPlan>;
  /** insert data into the table: "WUI_User" */
  insert_WUI_User?: Maybe<Wui_User_Mutation_Response>;
  /** insert data into the table: "WUI_UserFilePortfolio" */
  insert_WUI_UserFilePortfolio?: Maybe<Wui_UserFilePortfolio_Mutation_Response>;
  /** insert a single row into the table: "WUI_UserFilePortfolio" */
  insert_WUI_UserFilePortfolio_one?: Maybe<Wui_UserFilePortfolio>;
  /** insert data into the table: "WUI_UserReview" */
  insert_WUI_UserReview?: Maybe<Wui_UserReview_Mutation_Response>;
  /** insert a single row into the table: "WUI_UserReview" */
  insert_WUI_UserReview_one?: Maybe<Wui_UserReview>;
  /** insert data into the table: "WUI_UserSubscription" */
  insert_WUI_UserSubscription?: Maybe<Wui_UserSubscription_Mutation_Response>;
  /** insert a single row into the table: "WUI_UserSubscription" */
  insert_WUI_UserSubscription_one?: Maybe<Wui_UserSubscription>;
  /** insert data into the table: "WUI_UserType" */
  insert_WUI_UserType?: Maybe<Wui_UserType_Mutation_Response>;
  /** insert a single row into the table: "WUI_UserType" */
  insert_WUI_UserType_one?: Maybe<Wui_UserType>;
  /** insert a single row into the table: "WUI_User" */
  insert_WUI_User_one?: Maybe<Wui_User>;
  /** insert data into the table: "WUI_VerificationToken" */
  insert_WUI_VerificationToken?: Maybe<Wui_VerificationToken_Mutation_Response>;
  /** insert a single row into the table: "WUI_VerificationToken" */
  insert_WUI_VerificationToken_one?: Maybe<Wui_VerificationToken>;
  /** insert data into the table: "unclaimed_demographics" */
  insert_unclaimed_demographics?: Maybe<Unclaimed_Demographics_Mutation_Response>;
  /** insert a single row into the table: "unclaimed_demographics" */
  insert_unclaimed_demographics_one?: Maybe<Unclaimed_Demographics>;
  /** insert data into the table: "unclaimed_profile" */
  insert_unclaimed_profile?: Maybe<Unclaimed_Profile_Mutation_Response>;
  /** insert a single row into the table: "unclaimed_profile" */
  insert_unclaimed_profile_one?: Maybe<Unclaimed_Profile>;
  /** insert data into the table: "wui_userlastseen" */
  insert_wui_userlastseen?: Maybe<Wui_Userlastseen_Mutation_Response>;
  /** insert a single row into the table: "wui_userlastseen" */
  insert_wui_userlastseen_one?: Maybe<Wui_Userlastseen>;
  login?: Maybe<LoginOutput>;
  loginAdmin?: Maybe<LoginAdminOutput>;
  removeBankAccount?: Maybe<RemoveBankAccountOutput>;
  remove_WUI_PaymentMethodCard?: Maybe<RemovePaymentMethodCardOutput>;
  resetVotes: ResetVotesOutput;
  signup?: Maybe<SignupOutput>;
  updateStatus_WUI_Order?: Maybe<UpdateOrderOutput>;
  /** update data of the table: "Ambassador" */
  update_Ambassador?: Maybe<Ambassador_Mutation_Response>;
  /** update single row of the table: "Ambassador" */
  update_Ambassador_by_pk?: Maybe<Ambassador>;
  /** update multiples rows of table: "Ambassador" */
  update_Ambassador_many?: Maybe<Array<Maybe<Ambassador_Mutation_Response>>>;
  /** update data of the table: "Demographic" */
  update_Demographic?: Maybe<Demographic_Mutation_Response>;
  /** update single row of the table: "Demographic" */
  update_Demographic_by_pk?: Maybe<Demographic>;
  /** update multiples rows of table: "Demographic" */
  update_Demographic_many?: Maybe<Array<Maybe<Demographic_Mutation_Response>>>;
  /** update data of the table: "EmailDomainLookup" */
  update_EmailDomainLookup?: Maybe<EmailDomainLookup_Mutation_Response>;
  /** update single row of the table: "EmailDomainLookup" */
  update_EmailDomainLookup_by_pk?: Maybe<EmailDomainLookup>;
  /** update multiples rows of table: "EmailDomainLookup" */
  update_EmailDomainLookup_many?: Maybe<Array<Maybe<EmailDomainLookup_Mutation_Response>>>;
  /** update data of the table: "HeroBanner" */
  update_HeroBanner?: Maybe<HeroBanner_Mutation_Response>;
  /** update single row of the table: "HeroBanner" */
  update_HeroBanner_by_pk?: Maybe<HeroBanner>;
  /** update multiples rows of table: "HeroBanner" */
  update_HeroBanner_many?: Maybe<Array<Maybe<HeroBanner_Mutation_Response>>>;
  /** update data of the table: "Industry" */
  update_Industry?: Maybe<Industry_Mutation_Response>;
  /** update single row of the table: "Industry" */
  update_Industry_by_pk?: Maybe<Industry>;
  /** update multiples rows of table: "Industry" */
  update_Industry_many?: Maybe<Array<Maybe<Industry_Mutation_Response>>>;
  /** update data of the table: "MarketplaceWaitlist" */
  update_MarketplaceWaitlist?: Maybe<MarketplaceWaitlist_Mutation_Response>;
  /** update single row of the table: "MarketplaceWaitlist" */
  update_MarketplaceWaitlist_by_pk?: Maybe<MarketplaceWaitlist>;
  /** update multiples rows of table: "MarketplaceWaitlist" */
  update_MarketplaceWaitlist_many?: Maybe<Array<Maybe<MarketplaceWaitlist_Mutation_Response>>>;
  /** update data of the table: "PlatformAlert" */
  update_PlatformAlert?: Maybe<PlatformAlert_Mutation_Response>;
  /** update single row of the table: "PlatformAlert" */
  update_PlatformAlert_by_pk?: Maybe<PlatformAlert>;
  /** update multiples rows of table: "PlatformAlert" */
  update_PlatformAlert_many?: Maybe<Array<Maybe<PlatformAlert_Mutation_Response>>>;
  /** update data of the table: "ProjectDemographic" */
  update_ProjectDemographic?: Maybe<ProjectDemographic_Mutation_Response>;
  /** update single row of the table: "ProjectDemographic" */
  update_ProjectDemographic_by_pk?: Maybe<ProjectDemographic>;
  /** update multiples rows of table: "ProjectDemographic" */
  update_ProjectDemographic_many?: Maybe<Array<Maybe<ProjectDemographic_Mutation_Response>>>;
  /** update data of the table: "SignupSource" */
  update_SignupSource?: Maybe<SignupSource_Mutation_Response>;
  /** update data of the table: "SignupSourceTracker" */
  update_SignupSourceTracker?: Maybe<SignupSourceTracker_Mutation_Response>;
  /** update single row of the table: "SignupSourceTracker" */
  update_SignupSourceTracker_by_pk?: Maybe<SignupSourceTracker>;
  /** update multiples rows of table: "SignupSourceTracker" */
  update_SignupSourceTracker_many?: Maybe<Array<Maybe<SignupSourceTracker_Mutation_Response>>>;
  /** update single row of the table: "SignupSource" */
  update_SignupSource_by_pk?: Maybe<SignupSource>;
  /** update multiples rows of table: "SignupSource" */
  update_SignupSource_many?: Maybe<Array<Maybe<SignupSource_Mutation_Response>>>;
  /** update data of the table: "SocialMedia" */
  update_SocialMedia?: Maybe<SocialMedia_Mutation_Response>;
  /** update data of the table: "SocialMediaDemographic" */
  update_SocialMediaDemographic?: Maybe<SocialMediaDemographic_Mutation_Response>;
  /** update single row of the table: "SocialMediaDemographic" */
  update_SocialMediaDemographic_by_pk?: Maybe<SocialMediaDemographic>;
  /** update multiples rows of table: "SocialMediaDemographic" */
  update_SocialMediaDemographic_many?: Maybe<Array<Maybe<SocialMediaDemographic_Mutation_Response>>>;
  /** update data of the table: "SocialMediaHistory" */
  update_SocialMediaHistory?: Maybe<SocialMediaHistory_Mutation_Response>;
  /** update single row of the table: "SocialMediaHistory" */
  update_SocialMediaHistory_by_pk?: Maybe<SocialMediaHistory>;
  /** update multiples rows of table: "SocialMediaHistory" */
  update_SocialMediaHistory_many?: Maybe<Array<Maybe<SocialMediaHistory_Mutation_Response>>>;
  /** update data of the table: "SocialMediaLink" */
  update_SocialMediaLink?: Maybe<SocialMediaLink_Mutation_Response>;
  /** update single row of the table: "SocialMediaLink" */
  update_SocialMediaLink_by_pk?: Maybe<SocialMediaLink>;
  /** update multiples rows of table: "SocialMediaLink" */
  update_SocialMediaLink_many?: Maybe<Array<Maybe<SocialMediaLink_Mutation_Response>>>;
  /** update data of the table: "SocialMediaPlatform" */
  update_SocialMediaPlatform?: Maybe<SocialMediaPlatform_Mutation_Response>;
  /** update single row of the table: "SocialMediaPlatform" */
  update_SocialMediaPlatform_by_pk?: Maybe<SocialMediaPlatform>;
  /** update multiples rows of table: "SocialMediaPlatform" */
  update_SocialMediaPlatform_many?: Maybe<Array<Maybe<SocialMediaPlatform_Mutation_Response>>>;
  /** update single row of the table: "SocialMedia" */
  update_SocialMedia_by_pk?: Maybe<SocialMedia>;
  /** update multiples rows of table: "SocialMedia" */
  update_SocialMedia_many?: Maybe<Array<Maybe<SocialMedia_Mutation_Response>>>;
  /** update data of the table: "TempLoginWithPassword" */
  update_TempLoginWithPassword?: Maybe<TempLoginWithPassword_Mutation_Response>;
  /** update single row of the table: "TempLoginWithPassword" */
  update_TempLoginWithPassword_by_pk?: Maybe<TempLoginWithPassword>;
  /** update multiples rows of table: "TempLoginWithPassword" */
  update_TempLoginWithPassword_many?: Maybe<Array<Maybe<TempLoginWithPassword_Mutation_Response>>>;
  /** update data of the table: "UserVideo" */
  update_UserVideo?: Maybe<UserVideo_Mutation_Response>;
  /** update single row of the table: "UserVideo" */
  update_UserVideo_by_pk?: Maybe<UserVideo>;
  /** update multiples rows of table: "UserVideo" */
  update_UserVideo_many?: Maybe<Array<Maybe<UserVideo_Mutation_Response>>>;
  /** update data of the table: "Vote" */
  update_Vote?: Maybe<Vote_Mutation_Response>;
  /** update data of the table: "VoteCategory" */
  update_VoteCategory?: Maybe<VoteCategory_Mutation_Response>;
  /** update single row of the table: "VoteCategory" */
  update_VoteCategory_by_pk?: Maybe<VoteCategory>;
  /** update multiples rows of table: "VoteCategory" */
  update_VoteCategory_many?: Maybe<Array<Maybe<VoteCategory_Mutation_Response>>>;
  /** update data of the table: "VoteUser" */
  update_VoteUser?: Maybe<VoteUser_Mutation_Response>;
  /** update data of the table: "VoteUserCategory" */
  update_VoteUserCategory?: Maybe<VoteUserCategory_Mutation_Response>;
  /** update single row of the table: "VoteUserCategory" */
  update_VoteUserCategory_by_pk?: Maybe<VoteUserCategory>;
  /** update multiples rows of table: "VoteUserCategory" */
  update_VoteUserCategory_many?: Maybe<Array<Maybe<VoteUserCategory_Mutation_Response>>>;
  /** update single row of the table: "VoteUser" */
  update_VoteUser_by_pk?: Maybe<VoteUser>;
  /** update multiples rows of table: "VoteUser" */
  update_VoteUser_many?: Maybe<Array<Maybe<VoteUser_Mutation_Response>>>;
  /** update single row of the table: "Vote" */
  update_Vote_by_pk?: Maybe<Vote>;
  /** update multiples rows of table: "Vote" */
  update_Vote_many?: Maybe<Array<Maybe<Vote_Mutation_Response>>>;
  /** update data of the table: "WUI_Account" */
  update_WUI_Account?: Maybe<Wui_Account_Mutation_Response>;
  /** update single row of the table: "WUI_Account" */
  update_WUI_Account_by_pk?: Maybe<Wui_Account>;
  /** update multiples rows of table: "WUI_Account" */
  update_WUI_Account_many?: Maybe<Array<Maybe<Wui_Account_Mutation_Response>>>;
  /** update data of the table: "WUI_Affiliate" */
  update_WUI_Affiliate?: Maybe<Wui_Affiliate_Mutation_Response>;
  /** update data of the table: "WUI_AffiliateLevel" */
  update_WUI_AffiliateLevel?: Maybe<Wui_AffiliateLevel_Mutation_Response>;
  /** update single row of the table: "WUI_AffiliateLevel" */
  update_WUI_AffiliateLevel_by_pk?: Maybe<Wui_AffiliateLevel>;
  /** update multiples rows of table: "WUI_AffiliateLevel" */
  update_WUI_AffiliateLevel_many?: Maybe<Array<Maybe<Wui_AffiliateLevel_Mutation_Response>>>;
  /** update data of the table: "WUI_AffiliateStatus" */
  update_WUI_AffiliateStatus?: Maybe<Wui_AffiliateStatus_Mutation_Response>;
  /** update single row of the table: "WUI_AffiliateStatus" */
  update_WUI_AffiliateStatus_by_pk?: Maybe<Wui_AffiliateStatus>;
  /** update multiples rows of table: "WUI_AffiliateStatus" */
  update_WUI_AffiliateStatus_many?: Maybe<Array<Maybe<Wui_AffiliateStatus_Mutation_Response>>>;
  /** update data of the table: "WUI_AffiliateTransaction" */
  update_WUI_AffiliateTransaction?: Maybe<Wui_AffiliateTransaction_Mutation_Response>;
  /** update single row of the table: "WUI_AffiliateTransaction" */
  update_WUI_AffiliateTransaction_by_pk?: Maybe<Wui_AffiliateTransaction>;
  /** update multiples rows of table: "WUI_AffiliateTransaction" */
  update_WUI_AffiliateTransaction_many?: Maybe<Array<Maybe<Wui_AffiliateTransaction_Mutation_Response>>>;
  /** update data of the table: "WUI_AffiliateUsers" */
  update_WUI_AffiliateUsers?: Maybe<Wui_AffiliateUsers_Mutation_Response>;
  /** update single row of the table: "WUI_AffiliateUsers" */
  update_WUI_AffiliateUsers_by_pk?: Maybe<Wui_AffiliateUsers>;
  /** update multiples rows of table: "WUI_AffiliateUsers" */
  update_WUI_AffiliateUsers_many?: Maybe<Array<Maybe<Wui_AffiliateUsers_Mutation_Response>>>;
  /** update single row of the table: "WUI_Affiliate" */
  update_WUI_Affiliate_by_pk?: Maybe<Wui_Affiliate>;
  /** update multiples rows of table: "WUI_Affiliate" */
  update_WUI_Affiliate_many?: Maybe<Array<Maybe<Wui_Affiliate_Mutation_Response>>>;
  /** update data of the table: "WUI_AuthProvider" */
  update_WUI_AuthProvider?: Maybe<Wui_AuthProvider_Mutation_Response>;
  /** update single row of the table: "WUI_AuthProvider" */
  update_WUI_AuthProvider_by_pk?: Maybe<Wui_AuthProvider>;
  /** update multiples rows of table: "WUI_AuthProvider" */
  update_WUI_AuthProvider_many?: Maybe<Array<Maybe<Wui_AuthProvider_Mutation_Response>>>;
  /** update data of the table: "WUI_AuthSession" */
  update_WUI_AuthSession?: Maybe<Wui_AuthSession_Mutation_Response>;
  /** update single row of the table: "WUI_AuthSession" */
  update_WUI_AuthSession_by_pk?: Maybe<Wui_AuthSession>;
  /** update multiples rows of table: "WUI_AuthSession" */
  update_WUI_AuthSession_many?: Maybe<Array<Maybe<Wui_AuthSession_Mutation_Response>>>;
  /** update data of the table: "WUI_ChatRoom" */
  update_WUI_ChatRoom?: Maybe<Wui_ChatRoom_Mutation_Response>;
  /** update data of the table: "WUI_ChatRoomMessage" */
  update_WUI_ChatRoomMessage?: Maybe<Wui_ChatRoomMessage_Mutation_Response>;
  /** update single row of the table: "WUI_ChatRoomMessage" */
  update_WUI_ChatRoomMessage_by_pk?: Maybe<Wui_ChatRoomMessage>;
  /** update multiples rows of table: "WUI_ChatRoomMessage" */
  update_WUI_ChatRoomMessage_many?: Maybe<Array<Maybe<Wui_ChatRoomMessage_Mutation_Response>>>;
  /** update data of the table: "WUI_ChatRoomOrder" */
  update_WUI_ChatRoomOrder?: Maybe<Wui_ChatRoomOrder_Mutation_Response>;
  /** update single row of the table: "WUI_ChatRoomOrder" */
  update_WUI_ChatRoomOrder_by_pk?: Maybe<Wui_ChatRoomOrder>;
  /** update multiples rows of table: "WUI_ChatRoomOrder" */
  update_WUI_ChatRoomOrder_many?: Maybe<Array<Maybe<Wui_ChatRoomOrder_Mutation_Response>>>;
  /** update data of the table: "WUI_ChatRoomType" */
  update_WUI_ChatRoomType?: Maybe<Wui_ChatRoomType_Mutation_Response>;
  /** update single row of the table: "WUI_ChatRoomType" */
  update_WUI_ChatRoomType_by_pk?: Maybe<Wui_ChatRoomType>;
  /** update multiples rows of table: "WUI_ChatRoomType" */
  update_WUI_ChatRoomType_many?: Maybe<Array<Maybe<Wui_ChatRoomType_Mutation_Response>>>;
  /** update data of the table: "WUI_ChatRoomUser" */
  update_WUI_ChatRoomUser?: Maybe<Wui_ChatRoomUser_Mutation_Response>;
  /** update single row of the table: "WUI_ChatRoomUser" */
  update_WUI_ChatRoomUser_by_pk?: Maybe<Wui_ChatRoomUser>;
  /** update multiples rows of table: "WUI_ChatRoomUser" */
  update_WUI_ChatRoomUser_many?: Maybe<Array<Maybe<Wui_ChatRoomUser_Mutation_Response>>>;
  /** update single row of the table: "WUI_ChatRoom" */
  update_WUI_ChatRoom_by_pk?: Maybe<Wui_ChatRoom>;
  /** update multiples rows of table: "WUI_ChatRoom" */
  update_WUI_ChatRoom_many?: Maybe<Array<Maybe<Wui_ChatRoom_Mutation_Response>>>;
  /** update data of the table: "WUI_File" */
  update_WUI_File?: Maybe<Wui_File_Mutation_Response>;
  /** update data of the table: "WUI_FileType" */
  update_WUI_FileType?: Maybe<Wui_FileType_Mutation_Response>;
  /** update single row of the table: "WUI_FileType" */
  update_WUI_FileType_by_pk?: Maybe<Wui_FileType>;
  /** update multiples rows of table: "WUI_FileType" */
  update_WUI_FileType_many?: Maybe<Array<Maybe<Wui_FileType_Mutation_Response>>>;
  /** update single row of the table: "WUI_File" */
  update_WUI_File_by_pk?: Maybe<Wui_File>;
  /** update multiples rows of table: "WUI_File" */
  update_WUI_File_many?: Maybe<Array<Maybe<Wui_File_Mutation_Response>>>;
  /** update data of the table: "WUI_Notification" */
  update_WUI_Notification?: Maybe<Wui_Notification_Mutation_Response>;
  /** update single row of the table: "WUI_Notification" */
  update_WUI_Notification_by_pk?: Maybe<Wui_Notification>;
  /** update multiples rows of table: "WUI_Notification" */
  update_WUI_Notification_many?: Maybe<Array<Maybe<Wui_Notification_Mutation_Response>>>;
  /** update data of the table: "WUI_Order" */
  update_WUI_Order?: Maybe<Wui_Order_Mutation_Response>;
  /** update data of the table: "WUI_OrderReview" */
  update_WUI_OrderReview?: Maybe<Wui_OrderReview_Mutation_Response>;
  /** update single row of the table: "WUI_OrderReview" */
  update_WUI_OrderReview_by_pk?: Maybe<Wui_OrderReview>;
  /** update multiples rows of table: "WUI_OrderReview" */
  update_WUI_OrderReview_many?: Maybe<Array<Maybe<Wui_OrderReview_Mutation_Response>>>;
  /** update data of the table: "WUI_OrderStatus" */
  update_WUI_OrderStatus?: Maybe<Wui_OrderStatus_Mutation_Response>;
  /** update single row of the table: "WUI_OrderStatus" */
  update_WUI_OrderStatus_by_pk?: Maybe<Wui_OrderStatus>;
  /** update multiples rows of table: "WUI_OrderStatus" */
  update_WUI_OrderStatus_many?: Maybe<Array<Maybe<Wui_OrderStatus_Mutation_Response>>>;
  /** update data of the table: "WUI_OrderSubscription" */
  update_WUI_OrderSubscription?: Maybe<Wui_OrderSubscription_Mutation_Response>;
  /** update data of the table: "WUI_OrderSubscriptionPeriod" */
  update_WUI_OrderSubscriptionPeriod?: Maybe<Wui_OrderSubscriptionPeriod_Mutation_Response>;
  /** update single row of the table: "WUI_OrderSubscriptionPeriod" */
  update_WUI_OrderSubscriptionPeriod_by_pk?: Maybe<Wui_OrderSubscriptionPeriod>;
  /** update multiples rows of table: "WUI_OrderSubscriptionPeriod" */
  update_WUI_OrderSubscriptionPeriod_many?: Maybe<Array<Maybe<Wui_OrderSubscriptionPeriod_Mutation_Response>>>;
  /** update data of the table: "WUI_OrderSubscriptionStatus" */
  update_WUI_OrderSubscriptionStatus?: Maybe<Wui_OrderSubscriptionStatus_Mutation_Response>;
  /** update single row of the table: "WUI_OrderSubscriptionStatus" */
  update_WUI_OrderSubscriptionStatus_by_pk?: Maybe<Wui_OrderSubscriptionStatus>;
  /** update multiples rows of table: "WUI_OrderSubscriptionStatus" */
  update_WUI_OrderSubscriptionStatus_many?: Maybe<Array<Maybe<Wui_OrderSubscriptionStatus_Mutation_Response>>>;
  /** update single row of the table: "WUI_OrderSubscription" */
  update_WUI_OrderSubscription_by_pk?: Maybe<Wui_OrderSubscription>;
  /** update multiples rows of table: "WUI_OrderSubscription" */
  update_WUI_OrderSubscription_many?: Maybe<Array<Maybe<Wui_OrderSubscription_Mutation_Response>>>;
  /** update single row of the table: "WUI_Order" */
  update_WUI_Order_by_pk?: Maybe<Wui_Order>;
  /** update multiples rows of table: "WUI_Order" */
  update_WUI_Order_many?: Maybe<Array<Maybe<Wui_Order_Mutation_Response>>>;
  /** update data of the table: "WUI_PackageRequest" */
  update_WUI_PackageRequest?: Maybe<Wui_PackageRequest_Mutation_Response>;
  /** update single row of the table: "WUI_PackageRequest" */
  update_WUI_PackageRequest_by_pk?: Maybe<Wui_PackageRequest>;
  /** update multiples rows of table: "WUI_PackageRequest" */
  update_WUI_PackageRequest_many?: Maybe<Array<Maybe<Wui_PackageRequest_Mutation_Response>>>;
  /** update data of the table: "WUI_Payment" */
  update_WUI_Payment?: Maybe<Wui_Payment_Mutation_Response>;
  /** update data of the table: "WUI_PaymentMethod" */
  update_WUI_PaymentMethod?: Maybe<Wui_PaymentMethod_Mutation_Response>;
  /** update data of the table: "WUI_PaymentMethodBankAccount" */
  update_WUI_PaymentMethodBankAccount?: Maybe<Wui_PaymentMethodBankAccount_Mutation_Response>;
  /** update single row of the table: "WUI_PaymentMethodBankAccount" */
  update_WUI_PaymentMethodBankAccount_by_pk?: Maybe<Wui_PaymentMethodBankAccount>;
  /** update multiples rows of table: "WUI_PaymentMethodBankAccount" */
  update_WUI_PaymentMethodBankAccount_many?: Maybe<Array<Maybe<Wui_PaymentMethodBankAccount_Mutation_Response>>>;
  /** update data of the table: "WUI_PaymentMethodCard" */
  update_WUI_PaymentMethodCard?: Maybe<Wui_PaymentMethodCard_Mutation_Response>;
  /** update single row of the table: "WUI_PaymentMethodCard" */
  update_WUI_PaymentMethodCard_by_pk?: Maybe<Wui_PaymentMethodCard>;
  /** update multiples rows of table: "WUI_PaymentMethodCard" */
  update_WUI_PaymentMethodCard_many?: Maybe<Array<Maybe<Wui_PaymentMethodCard_Mutation_Response>>>;
  /** update data of the table: "WUI_PaymentMethodSource" */
  update_WUI_PaymentMethodSource?: Maybe<Wui_PaymentMethodSource_Mutation_Response>;
  /** update single row of the table: "WUI_PaymentMethodSource" */
  update_WUI_PaymentMethodSource_by_pk?: Maybe<Wui_PaymentMethodSource>;
  /** update multiples rows of table: "WUI_PaymentMethodSource" */
  update_WUI_PaymentMethodSource_many?: Maybe<Array<Maybe<Wui_PaymentMethodSource_Mutation_Response>>>;
  /** update single row of the table: "WUI_PaymentMethod" */
  update_WUI_PaymentMethod_by_pk?: Maybe<Wui_PaymentMethod>;
  /** update multiples rows of table: "WUI_PaymentMethod" */
  update_WUI_PaymentMethod_many?: Maybe<Array<Maybe<Wui_PaymentMethod_Mutation_Response>>>;
  /** update data of the table: "WUI_PaymentStatus" */
  update_WUI_PaymentStatus?: Maybe<Wui_PaymentStatus_Mutation_Response>;
  /** update single row of the table: "WUI_PaymentStatus" */
  update_WUI_PaymentStatus_by_pk?: Maybe<Wui_PaymentStatus>;
  /** update multiples rows of table: "WUI_PaymentStatus" */
  update_WUI_PaymentStatus_many?: Maybe<Array<Maybe<Wui_PaymentStatus_Mutation_Response>>>;
  /** update single row of the table: "WUI_Payment" */
  update_WUI_Payment_by_pk?: Maybe<Wui_Payment>;
  /** update multiples rows of table: "WUI_Payment" */
  update_WUI_Payment_many?: Maybe<Array<Maybe<Wui_Payment_Mutation_Response>>>;
  /** update data of the table: "WUI_RequestStatus" */
  update_WUI_RequestStatus?: Maybe<Wui_RequestStatus_Mutation_Response>;
  /** update single row of the table: "WUI_RequestStatus" */
  update_WUI_RequestStatus_by_pk?: Maybe<Wui_RequestStatus>;
  /** update multiples rows of table: "WUI_RequestStatus" */
  update_WUI_RequestStatus_many?: Maybe<Array<Maybe<Wui_RequestStatus_Mutation_Response>>>;
  /** update data of the table: "WUI_Role" */
  update_WUI_Role?: Maybe<Wui_Role_Mutation_Response>;
  /** update single row of the table: "WUI_Role" */
  update_WUI_Role_by_pk?: Maybe<Wui_Role>;
  /** update multiples rows of table: "WUI_Role" */
  update_WUI_Role_many?: Maybe<Array<Maybe<Wui_Role_Mutation_Response>>>;
  /** update data of the table: "WUI_SellerPackage" */
  update_WUI_SellerPackage?: Maybe<Wui_SellerPackage_Mutation_Response>;
  /** update data of the table: "WUI_SellerPackageItem" */
  update_WUI_SellerPackageItem?: Maybe<Wui_SellerPackageItem_Mutation_Response>;
  /** update single row of the table: "WUI_SellerPackageItem" */
  update_WUI_SellerPackageItem_by_pk?: Maybe<Wui_SellerPackageItem>;
  /** update multiples rows of table: "WUI_SellerPackageItem" */
  update_WUI_SellerPackageItem_many?: Maybe<Array<Maybe<Wui_SellerPackageItem_Mutation_Response>>>;
  /** update data of the table: "WUI_SellerPackageType" */
  update_WUI_SellerPackageType?: Maybe<Wui_SellerPackageType_Mutation_Response>;
  /** update single row of the table: "WUI_SellerPackageType" */
  update_WUI_SellerPackageType_by_pk?: Maybe<Wui_SellerPackageType>;
  /** update multiples rows of table: "WUI_SellerPackageType" */
  update_WUI_SellerPackageType_many?: Maybe<Array<Maybe<Wui_SellerPackageType_Mutation_Response>>>;
  /** update single row of the table: "WUI_SellerPackage" */
  update_WUI_SellerPackage_by_pk?: Maybe<Wui_SellerPackage>;
  /** update multiples rows of table: "WUI_SellerPackage" */
  update_WUI_SellerPackage_many?: Maybe<Array<Maybe<Wui_SellerPackage_Mutation_Response>>>;
  /** update data of the table: "WUI_SellerProject" */
  update_WUI_SellerProject?: Maybe<Wui_SellerProject_Mutation_Response>;
  /** update data of the table: "WUI_SellerProjectFile" */
  update_WUI_SellerProjectFile?: Maybe<Wui_SellerProjectFile_Mutation_Response>;
  /** update single row of the table: "WUI_SellerProjectFile" */
  update_WUI_SellerProjectFile_by_pk?: Maybe<Wui_SellerProjectFile>;
  /** update multiples rows of table: "WUI_SellerProjectFile" */
  update_WUI_SellerProjectFile_many?: Maybe<Array<Maybe<Wui_SellerProjectFile_Mutation_Response>>>;
  /** update data of the table: "WUI_SellerProjectItem" */
  update_WUI_SellerProjectItem?: Maybe<Wui_SellerProjectItem_Mutation_Response>;
  /** update single row of the table: "WUI_SellerProjectItem" */
  update_WUI_SellerProjectItem_by_pk?: Maybe<Wui_SellerProjectItem>;
  /** update multiples rows of table: "WUI_SellerProjectItem" */
  update_WUI_SellerProjectItem_many?: Maybe<Array<Maybe<Wui_SellerProjectItem_Mutation_Response>>>;
  /** update single row of the table: "WUI_SellerProject" */
  update_WUI_SellerProject_by_pk?: Maybe<Wui_SellerProject>;
  /** update multiples rows of table: "WUI_SellerProject" */
  update_WUI_SellerProject_many?: Maybe<Array<Maybe<Wui_SellerProject_Mutation_Response>>>;
  /** update data of the table: "WUI_Session" */
  update_WUI_Session?: Maybe<Wui_Session_Mutation_Response>;
  /** update data of the table: "WUI_SessionEvent" */
  update_WUI_SessionEvent?: Maybe<Wui_SessionEvent_Mutation_Response>;
  /** update data of the table: "WUI_SessionEventType" */
  update_WUI_SessionEventType?: Maybe<Wui_SessionEventType_Mutation_Response>;
  /** update single row of the table: "WUI_SessionEventType" */
  update_WUI_SessionEventType_by_pk?: Maybe<Wui_SessionEventType>;
  /** update multiples rows of table: "WUI_SessionEventType" */
  update_WUI_SessionEventType_many?: Maybe<Array<Maybe<Wui_SessionEventType_Mutation_Response>>>;
  /** update single row of the table: "WUI_SessionEvent" */
  update_WUI_SessionEvent_by_pk?: Maybe<Wui_SessionEvent>;
  /** update multiples rows of table: "WUI_SessionEvent" */
  update_WUI_SessionEvent_many?: Maybe<Array<Maybe<Wui_SessionEvent_Mutation_Response>>>;
  /** update single row of the table: "WUI_Session" */
  update_WUI_Session_by_pk?: Maybe<Wui_Session>;
  /** update multiples rows of table: "WUI_Session" */
  update_WUI_Session_many?: Maybe<Array<Maybe<Wui_Session_Mutation_Response>>>;
  /** update data of the table: "WUI_SocialMediaSource" */
  update_WUI_SocialMediaSource?: Maybe<Wui_SocialMediaSource_Mutation_Response>;
  /** update single row of the table: "WUI_SocialMediaSource" */
  update_WUI_SocialMediaSource_by_pk?: Maybe<Wui_SocialMediaSource>;
  /** update multiples rows of table: "WUI_SocialMediaSource" */
  update_WUI_SocialMediaSource_many?: Maybe<Array<Maybe<Wui_SocialMediaSource_Mutation_Response>>>;
  /** update data of the table: "WUI_SubscriptionPlan" */
  update_WUI_SubscriptionPlan?: Maybe<Wui_SubscriptionPlan_Mutation_Response>;
  /** update single row of the table: "WUI_SubscriptionPlan" */
  update_WUI_SubscriptionPlan_by_pk?: Maybe<Wui_SubscriptionPlan>;
  /** update multiples rows of table: "WUI_SubscriptionPlan" */
  update_WUI_SubscriptionPlan_many?: Maybe<Array<Maybe<Wui_SubscriptionPlan_Mutation_Response>>>;
  /** update data of the table: "WUI_User" */
  update_WUI_User?: Maybe<Wui_User_Mutation_Response>;
  /** update data of the table: "WUI_UserFilePortfolio" */
  update_WUI_UserFilePortfolio?: Maybe<Wui_UserFilePortfolio_Mutation_Response>;
  /** update single row of the table: "WUI_UserFilePortfolio" */
  update_WUI_UserFilePortfolio_by_pk?: Maybe<Wui_UserFilePortfolio>;
  /** update multiples rows of table: "WUI_UserFilePortfolio" */
  update_WUI_UserFilePortfolio_many?: Maybe<Array<Maybe<Wui_UserFilePortfolio_Mutation_Response>>>;
  /** update data of the table: "WUI_UserReview" */
  update_WUI_UserReview?: Maybe<Wui_UserReview_Mutation_Response>;
  /** update single row of the table: "WUI_UserReview" */
  update_WUI_UserReview_by_pk?: Maybe<Wui_UserReview>;
  /** update multiples rows of table: "WUI_UserReview" */
  update_WUI_UserReview_many?: Maybe<Array<Maybe<Wui_UserReview_Mutation_Response>>>;
  /** update data of the table: "WUI_UserSubscription" */
  update_WUI_UserSubscription?: Maybe<Wui_UserSubscription_Mutation_Response>;
  /** update single row of the table: "WUI_UserSubscription" */
  update_WUI_UserSubscription_by_pk?: Maybe<Wui_UserSubscription>;
  /** update multiples rows of table: "WUI_UserSubscription" */
  update_WUI_UserSubscription_many?: Maybe<Array<Maybe<Wui_UserSubscription_Mutation_Response>>>;
  /** update data of the table: "WUI_UserType" */
  update_WUI_UserType?: Maybe<Wui_UserType_Mutation_Response>;
  /** update single row of the table: "WUI_UserType" */
  update_WUI_UserType_by_pk?: Maybe<Wui_UserType>;
  /** update multiples rows of table: "WUI_UserType" */
  update_WUI_UserType_many?: Maybe<Array<Maybe<Wui_UserType_Mutation_Response>>>;
  /** update single row of the table: "WUI_User" */
  update_WUI_User_by_pk?: Maybe<Wui_User>;
  /** update multiples rows of table: "WUI_User" */
  update_WUI_User_many?: Maybe<Array<Maybe<Wui_User_Mutation_Response>>>;
  /** update data of the table: "WUI_VerificationToken" */
  update_WUI_VerificationToken?: Maybe<Wui_VerificationToken_Mutation_Response>;
  /** update single row of the table: "WUI_VerificationToken" */
  update_WUI_VerificationToken_by_pk?: Maybe<Wui_VerificationToken>;
  /** update multiples rows of table: "WUI_VerificationToken" */
  update_WUI_VerificationToken_many?: Maybe<Array<Maybe<Wui_VerificationToken_Mutation_Response>>>;
  /** update data of the table: "unclaimed_demographics" */
  update_unclaimed_demographics?: Maybe<Unclaimed_Demographics_Mutation_Response>;
  /** update single row of the table: "unclaimed_demographics" */
  update_unclaimed_demographics_by_pk?: Maybe<Unclaimed_Demographics>;
  /** update multiples rows of table: "unclaimed_demographics" */
  update_unclaimed_demographics_many?: Maybe<Array<Maybe<Unclaimed_Demographics_Mutation_Response>>>;
  /** update data of the table: "unclaimed_profile" */
  update_unclaimed_profile?: Maybe<Unclaimed_Profile_Mutation_Response>;
  /** update single row of the table: "unclaimed_profile" */
  update_unclaimed_profile_by_pk?: Maybe<Unclaimed_Profile>;
  /** update multiples rows of table: "unclaimed_profile" */
  update_unclaimed_profile_many?: Maybe<Array<Maybe<Unclaimed_Profile_Mutation_Response>>>;
  /** update data of the table: "wui_userlastseen" */
  update_wui_userlastseen?: Maybe<Wui_Userlastseen_Mutation_Response>;
  /** update multiples rows of table: "wui_userlastseen" */
  update_wui_userlastseen_many?: Maybe<Array<Maybe<Wui_Userlastseen_Mutation_Response>>>;
  uploadCoverPicture_WUI_user?: Maybe<FileOutput>;
  uploadPicture_HeroBanner?: Maybe<FileOutput>;
  uploadPicture_WUI_ChatRoomMessage?: Maybe<FileOutput>;
  uploadPicture_WUI_ProjectFile?: Maybe<ProjectFileOutput>;
  uploadPicture_WUI_UseFilePortfolio?: Maybe<PortfolioFileOutput>;
  uploadProfilePicture_WUI_User?: Maybe<FileOutput>;
};


/** mutation root */
export type Mutation_RootCreateBankAccountArgs = {
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateCompanyAccountArgs = {
  address: CompanyAddress;
  name: Scalars['String'];
  taxID: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateIndividualAccountArgs = {
  address: IndividualAddress;
  birthday: Scalars['String'];
  firstName: Scalars['String'];
  last4ssn: Scalars['String'];
  lastName: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreate_Wui_OrderArgs = {
  packageFees: Array<InputMaybe<Scalars['String']>>;
  packageId: Scalars['bigint'];
  payment_method_id: Scalars['bigint'];
  subscriptionPeriod?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootCreate_Wui_OrderDetailsArgs = {
  data: Scalars['jsonb'];
  roomID: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootCreate_Wui_PaymentIntentArgs = {
  orderId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootCreate_Wui_PaymentMethodCardArgs = {
  paymentMethodId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDefaultBankAccountArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_AmbassadorArgs = {
  where: Ambassador_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ambassador_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DemographicArgs = {
  where: Demographic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Demographic_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_EmailDomainLookupArgs = {
  where: EmailDomainLookup_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmailDomainLookup_By_PkArgs = {
  domain: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_HeroBannerArgs = {
  where: HeroBanner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_HeroBanner_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_IndustryArgs = {
  where: Industry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Industry_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_MarketplaceWaitlistArgs = {
  where: MarketplaceWaitlist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MarketplaceWaitlist_By_PkArgs = {
  user_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_PlatformAlertArgs = {
  where: PlatformAlert_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PlatformAlert_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProjectDemographicArgs = {
  where: ProjectDemographic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProjectDemographic_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_SignupSourceArgs = {
  where: SignupSource_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SignupSourceTrackerArgs = {
  where: SignupSourceTracker_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SignupSourceTracker_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SignupSource_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaArgs = {
  where: SocialMedia_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaDemographicArgs = {
  where: SocialMediaDemographic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaDemographic_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaHistoryArgs = {
  where: SocialMediaHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaHistory_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaLinkArgs = {
  where: SocialMediaLink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaLink_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaPlatformArgs = {
  where: SocialMediaPlatform_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaPlatform_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_SocialMedia_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TempLoginWithPasswordArgs = {
  where: TempLoginWithPassword_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TempLoginWithPassword_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserVideoArgs = {
  where: UserVideo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserVideo_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_VoteArgs = {
  where: Vote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VoteCategoryArgs = {
  where: VoteCategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VoteCategory_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_VoteUserArgs = {
  where: VoteUser_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VoteUserCategoryArgs = {
  where: VoteUserCategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VoteUserCategory_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_VoteUser_By_PkArgs = {
  user_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Vote_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_AccountArgs = {
  where: Wui_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_AffiliateArgs = {
  where: Wui_Affiliate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_AffiliateLevelArgs = {
  where: Wui_AffiliateLevel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_AffiliateLevel_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_AffiliateStatusArgs = {
  where: Wui_AffiliateStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_AffiliateStatus_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_AffiliateTransactionArgs = {
  where: Wui_AffiliateTransaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_AffiliateTransaction_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_AffiliateUsersArgs = {
  where: Wui_AffiliateUsers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_AffiliateUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_Affiliate_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_AuthProviderArgs = {
  where: Wui_AuthProvider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_AuthProvider_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_AuthSessionArgs = {
  where: Wui_AuthSession_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_AuthSession_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoomArgs = {
  where: Wui_ChatRoom_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoomMessageArgs = {
  where: Wui_ChatRoomMessage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoomMessage_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoomOrderArgs = {
  where: Wui_ChatRoomOrder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoomOrder_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoomTypeArgs = {
  where: Wui_ChatRoomType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoomType_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoomUserArgs = {
  where: Wui_ChatRoomUser_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoomUser_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoom_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_FileArgs = {
  where: Wui_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_FileTypeArgs = {
  where: Wui_FileType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_FileType_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_File_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_NotificationArgs = {
  where: Wui_Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_Notification_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_OrderArgs = {
  where: Wui_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_OrderReviewArgs = {
  where: Wui_OrderReview_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_OrderReview_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_OrderStatusArgs = {
  where: Wui_OrderStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_OrderStatus_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_OrderSubscriptionArgs = {
  where: Wui_OrderSubscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_OrderSubscriptionPeriodArgs = {
  where: Wui_OrderSubscriptionPeriod_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_OrderSubscriptionPeriod_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_OrderSubscriptionStatusArgs = {
  where: Wui_OrderSubscriptionStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_OrderSubscriptionStatus_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_OrderSubscription_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_Order_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_PackageRequestArgs = {
  where: Wui_PackageRequest_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_PackageRequest_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentArgs = {
  where: Wui_Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentMethodArgs = {
  where: Wui_PaymentMethod_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentMethodBankAccountArgs = {
  where: Wui_PaymentMethodBankAccount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentMethodBankAccount_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentMethodCardArgs = {
  where: Wui_PaymentMethodCard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentMethodCard_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentMethodSourceArgs = {
  where: Wui_PaymentMethodSource_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentMethodSource_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentMethod_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentStatusArgs = {
  where: Wui_PaymentStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentStatus_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_Payment_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_RequestStatusArgs = {
  where: Wui_RequestStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_RequestStatus_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_RoleArgs = {
  where: Wui_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_Role_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerPackageArgs = {
  where: Wui_SellerPackage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerPackageItemArgs = {
  where: Wui_SellerPackageItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerPackageItem_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerPackageTypeArgs = {
  where: Wui_SellerPackageType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerPackageType_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerPackage_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerProjectArgs = {
  where: Wui_SellerProject_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerProjectFileArgs = {
  where: Wui_SellerProjectFile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerProjectFile_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerProjectItemArgs = {
  where: Wui_SellerProjectItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerProjectItem_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerProject_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SessionArgs = {
  where: Wui_Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SessionEventArgs = {
  where: Wui_SessionEvent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SessionEventTypeArgs = {
  where: Wui_SessionEventType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SessionEventType_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SessionEvent_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_Session_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SocialMediaSourceArgs = {
  where: Wui_SocialMediaSource_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SocialMediaSource_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SubscriptionPlanArgs = {
  where: Wui_SubscriptionPlan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SubscriptionPlan_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_UserArgs = {
  where: Wui_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_UserFilePortfolioArgs = {
  where: Wui_UserFilePortfolio_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_UserFilePortfolio_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_UserReviewArgs = {
  where: Wui_UserReview_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_UserReview_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_UserSubscriptionArgs = {
  where: Wui_UserSubscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_UserSubscription_By_PkArgs = {
  external_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_UserTypeArgs = {
  where: Wui_UserType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_UserType_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_User_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_VerificationTokenArgs = {
  where: Wui_VerificationToken_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_VerificationToken_By_PkArgs = {
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Unclaimed_DemographicsArgs = {
  where: Unclaimed_Demographics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Unclaimed_Demographics_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Unclaimed_ProfileArgs = {
  where: Unclaimed_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Unclaimed_Profile_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_UserlastseenArgs = {
  where: Wui_Userlastseen_Bool_Exp;
};


/** mutation root */
export type Mutation_RootInsert_AmbassadorArgs = {
  objects: Array<Ambassador_Insert_Input>;
  on_conflict?: InputMaybe<Ambassador_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ambassador_OneArgs = {
  object: Ambassador_Insert_Input;
  on_conflict?: InputMaybe<Ambassador_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DemographicArgs = {
  objects: Array<Demographic_Insert_Input>;
  on_conflict?: InputMaybe<Demographic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Demographic_OneArgs = {
  object: Demographic_Insert_Input;
  on_conflict?: InputMaybe<Demographic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailDomainLookupArgs = {
  objects: Array<EmailDomainLookup_Insert_Input>;
  on_conflict?: InputMaybe<EmailDomainLookup_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailDomainLookup_OneArgs = {
  object: EmailDomainLookup_Insert_Input;
  on_conflict?: InputMaybe<EmailDomainLookup_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HeroBannerArgs = {
  objects: Array<HeroBanner_Insert_Input>;
  on_conflict?: InputMaybe<HeroBanner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HeroBanner_OneArgs = {
  object: HeroBanner_Insert_Input;
  on_conflict?: InputMaybe<HeroBanner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IndustryArgs = {
  objects: Array<Industry_Insert_Input>;
  on_conflict?: InputMaybe<Industry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Industry_OneArgs = {
  object: Industry_Insert_Input;
  on_conflict?: InputMaybe<Industry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MarketplaceWaitlistArgs = {
  objects: Array<MarketplaceWaitlist_Insert_Input>;
  on_conflict?: InputMaybe<MarketplaceWaitlist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MarketplaceWaitlist_OneArgs = {
  object: MarketplaceWaitlist_Insert_Input;
  on_conflict?: InputMaybe<MarketplaceWaitlist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PlatformAlertArgs = {
  objects: Array<PlatformAlert_Insert_Input>;
  on_conflict?: InputMaybe<PlatformAlert_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PlatformAlert_OneArgs = {
  object: PlatformAlert_Insert_Input;
  on_conflict?: InputMaybe<PlatformAlert_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectDemographicArgs = {
  objects: Array<ProjectDemographic_Insert_Input>;
  on_conflict?: InputMaybe<ProjectDemographic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectDemographic_OneArgs = {
  object: ProjectDemographic_Insert_Input;
  on_conflict?: InputMaybe<ProjectDemographic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SignupSourceArgs = {
  objects: Array<SignupSource_Insert_Input>;
  on_conflict?: InputMaybe<SignupSource_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SignupSourceTrackerArgs = {
  objects: Array<SignupSourceTracker_Insert_Input>;
  on_conflict?: InputMaybe<SignupSourceTracker_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SignupSourceTracker_OneArgs = {
  object: SignupSourceTracker_Insert_Input;
  on_conflict?: InputMaybe<SignupSourceTracker_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SignupSource_OneArgs = {
  object: SignupSource_Insert_Input;
  on_conflict?: InputMaybe<SignupSource_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaArgs = {
  objects: Array<SocialMedia_Insert_Input>;
  on_conflict?: InputMaybe<SocialMedia_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaDemographicArgs = {
  objects: Array<SocialMediaDemographic_Insert_Input>;
  on_conflict?: InputMaybe<SocialMediaDemographic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaDemographic_OneArgs = {
  object: SocialMediaDemographic_Insert_Input;
  on_conflict?: InputMaybe<SocialMediaDemographic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaHistoryArgs = {
  objects: Array<SocialMediaHistory_Insert_Input>;
  on_conflict?: InputMaybe<SocialMediaHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaHistory_OneArgs = {
  object: SocialMediaHistory_Insert_Input;
  on_conflict?: InputMaybe<SocialMediaHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaLinkArgs = {
  objects: Array<SocialMediaLink_Insert_Input>;
  on_conflict?: InputMaybe<SocialMediaLink_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaLink_OneArgs = {
  object: SocialMediaLink_Insert_Input;
  on_conflict?: InputMaybe<SocialMediaLink_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaPlatformArgs = {
  objects: Array<SocialMediaPlatform_Insert_Input>;
  on_conflict?: InputMaybe<SocialMediaPlatform_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaPlatform_OneArgs = {
  object: SocialMediaPlatform_Insert_Input;
  on_conflict?: InputMaybe<SocialMediaPlatform_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMedia_OneArgs = {
  object: SocialMedia_Insert_Input;
  on_conflict?: InputMaybe<SocialMedia_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TempLoginWithPasswordArgs = {
  objects: Array<TempLoginWithPassword_Insert_Input>;
  on_conflict?: InputMaybe<TempLoginWithPassword_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TempLoginWithPassword_OneArgs = {
  object: TempLoginWithPassword_Insert_Input;
  on_conflict?: InputMaybe<TempLoginWithPassword_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserVideoArgs = {
  objects: Array<UserVideo_Insert_Input>;
  on_conflict?: InputMaybe<UserVideo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserVideo_OneArgs = {
  object: UserVideo_Insert_Input;
  on_conflict?: InputMaybe<UserVideo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VoteArgs = {
  objects: Array<Vote_Insert_Input>;
  on_conflict?: InputMaybe<Vote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VoteCategoryArgs = {
  objects: Array<VoteCategory_Insert_Input>;
  on_conflict?: InputMaybe<VoteCategory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VoteCategory_OneArgs = {
  object: VoteCategory_Insert_Input;
  on_conflict?: InputMaybe<VoteCategory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VoteUserArgs = {
  objects: Array<VoteUser_Insert_Input>;
  on_conflict?: InputMaybe<VoteUser_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VoteUserCategoryArgs = {
  objects: Array<VoteUserCategory_Insert_Input>;
  on_conflict?: InputMaybe<VoteUserCategory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VoteUserCategory_OneArgs = {
  object: VoteUserCategory_Insert_Input;
  on_conflict?: InputMaybe<VoteUserCategory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VoteUser_OneArgs = {
  object: VoteUser_Insert_Input;
  on_conflict?: InputMaybe<VoteUser_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vote_OneArgs = {
  object: Vote_Insert_Input;
  on_conflict?: InputMaybe<Vote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_AccountArgs = {
  objects: Array<Wui_Account_Insert_Input>;
  on_conflict?: InputMaybe<Wui_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_Account_OneArgs = {
  object: Wui_Account_Insert_Input;
  on_conflict?: InputMaybe<Wui_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_AffiliateArgs = {
  objects: Array<Wui_Affiliate_Insert_Input>;
  on_conflict?: InputMaybe<Wui_Affiliate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_AffiliateLevelArgs = {
  objects: Array<Wui_AffiliateLevel_Insert_Input>;
  on_conflict?: InputMaybe<Wui_AffiliateLevel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_AffiliateLevel_OneArgs = {
  object: Wui_AffiliateLevel_Insert_Input;
  on_conflict?: InputMaybe<Wui_AffiliateLevel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_AffiliateStatusArgs = {
  objects: Array<Wui_AffiliateStatus_Insert_Input>;
  on_conflict?: InputMaybe<Wui_AffiliateStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_AffiliateStatus_OneArgs = {
  object: Wui_AffiliateStatus_Insert_Input;
  on_conflict?: InputMaybe<Wui_AffiliateStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_AffiliateTransactionArgs = {
  objects: Array<Wui_AffiliateTransaction_Insert_Input>;
  on_conflict?: InputMaybe<Wui_AffiliateTransaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_AffiliateTransaction_OneArgs = {
  object: Wui_AffiliateTransaction_Insert_Input;
  on_conflict?: InputMaybe<Wui_AffiliateTransaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_AffiliateUsersArgs = {
  objects: Array<Wui_AffiliateUsers_Insert_Input>;
  on_conflict?: InputMaybe<Wui_AffiliateUsers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_AffiliateUsers_OneArgs = {
  object: Wui_AffiliateUsers_Insert_Input;
  on_conflict?: InputMaybe<Wui_AffiliateUsers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_Affiliate_OneArgs = {
  object: Wui_Affiliate_Insert_Input;
  on_conflict?: InputMaybe<Wui_Affiliate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_AuthProviderArgs = {
  objects: Array<Wui_AuthProvider_Insert_Input>;
  on_conflict?: InputMaybe<Wui_AuthProvider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_AuthProvider_OneArgs = {
  object: Wui_AuthProvider_Insert_Input;
  on_conflict?: InputMaybe<Wui_AuthProvider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_AuthSessionArgs = {
  objects: Array<Wui_AuthSession_Insert_Input>;
  on_conflict?: InputMaybe<Wui_AuthSession_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_AuthSession_OneArgs = {
  object: Wui_AuthSession_Insert_Input;
  on_conflict?: InputMaybe<Wui_AuthSession_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoomArgs = {
  objects: Array<Wui_ChatRoom_Insert_Input>;
  on_conflict?: InputMaybe<Wui_ChatRoom_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoomMessageArgs = {
  objects: Array<Wui_ChatRoomMessage_Insert_Input>;
  on_conflict?: InputMaybe<Wui_ChatRoomMessage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoomMessage_OneArgs = {
  object: Wui_ChatRoomMessage_Insert_Input;
  on_conflict?: InputMaybe<Wui_ChatRoomMessage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoomOrderArgs = {
  objects: Array<Wui_ChatRoomOrder_Insert_Input>;
  on_conflict?: InputMaybe<Wui_ChatRoomOrder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoomOrder_OneArgs = {
  object: Wui_ChatRoomOrder_Insert_Input;
  on_conflict?: InputMaybe<Wui_ChatRoomOrder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoomTypeArgs = {
  objects: Array<Wui_ChatRoomType_Insert_Input>;
  on_conflict?: InputMaybe<Wui_ChatRoomType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoomType_OneArgs = {
  object: Wui_ChatRoomType_Insert_Input;
  on_conflict?: InputMaybe<Wui_ChatRoomType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoomUserArgs = {
  objects: Array<Wui_ChatRoomUser_Insert_Input>;
  on_conflict?: InputMaybe<Wui_ChatRoomUser_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoomUser_OneArgs = {
  object: Wui_ChatRoomUser_Insert_Input;
  on_conflict?: InputMaybe<Wui_ChatRoomUser_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoom_OneArgs = {
  object: Wui_ChatRoom_Insert_Input;
  on_conflict?: InputMaybe<Wui_ChatRoom_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_FileArgs = {
  objects: Array<Wui_File_Insert_Input>;
  on_conflict?: InputMaybe<Wui_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_FileTypeArgs = {
  objects: Array<Wui_FileType_Insert_Input>;
  on_conflict?: InputMaybe<Wui_FileType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_FileType_OneArgs = {
  object: Wui_FileType_Insert_Input;
  on_conflict?: InputMaybe<Wui_FileType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_File_OneArgs = {
  object: Wui_File_Insert_Input;
  on_conflict?: InputMaybe<Wui_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_NotificationArgs = {
  objects: Array<Wui_Notification_Insert_Input>;
  on_conflict?: InputMaybe<Wui_Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_Notification_OneArgs = {
  object: Wui_Notification_Insert_Input;
  on_conflict?: InputMaybe<Wui_Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_OrderArgs = {
  objects: Array<Wui_Order_Insert_Input>;
  on_conflict?: InputMaybe<Wui_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_OrderReviewArgs = {
  objects: Array<Wui_OrderReview_Insert_Input>;
  on_conflict?: InputMaybe<Wui_OrderReview_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_OrderReview_OneArgs = {
  object: Wui_OrderReview_Insert_Input;
  on_conflict?: InputMaybe<Wui_OrderReview_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_OrderStatusArgs = {
  objects: Array<Wui_OrderStatus_Insert_Input>;
  on_conflict?: InputMaybe<Wui_OrderStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_OrderStatus_OneArgs = {
  object: Wui_OrderStatus_Insert_Input;
  on_conflict?: InputMaybe<Wui_OrderStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_OrderSubscriptionArgs = {
  objects: Array<Wui_OrderSubscription_Insert_Input>;
  on_conflict?: InputMaybe<Wui_OrderSubscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_OrderSubscriptionPeriodArgs = {
  objects: Array<Wui_OrderSubscriptionPeriod_Insert_Input>;
  on_conflict?: InputMaybe<Wui_OrderSubscriptionPeriod_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_OrderSubscriptionPeriod_OneArgs = {
  object: Wui_OrderSubscriptionPeriod_Insert_Input;
  on_conflict?: InputMaybe<Wui_OrderSubscriptionPeriod_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_OrderSubscriptionStatusArgs = {
  objects: Array<Wui_OrderSubscriptionStatus_Insert_Input>;
  on_conflict?: InputMaybe<Wui_OrderSubscriptionStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_OrderSubscriptionStatus_OneArgs = {
  object: Wui_OrderSubscriptionStatus_Insert_Input;
  on_conflict?: InputMaybe<Wui_OrderSubscriptionStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_OrderSubscription_OneArgs = {
  object: Wui_OrderSubscription_Insert_Input;
  on_conflict?: InputMaybe<Wui_OrderSubscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_Order_OneArgs = {
  object: Wui_Order_Insert_Input;
  on_conflict?: InputMaybe<Wui_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PackageRequestArgs = {
  objects: Array<Wui_PackageRequest_Insert_Input>;
  on_conflict?: InputMaybe<Wui_PackageRequest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PackageRequest_OneArgs = {
  object: Wui_PackageRequest_Insert_Input;
  on_conflict?: InputMaybe<Wui_PackageRequest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentArgs = {
  objects: Array<Wui_Payment_Insert_Input>;
  on_conflict?: InputMaybe<Wui_Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentMethodArgs = {
  objects: Array<Wui_PaymentMethod_Insert_Input>;
  on_conflict?: InputMaybe<Wui_PaymentMethod_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentMethodBankAccountArgs = {
  objects: Array<Wui_PaymentMethodBankAccount_Insert_Input>;
  on_conflict?: InputMaybe<Wui_PaymentMethodBankAccount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentMethodBankAccount_OneArgs = {
  object: Wui_PaymentMethodBankAccount_Insert_Input;
  on_conflict?: InputMaybe<Wui_PaymentMethodBankAccount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentMethodCardArgs = {
  objects: Array<Wui_PaymentMethodCard_Insert_Input>;
  on_conflict?: InputMaybe<Wui_PaymentMethodCard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentMethodCard_OneArgs = {
  object: Wui_PaymentMethodCard_Insert_Input;
  on_conflict?: InputMaybe<Wui_PaymentMethodCard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentMethodSourceArgs = {
  objects: Array<Wui_PaymentMethodSource_Insert_Input>;
  on_conflict?: InputMaybe<Wui_PaymentMethodSource_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentMethodSource_OneArgs = {
  object: Wui_PaymentMethodSource_Insert_Input;
  on_conflict?: InputMaybe<Wui_PaymentMethodSource_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentMethod_OneArgs = {
  object: Wui_PaymentMethod_Insert_Input;
  on_conflict?: InputMaybe<Wui_PaymentMethod_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentStatusArgs = {
  objects: Array<Wui_PaymentStatus_Insert_Input>;
  on_conflict?: InputMaybe<Wui_PaymentStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentStatus_OneArgs = {
  object: Wui_PaymentStatus_Insert_Input;
  on_conflict?: InputMaybe<Wui_PaymentStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_Payment_OneArgs = {
  object: Wui_Payment_Insert_Input;
  on_conflict?: InputMaybe<Wui_Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_RequestStatusArgs = {
  objects: Array<Wui_RequestStatus_Insert_Input>;
  on_conflict?: InputMaybe<Wui_RequestStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_RequestStatus_OneArgs = {
  object: Wui_RequestStatus_Insert_Input;
  on_conflict?: InputMaybe<Wui_RequestStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_RoleArgs = {
  objects: Array<Wui_Role_Insert_Input>;
  on_conflict?: InputMaybe<Wui_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_Role_OneArgs = {
  object: Wui_Role_Insert_Input;
  on_conflict?: InputMaybe<Wui_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerPackageArgs = {
  objects: Array<Wui_SellerPackage_Insert_Input>;
  on_conflict?: InputMaybe<Wui_SellerPackage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerPackageItemArgs = {
  objects: Array<Wui_SellerPackageItem_Insert_Input>;
  on_conflict?: InputMaybe<Wui_SellerPackageItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerPackageItem_OneArgs = {
  object: Wui_SellerPackageItem_Insert_Input;
  on_conflict?: InputMaybe<Wui_SellerPackageItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerPackageTypeArgs = {
  objects: Array<Wui_SellerPackageType_Insert_Input>;
  on_conflict?: InputMaybe<Wui_SellerPackageType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerPackageType_OneArgs = {
  object: Wui_SellerPackageType_Insert_Input;
  on_conflict?: InputMaybe<Wui_SellerPackageType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerPackage_OneArgs = {
  object: Wui_SellerPackage_Insert_Input;
  on_conflict?: InputMaybe<Wui_SellerPackage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerProjectArgs = {
  objects: Array<Wui_SellerProject_Insert_Input>;
  on_conflict?: InputMaybe<Wui_SellerProject_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerProjectFileArgs = {
  objects: Array<Wui_SellerProjectFile_Insert_Input>;
  on_conflict?: InputMaybe<Wui_SellerProjectFile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerProjectFile_OneArgs = {
  object: Wui_SellerProjectFile_Insert_Input;
  on_conflict?: InputMaybe<Wui_SellerProjectFile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerProjectItemArgs = {
  objects: Array<Wui_SellerProjectItem_Insert_Input>;
  on_conflict?: InputMaybe<Wui_SellerProjectItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerProjectItem_OneArgs = {
  object: Wui_SellerProjectItem_Insert_Input;
  on_conflict?: InputMaybe<Wui_SellerProjectItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerProject_OneArgs = {
  object: Wui_SellerProject_Insert_Input;
  on_conflict?: InputMaybe<Wui_SellerProject_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SessionArgs = {
  objects: Array<Wui_Session_Insert_Input>;
  on_conflict?: InputMaybe<Wui_Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SessionEventArgs = {
  objects: Array<Wui_SessionEvent_Insert_Input>;
  on_conflict?: InputMaybe<Wui_SessionEvent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SessionEventTypeArgs = {
  objects: Array<Wui_SessionEventType_Insert_Input>;
  on_conflict?: InputMaybe<Wui_SessionEventType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SessionEventType_OneArgs = {
  object: Wui_SessionEventType_Insert_Input;
  on_conflict?: InputMaybe<Wui_SessionEventType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SessionEvent_OneArgs = {
  object: Wui_SessionEvent_Insert_Input;
  on_conflict?: InputMaybe<Wui_SessionEvent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_Session_OneArgs = {
  object: Wui_Session_Insert_Input;
  on_conflict?: InputMaybe<Wui_Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SocialMediaSourceArgs = {
  objects: Array<Wui_SocialMediaSource_Insert_Input>;
  on_conflict?: InputMaybe<Wui_SocialMediaSource_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SocialMediaSource_OneArgs = {
  object: Wui_SocialMediaSource_Insert_Input;
  on_conflict?: InputMaybe<Wui_SocialMediaSource_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SubscriptionPlanArgs = {
  objects: Array<Wui_SubscriptionPlan_Insert_Input>;
  on_conflict?: InputMaybe<Wui_SubscriptionPlan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SubscriptionPlan_OneArgs = {
  object: Wui_SubscriptionPlan_Insert_Input;
  on_conflict?: InputMaybe<Wui_SubscriptionPlan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_UserArgs = {
  objects: Array<Wui_User_Insert_Input>;
  on_conflict?: InputMaybe<Wui_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_UserFilePortfolioArgs = {
  objects: Array<Wui_UserFilePortfolio_Insert_Input>;
  on_conflict?: InputMaybe<Wui_UserFilePortfolio_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_UserFilePortfolio_OneArgs = {
  object: Wui_UserFilePortfolio_Insert_Input;
  on_conflict?: InputMaybe<Wui_UserFilePortfolio_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_UserReviewArgs = {
  objects: Array<Wui_UserReview_Insert_Input>;
  on_conflict?: InputMaybe<Wui_UserReview_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_UserReview_OneArgs = {
  object: Wui_UserReview_Insert_Input;
  on_conflict?: InputMaybe<Wui_UserReview_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_UserSubscriptionArgs = {
  objects: Array<Wui_UserSubscription_Insert_Input>;
  on_conflict?: InputMaybe<Wui_UserSubscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_UserSubscription_OneArgs = {
  object: Wui_UserSubscription_Insert_Input;
  on_conflict?: InputMaybe<Wui_UserSubscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_UserTypeArgs = {
  objects: Array<Wui_UserType_Insert_Input>;
  on_conflict?: InputMaybe<Wui_UserType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_UserType_OneArgs = {
  object: Wui_UserType_Insert_Input;
  on_conflict?: InputMaybe<Wui_UserType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_User_OneArgs = {
  object: Wui_User_Insert_Input;
  on_conflict?: InputMaybe<Wui_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_VerificationTokenArgs = {
  objects: Array<Wui_VerificationToken_Insert_Input>;
  on_conflict?: InputMaybe<Wui_VerificationToken_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_VerificationToken_OneArgs = {
  object: Wui_VerificationToken_Insert_Input;
  on_conflict?: InputMaybe<Wui_VerificationToken_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unclaimed_DemographicsArgs = {
  objects: Array<Unclaimed_Demographics_Insert_Input>;
  on_conflict?: InputMaybe<Unclaimed_Demographics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unclaimed_Demographics_OneArgs = {
  object: Unclaimed_Demographics_Insert_Input;
  on_conflict?: InputMaybe<Unclaimed_Demographics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unclaimed_ProfileArgs = {
  objects: Array<Unclaimed_Profile_Insert_Input>;
  on_conflict?: InputMaybe<Unclaimed_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unclaimed_Profile_OneArgs = {
  object: Unclaimed_Profile_Insert_Input;
  on_conflict?: InputMaybe<Unclaimed_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_UserlastseenArgs = {
  objects: Array<Wui_Userlastseen_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_Userlastseen_OneArgs = {
  object: Wui_Userlastseen_Insert_Input;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  externalID: Scalars['String'];
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootLoginAdminArgs = {
  externalID: Scalars['String'];
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootRemoveBankAccountArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootRemove_Wui_PaymentMethodCardArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootSignupArgs = {
  country: Scalars['String'];
  demographics?: InputMaybe<Scalars['jsonb']>;
  email: Scalars['String'];
  entityName?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  industry?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  links?: InputMaybe<Scalars['jsonb']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  sourceUserId?: InputMaybe<Scalars['Int']>;
  suggestedPackages?: InputMaybe<Scalars['jsonb']>;
  type: Scalars['String'];
  username: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdateStatus_Wui_OrderArgs = {
  orderId: Scalars['bigint'];
  status: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_AmbassadorArgs = {
  _inc?: InputMaybe<Ambassador_Inc_Input>;
  _set?: InputMaybe<Ambassador_Set_Input>;
  where: Ambassador_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ambassador_By_PkArgs = {
  _inc?: InputMaybe<Ambassador_Inc_Input>;
  _set?: InputMaybe<Ambassador_Set_Input>;
  pk_columns: Ambassador_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ambassador_ManyArgs = {
  updates: Array<Ambassador_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DemographicArgs = {
  _set?: InputMaybe<Demographic_Set_Input>;
  where: Demographic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Demographic_By_PkArgs = {
  _set?: InputMaybe<Demographic_Set_Input>;
  pk_columns: Demographic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Demographic_ManyArgs = {
  updates: Array<Demographic_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmailDomainLookupArgs = {
  _inc?: InputMaybe<EmailDomainLookup_Inc_Input>;
  _set?: InputMaybe<EmailDomainLookup_Set_Input>;
  where: EmailDomainLookup_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmailDomainLookup_By_PkArgs = {
  _inc?: InputMaybe<EmailDomainLookup_Inc_Input>;
  _set?: InputMaybe<EmailDomainLookup_Set_Input>;
  pk_columns: EmailDomainLookup_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmailDomainLookup_ManyArgs = {
  updates: Array<EmailDomainLookup_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_HeroBannerArgs = {
  _inc?: InputMaybe<HeroBanner_Inc_Input>;
  _set?: InputMaybe<HeroBanner_Set_Input>;
  where: HeroBanner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_HeroBanner_By_PkArgs = {
  _inc?: InputMaybe<HeroBanner_Inc_Input>;
  _set?: InputMaybe<HeroBanner_Set_Input>;
  pk_columns: HeroBanner_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_HeroBanner_ManyArgs = {
  updates: Array<HeroBanner_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IndustryArgs = {
  _set?: InputMaybe<Industry_Set_Input>;
  where: Industry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Industry_By_PkArgs = {
  _set?: InputMaybe<Industry_Set_Input>;
  pk_columns: Industry_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Industry_ManyArgs = {
  updates: Array<Industry_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MarketplaceWaitlistArgs = {
  _inc?: InputMaybe<MarketplaceWaitlist_Inc_Input>;
  _set?: InputMaybe<MarketplaceWaitlist_Set_Input>;
  where: MarketplaceWaitlist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MarketplaceWaitlist_By_PkArgs = {
  _inc?: InputMaybe<MarketplaceWaitlist_Inc_Input>;
  _set?: InputMaybe<MarketplaceWaitlist_Set_Input>;
  pk_columns: MarketplaceWaitlist_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MarketplaceWaitlist_ManyArgs = {
  updates: Array<MarketplaceWaitlist_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PlatformAlertArgs = {
  _set?: InputMaybe<PlatformAlert_Set_Input>;
  where: PlatformAlert_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PlatformAlert_By_PkArgs = {
  _set?: InputMaybe<PlatformAlert_Set_Input>;
  pk_columns: PlatformAlert_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PlatformAlert_ManyArgs = {
  updates: Array<PlatformAlert_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectDemographicArgs = {
  _inc?: InputMaybe<ProjectDemographic_Inc_Input>;
  _set?: InputMaybe<ProjectDemographic_Set_Input>;
  where: ProjectDemographic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectDemographic_By_PkArgs = {
  _inc?: InputMaybe<ProjectDemographic_Inc_Input>;
  _set?: InputMaybe<ProjectDemographic_Set_Input>;
  pk_columns: ProjectDemographic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectDemographic_ManyArgs = {
  updates: Array<ProjectDemographic_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SignupSourceArgs = {
  _set?: InputMaybe<SignupSource_Set_Input>;
  where: SignupSource_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SignupSourceTrackerArgs = {
  _inc?: InputMaybe<SignupSourceTracker_Inc_Input>;
  _set?: InputMaybe<SignupSourceTracker_Set_Input>;
  where: SignupSourceTracker_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SignupSourceTracker_By_PkArgs = {
  _inc?: InputMaybe<SignupSourceTracker_Inc_Input>;
  _set?: InputMaybe<SignupSourceTracker_Set_Input>;
  pk_columns: SignupSourceTracker_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SignupSourceTracker_ManyArgs = {
  updates: Array<SignupSourceTracker_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SignupSource_By_PkArgs = {
  _set?: InputMaybe<SignupSource_Set_Input>;
  pk_columns: SignupSource_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SignupSource_ManyArgs = {
  updates: Array<SignupSource_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaArgs = {
  _inc?: InputMaybe<SocialMedia_Inc_Input>;
  _set?: InputMaybe<SocialMedia_Set_Input>;
  where: SocialMedia_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaDemographicArgs = {
  _inc?: InputMaybe<SocialMediaDemographic_Inc_Input>;
  _set?: InputMaybe<SocialMediaDemographic_Set_Input>;
  where: SocialMediaDemographic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaDemographic_By_PkArgs = {
  _inc?: InputMaybe<SocialMediaDemographic_Inc_Input>;
  _set?: InputMaybe<SocialMediaDemographic_Set_Input>;
  pk_columns: SocialMediaDemographic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaDemographic_ManyArgs = {
  updates: Array<SocialMediaDemographic_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaHistoryArgs = {
  _inc?: InputMaybe<SocialMediaHistory_Inc_Input>;
  _set?: InputMaybe<SocialMediaHistory_Set_Input>;
  where: SocialMediaHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaHistory_By_PkArgs = {
  _inc?: InputMaybe<SocialMediaHistory_Inc_Input>;
  _set?: InputMaybe<SocialMediaHistory_Set_Input>;
  pk_columns: SocialMediaHistory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaHistory_ManyArgs = {
  updates: Array<SocialMediaHistory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaLinkArgs = {
  _inc?: InputMaybe<SocialMediaLink_Inc_Input>;
  _set?: InputMaybe<SocialMediaLink_Set_Input>;
  where: SocialMediaLink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaLink_By_PkArgs = {
  _inc?: InputMaybe<SocialMediaLink_Inc_Input>;
  _set?: InputMaybe<SocialMediaLink_Set_Input>;
  pk_columns: SocialMediaLink_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaLink_ManyArgs = {
  updates: Array<SocialMediaLink_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaPlatformArgs = {
  _set?: InputMaybe<SocialMediaPlatform_Set_Input>;
  where: SocialMediaPlatform_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaPlatform_By_PkArgs = {
  _set?: InputMaybe<SocialMediaPlatform_Set_Input>;
  pk_columns: SocialMediaPlatform_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaPlatform_ManyArgs = {
  updates: Array<SocialMediaPlatform_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMedia_By_PkArgs = {
  _inc?: InputMaybe<SocialMedia_Inc_Input>;
  _set?: InputMaybe<SocialMedia_Set_Input>;
  pk_columns: SocialMedia_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMedia_ManyArgs = {
  updates: Array<SocialMedia_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TempLoginWithPasswordArgs = {
  _inc?: InputMaybe<TempLoginWithPassword_Inc_Input>;
  _set?: InputMaybe<TempLoginWithPassword_Set_Input>;
  where: TempLoginWithPassword_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TempLoginWithPassword_By_PkArgs = {
  _inc?: InputMaybe<TempLoginWithPassword_Inc_Input>;
  _set?: InputMaybe<TempLoginWithPassword_Set_Input>;
  pk_columns: TempLoginWithPassword_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TempLoginWithPassword_ManyArgs = {
  updates: Array<TempLoginWithPassword_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserVideoArgs = {
  _inc?: InputMaybe<UserVideo_Inc_Input>;
  _set?: InputMaybe<UserVideo_Set_Input>;
  where: UserVideo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserVideo_By_PkArgs = {
  _inc?: InputMaybe<UserVideo_Inc_Input>;
  _set?: InputMaybe<UserVideo_Set_Input>;
  pk_columns: UserVideo_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserVideo_ManyArgs = {
  updates: Array<UserVideo_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VoteArgs = {
  _inc?: InputMaybe<Vote_Inc_Input>;
  _set?: InputMaybe<Vote_Set_Input>;
  where: Vote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VoteCategoryArgs = {
  _set?: InputMaybe<VoteCategory_Set_Input>;
  where: VoteCategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VoteCategory_By_PkArgs = {
  _set?: InputMaybe<VoteCategory_Set_Input>;
  pk_columns: VoteCategory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VoteCategory_ManyArgs = {
  updates: Array<VoteCategory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VoteUserArgs = {
  _inc?: InputMaybe<VoteUser_Inc_Input>;
  _set?: InputMaybe<VoteUser_Set_Input>;
  where: VoteUser_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VoteUserCategoryArgs = {
  _set?: InputMaybe<VoteUserCategory_Set_Input>;
  where: VoteUserCategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VoteUserCategory_By_PkArgs = {
  _set?: InputMaybe<VoteUserCategory_Set_Input>;
  pk_columns: VoteUserCategory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VoteUserCategory_ManyArgs = {
  updates: Array<VoteUserCategory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VoteUser_By_PkArgs = {
  _inc?: InputMaybe<VoteUser_Inc_Input>;
  _set?: InputMaybe<VoteUser_Set_Input>;
  pk_columns: VoteUser_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VoteUser_ManyArgs = {
  updates: Array<VoteUser_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Vote_By_PkArgs = {
  _inc?: InputMaybe<Vote_Inc_Input>;
  _set?: InputMaybe<Vote_Set_Input>;
  pk_columns: Vote_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vote_ManyArgs = {
  updates: Array<Vote_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AccountArgs = {
  _inc?: InputMaybe<Wui_Account_Inc_Input>;
  _set?: InputMaybe<Wui_Account_Set_Input>;
  where: Wui_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Account_By_PkArgs = {
  _inc?: InputMaybe<Wui_Account_Inc_Input>;
  _set?: InputMaybe<Wui_Account_Set_Input>;
  pk_columns: Wui_Account_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Account_ManyArgs = {
  updates: Array<Wui_Account_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AffiliateArgs = {
  _inc?: InputMaybe<Wui_Affiliate_Inc_Input>;
  _set?: InputMaybe<Wui_Affiliate_Set_Input>;
  where: Wui_Affiliate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AffiliateLevelArgs = {
  _set?: InputMaybe<Wui_AffiliateLevel_Set_Input>;
  where: Wui_AffiliateLevel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AffiliateLevel_By_PkArgs = {
  _set?: InputMaybe<Wui_AffiliateLevel_Set_Input>;
  pk_columns: Wui_AffiliateLevel_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AffiliateLevel_ManyArgs = {
  updates: Array<Wui_AffiliateLevel_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AffiliateStatusArgs = {
  _set?: InputMaybe<Wui_AffiliateStatus_Set_Input>;
  where: Wui_AffiliateStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AffiliateStatus_By_PkArgs = {
  _set?: InputMaybe<Wui_AffiliateStatus_Set_Input>;
  pk_columns: Wui_AffiliateStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AffiliateStatus_ManyArgs = {
  updates: Array<Wui_AffiliateStatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AffiliateTransactionArgs = {
  _inc?: InputMaybe<Wui_AffiliateTransaction_Inc_Input>;
  _set?: InputMaybe<Wui_AffiliateTransaction_Set_Input>;
  where: Wui_AffiliateTransaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AffiliateTransaction_By_PkArgs = {
  _inc?: InputMaybe<Wui_AffiliateTransaction_Inc_Input>;
  _set?: InputMaybe<Wui_AffiliateTransaction_Set_Input>;
  pk_columns: Wui_AffiliateTransaction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AffiliateTransaction_ManyArgs = {
  updates: Array<Wui_AffiliateTransaction_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AffiliateUsersArgs = {
  _inc?: InputMaybe<Wui_AffiliateUsers_Inc_Input>;
  _set?: InputMaybe<Wui_AffiliateUsers_Set_Input>;
  where: Wui_AffiliateUsers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AffiliateUsers_By_PkArgs = {
  _inc?: InputMaybe<Wui_AffiliateUsers_Inc_Input>;
  _set?: InputMaybe<Wui_AffiliateUsers_Set_Input>;
  pk_columns: Wui_AffiliateUsers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AffiliateUsers_ManyArgs = {
  updates: Array<Wui_AffiliateUsers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Affiliate_By_PkArgs = {
  _inc?: InputMaybe<Wui_Affiliate_Inc_Input>;
  _set?: InputMaybe<Wui_Affiliate_Set_Input>;
  pk_columns: Wui_Affiliate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Affiliate_ManyArgs = {
  updates: Array<Wui_Affiliate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AuthProviderArgs = {
  _inc?: InputMaybe<Wui_AuthProvider_Inc_Input>;
  _set?: InputMaybe<Wui_AuthProvider_Set_Input>;
  where: Wui_AuthProvider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AuthProvider_By_PkArgs = {
  _inc?: InputMaybe<Wui_AuthProvider_Inc_Input>;
  _set?: InputMaybe<Wui_AuthProvider_Set_Input>;
  pk_columns: Wui_AuthProvider_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AuthProvider_ManyArgs = {
  updates: Array<Wui_AuthProvider_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AuthSessionArgs = {
  _inc?: InputMaybe<Wui_AuthSession_Inc_Input>;
  _set?: InputMaybe<Wui_AuthSession_Set_Input>;
  where: Wui_AuthSession_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AuthSession_By_PkArgs = {
  _inc?: InputMaybe<Wui_AuthSession_Inc_Input>;
  _set?: InputMaybe<Wui_AuthSession_Set_Input>;
  pk_columns: Wui_AuthSession_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AuthSession_ManyArgs = {
  updates: Array<Wui_AuthSession_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomArgs = {
  _inc?: InputMaybe<Wui_ChatRoom_Inc_Input>;
  _set?: InputMaybe<Wui_ChatRoom_Set_Input>;
  where: Wui_ChatRoom_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomMessageArgs = {
  _append?: InputMaybe<Wui_ChatRoomMessage_Append_Input>;
  _delete_at_path?: InputMaybe<Wui_ChatRoomMessage_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Wui_ChatRoomMessage_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Wui_ChatRoomMessage_Delete_Key_Input>;
  _inc?: InputMaybe<Wui_ChatRoomMessage_Inc_Input>;
  _prepend?: InputMaybe<Wui_ChatRoomMessage_Prepend_Input>;
  _set?: InputMaybe<Wui_ChatRoomMessage_Set_Input>;
  where: Wui_ChatRoomMessage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomMessage_By_PkArgs = {
  _append?: InputMaybe<Wui_ChatRoomMessage_Append_Input>;
  _delete_at_path?: InputMaybe<Wui_ChatRoomMessage_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Wui_ChatRoomMessage_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Wui_ChatRoomMessage_Delete_Key_Input>;
  _inc?: InputMaybe<Wui_ChatRoomMessage_Inc_Input>;
  _prepend?: InputMaybe<Wui_ChatRoomMessage_Prepend_Input>;
  _set?: InputMaybe<Wui_ChatRoomMessage_Set_Input>;
  pk_columns: Wui_ChatRoomMessage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomMessage_ManyArgs = {
  updates: Array<Wui_ChatRoomMessage_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomOrderArgs = {
  _inc?: InputMaybe<Wui_ChatRoomOrder_Inc_Input>;
  _set?: InputMaybe<Wui_ChatRoomOrder_Set_Input>;
  where: Wui_ChatRoomOrder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomOrder_By_PkArgs = {
  _inc?: InputMaybe<Wui_ChatRoomOrder_Inc_Input>;
  _set?: InputMaybe<Wui_ChatRoomOrder_Set_Input>;
  pk_columns: Wui_ChatRoomOrder_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomOrder_ManyArgs = {
  updates: Array<Wui_ChatRoomOrder_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomTypeArgs = {
  _set?: InputMaybe<Wui_ChatRoomType_Set_Input>;
  where: Wui_ChatRoomType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomType_By_PkArgs = {
  _set?: InputMaybe<Wui_ChatRoomType_Set_Input>;
  pk_columns: Wui_ChatRoomType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomType_ManyArgs = {
  updates: Array<Wui_ChatRoomType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomUserArgs = {
  _inc?: InputMaybe<Wui_ChatRoomUser_Inc_Input>;
  _set?: InputMaybe<Wui_ChatRoomUser_Set_Input>;
  where: Wui_ChatRoomUser_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomUser_By_PkArgs = {
  _inc?: InputMaybe<Wui_ChatRoomUser_Inc_Input>;
  _set?: InputMaybe<Wui_ChatRoomUser_Set_Input>;
  pk_columns: Wui_ChatRoomUser_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomUser_ManyArgs = {
  updates: Array<Wui_ChatRoomUser_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoom_By_PkArgs = {
  _inc?: InputMaybe<Wui_ChatRoom_Inc_Input>;
  _set?: InputMaybe<Wui_ChatRoom_Set_Input>;
  pk_columns: Wui_ChatRoom_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoom_ManyArgs = {
  updates: Array<Wui_ChatRoom_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_FileArgs = {
  _inc?: InputMaybe<Wui_File_Inc_Input>;
  _set?: InputMaybe<Wui_File_Set_Input>;
  where: Wui_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_FileTypeArgs = {
  _set?: InputMaybe<Wui_FileType_Set_Input>;
  where: Wui_FileType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_FileType_By_PkArgs = {
  _set?: InputMaybe<Wui_FileType_Set_Input>;
  pk_columns: Wui_FileType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_FileType_ManyArgs = {
  updates: Array<Wui_FileType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_File_By_PkArgs = {
  _inc?: InputMaybe<Wui_File_Inc_Input>;
  _set?: InputMaybe<Wui_File_Set_Input>;
  pk_columns: Wui_File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_File_ManyArgs = {
  updates: Array<Wui_File_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_NotificationArgs = {
  _append?: InputMaybe<Wui_Notification_Append_Input>;
  _delete_at_path?: InputMaybe<Wui_Notification_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Wui_Notification_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Wui_Notification_Delete_Key_Input>;
  _inc?: InputMaybe<Wui_Notification_Inc_Input>;
  _prepend?: InputMaybe<Wui_Notification_Prepend_Input>;
  _set?: InputMaybe<Wui_Notification_Set_Input>;
  where: Wui_Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Notification_By_PkArgs = {
  _append?: InputMaybe<Wui_Notification_Append_Input>;
  _delete_at_path?: InputMaybe<Wui_Notification_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Wui_Notification_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Wui_Notification_Delete_Key_Input>;
  _inc?: InputMaybe<Wui_Notification_Inc_Input>;
  _prepend?: InputMaybe<Wui_Notification_Prepend_Input>;
  _set?: InputMaybe<Wui_Notification_Set_Input>;
  pk_columns: Wui_Notification_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Notification_ManyArgs = {
  updates: Array<Wui_Notification_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderArgs = {
  _append?: InputMaybe<Wui_Order_Append_Input>;
  _delete_at_path?: InputMaybe<Wui_Order_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Wui_Order_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Wui_Order_Delete_Key_Input>;
  _inc?: InputMaybe<Wui_Order_Inc_Input>;
  _prepend?: InputMaybe<Wui_Order_Prepend_Input>;
  _set?: InputMaybe<Wui_Order_Set_Input>;
  where: Wui_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderReviewArgs = {
  _inc?: InputMaybe<Wui_OrderReview_Inc_Input>;
  _set?: InputMaybe<Wui_OrderReview_Set_Input>;
  where: Wui_OrderReview_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderReview_By_PkArgs = {
  _inc?: InputMaybe<Wui_OrderReview_Inc_Input>;
  _set?: InputMaybe<Wui_OrderReview_Set_Input>;
  pk_columns: Wui_OrderReview_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderReview_ManyArgs = {
  updates: Array<Wui_OrderReview_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderStatusArgs = {
  _set?: InputMaybe<Wui_OrderStatus_Set_Input>;
  where: Wui_OrderStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderStatus_By_PkArgs = {
  _set?: InputMaybe<Wui_OrderStatus_Set_Input>;
  pk_columns: Wui_OrderStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderStatus_ManyArgs = {
  updates: Array<Wui_OrderStatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderSubscriptionArgs = {
  _inc?: InputMaybe<Wui_OrderSubscription_Inc_Input>;
  _set?: InputMaybe<Wui_OrderSubscription_Set_Input>;
  where: Wui_OrderSubscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderSubscriptionPeriodArgs = {
  _set?: InputMaybe<Wui_OrderSubscriptionPeriod_Set_Input>;
  where: Wui_OrderSubscriptionPeriod_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderSubscriptionPeriod_By_PkArgs = {
  _set?: InputMaybe<Wui_OrderSubscriptionPeriod_Set_Input>;
  pk_columns: Wui_OrderSubscriptionPeriod_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderSubscriptionPeriod_ManyArgs = {
  updates: Array<Wui_OrderSubscriptionPeriod_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderSubscriptionStatusArgs = {
  _set?: InputMaybe<Wui_OrderSubscriptionStatus_Set_Input>;
  where: Wui_OrderSubscriptionStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderSubscriptionStatus_By_PkArgs = {
  _set?: InputMaybe<Wui_OrderSubscriptionStatus_Set_Input>;
  pk_columns: Wui_OrderSubscriptionStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderSubscriptionStatus_ManyArgs = {
  updates: Array<Wui_OrderSubscriptionStatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderSubscription_By_PkArgs = {
  _inc?: InputMaybe<Wui_OrderSubscription_Inc_Input>;
  _set?: InputMaybe<Wui_OrderSubscription_Set_Input>;
  pk_columns: Wui_OrderSubscription_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderSubscription_ManyArgs = {
  updates: Array<Wui_OrderSubscription_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Order_By_PkArgs = {
  _append?: InputMaybe<Wui_Order_Append_Input>;
  _delete_at_path?: InputMaybe<Wui_Order_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Wui_Order_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Wui_Order_Delete_Key_Input>;
  _inc?: InputMaybe<Wui_Order_Inc_Input>;
  _prepend?: InputMaybe<Wui_Order_Prepend_Input>;
  _set?: InputMaybe<Wui_Order_Set_Input>;
  pk_columns: Wui_Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Order_ManyArgs = {
  updates: Array<Wui_Order_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PackageRequestArgs = {
  _inc?: InputMaybe<Wui_PackageRequest_Inc_Input>;
  _set?: InputMaybe<Wui_PackageRequest_Set_Input>;
  where: Wui_PackageRequest_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PackageRequest_By_PkArgs = {
  _inc?: InputMaybe<Wui_PackageRequest_Inc_Input>;
  _set?: InputMaybe<Wui_PackageRequest_Set_Input>;
  pk_columns: Wui_PackageRequest_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PackageRequest_ManyArgs = {
  updates: Array<Wui_PackageRequest_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentArgs = {
  _append?: InputMaybe<Wui_Payment_Append_Input>;
  _delete_at_path?: InputMaybe<Wui_Payment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Wui_Payment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Wui_Payment_Delete_Key_Input>;
  _inc?: InputMaybe<Wui_Payment_Inc_Input>;
  _prepend?: InputMaybe<Wui_Payment_Prepend_Input>;
  _set?: InputMaybe<Wui_Payment_Set_Input>;
  where: Wui_Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethodArgs = {
  _inc?: InputMaybe<Wui_PaymentMethod_Inc_Input>;
  _set?: InputMaybe<Wui_PaymentMethod_Set_Input>;
  where: Wui_PaymentMethod_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethodBankAccountArgs = {
  _inc?: InputMaybe<Wui_PaymentMethodBankAccount_Inc_Input>;
  _set?: InputMaybe<Wui_PaymentMethodBankAccount_Set_Input>;
  where: Wui_PaymentMethodBankAccount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethodBankAccount_By_PkArgs = {
  _inc?: InputMaybe<Wui_PaymentMethodBankAccount_Inc_Input>;
  _set?: InputMaybe<Wui_PaymentMethodBankAccount_Set_Input>;
  pk_columns: Wui_PaymentMethodBankAccount_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethodBankAccount_ManyArgs = {
  updates: Array<Wui_PaymentMethodBankAccount_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethodCardArgs = {
  _inc?: InputMaybe<Wui_PaymentMethodCard_Inc_Input>;
  _set?: InputMaybe<Wui_PaymentMethodCard_Set_Input>;
  where: Wui_PaymentMethodCard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethodCard_By_PkArgs = {
  _inc?: InputMaybe<Wui_PaymentMethodCard_Inc_Input>;
  _set?: InputMaybe<Wui_PaymentMethodCard_Set_Input>;
  pk_columns: Wui_PaymentMethodCard_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethodCard_ManyArgs = {
  updates: Array<Wui_PaymentMethodCard_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethodSourceArgs = {
  _set?: InputMaybe<Wui_PaymentMethodSource_Set_Input>;
  where: Wui_PaymentMethodSource_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethodSource_By_PkArgs = {
  _set?: InputMaybe<Wui_PaymentMethodSource_Set_Input>;
  pk_columns: Wui_PaymentMethodSource_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethodSource_ManyArgs = {
  updates: Array<Wui_PaymentMethodSource_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethod_By_PkArgs = {
  _inc?: InputMaybe<Wui_PaymentMethod_Inc_Input>;
  _set?: InputMaybe<Wui_PaymentMethod_Set_Input>;
  pk_columns: Wui_PaymentMethod_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethod_ManyArgs = {
  updates: Array<Wui_PaymentMethod_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentStatusArgs = {
  _set?: InputMaybe<Wui_PaymentStatus_Set_Input>;
  where: Wui_PaymentStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentStatus_By_PkArgs = {
  _set?: InputMaybe<Wui_PaymentStatus_Set_Input>;
  pk_columns: Wui_PaymentStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentStatus_ManyArgs = {
  updates: Array<Wui_PaymentStatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Payment_By_PkArgs = {
  _append?: InputMaybe<Wui_Payment_Append_Input>;
  _delete_at_path?: InputMaybe<Wui_Payment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Wui_Payment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Wui_Payment_Delete_Key_Input>;
  _inc?: InputMaybe<Wui_Payment_Inc_Input>;
  _prepend?: InputMaybe<Wui_Payment_Prepend_Input>;
  _set?: InputMaybe<Wui_Payment_Set_Input>;
  pk_columns: Wui_Payment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Payment_ManyArgs = {
  updates: Array<Wui_Payment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_RequestStatusArgs = {
  _set?: InputMaybe<Wui_RequestStatus_Set_Input>;
  where: Wui_RequestStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_RequestStatus_By_PkArgs = {
  _set?: InputMaybe<Wui_RequestStatus_Set_Input>;
  pk_columns: Wui_RequestStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_RequestStatus_ManyArgs = {
  updates: Array<Wui_RequestStatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_RoleArgs = {
  _inc?: InputMaybe<Wui_Role_Inc_Input>;
  _set?: InputMaybe<Wui_Role_Set_Input>;
  where: Wui_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Role_By_PkArgs = {
  _inc?: InputMaybe<Wui_Role_Inc_Input>;
  _set?: InputMaybe<Wui_Role_Set_Input>;
  pk_columns: Wui_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Role_ManyArgs = {
  updates: Array<Wui_Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerPackageArgs = {
  _append?: InputMaybe<Wui_SellerPackage_Append_Input>;
  _delete_at_path?: InputMaybe<Wui_SellerPackage_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Wui_SellerPackage_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Wui_SellerPackage_Delete_Key_Input>;
  _inc?: InputMaybe<Wui_SellerPackage_Inc_Input>;
  _prepend?: InputMaybe<Wui_SellerPackage_Prepend_Input>;
  _set?: InputMaybe<Wui_SellerPackage_Set_Input>;
  where: Wui_SellerPackage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerPackageItemArgs = {
  _inc?: InputMaybe<Wui_SellerPackageItem_Inc_Input>;
  _set?: InputMaybe<Wui_SellerPackageItem_Set_Input>;
  where: Wui_SellerPackageItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerPackageItem_By_PkArgs = {
  _inc?: InputMaybe<Wui_SellerPackageItem_Inc_Input>;
  _set?: InputMaybe<Wui_SellerPackageItem_Set_Input>;
  pk_columns: Wui_SellerPackageItem_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerPackageItem_ManyArgs = {
  updates: Array<Wui_SellerPackageItem_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerPackageTypeArgs = {
  _set?: InputMaybe<Wui_SellerPackageType_Set_Input>;
  where: Wui_SellerPackageType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerPackageType_By_PkArgs = {
  _set?: InputMaybe<Wui_SellerPackageType_Set_Input>;
  pk_columns: Wui_SellerPackageType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerPackageType_ManyArgs = {
  updates: Array<Wui_SellerPackageType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerPackage_By_PkArgs = {
  _append?: InputMaybe<Wui_SellerPackage_Append_Input>;
  _delete_at_path?: InputMaybe<Wui_SellerPackage_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Wui_SellerPackage_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Wui_SellerPackage_Delete_Key_Input>;
  _inc?: InputMaybe<Wui_SellerPackage_Inc_Input>;
  _prepend?: InputMaybe<Wui_SellerPackage_Prepend_Input>;
  _set?: InputMaybe<Wui_SellerPackage_Set_Input>;
  pk_columns: Wui_SellerPackage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerPackage_ManyArgs = {
  updates: Array<Wui_SellerPackage_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerProjectArgs = {
  _inc?: InputMaybe<Wui_SellerProject_Inc_Input>;
  _set?: InputMaybe<Wui_SellerProject_Set_Input>;
  where: Wui_SellerProject_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerProjectFileArgs = {
  _inc?: InputMaybe<Wui_SellerProjectFile_Inc_Input>;
  _set?: InputMaybe<Wui_SellerProjectFile_Set_Input>;
  where: Wui_SellerProjectFile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerProjectFile_By_PkArgs = {
  _inc?: InputMaybe<Wui_SellerProjectFile_Inc_Input>;
  _set?: InputMaybe<Wui_SellerProjectFile_Set_Input>;
  pk_columns: Wui_SellerProjectFile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerProjectFile_ManyArgs = {
  updates: Array<Wui_SellerProjectFile_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerProjectItemArgs = {
  _inc?: InputMaybe<Wui_SellerProjectItem_Inc_Input>;
  _set?: InputMaybe<Wui_SellerProjectItem_Set_Input>;
  where: Wui_SellerProjectItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerProjectItem_By_PkArgs = {
  _inc?: InputMaybe<Wui_SellerProjectItem_Inc_Input>;
  _set?: InputMaybe<Wui_SellerProjectItem_Set_Input>;
  pk_columns: Wui_SellerProjectItem_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerProjectItem_ManyArgs = {
  updates: Array<Wui_SellerProjectItem_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerProject_By_PkArgs = {
  _inc?: InputMaybe<Wui_SellerProject_Inc_Input>;
  _set?: InputMaybe<Wui_SellerProject_Set_Input>;
  pk_columns: Wui_SellerProject_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerProject_ManyArgs = {
  updates: Array<Wui_SellerProject_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SessionArgs = {
  _inc?: InputMaybe<Wui_Session_Inc_Input>;
  _set?: InputMaybe<Wui_Session_Set_Input>;
  where: Wui_Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SessionEventArgs = {
  _inc?: InputMaybe<Wui_SessionEvent_Inc_Input>;
  _set?: InputMaybe<Wui_SessionEvent_Set_Input>;
  where: Wui_SessionEvent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SessionEventTypeArgs = {
  _set?: InputMaybe<Wui_SessionEventType_Set_Input>;
  where: Wui_SessionEventType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SessionEventType_By_PkArgs = {
  _set?: InputMaybe<Wui_SessionEventType_Set_Input>;
  pk_columns: Wui_SessionEventType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SessionEventType_ManyArgs = {
  updates: Array<Wui_SessionEventType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SessionEvent_By_PkArgs = {
  _inc?: InputMaybe<Wui_SessionEvent_Inc_Input>;
  _set?: InputMaybe<Wui_SessionEvent_Set_Input>;
  pk_columns: Wui_SessionEvent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SessionEvent_ManyArgs = {
  updates: Array<Wui_SessionEvent_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Session_By_PkArgs = {
  _inc?: InputMaybe<Wui_Session_Inc_Input>;
  _set?: InputMaybe<Wui_Session_Set_Input>;
  pk_columns: Wui_Session_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Session_ManyArgs = {
  updates: Array<Wui_Session_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SocialMediaSourceArgs = {
  _set?: InputMaybe<Wui_SocialMediaSource_Set_Input>;
  where: Wui_SocialMediaSource_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SocialMediaSource_By_PkArgs = {
  _set?: InputMaybe<Wui_SocialMediaSource_Set_Input>;
  pk_columns: Wui_SocialMediaSource_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SocialMediaSource_ManyArgs = {
  updates: Array<Wui_SocialMediaSource_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SubscriptionPlanArgs = {
  _inc?: InputMaybe<Wui_SubscriptionPlan_Inc_Input>;
  _set?: InputMaybe<Wui_SubscriptionPlan_Set_Input>;
  where: Wui_SubscriptionPlan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SubscriptionPlan_By_PkArgs = {
  _inc?: InputMaybe<Wui_SubscriptionPlan_Inc_Input>;
  _set?: InputMaybe<Wui_SubscriptionPlan_Set_Input>;
  pk_columns: Wui_SubscriptionPlan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SubscriptionPlan_ManyArgs = {
  updates: Array<Wui_SubscriptionPlan_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserArgs = {
  _inc?: InputMaybe<Wui_User_Inc_Input>;
  _set?: InputMaybe<Wui_User_Set_Input>;
  where: Wui_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserFilePortfolioArgs = {
  _inc?: InputMaybe<Wui_UserFilePortfolio_Inc_Input>;
  _set?: InputMaybe<Wui_UserFilePortfolio_Set_Input>;
  where: Wui_UserFilePortfolio_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserFilePortfolio_By_PkArgs = {
  _inc?: InputMaybe<Wui_UserFilePortfolio_Inc_Input>;
  _set?: InputMaybe<Wui_UserFilePortfolio_Set_Input>;
  pk_columns: Wui_UserFilePortfolio_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserFilePortfolio_ManyArgs = {
  updates: Array<Wui_UserFilePortfolio_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserReviewArgs = {
  _append?: InputMaybe<Wui_UserReview_Append_Input>;
  _delete_at_path?: InputMaybe<Wui_UserReview_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Wui_UserReview_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Wui_UserReview_Delete_Key_Input>;
  _inc?: InputMaybe<Wui_UserReview_Inc_Input>;
  _prepend?: InputMaybe<Wui_UserReview_Prepend_Input>;
  _set?: InputMaybe<Wui_UserReview_Set_Input>;
  where: Wui_UserReview_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserReview_By_PkArgs = {
  _append?: InputMaybe<Wui_UserReview_Append_Input>;
  _delete_at_path?: InputMaybe<Wui_UserReview_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Wui_UserReview_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Wui_UserReview_Delete_Key_Input>;
  _inc?: InputMaybe<Wui_UserReview_Inc_Input>;
  _prepend?: InputMaybe<Wui_UserReview_Prepend_Input>;
  _set?: InputMaybe<Wui_UserReview_Set_Input>;
  pk_columns: Wui_UserReview_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserReview_ManyArgs = {
  updates: Array<Wui_UserReview_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserSubscriptionArgs = {
  _inc?: InputMaybe<Wui_UserSubscription_Inc_Input>;
  _set?: InputMaybe<Wui_UserSubscription_Set_Input>;
  where: Wui_UserSubscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserSubscription_By_PkArgs = {
  _inc?: InputMaybe<Wui_UserSubscription_Inc_Input>;
  _set?: InputMaybe<Wui_UserSubscription_Set_Input>;
  pk_columns: Wui_UserSubscription_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserSubscription_ManyArgs = {
  updates: Array<Wui_UserSubscription_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserTypeArgs = {
  _set?: InputMaybe<Wui_UserType_Set_Input>;
  where: Wui_UserType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserType_By_PkArgs = {
  _set?: InputMaybe<Wui_UserType_Set_Input>;
  pk_columns: Wui_UserType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserType_ManyArgs = {
  updates: Array<Wui_UserType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_User_By_PkArgs = {
  _inc?: InputMaybe<Wui_User_Inc_Input>;
  _set?: InputMaybe<Wui_User_Set_Input>;
  pk_columns: Wui_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_User_ManyArgs = {
  updates: Array<Wui_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_VerificationTokenArgs = {
  _set?: InputMaybe<Wui_VerificationToken_Set_Input>;
  where: Wui_VerificationToken_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_VerificationToken_By_PkArgs = {
  _set?: InputMaybe<Wui_VerificationToken_Set_Input>;
  pk_columns: Wui_VerificationToken_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_VerificationToken_ManyArgs = {
  updates: Array<Wui_VerificationToken_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Unclaimed_DemographicsArgs = {
  _inc?: InputMaybe<Unclaimed_Demographics_Inc_Input>;
  _set?: InputMaybe<Unclaimed_Demographics_Set_Input>;
  where: Unclaimed_Demographics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Unclaimed_Demographics_By_PkArgs = {
  _inc?: InputMaybe<Unclaimed_Demographics_Inc_Input>;
  _set?: InputMaybe<Unclaimed_Demographics_Set_Input>;
  pk_columns: Unclaimed_Demographics_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Unclaimed_Demographics_ManyArgs = {
  updates: Array<Unclaimed_Demographics_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Unclaimed_ProfileArgs = {
  _inc?: InputMaybe<Unclaimed_Profile_Inc_Input>;
  _set?: InputMaybe<Unclaimed_Profile_Set_Input>;
  where: Unclaimed_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Unclaimed_Profile_By_PkArgs = {
  _inc?: InputMaybe<Unclaimed_Profile_Inc_Input>;
  _set?: InputMaybe<Unclaimed_Profile_Set_Input>;
  pk_columns: Unclaimed_Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Unclaimed_Profile_ManyArgs = {
  updates: Array<Unclaimed_Profile_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserlastseenArgs = {
  _inc?: InputMaybe<Wui_Userlastseen_Inc_Input>;
  _set?: InputMaybe<Wui_Userlastseen_Set_Input>;
  where: Wui_Userlastseen_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Userlastseen_ManyArgs = {
  updates: Array<Wui_Userlastseen_Updates>;
};


/** mutation root */
export type Mutation_RootUploadCoverPicture_Wui_UserArgs = {
  imageBase64: Scalars['String'];
  imageType: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUploadPicture_HeroBannerArgs = {
  heroID: Scalars['bigint'];
  imageBase64: Scalars['String'];
  imageType: Scalars['String'];
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUploadPicture_Wui_ChatRoomMessageArgs = {
  imageBase64: Scalars['String'];
  imageType: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUploadPicture_Wui_ProjectFileArgs = {
  imageBase64: Scalars['String'];
  imageType: Scalars['String'];
  projectId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootUploadPicture_Wui_UseFilePortfolioArgs = {
  imageBase64: Scalars['String'];
  imageType: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUploadProfilePicture_Wui_UserArgs = {
  imageBase64: Scalars['String'];
  imageType: Scalars['String'];
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "Ambassador" */
  Ambassador: Array<Ambassador>;
  /** fetch aggregated fields from the table: "Ambassador" */
  Ambassador_aggregate: Ambassador_Aggregate;
  /** fetch data from the table: "Ambassador" using primary key columns */
  Ambassador_by_pk?: Maybe<Ambassador>;
  /** fetch data from the table: "Demographic" */
  Demographic: Array<Demographic>;
  /** fetch aggregated fields from the table: "Demographic" */
  Demographic_aggregate: Demographic_Aggregate;
  /** fetch data from the table: "Demographic" using primary key columns */
  Demographic_by_pk?: Maybe<Demographic>;
  /** fetch data from the table: "EmailDomainLookup" */
  EmailDomainLookup: Array<EmailDomainLookup>;
  /** fetch aggregated fields from the table: "EmailDomainLookup" */
  EmailDomainLookup_aggregate: EmailDomainLookup_Aggregate;
  /** fetch data from the table: "EmailDomainLookup" using primary key columns */
  EmailDomainLookup_by_pk?: Maybe<EmailDomainLookup>;
  /** fetch data from the table: "HeroBanner" */
  HeroBanner: Array<HeroBanner>;
  /** fetch aggregated fields from the table: "HeroBanner" */
  HeroBanner_aggregate: HeroBanner_Aggregate;
  /** fetch data from the table: "HeroBanner" using primary key columns */
  HeroBanner_by_pk?: Maybe<HeroBanner>;
  /** fetch data from the table: "Industry" */
  Industry: Array<Industry>;
  /** fetch aggregated fields from the table: "Industry" */
  Industry_aggregate: Industry_Aggregate;
  /** fetch data from the table: "Industry" using primary key columns */
  Industry_by_pk?: Maybe<Industry>;
  /** fetch data from the table: "MarketplaceWaitlist" */
  MarketplaceWaitlist: Array<MarketplaceWaitlist>;
  /** fetch aggregated fields from the table: "MarketplaceWaitlist" */
  MarketplaceWaitlist_aggregate: MarketplaceWaitlist_Aggregate;
  /** fetch data from the table: "MarketplaceWaitlist" using primary key columns */
  MarketplaceWaitlist_by_pk?: Maybe<MarketplaceWaitlist>;
  /** fetch data from the table: "PlatformAlert" */
  PlatformAlert: Array<PlatformAlert>;
  /** fetch aggregated fields from the table: "PlatformAlert" */
  PlatformAlert_aggregate: PlatformAlert_Aggregate;
  /** fetch data from the table: "PlatformAlert" using primary key columns */
  PlatformAlert_by_pk?: Maybe<PlatformAlert>;
  /** fetch data from the table: "ProjectDemographic" */
  ProjectDemographic: Array<ProjectDemographic>;
  /** fetch aggregated fields from the table: "ProjectDemographic" */
  ProjectDemographic_aggregate: ProjectDemographic_Aggregate;
  /** fetch data from the table: "ProjectDemographic" using primary key columns */
  ProjectDemographic_by_pk?: Maybe<ProjectDemographic>;
  /** fetch data from the table: "SignupSource" */
  SignupSource: Array<SignupSource>;
  /** fetch data from the table: "SignupSourceTracker" */
  SignupSourceTracker: Array<SignupSourceTracker>;
  /** fetch aggregated fields from the table: "SignupSourceTracker" */
  SignupSourceTracker_aggregate: SignupSourceTracker_Aggregate;
  /** fetch data from the table: "SignupSourceTracker" using primary key columns */
  SignupSourceTracker_by_pk?: Maybe<SignupSourceTracker>;
  /** fetch aggregated fields from the table: "SignupSource" */
  SignupSource_aggregate: SignupSource_Aggregate;
  /** fetch data from the table: "SignupSource" using primary key columns */
  SignupSource_by_pk?: Maybe<SignupSource>;
  /** fetch data from the table: "SocialMedia" */
  SocialMedia: Array<SocialMedia>;
  /** fetch data from the table: "SocialMediaDemographic" */
  SocialMediaDemographic: Array<SocialMediaDemographic>;
  /** fetch aggregated fields from the table: "SocialMediaDemographic" */
  SocialMediaDemographic_aggregate: SocialMediaDemographic_Aggregate;
  /** fetch data from the table: "SocialMediaDemographic" using primary key columns */
  SocialMediaDemographic_by_pk?: Maybe<SocialMediaDemographic>;
  /** fetch data from the table: "SocialMediaHistory" */
  SocialMediaHistory: Array<SocialMediaHistory>;
  /** fetch aggregated fields from the table: "SocialMediaHistory" */
  SocialMediaHistory_aggregate: SocialMediaHistory_Aggregate;
  /** fetch data from the table: "SocialMediaHistory" using primary key columns */
  SocialMediaHistory_by_pk?: Maybe<SocialMediaHistory>;
  /** fetch data from the table: "SocialMediaLink" */
  SocialMediaLink: Array<SocialMediaLink>;
  /** fetch aggregated fields from the table: "SocialMediaLink" */
  SocialMediaLink_aggregate: SocialMediaLink_Aggregate;
  /** fetch data from the table: "SocialMediaLink" using primary key columns */
  SocialMediaLink_by_pk?: Maybe<SocialMediaLink>;
  /** fetch data from the table: "SocialMediaPlatform" */
  SocialMediaPlatform: Array<SocialMediaPlatform>;
  /** fetch aggregated fields from the table: "SocialMediaPlatform" */
  SocialMediaPlatform_aggregate: SocialMediaPlatform_Aggregate;
  /** fetch data from the table: "SocialMediaPlatform" using primary key columns */
  SocialMediaPlatform_by_pk?: Maybe<SocialMediaPlatform>;
  /** fetch aggregated fields from the table: "SocialMedia" */
  SocialMedia_aggregate: SocialMedia_Aggregate;
  /** fetch data from the table: "SocialMedia" using primary key columns */
  SocialMedia_by_pk?: Maybe<SocialMedia>;
  /** fetch data from the table: "TempLoginWithPassword" */
  TempLoginWithPassword: Array<TempLoginWithPassword>;
  /** fetch aggregated fields from the table: "TempLoginWithPassword" */
  TempLoginWithPassword_aggregate: TempLoginWithPassword_Aggregate;
  /** fetch data from the table: "TempLoginWithPassword" using primary key columns */
  TempLoginWithPassword_by_pk?: Maybe<TempLoginWithPassword>;
  /** fetch data from the table: "UserVideo" */
  UserVideo: Array<UserVideo>;
  /** fetch aggregated fields from the table: "UserVideo" */
  UserVideo_aggregate: UserVideo_Aggregate;
  /** fetch data from the table: "UserVideo" using primary key columns */
  UserVideo_by_pk?: Maybe<UserVideo>;
  /** fetch data from the table: "Vote" */
  Vote: Array<Vote>;
  /** fetch data from the table: "VoteCategory" */
  VoteCategory: Array<VoteCategory>;
  /** fetch aggregated fields from the table: "VoteCategory" */
  VoteCategory_aggregate: VoteCategory_Aggregate;
  /** fetch data from the table: "VoteCategory" using primary key columns */
  VoteCategory_by_pk?: Maybe<VoteCategory>;
  /** fetch data from the table: "VoteUser" */
  VoteUser: Array<VoteUser>;
  /** fetch data from the table: "VoteUserCategory" */
  VoteUserCategory: Array<VoteUserCategory>;
  /** fetch aggregated fields from the table: "VoteUserCategory" */
  VoteUserCategory_aggregate: VoteUserCategory_Aggregate;
  /** fetch data from the table: "VoteUserCategory" using primary key columns */
  VoteUserCategory_by_pk?: Maybe<VoteUserCategory>;
  /** fetch aggregated fields from the table: "VoteUser" */
  VoteUser_aggregate: VoteUser_Aggregate;
  /** fetch data from the table: "VoteUser" using primary key columns */
  VoteUser_by_pk?: Maybe<VoteUser>;
  /** fetch aggregated fields from the table: "Vote" */
  Vote_aggregate: Vote_Aggregate;
  /** fetch data from the table: "Vote" using primary key columns */
  Vote_by_pk?: Maybe<Vote>;
  /** fetch data from the table: "VotesByUserCategoryId" */
  VotesByUserCategoryId: Array<VotesByUserCategoryId>;
  /** fetch aggregated fields from the table: "VotesByUserCategoryId" */
  VotesByUserCategoryId_aggregate: VotesByUserCategoryId_Aggregate;
  /** fetch data from the table: "WUI_Account" */
  WUI_Account: Array<Wui_Account>;
  /** fetch aggregated fields from the table: "WUI_Account" */
  WUI_Account_aggregate: Wui_Account_Aggregate;
  /** fetch data from the table: "WUI_Account" using primary key columns */
  WUI_Account_by_pk?: Maybe<Wui_Account>;
  /** fetch data from the table: "WUI_Affiliate" */
  WUI_Affiliate: Array<Wui_Affiliate>;
  /** fetch data from the table: "WUI_AffiliateLevel" */
  WUI_AffiliateLevel: Array<Wui_AffiliateLevel>;
  /** fetch aggregated fields from the table: "WUI_AffiliateLevel" */
  WUI_AffiliateLevel_aggregate: Wui_AffiliateLevel_Aggregate;
  /** fetch data from the table: "WUI_AffiliateLevel" using primary key columns */
  WUI_AffiliateLevel_by_pk?: Maybe<Wui_AffiliateLevel>;
  /** fetch data from the table: "WUI_AffiliateStatus" */
  WUI_AffiliateStatus: Array<Wui_AffiliateStatus>;
  /** fetch aggregated fields from the table: "WUI_AffiliateStatus" */
  WUI_AffiliateStatus_aggregate: Wui_AffiliateStatus_Aggregate;
  /** fetch data from the table: "WUI_AffiliateStatus" using primary key columns */
  WUI_AffiliateStatus_by_pk?: Maybe<Wui_AffiliateStatus>;
  /** fetch data from the table: "WUI_AffiliateTransaction" */
  WUI_AffiliateTransaction: Array<Wui_AffiliateTransaction>;
  /** fetch aggregated fields from the table: "WUI_AffiliateTransaction" */
  WUI_AffiliateTransaction_aggregate: Wui_AffiliateTransaction_Aggregate;
  /** fetch data from the table: "WUI_AffiliateTransaction" using primary key columns */
  WUI_AffiliateTransaction_by_pk?: Maybe<Wui_AffiliateTransaction>;
  /** fetch data from the table: "WUI_AffiliateUsers" */
  WUI_AffiliateUsers: Array<Wui_AffiliateUsers>;
  /** fetch aggregated fields from the table: "WUI_AffiliateUsers" */
  WUI_AffiliateUsers_aggregate: Wui_AffiliateUsers_Aggregate;
  /** fetch data from the table: "WUI_AffiliateUsers" using primary key columns */
  WUI_AffiliateUsers_by_pk?: Maybe<Wui_AffiliateUsers>;
  /** fetch aggregated fields from the table: "WUI_Affiliate" */
  WUI_Affiliate_aggregate: Wui_Affiliate_Aggregate;
  /** fetch data from the table: "WUI_Affiliate" using primary key columns */
  WUI_Affiliate_by_pk?: Maybe<Wui_Affiliate>;
  /** fetch data from the table: "WUI_AuthProvider" */
  WUI_AuthProvider: Array<Wui_AuthProvider>;
  /** fetch aggregated fields from the table: "WUI_AuthProvider" */
  WUI_AuthProvider_aggregate: Wui_AuthProvider_Aggregate;
  /** fetch data from the table: "WUI_AuthProvider" using primary key columns */
  WUI_AuthProvider_by_pk?: Maybe<Wui_AuthProvider>;
  /** fetch data from the table: "WUI_AuthSession" */
  WUI_AuthSession: Array<Wui_AuthSession>;
  /** fetch aggregated fields from the table: "WUI_AuthSession" */
  WUI_AuthSession_aggregate: Wui_AuthSession_Aggregate;
  /** fetch data from the table: "WUI_AuthSession" using primary key columns */
  WUI_AuthSession_by_pk?: Maybe<Wui_AuthSession>;
  /** fetch data from the table: "WUI_ChatRoom" */
  WUI_ChatRoom: Array<Wui_ChatRoom>;
  /** fetch data from the table: "WUI_ChatRoomMessage" */
  WUI_ChatRoomMessage: Array<Wui_ChatRoomMessage>;
  /** fetch aggregated fields from the table: "WUI_ChatRoomMessage" */
  WUI_ChatRoomMessage_aggregate: Wui_ChatRoomMessage_Aggregate;
  /** fetch data from the table: "WUI_ChatRoomMessage" using primary key columns */
  WUI_ChatRoomMessage_by_pk?: Maybe<Wui_ChatRoomMessage>;
  /** fetch data from the table: "WUI_ChatRoomOrder" */
  WUI_ChatRoomOrder: Array<Wui_ChatRoomOrder>;
  /** fetch aggregated fields from the table: "WUI_ChatRoomOrder" */
  WUI_ChatRoomOrder_aggregate: Wui_ChatRoomOrder_Aggregate;
  /** fetch data from the table: "WUI_ChatRoomOrder" using primary key columns */
  WUI_ChatRoomOrder_by_pk?: Maybe<Wui_ChatRoomOrder>;
  /** fetch data from the table: "WUI_ChatRoomType" */
  WUI_ChatRoomType: Array<Wui_ChatRoomType>;
  /** fetch aggregated fields from the table: "WUI_ChatRoomType" */
  WUI_ChatRoomType_aggregate: Wui_ChatRoomType_Aggregate;
  /** fetch data from the table: "WUI_ChatRoomType" using primary key columns */
  WUI_ChatRoomType_by_pk?: Maybe<Wui_ChatRoomType>;
  /** fetch data from the table: "WUI_ChatRoomUser" */
  WUI_ChatRoomUser: Array<Wui_ChatRoomUser>;
  /** fetch aggregated fields from the table: "WUI_ChatRoomUser" */
  WUI_ChatRoomUser_aggregate: Wui_ChatRoomUser_Aggregate;
  /** fetch data from the table: "WUI_ChatRoomUser" using primary key columns */
  WUI_ChatRoomUser_by_pk?: Maybe<Wui_ChatRoomUser>;
  /** fetch aggregated fields from the table: "WUI_ChatRoom" */
  WUI_ChatRoom_aggregate: Wui_ChatRoom_Aggregate;
  /** fetch data from the table: "WUI_ChatRoom" using primary key columns */
  WUI_ChatRoom_by_pk?: Maybe<Wui_ChatRoom>;
  /** fetch data from the table: "WUI_File" */
  WUI_File: Array<Wui_File>;
  /** fetch data from the table: "WUI_FileType" */
  WUI_FileType: Array<Wui_FileType>;
  /** fetch aggregated fields from the table: "WUI_FileType" */
  WUI_FileType_aggregate: Wui_FileType_Aggregate;
  /** fetch data from the table: "WUI_FileType" using primary key columns */
  WUI_FileType_by_pk?: Maybe<Wui_FileType>;
  /** fetch aggregated fields from the table: "WUI_File" */
  WUI_File_aggregate: Wui_File_Aggregate;
  /** fetch data from the table: "WUI_File" using primary key columns */
  WUI_File_by_pk?: Maybe<Wui_File>;
  /** An array relationship */
  WUI_Notification: Array<Wui_Notification>;
  /** An aggregate relationship */
  WUI_Notification_aggregate: Wui_Notification_Aggregate;
  /** fetch data from the table: "WUI_Notification" using primary key columns */
  WUI_Notification_by_pk?: Maybe<Wui_Notification>;
  /** fetch data from the table: "WUI_Order" */
  WUI_Order: Array<Wui_Order>;
  /** fetch data from the table: "WUI_OrderReview" */
  WUI_OrderReview: Array<Wui_OrderReview>;
  /** fetch aggregated fields from the table: "WUI_OrderReview" */
  WUI_OrderReview_aggregate: Wui_OrderReview_Aggregate;
  /** fetch data from the table: "WUI_OrderReview" using primary key columns */
  WUI_OrderReview_by_pk?: Maybe<Wui_OrderReview>;
  /** fetch data from the table: "WUI_OrderStatus" */
  WUI_OrderStatus: Array<Wui_OrderStatus>;
  /** fetch aggregated fields from the table: "WUI_OrderStatus" */
  WUI_OrderStatus_aggregate: Wui_OrderStatus_Aggregate;
  /** fetch data from the table: "WUI_OrderStatus" using primary key columns */
  WUI_OrderStatus_by_pk?: Maybe<Wui_OrderStatus>;
  /** fetch data from the table: "WUI_OrderSubscription" */
  WUI_OrderSubscription: Array<Wui_OrderSubscription>;
  /** fetch data from the table: "WUI_OrderSubscriptionPeriod" */
  WUI_OrderSubscriptionPeriod: Array<Wui_OrderSubscriptionPeriod>;
  /** fetch aggregated fields from the table: "WUI_OrderSubscriptionPeriod" */
  WUI_OrderSubscriptionPeriod_aggregate: Wui_OrderSubscriptionPeriod_Aggregate;
  /** fetch data from the table: "WUI_OrderSubscriptionPeriod" using primary key columns */
  WUI_OrderSubscriptionPeriod_by_pk?: Maybe<Wui_OrderSubscriptionPeriod>;
  /** fetch data from the table: "WUI_OrderSubscriptionStatus" */
  WUI_OrderSubscriptionStatus: Array<Wui_OrderSubscriptionStatus>;
  /** fetch aggregated fields from the table: "WUI_OrderSubscriptionStatus" */
  WUI_OrderSubscriptionStatus_aggregate: Wui_OrderSubscriptionStatus_Aggregate;
  /** fetch data from the table: "WUI_OrderSubscriptionStatus" using primary key columns */
  WUI_OrderSubscriptionStatus_by_pk?: Maybe<Wui_OrderSubscriptionStatus>;
  /** fetch aggregated fields from the table: "WUI_OrderSubscription" */
  WUI_OrderSubscription_aggregate: Wui_OrderSubscription_Aggregate;
  /** fetch data from the table: "WUI_OrderSubscription" using primary key columns */
  WUI_OrderSubscription_by_pk?: Maybe<Wui_OrderSubscription>;
  /** fetch aggregated fields from the table: "WUI_Order" */
  WUI_Order_aggregate: Wui_Order_Aggregate;
  /** fetch data from the table: "WUI_Order" using primary key columns */
  WUI_Order_by_pk?: Maybe<Wui_Order>;
  /** fetch data from the table: "WUI_PackageRequest" */
  WUI_PackageRequest: Array<Wui_PackageRequest>;
  /** fetch aggregated fields from the table: "WUI_PackageRequest" */
  WUI_PackageRequest_aggregate: Wui_PackageRequest_Aggregate;
  /** fetch data from the table: "WUI_PackageRequest" using primary key columns */
  WUI_PackageRequest_by_pk?: Maybe<Wui_PackageRequest>;
  /** fetch data from the table: "WUI_Payment" */
  WUI_Payment: Array<Wui_Payment>;
  /** fetch data from the table: "WUI_PaymentMethod" */
  WUI_PaymentMethod: Array<Wui_PaymentMethod>;
  /** fetch data from the table: "WUI_PaymentMethodBankAccount" */
  WUI_PaymentMethodBankAccount: Array<Wui_PaymentMethodBankAccount>;
  /** fetch aggregated fields from the table: "WUI_PaymentMethodBankAccount" */
  WUI_PaymentMethodBankAccount_aggregate: Wui_PaymentMethodBankAccount_Aggregate;
  /** fetch data from the table: "WUI_PaymentMethodBankAccount" using primary key columns */
  WUI_PaymentMethodBankAccount_by_pk?: Maybe<Wui_PaymentMethodBankAccount>;
  /** fetch data from the table: "WUI_PaymentMethodCard" */
  WUI_PaymentMethodCard: Array<Wui_PaymentMethodCard>;
  /** fetch aggregated fields from the table: "WUI_PaymentMethodCard" */
  WUI_PaymentMethodCard_aggregate: Wui_PaymentMethodCard_Aggregate;
  /** fetch data from the table: "WUI_PaymentMethodCard" using primary key columns */
  WUI_PaymentMethodCard_by_pk?: Maybe<Wui_PaymentMethodCard>;
  /** fetch data from the table: "WUI_PaymentMethodSource" */
  WUI_PaymentMethodSource: Array<Wui_PaymentMethodSource>;
  /** fetch aggregated fields from the table: "WUI_PaymentMethodSource" */
  WUI_PaymentMethodSource_aggregate: Wui_PaymentMethodSource_Aggregate;
  /** fetch data from the table: "WUI_PaymentMethodSource" using primary key columns */
  WUI_PaymentMethodSource_by_pk?: Maybe<Wui_PaymentMethodSource>;
  /** fetch aggregated fields from the table: "WUI_PaymentMethod" */
  WUI_PaymentMethod_aggregate: Wui_PaymentMethod_Aggregate;
  /** fetch data from the table: "WUI_PaymentMethod" using primary key columns */
  WUI_PaymentMethod_by_pk?: Maybe<Wui_PaymentMethod>;
  /** fetch data from the table: "WUI_PaymentStatus" */
  WUI_PaymentStatus: Array<Wui_PaymentStatus>;
  /** fetch aggregated fields from the table: "WUI_PaymentStatus" */
  WUI_PaymentStatus_aggregate: Wui_PaymentStatus_Aggregate;
  /** fetch data from the table: "WUI_PaymentStatus" using primary key columns */
  WUI_PaymentStatus_by_pk?: Maybe<Wui_PaymentStatus>;
  /** fetch aggregated fields from the table: "WUI_Payment" */
  WUI_Payment_aggregate: Wui_Payment_Aggregate;
  /** fetch data from the table: "WUI_Payment" using primary key columns */
  WUI_Payment_by_pk?: Maybe<Wui_Payment>;
  /** fetch data from the table: "WUI_RequestStatus" */
  WUI_RequestStatus: Array<Wui_RequestStatus>;
  /** fetch aggregated fields from the table: "WUI_RequestStatus" */
  WUI_RequestStatus_aggregate: Wui_RequestStatus_Aggregate;
  /** fetch data from the table: "WUI_RequestStatus" using primary key columns */
  WUI_RequestStatus_by_pk?: Maybe<Wui_RequestStatus>;
  /** fetch data from the table: "WUI_Role" */
  WUI_Role: Array<Wui_Role>;
  /** fetch aggregated fields from the table: "WUI_Role" */
  WUI_Role_aggregate: Wui_Role_Aggregate;
  /** fetch data from the table: "WUI_Role" using primary key columns */
  WUI_Role_by_pk?: Maybe<Wui_Role>;
  /** fetch data from the table: "WUI_SellerPackage" */
  WUI_SellerPackage: Array<Wui_SellerPackage>;
  /** fetch data from the table: "WUI_SellerPackageItem" */
  WUI_SellerPackageItem: Array<Wui_SellerPackageItem>;
  /** fetch aggregated fields from the table: "WUI_SellerPackageItem" */
  WUI_SellerPackageItem_aggregate: Wui_SellerPackageItem_Aggregate;
  /** fetch data from the table: "WUI_SellerPackageItem" using primary key columns */
  WUI_SellerPackageItem_by_pk?: Maybe<Wui_SellerPackageItem>;
  /** fetch data from the table: "WUI_SellerPackageType" */
  WUI_SellerPackageType: Array<Wui_SellerPackageType>;
  /** fetch aggregated fields from the table: "WUI_SellerPackageType" */
  WUI_SellerPackageType_aggregate: Wui_SellerPackageType_Aggregate;
  /** fetch data from the table: "WUI_SellerPackageType" using primary key columns */
  WUI_SellerPackageType_by_pk?: Maybe<Wui_SellerPackageType>;
  /** fetch aggregated fields from the table: "WUI_SellerPackage" */
  WUI_SellerPackage_aggregate: Wui_SellerPackage_Aggregate;
  /** fetch data from the table: "WUI_SellerPackage" using primary key columns */
  WUI_SellerPackage_by_pk?: Maybe<Wui_SellerPackage>;
  /** fetch data from the table: "WUI_SellerProject" */
  WUI_SellerProject: Array<Wui_SellerProject>;
  /** fetch data from the table: "WUI_SellerProjectFile" */
  WUI_SellerProjectFile: Array<Wui_SellerProjectFile>;
  /** fetch aggregated fields from the table: "WUI_SellerProjectFile" */
  WUI_SellerProjectFile_aggregate: Wui_SellerProjectFile_Aggregate;
  /** fetch data from the table: "WUI_SellerProjectFile" using primary key columns */
  WUI_SellerProjectFile_by_pk?: Maybe<Wui_SellerProjectFile>;
  /** fetch data from the table: "WUI_SellerProjectItem" */
  WUI_SellerProjectItem: Array<Wui_SellerProjectItem>;
  /** fetch aggregated fields from the table: "WUI_SellerProjectItem" */
  WUI_SellerProjectItem_aggregate: Wui_SellerProjectItem_Aggregate;
  /** fetch data from the table: "WUI_SellerProjectItem" using primary key columns */
  WUI_SellerProjectItem_by_pk?: Maybe<Wui_SellerProjectItem>;
  /** fetch aggregated fields from the table: "WUI_SellerProject" */
  WUI_SellerProject_aggregate: Wui_SellerProject_Aggregate;
  /** fetch data from the table: "WUI_SellerProject" using primary key columns */
  WUI_SellerProject_by_pk?: Maybe<Wui_SellerProject>;
  /** fetch data from the table: "WUI_Session" */
  WUI_Session: Array<Wui_Session>;
  /** fetch data from the table: "WUI_SessionEvent" */
  WUI_SessionEvent: Array<Wui_SessionEvent>;
  /** fetch data from the table: "WUI_SessionEventType" */
  WUI_SessionEventType: Array<Wui_SessionEventType>;
  /** fetch aggregated fields from the table: "WUI_SessionEventType" */
  WUI_SessionEventType_aggregate: Wui_SessionEventType_Aggregate;
  /** fetch data from the table: "WUI_SessionEventType" using primary key columns */
  WUI_SessionEventType_by_pk?: Maybe<Wui_SessionEventType>;
  /** fetch aggregated fields from the table: "WUI_SessionEvent" */
  WUI_SessionEvent_aggregate: Wui_SessionEvent_Aggregate;
  /** fetch data from the table: "WUI_SessionEvent" using primary key columns */
  WUI_SessionEvent_by_pk?: Maybe<Wui_SessionEvent>;
  /** fetch aggregated fields from the table: "WUI_Session" */
  WUI_Session_aggregate: Wui_Session_Aggregate;
  /** fetch data from the table: "WUI_Session" using primary key columns */
  WUI_Session_by_pk?: Maybe<Wui_Session>;
  /** fetch data from the table: "WUI_SocialMediaSource" */
  WUI_SocialMediaSource: Array<Wui_SocialMediaSource>;
  /** fetch aggregated fields from the table: "WUI_SocialMediaSource" */
  WUI_SocialMediaSource_aggregate: Wui_SocialMediaSource_Aggregate;
  /** fetch data from the table: "WUI_SocialMediaSource" using primary key columns */
  WUI_SocialMediaSource_by_pk?: Maybe<Wui_SocialMediaSource>;
  /** fetch data from the table: "WUI_SubscriptionPlan" */
  WUI_SubscriptionPlan: Array<Wui_SubscriptionPlan>;
  /** fetch aggregated fields from the table: "WUI_SubscriptionPlan" */
  WUI_SubscriptionPlan_aggregate: Wui_SubscriptionPlan_Aggregate;
  /** fetch data from the table: "WUI_SubscriptionPlan" using primary key columns */
  WUI_SubscriptionPlan_by_pk?: Maybe<Wui_SubscriptionPlan>;
  /** fetch data from the table: "WUI_User" */
  WUI_User: Array<Wui_User>;
  /** fetch data from the table: "WUI_UserFilePortfolio" */
  WUI_UserFilePortfolio: Array<Wui_UserFilePortfolio>;
  /** fetch aggregated fields from the table: "WUI_UserFilePortfolio" */
  WUI_UserFilePortfolio_aggregate: Wui_UserFilePortfolio_Aggregate;
  /** fetch data from the table: "WUI_UserFilePortfolio" using primary key columns */
  WUI_UserFilePortfolio_by_pk?: Maybe<Wui_UserFilePortfolio>;
  /** fetch data from the table: "WUI_UserReview" */
  WUI_UserReview: Array<Wui_UserReview>;
  /** fetch aggregated fields from the table: "WUI_UserReview" */
  WUI_UserReview_aggregate: Wui_UserReview_Aggregate;
  /** fetch data from the table: "WUI_UserReview" using primary key columns */
  WUI_UserReview_by_pk?: Maybe<Wui_UserReview>;
  /** fetch data from the table: "WUI_UserRole" */
  WUI_UserRole: Array<Wui_UserRole>;
  /** fetch aggregated fields from the table: "WUI_UserRole" */
  WUI_UserRole_aggregate: Wui_UserRole_Aggregate;
  /** fetch data from the table: "WUI_UserSubscription" */
  WUI_UserSubscription: Array<Wui_UserSubscription>;
  /** fetch aggregated fields from the table: "WUI_UserSubscription" */
  WUI_UserSubscription_aggregate: Wui_UserSubscription_Aggregate;
  /** fetch data from the table: "WUI_UserSubscription" using primary key columns */
  WUI_UserSubscription_by_pk?: Maybe<Wui_UserSubscription>;
  /** fetch data from the table: "WUI_UserType" */
  WUI_UserType: Array<Wui_UserType>;
  /** fetch aggregated fields from the table: "WUI_UserType" */
  WUI_UserType_aggregate: Wui_UserType_Aggregate;
  /** fetch data from the table: "WUI_UserType" using primary key columns */
  WUI_UserType_by_pk?: Maybe<Wui_UserType>;
  /** fetch aggregated fields from the table: "WUI_User" */
  WUI_User_aggregate: Wui_User_Aggregate;
  /** fetch data from the table: "WUI_User" using primary key columns */
  WUI_User_by_pk?: Maybe<Wui_User>;
  /** fetch data from the table: "WUI_VerificationToken" */
  WUI_VerificationToken: Array<Wui_VerificationToken>;
  /** fetch aggregated fields from the table: "WUI_VerificationToken" */
  WUI_VerificationToken_aggregate: Wui_VerificationToken_Aggregate;
  /** fetch data from the table: "WUI_VerificationToken" using primary key columns */
  WUI_VerificationToken_by_pk?: Maybe<Wui_VerificationToken>;
  getBankAccounts: Array<BankAccountOutput>;
  getCountrySpecs: CountrySpecsOutput;
  getCountrySupported: CountrySupportedOutput;
  legalInformation: LegalInformationOutput;
  searchInfluencers: Array<Maybe<SearchInfluencersOutput>>;
  shared_MissingAccountItems?: Maybe<MissingAccountItemsOutput>;
  /** fetch data from the table: "unclaimed_demographics" */
  unclaimed_demographics: Array<Unclaimed_Demographics>;
  /** fetch aggregated fields from the table: "unclaimed_demographics" */
  unclaimed_demographics_aggregate: Unclaimed_Demographics_Aggregate;
  /** fetch data from the table: "unclaimed_demographics" using primary key columns */
  unclaimed_demographics_by_pk?: Maybe<Unclaimed_Demographics>;
  /** fetch data from the table: "unclaimed_profile" */
  unclaimed_profile: Array<Unclaimed_Profile>;
  /** fetch aggregated fields from the table: "unclaimed_profile" */
  unclaimed_profile_aggregate: Unclaimed_Profile_Aggregate;
  /** fetch data from the table: "unclaimed_profile" using primary key columns */
  unclaimed_profile_by_pk?: Maybe<Unclaimed_Profile>;
  /** fetch data from the table: "votesbyuser" */
  votesbyuser: Array<Votesbyuser>;
  /** fetch aggregated fields from the table: "votesbyuser" */
  votesbyuser_aggregate: Votesbyuser_Aggregate;
  /** fetch data from the table: "wui_userlastseen" */
  wui_userlastseen: Array<Wui_Userlastseen>;
  /** fetch aggregated fields from the table: "wui_userlastseen" */
  wui_userlastseen_aggregate: Wui_Userlastseen_Aggregate;
};


export type Query_RootAmbassadorArgs = {
  distinct_on?: InputMaybe<Array<Ambassador_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ambassador_Order_By>>;
  where?: InputMaybe<Ambassador_Bool_Exp>;
};


export type Query_RootAmbassador_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ambassador_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ambassador_Order_By>>;
  where?: InputMaybe<Ambassador_Bool_Exp>;
};


export type Query_RootAmbassador_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDemographicArgs = {
  distinct_on?: InputMaybe<Array<Demographic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Demographic_Order_By>>;
  where?: InputMaybe<Demographic_Bool_Exp>;
};


export type Query_RootDemographic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Demographic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Demographic_Order_By>>;
  where?: InputMaybe<Demographic_Bool_Exp>;
};


export type Query_RootDemographic_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEmailDomainLookupArgs = {
  distinct_on?: InputMaybe<Array<EmailDomainLookup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailDomainLookup_Order_By>>;
  where?: InputMaybe<EmailDomainLookup_Bool_Exp>;
};


export type Query_RootEmailDomainLookup_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailDomainLookup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailDomainLookup_Order_By>>;
  where?: InputMaybe<EmailDomainLookup_Bool_Exp>;
};


export type Query_RootEmailDomainLookup_By_PkArgs = {
  domain: Scalars['String'];
};


export type Query_RootHeroBannerArgs = {
  distinct_on?: InputMaybe<Array<HeroBanner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HeroBanner_Order_By>>;
  where?: InputMaybe<HeroBanner_Bool_Exp>;
};


export type Query_RootHeroBanner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HeroBanner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HeroBanner_Order_By>>;
  where?: InputMaybe<HeroBanner_Bool_Exp>;
};


export type Query_RootHeroBanner_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootIndustryArgs = {
  distinct_on?: InputMaybe<Array<Industry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Industry_Order_By>>;
  where?: InputMaybe<Industry_Bool_Exp>;
};


export type Query_RootIndustry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Industry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Industry_Order_By>>;
  where?: InputMaybe<Industry_Bool_Exp>;
};


export type Query_RootIndustry_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootMarketplaceWaitlistArgs = {
  distinct_on?: InputMaybe<Array<MarketplaceWaitlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketplaceWaitlist_Order_By>>;
  where?: InputMaybe<MarketplaceWaitlist_Bool_Exp>;
};


export type Query_RootMarketplaceWaitlist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MarketplaceWaitlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketplaceWaitlist_Order_By>>;
  where?: InputMaybe<MarketplaceWaitlist_Bool_Exp>;
};


export type Query_RootMarketplaceWaitlist_By_PkArgs = {
  user_id: Scalars['bigint'];
};


export type Query_RootPlatformAlertArgs = {
  distinct_on?: InputMaybe<Array<PlatformAlert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlatformAlert_Order_By>>;
  where?: InputMaybe<PlatformAlert_Bool_Exp>;
};


export type Query_RootPlatformAlert_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PlatformAlert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlatformAlert_Order_By>>;
  where?: InputMaybe<PlatformAlert_Bool_Exp>;
};


export type Query_RootPlatformAlert_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProjectDemographicArgs = {
  distinct_on?: InputMaybe<Array<ProjectDemographic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProjectDemographic_Order_By>>;
  where?: InputMaybe<ProjectDemographic_Bool_Exp>;
};


export type Query_RootProjectDemographic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProjectDemographic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProjectDemographic_Order_By>>;
  where?: InputMaybe<ProjectDemographic_Bool_Exp>;
};


export type Query_RootProjectDemographic_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSignupSourceArgs = {
  distinct_on?: InputMaybe<Array<SignupSource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignupSource_Order_By>>;
  where?: InputMaybe<SignupSource_Bool_Exp>;
};


export type Query_RootSignupSourceTrackerArgs = {
  distinct_on?: InputMaybe<Array<SignupSourceTracker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignupSourceTracker_Order_By>>;
  where?: InputMaybe<SignupSourceTracker_Bool_Exp>;
};


export type Query_RootSignupSourceTracker_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SignupSourceTracker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignupSourceTracker_Order_By>>;
  where?: InputMaybe<SignupSourceTracker_Bool_Exp>;
};


export type Query_RootSignupSourceTracker_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSignupSource_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SignupSource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignupSource_Order_By>>;
  where?: InputMaybe<SignupSource_Bool_Exp>;
};


export type Query_RootSignupSource_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootSocialMediaArgs = {
  distinct_on?: InputMaybe<Array<SocialMedia_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMedia_Order_By>>;
  where?: InputMaybe<SocialMedia_Bool_Exp>;
};


export type Query_RootSocialMediaDemographicArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaDemographic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaDemographic_Order_By>>;
  where?: InputMaybe<SocialMediaDemographic_Bool_Exp>;
};


export type Query_RootSocialMediaDemographic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaDemographic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaDemographic_Order_By>>;
  where?: InputMaybe<SocialMediaDemographic_Bool_Exp>;
};


export type Query_RootSocialMediaDemographic_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSocialMediaHistoryArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaHistory_Order_By>>;
  where?: InputMaybe<SocialMediaHistory_Bool_Exp>;
};


export type Query_RootSocialMediaHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaHistory_Order_By>>;
  where?: InputMaybe<SocialMediaHistory_Bool_Exp>;
};


export type Query_RootSocialMediaHistory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSocialMediaLinkArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaLink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaLink_Order_By>>;
  where?: InputMaybe<SocialMediaLink_Bool_Exp>;
};


export type Query_RootSocialMediaLink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaLink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaLink_Order_By>>;
  where?: InputMaybe<SocialMediaLink_Bool_Exp>;
};


export type Query_RootSocialMediaLink_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSocialMediaPlatformArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaPlatform_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaPlatform_Order_By>>;
  where?: InputMaybe<SocialMediaPlatform_Bool_Exp>;
};


export type Query_RootSocialMediaPlatform_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaPlatform_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaPlatform_Order_By>>;
  where?: InputMaybe<SocialMediaPlatform_Bool_Exp>;
};


export type Query_RootSocialMediaPlatform_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootSocialMedia_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMedia_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMedia_Order_By>>;
  where?: InputMaybe<SocialMedia_Bool_Exp>;
};


export type Query_RootSocialMedia_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTempLoginWithPasswordArgs = {
  distinct_on?: InputMaybe<Array<TempLoginWithPassword_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TempLoginWithPassword_Order_By>>;
  where?: InputMaybe<TempLoginWithPassword_Bool_Exp>;
};


export type Query_RootTempLoginWithPassword_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TempLoginWithPassword_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TempLoginWithPassword_Order_By>>;
  where?: InputMaybe<TempLoginWithPassword_Bool_Exp>;
};


export type Query_RootTempLoginWithPassword_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserVideoArgs = {
  distinct_on?: InputMaybe<Array<UserVideo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserVideo_Order_By>>;
  where?: InputMaybe<UserVideo_Bool_Exp>;
};


export type Query_RootUserVideo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserVideo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserVideo_Order_By>>;
  where?: InputMaybe<UserVideo_Bool_Exp>;
};


export type Query_RootUserVideo_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVoteArgs = {
  distinct_on?: InputMaybe<Array<Vote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vote_Order_By>>;
  where?: InputMaybe<Vote_Bool_Exp>;
};


export type Query_RootVoteCategoryArgs = {
  distinct_on?: InputMaybe<Array<VoteCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VoteCategory_Order_By>>;
  where?: InputMaybe<VoteCategory_Bool_Exp>;
};


export type Query_RootVoteCategory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VoteCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VoteCategory_Order_By>>;
  where?: InputMaybe<VoteCategory_Bool_Exp>;
};


export type Query_RootVoteCategory_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootVoteUserArgs = {
  distinct_on?: InputMaybe<Array<VoteUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VoteUser_Order_By>>;
  where?: InputMaybe<VoteUser_Bool_Exp>;
};


export type Query_RootVoteUserCategoryArgs = {
  distinct_on?: InputMaybe<Array<VoteUserCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VoteUserCategory_Order_By>>;
  where?: InputMaybe<VoteUserCategory_Bool_Exp>;
};


export type Query_RootVoteUserCategory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VoteUserCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VoteUserCategory_Order_By>>;
  where?: InputMaybe<VoteUserCategory_Bool_Exp>;
};


export type Query_RootVoteUserCategory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVoteUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VoteUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VoteUser_Order_By>>;
  where?: InputMaybe<VoteUser_Bool_Exp>;
};


export type Query_RootVoteUser_By_PkArgs = {
  user_id: Scalars['bigint'];
};


export type Query_RootVote_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vote_Order_By>>;
  where?: InputMaybe<Vote_Bool_Exp>;
};


export type Query_RootVote_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVotesByUserCategoryIdArgs = {
  distinct_on?: InputMaybe<Array<VotesByUserCategoryId_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VotesByUserCategoryId_Order_By>>;
  where?: InputMaybe<VotesByUserCategoryId_Bool_Exp>;
};


export type Query_RootVotesByUserCategoryId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VotesByUserCategoryId_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VotesByUserCategoryId_Order_By>>;
  where?: InputMaybe<VotesByUserCategoryId_Bool_Exp>;
};


export type Query_RootWui_AccountArgs = {
  distinct_on?: InputMaybe<Array<Wui_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Account_Order_By>>;
  where?: InputMaybe<Wui_Account_Bool_Exp>;
};


export type Query_RootWui_Account_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Account_Order_By>>;
  where?: InputMaybe<Wui_Account_Bool_Exp>;
};


export type Query_RootWui_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootWui_AffiliateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Affiliate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Affiliate_Order_By>>;
  where?: InputMaybe<Wui_Affiliate_Bool_Exp>;
};


export type Query_RootWui_AffiliateLevelArgs = {
  distinct_on?: InputMaybe<Array<Wui_AffiliateLevel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AffiliateLevel_Order_By>>;
  where?: InputMaybe<Wui_AffiliateLevel_Bool_Exp>;
};


export type Query_RootWui_AffiliateLevel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_AffiliateLevel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AffiliateLevel_Order_By>>;
  where?: InputMaybe<Wui_AffiliateLevel_Bool_Exp>;
};


export type Query_RootWui_AffiliateLevel_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_AffiliateStatusArgs = {
  distinct_on?: InputMaybe<Array<Wui_AffiliateStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AffiliateStatus_Order_By>>;
  where?: InputMaybe<Wui_AffiliateStatus_Bool_Exp>;
};


export type Query_RootWui_AffiliateStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_AffiliateStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AffiliateStatus_Order_By>>;
  where?: InputMaybe<Wui_AffiliateStatus_Bool_Exp>;
};


export type Query_RootWui_AffiliateStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_AffiliateTransactionArgs = {
  distinct_on?: InputMaybe<Array<Wui_AffiliateTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AffiliateTransaction_Order_By>>;
  where?: InputMaybe<Wui_AffiliateTransaction_Bool_Exp>;
};


export type Query_RootWui_AffiliateTransaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_AffiliateTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AffiliateTransaction_Order_By>>;
  where?: InputMaybe<Wui_AffiliateTransaction_Bool_Exp>;
};


export type Query_RootWui_AffiliateTransaction_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootWui_AffiliateUsersArgs = {
  distinct_on?: InputMaybe<Array<Wui_AffiliateUsers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AffiliateUsers_Order_By>>;
  where?: InputMaybe<Wui_AffiliateUsers_Bool_Exp>;
};


export type Query_RootWui_AffiliateUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_AffiliateUsers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AffiliateUsers_Order_By>>;
  where?: InputMaybe<Wui_AffiliateUsers_Bool_Exp>;
};


export type Query_RootWui_AffiliateUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootWui_Affiliate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Affiliate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Affiliate_Order_By>>;
  where?: InputMaybe<Wui_Affiliate_Bool_Exp>;
};


export type Query_RootWui_Affiliate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootWui_AuthProviderArgs = {
  distinct_on?: InputMaybe<Array<Wui_AuthProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AuthProvider_Order_By>>;
  where?: InputMaybe<Wui_AuthProvider_Bool_Exp>;
};


export type Query_RootWui_AuthProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_AuthProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AuthProvider_Order_By>>;
  where?: InputMaybe<Wui_AuthProvider_Bool_Exp>;
};


export type Query_RootWui_AuthProvider_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_AuthSessionArgs = {
  distinct_on?: InputMaybe<Array<Wui_AuthSession_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AuthSession_Order_By>>;
  where?: InputMaybe<Wui_AuthSession_Bool_Exp>;
};


export type Query_RootWui_AuthSession_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_AuthSession_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AuthSession_Order_By>>;
  where?: InputMaybe<Wui_AuthSession_Bool_Exp>;
};


export type Query_RootWui_AuthSession_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootWui_ChatRoomArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoom_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoom_Order_By>>;
  where?: InputMaybe<Wui_ChatRoom_Bool_Exp>;
};


export type Query_RootWui_ChatRoomMessageArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomMessage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomMessage_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomMessage_Bool_Exp>;
};


export type Query_RootWui_ChatRoomMessage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomMessage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomMessage_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomMessage_Bool_Exp>;
};


export type Query_RootWui_ChatRoomMessage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_ChatRoomOrderArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomOrder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomOrder_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomOrder_Bool_Exp>;
};


export type Query_RootWui_ChatRoomOrder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomOrder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomOrder_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomOrder_Bool_Exp>;
};


export type Query_RootWui_ChatRoomOrder_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_ChatRoomTypeArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomType_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomType_Bool_Exp>;
};


export type Query_RootWui_ChatRoomType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomType_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomType_Bool_Exp>;
};


export type Query_RootWui_ChatRoomType_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_ChatRoomUserArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomUser_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomUser_Bool_Exp>;
};


export type Query_RootWui_ChatRoomUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomUser_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomUser_Bool_Exp>;
};


export type Query_RootWui_ChatRoomUser_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_ChatRoom_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoom_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoom_Order_By>>;
  where?: InputMaybe<Wui_ChatRoom_Bool_Exp>;
};


export type Query_RootWui_ChatRoom_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_FileArgs = {
  distinct_on?: InputMaybe<Array<Wui_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_File_Order_By>>;
  where?: InputMaybe<Wui_File_Bool_Exp>;
};


export type Query_RootWui_FileTypeArgs = {
  distinct_on?: InputMaybe<Array<Wui_FileType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_FileType_Order_By>>;
  where?: InputMaybe<Wui_FileType_Bool_Exp>;
};


export type Query_RootWui_FileType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_FileType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_FileType_Order_By>>;
  where?: InputMaybe<Wui_FileType_Bool_Exp>;
};


export type Query_RootWui_FileType_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_File_Order_By>>;
  where?: InputMaybe<Wui_File_Bool_Exp>;
};


export type Query_RootWui_File_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_NotificationArgs = {
  distinct_on?: InputMaybe<Array<Wui_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Notification_Order_By>>;
  where?: InputMaybe<Wui_Notification_Bool_Exp>;
};


export type Query_RootWui_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Notification_Order_By>>;
  where?: InputMaybe<Wui_Notification_Bool_Exp>;
};


export type Query_RootWui_Notification_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_OrderArgs = {
  distinct_on?: InputMaybe<Array<Wui_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Order_Order_By>>;
  where?: InputMaybe<Wui_Order_Bool_Exp>;
};


export type Query_RootWui_OrderReviewArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderReview_Order_By>>;
  where?: InputMaybe<Wui_OrderReview_Bool_Exp>;
};


export type Query_RootWui_OrderReview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderReview_Order_By>>;
  where?: InputMaybe<Wui_OrderReview_Bool_Exp>;
};


export type Query_RootWui_OrderReview_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_OrderStatusArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderStatus_Order_By>>;
  where?: InputMaybe<Wui_OrderStatus_Bool_Exp>;
};


export type Query_RootWui_OrderStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderStatus_Order_By>>;
  where?: InputMaybe<Wui_OrderStatus_Bool_Exp>;
};


export type Query_RootWui_OrderStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_OrderSubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderSubscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderSubscription_Order_By>>;
  where?: InputMaybe<Wui_OrderSubscription_Bool_Exp>;
};


export type Query_RootWui_OrderSubscriptionPeriodArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderSubscriptionPeriod_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderSubscriptionPeriod_Order_By>>;
  where?: InputMaybe<Wui_OrderSubscriptionPeriod_Bool_Exp>;
};


export type Query_RootWui_OrderSubscriptionPeriod_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderSubscriptionPeriod_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderSubscriptionPeriod_Order_By>>;
  where?: InputMaybe<Wui_OrderSubscriptionPeriod_Bool_Exp>;
};


export type Query_RootWui_OrderSubscriptionPeriod_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_OrderSubscriptionStatusArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderSubscriptionStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderSubscriptionStatus_Order_By>>;
  where?: InputMaybe<Wui_OrderSubscriptionStatus_Bool_Exp>;
};


export type Query_RootWui_OrderSubscriptionStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderSubscriptionStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderSubscriptionStatus_Order_By>>;
  where?: InputMaybe<Wui_OrderSubscriptionStatus_Bool_Exp>;
};


export type Query_RootWui_OrderSubscriptionStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_OrderSubscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderSubscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderSubscription_Order_By>>;
  where?: InputMaybe<Wui_OrderSubscription_Bool_Exp>;
};


export type Query_RootWui_OrderSubscription_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Order_Order_By>>;
  where?: InputMaybe<Wui_Order_Bool_Exp>;
};


export type Query_RootWui_Order_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_PackageRequestArgs = {
  distinct_on?: InputMaybe<Array<Wui_PackageRequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PackageRequest_Order_By>>;
  where?: InputMaybe<Wui_PackageRequest_Bool_Exp>;
};


export type Query_RootWui_PackageRequest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_PackageRequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PackageRequest_Order_By>>;
  where?: InputMaybe<Wui_PackageRequest_Bool_Exp>;
};


export type Query_RootWui_PackageRequest_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_PaymentArgs = {
  distinct_on?: InputMaybe<Array<Wui_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Payment_Order_By>>;
  where?: InputMaybe<Wui_Payment_Bool_Exp>;
};


export type Query_RootWui_PaymentMethodArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethod_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethod_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethod_Bool_Exp>;
};


export type Query_RootWui_PaymentMethodBankAccountArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethodBankAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethodBankAccount_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethodBankAccount_Bool_Exp>;
};


export type Query_RootWui_PaymentMethodBankAccount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethodBankAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethodBankAccount_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethodBankAccount_Bool_Exp>;
};


export type Query_RootWui_PaymentMethodBankAccount_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootWui_PaymentMethodCardArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethodCard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethodCard_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethodCard_Bool_Exp>;
};


export type Query_RootWui_PaymentMethodCard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethodCard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethodCard_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethodCard_Bool_Exp>;
};


export type Query_RootWui_PaymentMethodCard_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_PaymentMethodSourceArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethodSource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethodSource_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethodSource_Bool_Exp>;
};


export type Query_RootWui_PaymentMethodSource_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethodSource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethodSource_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethodSource_Bool_Exp>;
};


export type Query_RootWui_PaymentMethodSource_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_PaymentMethod_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethod_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethod_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethod_Bool_Exp>;
};


export type Query_RootWui_PaymentMethod_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_PaymentStatusArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentStatus_Order_By>>;
  where?: InputMaybe<Wui_PaymentStatus_Bool_Exp>;
};


export type Query_RootWui_PaymentStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentStatus_Order_By>>;
  where?: InputMaybe<Wui_PaymentStatus_Bool_Exp>;
};


export type Query_RootWui_PaymentStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_Payment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Payment_Order_By>>;
  where?: InputMaybe<Wui_Payment_Bool_Exp>;
};


export type Query_RootWui_Payment_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_RequestStatusArgs = {
  distinct_on?: InputMaybe<Array<Wui_RequestStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_RequestStatus_Order_By>>;
  where?: InputMaybe<Wui_RequestStatus_Bool_Exp>;
};


export type Query_RootWui_RequestStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_RequestStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_RequestStatus_Order_By>>;
  where?: InputMaybe<Wui_RequestStatus_Bool_Exp>;
};


export type Query_RootWui_RequestStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_RoleArgs = {
  distinct_on?: InputMaybe<Array<Wui_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Role_Order_By>>;
  where?: InputMaybe<Wui_Role_Bool_Exp>;
};


export type Query_RootWui_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Role_Order_By>>;
  where?: InputMaybe<Wui_Role_Bool_Exp>;
};


export type Query_RootWui_Role_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootWui_SellerPackageArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerPackage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerPackage_Order_By>>;
  where?: InputMaybe<Wui_SellerPackage_Bool_Exp>;
};


export type Query_RootWui_SellerPackageItemArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerPackageItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerPackageItem_Order_By>>;
  where?: InputMaybe<Wui_SellerPackageItem_Bool_Exp>;
};


export type Query_RootWui_SellerPackageItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerPackageItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerPackageItem_Order_By>>;
  where?: InputMaybe<Wui_SellerPackageItem_Bool_Exp>;
};


export type Query_RootWui_SellerPackageItem_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_SellerPackageTypeArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerPackageType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerPackageType_Order_By>>;
  where?: InputMaybe<Wui_SellerPackageType_Bool_Exp>;
};


export type Query_RootWui_SellerPackageType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerPackageType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerPackageType_Order_By>>;
  where?: InputMaybe<Wui_SellerPackageType_Bool_Exp>;
};


export type Query_RootWui_SellerPackageType_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_SellerPackage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerPackage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerPackage_Order_By>>;
  where?: InputMaybe<Wui_SellerPackage_Bool_Exp>;
};


export type Query_RootWui_SellerPackage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_SellerProjectArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerProject_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerProject_Order_By>>;
  where?: InputMaybe<Wui_SellerProject_Bool_Exp>;
};


export type Query_RootWui_SellerProjectFileArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerProjectFile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerProjectFile_Order_By>>;
  where?: InputMaybe<Wui_SellerProjectFile_Bool_Exp>;
};


export type Query_RootWui_SellerProjectFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerProjectFile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerProjectFile_Order_By>>;
  where?: InputMaybe<Wui_SellerProjectFile_Bool_Exp>;
};


export type Query_RootWui_SellerProjectFile_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootWui_SellerProjectItemArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerProjectItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerProjectItem_Order_By>>;
  where?: InputMaybe<Wui_SellerProjectItem_Bool_Exp>;
};


export type Query_RootWui_SellerProjectItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerProjectItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerProjectItem_Order_By>>;
  where?: InputMaybe<Wui_SellerProjectItem_Bool_Exp>;
};


export type Query_RootWui_SellerProjectItem_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_SellerProject_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerProject_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerProject_Order_By>>;
  where?: InputMaybe<Wui_SellerProject_Bool_Exp>;
};


export type Query_RootWui_SellerProject_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_SessionArgs = {
  distinct_on?: InputMaybe<Array<Wui_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Session_Order_By>>;
  where?: InputMaybe<Wui_Session_Bool_Exp>;
};


export type Query_RootWui_SessionEventArgs = {
  distinct_on?: InputMaybe<Array<Wui_SessionEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SessionEvent_Order_By>>;
  where?: InputMaybe<Wui_SessionEvent_Bool_Exp>;
};


export type Query_RootWui_SessionEventTypeArgs = {
  distinct_on?: InputMaybe<Array<Wui_SessionEventType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SessionEventType_Order_By>>;
  where?: InputMaybe<Wui_SessionEventType_Bool_Exp>;
};


export type Query_RootWui_SessionEventType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SessionEventType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SessionEventType_Order_By>>;
  where?: InputMaybe<Wui_SessionEventType_Bool_Exp>;
};


export type Query_RootWui_SessionEventType_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_SessionEvent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SessionEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SessionEvent_Order_By>>;
  where?: InputMaybe<Wui_SessionEvent_Bool_Exp>;
};


export type Query_RootWui_SessionEvent_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_Session_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Session_Order_By>>;
  where?: InputMaybe<Wui_Session_Bool_Exp>;
};


export type Query_RootWui_Session_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_SocialMediaSourceArgs = {
  distinct_on?: InputMaybe<Array<Wui_SocialMediaSource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SocialMediaSource_Order_By>>;
  where?: InputMaybe<Wui_SocialMediaSource_Bool_Exp>;
};


export type Query_RootWui_SocialMediaSource_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SocialMediaSource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SocialMediaSource_Order_By>>;
  where?: InputMaybe<Wui_SocialMediaSource_Bool_Exp>;
};


export type Query_RootWui_SocialMediaSource_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_SubscriptionPlanArgs = {
  distinct_on?: InputMaybe<Array<Wui_SubscriptionPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SubscriptionPlan_Order_By>>;
  where?: InputMaybe<Wui_SubscriptionPlan_Bool_Exp>;
};


export type Query_RootWui_SubscriptionPlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SubscriptionPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SubscriptionPlan_Order_By>>;
  where?: InputMaybe<Wui_SubscriptionPlan_Bool_Exp>;
};


export type Query_RootWui_SubscriptionPlan_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootWui_UserArgs = {
  distinct_on?: InputMaybe<Array<Wui_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_User_Order_By>>;
  where?: InputMaybe<Wui_User_Bool_Exp>;
};


export type Query_RootWui_UserFilePortfolioArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserFilePortfolio_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserFilePortfolio_Order_By>>;
  where?: InputMaybe<Wui_UserFilePortfolio_Bool_Exp>;
};


export type Query_RootWui_UserFilePortfolio_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserFilePortfolio_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserFilePortfolio_Order_By>>;
  where?: InputMaybe<Wui_UserFilePortfolio_Bool_Exp>;
};


export type Query_RootWui_UserFilePortfolio_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_UserReviewArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserReview_Order_By>>;
  where?: InputMaybe<Wui_UserReview_Bool_Exp>;
};


export type Query_RootWui_UserReview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserReview_Order_By>>;
  where?: InputMaybe<Wui_UserReview_Bool_Exp>;
};


export type Query_RootWui_UserReview_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_UserRoleArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserRole_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserRole_Order_By>>;
  where?: InputMaybe<Wui_UserRole_Bool_Exp>;
};


export type Query_RootWui_UserRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserRole_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserRole_Order_By>>;
  where?: InputMaybe<Wui_UserRole_Bool_Exp>;
};


export type Query_RootWui_UserSubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserSubscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserSubscription_Order_By>>;
  where?: InputMaybe<Wui_UserSubscription_Bool_Exp>;
};


export type Query_RootWui_UserSubscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserSubscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserSubscription_Order_By>>;
  where?: InputMaybe<Wui_UserSubscription_Bool_Exp>;
};


export type Query_RootWui_UserSubscription_By_PkArgs = {
  external_id: Scalars['String'];
};


export type Query_RootWui_UserTypeArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserType_Order_By>>;
  where?: InputMaybe<Wui_UserType_Bool_Exp>;
};


export type Query_RootWui_UserType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserType_Order_By>>;
  where?: InputMaybe<Wui_UserType_Bool_Exp>;
};


export type Query_RootWui_UserType_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_User_Order_By>>;
  where?: InputMaybe<Wui_User_Bool_Exp>;
};


export type Query_RootWui_User_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_VerificationTokenArgs = {
  distinct_on?: InputMaybe<Array<Wui_VerificationToken_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_VerificationToken_Order_By>>;
  where?: InputMaybe<Wui_VerificationToken_Bool_Exp>;
};


export type Query_RootWui_VerificationToken_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_VerificationToken_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_VerificationToken_Order_By>>;
  where?: InputMaybe<Wui_VerificationToken_Bool_Exp>;
};


export type Query_RootWui_VerificationToken_By_PkArgs = {
  token: Scalars['String'];
};


export type Query_RootGetCountrySpecsArgs = {
  countryCode: Scalars['String'];
};


export type Query_RootSearchInfluencersArgs = {
  filters: SearchInfluencersFilters;
};


export type Query_RootShared_MissingAccountItemsArgs = {
  userId: Scalars['bigint'];
};


export type Query_RootUnclaimed_DemographicsArgs = {
  distinct_on?: InputMaybe<Array<Unclaimed_Demographics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unclaimed_Demographics_Order_By>>;
  where?: InputMaybe<Unclaimed_Demographics_Bool_Exp>;
};


export type Query_RootUnclaimed_Demographics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unclaimed_Demographics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unclaimed_Demographics_Order_By>>;
  where?: InputMaybe<Unclaimed_Demographics_Bool_Exp>;
};


export type Query_RootUnclaimed_Demographics_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootUnclaimed_ProfileArgs = {
  distinct_on?: InputMaybe<Array<Unclaimed_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unclaimed_Profile_Order_By>>;
  where?: InputMaybe<Unclaimed_Profile_Bool_Exp>;
};


export type Query_RootUnclaimed_Profile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unclaimed_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unclaimed_Profile_Order_By>>;
  where?: InputMaybe<Unclaimed_Profile_Bool_Exp>;
};


export type Query_RootUnclaimed_Profile_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootVotesbyuserArgs = {
  distinct_on?: InputMaybe<Array<Votesbyuser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Votesbyuser_Order_By>>;
  where?: InputMaybe<Votesbyuser_Bool_Exp>;
};


export type Query_RootVotesbyuser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Votesbyuser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Votesbyuser_Order_By>>;
  where?: InputMaybe<Votesbyuser_Bool_Exp>;
};


export type Query_RootWui_UserlastseenArgs = {
  distinct_on?: InputMaybe<Array<Wui_Userlastseen_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Userlastseen_Order_By>>;
  where?: InputMaybe<Wui_Userlastseen_Bool_Exp>;
};


export type Query_RootWui_Userlastseen_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Userlastseen_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Userlastseen_Order_By>>;
  where?: InputMaybe<Wui_Userlastseen_Bool_Exp>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "Ambassador" */
  Ambassador: Array<Ambassador>;
  /** fetch aggregated fields from the table: "Ambassador" */
  Ambassador_aggregate: Ambassador_Aggregate;
  /** fetch data from the table: "Ambassador" using primary key columns */
  Ambassador_by_pk?: Maybe<Ambassador>;
  /** fetch data from the table in a streaming manner: "Ambassador" */
  Ambassador_stream: Array<Ambassador>;
  /** fetch data from the table: "Demographic" */
  Demographic: Array<Demographic>;
  /** fetch aggregated fields from the table: "Demographic" */
  Demographic_aggregate: Demographic_Aggregate;
  /** fetch data from the table: "Demographic" using primary key columns */
  Demographic_by_pk?: Maybe<Demographic>;
  /** fetch data from the table in a streaming manner: "Demographic" */
  Demographic_stream: Array<Demographic>;
  /** fetch data from the table: "EmailDomainLookup" */
  EmailDomainLookup: Array<EmailDomainLookup>;
  /** fetch aggregated fields from the table: "EmailDomainLookup" */
  EmailDomainLookup_aggregate: EmailDomainLookup_Aggregate;
  /** fetch data from the table: "EmailDomainLookup" using primary key columns */
  EmailDomainLookup_by_pk?: Maybe<EmailDomainLookup>;
  /** fetch data from the table in a streaming manner: "EmailDomainLookup" */
  EmailDomainLookup_stream: Array<EmailDomainLookup>;
  /** fetch data from the table: "HeroBanner" */
  HeroBanner: Array<HeroBanner>;
  /** fetch aggregated fields from the table: "HeroBanner" */
  HeroBanner_aggregate: HeroBanner_Aggregate;
  /** fetch data from the table: "HeroBanner" using primary key columns */
  HeroBanner_by_pk?: Maybe<HeroBanner>;
  /** fetch data from the table in a streaming manner: "HeroBanner" */
  HeroBanner_stream: Array<HeroBanner>;
  /** fetch data from the table: "Industry" */
  Industry: Array<Industry>;
  /** fetch aggregated fields from the table: "Industry" */
  Industry_aggregate: Industry_Aggregate;
  /** fetch data from the table: "Industry" using primary key columns */
  Industry_by_pk?: Maybe<Industry>;
  /** fetch data from the table in a streaming manner: "Industry" */
  Industry_stream: Array<Industry>;
  /** fetch data from the table: "MarketplaceWaitlist" */
  MarketplaceWaitlist: Array<MarketplaceWaitlist>;
  /** fetch aggregated fields from the table: "MarketplaceWaitlist" */
  MarketplaceWaitlist_aggregate: MarketplaceWaitlist_Aggregate;
  /** fetch data from the table: "MarketplaceWaitlist" using primary key columns */
  MarketplaceWaitlist_by_pk?: Maybe<MarketplaceWaitlist>;
  /** fetch data from the table in a streaming manner: "MarketplaceWaitlist" */
  MarketplaceWaitlist_stream: Array<MarketplaceWaitlist>;
  /** fetch data from the table: "PlatformAlert" */
  PlatformAlert: Array<PlatformAlert>;
  /** fetch aggregated fields from the table: "PlatformAlert" */
  PlatformAlert_aggregate: PlatformAlert_Aggregate;
  /** fetch data from the table: "PlatformAlert" using primary key columns */
  PlatformAlert_by_pk?: Maybe<PlatformAlert>;
  /** fetch data from the table in a streaming manner: "PlatformAlert" */
  PlatformAlert_stream: Array<PlatformAlert>;
  /** fetch data from the table: "ProjectDemographic" */
  ProjectDemographic: Array<ProjectDemographic>;
  /** fetch aggregated fields from the table: "ProjectDemographic" */
  ProjectDemographic_aggregate: ProjectDemographic_Aggregate;
  /** fetch data from the table: "ProjectDemographic" using primary key columns */
  ProjectDemographic_by_pk?: Maybe<ProjectDemographic>;
  /** fetch data from the table in a streaming manner: "ProjectDemographic" */
  ProjectDemographic_stream: Array<ProjectDemographic>;
  /** fetch data from the table: "SignupSource" */
  SignupSource: Array<SignupSource>;
  /** fetch data from the table: "SignupSourceTracker" */
  SignupSourceTracker: Array<SignupSourceTracker>;
  /** fetch aggregated fields from the table: "SignupSourceTracker" */
  SignupSourceTracker_aggregate: SignupSourceTracker_Aggregate;
  /** fetch data from the table: "SignupSourceTracker" using primary key columns */
  SignupSourceTracker_by_pk?: Maybe<SignupSourceTracker>;
  /** fetch data from the table in a streaming manner: "SignupSourceTracker" */
  SignupSourceTracker_stream: Array<SignupSourceTracker>;
  /** fetch aggregated fields from the table: "SignupSource" */
  SignupSource_aggregate: SignupSource_Aggregate;
  /** fetch data from the table: "SignupSource" using primary key columns */
  SignupSource_by_pk?: Maybe<SignupSource>;
  /** fetch data from the table in a streaming manner: "SignupSource" */
  SignupSource_stream: Array<SignupSource>;
  /** fetch data from the table: "SocialMedia" */
  SocialMedia: Array<SocialMedia>;
  /** fetch data from the table: "SocialMediaDemographic" */
  SocialMediaDemographic: Array<SocialMediaDemographic>;
  /** fetch aggregated fields from the table: "SocialMediaDemographic" */
  SocialMediaDemographic_aggregate: SocialMediaDemographic_Aggregate;
  /** fetch data from the table: "SocialMediaDemographic" using primary key columns */
  SocialMediaDemographic_by_pk?: Maybe<SocialMediaDemographic>;
  /** fetch data from the table in a streaming manner: "SocialMediaDemographic" */
  SocialMediaDemographic_stream: Array<SocialMediaDemographic>;
  /** fetch data from the table: "SocialMediaHistory" */
  SocialMediaHistory: Array<SocialMediaHistory>;
  /** fetch aggregated fields from the table: "SocialMediaHistory" */
  SocialMediaHistory_aggregate: SocialMediaHistory_Aggregate;
  /** fetch data from the table: "SocialMediaHistory" using primary key columns */
  SocialMediaHistory_by_pk?: Maybe<SocialMediaHistory>;
  /** fetch data from the table in a streaming manner: "SocialMediaHistory" */
  SocialMediaHistory_stream: Array<SocialMediaHistory>;
  /** fetch data from the table: "SocialMediaLink" */
  SocialMediaLink: Array<SocialMediaLink>;
  /** fetch aggregated fields from the table: "SocialMediaLink" */
  SocialMediaLink_aggregate: SocialMediaLink_Aggregate;
  /** fetch data from the table: "SocialMediaLink" using primary key columns */
  SocialMediaLink_by_pk?: Maybe<SocialMediaLink>;
  /** fetch data from the table in a streaming manner: "SocialMediaLink" */
  SocialMediaLink_stream: Array<SocialMediaLink>;
  /** fetch data from the table: "SocialMediaPlatform" */
  SocialMediaPlatform: Array<SocialMediaPlatform>;
  /** fetch aggregated fields from the table: "SocialMediaPlatform" */
  SocialMediaPlatform_aggregate: SocialMediaPlatform_Aggregate;
  /** fetch data from the table: "SocialMediaPlatform" using primary key columns */
  SocialMediaPlatform_by_pk?: Maybe<SocialMediaPlatform>;
  /** fetch data from the table in a streaming manner: "SocialMediaPlatform" */
  SocialMediaPlatform_stream: Array<SocialMediaPlatform>;
  /** fetch aggregated fields from the table: "SocialMedia" */
  SocialMedia_aggregate: SocialMedia_Aggregate;
  /** fetch data from the table: "SocialMedia" using primary key columns */
  SocialMedia_by_pk?: Maybe<SocialMedia>;
  /** fetch data from the table in a streaming manner: "SocialMedia" */
  SocialMedia_stream: Array<SocialMedia>;
  /** fetch data from the table: "TempLoginWithPassword" */
  TempLoginWithPassword: Array<TempLoginWithPassword>;
  /** fetch aggregated fields from the table: "TempLoginWithPassword" */
  TempLoginWithPassword_aggregate: TempLoginWithPassword_Aggregate;
  /** fetch data from the table: "TempLoginWithPassword" using primary key columns */
  TempLoginWithPassword_by_pk?: Maybe<TempLoginWithPassword>;
  /** fetch data from the table in a streaming manner: "TempLoginWithPassword" */
  TempLoginWithPassword_stream: Array<TempLoginWithPassword>;
  /** fetch data from the table: "UserVideo" */
  UserVideo: Array<UserVideo>;
  /** fetch aggregated fields from the table: "UserVideo" */
  UserVideo_aggregate: UserVideo_Aggregate;
  /** fetch data from the table: "UserVideo" using primary key columns */
  UserVideo_by_pk?: Maybe<UserVideo>;
  /** fetch data from the table in a streaming manner: "UserVideo" */
  UserVideo_stream: Array<UserVideo>;
  /** fetch data from the table: "Vote" */
  Vote: Array<Vote>;
  /** fetch data from the table: "VoteCategory" */
  VoteCategory: Array<VoteCategory>;
  /** fetch aggregated fields from the table: "VoteCategory" */
  VoteCategory_aggregate: VoteCategory_Aggregate;
  /** fetch data from the table: "VoteCategory" using primary key columns */
  VoteCategory_by_pk?: Maybe<VoteCategory>;
  /** fetch data from the table in a streaming manner: "VoteCategory" */
  VoteCategory_stream: Array<VoteCategory>;
  /** fetch data from the table: "VoteUser" */
  VoteUser: Array<VoteUser>;
  /** fetch data from the table: "VoteUserCategory" */
  VoteUserCategory: Array<VoteUserCategory>;
  /** fetch aggregated fields from the table: "VoteUserCategory" */
  VoteUserCategory_aggregate: VoteUserCategory_Aggregate;
  /** fetch data from the table: "VoteUserCategory" using primary key columns */
  VoteUserCategory_by_pk?: Maybe<VoteUserCategory>;
  /** fetch data from the table in a streaming manner: "VoteUserCategory" */
  VoteUserCategory_stream: Array<VoteUserCategory>;
  /** fetch aggregated fields from the table: "VoteUser" */
  VoteUser_aggregate: VoteUser_Aggregate;
  /** fetch data from the table: "VoteUser" using primary key columns */
  VoteUser_by_pk?: Maybe<VoteUser>;
  /** fetch data from the table in a streaming manner: "VoteUser" */
  VoteUser_stream: Array<VoteUser>;
  /** fetch aggregated fields from the table: "Vote" */
  Vote_aggregate: Vote_Aggregate;
  /** fetch data from the table: "Vote" using primary key columns */
  Vote_by_pk?: Maybe<Vote>;
  /** fetch data from the table in a streaming manner: "Vote" */
  Vote_stream: Array<Vote>;
  /** fetch data from the table: "VotesByUserCategoryId" */
  VotesByUserCategoryId: Array<VotesByUserCategoryId>;
  /** fetch aggregated fields from the table: "VotesByUserCategoryId" */
  VotesByUserCategoryId_aggregate: VotesByUserCategoryId_Aggregate;
  /** fetch data from the table in a streaming manner: "VotesByUserCategoryId" */
  VotesByUserCategoryId_stream: Array<VotesByUserCategoryId>;
  /** fetch data from the table: "WUI_Account" */
  WUI_Account: Array<Wui_Account>;
  /** fetch aggregated fields from the table: "WUI_Account" */
  WUI_Account_aggregate: Wui_Account_Aggregate;
  /** fetch data from the table: "WUI_Account" using primary key columns */
  WUI_Account_by_pk?: Maybe<Wui_Account>;
  /** fetch data from the table in a streaming manner: "WUI_Account" */
  WUI_Account_stream: Array<Wui_Account>;
  /** fetch data from the table: "WUI_Affiliate" */
  WUI_Affiliate: Array<Wui_Affiliate>;
  /** fetch data from the table: "WUI_AffiliateLevel" */
  WUI_AffiliateLevel: Array<Wui_AffiliateLevel>;
  /** fetch aggregated fields from the table: "WUI_AffiliateLevel" */
  WUI_AffiliateLevel_aggregate: Wui_AffiliateLevel_Aggregate;
  /** fetch data from the table: "WUI_AffiliateLevel" using primary key columns */
  WUI_AffiliateLevel_by_pk?: Maybe<Wui_AffiliateLevel>;
  /** fetch data from the table in a streaming manner: "WUI_AffiliateLevel" */
  WUI_AffiliateLevel_stream: Array<Wui_AffiliateLevel>;
  /** fetch data from the table: "WUI_AffiliateStatus" */
  WUI_AffiliateStatus: Array<Wui_AffiliateStatus>;
  /** fetch aggregated fields from the table: "WUI_AffiliateStatus" */
  WUI_AffiliateStatus_aggregate: Wui_AffiliateStatus_Aggregate;
  /** fetch data from the table: "WUI_AffiliateStatus" using primary key columns */
  WUI_AffiliateStatus_by_pk?: Maybe<Wui_AffiliateStatus>;
  /** fetch data from the table in a streaming manner: "WUI_AffiliateStatus" */
  WUI_AffiliateStatus_stream: Array<Wui_AffiliateStatus>;
  /** fetch data from the table: "WUI_AffiliateTransaction" */
  WUI_AffiliateTransaction: Array<Wui_AffiliateTransaction>;
  /** fetch aggregated fields from the table: "WUI_AffiliateTransaction" */
  WUI_AffiliateTransaction_aggregate: Wui_AffiliateTransaction_Aggregate;
  /** fetch data from the table: "WUI_AffiliateTransaction" using primary key columns */
  WUI_AffiliateTransaction_by_pk?: Maybe<Wui_AffiliateTransaction>;
  /** fetch data from the table in a streaming manner: "WUI_AffiliateTransaction" */
  WUI_AffiliateTransaction_stream: Array<Wui_AffiliateTransaction>;
  /** fetch data from the table: "WUI_AffiliateUsers" */
  WUI_AffiliateUsers: Array<Wui_AffiliateUsers>;
  /** fetch aggregated fields from the table: "WUI_AffiliateUsers" */
  WUI_AffiliateUsers_aggregate: Wui_AffiliateUsers_Aggregate;
  /** fetch data from the table: "WUI_AffiliateUsers" using primary key columns */
  WUI_AffiliateUsers_by_pk?: Maybe<Wui_AffiliateUsers>;
  /** fetch data from the table in a streaming manner: "WUI_AffiliateUsers" */
  WUI_AffiliateUsers_stream: Array<Wui_AffiliateUsers>;
  /** fetch aggregated fields from the table: "WUI_Affiliate" */
  WUI_Affiliate_aggregate: Wui_Affiliate_Aggregate;
  /** fetch data from the table: "WUI_Affiliate" using primary key columns */
  WUI_Affiliate_by_pk?: Maybe<Wui_Affiliate>;
  /** fetch data from the table in a streaming manner: "WUI_Affiliate" */
  WUI_Affiliate_stream: Array<Wui_Affiliate>;
  /** fetch data from the table: "WUI_AuthProvider" */
  WUI_AuthProvider: Array<Wui_AuthProvider>;
  /** fetch aggregated fields from the table: "WUI_AuthProvider" */
  WUI_AuthProvider_aggregate: Wui_AuthProvider_Aggregate;
  /** fetch data from the table: "WUI_AuthProvider" using primary key columns */
  WUI_AuthProvider_by_pk?: Maybe<Wui_AuthProvider>;
  /** fetch data from the table in a streaming manner: "WUI_AuthProvider" */
  WUI_AuthProvider_stream: Array<Wui_AuthProvider>;
  /** fetch data from the table: "WUI_AuthSession" */
  WUI_AuthSession: Array<Wui_AuthSession>;
  /** fetch aggregated fields from the table: "WUI_AuthSession" */
  WUI_AuthSession_aggregate: Wui_AuthSession_Aggregate;
  /** fetch data from the table: "WUI_AuthSession" using primary key columns */
  WUI_AuthSession_by_pk?: Maybe<Wui_AuthSession>;
  /** fetch data from the table in a streaming manner: "WUI_AuthSession" */
  WUI_AuthSession_stream: Array<Wui_AuthSession>;
  /** fetch data from the table: "WUI_ChatRoom" */
  WUI_ChatRoom: Array<Wui_ChatRoom>;
  /** fetch data from the table: "WUI_ChatRoomMessage" */
  WUI_ChatRoomMessage: Array<Wui_ChatRoomMessage>;
  /** fetch aggregated fields from the table: "WUI_ChatRoomMessage" */
  WUI_ChatRoomMessage_aggregate: Wui_ChatRoomMessage_Aggregate;
  /** fetch data from the table: "WUI_ChatRoomMessage" using primary key columns */
  WUI_ChatRoomMessage_by_pk?: Maybe<Wui_ChatRoomMessage>;
  /** fetch data from the table in a streaming manner: "WUI_ChatRoomMessage" */
  WUI_ChatRoomMessage_stream: Array<Wui_ChatRoomMessage>;
  /** fetch data from the table: "WUI_ChatRoomOrder" */
  WUI_ChatRoomOrder: Array<Wui_ChatRoomOrder>;
  /** fetch aggregated fields from the table: "WUI_ChatRoomOrder" */
  WUI_ChatRoomOrder_aggregate: Wui_ChatRoomOrder_Aggregate;
  /** fetch data from the table: "WUI_ChatRoomOrder" using primary key columns */
  WUI_ChatRoomOrder_by_pk?: Maybe<Wui_ChatRoomOrder>;
  /** fetch data from the table in a streaming manner: "WUI_ChatRoomOrder" */
  WUI_ChatRoomOrder_stream: Array<Wui_ChatRoomOrder>;
  /** fetch data from the table: "WUI_ChatRoomType" */
  WUI_ChatRoomType: Array<Wui_ChatRoomType>;
  /** fetch aggregated fields from the table: "WUI_ChatRoomType" */
  WUI_ChatRoomType_aggregate: Wui_ChatRoomType_Aggregate;
  /** fetch data from the table: "WUI_ChatRoomType" using primary key columns */
  WUI_ChatRoomType_by_pk?: Maybe<Wui_ChatRoomType>;
  /** fetch data from the table in a streaming manner: "WUI_ChatRoomType" */
  WUI_ChatRoomType_stream: Array<Wui_ChatRoomType>;
  /** fetch data from the table: "WUI_ChatRoomUser" */
  WUI_ChatRoomUser: Array<Wui_ChatRoomUser>;
  /** fetch aggregated fields from the table: "WUI_ChatRoomUser" */
  WUI_ChatRoomUser_aggregate: Wui_ChatRoomUser_Aggregate;
  /** fetch data from the table: "WUI_ChatRoomUser" using primary key columns */
  WUI_ChatRoomUser_by_pk?: Maybe<Wui_ChatRoomUser>;
  /** fetch data from the table in a streaming manner: "WUI_ChatRoomUser" */
  WUI_ChatRoomUser_stream: Array<Wui_ChatRoomUser>;
  /** fetch aggregated fields from the table: "WUI_ChatRoom" */
  WUI_ChatRoom_aggregate: Wui_ChatRoom_Aggregate;
  /** fetch data from the table: "WUI_ChatRoom" using primary key columns */
  WUI_ChatRoom_by_pk?: Maybe<Wui_ChatRoom>;
  /** fetch data from the table in a streaming manner: "WUI_ChatRoom" */
  WUI_ChatRoom_stream: Array<Wui_ChatRoom>;
  /** fetch data from the table: "WUI_File" */
  WUI_File: Array<Wui_File>;
  /** fetch data from the table: "WUI_FileType" */
  WUI_FileType: Array<Wui_FileType>;
  /** fetch aggregated fields from the table: "WUI_FileType" */
  WUI_FileType_aggregate: Wui_FileType_Aggregate;
  /** fetch data from the table: "WUI_FileType" using primary key columns */
  WUI_FileType_by_pk?: Maybe<Wui_FileType>;
  /** fetch data from the table in a streaming manner: "WUI_FileType" */
  WUI_FileType_stream: Array<Wui_FileType>;
  /** fetch aggregated fields from the table: "WUI_File" */
  WUI_File_aggregate: Wui_File_Aggregate;
  /** fetch data from the table: "WUI_File" using primary key columns */
  WUI_File_by_pk?: Maybe<Wui_File>;
  /** fetch data from the table in a streaming manner: "WUI_File" */
  WUI_File_stream: Array<Wui_File>;
  /** An array relationship */
  WUI_Notification: Array<Wui_Notification>;
  /** An aggregate relationship */
  WUI_Notification_aggregate: Wui_Notification_Aggregate;
  /** fetch data from the table: "WUI_Notification" using primary key columns */
  WUI_Notification_by_pk?: Maybe<Wui_Notification>;
  /** fetch data from the table in a streaming manner: "WUI_Notification" */
  WUI_Notification_stream: Array<Wui_Notification>;
  /** fetch data from the table: "WUI_Order" */
  WUI_Order: Array<Wui_Order>;
  /** fetch data from the table: "WUI_OrderReview" */
  WUI_OrderReview: Array<Wui_OrderReview>;
  /** fetch aggregated fields from the table: "WUI_OrderReview" */
  WUI_OrderReview_aggregate: Wui_OrderReview_Aggregate;
  /** fetch data from the table: "WUI_OrderReview" using primary key columns */
  WUI_OrderReview_by_pk?: Maybe<Wui_OrderReview>;
  /** fetch data from the table in a streaming manner: "WUI_OrderReview" */
  WUI_OrderReview_stream: Array<Wui_OrderReview>;
  /** fetch data from the table: "WUI_OrderStatus" */
  WUI_OrderStatus: Array<Wui_OrderStatus>;
  /** fetch aggregated fields from the table: "WUI_OrderStatus" */
  WUI_OrderStatus_aggregate: Wui_OrderStatus_Aggregate;
  /** fetch data from the table: "WUI_OrderStatus" using primary key columns */
  WUI_OrderStatus_by_pk?: Maybe<Wui_OrderStatus>;
  /** fetch data from the table in a streaming manner: "WUI_OrderStatus" */
  WUI_OrderStatus_stream: Array<Wui_OrderStatus>;
  /** fetch data from the table: "WUI_OrderSubscription" */
  WUI_OrderSubscription: Array<Wui_OrderSubscription>;
  /** fetch data from the table: "WUI_OrderSubscriptionPeriod" */
  WUI_OrderSubscriptionPeriod: Array<Wui_OrderSubscriptionPeriod>;
  /** fetch aggregated fields from the table: "WUI_OrderSubscriptionPeriod" */
  WUI_OrderSubscriptionPeriod_aggregate: Wui_OrderSubscriptionPeriod_Aggregate;
  /** fetch data from the table: "WUI_OrderSubscriptionPeriod" using primary key columns */
  WUI_OrderSubscriptionPeriod_by_pk?: Maybe<Wui_OrderSubscriptionPeriod>;
  /** fetch data from the table in a streaming manner: "WUI_OrderSubscriptionPeriod" */
  WUI_OrderSubscriptionPeriod_stream: Array<Wui_OrderSubscriptionPeriod>;
  /** fetch data from the table: "WUI_OrderSubscriptionStatus" */
  WUI_OrderSubscriptionStatus: Array<Wui_OrderSubscriptionStatus>;
  /** fetch aggregated fields from the table: "WUI_OrderSubscriptionStatus" */
  WUI_OrderSubscriptionStatus_aggregate: Wui_OrderSubscriptionStatus_Aggregate;
  /** fetch data from the table: "WUI_OrderSubscriptionStatus" using primary key columns */
  WUI_OrderSubscriptionStatus_by_pk?: Maybe<Wui_OrderSubscriptionStatus>;
  /** fetch data from the table in a streaming manner: "WUI_OrderSubscriptionStatus" */
  WUI_OrderSubscriptionStatus_stream: Array<Wui_OrderSubscriptionStatus>;
  /** fetch aggregated fields from the table: "WUI_OrderSubscription" */
  WUI_OrderSubscription_aggregate: Wui_OrderSubscription_Aggregate;
  /** fetch data from the table: "WUI_OrderSubscription" using primary key columns */
  WUI_OrderSubscription_by_pk?: Maybe<Wui_OrderSubscription>;
  /** fetch data from the table in a streaming manner: "WUI_OrderSubscription" */
  WUI_OrderSubscription_stream: Array<Wui_OrderSubscription>;
  /** fetch aggregated fields from the table: "WUI_Order" */
  WUI_Order_aggregate: Wui_Order_Aggregate;
  /** fetch data from the table: "WUI_Order" using primary key columns */
  WUI_Order_by_pk?: Maybe<Wui_Order>;
  /** fetch data from the table in a streaming manner: "WUI_Order" */
  WUI_Order_stream: Array<Wui_Order>;
  /** fetch data from the table: "WUI_PackageRequest" */
  WUI_PackageRequest: Array<Wui_PackageRequest>;
  /** fetch aggregated fields from the table: "WUI_PackageRequest" */
  WUI_PackageRequest_aggregate: Wui_PackageRequest_Aggregate;
  /** fetch data from the table: "WUI_PackageRequest" using primary key columns */
  WUI_PackageRequest_by_pk?: Maybe<Wui_PackageRequest>;
  /** fetch data from the table in a streaming manner: "WUI_PackageRequest" */
  WUI_PackageRequest_stream: Array<Wui_PackageRequest>;
  /** fetch data from the table: "WUI_Payment" */
  WUI_Payment: Array<Wui_Payment>;
  /** fetch data from the table: "WUI_PaymentMethod" */
  WUI_PaymentMethod: Array<Wui_PaymentMethod>;
  /** fetch data from the table: "WUI_PaymentMethodBankAccount" */
  WUI_PaymentMethodBankAccount: Array<Wui_PaymentMethodBankAccount>;
  /** fetch aggregated fields from the table: "WUI_PaymentMethodBankAccount" */
  WUI_PaymentMethodBankAccount_aggregate: Wui_PaymentMethodBankAccount_Aggregate;
  /** fetch data from the table: "WUI_PaymentMethodBankAccount" using primary key columns */
  WUI_PaymentMethodBankAccount_by_pk?: Maybe<Wui_PaymentMethodBankAccount>;
  /** fetch data from the table in a streaming manner: "WUI_PaymentMethodBankAccount" */
  WUI_PaymentMethodBankAccount_stream: Array<Wui_PaymentMethodBankAccount>;
  /** fetch data from the table: "WUI_PaymentMethodCard" */
  WUI_PaymentMethodCard: Array<Wui_PaymentMethodCard>;
  /** fetch aggregated fields from the table: "WUI_PaymentMethodCard" */
  WUI_PaymentMethodCard_aggregate: Wui_PaymentMethodCard_Aggregate;
  /** fetch data from the table: "WUI_PaymentMethodCard" using primary key columns */
  WUI_PaymentMethodCard_by_pk?: Maybe<Wui_PaymentMethodCard>;
  /** fetch data from the table in a streaming manner: "WUI_PaymentMethodCard" */
  WUI_PaymentMethodCard_stream: Array<Wui_PaymentMethodCard>;
  /** fetch data from the table: "WUI_PaymentMethodSource" */
  WUI_PaymentMethodSource: Array<Wui_PaymentMethodSource>;
  /** fetch aggregated fields from the table: "WUI_PaymentMethodSource" */
  WUI_PaymentMethodSource_aggregate: Wui_PaymentMethodSource_Aggregate;
  /** fetch data from the table: "WUI_PaymentMethodSource" using primary key columns */
  WUI_PaymentMethodSource_by_pk?: Maybe<Wui_PaymentMethodSource>;
  /** fetch data from the table in a streaming manner: "WUI_PaymentMethodSource" */
  WUI_PaymentMethodSource_stream: Array<Wui_PaymentMethodSource>;
  /** fetch aggregated fields from the table: "WUI_PaymentMethod" */
  WUI_PaymentMethod_aggregate: Wui_PaymentMethod_Aggregate;
  /** fetch data from the table: "WUI_PaymentMethod" using primary key columns */
  WUI_PaymentMethod_by_pk?: Maybe<Wui_PaymentMethod>;
  /** fetch data from the table in a streaming manner: "WUI_PaymentMethod" */
  WUI_PaymentMethod_stream: Array<Wui_PaymentMethod>;
  /** fetch data from the table: "WUI_PaymentStatus" */
  WUI_PaymentStatus: Array<Wui_PaymentStatus>;
  /** fetch aggregated fields from the table: "WUI_PaymentStatus" */
  WUI_PaymentStatus_aggregate: Wui_PaymentStatus_Aggregate;
  /** fetch data from the table: "WUI_PaymentStatus" using primary key columns */
  WUI_PaymentStatus_by_pk?: Maybe<Wui_PaymentStatus>;
  /** fetch data from the table in a streaming manner: "WUI_PaymentStatus" */
  WUI_PaymentStatus_stream: Array<Wui_PaymentStatus>;
  /** fetch aggregated fields from the table: "WUI_Payment" */
  WUI_Payment_aggregate: Wui_Payment_Aggregate;
  /** fetch data from the table: "WUI_Payment" using primary key columns */
  WUI_Payment_by_pk?: Maybe<Wui_Payment>;
  /** fetch data from the table in a streaming manner: "WUI_Payment" */
  WUI_Payment_stream: Array<Wui_Payment>;
  /** fetch data from the table: "WUI_RequestStatus" */
  WUI_RequestStatus: Array<Wui_RequestStatus>;
  /** fetch aggregated fields from the table: "WUI_RequestStatus" */
  WUI_RequestStatus_aggregate: Wui_RequestStatus_Aggregate;
  /** fetch data from the table: "WUI_RequestStatus" using primary key columns */
  WUI_RequestStatus_by_pk?: Maybe<Wui_RequestStatus>;
  /** fetch data from the table in a streaming manner: "WUI_RequestStatus" */
  WUI_RequestStatus_stream: Array<Wui_RequestStatus>;
  /** fetch data from the table: "WUI_Role" */
  WUI_Role: Array<Wui_Role>;
  /** fetch aggregated fields from the table: "WUI_Role" */
  WUI_Role_aggregate: Wui_Role_Aggregate;
  /** fetch data from the table: "WUI_Role" using primary key columns */
  WUI_Role_by_pk?: Maybe<Wui_Role>;
  /** fetch data from the table in a streaming manner: "WUI_Role" */
  WUI_Role_stream: Array<Wui_Role>;
  /** fetch data from the table: "WUI_SellerPackage" */
  WUI_SellerPackage: Array<Wui_SellerPackage>;
  /** fetch data from the table: "WUI_SellerPackageItem" */
  WUI_SellerPackageItem: Array<Wui_SellerPackageItem>;
  /** fetch aggregated fields from the table: "WUI_SellerPackageItem" */
  WUI_SellerPackageItem_aggregate: Wui_SellerPackageItem_Aggregate;
  /** fetch data from the table: "WUI_SellerPackageItem" using primary key columns */
  WUI_SellerPackageItem_by_pk?: Maybe<Wui_SellerPackageItem>;
  /** fetch data from the table in a streaming manner: "WUI_SellerPackageItem" */
  WUI_SellerPackageItem_stream: Array<Wui_SellerPackageItem>;
  /** fetch data from the table: "WUI_SellerPackageType" */
  WUI_SellerPackageType: Array<Wui_SellerPackageType>;
  /** fetch aggregated fields from the table: "WUI_SellerPackageType" */
  WUI_SellerPackageType_aggregate: Wui_SellerPackageType_Aggregate;
  /** fetch data from the table: "WUI_SellerPackageType" using primary key columns */
  WUI_SellerPackageType_by_pk?: Maybe<Wui_SellerPackageType>;
  /** fetch data from the table in a streaming manner: "WUI_SellerPackageType" */
  WUI_SellerPackageType_stream: Array<Wui_SellerPackageType>;
  /** fetch aggregated fields from the table: "WUI_SellerPackage" */
  WUI_SellerPackage_aggregate: Wui_SellerPackage_Aggregate;
  /** fetch data from the table: "WUI_SellerPackage" using primary key columns */
  WUI_SellerPackage_by_pk?: Maybe<Wui_SellerPackage>;
  /** fetch data from the table in a streaming manner: "WUI_SellerPackage" */
  WUI_SellerPackage_stream: Array<Wui_SellerPackage>;
  /** fetch data from the table: "WUI_SellerProject" */
  WUI_SellerProject: Array<Wui_SellerProject>;
  /** fetch data from the table: "WUI_SellerProjectFile" */
  WUI_SellerProjectFile: Array<Wui_SellerProjectFile>;
  /** fetch aggregated fields from the table: "WUI_SellerProjectFile" */
  WUI_SellerProjectFile_aggregate: Wui_SellerProjectFile_Aggregate;
  /** fetch data from the table: "WUI_SellerProjectFile" using primary key columns */
  WUI_SellerProjectFile_by_pk?: Maybe<Wui_SellerProjectFile>;
  /** fetch data from the table in a streaming manner: "WUI_SellerProjectFile" */
  WUI_SellerProjectFile_stream: Array<Wui_SellerProjectFile>;
  /** fetch data from the table: "WUI_SellerProjectItem" */
  WUI_SellerProjectItem: Array<Wui_SellerProjectItem>;
  /** fetch aggregated fields from the table: "WUI_SellerProjectItem" */
  WUI_SellerProjectItem_aggregate: Wui_SellerProjectItem_Aggregate;
  /** fetch data from the table: "WUI_SellerProjectItem" using primary key columns */
  WUI_SellerProjectItem_by_pk?: Maybe<Wui_SellerProjectItem>;
  /** fetch data from the table in a streaming manner: "WUI_SellerProjectItem" */
  WUI_SellerProjectItem_stream: Array<Wui_SellerProjectItem>;
  /** fetch aggregated fields from the table: "WUI_SellerProject" */
  WUI_SellerProject_aggregate: Wui_SellerProject_Aggregate;
  /** fetch data from the table: "WUI_SellerProject" using primary key columns */
  WUI_SellerProject_by_pk?: Maybe<Wui_SellerProject>;
  /** fetch data from the table in a streaming manner: "WUI_SellerProject" */
  WUI_SellerProject_stream: Array<Wui_SellerProject>;
  /** fetch data from the table: "WUI_Session" */
  WUI_Session: Array<Wui_Session>;
  /** fetch data from the table: "WUI_SessionEvent" */
  WUI_SessionEvent: Array<Wui_SessionEvent>;
  /** fetch data from the table: "WUI_SessionEventType" */
  WUI_SessionEventType: Array<Wui_SessionEventType>;
  /** fetch aggregated fields from the table: "WUI_SessionEventType" */
  WUI_SessionEventType_aggregate: Wui_SessionEventType_Aggregate;
  /** fetch data from the table: "WUI_SessionEventType" using primary key columns */
  WUI_SessionEventType_by_pk?: Maybe<Wui_SessionEventType>;
  /** fetch data from the table in a streaming manner: "WUI_SessionEventType" */
  WUI_SessionEventType_stream: Array<Wui_SessionEventType>;
  /** fetch aggregated fields from the table: "WUI_SessionEvent" */
  WUI_SessionEvent_aggregate: Wui_SessionEvent_Aggregate;
  /** fetch data from the table: "WUI_SessionEvent" using primary key columns */
  WUI_SessionEvent_by_pk?: Maybe<Wui_SessionEvent>;
  /** fetch data from the table in a streaming manner: "WUI_SessionEvent" */
  WUI_SessionEvent_stream: Array<Wui_SessionEvent>;
  /** fetch aggregated fields from the table: "WUI_Session" */
  WUI_Session_aggregate: Wui_Session_Aggregate;
  /** fetch data from the table: "WUI_Session" using primary key columns */
  WUI_Session_by_pk?: Maybe<Wui_Session>;
  /** fetch data from the table in a streaming manner: "WUI_Session" */
  WUI_Session_stream: Array<Wui_Session>;
  /** fetch data from the table: "WUI_SocialMediaSource" */
  WUI_SocialMediaSource: Array<Wui_SocialMediaSource>;
  /** fetch aggregated fields from the table: "WUI_SocialMediaSource" */
  WUI_SocialMediaSource_aggregate: Wui_SocialMediaSource_Aggregate;
  /** fetch data from the table: "WUI_SocialMediaSource" using primary key columns */
  WUI_SocialMediaSource_by_pk?: Maybe<Wui_SocialMediaSource>;
  /** fetch data from the table in a streaming manner: "WUI_SocialMediaSource" */
  WUI_SocialMediaSource_stream: Array<Wui_SocialMediaSource>;
  /** fetch data from the table: "WUI_SubscriptionPlan" */
  WUI_SubscriptionPlan: Array<Wui_SubscriptionPlan>;
  /** fetch aggregated fields from the table: "WUI_SubscriptionPlan" */
  WUI_SubscriptionPlan_aggregate: Wui_SubscriptionPlan_Aggregate;
  /** fetch data from the table: "WUI_SubscriptionPlan" using primary key columns */
  WUI_SubscriptionPlan_by_pk?: Maybe<Wui_SubscriptionPlan>;
  /** fetch data from the table in a streaming manner: "WUI_SubscriptionPlan" */
  WUI_SubscriptionPlan_stream: Array<Wui_SubscriptionPlan>;
  /** fetch data from the table: "WUI_User" */
  WUI_User: Array<Wui_User>;
  /** fetch data from the table: "WUI_UserFilePortfolio" */
  WUI_UserFilePortfolio: Array<Wui_UserFilePortfolio>;
  /** fetch aggregated fields from the table: "WUI_UserFilePortfolio" */
  WUI_UserFilePortfolio_aggregate: Wui_UserFilePortfolio_Aggregate;
  /** fetch data from the table: "WUI_UserFilePortfolio" using primary key columns */
  WUI_UserFilePortfolio_by_pk?: Maybe<Wui_UserFilePortfolio>;
  /** fetch data from the table in a streaming manner: "WUI_UserFilePortfolio" */
  WUI_UserFilePortfolio_stream: Array<Wui_UserFilePortfolio>;
  /** fetch data from the table: "WUI_UserReview" */
  WUI_UserReview: Array<Wui_UserReview>;
  /** fetch aggregated fields from the table: "WUI_UserReview" */
  WUI_UserReview_aggregate: Wui_UserReview_Aggregate;
  /** fetch data from the table: "WUI_UserReview" using primary key columns */
  WUI_UserReview_by_pk?: Maybe<Wui_UserReview>;
  /** fetch data from the table in a streaming manner: "WUI_UserReview" */
  WUI_UserReview_stream: Array<Wui_UserReview>;
  /** fetch data from the table: "WUI_UserRole" */
  WUI_UserRole: Array<Wui_UserRole>;
  /** fetch aggregated fields from the table: "WUI_UserRole" */
  WUI_UserRole_aggregate: Wui_UserRole_Aggregate;
  /** fetch data from the table in a streaming manner: "WUI_UserRole" */
  WUI_UserRole_stream: Array<Wui_UserRole>;
  /** fetch data from the table: "WUI_UserSubscription" */
  WUI_UserSubscription: Array<Wui_UserSubscription>;
  /** fetch aggregated fields from the table: "WUI_UserSubscription" */
  WUI_UserSubscription_aggregate: Wui_UserSubscription_Aggregate;
  /** fetch data from the table: "WUI_UserSubscription" using primary key columns */
  WUI_UserSubscription_by_pk?: Maybe<Wui_UserSubscription>;
  /** fetch data from the table in a streaming manner: "WUI_UserSubscription" */
  WUI_UserSubscription_stream: Array<Wui_UserSubscription>;
  /** fetch data from the table: "WUI_UserType" */
  WUI_UserType: Array<Wui_UserType>;
  /** fetch aggregated fields from the table: "WUI_UserType" */
  WUI_UserType_aggregate: Wui_UserType_Aggregate;
  /** fetch data from the table: "WUI_UserType" using primary key columns */
  WUI_UserType_by_pk?: Maybe<Wui_UserType>;
  /** fetch data from the table in a streaming manner: "WUI_UserType" */
  WUI_UserType_stream: Array<Wui_UserType>;
  /** fetch aggregated fields from the table: "WUI_User" */
  WUI_User_aggregate: Wui_User_Aggregate;
  /** fetch data from the table: "WUI_User" using primary key columns */
  WUI_User_by_pk?: Maybe<Wui_User>;
  /** fetch data from the table in a streaming manner: "WUI_User" */
  WUI_User_stream: Array<Wui_User>;
  /** fetch data from the table: "WUI_VerificationToken" */
  WUI_VerificationToken: Array<Wui_VerificationToken>;
  /** fetch aggregated fields from the table: "WUI_VerificationToken" */
  WUI_VerificationToken_aggregate: Wui_VerificationToken_Aggregate;
  /** fetch data from the table: "WUI_VerificationToken" using primary key columns */
  WUI_VerificationToken_by_pk?: Maybe<Wui_VerificationToken>;
  /** fetch data from the table in a streaming manner: "WUI_VerificationToken" */
  WUI_VerificationToken_stream: Array<Wui_VerificationToken>;
  /** fetch data from the table: "unclaimed_demographics" */
  unclaimed_demographics: Array<Unclaimed_Demographics>;
  /** fetch aggregated fields from the table: "unclaimed_demographics" */
  unclaimed_demographics_aggregate: Unclaimed_Demographics_Aggregate;
  /** fetch data from the table: "unclaimed_demographics" using primary key columns */
  unclaimed_demographics_by_pk?: Maybe<Unclaimed_Demographics>;
  /** fetch data from the table in a streaming manner: "unclaimed_demographics" */
  unclaimed_demographics_stream: Array<Unclaimed_Demographics>;
  /** fetch data from the table: "unclaimed_profile" */
  unclaimed_profile: Array<Unclaimed_Profile>;
  /** fetch aggregated fields from the table: "unclaimed_profile" */
  unclaimed_profile_aggregate: Unclaimed_Profile_Aggregate;
  /** fetch data from the table: "unclaimed_profile" using primary key columns */
  unclaimed_profile_by_pk?: Maybe<Unclaimed_Profile>;
  /** fetch data from the table in a streaming manner: "unclaimed_profile" */
  unclaimed_profile_stream: Array<Unclaimed_Profile>;
  /** fetch data from the table: "votesbyuser" */
  votesbyuser: Array<Votesbyuser>;
  /** fetch aggregated fields from the table: "votesbyuser" */
  votesbyuser_aggregate: Votesbyuser_Aggregate;
  /** fetch data from the table in a streaming manner: "votesbyuser" */
  votesbyuser_stream: Array<Votesbyuser>;
  /** fetch data from the table: "wui_userlastseen" */
  wui_userlastseen: Array<Wui_Userlastseen>;
  /** fetch aggregated fields from the table: "wui_userlastseen" */
  wui_userlastseen_aggregate: Wui_Userlastseen_Aggregate;
  /** fetch data from the table in a streaming manner: "wui_userlastseen" */
  wui_userlastseen_stream: Array<Wui_Userlastseen>;
};


export type Subscription_RootAmbassadorArgs = {
  distinct_on?: InputMaybe<Array<Ambassador_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ambassador_Order_By>>;
  where?: InputMaybe<Ambassador_Bool_Exp>;
};


export type Subscription_RootAmbassador_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ambassador_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ambassador_Order_By>>;
  where?: InputMaybe<Ambassador_Bool_Exp>;
};


export type Subscription_RootAmbassador_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAmbassador_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ambassador_Stream_Cursor_Input>>;
  where?: InputMaybe<Ambassador_Bool_Exp>;
};


export type Subscription_RootDemographicArgs = {
  distinct_on?: InputMaybe<Array<Demographic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Demographic_Order_By>>;
  where?: InputMaybe<Demographic_Bool_Exp>;
};


export type Subscription_RootDemographic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Demographic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Demographic_Order_By>>;
  where?: InputMaybe<Demographic_Bool_Exp>;
};


export type Subscription_RootDemographic_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootDemographic_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Demographic_Stream_Cursor_Input>>;
  where?: InputMaybe<Demographic_Bool_Exp>;
};


export type Subscription_RootEmailDomainLookupArgs = {
  distinct_on?: InputMaybe<Array<EmailDomainLookup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailDomainLookup_Order_By>>;
  where?: InputMaybe<EmailDomainLookup_Bool_Exp>;
};


export type Subscription_RootEmailDomainLookup_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailDomainLookup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailDomainLookup_Order_By>>;
  where?: InputMaybe<EmailDomainLookup_Bool_Exp>;
};


export type Subscription_RootEmailDomainLookup_By_PkArgs = {
  domain: Scalars['String'];
};


export type Subscription_RootEmailDomainLookup_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmailDomainLookup_Stream_Cursor_Input>>;
  where?: InputMaybe<EmailDomainLookup_Bool_Exp>;
};


export type Subscription_RootHeroBannerArgs = {
  distinct_on?: InputMaybe<Array<HeroBanner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HeroBanner_Order_By>>;
  where?: InputMaybe<HeroBanner_Bool_Exp>;
};


export type Subscription_RootHeroBanner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HeroBanner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HeroBanner_Order_By>>;
  where?: InputMaybe<HeroBanner_Bool_Exp>;
};


export type Subscription_RootHeroBanner_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootHeroBanner_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<HeroBanner_Stream_Cursor_Input>>;
  where?: InputMaybe<HeroBanner_Bool_Exp>;
};


export type Subscription_RootIndustryArgs = {
  distinct_on?: InputMaybe<Array<Industry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Industry_Order_By>>;
  where?: InputMaybe<Industry_Bool_Exp>;
};


export type Subscription_RootIndustry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Industry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Industry_Order_By>>;
  where?: InputMaybe<Industry_Bool_Exp>;
};


export type Subscription_RootIndustry_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootIndustry_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Industry_Stream_Cursor_Input>>;
  where?: InputMaybe<Industry_Bool_Exp>;
};


export type Subscription_RootMarketplaceWaitlistArgs = {
  distinct_on?: InputMaybe<Array<MarketplaceWaitlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketplaceWaitlist_Order_By>>;
  where?: InputMaybe<MarketplaceWaitlist_Bool_Exp>;
};


export type Subscription_RootMarketplaceWaitlist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MarketplaceWaitlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketplaceWaitlist_Order_By>>;
  where?: InputMaybe<MarketplaceWaitlist_Bool_Exp>;
};


export type Subscription_RootMarketplaceWaitlist_By_PkArgs = {
  user_id: Scalars['bigint'];
};


export type Subscription_RootMarketplaceWaitlist_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MarketplaceWaitlist_Stream_Cursor_Input>>;
  where?: InputMaybe<MarketplaceWaitlist_Bool_Exp>;
};


export type Subscription_RootPlatformAlertArgs = {
  distinct_on?: InputMaybe<Array<PlatformAlert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlatformAlert_Order_By>>;
  where?: InputMaybe<PlatformAlert_Bool_Exp>;
};


export type Subscription_RootPlatformAlert_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PlatformAlert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlatformAlert_Order_By>>;
  where?: InputMaybe<PlatformAlert_Bool_Exp>;
};


export type Subscription_RootPlatformAlert_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPlatformAlert_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PlatformAlert_Stream_Cursor_Input>>;
  where?: InputMaybe<PlatformAlert_Bool_Exp>;
};


export type Subscription_RootProjectDemographicArgs = {
  distinct_on?: InputMaybe<Array<ProjectDemographic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProjectDemographic_Order_By>>;
  where?: InputMaybe<ProjectDemographic_Bool_Exp>;
};


export type Subscription_RootProjectDemographic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProjectDemographic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProjectDemographic_Order_By>>;
  where?: InputMaybe<ProjectDemographic_Bool_Exp>;
};


export type Subscription_RootProjectDemographic_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProjectDemographic_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ProjectDemographic_Stream_Cursor_Input>>;
  where?: InputMaybe<ProjectDemographic_Bool_Exp>;
};


export type Subscription_RootSignupSourceArgs = {
  distinct_on?: InputMaybe<Array<SignupSource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignupSource_Order_By>>;
  where?: InputMaybe<SignupSource_Bool_Exp>;
};


export type Subscription_RootSignupSourceTrackerArgs = {
  distinct_on?: InputMaybe<Array<SignupSourceTracker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignupSourceTracker_Order_By>>;
  where?: InputMaybe<SignupSourceTracker_Bool_Exp>;
};


export type Subscription_RootSignupSourceTracker_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SignupSourceTracker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignupSourceTracker_Order_By>>;
  where?: InputMaybe<SignupSourceTracker_Bool_Exp>;
};


export type Subscription_RootSignupSourceTracker_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSignupSourceTracker_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SignupSourceTracker_Stream_Cursor_Input>>;
  where?: InputMaybe<SignupSourceTracker_Bool_Exp>;
};


export type Subscription_RootSignupSource_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SignupSource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignupSource_Order_By>>;
  where?: InputMaybe<SignupSource_Bool_Exp>;
};


export type Subscription_RootSignupSource_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootSignupSource_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SignupSource_Stream_Cursor_Input>>;
  where?: InputMaybe<SignupSource_Bool_Exp>;
};


export type Subscription_RootSocialMediaArgs = {
  distinct_on?: InputMaybe<Array<SocialMedia_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMedia_Order_By>>;
  where?: InputMaybe<SocialMedia_Bool_Exp>;
};


export type Subscription_RootSocialMediaDemographicArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaDemographic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaDemographic_Order_By>>;
  where?: InputMaybe<SocialMediaDemographic_Bool_Exp>;
};


export type Subscription_RootSocialMediaDemographic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaDemographic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaDemographic_Order_By>>;
  where?: InputMaybe<SocialMediaDemographic_Bool_Exp>;
};


export type Subscription_RootSocialMediaDemographic_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSocialMediaDemographic_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SocialMediaDemographic_Stream_Cursor_Input>>;
  where?: InputMaybe<SocialMediaDemographic_Bool_Exp>;
};


export type Subscription_RootSocialMediaHistoryArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaHistory_Order_By>>;
  where?: InputMaybe<SocialMediaHistory_Bool_Exp>;
};


export type Subscription_RootSocialMediaHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaHistory_Order_By>>;
  where?: InputMaybe<SocialMediaHistory_Bool_Exp>;
};


export type Subscription_RootSocialMediaHistory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSocialMediaHistory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SocialMediaHistory_Stream_Cursor_Input>>;
  where?: InputMaybe<SocialMediaHistory_Bool_Exp>;
};


export type Subscription_RootSocialMediaLinkArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaLink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaLink_Order_By>>;
  where?: InputMaybe<SocialMediaLink_Bool_Exp>;
};


export type Subscription_RootSocialMediaLink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaLink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaLink_Order_By>>;
  where?: InputMaybe<SocialMediaLink_Bool_Exp>;
};


export type Subscription_RootSocialMediaLink_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSocialMediaLink_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SocialMediaLink_Stream_Cursor_Input>>;
  where?: InputMaybe<SocialMediaLink_Bool_Exp>;
};


export type Subscription_RootSocialMediaPlatformArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaPlatform_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaPlatform_Order_By>>;
  where?: InputMaybe<SocialMediaPlatform_Bool_Exp>;
};


export type Subscription_RootSocialMediaPlatform_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaPlatform_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaPlatform_Order_By>>;
  where?: InputMaybe<SocialMediaPlatform_Bool_Exp>;
};


export type Subscription_RootSocialMediaPlatform_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootSocialMediaPlatform_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SocialMediaPlatform_Stream_Cursor_Input>>;
  where?: InputMaybe<SocialMediaPlatform_Bool_Exp>;
};


export type Subscription_RootSocialMedia_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMedia_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMedia_Order_By>>;
  where?: InputMaybe<SocialMedia_Bool_Exp>;
};


export type Subscription_RootSocialMedia_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSocialMedia_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SocialMedia_Stream_Cursor_Input>>;
  where?: InputMaybe<SocialMedia_Bool_Exp>;
};


export type Subscription_RootTempLoginWithPasswordArgs = {
  distinct_on?: InputMaybe<Array<TempLoginWithPassword_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TempLoginWithPassword_Order_By>>;
  where?: InputMaybe<TempLoginWithPassword_Bool_Exp>;
};


export type Subscription_RootTempLoginWithPassword_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TempLoginWithPassword_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TempLoginWithPassword_Order_By>>;
  where?: InputMaybe<TempLoginWithPassword_Bool_Exp>;
};


export type Subscription_RootTempLoginWithPassword_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTempLoginWithPassword_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TempLoginWithPassword_Stream_Cursor_Input>>;
  where?: InputMaybe<TempLoginWithPassword_Bool_Exp>;
};


export type Subscription_RootUserVideoArgs = {
  distinct_on?: InputMaybe<Array<UserVideo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserVideo_Order_By>>;
  where?: InputMaybe<UserVideo_Bool_Exp>;
};


export type Subscription_RootUserVideo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserVideo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserVideo_Order_By>>;
  where?: InputMaybe<UserVideo_Bool_Exp>;
};


export type Subscription_RootUserVideo_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUserVideo_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserVideo_Stream_Cursor_Input>>;
  where?: InputMaybe<UserVideo_Bool_Exp>;
};


export type Subscription_RootVoteArgs = {
  distinct_on?: InputMaybe<Array<Vote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vote_Order_By>>;
  where?: InputMaybe<Vote_Bool_Exp>;
};


export type Subscription_RootVoteCategoryArgs = {
  distinct_on?: InputMaybe<Array<VoteCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VoteCategory_Order_By>>;
  where?: InputMaybe<VoteCategory_Bool_Exp>;
};


export type Subscription_RootVoteCategory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VoteCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VoteCategory_Order_By>>;
  where?: InputMaybe<VoteCategory_Bool_Exp>;
};


export type Subscription_RootVoteCategory_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootVoteCategory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<VoteCategory_Stream_Cursor_Input>>;
  where?: InputMaybe<VoteCategory_Bool_Exp>;
};


export type Subscription_RootVoteUserArgs = {
  distinct_on?: InputMaybe<Array<VoteUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VoteUser_Order_By>>;
  where?: InputMaybe<VoteUser_Bool_Exp>;
};


export type Subscription_RootVoteUserCategoryArgs = {
  distinct_on?: InputMaybe<Array<VoteUserCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VoteUserCategory_Order_By>>;
  where?: InputMaybe<VoteUserCategory_Bool_Exp>;
};


export type Subscription_RootVoteUserCategory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VoteUserCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VoteUserCategory_Order_By>>;
  where?: InputMaybe<VoteUserCategory_Bool_Exp>;
};


export type Subscription_RootVoteUserCategory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVoteUserCategory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<VoteUserCategory_Stream_Cursor_Input>>;
  where?: InputMaybe<VoteUserCategory_Bool_Exp>;
};


export type Subscription_RootVoteUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VoteUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VoteUser_Order_By>>;
  where?: InputMaybe<VoteUser_Bool_Exp>;
};


export type Subscription_RootVoteUser_By_PkArgs = {
  user_id: Scalars['bigint'];
};


export type Subscription_RootVoteUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<VoteUser_Stream_Cursor_Input>>;
  where?: InputMaybe<VoteUser_Bool_Exp>;
};


export type Subscription_RootVote_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vote_Order_By>>;
  where?: InputMaybe<Vote_Bool_Exp>;
};


export type Subscription_RootVote_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVote_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Vote_Stream_Cursor_Input>>;
  where?: InputMaybe<Vote_Bool_Exp>;
};


export type Subscription_RootVotesByUserCategoryIdArgs = {
  distinct_on?: InputMaybe<Array<VotesByUserCategoryId_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VotesByUserCategoryId_Order_By>>;
  where?: InputMaybe<VotesByUserCategoryId_Bool_Exp>;
};


export type Subscription_RootVotesByUserCategoryId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VotesByUserCategoryId_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VotesByUserCategoryId_Order_By>>;
  where?: InputMaybe<VotesByUserCategoryId_Bool_Exp>;
};


export type Subscription_RootVotesByUserCategoryId_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<VotesByUserCategoryId_Stream_Cursor_Input>>;
  where?: InputMaybe<VotesByUserCategoryId_Bool_Exp>;
};


export type Subscription_RootWui_AccountArgs = {
  distinct_on?: InputMaybe<Array<Wui_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Account_Order_By>>;
  where?: InputMaybe<Wui_Account_Bool_Exp>;
};


export type Subscription_RootWui_Account_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Account_Order_By>>;
  where?: InputMaybe<Wui_Account_Bool_Exp>;
};


export type Subscription_RootWui_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWui_Account_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_Account_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_Account_Bool_Exp>;
};


export type Subscription_RootWui_AffiliateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Affiliate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Affiliate_Order_By>>;
  where?: InputMaybe<Wui_Affiliate_Bool_Exp>;
};


export type Subscription_RootWui_AffiliateLevelArgs = {
  distinct_on?: InputMaybe<Array<Wui_AffiliateLevel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AffiliateLevel_Order_By>>;
  where?: InputMaybe<Wui_AffiliateLevel_Bool_Exp>;
};


export type Subscription_RootWui_AffiliateLevel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_AffiliateLevel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AffiliateLevel_Order_By>>;
  where?: InputMaybe<Wui_AffiliateLevel_Bool_Exp>;
};


export type Subscription_RootWui_AffiliateLevel_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_AffiliateLevel_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_AffiliateLevel_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_AffiliateLevel_Bool_Exp>;
};


export type Subscription_RootWui_AffiliateStatusArgs = {
  distinct_on?: InputMaybe<Array<Wui_AffiliateStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AffiliateStatus_Order_By>>;
  where?: InputMaybe<Wui_AffiliateStatus_Bool_Exp>;
};


export type Subscription_RootWui_AffiliateStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_AffiliateStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AffiliateStatus_Order_By>>;
  where?: InputMaybe<Wui_AffiliateStatus_Bool_Exp>;
};


export type Subscription_RootWui_AffiliateStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_AffiliateStatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_AffiliateStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_AffiliateStatus_Bool_Exp>;
};


export type Subscription_RootWui_AffiliateTransactionArgs = {
  distinct_on?: InputMaybe<Array<Wui_AffiliateTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AffiliateTransaction_Order_By>>;
  where?: InputMaybe<Wui_AffiliateTransaction_Bool_Exp>;
};


export type Subscription_RootWui_AffiliateTransaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_AffiliateTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AffiliateTransaction_Order_By>>;
  where?: InputMaybe<Wui_AffiliateTransaction_Bool_Exp>;
};


export type Subscription_RootWui_AffiliateTransaction_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWui_AffiliateTransaction_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_AffiliateTransaction_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_AffiliateTransaction_Bool_Exp>;
};


export type Subscription_RootWui_AffiliateUsersArgs = {
  distinct_on?: InputMaybe<Array<Wui_AffiliateUsers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AffiliateUsers_Order_By>>;
  where?: InputMaybe<Wui_AffiliateUsers_Bool_Exp>;
};


export type Subscription_RootWui_AffiliateUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_AffiliateUsers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AffiliateUsers_Order_By>>;
  where?: InputMaybe<Wui_AffiliateUsers_Bool_Exp>;
};


export type Subscription_RootWui_AffiliateUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWui_AffiliateUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_AffiliateUsers_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_AffiliateUsers_Bool_Exp>;
};


export type Subscription_RootWui_Affiliate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Affiliate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Affiliate_Order_By>>;
  where?: InputMaybe<Wui_Affiliate_Bool_Exp>;
};


export type Subscription_RootWui_Affiliate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWui_Affiliate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_Affiliate_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_Affiliate_Bool_Exp>;
};


export type Subscription_RootWui_AuthProviderArgs = {
  distinct_on?: InputMaybe<Array<Wui_AuthProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AuthProvider_Order_By>>;
  where?: InputMaybe<Wui_AuthProvider_Bool_Exp>;
};


export type Subscription_RootWui_AuthProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_AuthProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AuthProvider_Order_By>>;
  where?: InputMaybe<Wui_AuthProvider_Bool_Exp>;
};


export type Subscription_RootWui_AuthProvider_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_AuthProvider_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_AuthProvider_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_AuthProvider_Bool_Exp>;
};


export type Subscription_RootWui_AuthSessionArgs = {
  distinct_on?: InputMaybe<Array<Wui_AuthSession_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AuthSession_Order_By>>;
  where?: InputMaybe<Wui_AuthSession_Bool_Exp>;
};


export type Subscription_RootWui_AuthSession_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_AuthSession_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_AuthSession_Order_By>>;
  where?: InputMaybe<Wui_AuthSession_Bool_Exp>;
};


export type Subscription_RootWui_AuthSession_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWui_AuthSession_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_AuthSession_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_AuthSession_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoom_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoom_Order_By>>;
  where?: InputMaybe<Wui_ChatRoom_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomMessageArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomMessage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomMessage_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomMessage_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomMessage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomMessage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomMessage_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomMessage_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomMessage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_ChatRoomMessage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_ChatRoomMessage_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_ChatRoomMessage_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomOrderArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomOrder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomOrder_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomOrder_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomOrder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomOrder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomOrder_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomOrder_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomOrder_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_ChatRoomOrder_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_ChatRoomOrder_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_ChatRoomOrder_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomTypeArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomType_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomType_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomType_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomType_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomType_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_ChatRoomType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_ChatRoomType_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_ChatRoomType_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomUserArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomUser_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomUser_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoomUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoomUser_Order_By>>;
  where?: InputMaybe<Wui_ChatRoomUser_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomUser_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_ChatRoomUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_ChatRoomUser_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_ChatRoomUser_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoom_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_ChatRoom_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_ChatRoom_Order_By>>;
  where?: InputMaybe<Wui_ChatRoom_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoom_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_ChatRoom_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_ChatRoom_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_ChatRoom_Bool_Exp>;
};


export type Subscription_RootWui_FileArgs = {
  distinct_on?: InputMaybe<Array<Wui_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_File_Order_By>>;
  where?: InputMaybe<Wui_File_Bool_Exp>;
};


export type Subscription_RootWui_FileTypeArgs = {
  distinct_on?: InputMaybe<Array<Wui_FileType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_FileType_Order_By>>;
  where?: InputMaybe<Wui_FileType_Bool_Exp>;
};


export type Subscription_RootWui_FileType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_FileType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_FileType_Order_By>>;
  where?: InputMaybe<Wui_FileType_Bool_Exp>;
};


export type Subscription_RootWui_FileType_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_FileType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_FileType_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_FileType_Bool_Exp>;
};


export type Subscription_RootWui_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_File_Order_By>>;
  where?: InputMaybe<Wui_File_Bool_Exp>;
};


export type Subscription_RootWui_File_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_File_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_File_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_File_Bool_Exp>;
};


export type Subscription_RootWui_NotificationArgs = {
  distinct_on?: InputMaybe<Array<Wui_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Notification_Order_By>>;
  where?: InputMaybe<Wui_Notification_Bool_Exp>;
};


export type Subscription_RootWui_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Notification_Order_By>>;
  where?: InputMaybe<Wui_Notification_Bool_Exp>;
};


export type Subscription_RootWui_Notification_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_Notification_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_Notification_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_Notification_Bool_Exp>;
};


export type Subscription_RootWui_OrderArgs = {
  distinct_on?: InputMaybe<Array<Wui_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Order_Order_By>>;
  where?: InputMaybe<Wui_Order_Bool_Exp>;
};


export type Subscription_RootWui_OrderReviewArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderReview_Order_By>>;
  where?: InputMaybe<Wui_OrderReview_Bool_Exp>;
};


export type Subscription_RootWui_OrderReview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderReview_Order_By>>;
  where?: InputMaybe<Wui_OrderReview_Bool_Exp>;
};


export type Subscription_RootWui_OrderReview_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_OrderReview_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_OrderReview_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_OrderReview_Bool_Exp>;
};


export type Subscription_RootWui_OrderStatusArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderStatus_Order_By>>;
  where?: InputMaybe<Wui_OrderStatus_Bool_Exp>;
};


export type Subscription_RootWui_OrderStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderStatus_Order_By>>;
  where?: InputMaybe<Wui_OrderStatus_Bool_Exp>;
};


export type Subscription_RootWui_OrderStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_OrderStatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_OrderStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_OrderStatus_Bool_Exp>;
};


export type Subscription_RootWui_OrderSubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderSubscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderSubscription_Order_By>>;
  where?: InputMaybe<Wui_OrderSubscription_Bool_Exp>;
};


export type Subscription_RootWui_OrderSubscriptionPeriodArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderSubscriptionPeriod_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderSubscriptionPeriod_Order_By>>;
  where?: InputMaybe<Wui_OrderSubscriptionPeriod_Bool_Exp>;
};


export type Subscription_RootWui_OrderSubscriptionPeriod_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderSubscriptionPeriod_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderSubscriptionPeriod_Order_By>>;
  where?: InputMaybe<Wui_OrderSubscriptionPeriod_Bool_Exp>;
};


export type Subscription_RootWui_OrderSubscriptionPeriod_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_OrderSubscriptionPeriod_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_OrderSubscriptionPeriod_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_OrderSubscriptionPeriod_Bool_Exp>;
};


export type Subscription_RootWui_OrderSubscriptionStatusArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderSubscriptionStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderSubscriptionStatus_Order_By>>;
  where?: InputMaybe<Wui_OrderSubscriptionStatus_Bool_Exp>;
};


export type Subscription_RootWui_OrderSubscriptionStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderSubscriptionStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderSubscriptionStatus_Order_By>>;
  where?: InputMaybe<Wui_OrderSubscriptionStatus_Bool_Exp>;
};


export type Subscription_RootWui_OrderSubscriptionStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_OrderSubscriptionStatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_OrderSubscriptionStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_OrderSubscriptionStatus_Bool_Exp>;
};


export type Subscription_RootWui_OrderSubscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_OrderSubscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_OrderSubscription_Order_By>>;
  where?: InputMaybe<Wui_OrderSubscription_Bool_Exp>;
};


export type Subscription_RootWui_OrderSubscription_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_OrderSubscription_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_OrderSubscription_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_OrderSubscription_Bool_Exp>;
};


export type Subscription_RootWui_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Order_Order_By>>;
  where?: InputMaybe<Wui_Order_Bool_Exp>;
};


export type Subscription_RootWui_Order_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_Order_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_Order_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_Order_Bool_Exp>;
};


export type Subscription_RootWui_PackageRequestArgs = {
  distinct_on?: InputMaybe<Array<Wui_PackageRequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PackageRequest_Order_By>>;
  where?: InputMaybe<Wui_PackageRequest_Bool_Exp>;
};


export type Subscription_RootWui_PackageRequest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_PackageRequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PackageRequest_Order_By>>;
  where?: InputMaybe<Wui_PackageRequest_Bool_Exp>;
};


export type Subscription_RootWui_PackageRequest_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_PackageRequest_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_PackageRequest_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_PackageRequest_Bool_Exp>;
};


export type Subscription_RootWui_PaymentArgs = {
  distinct_on?: InputMaybe<Array<Wui_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Payment_Order_By>>;
  where?: InputMaybe<Wui_Payment_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethodArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethod_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethod_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethod_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethodBankAccountArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethodBankAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethodBankAccount_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethodBankAccount_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethodBankAccount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethodBankAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethodBankAccount_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethodBankAccount_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethodBankAccount_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootWui_PaymentMethodBankAccount_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_PaymentMethodBankAccount_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_PaymentMethodBankAccount_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethodCardArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethodCard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethodCard_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethodCard_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethodCard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethodCard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethodCard_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethodCard_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethodCard_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_PaymentMethodCard_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_PaymentMethodCard_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_PaymentMethodCard_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethodSourceArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethodSource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethodSource_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethodSource_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethodSource_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethodSource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethodSource_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethodSource_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethodSource_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_PaymentMethodSource_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_PaymentMethodSource_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_PaymentMethodSource_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethod_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentMethod_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentMethod_Order_By>>;
  where?: InputMaybe<Wui_PaymentMethod_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethod_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_PaymentMethod_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_PaymentMethod_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_PaymentMethod_Bool_Exp>;
};


export type Subscription_RootWui_PaymentStatusArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentStatus_Order_By>>;
  where?: InputMaybe<Wui_PaymentStatus_Bool_Exp>;
};


export type Subscription_RootWui_PaymentStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_PaymentStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_PaymentStatus_Order_By>>;
  where?: InputMaybe<Wui_PaymentStatus_Bool_Exp>;
};


export type Subscription_RootWui_PaymentStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_PaymentStatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_PaymentStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_PaymentStatus_Bool_Exp>;
};


export type Subscription_RootWui_Payment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Payment_Order_By>>;
  where?: InputMaybe<Wui_Payment_Bool_Exp>;
};


export type Subscription_RootWui_Payment_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_Payment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_Payment_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_Payment_Bool_Exp>;
};


export type Subscription_RootWui_RequestStatusArgs = {
  distinct_on?: InputMaybe<Array<Wui_RequestStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_RequestStatus_Order_By>>;
  where?: InputMaybe<Wui_RequestStatus_Bool_Exp>;
};


export type Subscription_RootWui_RequestStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_RequestStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_RequestStatus_Order_By>>;
  where?: InputMaybe<Wui_RequestStatus_Bool_Exp>;
};


export type Subscription_RootWui_RequestStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_RequestStatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_RequestStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_RequestStatus_Bool_Exp>;
};


export type Subscription_RootWui_RoleArgs = {
  distinct_on?: InputMaybe<Array<Wui_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Role_Order_By>>;
  where?: InputMaybe<Wui_Role_Bool_Exp>;
};


export type Subscription_RootWui_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Role_Order_By>>;
  where?: InputMaybe<Wui_Role_Bool_Exp>;
};


export type Subscription_RootWui_Role_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootWui_Role_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_Role_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_Role_Bool_Exp>;
};


export type Subscription_RootWui_SellerPackageArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerPackage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerPackage_Order_By>>;
  where?: InputMaybe<Wui_SellerPackage_Bool_Exp>;
};


export type Subscription_RootWui_SellerPackageItemArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerPackageItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerPackageItem_Order_By>>;
  where?: InputMaybe<Wui_SellerPackageItem_Bool_Exp>;
};


export type Subscription_RootWui_SellerPackageItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerPackageItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerPackageItem_Order_By>>;
  where?: InputMaybe<Wui_SellerPackageItem_Bool_Exp>;
};


export type Subscription_RootWui_SellerPackageItem_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_SellerPackageItem_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_SellerPackageItem_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_SellerPackageItem_Bool_Exp>;
};


export type Subscription_RootWui_SellerPackageTypeArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerPackageType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerPackageType_Order_By>>;
  where?: InputMaybe<Wui_SellerPackageType_Bool_Exp>;
};


export type Subscription_RootWui_SellerPackageType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerPackageType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerPackageType_Order_By>>;
  where?: InputMaybe<Wui_SellerPackageType_Bool_Exp>;
};


export type Subscription_RootWui_SellerPackageType_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_SellerPackageType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_SellerPackageType_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_SellerPackageType_Bool_Exp>;
};


export type Subscription_RootWui_SellerPackage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerPackage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerPackage_Order_By>>;
  where?: InputMaybe<Wui_SellerPackage_Bool_Exp>;
};


export type Subscription_RootWui_SellerPackage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_SellerPackage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_SellerPackage_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_SellerPackage_Bool_Exp>;
};


export type Subscription_RootWui_SellerProjectArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerProject_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerProject_Order_By>>;
  where?: InputMaybe<Wui_SellerProject_Bool_Exp>;
};


export type Subscription_RootWui_SellerProjectFileArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerProjectFile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerProjectFile_Order_By>>;
  where?: InputMaybe<Wui_SellerProjectFile_Bool_Exp>;
};


export type Subscription_RootWui_SellerProjectFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerProjectFile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerProjectFile_Order_By>>;
  where?: InputMaybe<Wui_SellerProjectFile_Bool_Exp>;
};


export type Subscription_RootWui_SellerProjectFile_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootWui_SellerProjectFile_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_SellerProjectFile_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_SellerProjectFile_Bool_Exp>;
};


export type Subscription_RootWui_SellerProjectItemArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerProjectItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerProjectItem_Order_By>>;
  where?: InputMaybe<Wui_SellerProjectItem_Bool_Exp>;
};


export type Subscription_RootWui_SellerProjectItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerProjectItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerProjectItem_Order_By>>;
  where?: InputMaybe<Wui_SellerProjectItem_Bool_Exp>;
};


export type Subscription_RootWui_SellerProjectItem_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_SellerProjectItem_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_SellerProjectItem_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_SellerProjectItem_Bool_Exp>;
};


export type Subscription_RootWui_SellerProject_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SellerProject_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SellerProject_Order_By>>;
  where?: InputMaybe<Wui_SellerProject_Bool_Exp>;
};


export type Subscription_RootWui_SellerProject_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_SellerProject_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_SellerProject_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_SellerProject_Bool_Exp>;
};


export type Subscription_RootWui_SessionArgs = {
  distinct_on?: InputMaybe<Array<Wui_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Session_Order_By>>;
  where?: InputMaybe<Wui_Session_Bool_Exp>;
};


export type Subscription_RootWui_SessionEventArgs = {
  distinct_on?: InputMaybe<Array<Wui_SessionEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SessionEvent_Order_By>>;
  where?: InputMaybe<Wui_SessionEvent_Bool_Exp>;
};


export type Subscription_RootWui_SessionEventTypeArgs = {
  distinct_on?: InputMaybe<Array<Wui_SessionEventType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SessionEventType_Order_By>>;
  where?: InputMaybe<Wui_SessionEventType_Bool_Exp>;
};


export type Subscription_RootWui_SessionEventType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SessionEventType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SessionEventType_Order_By>>;
  where?: InputMaybe<Wui_SessionEventType_Bool_Exp>;
};


export type Subscription_RootWui_SessionEventType_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_SessionEventType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_SessionEventType_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_SessionEventType_Bool_Exp>;
};


export type Subscription_RootWui_SessionEvent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SessionEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SessionEvent_Order_By>>;
  where?: InputMaybe<Wui_SessionEvent_Bool_Exp>;
};


export type Subscription_RootWui_SessionEvent_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_SessionEvent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_SessionEvent_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_SessionEvent_Bool_Exp>;
};


export type Subscription_RootWui_Session_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Session_Order_By>>;
  where?: InputMaybe<Wui_Session_Bool_Exp>;
};


export type Subscription_RootWui_Session_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_Session_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_Session_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_Session_Bool_Exp>;
};


export type Subscription_RootWui_SocialMediaSourceArgs = {
  distinct_on?: InputMaybe<Array<Wui_SocialMediaSource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SocialMediaSource_Order_By>>;
  where?: InputMaybe<Wui_SocialMediaSource_Bool_Exp>;
};


export type Subscription_RootWui_SocialMediaSource_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SocialMediaSource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SocialMediaSource_Order_By>>;
  where?: InputMaybe<Wui_SocialMediaSource_Bool_Exp>;
};


export type Subscription_RootWui_SocialMediaSource_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_SocialMediaSource_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_SocialMediaSource_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_SocialMediaSource_Bool_Exp>;
};


export type Subscription_RootWui_SubscriptionPlanArgs = {
  distinct_on?: InputMaybe<Array<Wui_SubscriptionPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SubscriptionPlan_Order_By>>;
  where?: InputMaybe<Wui_SubscriptionPlan_Bool_Exp>;
};


export type Subscription_RootWui_SubscriptionPlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_SubscriptionPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_SubscriptionPlan_Order_By>>;
  where?: InputMaybe<Wui_SubscriptionPlan_Bool_Exp>;
};


export type Subscription_RootWui_SubscriptionPlan_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWui_SubscriptionPlan_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_SubscriptionPlan_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_SubscriptionPlan_Bool_Exp>;
};


export type Subscription_RootWui_UserArgs = {
  distinct_on?: InputMaybe<Array<Wui_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_User_Order_By>>;
  where?: InputMaybe<Wui_User_Bool_Exp>;
};


export type Subscription_RootWui_UserFilePortfolioArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserFilePortfolio_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserFilePortfolio_Order_By>>;
  where?: InputMaybe<Wui_UserFilePortfolio_Bool_Exp>;
};


export type Subscription_RootWui_UserFilePortfolio_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserFilePortfolio_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserFilePortfolio_Order_By>>;
  where?: InputMaybe<Wui_UserFilePortfolio_Bool_Exp>;
};


export type Subscription_RootWui_UserFilePortfolio_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_UserFilePortfolio_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_UserFilePortfolio_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_UserFilePortfolio_Bool_Exp>;
};


export type Subscription_RootWui_UserReviewArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserReview_Order_By>>;
  where?: InputMaybe<Wui_UserReview_Bool_Exp>;
};


export type Subscription_RootWui_UserReview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserReview_Order_By>>;
  where?: InputMaybe<Wui_UserReview_Bool_Exp>;
};


export type Subscription_RootWui_UserReview_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_UserReview_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_UserReview_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_UserReview_Bool_Exp>;
};


export type Subscription_RootWui_UserRoleArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserRole_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserRole_Order_By>>;
  where?: InputMaybe<Wui_UserRole_Bool_Exp>;
};


export type Subscription_RootWui_UserRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserRole_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserRole_Order_By>>;
  where?: InputMaybe<Wui_UserRole_Bool_Exp>;
};


export type Subscription_RootWui_UserRole_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_UserRole_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_UserRole_Bool_Exp>;
};


export type Subscription_RootWui_UserSubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserSubscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserSubscription_Order_By>>;
  where?: InputMaybe<Wui_UserSubscription_Bool_Exp>;
};


export type Subscription_RootWui_UserSubscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserSubscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserSubscription_Order_By>>;
  where?: InputMaybe<Wui_UserSubscription_Bool_Exp>;
};


export type Subscription_RootWui_UserSubscription_By_PkArgs = {
  external_id: Scalars['String'];
};


export type Subscription_RootWui_UserSubscription_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_UserSubscription_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_UserSubscription_Bool_Exp>;
};


export type Subscription_RootWui_UserTypeArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserType_Order_By>>;
  where?: InputMaybe<Wui_UserType_Bool_Exp>;
};


export type Subscription_RootWui_UserType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_UserType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_UserType_Order_By>>;
  where?: InputMaybe<Wui_UserType_Bool_Exp>;
};


export type Subscription_RootWui_UserType_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_UserType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_UserType_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_UserType_Bool_Exp>;
};


export type Subscription_RootWui_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_User_Order_By>>;
  where?: InputMaybe<Wui_User_Bool_Exp>;
};


export type Subscription_RootWui_User_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_User_Bool_Exp>;
};


export type Subscription_RootWui_VerificationTokenArgs = {
  distinct_on?: InputMaybe<Array<Wui_VerificationToken_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_VerificationToken_Order_By>>;
  where?: InputMaybe<Wui_VerificationToken_Bool_Exp>;
};


export type Subscription_RootWui_VerificationToken_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_VerificationToken_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_VerificationToken_Order_By>>;
  where?: InputMaybe<Wui_VerificationToken_Bool_Exp>;
};


export type Subscription_RootWui_VerificationToken_By_PkArgs = {
  token: Scalars['String'];
};


export type Subscription_RootWui_VerificationToken_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_VerificationToken_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_VerificationToken_Bool_Exp>;
};


export type Subscription_RootUnclaimed_DemographicsArgs = {
  distinct_on?: InputMaybe<Array<Unclaimed_Demographics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unclaimed_Demographics_Order_By>>;
  where?: InputMaybe<Unclaimed_Demographics_Bool_Exp>;
};


export type Subscription_RootUnclaimed_Demographics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unclaimed_Demographics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unclaimed_Demographics_Order_By>>;
  where?: InputMaybe<Unclaimed_Demographics_Bool_Exp>;
};


export type Subscription_RootUnclaimed_Demographics_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootUnclaimed_Demographics_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Unclaimed_Demographics_Stream_Cursor_Input>>;
  where?: InputMaybe<Unclaimed_Demographics_Bool_Exp>;
};


export type Subscription_RootUnclaimed_ProfileArgs = {
  distinct_on?: InputMaybe<Array<Unclaimed_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unclaimed_Profile_Order_By>>;
  where?: InputMaybe<Unclaimed_Profile_Bool_Exp>;
};


export type Subscription_RootUnclaimed_Profile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unclaimed_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unclaimed_Profile_Order_By>>;
  where?: InputMaybe<Unclaimed_Profile_Bool_Exp>;
};


export type Subscription_RootUnclaimed_Profile_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootUnclaimed_Profile_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Unclaimed_Profile_Stream_Cursor_Input>>;
  where?: InputMaybe<Unclaimed_Profile_Bool_Exp>;
};


export type Subscription_RootVotesbyuserArgs = {
  distinct_on?: InputMaybe<Array<Votesbyuser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Votesbyuser_Order_By>>;
  where?: InputMaybe<Votesbyuser_Bool_Exp>;
};


export type Subscription_RootVotesbyuser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Votesbyuser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Votesbyuser_Order_By>>;
  where?: InputMaybe<Votesbyuser_Bool_Exp>;
};


export type Subscription_RootVotesbyuser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Votesbyuser_Stream_Cursor_Input>>;
  where?: InputMaybe<Votesbyuser_Bool_Exp>;
};


export type Subscription_RootWui_UserlastseenArgs = {
  distinct_on?: InputMaybe<Array<Wui_Userlastseen_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Userlastseen_Order_By>>;
  where?: InputMaybe<Wui_Userlastseen_Bool_Exp>;
};


export type Subscription_RootWui_Userlastseen_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wui_Userlastseen_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wui_Userlastseen_Order_By>>;
  where?: InputMaybe<Wui_Userlastseen_Bool_Exp>;
};


export type Subscription_RootWui_Userlastseen_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wui_Userlastseen_Stream_Cursor_Input>>;
  where?: InputMaybe<Wui_Userlastseen_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "unclaimed_demographics" */
export type Unclaimed_Demographics = {
  __typename?: 'unclaimed_demographics';
  demographic: Demographic_Enum;
  id: Scalars['bigint'];
  max?: Maybe<Scalars['numeric']>;
  min?: Maybe<Scalars['numeric']>;
  source: Wui_SocialMediaSource_Enum;
  user_id?: Maybe<Scalars['bigint']>;
  value: Scalars['String'];
};

/** aggregated selection of "unclaimed_demographics" */
export type Unclaimed_Demographics_Aggregate = {
  __typename?: 'unclaimed_demographics_aggregate';
  aggregate?: Maybe<Unclaimed_Demographics_Aggregate_Fields>;
  nodes: Array<Unclaimed_Demographics>;
};

export type Unclaimed_Demographics_Aggregate_Bool_Exp = {
  count?: InputMaybe<Unclaimed_Demographics_Aggregate_Bool_Exp_Count>;
};

export type Unclaimed_Demographics_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Unclaimed_Demographics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Unclaimed_Demographics_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "unclaimed_demographics" */
export type Unclaimed_Demographics_Aggregate_Fields = {
  __typename?: 'unclaimed_demographics_aggregate_fields';
  avg?: Maybe<Unclaimed_Demographics_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Unclaimed_Demographics_Max_Fields>;
  min?: Maybe<Unclaimed_Demographics_Min_Fields>;
  stddev?: Maybe<Unclaimed_Demographics_Stddev_Fields>;
  stddev_pop?: Maybe<Unclaimed_Demographics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Unclaimed_Demographics_Stddev_Samp_Fields>;
  sum?: Maybe<Unclaimed_Demographics_Sum_Fields>;
  var_pop?: Maybe<Unclaimed_Demographics_Var_Pop_Fields>;
  var_samp?: Maybe<Unclaimed_Demographics_Var_Samp_Fields>;
  variance?: Maybe<Unclaimed_Demographics_Variance_Fields>;
};


/** aggregate fields of "unclaimed_demographics" */
export type Unclaimed_Demographics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Unclaimed_Demographics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "unclaimed_demographics" */
export type Unclaimed_Demographics_Aggregate_Order_By = {
  avg?: InputMaybe<Unclaimed_Demographics_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Unclaimed_Demographics_Max_Order_By>;
  min?: InputMaybe<Unclaimed_Demographics_Min_Order_By>;
  stddev?: InputMaybe<Unclaimed_Demographics_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Unclaimed_Demographics_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Unclaimed_Demographics_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Unclaimed_Demographics_Sum_Order_By>;
  var_pop?: InputMaybe<Unclaimed_Demographics_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Unclaimed_Demographics_Var_Samp_Order_By>;
  variance?: InputMaybe<Unclaimed_Demographics_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "unclaimed_demographics" */
export type Unclaimed_Demographics_Arr_Rel_Insert_Input = {
  data: Array<Unclaimed_Demographics_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Unclaimed_Demographics_On_Conflict>;
};

/** aggregate avg on columns */
export type Unclaimed_Demographics_Avg_Fields = {
  __typename?: 'unclaimed_demographics_avg_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "unclaimed_demographics" */
export type Unclaimed_Demographics_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "unclaimed_demographics". All fields are combined with a logical 'AND'. */
export type Unclaimed_Demographics_Bool_Exp = {
  _and?: InputMaybe<Array<Unclaimed_Demographics_Bool_Exp>>;
  _not?: InputMaybe<Unclaimed_Demographics_Bool_Exp>;
  _or?: InputMaybe<Array<Unclaimed_Demographics_Bool_Exp>>;
  demographic?: InputMaybe<Demographic_Enum_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  max?: InputMaybe<Numeric_Comparison_Exp>;
  min?: InputMaybe<Numeric_Comparison_Exp>;
  source?: InputMaybe<Wui_SocialMediaSource_Enum_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "unclaimed_demographics" */
export enum Unclaimed_Demographics_Constraint {
  /** unique or primary key constraint on columns "id" */
  UnclaimedDemographicsPkey = 'unclaimed_demographics_pkey',
  /** unique or primary key constraint on columns "source", "user_id", "demographic" */
  UnclaimedDemographicsUserIdSourceDemographicKey = 'unclaimed_demographics_user_id_source_demographic_key'
}

/** input type for incrementing numeric columns in table "unclaimed_demographics" */
export type Unclaimed_Demographics_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  max?: InputMaybe<Scalars['numeric']>;
  min?: InputMaybe<Scalars['numeric']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "unclaimed_demographics" */
export type Unclaimed_Demographics_Insert_Input = {
  demographic?: InputMaybe<Demographic_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  max?: InputMaybe<Scalars['numeric']>;
  min?: InputMaybe<Scalars['numeric']>;
  source?: InputMaybe<Wui_SocialMediaSource_Enum>;
  user_id?: InputMaybe<Scalars['bigint']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Unclaimed_Demographics_Max_Fields = {
  __typename?: 'unclaimed_demographics_max_fields';
  id?: Maybe<Scalars['bigint']>;
  max?: Maybe<Scalars['numeric']>;
  min?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "unclaimed_demographics" */
export type Unclaimed_Demographics_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Unclaimed_Demographics_Min_Fields = {
  __typename?: 'unclaimed_demographics_min_fields';
  id?: Maybe<Scalars['bigint']>;
  max?: Maybe<Scalars['numeric']>;
  min?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "unclaimed_demographics" */
export type Unclaimed_Demographics_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "unclaimed_demographics" */
export type Unclaimed_Demographics_Mutation_Response = {
  __typename?: 'unclaimed_demographics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Unclaimed_Demographics>;
};

/** on_conflict condition type for table "unclaimed_demographics" */
export type Unclaimed_Demographics_On_Conflict = {
  constraint: Unclaimed_Demographics_Constraint;
  update_columns?: Array<Unclaimed_Demographics_Update_Column>;
  where?: InputMaybe<Unclaimed_Demographics_Bool_Exp>;
};

/** Ordering options when selecting data from "unclaimed_demographics". */
export type Unclaimed_Demographics_Order_By = {
  demographic?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: unclaimed_demographics */
export type Unclaimed_Demographics_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "unclaimed_demographics" */
export enum Unclaimed_Demographics_Select_Column {
  /** column name */
  Demographic = 'demographic',
  /** column name */
  Id = 'id',
  /** column name */
  Max = 'max',
  /** column name */
  Min = 'min',
  /** column name */
  Source = 'source',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "unclaimed_demographics" */
export type Unclaimed_Demographics_Set_Input = {
  demographic?: InputMaybe<Demographic_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  max?: InputMaybe<Scalars['numeric']>;
  min?: InputMaybe<Scalars['numeric']>;
  source?: InputMaybe<Wui_SocialMediaSource_Enum>;
  user_id?: InputMaybe<Scalars['bigint']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Unclaimed_Demographics_Stddev_Fields = {
  __typename?: 'unclaimed_demographics_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "unclaimed_demographics" */
export type Unclaimed_Demographics_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Unclaimed_Demographics_Stddev_Pop_Fields = {
  __typename?: 'unclaimed_demographics_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "unclaimed_demographics" */
export type Unclaimed_Demographics_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Unclaimed_Demographics_Stddev_Samp_Fields = {
  __typename?: 'unclaimed_demographics_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "unclaimed_demographics" */
export type Unclaimed_Demographics_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "unclaimed_demographics" */
export type Unclaimed_Demographics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Unclaimed_Demographics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Unclaimed_Demographics_Stream_Cursor_Value_Input = {
  demographic?: InputMaybe<Demographic_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  max?: InputMaybe<Scalars['numeric']>;
  min?: InputMaybe<Scalars['numeric']>;
  source?: InputMaybe<Wui_SocialMediaSource_Enum>;
  user_id?: InputMaybe<Scalars['bigint']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Unclaimed_Demographics_Sum_Fields = {
  __typename?: 'unclaimed_demographics_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  max?: Maybe<Scalars['numeric']>;
  min?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "unclaimed_demographics" */
export type Unclaimed_Demographics_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "unclaimed_demographics" */
export enum Unclaimed_Demographics_Update_Column {
  /** column name */
  Demographic = 'demographic',
  /** column name */
  Id = 'id',
  /** column name */
  Max = 'max',
  /** column name */
  Min = 'min',
  /** column name */
  Source = 'source',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Unclaimed_Demographics_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Unclaimed_Demographics_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Unclaimed_Demographics_Set_Input>;
  /** filter the rows which have to be updated */
  where: Unclaimed_Demographics_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Unclaimed_Demographics_Var_Pop_Fields = {
  __typename?: 'unclaimed_demographics_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "unclaimed_demographics" */
export type Unclaimed_Demographics_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Unclaimed_Demographics_Var_Samp_Fields = {
  __typename?: 'unclaimed_demographics_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "unclaimed_demographics" */
export type Unclaimed_Demographics_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Unclaimed_Demographics_Variance_Fields = {
  __typename?: 'unclaimed_demographics_variance_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "unclaimed_demographics" */
export type Unclaimed_Demographics_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "unclaimed_profile" */
export type Unclaimed_Profile = {
  __typename?: 'unclaimed_profile';
  about_me: Scalars['String'];
  cover_picture_file_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  first_name: Scalars['String'];
  id: Scalars['bigint'];
  last_name: Scalars['String'];
  profile_picture_file_id?: Maybe<Scalars['bigint']>;
  /** fetch data from the table: "unclaimed_demographics" */
  unclaimed_demographics: Array<Unclaimed_Demographics>;
  /** fetch aggregated fields from the table: "unclaimed_demographics" */
  unclaimed_demographics_aggregate: Unclaimed_Demographics_Aggregate;
  /** An object relationship */
  unclaimed_profile_cover?: Maybe<Wui_File>;
  /** An object relationship */
  unclaimed_profile_picture?: Maybe<Wui_File>;
  username: Scalars['String'];
};


/** columns and relationships of "unclaimed_profile" */
export type Unclaimed_ProfileUnclaimed_DemographicsArgs = {
  distinct_on?: InputMaybe<Array<Unclaimed_Demographics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unclaimed_Demographics_Order_By>>;
  where?: InputMaybe<Unclaimed_Demographics_Bool_Exp>;
};


/** columns and relationships of "unclaimed_profile" */
export type Unclaimed_ProfileUnclaimed_Demographics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unclaimed_Demographics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unclaimed_Demographics_Order_By>>;
  where?: InputMaybe<Unclaimed_Demographics_Bool_Exp>;
};

/** aggregated selection of "unclaimed_profile" */
export type Unclaimed_Profile_Aggregate = {
  __typename?: 'unclaimed_profile_aggregate';
  aggregate?: Maybe<Unclaimed_Profile_Aggregate_Fields>;
  nodes: Array<Unclaimed_Profile>;
};

/** aggregate fields of "unclaimed_profile" */
export type Unclaimed_Profile_Aggregate_Fields = {
  __typename?: 'unclaimed_profile_aggregate_fields';
  avg?: Maybe<Unclaimed_Profile_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Unclaimed_Profile_Max_Fields>;
  min?: Maybe<Unclaimed_Profile_Min_Fields>;
  stddev?: Maybe<Unclaimed_Profile_Stddev_Fields>;
  stddev_pop?: Maybe<Unclaimed_Profile_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Unclaimed_Profile_Stddev_Samp_Fields>;
  sum?: Maybe<Unclaimed_Profile_Sum_Fields>;
  var_pop?: Maybe<Unclaimed_Profile_Var_Pop_Fields>;
  var_samp?: Maybe<Unclaimed_Profile_Var_Samp_Fields>;
  variance?: Maybe<Unclaimed_Profile_Variance_Fields>;
};


/** aggregate fields of "unclaimed_profile" */
export type Unclaimed_Profile_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Unclaimed_Profile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Unclaimed_Profile_Avg_Fields = {
  __typename?: 'unclaimed_profile_avg_fields';
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "unclaimed_profile". All fields are combined with a logical 'AND'. */
export type Unclaimed_Profile_Bool_Exp = {
  _and?: InputMaybe<Array<Unclaimed_Profile_Bool_Exp>>;
  _not?: InputMaybe<Unclaimed_Profile_Bool_Exp>;
  _or?: InputMaybe<Array<Unclaimed_Profile_Bool_Exp>>;
  about_me?: InputMaybe<String_Comparison_Exp>;
  cover_picture_file_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  profile_picture_file_id?: InputMaybe<Bigint_Comparison_Exp>;
  unclaimed_demographics?: InputMaybe<Unclaimed_Demographics_Bool_Exp>;
  unclaimed_demographics_aggregate?: InputMaybe<Unclaimed_Demographics_Aggregate_Bool_Exp>;
  unclaimed_profile_cover?: InputMaybe<Wui_File_Bool_Exp>;
  unclaimed_profile_picture?: InputMaybe<Wui_File_Bool_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "unclaimed_profile" */
export enum Unclaimed_Profile_Constraint {
  /** unique or primary key constraint on columns "id" */
  UnclaimedProfilePkey = 'unclaimed_profile_pkey'
}

/** input type for incrementing numeric columns in table "unclaimed_profile" */
export type Unclaimed_Profile_Inc_Input = {
  cover_picture_file_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  profile_picture_file_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "unclaimed_profile" */
export type Unclaimed_Profile_Insert_Input = {
  about_me?: InputMaybe<Scalars['String']>;
  cover_picture_file_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  last_name?: InputMaybe<Scalars['String']>;
  profile_picture_file_id?: InputMaybe<Scalars['bigint']>;
  unclaimed_demographics?: InputMaybe<Unclaimed_Demographics_Arr_Rel_Insert_Input>;
  unclaimed_profile_cover?: InputMaybe<Wui_File_Obj_Rel_Insert_Input>;
  unclaimed_profile_picture?: InputMaybe<Wui_File_Obj_Rel_Insert_Input>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Unclaimed_Profile_Max_Fields = {
  __typename?: 'unclaimed_profile_max_fields';
  about_me?: Maybe<Scalars['String']>;
  cover_picture_file_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  profile_picture_file_id?: Maybe<Scalars['bigint']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Unclaimed_Profile_Min_Fields = {
  __typename?: 'unclaimed_profile_min_fields';
  about_me?: Maybe<Scalars['String']>;
  cover_picture_file_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  profile_picture_file_id?: Maybe<Scalars['bigint']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "unclaimed_profile" */
export type Unclaimed_Profile_Mutation_Response = {
  __typename?: 'unclaimed_profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Unclaimed_Profile>;
};

/** on_conflict condition type for table "unclaimed_profile" */
export type Unclaimed_Profile_On_Conflict = {
  constraint: Unclaimed_Profile_Constraint;
  update_columns?: Array<Unclaimed_Profile_Update_Column>;
  where?: InputMaybe<Unclaimed_Profile_Bool_Exp>;
};

/** Ordering options when selecting data from "unclaimed_profile". */
export type Unclaimed_Profile_Order_By = {
  about_me?: InputMaybe<Order_By>;
  cover_picture_file_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  profile_picture_file_id?: InputMaybe<Order_By>;
  unclaimed_demographics_aggregate?: InputMaybe<Unclaimed_Demographics_Aggregate_Order_By>;
  unclaimed_profile_cover?: InputMaybe<Wui_File_Order_By>;
  unclaimed_profile_picture?: InputMaybe<Wui_File_Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: unclaimed_profile */
export type Unclaimed_Profile_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "unclaimed_profile" */
export enum Unclaimed_Profile_Select_Column {
  /** column name */
  AboutMe = 'about_me',
  /** column name */
  CoverPictureFileId = 'cover_picture_file_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  ProfilePictureFileId = 'profile_picture_file_id',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "unclaimed_profile" */
export type Unclaimed_Profile_Set_Input = {
  about_me?: InputMaybe<Scalars['String']>;
  cover_picture_file_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  last_name?: InputMaybe<Scalars['String']>;
  profile_picture_file_id?: InputMaybe<Scalars['bigint']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Unclaimed_Profile_Stddev_Fields = {
  __typename?: 'unclaimed_profile_stddev_fields';
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Unclaimed_Profile_Stddev_Pop_Fields = {
  __typename?: 'unclaimed_profile_stddev_pop_fields';
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Unclaimed_Profile_Stddev_Samp_Fields = {
  __typename?: 'unclaimed_profile_stddev_samp_fields';
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "unclaimed_profile" */
export type Unclaimed_Profile_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Unclaimed_Profile_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Unclaimed_Profile_Stream_Cursor_Value_Input = {
  about_me?: InputMaybe<Scalars['String']>;
  cover_picture_file_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  last_name?: InputMaybe<Scalars['String']>;
  profile_picture_file_id?: InputMaybe<Scalars['bigint']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Unclaimed_Profile_Sum_Fields = {
  __typename?: 'unclaimed_profile_sum_fields';
  cover_picture_file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  profile_picture_file_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "unclaimed_profile" */
export enum Unclaimed_Profile_Update_Column {
  /** column name */
  AboutMe = 'about_me',
  /** column name */
  CoverPictureFileId = 'cover_picture_file_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  ProfilePictureFileId = 'profile_picture_file_id',
  /** column name */
  Username = 'username'
}

export type Unclaimed_Profile_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Unclaimed_Profile_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Unclaimed_Profile_Set_Input>;
  /** filter the rows which have to be updated */
  where: Unclaimed_Profile_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Unclaimed_Profile_Var_Pop_Fields = {
  __typename?: 'unclaimed_profile_var_pop_fields';
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Unclaimed_Profile_Var_Samp_Fields = {
  __typename?: 'unclaimed_profile_var_samp_fields';
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Unclaimed_Profile_Variance_Fields = {
  __typename?: 'unclaimed_profile_variance_fields';
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "votesbyuser" */
export type Votesbyuser = {
  __typename?: 'votesbyuser';
  total_votes?: Maybe<Scalars['bigint']>;
  voter_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "votesbyuser" */
export type Votesbyuser_Aggregate = {
  __typename?: 'votesbyuser_aggregate';
  aggregate?: Maybe<Votesbyuser_Aggregate_Fields>;
  nodes: Array<Votesbyuser>;
};

/** aggregate fields of "votesbyuser" */
export type Votesbyuser_Aggregate_Fields = {
  __typename?: 'votesbyuser_aggregate_fields';
  avg?: Maybe<Votesbyuser_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Votesbyuser_Max_Fields>;
  min?: Maybe<Votesbyuser_Min_Fields>;
  stddev?: Maybe<Votesbyuser_Stddev_Fields>;
  stddev_pop?: Maybe<Votesbyuser_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Votesbyuser_Stddev_Samp_Fields>;
  sum?: Maybe<Votesbyuser_Sum_Fields>;
  var_pop?: Maybe<Votesbyuser_Var_Pop_Fields>;
  var_samp?: Maybe<Votesbyuser_Var_Samp_Fields>;
  variance?: Maybe<Votesbyuser_Variance_Fields>;
};


/** aggregate fields of "votesbyuser" */
export type Votesbyuser_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Votesbyuser_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Votesbyuser_Avg_Fields = {
  __typename?: 'votesbyuser_avg_fields';
  total_votes?: Maybe<Scalars['Float']>;
  voter_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "votesbyuser". All fields are combined with a logical 'AND'. */
export type Votesbyuser_Bool_Exp = {
  _and?: InputMaybe<Array<Votesbyuser_Bool_Exp>>;
  _not?: InputMaybe<Votesbyuser_Bool_Exp>;
  _or?: InputMaybe<Array<Votesbyuser_Bool_Exp>>;
  total_votes?: InputMaybe<Bigint_Comparison_Exp>;
  voter_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Votesbyuser_Max_Fields = {
  __typename?: 'votesbyuser_max_fields';
  total_votes?: Maybe<Scalars['bigint']>;
  voter_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Votesbyuser_Min_Fields = {
  __typename?: 'votesbyuser_min_fields';
  total_votes?: Maybe<Scalars['bigint']>;
  voter_id?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "votesbyuser". */
export type Votesbyuser_Order_By = {
  total_votes?: InputMaybe<Order_By>;
  voter_id?: InputMaybe<Order_By>;
};

/** select columns of table "votesbyuser" */
export enum Votesbyuser_Select_Column {
  /** column name */
  TotalVotes = 'total_votes',
  /** column name */
  VoterId = 'voter_id'
}

/** aggregate stddev on columns */
export type Votesbyuser_Stddev_Fields = {
  __typename?: 'votesbyuser_stddev_fields';
  total_votes?: Maybe<Scalars['Float']>;
  voter_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Votesbyuser_Stddev_Pop_Fields = {
  __typename?: 'votesbyuser_stddev_pop_fields';
  total_votes?: Maybe<Scalars['Float']>;
  voter_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Votesbyuser_Stddev_Samp_Fields = {
  __typename?: 'votesbyuser_stddev_samp_fields';
  total_votes?: Maybe<Scalars['Float']>;
  voter_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "votesbyuser" */
export type Votesbyuser_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Votesbyuser_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Votesbyuser_Stream_Cursor_Value_Input = {
  total_votes?: InputMaybe<Scalars['bigint']>;
  voter_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Votesbyuser_Sum_Fields = {
  __typename?: 'votesbyuser_sum_fields';
  total_votes?: Maybe<Scalars['bigint']>;
  voter_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Votesbyuser_Var_Pop_Fields = {
  __typename?: 'votesbyuser_var_pop_fields';
  total_votes?: Maybe<Scalars['Float']>;
  voter_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Votesbyuser_Var_Samp_Fields = {
  __typename?: 'votesbyuser_var_samp_fields';
  total_votes?: Maybe<Scalars['Float']>;
  voter_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Votesbyuser_Variance_Fields = {
  __typename?: 'votesbyuser_variance_fields';
  total_votes?: Maybe<Scalars['Float']>;
  voter_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "wui_userlastseen" */
export type Wui_Userlastseen = {
  __typename?: 'wui_userlastseen';
  id?: Maybe<Scalars['bigint']>;
  last_seen_date?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "wui_userlastseen" */
export type Wui_Userlastseen_Aggregate = {
  __typename?: 'wui_userlastseen_aggregate';
  aggregate?: Maybe<Wui_Userlastseen_Aggregate_Fields>;
  nodes: Array<Wui_Userlastseen>;
};

/** aggregate fields of "wui_userlastseen" */
export type Wui_Userlastseen_Aggregate_Fields = {
  __typename?: 'wui_userlastseen_aggregate_fields';
  avg?: Maybe<Wui_Userlastseen_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_Userlastseen_Max_Fields>;
  min?: Maybe<Wui_Userlastseen_Min_Fields>;
  stddev?: Maybe<Wui_Userlastseen_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_Userlastseen_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_Userlastseen_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_Userlastseen_Sum_Fields>;
  var_pop?: Maybe<Wui_Userlastseen_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_Userlastseen_Var_Samp_Fields>;
  variance?: Maybe<Wui_Userlastseen_Variance_Fields>;
};


/** aggregate fields of "wui_userlastseen" */
export type Wui_Userlastseen_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wui_Userlastseen_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_Userlastseen_Avg_Fields = {
  __typename?: 'wui_userlastseen_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "wui_userlastseen". All fields are combined with a logical 'AND'. */
export type Wui_Userlastseen_Bool_Exp = {
  _and?: InputMaybe<Array<Wui_Userlastseen_Bool_Exp>>;
  _not?: InputMaybe<Wui_Userlastseen_Bool_Exp>;
  _or?: InputMaybe<Array<Wui_Userlastseen_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  last_seen_date?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "wui_userlastseen" */
export type Wui_Userlastseen_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "wui_userlastseen" */
export type Wui_Userlastseen_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  last_seen_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Wui_Userlastseen_Max_Fields = {
  __typename?: 'wui_userlastseen_max_fields';
  id?: Maybe<Scalars['bigint']>;
  last_seen_date?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Wui_Userlastseen_Min_Fields = {
  __typename?: 'wui_userlastseen_min_fields';
  id?: Maybe<Scalars['bigint']>;
  last_seen_date?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "wui_userlastseen" */
export type Wui_Userlastseen_Mutation_Response = {
  __typename?: 'wui_userlastseen_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_Userlastseen>;
};

/** Ordering options when selecting data from "wui_userlastseen". */
export type Wui_Userlastseen_Order_By = {
  id?: InputMaybe<Order_By>;
  last_seen_date?: InputMaybe<Order_By>;
};

/** select columns of table "wui_userlastseen" */
export enum Wui_Userlastseen_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LastSeenDate = 'last_seen_date'
}

/** input type for updating data in table "wui_userlastseen" */
export type Wui_Userlastseen_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  last_seen_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Wui_Userlastseen_Stddev_Fields = {
  __typename?: 'wui_userlastseen_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_Userlastseen_Stddev_Pop_Fields = {
  __typename?: 'wui_userlastseen_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_Userlastseen_Stddev_Samp_Fields = {
  __typename?: 'wui_userlastseen_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "wui_userlastseen" */
export type Wui_Userlastseen_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wui_Userlastseen_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wui_Userlastseen_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  last_seen_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Wui_Userlastseen_Sum_Fields = {
  __typename?: 'wui_userlastseen_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

export type Wui_Userlastseen_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wui_Userlastseen_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wui_Userlastseen_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wui_Userlastseen_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wui_Userlastseen_Var_Pop_Fields = {
  __typename?: 'wui_userlastseen_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_Userlastseen_Var_Samp_Fields = {
  __typename?: 'wui_userlastseen_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_Userlastseen_Variance_Fields = {
  __typename?: 'wui_userlastseen_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type UserDetailsFragment = { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null };

export type UserContactFragment = { __typename?: 'WUI_User', contact_email: string, phone_number?: string | null };

export type AcceptTermsAndConditionsMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type AcceptTermsAndConditionsMutation = { __typename?: 'mutation_root', update_WUI_User?: { __typename?: 'WUI_User_mutation_response', affected_rows: number } | null };

export type AddCreditCardMutationVariables = Exact<{
  paymentMethodId: Scalars['String'];
}>;


export type AddCreditCardMutation = { __typename?: 'mutation_root', create_WUI_PaymentMethodCard?: { __typename?: 'PaymentMethodCardOutput', id: any, external_id: string, last4: string, brand: string, funding: string, country_code: string, exp_year: number, exp_month: number, fingerprint: string, allow_3d_secure: boolean } | null };

export type AddEmailDomainMutationVariables = Exact<{
  objects: Array<EmailDomainLookup_Insert_Input> | EmailDomainLookup_Insert_Input;
}>;


export type AddEmailDomainMutation = { __typename?: 'mutation_root', insert_EmailDomainLookup?: { __typename?: 'EmailDomainLookup_mutation_response', affected_rows: number } | null };

export type AdminCreateSellerPackageItemsMutationVariables = Exact<{
  sellerPackageId: Scalars['bigint'];
  objects: Array<Wui_SellerPackageItem_Insert_Input> | Wui_SellerPackageItem_Insert_Input;
}>;


export type AdminCreateSellerPackageItemsMutation = { __typename?: 'mutation_root', delete_WUI_SellerPackageItem?: { __typename?: 'WUI_SellerPackageItem_mutation_response', affected_rows: number } | null, insert_WUI_SellerPackageItem?: { __typename?: 'WUI_SellerPackageItem_mutation_response', affected_rows: number } | null };

export type AdminCreateUserMutationVariables = Exact<{
  object: TempLoginWithPassword_Insert_Input;
}>;


export type AdminCreateUserMutation = { __typename?: 'mutation_root', insert_TempLoginWithPassword_one?: { __typename?: 'TempLoginWithPassword', id: any, user_id: any } | null };

export type AdminCreateUserWithPwdMutationVariables = Exact<{
  object: Wui_User_Insert_Input;
}>;


export type AdminCreateUserWithPwdMutation = { __typename?: 'mutation_root', insert_WUI_User_one?: { __typename?: 'WUI_User', id: any } | null };

export type AdminDeleteAmbassadorMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AdminDeleteAmbassadorMutation = { __typename?: 'mutation_root', delete_Ambassador_by_pk?: { __typename?: 'Ambassador', id: any } | null };

export type AdminDeleteUserSubsMutationVariables = Exact<{
  userId: Scalars['bigint'];
}>;


export type AdminDeleteUserSubsMutation = { __typename?: 'mutation_root', delete_WUI_UserSubscription?: { __typename?: 'WUI_UserSubscription_mutation_response', affected_rows: number } | null };

export type AdminInsertAmbassadorMutationVariables = Exact<{
  object: Ambassador_Insert_Input;
}>;


export type AdminInsertAmbassadorMutation = { __typename?: 'mutation_root', insert_Ambassador_one?: { __typename?: 'Ambassador', id: any } | null };

export type AdminInsertOrUpdatePackageMutationVariables = Exact<{
  object: Wui_SellerPackage_Insert_Input;
}>;


export type AdminInsertOrUpdatePackageMutation = { __typename?: 'mutation_root', insert_WUI_SellerPackage_one?: { __typename?: 'WUI_SellerPackage', id: any, description: string, enabled: boolean, hide_price: boolean, metadata?: any | null, package_type: Wui_SellerPackageType_Enum, price: any, title?: string | null, user_id: any } | null };

export type AdminSendMessagesToUsersMutationVariables = Exact<{
  objects: Array<Wui_ChatRoomMessage_Insert_Input> | Wui_ChatRoomMessage_Insert_Input;
}>;


export type AdminSendMessagesToUsersMutation = { __typename?: 'mutation_root', insert_WUI_ChatRoomMessage?: { __typename?: 'WUI_ChatRoomMessage_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'WUI_ChatRoomMessage', id: any, room_id: any }> } | null };

export type AdminUpdateAmbassadorByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
  set: Ambassador_Set_Input;
}>;


export type AdminUpdateAmbassadorByIdMutation = { __typename?: 'mutation_root', update_Ambassador_by_pk?: { __typename?: 'Ambassador', id: any } | null };

export type AdminUpdateUserByIdMutationVariables = Exact<{
  id: Scalars['bigint'];
  set: Wui_User_Set_Input;
}>;


export type AdminUpdateUserByIdMutation = { __typename?: 'mutation_root', update_WUI_User_by_pk?: { __typename?: 'WUI_User', id: any } | null };

export type CreateBankAccountMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type CreateBankAccountMutation = { __typename?: 'mutation_root', createBankAccount?: { __typename?: 'BankAccountOutput', id: string, object: string, account_holder_name: string, account_holder_type: string, bank_name: string, country: string, currency: string, fingerprint: string, last4: string, routing_number: string, status: string, account: string } | null };

export type CreateConversationMutationVariables = Exact<{
  data: Array<Wui_ChatRoomUser_Insert_Input> | Wui_ChatRoomUser_Insert_Input;
}>;


export type CreateConversationMutation = { __typename?: 'mutation_root', insert_WUI_ChatRoom_one?: { __typename?: 'WUI_ChatRoom', id: any } | null };

export type CreateOrderDetailsMutationVariables = Exact<{
  roomID: Scalars['bigint'];
  data: Scalars['jsonb'];
}>;


export type CreateOrderDetailsMutation = { __typename?: 'mutation_root', create_WUI_OrderDetails?: { __typename?: 'OrderDetailsOutput', id: any } | null };

export type CreateOrderMutationVariables = Exact<{
  packageId: Scalars['bigint'];
  packageFees: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  subscriptionPeriod?: InputMaybe<Scalars['String']>;
  paymentMethodId: Scalars['bigint'];
}>;


export type CreateOrderMutation = { __typename?: 'mutation_root', create_WUI_Order?: { __typename?: 'OrderOutput', id: any, buyer_id: any, seller_id: any, created_by: any, package_type: string, title: string, description: string, price: number, status: string, items: any, subscription_id?: string | null } | null };

export type CreatePackageMutationVariables = Exact<{
  object: Wui_SellerPackage_Insert_Input;
}>;


export type CreatePackageMutation = { __typename?: 'mutation_root', insert_WUI_SellerPackage_one?: { __typename?: 'WUI_SellerPackage', description: string, enabled: boolean, id: any, package_type: Wui_SellerPackageType_Enum, price: any, title?: string | null, metadata?: any | null, hide_price: boolean, WUI_SellerPackageItems: Array<{ __typename?: 'WUI_SellerPackageItem', id: any, title?: string | null, comment?: string | null, quantity?: number | null }> } | null };

export type CreateProjectMutationVariables = Exact<{
  object: Wui_SellerProject_Insert_Input;
}>;


export type CreateProjectMutation = { __typename?: 'mutation_root', insert_WUI_SellerProject_one?: { __typename?: 'WUI_SellerProject', id: any } | null };

export type CreateRequestPackagePriceMutationVariables = Exact<{
  object: Wui_PackageRequest_Insert_Input;
}>;


export type CreateRequestPackagePriceMutation = { __typename?: 'mutation_root', insert_WUI_PackageRequest_one?: { __typename?: 'WUI_PackageRequest', id: any, status: Wui_RequestStatus_Enum, tries: number, package_id: any } | null };

export type CreateReviewOrderMutationVariables = Exact<{
  orderId: Scalars['bigint'];
  data: Wui_UserReview_Insert_Input;
}>;


export type CreateReviewOrderMutation = { __typename?: 'mutation_root', insert_WUI_OrderReview?: { __typename?: 'WUI_OrderReview_mutation_response', affected_rows: number } | null };

export type CreateSessionEventMutationVariables = Exact<{
  object: Wui_SessionEvent_Insert_Input;
}>;


export type CreateSessionEventMutation = { __typename?: 'mutation_root', insert_WUI_SessionEvent_one?: { __typename?: 'WUI_SessionEvent', id: any } | null };

export type CreateSubscriptionMutationVariables = Exact<{
  object: Wui_UserSubscription_Insert_Input;
}>;


export type CreateSubscriptionMutation = { __typename?: 'mutation_root', insert_WUI_UserSubscription_one?: { __typename?: 'WUI_UserSubscription', id: any, expires_at: any, status: string, external_id: string, paid: boolean } | null };

export type DeleteBankAccountMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteBankAccountMutation = { __typename?: 'mutation_root', removeBankAccount?: { __typename?: 'RemoveBankAccountOutput', id: string } | null };

export type DeleteCreditCardMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type DeleteCreditCardMutation = { __typename?: 'mutation_root', remove_WUI_PaymentMethodCard?: { __typename?: 'RemovePaymentMethodCardOutput', id: any } | null };

export type DeleteLinkMutationVariables = Exact<{
  whereDeleteEvent: Wui_SessionEvent_Bool_Exp;
  whereDeleteLink: SocialMediaLink_Bool_Exp;
}>;


export type DeleteLinkMutation = { __typename?: 'mutation_root', delete_WUI_SessionEvent?: { __typename?: 'WUI_SessionEvent_mutation_response', affected_rows: number } | null, delete_SocialMediaLink?: { __typename?: 'SocialMediaLink_mutation_response', returning: Array<{ __typename?: 'SocialMediaLink', id: any }> } | null };

export type DeleteLinkEventsMutationVariables = Exact<{
  whereDeleteEvent: Wui_SessionEvent_Bool_Exp;
}>;


export type DeleteLinkEventsMutation = { __typename?: 'mutation_root', delete_WUI_SessionEvent?: { __typename?: 'WUI_SessionEvent_mutation_response', affected_rows: number } | null };

export type DeletePorfolioPicturesMutationVariables = Exact<{
  whereDelete: Wui_UserFilePortfolio_Bool_Exp;
}>;


export type DeletePorfolioPicturesMutation = { __typename?: 'mutation_root', delete_WUI_UserFilePortfolio?: { __typename?: 'WUI_UserFilePortfolio_mutation_response', returning: Array<{ __typename?: 'WUI_UserFilePortfolio', id: any, WUI_File?: { __typename?: 'WUI_File', id: any, file_type: Wui_FileType_Enum, url: string } | null }> } | null };

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type DeleteProjectMutation = { __typename?: 'mutation_root', delete_WUI_SellerProject_by_pk?: { __typename?: 'WUI_SellerProject', id: any } | null };

export type DeleteUserPortfolioPicturesMutationVariables = Exact<{
  whereDelete: Wui_UserFilePortfolio_Bool_Exp;
}>;


export type DeleteUserPortfolioPicturesMutation = { __typename?: 'mutation_root', delete_WUI_UserFilePortfolio?: { __typename?: 'WUI_UserFilePortfolio_mutation_response', affected_rows: number } | null };

export type IncrementEmailDomainLookupSeenMutationVariables = Exact<{
  domain: Scalars['String'];
}>;


export type IncrementEmailDomainLookupSeenMutation = { __typename?: 'mutation_root', update_EmailDomainLookup_by_pk?: { __typename?: 'EmailDomainLookup', seen: number } | null };

export type InsertLinksMutationVariables = Exact<{
  Objects: Array<SocialMediaLink_Insert_Input> | SocialMediaLink_Insert_Input;
}>;


export type InsertLinksMutation = { __typename?: 'mutation_root', insert_SocialMediaLink?: { __typename?: 'SocialMediaLink_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'SocialMediaLink', id: any, title: string, url: string, primary: boolean }> } | null };

export type InsertMarketplaceWaitlistMutationVariables = Exact<{ [key: string]: never; }>;


export type InsertMarketplaceWaitlistMutation = { __typename?: 'mutation_root', insert_MarketplaceWaitlist_one?: { __typename?: 'MarketplaceWaitlist', id: any } | null };

export type InsertOrUpdateUserPortfolioPicturesMutationVariables = Exact<{
  insertOrUpdatePictures: Array<Wui_UserFilePortfolio_Insert_Input> | Wui_UserFilePortfolio_Insert_Input;
}>;


export type InsertOrUpdateUserPortfolioPicturesMutation = { __typename?: 'mutation_root', insert_WUI_UserFilePortfolio?: { __typename?: 'WUI_UserFilePortfolio_mutation_response', returning: Array<{ __typename?: 'WUI_UserFilePortfolio', id: any, WUI_File?: { __typename?: 'WUI_File', id: any, file_type: Wui_FileType_Enum, url: string } | null }> } | null };

export type LegalInformationCompanyMutationVariables = Exact<{
  taxID: Scalars['String'];
  name: Scalars['String'];
  address: CompanyAddress;
}>;


export type LegalInformationCompanyMutation = { __typename?: 'mutation_root', createCompanyAccount?: { __typename?: 'CreateCompanyInfoOutput', success?: boolean | null } | null };

export type LegalInformationIndividualMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  last4ssn: Scalars['String'];
  address: IndividualAddress;
  birthday: Scalars['String'];
}>;


export type LegalInformationIndividualMutation = { __typename?: 'mutation_root', createIndividualAccount?: { __typename?: 'CreateIndividualAccountOutput', success?: boolean | null } | null };

export type MakeBankAccountPrimaryMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type MakeBankAccountPrimaryMutation = { __typename?: 'mutation_root', defaultBankAccount?: { __typename?: 'DefaultBankAccountOutput', id: string } | null };

export type MarkChatAsMutationVariables = Exact<{
  whereUser?: InputMaybe<Bigint_Comparison_Exp>;
  roomID: Scalars['bigint'];
  seen?: InputMaybe<Scalars['Boolean']>;
}>;


export type MarkChatAsMutation = { __typename?: 'mutation_root', update_WUI_ChatRoomUser?: { __typename?: 'WUI_ChatRoomUser_mutation_response', affected_rows: number } | null };

export type MarkNotificationAsSeenMutationVariables = Exact<{
  id: Scalars['bigint'];
  seen?: InputMaybe<Scalars['timestamptz']>;
}>;


export type MarkNotificationAsSeenMutation = { __typename?: 'mutation_root', update_WUI_Notification_by_pk?: { __typename?: 'WUI_Notification', seen?: any | null } | null };

export type PaymentIntentMutationVariables = Exact<{
  orderId: Scalars['bigint'];
}>;


export type PaymentIntentMutation = { __typename?: 'mutation_root', create_WUI_PaymentIntent?: { __typename?: 'PaymentIntentOutput', intentID: string, clientSecret: string } | null };

export type SendMessageMutationVariables = Exact<{
  message?: InputMaybe<Scalars['String']>;
  roomID: Scalars['bigint'];
  userID: Scalars['bigint'];
  data?: InputMaybe<Scalars['jsonb']>;
}>;


export type SendMessageMutation = { __typename?: 'mutation_root', insert_WUI_ChatRoomMessage?: { __typename?: 'WUI_ChatRoomMessage_mutation_response', returning: Array<{ __typename?: 'WUI_ChatRoomMessage', id: any, created_at?: any | null, message: string, data?: any | null }> } | null };

export type ToggleAllPackagesStatusMutationVariables = Exact<{
  status?: InputMaybe<Scalars['Boolean']>;
}>;


export type ToggleAllPackagesStatusMutation = { __typename?: 'mutation_root', update_WUI_SellerPackage?: { __typename?: 'WUI_SellerPackage_mutation_response', returning: Array<{ __typename?: 'WUI_SellerPackage', id: any, enabled: boolean }> } | null };

export type ToggleAllProjectsStatusMutationVariables = Exact<{
  status: Scalars['Boolean'];
  userID: Scalars['bigint'];
}>;


export type ToggleAllProjectsStatusMutation = { __typename?: 'mutation_root', update_WUI_SellerProject?: { __typename?: 'WUI_SellerProject_mutation_response', affected_rows: number } | null };

export type TogglePackageStatusMutationVariables = Exact<{
  id: Scalars['bigint'];
  status?: InputMaybe<Scalars['Boolean']>;
}>;


export type TogglePackageStatusMutation = { __typename?: 'mutation_root', update_WUI_SellerPackage_by_pk?: { __typename?: 'WUI_SellerPackage', id: any, enabled: boolean } | null };

export type ToggleProjectStatusMutationVariables = Exact<{
  id: Scalars['bigint'];
  status?: InputMaybe<Scalars['Boolean']>;
}>;


export type ToggleProjectStatusMutation = { __typename?: 'mutation_root', update_WUI_SellerProject_by_pk?: { __typename?: 'WUI_SellerProject', id: any } | null };

export type UpdateLastSeenMutationVariables = Exact<{
  id: Scalars['bigint'];
  lastSeen?: InputMaybe<Scalars['timestamptz']>;
}>;


export type UpdateLastSeenMutation = { __typename?: 'mutation_root', update_wui_userlastseen?: { __typename?: 'wui_userlastseen_mutation_response', affected_rows: number } | null };

export type UpdateOrderStatusMutationVariables = Exact<{
  orderId: Scalars['bigint'];
  status: Scalars['String'];
}>;


export type UpdateOrderStatusMutation = { __typename?: 'mutation_root', updateStatus_WUI_Order?: { __typename?: 'UpdateOrderOutput', success?: boolean | null } | null };

export type UpdatePackageMutationVariables = Exact<{
  id: Scalars['bigint'];
  set: Wui_SellerPackage_Set_Input;
}>;


export type UpdatePackageMutation = { __typename?: 'mutation_root', update_WUI_SellerPackage_by_pk?: { __typename?: 'WUI_SellerPackage', description: string, enabled: boolean, id: any, package_type: Wui_SellerPackageType_Enum, price: any, title?: string | null, metadata?: any | null, hide_price: boolean, WUI_SellerPackageItems: Array<{ __typename?: 'WUI_SellerPackageItem', id: any, title?: string | null, comment?: string | null, quantity?: number | null }> } | null };

export type UpdatePackageItemsMutationVariables = Exact<{
  packageID: Scalars['bigint'];
  items: Array<Wui_SellerPackageItem_Insert_Input> | Wui_SellerPackageItem_Insert_Input;
}>;


export type UpdatePackageItemsMutation = { __typename?: 'mutation_root', delete_WUI_SellerPackageItem?: { __typename?: 'WUI_SellerPackageItem_mutation_response', affected_rows: number } | null, insert_WUI_SellerPackageItem?: { __typename?: 'WUI_SellerPackageItem_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'WUI_SellerPackageItem', id: any, title?: string | null, quantity?: number | null, comment?: string | null, WUI_SellerPackage: { __typename?: 'WUI_SellerPackage', id: any } }> } | null };

export type UpdatePackageRequestStatusMutationVariables = Exact<{
  status: Wui_RequestStatus_Enum;
  id: Scalars['bigint'];
}>;


export type UpdatePackageRequestStatusMutation = { __typename?: 'mutation_root', update_WUI_PackageRequest_by_pk?: { __typename?: 'WUI_PackageRequest', id: any, status: Wui_RequestStatus_Enum, tries: number } | null };

export type UpdateProfileSettingsMutationVariables = Exact<{
  userID: Scalars['bigint'];
  setUser: Wui_User_Set_Input;
}>;


export type UpdateProfileSettingsMutation = { __typename?: 'mutation_root', update_WUI_User_by_pk?: { __typename?: 'WUI_User', about_me?: string | null, is_seller: boolean, theme_color?: string | null, video_url?: string | null, allow_contact_without_order?: boolean | null, custom_color?: string | null, language: string, id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null } | null };

export type UpdateProfileVideosMutationVariables = Exact<{
  videoObjects: Array<UserVideo_Insert_Input> | UserVideo_Insert_Input;
}>;


export type UpdateProfileVideosMutation = { __typename?: 'mutation_root', delete_UserVideo?: { __typename?: 'UserVideo_mutation_response', affected_rows: number } | null, insert_UserVideo?: { __typename?: 'UserVideo_mutation_response', affected_rows: number } | null };

export type UpdateProjectMutationVariables = Exact<{
  projectID: Scalars['bigint'];
  set: Wui_SellerProject_Set_Input;
}>;


export type UpdateProjectMutation = { __typename?: 'mutation_root', update_WUI_SellerProject_by_pk?: { __typename?: 'WUI_SellerProject', id: any } | null };

export type UpdateProjectDemographicsMutationVariables = Exact<{
  projectID: Scalars['bigint'];
  objects: Array<ProjectDemographic_Insert_Input> | ProjectDemographic_Insert_Input;
}>;


export type UpdateProjectDemographicsMutation = { __typename?: 'mutation_root', delete_ProjectDemographic?: { __typename?: 'ProjectDemographic_mutation_response', affected_rows: number } | null, insert_ProjectDemographic?: { __typename?: 'ProjectDemographic_mutation_response', affected_rows: number } | null };

export type UpdateProjectItemsMutationVariables = Exact<{
  projectID: Scalars['bigint'];
  items: Array<Wui_SellerProjectItem_Insert_Input> | Wui_SellerProjectItem_Insert_Input;
}>;


export type UpdateProjectItemsMutation = { __typename?: 'mutation_root', delete_WUI_SellerProjectItem?: { __typename?: 'WUI_SellerProjectItem_mutation_response', affected_rows: number } | null, insert_WUI_SellerProjectItem?: { __typename?: 'WUI_SellerProjectItem_mutation_response', affected_rows: number } | null };

export type UpdateReviewMutationVariables = Exact<{
  id: Scalars['bigint'];
  stars: Scalars['Int'];
  comment: Scalars['String'];
  createdAt?: InputMaybe<Scalars['timestamptz']>;
}>;


export type UpdateReviewMutation = { __typename?: 'mutation_root', update_WUI_UserReview?: { __typename?: 'WUI_UserReview_mutation_response', affected_rows: number } | null };

export type UpdateSessionMutationVariables = Exact<{
  sessionId: Scalars['String'];
}>;


export type UpdateSessionMutation = { __typename?: 'mutation_root', update_WUI_Session?: { __typename?: 'WUI_Session_mutation_response', affected_rows: number } | null };

export type UpdateSocialMediaDataMutationVariables = Exact<{
  userID: Scalars['bigint'];
  objects: Array<SocialMediaDemographic_Insert_Input> | SocialMediaDemographic_Insert_Input;
}>;


export type UpdateSocialMediaDataMutation = { __typename?: 'mutation_root', delete_SocialMediaDemographic?: { __typename?: 'SocialMediaDemographic_mutation_response', affected_rows: number } | null, insert_SocialMediaDemographic?: { __typename?: 'SocialMediaDemographic_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'SocialMediaDemographic', demographic: Demographic_Enum, id: any, source: Wui_SocialMediaSource_Enum, user_id: any, value: string }> } | null };

export type UpdateSocialMediaLinksMutationVariables = Exact<{
  linkID: Scalars['bigint'];
  set?: InputMaybe<SocialMediaLink_Set_Input>;
}>;


export type UpdateSocialMediaLinksMutation = { __typename?: 'mutation_root', update_SocialMediaLink?: { __typename?: 'SocialMediaLink_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'SocialMediaLink', id: any, title: string, url: string, primary: boolean }> } | null };

export type UpdateUserSubscriptionMutationVariables = Exact<{
  externalId: Scalars['String'];
  set: Wui_UserSubscription_Set_Input;
}>;


export type UpdateUserSubscriptionMutation = { __typename?: 'mutation_root', update_WUI_UserSubscription_by_pk?: { __typename?: 'WUI_UserSubscription', id: any, expires_at: any, status: string, external_id: string, cancel_at?: any | null } | null };

export type UploadChatPictureMutationVariables = Exact<{
  imageType: Scalars['String'];
  imageBase64: Scalars['String'];
}>;


export type UploadChatPictureMutation = { __typename?: 'mutation_root', uploadPicture_WUI_ChatRoomMessage?: { __typename?: 'FileOutput', fileId: any, imageUrl: string, fileType: string } | null };

export type UploadCoverPictureMutationVariables = Exact<{
  imageType: Scalars['String'];
  imageBase64: Scalars['String'];
}>;


export type UploadCoverPictureMutation = { __typename?: 'mutation_root', uploadCoverPicture_WUI_user?: { __typename?: 'FileOutput', imageUrl: string } | null };

export type UploadMessageImageMutationVariables = Exact<{
  imageBase64: Scalars['String'];
  imageType: Scalars['String'];
}>;


export type UploadMessageImageMutation = { __typename?: 'mutation_root', uploadPicture_WUI_ChatRoomMessage?: { __typename?: 'FileOutput', imageUrl: string, fileType: string, fileId: any } | null };

export type UploadPorfolioPictureMutationVariables = Exact<{
  imageType: Scalars['String'];
  imageBase64: Scalars['String'];
}>;


export type UploadPorfolioPictureMutation = { __typename?: 'mutation_root', uploadPicture_WUI_UseFilePortfolio?: { __typename?: 'PortfolioFileOutput', portfolioId: any, fileId: any, imageUrl: string } | null };

export type UploadProfilePictureMutationVariables = Exact<{
  imageType: Scalars['String'];
  imageBase64: Scalars['String'];
}>;


export type UploadProfilePictureMutation = { __typename?: 'mutation_root', uploadProfilePicture_WUI_User?: { __typename?: 'FileOutput', imageUrl: string } | null };

export type UploadProjectPictureMutationVariables = Exact<{
  projectID: Scalars['bigint'];
  imageType: Scalars['String'];
  imageBase64: Scalars['String'];
}>;


export type UploadProjectPictureMutation = { __typename?: 'mutation_root', delete_WUI_SellerProjectFile?: { __typename?: 'WUI_SellerProjectFile_mutation_response', affected_rows: number } | null, uploadPicture_WUI_ProjectFile?: { __typename?: 'ProjectFileOutput', projectId: any, fileId: any, imageUrl: string } | null };

export type LoginMutationVariables = Exact<{
  externalID: Scalars['String'];
  token: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'mutation_root', login?: { __typename?: 'LoginOutput', id: any, email: string, firstName: string, lastName: string, username: string, phoneNumber?: string | null, isSeller: boolean, picture?: string | null, theme_color?: string | null, hasCompletedLegalInformation: boolean, accepted_terms: boolean } | null };

export type SignupMutationVariables = Exact<{
  username: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  entityName?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  picture?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  industry?: InputMaybe<Scalars['String']>;
  sourceUserId?: InputMaybe<Scalars['Int']>;
  demographics?: InputMaybe<Scalars['jsonb']>;
  suggestedPackages?: InputMaybe<Scalars['jsonb']>;
  links?: InputMaybe<Scalars['jsonb']>;
  language?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
}>;


export type SignupMutation = { __typename?: 'mutation_root', signup?: { __typename?: 'SignupOutput', id: any, email: string, entityName?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, username: string, isSeller: boolean, picture?: string | null, hasCompletedLegalInformation: boolean, accepted_terms: boolean, type: string } | null };

export type AdminCountUsersQueryVariables = Exact<{
  where: Wui_User_Bool_Exp;
}>;


export type AdminCountUsersQuery = { __typename?: 'query_root', WUI_User_aggregate: { __typename?: 'WUI_User_aggregate', aggregate?: { __typename?: 'WUI_User_aggregate_fields', count: number } | null } };

export type AdminGetUsersQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where: Wui_User_Bool_Exp;
}>;


export type AdminGetUsersQuery = { __typename?: 'query_root', WUI_User: Array<{ __typename?: 'WUI_User', is_seller: boolean, ambassador_id?: any | null, country?: string | null, source: SignupSource_Enum, completed_profile?: boolean | null, id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', url: string } | null, WUI_SessionEvents_aggregate: { __typename?: 'WUI_SessionEvent_aggregate', aggregate?: { __typename?: 'WUI_SessionEvent_aggregate_fields', count: number } | null }, WUI_Notification_aggregate: { __typename?: 'WUI_Notification_aggregate', nodes: Array<{ __typename?: 'WUI_Notification', id: any }>, aggregate?: { __typename?: 'WUI_Notification_aggregate_fields', count: number } | null } }> };

export type AdminUserByIdQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type AdminUserByIdQuery = { __typename?: 'query_root', WUI_User_by_pk?: { __typename?: 'WUI_User', ambassador_id?: any | null, country?: string | null, first_name?: string | null, industry?: Industry_Enum | null, is_seller: boolean, last_name?: string | null, phone_number?: string | null, username?: string | null, Ambassador?: { __typename?: 'Ambassador', id: any, custom_rate: any } | null } | null };

export type AdminUserSubsByUserIdQueryVariables = Exact<{
  userId: Scalars['bigint'];
  where: Wui_UserSubscription_Bool_Exp;
}>;


export type AdminUserSubsByUserIdQuery = { __typename?: 'query_root', WUI_User_by_pk?: { __typename?: 'WUI_User', WUI_UserSubscriptions: Array<{ __typename?: 'WUI_UserSubscription', id: any, external_id: string, status: string }> } | null };

export type AffiliateInfoQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type AffiliateInfoQuery = { __typename?: 'query_root', WUI_Affiliate: Array<{ __typename?: 'WUI_Affiliate', active: boolean, id: any, level_a: number, level_aa: number }> };

export type AffiliateTransactionsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type AffiliateTransactionsQuery = { __typename?: 'query_root', WUI_AffiliateTransaction_aggregate: { __typename?: 'WUI_AffiliateTransaction_aggregate', aggregate?: { __typename?: 'WUI_AffiliateTransaction_aggregate_fields', count: number } | null }, WUI_AffiliateTransaction: Array<{ __typename?: 'WUI_AffiliateTransaction', id: any, created_at: any, gross_amount: any, amount: any, level: Wui_AffiliateLevel_Enum, rate: any, status: Wui_AffiliateStatus_Enum, order_id?: any | null, Seller?: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null } | null }> };

export type AffiliateUsersQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<Wui_AffiliateUsers_Bool_Exp>;
}>;


export type AffiliateUsersQuery = { __typename?: 'query_root', WUI_AffiliateUsers: Array<{ __typename?: 'WUI_AffiliateUsers', id: any, WUI_User: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null } }> };

export type AllVoteUserCategoryQueryVariables = Exact<{ [key: string]: never; }>;


export type AllVoteUserCategoryQuery = { __typename?: 'query_root', VoteUserCategory: Array<{ __typename?: 'VoteUserCategory', id: any }> };

export type BasicUsersDataByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type BasicUsersDataByEmailQuery = { __typename?: 'query_root', WUI_User: Array<{ __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null }> };

export type BasicUsersDataByIdQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type BasicUsersDataByIdQuery = { __typename?: 'query_root', WUI_User: Array<{ __typename?: 'WUI_User', id: any, full_name?: string | null, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null }> };

export type BasicUsersDataByUsernameQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type BasicUsersDataByUsernameQuery = { __typename?: 'query_root', WUI_User: Array<{ __typename?: 'WUI_User', about_me?: string | null, is_seller: boolean, theme_color?: string | null, allow_contact_without_order?: boolean | null, custom_color?: string | null, completed_legal_information: boolean, industry?: Industry_Enum | null, ambassador_id?: any | null, platform_brand: string, id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null, UserVideos: Array<{ __typename?: 'UserVideo', id: any, url: string }>, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null, WUI_File_Cover_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null }> };

export type ChatRoomByIdQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type ChatRoomByIdQuery = { __typename?: 'query_root', WUI_ChatRoom_by_pk?: { __typename?: 'WUI_ChatRoom', id: any, type: Wui_ChatRoomType_Enum } | null };

export type ChatRoomOrderByIdQueryVariables = Exact<{
  chatRoomID: Scalars['bigint'];
}>;


export type ChatRoomOrderByIdQuery = { __typename?: 'query_root', WUI_ChatRoomOrder: Array<{ __typename?: 'WUI_ChatRoomOrder', id: any, WUI_Order: { __typename?: 'WUI_Order', id: any, title: string, WUI_User_Buyer: { __typename?: 'WUI_User', first_name?: string | null, last_name?: string | null, username?: string | null, id: any, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null }, WUI_User_Seller: { __typename?: 'WUI_User', first_name?: string | null, last_name?: string | null, username?: string | null, id: any, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null } } }> };

export type ChatRoomUsersByRoomIdQueryVariables = Exact<{
  id: Scalars['bigint'];
  userId: Scalars['bigint'];
}>;


export type ChatRoomUsersByRoomIdQuery = { __typename?: 'query_root', WUI_ChatRoom_by_pk?: { __typename?: 'WUI_ChatRoom', id: any, WUI_ChatRoomUsers: Array<{ __typename?: 'WUI_ChatRoomUser', id: any, WUI_User?: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null } | null }> } | null };

export type CheckUserExistsByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckUserExistsByEmailQuery = { __typename?: 'query_root', WUI_User: Array<{ __typename?: 'WUI_User', id: any }> };

export type CountCurrentOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type CountCurrentOrdersQuery = { __typename?: 'query_root', WUI_Order_aggregate: { __typename?: 'WUI_Order_aggregate', aggregate?: { __typename?: 'WUI_Order_aggregate_fields', count: number } | null } };

export type CountFreeVotesInCategoryQueryVariables = Exact<{
  where: Vote_Bool_Exp;
}>;


export type CountFreeVotesInCategoryQuery = { __typename?: 'query_root', Vote_aggregate: { __typename?: 'Vote_aggregate', aggregate?: { __typename?: 'Vote_aggregate_fields', count: number, sum?: { __typename?: 'Vote_sum_fields', votes?: number | null } | null } | null } };

export type CountPastOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type CountPastOrdersQuery = { __typename?: 'query_root', WUI_Order_aggregate: { __typename?: 'WUI_Order_aggregate', aggregate?: { __typename?: 'WUI_Order_aggregate_fields', count: number } | null } };

export type CountUserReviewsQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type CountUserReviewsQuery = { __typename?: 'query_root', WUI_UserReview_aggregate: { __typename?: 'WUI_UserReview_aggregate', aggregate?: { __typename?: 'WUI_UserReview_aggregate_fields', count: number, avg?: { __typename?: 'WUI_UserReview_avg_fields', stars?: number | null } | null } | null } };

export type CredentialsQueryVariables = Exact<{ [key: string]: never; }>;


export type CredentialsQuery = { __typename?: 'query_root', WUI_Account: Array<{ __typename?: 'WUI_Account', id: any, provider: string, type: string }> };

export type CreditCardsQueryVariables = Exact<{ [key: string]: never; }>;


export type CreditCardsQuery = { __typename?: 'query_root', WUI_PaymentMethodCard: Array<{ __typename?: 'WUI_PaymentMethodCard', brand: string, id: any, exp_year: number, exp_month: number, last4: string, country_code: string, external_id: string }> };

export type EmailDomainLookupQueryVariables = Exact<{
  domain: Scalars['String'];
}>;


export type EmailDomainLookupQuery = { __typename?: 'query_root', EmailDomainLookup_by_pk?: { __typename?: 'EmailDomainLookup', banned: boolean, seen: number } | null };

export type FindChatRoomsByOrderIdQueryVariables = Exact<{
  orderId: Scalars['bigint'];
}>;


export type FindChatRoomsByOrderIdQuery = { __typename?: 'query_root', WUI_ChatRoomOrder: Array<{ __typename?: 'WUI_ChatRoomOrder', id: any, order_id: any, WUI_ChatRoom: { __typename?: 'WUI_ChatRoom', id: any, type: Wui_ChatRoomType_Enum } }> };

export type GetActiveSubscriptionsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetActiveSubscriptionsQuery = { __typename?: 'query_root', WUI_OrderSubscription: Array<{ __typename?: 'WUI_OrderSubscription', id: any, next_billing_date: any, order_id: any, payment_method_id: any, recurring_period: Wui_OrderSubscriptionPeriod_Enum, status: Wui_OrderSubscriptionStatus_Enum, created_at?: any | null, WUI_Order: { __typename?: 'WUI_Order', price: any, package_type: Wui_SellerPackageType_Enum, WUI_User_Buyer: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', file_type: Wui_FileType_Enum, id: any, url: string, created_at: any } | null }, WUI_User_Seller: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', file_type: Wui_FileType_Enum, id: any, url: string, created_at: any } | null } } }> };

export type GetAllNomineesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllNomineesQuery = { __typename?: 'query_root', VoteUser: Array<{ __typename?: 'VoteUser', WUI_User: { __typename?: 'WUI_User', username?: string | null } }> };

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUsersQuery = { __typename?: 'query_root', WUI_User: Array<{ __typename?: 'WUI_User', username?: string | null }> };

export type GetAudienceSizeQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type GetAudienceSizeQuery = { __typename?: 'query_root', SocialMediaDemographic: Array<{ __typename?: 'SocialMediaDemographic', demographic: Demographic_Enum, id: any, source: Wui_SocialMediaSource_Enum, user_id: any, value: string }> };

export type GetBankAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBankAccountsQuery = { __typename?: 'query_root', getBankAccounts: Array<{ __typename?: 'BankAccountOutput', id: string, last4: string, object: string, routing_number: string, status: string, fingerprint: string, currency: string, country: string, bank_name: string, account_holder_type: string, account_holder_name: string, account: string, metadata: any }> };

export type GetChatRoomByIdQueryVariables = Exact<{
  roomID: Scalars['bigint'];
}>;


export type GetChatRoomByIdQuery = { __typename?: 'query_root', WUI_ChatRoom_by_pk?: { __typename?: 'WUI_ChatRoom', id: any, type: Wui_ChatRoomType_Enum, LastMessage?: { __typename?: 'WUI_ChatRoomMessage', created_at?: any | null, message: string } | null, WUI_ChatRoomUsers: Array<{ __typename?: 'WUI_ChatRoomUser', id: any, seen?: boolean | null, WUI_User?: { __typename?: 'WUI_User', id: any, username?: string | null, first_name?: string | null, last_name?: string | null } | null }> } | null };

export type GetChatRoomsByUserQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  filter?: InputMaybe<Wui_ChatRoom_Bool_Exp>;
}>;


export type GetChatRoomsByUserQuery = { __typename?: 'query_root', WUI_ChatRoom: Array<{ __typename?: 'WUI_ChatRoom', id: any, type: Wui_ChatRoomType_Enum, LastMessage?: { __typename?: 'WUI_ChatRoomMessage', created_at?: any | null, message: string } | null, WUI_ChatRoomUsers: Array<{ __typename?: 'WUI_ChatRoomUser', id: any, seen?: boolean | null, WUI_User?: { __typename?: 'WUI_User', type?: Wui_UserType_Enum | null, id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null } | null }> }> };

export type GetClicksByLinkIdQueryVariables = Exact<{
  userID: Scalars['bigint'];
  linkID: Scalars['bigint'];
}>;


export type GetClicksByLinkIdQuery = { __typename?: 'query_root', WUI_SessionEvent_aggregate: { __typename?: 'WUI_SessionEvent_aggregate', aggregate?: { __typename?: 'WUI_SessionEvent_aggregate_fields', count: number } | null, nodes: Array<{ __typename?: 'WUI_SessionEvent', id: any, created_at: any, SocialMediaLink?: { __typename?: 'SocialMediaLink', title: string, id: any } | null }> } };

export type GetClientsQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetClientsQuery = { __typename?: 'query_root', WUI_Order: Array<{ __typename?: 'WUI_Order', buyer_id: any, seller_id: any, WUI_User_Buyer: { __typename?: 'WUI_User', is_seller: boolean, id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', url: string } | null, SocialMediaDemographics: Array<{ __typename?: 'SocialMediaDemographic', source: Wui_SocialMediaSource_Enum, value: string, demographic: Demographic_Enum }> } }> };

export type GetConverSationWithUserIdQueryVariables = Exact<{
  firstID: Scalars['bigint'];
  secondID: Scalars['bigint'];
}>;


export type GetConverSationWithUserIdQuery = { __typename?: 'query_root', WUI_ChatRoom: Array<{ __typename?: 'WUI_ChatRoom', id: any, WUI_ChatRoomUsers: Array<{ __typename?: 'WUI_ChatRoomUser', id: any, WUI_User?: { __typename?: 'WUI_User', id: any, username?: string | null, first_name?: string | null } | null }> }> };

export type GetCreatedReviewsQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type GetCreatedReviewsQuery = { __typename?: 'query_root', WUI_UserReview: Array<{ __typename?: 'WUI_UserReview', id: any, to_user_id: any, from_user_id: any, created_at: any, comment?: string | null, stars: number, WUI_User_To: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', url: string, id: any, file_type: Wui_FileType_Enum, created_at: any } | null } }> };

export type GetCurrentOrdersMetricsQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type GetCurrentOrdersMetricsQuery = { __typename?: 'query_root', actives: { __typename?: 'WUI_Order_aggregate', aggregate?: { __typename?: 'WUI_Order_aggregate_fields', count: number, sum?: { __typename?: 'WUI_Order_sum_fields', price?: any | null } | null } | null, nodes: Array<{ __typename?: 'WUI_Order', id: any }> }, pending: { __typename?: 'WUI_Order_aggregate', aggregate?: { __typename?: 'WUI_Order_aggregate_fields', count: number, sum?: { __typename?: 'WUI_Order_sum_fields', price?: any | null } | null } | null, nodes: Array<{ __typename?: 'WUI_Order', id: any }> } };

export type GetHiredUsersQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetHiredUsersQuery = { __typename?: 'query_root', WUI_Order: Array<{ __typename?: 'WUI_Order', buyer_id: any, seller_id: any, WUI_User_Seller: { __typename?: 'WUI_User', is_seller: boolean, id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', url: string } | null, SocialMediaDemographics: Array<{ __typename?: 'SocialMediaDemographic', source: Wui_SocialMediaSource_Enum, value: string, demographic: Demographic_Enum }> } }> };

export type GetInactiveSubscriptionsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetInactiveSubscriptionsQuery = { __typename?: 'query_root', WUI_OrderSubscription: Array<{ __typename?: 'WUI_OrderSubscription', id: any, next_billing_date: any, order_id: any, payment_method_id: any, recurring_period: Wui_OrderSubscriptionPeriod_Enum, status: Wui_OrderSubscriptionStatus_Enum, created_at?: any | null, WUI_Order: { __typename?: 'WUI_Order', price: any, package_type: Wui_SellerPackageType_Enum, WUI_User_Buyer: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', file_type: Wui_FileType_Enum, id: any, url: string, created_at: any } | null }, WUI_User_Seller: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', file_type: Wui_FileType_Enum, id: any, url: string, created_at: any } | null } } }> };

export type GetIndustriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIndustriesQuery = { __typename?: 'query_root', Industry: Array<{ __typename?: 'Industry', value: string }> };

export type GetLastChatMessageByRoomIdQueryVariables = Exact<{
  roomID: Scalars['bigint'];
}>;


export type GetLastChatMessageByRoomIdQuery = { __typename?: 'query_root', WUI_ChatRoom_by_pk?: { __typename?: 'WUI_ChatRoom', id: any, WUI_ChatRoomMessages: Array<{ __typename?: 'WUI_ChatRoomMessage', id: any, created_at?: any | null, message: string }> } | null };

export type GetMarketplaceWaitlistQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMarketplaceWaitlistQuery = { __typename?: 'query_root', MarketplaceWaitlist: Array<{ __typename?: 'MarketplaceWaitlist', user_id: any, created_at: any, updated_at: any, id: any }> };

export type GetMessagesByRoomIdSubscriptionVariables = Exact<{
  roomID: Scalars['bigint'];
  limit: Scalars['Int'];
}>;


export type GetMessagesByRoomIdSubscription = { __typename?: 'subscription_root', WUI_ChatRoomMessage: Array<{ __typename?: 'WUI_ChatRoomMessage', id: any, message: string, created_at?: any | null, room_id: any, data?: any | null, WUI_User: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null } }> };

export type GetOrderConversationsByUserIdQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type GetOrderConversationsByUserIdQuery = { __typename?: 'query_root', WUI_ChatRoom: Array<{ __typename?: 'WUI_ChatRoom', id: any, WUI_ChatRoomMessages: Array<{ __typename?: 'WUI_ChatRoomMessage', created_at?: any | null, id: any, message: string, data?: any | null, WUI_User: { __typename?: 'WUI_User', id: any, username?: string | null, first_name?: string | null } }>, WUI_ChatRoomUsers: Array<{ __typename?: 'WUI_ChatRoomUser', id: any, WUI_User?: { __typename?: 'WUI_User', id: any, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null } | null }> }> };

export type GetOrderDetailsMessageQueryVariables = Exact<{
  orderID: Scalars['bigint'];
}>;


export type GetOrderDetailsMessageQuery = { __typename?: 'query_root', WUI_ChatRoomOrder: Array<{ __typename?: 'WUI_ChatRoomOrder', id: any, WUI_ChatRoom: { __typename?: 'WUI_ChatRoom', id: any, WUI_ChatRoomMessages: Array<{ __typename?: 'WUI_ChatRoomMessage', created_at?: any | null, data?: any | null, id: any, message: string, updated_at?: any | null, user_id: any }> } }> };

export type GetOrdersHistoryMetricsQueryVariables = Exact<{
  userID: Scalars['bigint'];
  whereClosedAt: Timestamptz_Comparison_Exp;
}>;


export type GetOrdersHistoryMetricsQuery = { __typename?: 'query_root', completed: { __typename?: 'WUI_Order_aggregate', aggregate?: { __typename?: 'WUI_Order_aggregate_fields', count: number, sum?: { __typename?: 'WUI_Order_sum_fields', price?: any | null } | null } | null, nodes: Array<{ __typename?: 'WUI_Order', id: any }> }, cancelled: { __typename?: 'WUI_Order_aggregate', aggregate?: { __typename?: 'WUI_Order_aggregate_fields', count: number, sum?: { __typename?: 'WUI_Order_sum_fields', price?: any | null } | null } | null, nodes: Array<{ __typename?: 'WUI_Order', id: any }> } };

export type GetPackagesRequestQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type GetPackagesRequestQuery = { __typename?: 'query_root', WUI_PackageRequest: Array<{ __typename?: 'WUI_PackageRequest', id: any, updated_at: any, requester_id: any, seller_id: any, status: Wui_RequestStatus_Enum, WUI_Seller_User: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null, WUI_File_Cover_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null }, WUI_Requester_User: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null, WUI_File_Cover_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null }, WUI_SellerPackage: { __typename?: 'WUI_SellerPackage', id: any, title?: string | null } }> };

export type GetPackagesRequestByPackageIdQueryVariables = Exact<{
  package_id?: InputMaybe<Scalars['bigint']>;
}>;


export type GetPackagesRequestByPackageIdQuery = { __typename?: 'query_root', WUI_PackageRequest: Array<{ __typename?: 'WUI_PackageRequest', id: any, status: Wui_RequestStatus_Enum, tries: number }> };

export type GetPaymentByOrderIdQueryVariables = Exact<{
  order_id: Scalars['bigint'];
}>;


export type GetPaymentByOrderIdQuery = { __typename?: 'query_root', WUI_Payment: Array<{ __typename?: 'WUI_Payment', price: any }> };

export type GetPaymentsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetPaymentsQuery = { __typename?: 'query_root', WUI_Payment: Array<{ __typename?: 'WUI_Payment', id: any, price: any, statement_description?: string | null, description?: string | null, status: Wui_PaymentStatus_Enum, updated_at: any, created_at: any, seller_fees: any, buyer_fees: any, WUI_Order: { __typename?: 'WUI_Order', id: any, description: string, package_type: Wui_SellerPackageType_Enum, status: Wui_OrderStatus_Enum, buyer_id: any, seller_id: any, WUI_User_Seller: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', url: string, id: any, file_type: Wui_FileType_Enum, created_at: any } | null }, WUI_User_Buyer: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', url: string, id: any, file_type: Wui_FileType_Enum, created_at: any } | null } } }> };

export type GetProfileViewsQueryVariables = Exact<{
  userID: Scalars['bigint'];
  whereCreatedAt: Timestamptz_Comparison_Exp;
}>;


export type GetProfileViewsQuery = { __typename?: 'query_root', WUI_SessionEvent_aggregate: { __typename?: 'WUI_SessionEvent_aggregate', aggregate?: { __typename?: 'WUI_SessionEvent_aggregate_fields', count: number } | null, nodes: Array<{ __typename?: 'WUI_SessionEvent', id: any, created_at: any }> } };

export type GetReviewByOrderIdQueryVariables = Exact<{
  orderID: Scalars['bigint'];
}>;


export type GetReviewByOrderIdQuery = { __typename?: 'query_root', WUI_OrderReview: Array<{ __typename?: 'WUI_OrderReview', WUI_UserReview: { __typename?: 'WUI_UserReview', comment?: string | null, created_at: any, from_user_id: any, id: any, stars: number, to_user_id: any, WUI_User_To: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', url: string, id: any, file_type: Wui_FileType_Enum, created_at: any } | null }, WUI_User_From: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', url: string, id: any, file_type: Wui_FileType_Enum, created_at: any } | null } } }> };

export type GetSentPackagesRequestQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type GetSentPackagesRequestQuery = { __typename?: 'query_root', WUI_PackageRequest: Array<{ __typename?: 'WUI_PackageRequest', id: any, updated_at: any, requester_id: any, seller_id: any, status: Wui_RequestStatus_Enum, WUI_Seller_User: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null, WUI_File_Cover_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null }, WUI_Requester_User: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null, WUI_File_Cover_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null }, WUI_SellerPackage: { __typename?: 'WUI_SellerPackage', id: any, title?: string | null } }> };

export type GetSessionQueryVariables = Exact<{
  where: Wui_Session_Bool_Exp;
}>;


export type GetSessionQuery = { __typename?: 'query_root', WUI_Session: Array<{ __typename?: 'WUI_Session', id: any, session_id: string, user_id?: any | null }> };

export type GetSocialMediaDemographicsQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type GetSocialMediaDemographicsQuery = { __typename?: 'query_root', SocialMediaDemographic: Array<{ __typename?: 'SocialMediaDemographic', id: any, demographic: Demographic_Enum, source: Wui_SocialMediaSource_Enum, user_id: any, value: string }> };

export type GetSocialMediaLinksQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type GetSocialMediaLinksQuery = { __typename?: 'query_root', SocialMediaLink: Array<{ __typename?: 'SocialMediaLink', id: any, primary: boolean, title: string, url: string, user_id: any, enabled: boolean, solid: boolean, order: any }> };

export type GetStartedAndEndedAtQueryVariables = Exact<{
  voteUserCategoryId: Scalars['uuid'];
}>;


export type GetStartedAndEndedAtQuery = { __typename?: 'query_root', VoteUserCategory_by_pk?: { __typename?: 'VoteUserCategory', VoteUser: { __typename?: 'VoteUser', started_at: any, ended_at: any } } | null };

export type GetSubscriptionByOrderIdQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetSubscriptionByOrderIdQuery = { __typename?: 'query_root', WUI_OrderSubscription: Array<{ __typename?: 'WUI_OrderSubscription', id: any, next_billing_date: any, recurring_period: Wui_OrderSubscriptionPeriod_Enum }> };

export type GetSupportedCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSupportedCountriesQuery = { __typename?: 'query_root', getCountrySupported: { __typename?: 'CountrySupportedOutput', data: any } };

export type GetUserReviewsQueryVariables = Exact<{
  userID: Scalars['bigint'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetUserReviewsQuery = { __typename?: 'query_root', WUI_UserReview: Array<{ __typename?: 'WUI_UserReview', id: any, to_user_id: any, from_user_id: any, created_at: any, comment?: string | null, stars: number, WUI_User_From: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', url: string, id: any, file_type: Wui_FileType_Enum } | null } }> };

export type LegalInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type LegalInformationQuery = { __typename?: 'query_root', legalInformation: { __typename?: 'LegalInformationOutput', type: string, data?: any | null, address?: any | null } };

export type ManageOrdersQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where: Wui_Order_Bool_Exp;
}>;


export type ManageOrdersQuery = { __typename?: 'query_root', WUI_Order: Array<{ __typename?: 'WUI_Order', id: any, package_type: Wui_SellerPackageType_Enum, title: string, description: string, price: any, status: Wui_OrderStatus_Enum, created_by: any, created_at: any, items: any, updated_at: any, buyer_id: any, seller_id: any, metadata?: any | null, WUI_User_Buyer: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null }, WUI_User_Seller: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null }, WUI_Payment?: { __typename?: 'WUI_Payment', price: any } | null }> };

export type ManagePackagesQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type ManagePackagesQuery = { __typename?: 'query_root', WUI_SellerPackage: Array<{ __typename?: 'WUI_SellerPackage', description: string, enabled: boolean, id: any, package_type: Wui_SellerPackageType_Enum, price: any, title?: string | null, metadata?: any | null, hide_price: boolean, WUI_SellerPackageItems: Array<{ __typename?: 'WUI_SellerPackageItem', id: any, title?: string | null, comment?: string | null, quantity?: number | null }> }> };

export type ManageProjectsQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type ManageProjectsQuery = { __typename?: 'query_root', WUI_SellerProject: Array<{ __typename?: 'WUI_SellerProject', description: string, enabled: boolean, id: any, price: any, title: string, WUI_SellerProjectItems: Array<{ __typename?: 'WUI_SellerProjectItem', details: string }>, demographics: Array<{ __typename?: 'ProjectDemographic', demographic: Demographic_Enum, id: any, project_id: any, source: Wui_SocialMediaSource_Enum, value: string }>, WUI_SellerProjectFiles: Array<{ __typename?: 'WUI_SellerProjectFile', WUI_File: { __typename?: 'WUI_File', url: string } }> }> };

export type MostRecentOpenOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type MostRecentOpenOrdersQuery = { __typename?: 'query_root', WUI_Order: Array<{ __typename?: 'WUI_Order', id: any, package_type: Wui_SellerPackageType_Enum, title: string, description: string, price: any, status: Wui_OrderStatus_Enum, created_by: any, created_at: any, items: any, buyer_id: any, WUI_User_Buyer: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null }, WUI_User_Seller: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null } }> };

export type MostRecentPendingOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type MostRecentPendingOrdersQuery = { __typename?: 'query_root', WUI_Order: Array<{ __typename?: 'WUI_Order', id: any, package_type: Wui_SellerPackageType_Enum, title: string, description: string, price: any, status: Wui_OrderStatus_Enum, created_by: any, created_at: any, items: any, buyer_id: any, WUI_User_Buyer: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', url: string } | null }, WUI_User_Seller: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', url: string } | null } }> };

export type OrderByIdQueryVariables = Exact<{
  orderID: Scalars['bigint'];
}>;


export type OrderByIdQuery = { __typename?: 'query_root', WUI_Order_by_pk?: { __typename?: 'WUI_Order', id: any, package_type: Wui_SellerPackageType_Enum, title: string, description: string, price: any, status: Wui_OrderStatus_Enum, created_by: any, created_at: any, items: any, seller_id: any, buyer_id: any } | null };

export type PackageDetailsQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type PackageDetailsQuery = { __typename?: 'query_root', WUI_SellerPackage_by_pk?: { __typename?: 'WUI_SellerPackage', id: any, enabled: boolean, description: string, package_type: Wui_SellerPackageType_Enum, price: any, title?: string | null, user_id: any, metadata?: any | null, hide_price: boolean, WUI_SellerPackageItems: Array<{ __typename?: 'WUI_SellerPackageItem', id: any, quantity?: number | null, title?: string | null, comment?: string | null }>, WUI_User: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', url: string } | null } } | null };

export type PackageRequestsByPackageIdQueryVariables = Exact<{
  packageId: Scalars['bigint'];
}>;


export type PackageRequestsByPackageIdQuery = { __typename?: 'query_root', WUI_PackageRequest: Array<{ __typename?: 'WUI_PackageRequest', status: Wui_RequestStatus_Enum }> };

export type PlatformAlertsQueryVariables = Exact<{ [key: string]: never; }>;


export type PlatformAlertsQuery = { __typename?: 'query_root', PlatformAlert: Array<{ __typename?: 'PlatformAlert', id: any, created_at: any, updated_at: any, message: string, message_es: string, type: string }> };

export type PortfolioPicturesQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type PortfolioPicturesQuery = { __typename?: 'query_root', WUI_UserFilePortfolio: Array<{ __typename?: 'WUI_UserFilePortfolio', id: any, WUI_File?: { __typename?: 'WUI_File', id: any, file_type: Wui_FileType_Enum, url: string } | null }> };

export type ProPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type ProPlansQuery = { __typename?: 'query_root', WUI_SubscriptionPlan: Array<{ __typename?: 'WUI_SubscriptionPlan', id: any, name: string, price: number, original_price?: number | null, frequency: string }> };

export type ProfilePackagesQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type ProfilePackagesQuery = { __typename?: 'query_root', WUI_SellerPackage: Array<{ __typename?: 'WUI_SellerPackage', description: string, enabled: boolean, id: any, package_type: Wui_SellerPackageType_Enum, price: any, title?: string | null, user_id: any, metadata?: any | null, hide_price: boolean, WUI_SellerPackageItems: Array<{ __typename?: 'WUI_SellerPackageItem', id: any, title?: string | null, comment?: string | null, quantity?: number | null }> }> };

export type ProfileSettingsQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type ProfileSettingsQuery = { __typename?: 'query_root', WUI_User_by_pk?: { __typename?: 'WUI_User', about_me?: string | null, is_seller: boolean, theme_color?: string | null, allow_contact_without_order?: boolean | null, custom_color?: string | null, country?: string | null, industry?: Industry_Enum | null, language: string, id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null, WUI_File_Cover_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null, UserVideos: Array<{ __typename?: 'UserVideo', id: any, url: string }> } | null };

export type PublicProfileQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type PublicProfileQuery = { __typename?: 'query_root', SocialMediaDemographic: Array<{ __typename?: 'SocialMediaDemographic', id: any, demographic: Demographic_Enum, source: Wui_SocialMediaSource_Enum, user_id: any, value: string }>, WUI_SellerPackage: Array<{ __typename?: 'WUI_SellerPackage', description: string, enabled: boolean, id: any, package_type: Wui_SellerPackageType_Enum, price: any, title?: string | null, user_id: any, metadata?: any | null, hide_price: boolean, WUI_SellerPackageItems: Array<{ __typename?: 'WUI_SellerPackageItem', id: any, title?: string | null, comment?: string | null, quantity?: number | null }> }>, WUI_UserFilePortfolio: Array<{ __typename?: 'WUI_UserFilePortfolio', id: any, order?: number | null, WUI_File?: { __typename?: 'WUI_File', id: any, file_type: Wui_FileType_Enum, url: string } | null }>, SocialMediaLink: Array<{ __typename?: 'SocialMediaLink', id: any, primary: boolean, title: string, url: string, user_id: any, enabled: boolean, solid: boolean }>, WUI_UserReview_aggregate: { __typename?: 'WUI_UserReview_aggregate', aggregate?: { __typename?: 'WUI_UserReview_aggregate_fields', count: number, avg?: { __typename?: 'WUI_UserReview_avg_fields', stars?: number | null } | null } | null }, UserVideo: Array<{ __typename?: 'UserVideo', id: any, url: string }>, WUI_Affiliate_aggregate: { __typename?: 'WUI_Affiliate_aggregate', aggregate?: { __typename?: 'WUI_Affiliate_aggregate_fields', count: number } | null }, WUI_UserSubscription_aggregate: { __typename?: 'WUI_UserSubscription_aggregate', aggregate?: { __typename?: 'WUI_UserSubscription_aggregate_fields', count: number } | null }, VoteUser_by_pk?: { __typename?: 'VoteUser', started_at: any, ended_at: any, primary_category_id: string, VoteUserCategories: Array<{ __typename?: 'VoteUserCategory', id: any, category_id: string }> } | null };

export type SearchInfluencersQueryVariables = Exact<{
  filters: SearchInfluencersFilters;
}>;


export type SearchInfluencersQuery = { __typename?: 'query_root', searchInfluencers: Array<{ __typename?: 'SearchInfluencersOutput', user_id: any, picture?: string | null, first_name: string, last_name: string, username: string, review_avg: number, social_media: Array<string | null>, highlights: Array<any | null>, from_price: number, is_seller: boolean } | null> };

export type SocialMediaQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type SocialMediaQuery = { __typename?: 'query_root', SocialMedia: Array<{ __typename?: 'SocialMedia', id: any, user_id: any, platform: SocialMediaPlatform_Enum, handle: string, followers: any, following: any, posts: any, engagement: any }> };

export type SubscriptionPlanByIdQueryVariables = Exact<{
  planID: Scalars['uuid'];
}>;


export type SubscriptionPlanByIdQuery = { __typename?: 'query_root', WUI_SubscriptionPlan_by_pk?: { __typename?: 'WUI_SubscriptionPlan', stripe_product_id: string, stripe_price_id: string, price: number } | null };

export type TotalAffiliateUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type TotalAffiliateUsersQuery = { __typename?: 'query_root', WUI_AffiliateUsers_aggregate: { __typename?: 'WUI_AffiliateUsers_aggregate', aggregate?: { __typename?: 'WUI_AffiliateUsers_aggregate_fields', count: number } | null } };

export type UpdateOrderDetailsMutationVariables = Exact<{
  messageID: Scalars['bigint'];
  data: Scalars['jsonb'];
}>;


export type UpdateOrderDetailsMutation = { __typename?: 'mutation_root', update_WUI_ChatRoomMessage_by_pk?: { __typename?: 'WUI_ChatRoomMessage', id: any, data?: any | null } | null };

export type UpdateUserIsTypingMutationVariables = Exact<{
  set: Wui_ChatRoomUser_Set_Input;
  userID: Scalars['bigint'];
  roomID: Scalars['bigint'];
}>;


export type UpdateUserIsTypingMutation = { __typename?: 'mutation_root', update_WUI_ChatRoomUser?: { __typename?: 'WUI_ChatRoomUser_mutation_response', affected_rows: number } | null };

export type UserNameAndPictureQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type UserNameAndPictureQuery = { __typename?: 'query_root', WUI_User_by_pk?: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null } | null };

export type UserPictureQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type UserPictureQuery = { __typename?: 'query_root', WUI_User_by_pk?: { __typename?: 'WUI_User', id: any, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null } | null };

export type UserSourceInfoQueryVariables = Exact<{
  userId: Scalars['bigint'];
}>;


export type UserSourceInfoQuery = { __typename?: 'query_root', WUI_User_by_pk?: { __typename?: 'WUI_User', type?: Wui_UserType_Enum | null, id: any, first_name?: string | null, last_name?: string | null, full_name?: string | null, entity_name?: string | null, public_name?: string | null, username?: string | null, contact_email: string, phone_number?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', url: string } | null, WUI_UserSubscriptions_aggregate: { __typename?: 'WUI_UserSubscription_aggregate', aggregate?: { __typename?: 'WUI_UserSubscription_aggregate_fields', count: number } | null } } | null };

export type UserStripeCustomerIdQueryVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type UserStripeCustomerIdQuery = { __typename?: 'query_root', WUI_User_by_pk?: { __typename?: 'WUI_User', stripe_customer_id?: string | null } | null };

export type UserSubscriptionsByUserIdQueryVariables = Exact<{
  userId: Scalars['bigint'];
}>;


export type UserSubscriptionsByUserIdQuery = { __typename?: 'query_root', WUI_UserSubscription: Array<{ __typename?: 'WUI_UserSubscription', id: any, expires_at: any, status: string, external_id: string, cancel_at?: any | null }> };

export type VoteUserInfoQueryVariables = Exact<{
  voteUserCategoryId: Scalars['uuid'];
}>;


export type VoteUserInfoQuery = { __typename?: 'query_root', VoteUserCategory_by_pk?: { __typename?: 'VoteUserCategory', category_id: string, VoteUser: { __typename?: 'VoteUser', started_at: any, ended_at: any, WUI_User: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', url: string } | null } } } | null };

export type VotesByVoterIdQueryVariables = Exact<{
  voterId: Scalars['bigint'];
}>;


export type VotesByVoterIdQuery = { __typename?: 'query_root', Vote: Array<{ __typename?: 'Vote', category_id: string, user_category_id: any, VoteUserCategory: { __typename?: 'VoteUserCategory', VoteUser: { __typename?: 'VoteUser', WUI_User: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', url: string } | null } } } }> };

export type ChatRoomsSubscriptionSubscriptionVariables = Exact<{
  filter?: InputMaybe<Wui_ChatRoom_Bool_Exp>;
}>;


export type ChatRoomsSubscriptionSubscription = { __typename?: 'subscription_root', WUI_ChatRoom: Array<{ __typename?: 'WUI_ChatRoom', id: any, type: Wui_ChatRoomType_Enum, LastMessage?: { __typename?: 'WUI_ChatRoomMessage', created_at?: any | null, message: string } | null, WUI_ChatRoomUsers: Array<{ __typename?: 'WUI_ChatRoomUser', id: any, seen?: boolean | null, WUI_User?: { __typename?: 'WUI_User', id: any, username?: string | null, first_name?: string | null, last_name?: string | null } | null }> }> };

export type CheckUserIsTypingSubscriptionVariables = Exact<{
  roomID: Scalars['bigint'];
  userID: Scalars['bigint'];
}>;


export type CheckUserIsTypingSubscription = { __typename?: 'subscription_root', WUI_ChatRoomUser: Array<{ __typename?: 'WUI_ChatRoomUser', typing?: boolean | null, user_id?: any | null, last_typing?: any | null, WUI_User?: { __typename?: 'WUI_User', id: any, username?: string | null, first_name?: string | null, last_name?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null } | null }> };

export type CountChatMessagesSubscriptionVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type CountChatMessagesSubscription = { __typename?: 'subscription_root', WUI_ChatRoomMessage_aggregate: { __typename?: 'WUI_ChatRoomMessage_aggregate', aggregate?: { __typename?: 'WUI_ChatRoomMessage_aggregate_fields', count: number } | null } };

export type CountPricesRequestsSubscriptionVariables = Exact<{
  userID?: InputMaybe<Scalars['bigint']>;
}>;


export type CountPricesRequestsSubscription = { __typename?: 'subscription_root', WUI_PackageRequest_aggregate: { __typename?: 'WUI_PackageRequest_aggregate', aggregate?: { __typename?: 'WUI_PackageRequest_aggregate_fields', count: number } | null } };

export type CountReviewsByOrderIdSubscriptionVariables = Exact<{
  userID: Scalars['bigint'];
  orderId: Scalars['bigint'];
}>;


export type CountReviewsByOrderIdSubscription = { __typename?: 'subscription_root', WUI_OrderReview_aggregate: { __typename?: 'WUI_OrderReview_aggregate', aggregate?: { __typename?: 'WUI_OrderReview_aggregate_fields', count: number } | null } };

export type CountUnSeenChatRoomsSubscriptionVariables = Exact<{
  userID: Scalars['bigint'];
}>;


export type CountUnSeenChatRoomsSubscription = { __typename?: 'subscription_root', WUI_ChatRoomUser_aggregate: { __typename?: 'WUI_ChatRoomUser_aggregate', aggregate?: { __typename?: 'WUI_ChatRoomUser_aggregate_fields', count: number } | null } };

export type CountUnseenNotificationsSubscriptionVariables = Exact<{
  userId: Scalars['bigint'];
}>;


export type CountUnseenNotificationsSubscription = { __typename?: 'subscription_root', WUI_Notification_aggregate: { __typename?: 'WUI_Notification_aggregate', aggregate?: { __typename?: 'WUI_Notification_aggregate_fields', count: number } | null } };

export type GetChatUserByUserIdSubscriptionVariables = Exact<{
  userID: Scalars['bigint'];
  roomID: Scalars['bigint'];
}>;


export type GetChatUserByUserIdSubscription = { __typename?: 'subscription_root', WUI_ChatRoomUser: Array<{ __typename?: 'WUI_ChatRoomUser', seen?: boolean | null }> };

export type GetUserLastSeenSubscriptionVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetUserLastSeenSubscription = { __typename?: 'subscription_root', wui_userlastseen: Array<{ __typename?: 'wui_userlastseen', last_seen_date?: any | null }> };

export type NotificationsSubscriptionVariables = Exact<{
  userId: Scalars['bigint'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type NotificationsSubscription = { __typename?: 'subscription_root', WUI_Notification: Array<{ __typename?: 'WUI_Notification', id: any, created_at: any, message: string, metadata: any, seen?: any | null, title?: string | null }> };

export type OrderByIdSubscriptionSubscriptionVariables = Exact<{
  orderID: Scalars['bigint'];
}>;


export type OrderByIdSubscriptionSubscription = { __typename?: 'subscription_root', WUI_Order_by_pk?: { __typename?: 'WUI_Order', id: any, package_type: Wui_SellerPackageType_Enum, title: string, description: string, price: any, status: Wui_OrderStatus_Enum, created_by: any, created_at: any, items: any, seller_id: any, buyer_id: any, updated_at: any, metadata?: any | null, WUI_User_Buyer: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null }, WUI_User_Seller: { __typename?: 'WUI_User', id: any, first_name?: string | null, last_name?: string | null, username?: string | null, WUI_File_Profile_Picture?: { __typename?: 'WUI_File', id: any, url: string } | null }, WUI_Payment?: { __typename?: 'WUI_Payment', price: any } | null } | null };

export const UserDetailsFragmentDoc = gql`
    fragment UserDetails on WUI_User {
  id
  first_name
  last_name
  full_name
  entity_name
  public_name
  username
  contact_email
  phone_number
}
    `;
export const UserContactFragmentDoc = gql`
    fragment UserContact on WUI_User {
  contact_email
  phone_number
}
    `;
export const AcceptTermsAndConditionsDocument = gql`
    mutation AcceptTermsAndConditions($id: bigint!) {
  update_WUI_User(_set: {accepted_terms: true}, where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;

export function useAcceptTermsAndConditionsMutation() {
  return Urql.useMutation<AcceptTermsAndConditionsMutation, AcceptTermsAndConditionsMutationVariables>(AcceptTermsAndConditionsDocument);
};
export const AddCreditCardDocument = gql`
    mutation AddCreditCard($paymentMethodId: String!) {
  create_WUI_PaymentMethodCard(paymentMethodId: $paymentMethodId) {
    id
    external_id
    last4
    brand
    funding
    country_code
    exp_year
    exp_month
    fingerprint
    allow_3d_secure
  }
}
    `;

export function useAddCreditCardMutation() {
  return Urql.useMutation<AddCreditCardMutation, AddCreditCardMutationVariables>(AddCreditCardDocument);
};
export const AddEmailDomainDocument = gql`
    mutation AddEmailDomain($objects: [EmailDomainLookup_insert_input!]!) {
  insert_EmailDomainLookup(objects: $objects) {
    affected_rows
  }
}
    `;

export function useAddEmailDomainMutation() {
  return Urql.useMutation<AddEmailDomainMutation, AddEmailDomainMutationVariables>(AddEmailDomainDocument);
};
export const AdminCreateSellerPackageItemsDocument = gql`
    mutation AdminCreateSellerPackageItems($sellerPackageId: bigint!, $objects: [WUI_SellerPackageItem_insert_input!]!) {
  delete_WUI_SellerPackageItem(
    where: {seller_package_id: {_eq: $sellerPackageId}}
  ) {
    affected_rows
  }
  insert_WUI_SellerPackageItem(objects: $objects) {
    affected_rows
  }
}
    `;

export function useAdminCreateSellerPackageItemsMutation() {
  return Urql.useMutation<AdminCreateSellerPackageItemsMutation, AdminCreateSellerPackageItemsMutationVariables>(AdminCreateSellerPackageItemsDocument);
};
export const AdminCreateUserDocument = gql`
    mutation AdminCreateUser($object: TempLoginWithPassword_insert_input!) {
  insert_TempLoginWithPassword_one(object: $object) {
    id
    user_id
  }
}
    `;

export function useAdminCreateUserMutation() {
  return Urql.useMutation<AdminCreateUserMutation, AdminCreateUserMutationVariables>(AdminCreateUserDocument);
};
export const AdminCreateUserWithPwdDocument = gql`
    mutation AdminCreateUserWithPwd($object: WUI_User_insert_input!) {
  insert_WUI_User_one(object: $object) {
    id
  }
}
    `;

export function useAdminCreateUserWithPwdMutation() {
  return Urql.useMutation<AdminCreateUserWithPwdMutation, AdminCreateUserWithPwdMutationVariables>(AdminCreateUserWithPwdDocument);
};
export const AdminDeleteAmbassadorDocument = gql`
    mutation AdminDeleteAmbassador($id: uuid!) {
  delete_Ambassador_by_pk(id: $id) {
    id
  }
}
    `;

export function useAdminDeleteAmbassadorMutation() {
  return Urql.useMutation<AdminDeleteAmbassadorMutation, AdminDeleteAmbassadorMutationVariables>(AdminDeleteAmbassadorDocument);
};
export const AdminDeleteUserSubsDocument = gql`
    mutation AdminDeleteUserSubs($userId: bigint!) {
  delete_WUI_UserSubscription(where: {user_id: {_eq: $userId}}) {
    affected_rows
  }
}
    `;

export function useAdminDeleteUserSubsMutation() {
  return Urql.useMutation<AdminDeleteUserSubsMutation, AdminDeleteUserSubsMutationVariables>(AdminDeleteUserSubsDocument);
};
export const AdminInsertAmbassadorDocument = gql`
    mutation AdminInsertAmbassador($object: Ambassador_insert_input!) {
  insert_Ambassador_one(object: $object) {
    id
  }
}
    `;

export function useAdminInsertAmbassadorMutation() {
  return Urql.useMutation<AdminInsertAmbassadorMutation, AdminInsertAmbassadorMutationVariables>(AdminInsertAmbassadorDocument);
};
export const AdminInsertOrUpdatePackageDocument = gql`
    mutation AdminInsertOrUpdatePackage($object: WUI_SellerPackage_insert_input!) {
  insert_WUI_SellerPackage_one(
    object: $object
    on_conflict: {constraint: WUI_SellerPackage_user_id_package_type_key, update_columns: [description, enabled, hide_price, metadata, package_type, price, title, user_id]}
  ) {
    id
    description
    enabled
    hide_price
    metadata
    package_type
    price
    title
    user_id
  }
}
    `;

export function useAdminInsertOrUpdatePackageMutation() {
  return Urql.useMutation<AdminInsertOrUpdatePackageMutation, AdminInsertOrUpdatePackageMutationVariables>(AdminInsertOrUpdatePackageDocument);
};
export const AdminSendMessagesToUsersDocument = gql`
    mutation AdminSendMessagesToUsers($objects: [WUI_ChatRoomMessage_insert_input!]!) {
  insert_WUI_ChatRoomMessage(objects: $objects) {
    affected_rows
    returning {
      id
      room_id
    }
  }
}
    `;

export function useAdminSendMessagesToUsersMutation() {
  return Urql.useMutation<AdminSendMessagesToUsersMutation, AdminSendMessagesToUsersMutationVariables>(AdminSendMessagesToUsersDocument);
};
export const AdminUpdateAmbassadorByIdDocument = gql`
    mutation AdminUpdateAmbassadorByID($id: uuid!, $set: Ambassador_set_input!) {
  update_Ambassador_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
}
    `;

export function useAdminUpdateAmbassadorByIdMutation() {
  return Urql.useMutation<AdminUpdateAmbassadorByIdMutation, AdminUpdateAmbassadorByIdMutationVariables>(AdminUpdateAmbassadorByIdDocument);
};
export const AdminUpdateUserByIdDocument = gql`
    mutation AdminUpdateUserByID($id: bigint!, $set: WUI_User_set_input!) {
  update_WUI_User_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
}
    `;

export function useAdminUpdateUserByIdMutation() {
  return Urql.useMutation<AdminUpdateUserByIdMutation, AdminUpdateUserByIdMutationVariables>(AdminUpdateUserByIdDocument);
};
export const CreateBankAccountDocument = gql`
    mutation CreateBankAccount($token: String!) {
  createBankAccount(token: $token) {
    id
    object
    account_holder_name
    account_holder_type
    bank_name
    country
    currency
    fingerprint
    last4
    routing_number
    status
    account
  }
}
    `;

export function useCreateBankAccountMutation() {
  return Urql.useMutation<CreateBankAccountMutation, CreateBankAccountMutationVariables>(CreateBankAccountDocument);
};
export const CreateConversationDocument = gql`
    mutation CreateConversation($data: [WUI_ChatRoomUser_insert_input!]!) {
  insert_WUI_ChatRoom_one(
    object: {WUI_ChatRoomUsers: {data: $data}, type: conversation}
  ) {
    id
  }
}
    `;

export function useCreateConversationMutation() {
  return Urql.useMutation<CreateConversationMutation, CreateConversationMutationVariables>(CreateConversationDocument);
};
export const CreateOrderDetailsDocument = gql`
    mutation CreateOrderDetails($roomID: bigint!, $data: jsonb!) {
  create_WUI_OrderDetails(roomID: $roomID, data: $data) {
    id
  }
}
    `;

export function useCreateOrderDetailsMutation() {
  return Urql.useMutation<CreateOrderDetailsMutation, CreateOrderDetailsMutationVariables>(CreateOrderDetailsDocument);
};
export const CreateOrderDocument = gql`
    mutation CreateOrder($packageId: bigint!, $packageFees: [String]!, $subscriptionPeriod: String, $paymentMethodId: bigint!) {
  create_WUI_Order(
    packageId: $packageId
    packageFees: $packageFees
    subscriptionPeriod: $subscriptionPeriod
    payment_method_id: $paymentMethodId
  ) {
    id
    buyer_id
    seller_id
    created_by
    package_type
    title
    description
    price
    status
    items
    subscription_id
  }
}
    `;

export function useCreateOrderMutation() {
  return Urql.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument);
};
export const CreatePackageDocument = gql`
    mutation CreatePackage($object: WUI_SellerPackage_insert_input!) {
  insert_WUI_SellerPackage_one(object: $object) {
    description
    enabled
    id
    package_type
    price
    title
    metadata
    hide_price
    WUI_SellerPackageItems {
      id
      title
      comment
      quantity
    }
  }
}
    `;

export function useCreatePackageMutation() {
  return Urql.useMutation<CreatePackageMutation, CreatePackageMutationVariables>(CreatePackageDocument);
};
export const CreateProjectDocument = gql`
    mutation CreateProject($object: WUI_SellerProject_insert_input!) {
  insert_WUI_SellerProject_one(object: $object) {
    id
  }
}
    `;

export function useCreateProjectMutation() {
  return Urql.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument);
};
export const CreateRequestPackagePriceDocument = gql`
    mutation CreateRequestPackagePrice($object: WUI_PackageRequest_insert_input!) {
  insert_WUI_PackageRequest_one(object: $object) {
    id
    status
    tries
    package_id
  }
}
    `;

export function useCreateRequestPackagePriceMutation() {
  return Urql.useMutation<CreateRequestPackagePriceMutation, CreateRequestPackagePriceMutationVariables>(CreateRequestPackagePriceDocument);
};
export const CreateReviewOrderDocument = gql`
    mutation CreateReviewOrder($orderId: bigint!, $data: WUI_UserReview_insert_input!) {
  insert_WUI_OrderReview(
    objects: {order_id: $orderId, WUI_UserReview: {data: $data}}
  ) {
    affected_rows
  }
}
    `;

export function useCreateReviewOrderMutation() {
  return Urql.useMutation<CreateReviewOrderMutation, CreateReviewOrderMutationVariables>(CreateReviewOrderDocument);
};
export const CreateSessionEventDocument = gql`
    mutation CreateSessionEvent($object: WUI_SessionEvent_insert_input!) {
  insert_WUI_SessionEvent_one(object: $object) {
    id
  }
}
    `;

export function useCreateSessionEventMutation() {
  return Urql.useMutation<CreateSessionEventMutation, CreateSessionEventMutationVariables>(CreateSessionEventDocument);
};
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($object: WUI_UserSubscription_insert_input!) {
  insert_WUI_UserSubscription_one(object: $object) {
    id
    expires_at
    status
    external_id
    paid
  }
}
    `;

export function useCreateSubscriptionMutation() {
  return Urql.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument);
};
export const DeleteBankAccountDocument = gql`
    mutation DeleteBankAccount($id: String!) {
  removeBankAccount(id: $id) {
    id
  }
}
    `;

export function useDeleteBankAccountMutation() {
  return Urql.useMutation<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>(DeleteBankAccountDocument);
};
export const DeleteCreditCardDocument = gql`
    mutation DeleteCreditCard($id: bigint!) {
  remove_WUI_PaymentMethodCard(id: $id) {
    id
  }
}
    `;

export function useDeleteCreditCardMutation() {
  return Urql.useMutation<DeleteCreditCardMutation, DeleteCreditCardMutationVariables>(DeleteCreditCardDocument);
};
export const DeleteLinkDocument = gql`
    mutation DeleteLink($whereDeleteEvent: WUI_SessionEvent_bool_exp!, $whereDeleteLink: SocialMediaLink_bool_exp!) {
  delete_WUI_SessionEvent(where: $whereDeleteEvent) {
    affected_rows
  }
  delete_SocialMediaLink(where: $whereDeleteLink) {
    returning {
      id
    }
  }
}
    `;

export function useDeleteLinkMutation() {
  return Urql.useMutation<DeleteLinkMutation, DeleteLinkMutationVariables>(DeleteLinkDocument);
};
export const DeleteLinkEventsDocument = gql`
    mutation DeleteLinkEvents($whereDeleteEvent: WUI_SessionEvent_bool_exp!) {
  delete_WUI_SessionEvent(where: $whereDeleteEvent) {
    affected_rows
  }
}
    `;

export function useDeleteLinkEventsMutation() {
  return Urql.useMutation<DeleteLinkEventsMutation, DeleteLinkEventsMutationVariables>(DeleteLinkEventsDocument);
};
export const DeletePorfolioPicturesDocument = gql`
    mutation DeletePorfolioPictures($whereDelete: WUI_UserFilePortfolio_bool_exp!) {
  delete_WUI_UserFilePortfolio(where: $whereDelete) {
    returning {
      id
      WUI_File {
        id
        file_type
        url
      }
    }
  }
}
    `;

export function useDeletePorfolioPicturesMutation() {
  return Urql.useMutation<DeletePorfolioPicturesMutation, DeletePorfolioPicturesMutationVariables>(DeletePorfolioPicturesDocument);
};
export const DeleteProjectDocument = gql`
    mutation DeleteProject($id: bigint!) {
  delete_WUI_SellerProject_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteProjectMutation() {
  return Urql.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument);
};
export const DeleteUserPortfolioPicturesDocument = gql`
    mutation DeleteUserPortfolioPictures($whereDelete: WUI_UserFilePortfolio_bool_exp!) {
  delete_WUI_UserFilePortfolio(where: $whereDelete) {
    affected_rows
  }
}
    `;

export function useDeleteUserPortfolioPicturesMutation() {
  return Urql.useMutation<DeleteUserPortfolioPicturesMutation, DeleteUserPortfolioPicturesMutationVariables>(DeleteUserPortfolioPicturesDocument);
};
export const IncrementEmailDomainLookupSeenDocument = gql`
    mutation IncrementEmailDomainLookupSeen($domain: String!) {
  update_EmailDomainLookup_by_pk(pk_columns: {domain: $domain}, _inc: {seen: 1}) {
    seen
  }
}
    `;

export function useIncrementEmailDomainLookupSeenMutation() {
  return Urql.useMutation<IncrementEmailDomainLookupSeenMutation, IncrementEmailDomainLookupSeenMutationVariables>(IncrementEmailDomainLookupSeenDocument);
};
export const InsertLinksDocument = gql`
    mutation InsertLinks($Objects: [SocialMediaLink_insert_input!]!) {
  insert_SocialMediaLink(objects: $Objects) {
    affected_rows
    returning {
      id
      title
      url
      primary
    }
  }
}
    `;

export function useInsertLinksMutation() {
  return Urql.useMutation<InsertLinksMutation, InsertLinksMutationVariables>(InsertLinksDocument);
};
export const InsertMarketplaceWaitlistDocument = gql`
    mutation InsertMarketplaceWaitlist {
  insert_MarketplaceWaitlist_one(object: {}) {
    id
  }
}
    `;

export function useInsertMarketplaceWaitlistMutation() {
  return Urql.useMutation<InsertMarketplaceWaitlistMutation, InsertMarketplaceWaitlistMutationVariables>(InsertMarketplaceWaitlistDocument);
};
export const InsertOrUpdateUserPortfolioPicturesDocument = gql`
    mutation InsertOrUpdateUserPortfolioPictures($insertOrUpdatePictures: [WUI_UserFilePortfolio_insert_input!]!) {
  insert_WUI_UserFilePortfolio(
    objects: $insertOrUpdatePictures
    on_conflict: {constraint: WUI_UserImagePortfolio_pkey, update_columns: order}
  ) {
    returning {
      id
      WUI_File {
        id
        file_type
        url
      }
    }
  }
}
    `;

export function useInsertOrUpdateUserPortfolioPicturesMutation() {
  return Urql.useMutation<InsertOrUpdateUserPortfolioPicturesMutation, InsertOrUpdateUserPortfolioPicturesMutationVariables>(InsertOrUpdateUserPortfolioPicturesDocument);
};
export const LegalInformationCompanyDocument = gql`
    mutation LegalInformationCompany($taxID: String!, $name: String!, $address: CompanyAddress!) {
  createCompanyAccount(taxID: $taxID, name: $name, address: $address) {
    success
  }
}
    `;

export function useLegalInformationCompanyMutation() {
  return Urql.useMutation<LegalInformationCompanyMutation, LegalInformationCompanyMutationVariables>(LegalInformationCompanyDocument);
};
export const LegalInformationIndividualDocument = gql`
    mutation LegalInformationIndividual($firstName: String!, $lastName: String!, $last4ssn: String!, $address: IndividualAddress!, $birthday: String!) {
  createIndividualAccount(
    firstName: $firstName
    lastName: $lastName
    last4ssn: $last4ssn
    address: $address
    birthday: $birthday
  ) {
    success
  }
}
    `;

export function useLegalInformationIndividualMutation() {
  return Urql.useMutation<LegalInformationIndividualMutation, LegalInformationIndividualMutationVariables>(LegalInformationIndividualDocument);
};
export const MakeBankAccountPrimaryDocument = gql`
    mutation MakeBankAccountPrimary($id: String!) {
  defaultBankAccount(id: $id) {
    id
  }
}
    `;

export function useMakeBankAccountPrimaryMutation() {
  return Urql.useMutation<MakeBankAccountPrimaryMutation, MakeBankAccountPrimaryMutationVariables>(MakeBankAccountPrimaryDocument);
};
export const MarkChatAsDocument = gql`
    mutation MarkChatAs($whereUser: bigint_comparison_exp, $roomID: bigint!, $seen: Boolean) {
  update_WUI_ChatRoomUser(
    where: {user_id: $whereUser, room_id: {_eq: $roomID}}
    _set: {seen: $seen}
  ) {
    affected_rows
  }
}
    `;

export function useMarkChatAsMutation() {
  return Urql.useMutation<MarkChatAsMutation, MarkChatAsMutationVariables>(MarkChatAsDocument);
};
export const MarkNotificationAsSeenDocument = gql`
    mutation MarkNotificationAsSeen($id: bigint!, $seen: timestamptz) {
  update_WUI_Notification_by_pk(pk_columns: {id: $id}, _set: {seen: $seen}) {
    seen
  }
}
    `;

export function useMarkNotificationAsSeenMutation() {
  return Urql.useMutation<MarkNotificationAsSeenMutation, MarkNotificationAsSeenMutationVariables>(MarkNotificationAsSeenDocument);
};
export const PaymentIntentDocument = gql`
    mutation PaymentIntent($orderId: bigint!) {
  create_WUI_PaymentIntent(orderId: $orderId) {
    intentID
    clientSecret
  }
}
    `;

export function usePaymentIntentMutation() {
  return Urql.useMutation<PaymentIntentMutation, PaymentIntentMutationVariables>(PaymentIntentDocument);
};
export const SendMessageDocument = gql`
    mutation SendMessage($message: String, $roomID: bigint!, $userID: bigint!, $data: jsonb) {
  insert_WUI_ChatRoomMessage(
    objects: {message: $message, room_id: $roomID, user_id: $userID, data: $data}
  ) {
    returning {
      id
      created_at
      message
      data
    }
  }
}
    `;

export function useSendMessageMutation() {
  return Urql.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument);
};
export const ToggleAllPackagesStatusDocument = gql`
    mutation ToggleAllPackagesStatus($status: Boolean) {
  update_WUI_SellerPackage(where: {}, _set: {enabled: $status}) {
    returning {
      id
      enabled
    }
  }
}
    `;

export function useToggleAllPackagesStatusMutation() {
  return Urql.useMutation<ToggleAllPackagesStatusMutation, ToggleAllPackagesStatusMutationVariables>(ToggleAllPackagesStatusDocument);
};
export const ToggleAllProjectsStatusDocument = gql`
    mutation ToggleAllProjectsStatus($status: Boolean!, $userID: bigint!) {
  update_WUI_SellerProject(
    where: {user_id: {_eq: $userID}}
    _set: {enabled: $status}
  ) {
    affected_rows
  }
}
    `;

export function useToggleAllProjectsStatusMutation() {
  return Urql.useMutation<ToggleAllProjectsStatusMutation, ToggleAllProjectsStatusMutationVariables>(ToggleAllProjectsStatusDocument);
};
export const TogglePackageStatusDocument = gql`
    mutation TogglePackageStatus($id: bigint!, $status: Boolean) {
  update_WUI_SellerPackage_by_pk(pk_columns: {id: $id}, _set: {enabled: $status}) {
    id
    enabled
  }
}
    `;

export function useTogglePackageStatusMutation() {
  return Urql.useMutation<TogglePackageStatusMutation, TogglePackageStatusMutationVariables>(TogglePackageStatusDocument);
};
export const ToggleProjectStatusDocument = gql`
    mutation ToggleProjectStatus($id: bigint!, $status: Boolean) {
  update_WUI_SellerProject_by_pk(pk_columns: {id: $id}, _set: {enabled: $status}) {
    id
  }
}
    `;

export function useToggleProjectStatusMutation() {
  return Urql.useMutation<ToggleProjectStatusMutation, ToggleProjectStatusMutationVariables>(ToggleProjectStatusDocument);
};
export const UpdateLastSeenDocument = gql`
    mutation UpdateLastSeen($id: bigint!, $lastSeen: timestamptz) {
  update_wui_userlastseen(
    where: {id: {_eq: $id}}
    _set: {last_seen_date: $lastSeen}
  ) {
    affected_rows
  }
}
    `;

export function useUpdateLastSeenMutation() {
  return Urql.useMutation<UpdateLastSeenMutation, UpdateLastSeenMutationVariables>(UpdateLastSeenDocument);
};
export const UpdateOrderStatusDocument = gql`
    mutation UpdateOrderStatus($orderId: bigint!, $status: String!) {
  updateStatus_WUI_Order(orderId: $orderId, status: $status) {
    success
  }
}
    `;

export function useUpdateOrderStatusMutation() {
  return Urql.useMutation<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>(UpdateOrderStatusDocument);
};
export const UpdatePackageDocument = gql`
    mutation UpdatePackage($id: bigint!, $set: WUI_SellerPackage_set_input!) {
  update_WUI_SellerPackage_by_pk(pk_columns: {id: $id}, _set: $set) {
    description
    enabled
    id
    package_type
    price
    title
    metadata
    hide_price
    WUI_SellerPackageItems {
      id
      title
      comment
      quantity
    }
  }
}
    `;

export function useUpdatePackageMutation() {
  return Urql.useMutation<UpdatePackageMutation, UpdatePackageMutationVariables>(UpdatePackageDocument);
};
export const UpdatePackageItemsDocument = gql`
    mutation UpdatePackageItems($packageID: bigint!, $items: [WUI_SellerPackageItem_insert_input!]!) {
  delete_WUI_SellerPackageItem(where: {seller_package_id: {_eq: $packageID}}) {
    affected_rows
  }
  insert_WUI_SellerPackageItem(objects: $items) {
    affected_rows
    returning {
      id
      title
      quantity
      comment
      WUI_SellerPackage {
        id
      }
    }
  }
}
    `;

export function useUpdatePackageItemsMutation() {
  return Urql.useMutation<UpdatePackageItemsMutation, UpdatePackageItemsMutationVariables>(UpdatePackageItemsDocument);
};
export const UpdatePackageRequestStatusDocument = gql`
    mutation UpdatePackageRequestStatus($status: WUI_RequestStatus_enum!, $id: bigint!) {
  update_WUI_PackageRequest_by_pk(pk_columns: {id: $id}, _set: {status: $status}) {
    id
    status
    tries
  }
}
    `;

export function useUpdatePackageRequestStatusMutation() {
  return Urql.useMutation<UpdatePackageRequestStatusMutation, UpdatePackageRequestStatusMutationVariables>(UpdatePackageRequestStatusDocument);
};
export const UpdateProfileSettingsDocument = gql`
    mutation UpdateProfileSettings($userID: bigint!, $setUser: WUI_User_set_input!) {
  update_WUI_User_by_pk(pk_columns: {id: $userID}, _set: $setUser) {
    ...UserDetails
    ...UserContact
    about_me
    is_seller
    theme_color
    video_url
    allow_contact_without_order
    custom_color
    language
  }
}
    ${UserDetailsFragmentDoc}
${UserContactFragmentDoc}`;

export function useUpdateProfileSettingsMutation() {
  return Urql.useMutation<UpdateProfileSettingsMutation, UpdateProfileSettingsMutationVariables>(UpdateProfileSettingsDocument);
};
export const UpdateProfileVideosDocument = gql`
    mutation UpdateProfileVideos($videoObjects: [UserVideo_insert_input!]!) {
  delete_UserVideo(where: {}) {
    affected_rows
  }
  insert_UserVideo(objects: $videoObjects) {
    affected_rows
  }
}
    `;

export function useUpdateProfileVideosMutation() {
  return Urql.useMutation<UpdateProfileVideosMutation, UpdateProfileVideosMutationVariables>(UpdateProfileVideosDocument);
};
export const UpdateProjectDocument = gql`
    mutation UpdateProject($projectID: bigint!, $set: WUI_SellerProject_set_input!) {
  update_WUI_SellerProject_by_pk(pk_columns: {id: $projectID}, _set: $set) {
    id
  }
}
    `;

export function useUpdateProjectMutation() {
  return Urql.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument);
};
export const UpdateProjectDemographicsDocument = gql`
    mutation UpdateProjectDemographics($projectID: bigint!, $objects: [ProjectDemographic_insert_input!]!) {
  delete_ProjectDemographic(where: {project_id: {_eq: $projectID}}) {
    affected_rows
  }
  insert_ProjectDemographic(objects: $objects) {
    affected_rows
  }
}
    `;

export function useUpdateProjectDemographicsMutation() {
  return Urql.useMutation<UpdateProjectDemographicsMutation, UpdateProjectDemographicsMutationVariables>(UpdateProjectDemographicsDocument);
};
export const UpdateProjectItemsDocument = gql`
    mutation UpdateProjectItems($projectID: bigint!, $items: [WUI_SellerProjectItem_insert_input!]!) {
  delete_WUI_SellerProjectItem(where: {seller_project_id: {_eq: $projectID}}) {
    affected_rows
  }
  insert_WUI_SellerProjectItem(objects: $items) {
    affected_rows
  }
}
    `;

export function useUpdateProjectItemsMutation() {
  return Urql.useMutation<UpdateProjectItemsMutation, UpdateProjectItemsMutationVariables>(UpdateProjectItemsDocument);
};
export const UpdateReviewDocument = gql`
    mutation UpdateReview($id: bigint!, $stars: Int!, $comment: String!, $createdAt: timestamptz) {
  update_WUI_UserReview(
    where: {id: {_eq: $id}}
    _set: {comment: $comment, stars: $stars, created_at: $createdAt}
  ) {
    affected_rows
  }
}
    `;

export function useUpdateReviewMutation() {
  return Urql.useMutation<UpdateReviewMutation, UpdateReviewMutationVariables>(UpdateReviewDocument);
};
export const UpdateSessionDocument = gql`
    mutation UpdateSession($sessionId: String!) {
  update_WUI_Session(where: {session_id: {_eq: $sessionId}}) {
    affected_rows
  }
}
    `;

export function useUpdateSessionMutation() {
  return Urql.useMutation<UpdateSessionMutation, UpdateSessionMutationVariables>(UpdateSessionDocument);
};
export const UpdateSocialMediaDataDocument = gql`
    mutation UpdateSocialMediaData($userID: bigint!, $objects: [SocialMediaDemographic_insert_input!]!) {
  delete_SocialMediaDemographic(where: {user_id: {_eq: $userID}}) {
    affected_rows
  }
  insert_SocialMediaDemographic(objects: $objects) {
    affected_rows
    returning {
      demographic
      id
      source
      user_id
      value
    }
  }
}
    `;

export function useUpdateSocialMediaDataMutation() {
  return Urql.useMutation<UpdateSocialMediaDataMutation, UpdateSocialMediaDataMutationVariables>(UpdateSocialMediaDataDocument);
};
export const UpdateSocialMediaLinksDocument = gql`
    mutation UpdateSocialMediaLinks($linkID: bigint!, $set: SocialMediaLink_set_input) {
  update_SocialMediaLink(where: {id: {_eq: $linkID}}, _set: $set) {
    affected_rows
    returning {
      id
      title
      url
      primary
    }
  }
}
    `;

export function useUpdateSocialMediaLinksMutation() {
  return Urql.useMutation<UpdateSocialMediaLinksMutation, UpdateSocialMediaLinksMutationVariables>(UpdateSocialMediaLinksDocument);
};
export const UpdateUserSubscriptionDocument = gql`
    mutation UpdateUserSubscription($externalId: String!, $set: WUI_UserSubscription_set_input!) {
  update_WUI_UserSubscription_by_pk(
    pk_columns: {external_id: $externalId}
    _set: $set
  ) {
    id
    expires_at
    status
    external_id
    cancel_at
  }
}
    `;

export function useUpdateUserSubscriptionMutation() {
  return Urql.useMutation<UpdateUserSubscriptionMutation, UpdateUserSubscriptionMutationVariables>(UpdateUserSubscriptionDocument);
};
export const UploadChatPictureDocument = gql`
    mutation UploadChatPicture($imageType: String!, $imageBase64: String!) {
  uploadPicture_WUI_ChatRoomMessage(
    imageType: $imageType
    imageBase64: $imageBase64
  ) {
    fileId
    imageUrl
    fileType
  }
}
    `;

export function useUploadChatPictureMutation() {
  return Urql.useMutation<UploadChatPictureMutation, UploadChatPictureMutationVariables>(UploadChatPictureDocument);
};
export const UploadCoverPictureDocument = gql`
    mutation UploadCoverPicture($imageType: String!, $imageBase64: String!) {
  uploadCoverPicture_WUI_user(imageType: $imageType, imageBase64: $imageBase64) {
    imageUrl
  }
}
    `;

export function useUploadCoverPictureMutation() {
  return Urql.useMutation<UploadCoverPictureMutation, UploadCoverPictureMutationVariables>(UploadCoverPictureDocument);
};
export const UploadMessageImageDocument = gql`
    mutation UploadMessageImage($imageBase64: String!, $imageType: String!) {
  uploadPicture_WUI_ChatRoomMessage(
    imageBase64: $imageBase64
    imageType: $imageType
  ) {
    imageUrl
    fileType
    fileId
  }
}
    `;

export function useUploadMessageImageMutation() {
  return Urql.useMutation<UploadMessageImageMutation, UploadMessageImageMutationVariables>(UploadMessageImageDocument);
};
export const UploadPorfolioPictureDocument = gql`
    mutation UploadPorfolioPicture($imageType: String!, $imageBase64: String!) {
  uploadPicture_WUI_UseFilePortfolio(
    imageType: $imageType
    imageBase64: $imageBase64
  ) {
    portfolioId
    fileId
    imageUrl
  }
}
    `;

export function useUploadPorfolioPictureMutation() {
  return Urql.useMutation<UploadPorfolioPictureMutation, UploadPorfolioPictureMutationVariables>(UploadPorfolioPictureDocument);
};
export const UploadProfilePictureDocument = gql`
    mutation UploadProfilePicture($imageType: String!, $imageBase64: String!) {
  uploadProfilePicture_WUI_User(imageType: $imageType, imageBase64: $imageBase64) {
    imageUrl
  }
}
    `;

export function useUploadProfilePictureMutation() {
  return Urql.useMutation<UploadProfilePictureMutation, UploadProfilePictureMutationVariables>(UploadProfilePictureDocument);
};
export const UploadProjectPictureDocument = gql`
    mutation UploadProjectPicture($projectID: bigint!, $imageType: String!, $imageBase64: String!) {
  delete_WUI_SellerProjectFile(where: {project_id: {_eq: $projectID}}) {
    affected_rows
  }
  uploadPicture_WUI_ProjectFile(
    projectId: $projectID
    imageType: $imageType
    imageBase64: $imageBase64
  ) {
    projectId
    fileId
    imageUrl
  }
}
    `;

export function useUploadProjectPictureMutation() {
  return Urql.useMutation<UploadProjectPictureMutation, UploadProjectPictureMutationVariables>(UploadProjectPictureDocument);
};
export const LoginDocument = gql`
    mutation Login($externalID: String!, $token: String!) {
  login(externalID: $externalID, token: $token) {
    id
    email
    firstName
    lastName
    username
    phoneNumber
    isSeller
    picture
    theme_color
    hasCompletedLegalInformation
    accepted_terms
  }
}
    `;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
};
export const SignupDocument = gql`
    mutation Signup($username: String!, $phoneNumber: String, $entityName: String, $firstName: String!, $lastName: String!, $email: String!, $picture: String, $country: String!, $industry: String, $sourceUserId: Int, $demographics: jsonb, $suggestedPackages: jsonb, $links: jsonb, $language: String, $type: String!) {
  signup(
    username: $username
    phoneNumber: $phoneNumber
    entityName: $entityName
    firstName: $firstName
    lastName: $lastName
    email: $email
    picture: $picture
    country: $country
    industry: $industry
    sourceUserId: $sourceUserId
    demographics: $demographics
    suggestedPackages: $suggestedPackages
    links: $links
    language: $language
    type: $type
  ) {
    id
    email
    entityName
    firstName
    lastName
    phoneNumber
    username
    isSeller
    picture
    hasCompletedLegalInformation
    accepted_terms
    type
  }
}
    `;

export function useSignupMutation() {
  return Urql.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument);
};
export const AdminCountUsersDocument = gql`
    query AdminCountUsers($where: WUI_User_bool_exp!) {
  WUI_User_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

export function useAdminCountUsersQuery(options: Omit<Urql.UseQueryArgs<AdminCountUsersQueryVariables>, 'query'>) {
  return Urql.useQuery<AdminCountUsersQuery, AdminCountUsersQueryVariables>({ query: AdminCountUsersDocument, ...options });
};
export const AdminGetUsersDocument = gql`
    query AdminGetUsers($limit: Int!, $offset: Int!, $where: WUI_User_bool_exp!) {
  WUI_User(
    limit: $limit
    offset: $offset
    where: $where
    order_by: {created_at: asc}
  ) {
    ...UserDetails
    ...UserContact
    is_seller
    ambassador_id
    country
    source
    completed_profile
    WUI_File_Profile_Picture {
      url
    }
    WUI_SessionEvents_aggregate(where: {event_type: {_eq: page_view}}) {
      aggregate {
        count
      }
    }
    WUI_Notification_aggregate(where: {seen: {_is_null: true}}) {
      nodes {
        id
      }
      aggregate {
        count
      }
    }
  }
}
    ${UserDetailsFragmentDoc}
${UserContactFragmentDoc}`;

export function useAdminGetUsersQuery(options: Omit<Urql.UseQueryArgs<AdminGetUsersQueryVariables>, 'query'>) {
  return Urql.useQuery<AdminGetUsersQuery, AdminGetUsersQueryVariables>({ query: AdminGetUsersDocument, ...options });
};
export const AdminUserByIdDocument = gql`
    query AdminUserByID($userID: bigint!) {
  WUI_User_by_pk(id: $userID) {
    ambassador_id
    Ambassador {
      id
      custom_rate
    }
    country
    first_name
    industry
    is_seller
    last_name
    phone_number
    username
  }
}
    `;

export function useAdminUserByIdQuery(options: Omit<Urql.UseQueryArgs<AdminUserByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<AdminUserByIdQuery, AdminUserByIdQueryVariables>({ query: AdminUserByIdDocument, ...options });
};
export const AdminUserSubsByUserIdDocument = gql`
    query AdminUserSubsByUserId($userId: bigint!, $where: WUI_UserSubscription_bool_exp!) {
  WUI_User_by_pk(id: $userId) {
    WUI_UserSubscriptions(where: $where) {
      id
      external_id
      status
    }
  }
}
    `;

export function useAdminUserSubsByUserIdQuery(options: Omit<Urql.UseQueryArgs<AdminUserSubsByUserIdQueryVariables>, 'query'>) {
  return Urql.useQuery<AdminUserSubsByUserIdQuery, AdminUserSubsByUserIdQueryVariables>({ query: AdminUserSubsByUserIdDocument, ...options });
};
export const AffiliateInfoDocument = gql`
    query AffiliateInfo($userID: bigint!) {
  WUI_Affiliate(where: {user_id: {_eq: $userID}}) {
    active
    id
    level_a
    level_aa
  }
}
    `;

export function useAffiliateInfoQuery(options: Omit<Urql.UseQueryArgs<AffiliateInfoQueryVariables>, 'query'>) {
  return Urql.useQuery<AffiliateInfoQuery, AffiliateInfoQueryVariables>({ query: AffiliateInfoDocument, ...options });
};
export const AffiliateTransactionsDocument = gql`
    query AffiliateTransactions($limit: Int!, $offset: Int!) {
  WUI_AffiliateTransaction_aggregate {
    aggregate {
      count
    }
  }
  WUI_AffiliateTransaction(
    limit: $limit
    offset: $offset
    order_by: {created_at: desc}
  ) {
    id
    created_at
    gross_amount
    amount
    level
    rate
    status
    order_id
    Seller {
      ...UserDetails
      WUI_File_Profile_Picture {
        id
        url
      }
    }
  }
}
    ${UserDetailsFragmentDoc}`;

export function useAffiliateTransactionsQuery(options: Omit<Urql.UseQueryArgs<AffiliateTransactionsQueryVariables>, 'query'>) {
  return Urql.useQuery<AffiliateTransactionsQuery, AffiliateTransactionsQueryVariables>({ query: AffiliateTransactionsDocument, ...options });
};
export const AffiliateUsersDocument = gql`
    query AffiliateUsers($limit: Int!, $offset: Int!, $where: WUI_AffiliateUsers_bool_exp) {
  WUI_AffiliateUsers(limit: $limit, offset: $offset, where: $where) {
    id
    WUI_User {
      ...UserDetails
      WUI_File_Profile_Picture {
        id
        url
      }
    }
  }
}
    ${UserDetailsFragmentDoc}`;

export function useAffiliateUsersQuery(options: Omit<Urql.UseQueryArgs<AffiliateUsersQueryVariables>, 'query'>) {
  return Urql.useQuery<AffiliateUsersQuery, AffiliateUsersQueryVariables>({ query: AffiliateUsersDocument, ...options });
};
export const AllVoteUserCategoryDocument = gql`
    query AllVoteUserCategory {
  VoteUserCategory {
    id
  }
}
    `;

export function useAllVoteUserCategoryQuery(options?: Omit<Urql.UseQueryArgs<AllVoteUserCategoryQueryVariables>, 'query'>) {
  return Urql.useQuery<AllVoteUserCategoryQuery, AllVoteUserCategoryQueryVariables>({ query: AllVoteUserCategoryDocument, ...options });
};
export const BasicUsersDataByEmailDocument = gql`
    query BasicUsersDataByEmail($email: String!) {
  WUI_User(where: {contact_email: {_eq: $email}}) {
    id
    first_name
    last_name
  }
}
    `;

export function useBasicUsersDataByEmailQuery(options: Omit<Urql.UseQueryArgs<BasicUsersDataByEmailQueryVariables>, 'query'>) {
  return Urql.useQuery<BasicUsersDataByEmailQuery, BasicUsersDataByEmailQueryVariables>({ query: BasicUsersDataByEmailDocument, ...options });
};
export const BasicUsersDataByIdDocument = gql`
    query BasicUsersDataByID($id: bigint!) {
  WUI_User(where: {id: {_eq: $id}}) {
    id
    full_name
    first_name
    last_name
    username
    WUI_File_Profile_Picture {
      id
      url
    }
  }
}
    `;

export function useBasicUsersDataByIdQuery(options: Omit<Urql.UseQueryArgs<BasicUsersDataByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<BasicUsersDataByIdQuery, BasicUsersDataByIdQueryVariables>({ query: BasicUsersDataByIdDocument, ...options });
};
export const BasicUsersDataByUsernameDocument = gql`
    query BasicUsersDataByUsername($username: String!) {
  WUI_User(where: {username: {_eq: $username}}) {
    ...UserDetails
    about_me
    is_seller
    theme_color
    UserVideos {
      id
      url
    }
    allow_contact_without_order
    custom_color
    completed_legal_information
    WUI_File_Profile_Picture {
      id
      url
    }
    WUI_File_Cover_Picture {
      id
      url
    }
    industry
    ambassador_id
    platform_brand
  }
}
    ${UserDetailsFragmentDoc}`;

export function useBasicUsersDataByUsernameQuery(options: Omit<Urql.UseQueryArgs<BasicUsersDataByUsernameQueryVariables>, 'query'>) {
  return Urql.useQuery<BasicUsersDataByUsernameQuery, BasicUsersDataByUsernameQueryVariables>({ query: BasicUsersDataByUsernameDocument, ...options });
};
export const ChatRoomByIdDocument = gql`
    query ChatRoomByID($id: bigint!) {
  WUI_ChatRoom_by_pk(id: $id) {
    id
    type
  }
}
    `;

export function useChatRoomByIdQuery(options: Omit<Urql.UseQueryArgs<ChatRoomByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<ChatRoomByIdQuery, ChatRoomByIdQueryVariables>({ query: ChatRoomByIdDocument, ...options });
};
export const ChatRoomOrderByIdDocument = gql`
    query ChatRoomOrderByID($chatRoomID: bigint!) {
  WUI_ChatRoomOrder(where: {room_id: {_eq: $chatRoomID}}) {
    id
    WUI_Order {
      id
      WUI_User_Buyer {
        first_name
        last_name
        username
        id
        WUI_File_Profile_Picture {
          id
          url
        }
      }
      WUI_User_Seller {
        first_name
        last_name
        username
        id
        WUI_File_Profile_Picture {
          id
          url
        }
      }
      title
    }
  }
}
    `;

export function useChatRoomOrderByIdQuery(options: Omit<Urql.UseQueryArgs<ChatRoomOrderByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<ChatRoomOrderByIdQuery, ChatRoomOrderByIdQueryVariables>({ query: ChatRoomOrderByIdDocument, ...options });
};
export const ChatRoomUsersByRoomIdDocument = gql`
    query ChatRoomUsersByRoomID($id: bigint!, $userId: bigint!) {
  WUI_ChatRoom_by_pk(id: $id) {
    id
    WUI_ChatRoomUsers(where: {WUI_User: {id: {_neq: $userId}}}) {
      id
      WUI_User {
        ...UserDetails
        WUI_File_Profile_Picture {
          id
          url
        }
      }
    }
  }
}
    ${UserDetailsFragmentDoc}`;

export function useChatRoomUsersByRoomIdQuery(options: Omit<Urql.UseQueryArgs<ChatRoomUsersByRoomIdQueryVariables>, 'query'>) {
  return Urql.useQuery<ChatRoomUsersByRoomIdQuery, ChatRoomUsersByRoomIdQueryVariables>({ query: ChatRoomUsersByRoomIdDocument, ...options });
};
export const CheckUserExistsByEmailDocument = gql`
    query CheckUserExistsByEmail($email: String!) {
  WUI_User(where: {contact_email: {_eq: $email}}, limit: 1) {
    id
  }
}
    `;

export function useCheckUserExistsByEmailQuery(options: Omit<Urql.UseQueryArgs<CheckUserExistsByEmailQueryVariables>, 'query'>) {
  return Urql.useQuery<CheckUserExistsByEmailQuery, CheckUserExistsByEmailQueryVariables>({ query: CheckUserExistsByEmailDocument, ...options });
};
export const CountCurrentOrdersDocument = gql`
    query CountCurrentOrders {
  WUI_Order_aggregate(
    where: {_or: [{status: {_eq: open}}, {status: {_eq: pending}}, {status: {_eq: waitingForContent}}, {status: {_eq: inProgress}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

export function useCountCurrentOrdersQuery(options?: Omit<Urql.UseQueryArgs<CountCurrentOrdersQueryVariables>, 'query'>) {
  return Urql.useQuery<CountCurrentOrdersQuery, CountCurrentOrdersQueryVariables>({ query: CountCurrentOrdersDocument, ...options });
};
export const CountFreeVotesInCategoryDocument = gql`
    query CountFreeVotesInCategory($where: Vote_bool_exp!) {
  Vote_aggregate(where: $where) {
    aggregate {
      count
      sum {
        votes
      }
    }
  }
}
    `;

export function useCountFreeVotesInCategoryQuery(options: Omit<Urql.UseQueryArgs<CountFreeVotesInCategoryQueryVariables>, 'query'>) {
  return Urql.useQuery<CountFreeVotesInCategoryQuery, CountFreeVotesInCategoryQueryVariables>({ query: CountFreeVotesInCategoryDocument, ...options });
};
export const CountPastOrdersDocument = gql`
    query CountPastOrders {
  WUI_Order_aggregate(
    where: {_or: [{status: {_eq: rejected}}, {status: {_eq: completed}}, {status: {_eq: cancelled}}, {status: {_eq: delivered}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

export function useCountPastOrdersQuery(options?: Omit<Urql.UseQueryArgs<CountPastOrdersQueryVariables>, 'query'>) {
  return Urql.useQuery<CountPastOrdersQuery, CountPastOrdersQueryVariables>({ query: CountPastOrdersDocument, ...options });
};
export const CountUserReviewsDocument = gql`
    query CountUserReviews($userID: bigint!) {
  WUI_UserReview_aggregate(where: {to_user_id: {_eq: $userID}}) {
    aggregate {
      avg {
        stars
      }
      count(columns: id)
    }
  }
}
    `;

export function useCountUserReviewsQuery(options: Omit<Urql.UseQueryArgs<CountUserReviewsQueryVariables>, 'query'>) {
  return Urql.useQuery<CountUserReviewsQuery, CountUserReviewsQueryVariables>({ query: CountUserReviewsDocument, ...options });
};
export const CredentialsDocument = gql`
    query Credentials {
  WUI_Account {
    id
    provider
    type
  }
}
    `;

export function useCredentialsQuery(options?: Omit<Urql.UseQueryArgs<CredentialsQueryVariables>, 'query'>) {
  return Urql.useQuery<CredentialsQuery, CredentialsQueryVariables>({ query: CredentialsDocument, ...options });
};
export const CreditCardsDocument = gql`
    query CreditCards {
  WUI_PaymentMethodCard {
    brand
    id
    exp_year
    exp_month
    last4
    country_code
    external_id
  }
}
    `;

export function useCreditCardsQuery(options?: Omit<Urql.UseQueryArgs<CreditCardsQueryVariables>, 'query'>) {
  return Urql.useQuery<CreditCardsQuery, CreditCardsQueryVariables>({ query: CreditCardsDocument, ...options });
};
export const EmailDomainLookupDocument = gql`
    query EmailDomainLookup($domain: String!) {
  EmailDomainLookup_by_pk(domain: $domain) {
    banned
    seen
  }
}
    `;

export function useEmailDomainLookupQuery(options: Omit<Urql.UseQueryArgs<EmailDomainLookupQueryVariables>, 'query'>) {
  return Urql.useQuery<EmailDomainLookupQuery, EmailDomainLookupQueryVariables>({ query: EmailDomainLookupDocument, ...options });
};
export const FindChatRoomsByOrderIdDocument = gql`
    query FindChatRoomsByOrderID($orderId: bigint!) {
  WUI_ChatRoomOrder(where: {order_id: {_eq: $orderId}}) {
    id
    order_id
    WUI_ChatRoom {
      id
      type
    }
  }
}
    `;

export function useFindChatRoomsByOrderIdQuery(options: Omit<Urql.UseQueryArgs<FindChatRoomsByOrderIdQueryVariables>, 'query'>) {
  return Urql.useQuery<FindChatRoomsByOrderIdQuery, FindChatRoomsByOrderIdQueryVariables>({ query: FindChatRoomsByOrderIdDocument, ...options });
};
export const GetActiveSubscriptionsDocument = gql`
    query GetActiveSubscriptions($limit: Int!, $offset: Int!) {
  WUI_OrderSubscription(
    limit: $limit
    offset: $offset
    where: {status: {_eq: active}}
    order_by: {id: desc}
  ) {
    id
    next_billing_date
    order_id
    payment_method_id
    recurring_period
    status
    created_at
    WUI_Order {
      price
      package_type
      WUI_User_Buyer {
        id
        first_name
        last_name
        WUI_File_Profile_Picture {
          file_type
          id
          url
          created_at
        }
        username
      }
      WUI_User_Seller {
        id
        first_name
        last_name
        WUI_File_Profile_Picture {
          file_type
          id
          url
          created_at
        }
        username
      }
    }
  }
}
    `;

export function useGetActiveSubscriptionsQuery(options: Omit<Urql.UseQueryArgs<GetActiveSubscriptionsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetActiveSubscriptionsQuery, GetActiveSubscriptionsQueryVariables>({ query: GetActiveSubscriptionsDocument, ...options });
};
export const GetAllNomineesDocument = gql`
    query GetAllNominees {
  VoteUser {
    WUI_User {
      username
    }
  }
}
    `;

export function useGetAllNomineesQuery(options?: Omit<Urql.UseQueryArgs<GetAllNomineesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllNomineesQuery, GetAllNomineesQueryVariables>({ query: GetAllNomineesDocument, ...options });
};
export const GetAllUsersDocument = gql`
    query GetAllUsers {
  WUI_User(where: {username: {_is_null: false}}) {
    username
  }
}
    `;

export function useGetAllUsersQuery(options?: Omit<Urql.UseQueryArgs<GetAllUsersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>({ query: GetAllUsersDocument, ...options });
};
export const GetAudienceSizeDocument = gql`
    query GetAudienceSize($userID: bigint!) {
  SocialMediaDemographic(
    where: {user_id: {_eq: $userID}, demographic: {_eq: audience_size}}
  ) {
    demographic
    id
    source
    user_id
    value
  }
}
    `;

export function useGetAudienceSizeQuery(options: Omit<Urql.UseQueryArgs<GetAudienceSizeQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAudienceSizeQuery, GetAudienceSizeQueryVariables>({ query: GetAudienceSizeDocument, ...options });
};
export const GetBankAccountsDocument = gql`
    query GetBankAccounts {
  getBankAccounts {
    id
    last4
    object
    routing_number
    status
    fingerprint
    currency
    country
    bank_name
    account_holder_type
    account_holder_name
    account
    metadata
  }
}
    `;

export function useGetBankAccountsQuery(options?: Omit<Urql.UseQueryArgs<GetBankAccountsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetBankAccountsQuery, GetBankAccountsQueryVariables>({ query: GetBankAccountsDocument, ...options });
};
export const GetChatRoomByIdDocument = gql`
    query GetChatRoomByID($roomID: bigint!) {
  WUI_ChatRoom_by_pk(id: $roomID) {
    id
    type
    LastMessage {
      created_at
      message
    }
    WUI_ChatRoomUsers {
      id
      seen
      WUI_User {
        id
        username
        first_name
        last_name
      }
    }
  }
}
    `;

export function useGetChatRoomByIdQuery(options: Omit<Urql.UseQueryArgs<GetChatRoomByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetChatRoomByIdQuery, GetChatRoomByIdQueryVariables>({ query: GetChatRoomByIdDocument, ...options });
};
export const GetChatRoomsByUserDocument = gql`
    query GetChatRoomsByUser($limit: Int!, $offset: Int!, $filter: WUI_ChatRoom_bool_exp) {
  WUI_ChatRoom(
    limit: $limit
    offset: $offset
    where: $filter
    order_by: {updated_at: desc}
  ) {
    id
    type
    LastMessage {
      created_at
      message
    }
    WUI_ChatRoomUsers {
      id
      seen
      WUI_User {
        ...UserDetails
        type
      }
    }
  }
}
    ${UserDetailsFragmentDoc}`;

export function useGetChatRoomsByUserQuery(options: Omit<Urql.UseQueryArgs<GetChatRoomsByUserQueryVariables>, 'query'>) {
  return Urql.useQuery<GetChatRoomsByUserQuery, GetChatRoomsByUserQueryVariables>({ query: GetChatRoomsByUserDocument, ...options });
};
export const GetClicksByLinkIdDocument = gql`
    query GetClicksByLinkId($userID: bigint!, $linkID: bigint!) {
  WUI_SessionEvent_aggregate(
    where: {target_user_id: {_eq: $userID}, event_type: {_eq: click}, link_id: {_eq: $linkID}}
  ) {
    aggregate {
      count(columns: id)
    }
    nodes {
      id
      created_at
      SocialMediaLink {
        title
        id
      }
    }
  }
}
    `;

export function useGetClicksByLinkIdQuery(options: Omit<Urql.UseQueryArgs<GetClicksByLinkIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetClicksByLinkIdQuery, GetClicksByLinkIdQueryVariables>({ query: GetClicksByLinkIdDocument, ...options });
};
export const GetClientsDocument = gql`
    query GetClients($id: bigint!) {
  WUI_Order(distinct_on: buyer_id, where: {seller_id: {_eq: $id}}, limit: 10) {
    buyer_id
    seller_id
    WUI_User_Buyer {
      ...UserDetails
      is_seller
      WUI_File_Profile_Picture {
        url
      }
      SocialMediaDemographics {
        source
        value
        demographic
      }
    }
  }
}
    ${UserDetailsFragmentDoc}`;

export function useGetClientsQuery(options: Omit<Urql.UseQueryArgs<GetClientsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetClientsQuery, GetClientsQueryVariables>({ query: GetClientsDocument, ...options });
};
export const GetConverSationWithUserIdDocument = gql`
    query GetConverSationWithUserID($firstID: bigint!, $secondID: bigint!) {
  WUI_ChatRoom(
    where: {WUI_ChatRoomUsers: {user_id: {_in: [$firstID]}}, _and: {WUI_ChatRoomUsers: {user_id: {_in: [$secondID]}}, type: {_eq: conversation}}}
  ) {
    WUI_ChatRoomUsers {
      WUI_User {
        id
        username
        first_name
      }
      id
    }
    id
  }
}
    `;

export function useGetConverSationWithUserIdQuery(options: Omit<Urql.UseQueryArgs<GetConverSationWithUserIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetConverSationWithUserIdQuery, GetConverSationWithUserIdQueryVariables>({ query: GetConverSationWithUserIdDocument, ...options });
};
export const GetCreatedReviewsDocument = gql`
    query GetCreatedReviews($userID: bigint!) {
  WUI_UserReview(
    where: {from_user_id: {_eq: $userID}}
    order_by: {created_at: desc}
  ) {
    WUI_User_To {
      id
      first_name
      last_name
      username
      WUI_File_Profile_Picture {
        url
        id
        file_type
        created_at
      }
    }
    id
    to_user_id
    from_user_id
    created_at
    comment
    stars
  }
}
    `;

export function useGetCreatedReviewsQuery(options: Omit<Urql.UseQueryArgs<GetCreatedReviewsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCreatedReviewsQuery, GetCreatedReviewsQueryVariables>({ query: GetCreatedReviewsDocument, ...options });
};
export const GetCurrentOrdersMetricsDocument = gql`
    query GetCurrentOrdersMetrics($userID: bigint!) {
  actives: WUI_Order_aggregate(
    where: {_and: [{_or: [{status: {_eq: inProgress}}, {status: {_eq: delivered}}]}, {_or: [{seller_id: {_eq: $userID}}, {buyer_id: {_eq: $userID}}]}]}
  ) {
    aggregate {
      count(columns: id)
      sum {
        price
      }
    }
    nodes {
      id
    }
  }
  pending: WUI_Order_aggregate(
    where: {_or: [{_or: [{status: {_eq: pending}}, {status: {_eq: waitingForContent}}]}, {_or: {buyer_id: {_eq: $userID}, seller_id: {_eq: $userID}}}]}
  ) {
    aggregate {
      count(columns: id)
      sum {
        price
      }
    }
    nodes {
      id
    }
  }
}
    `;

export function useGetCurrentOrdersMetricsQuery(options: Omit<Urql.UseQueryArgs<GetCurrentOrdersMetricsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCurrentOrdersMetricsQuery, GetCurrentOrdersMetricsQueryVariables>({ query: GetCurrentOrdersMetricsDocument, ...options });
};
export const GetHiredUsersDocument = gql`
    query GetHiredUsers($id: bigint!) {
  WUI_Order(distinct_on: seller_id, where: {buyer_id: {_eq: $id}}, limit: 10) {
    buyer_id
    seller_id
    WUI_User_Seller {
      ...UserDetails
      is_seller
      WUI_File_Profile_Picture {
        url
      }
      SocialMediaDemographics {
        source
        value
        demographic
      }
    }
  }
}
    ${UserDetailsFragmentDoc}`;

export function useGetHiredUsersQuery(options: Omit<Urql.UseQueryArgs<GetHiredUsersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetHiredUsersQuery, GetHiredUsersQueryVariables>({ query: GetHiredUsersDocument, ...options });
};
export const GetInactiveSubscriptionsDocument = gql`
    query GetInactiveSubscriptions($limit: Int!, $offset: Int!) {
  WUI_OrderSubscription(
    limit: $limit
    offset: $offset
    where: {status: {_eq: inactive}}
    order_by: {id: desc}
  ) {
    id
    next_billing_date
    order_id
    payment_method_id
    recurring_period
    status
    created_at
    WUI_Order {
      price
      package_type
      WUI_User_Buyer {
        id
        first_name
        last_name
        WUI_File_Profile_Picture {
          file_type
          id
          url
          created_at
        }
        username
      }
      WUI_User_Seller {
        id
        first_name
        last_name
        WUI_File_Profile_Picture {
          file_type
          id
          url
          created_at
        }
        username
      }
    }
  }
}
    `;

export function useGetInactiveSubscriptionsQuery(options: Omit<Urql.UseQueryArgs<GetInactiveSubscriptionsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetInactiveSubscriptionsQuery, GetInactiveSubscriptionsQueryVariables>({ query: GetInactiveSubscriptionsDocument, ...options });
};
export const GetIndustriesDocument = gql`
    query GetIndustries {
  Industry {
    value
  }
}
    `;

export function useGetIndustriesQuery(options?: Omit<Urql.UseQueryArgs<GetIndustriesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetIndustriesQuery, GetIndustriesQueryVariables>({ query: GetIndustriesDocument, ...options });
};
export const GetLastChatMessageByRoomIdDocument = gql`
    query GetLastChatMessageByRoomID($roomID: bigint!) {
  WUI_ChatRoom_by_pk(id: $roomID) {
    id
    WUI_ChatRoomMessages(limit: 1, order_by: {created_at: desc}) {
      id
      created_at
      message
    }
  }
}
    `;

export function useGetLastChatMessageByRoomIdQuery(options: Omit<Urql.UseQueryArgs<GetLastChatMessageByRoomIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLastChatMessageByRoomIdQuery, GetLastChatMessageByRoomIdQueryVariables>({ query: GetLastChatMessageByRoomIdDocument, ...options });
};
export const GetMarketplaceWaitlistDocument = gql`
    query GetMarketplaceWaitlist {
  MarketplaceWaitlist {
    user_id
    created_at
    updated_at
    id
  }
}
    `;

export function useGetMarketplaceWaitlistQuery(options?: Omit<Urql.UseQueryArgs<GetMarketplaceWaitlistQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMarketplaceWaitlistQuery, GetMarketplaceWaitlistQueryVariables>({ query: GetMarketplaceWaitlistDocument, ...options });
};
export const GetMessagesByRoomIdDocument = gql`
    subscription GetMessagesByRoomID($roomID: bigint!, $limit: Int!) {
  WUI_ChatRoomMessage(
    where: {room_id: {_eq: $roomID}}
    order_by: {id: desc}
    limit: $limit
  ) {
    id
    message
    created_at
    WUI_User {
      ...UserDetails
      WUI_File_Profile_Picture {
        id
        url
      }
    }
    room_id
    data
  }
}
    ${UserDetailsFragmentDoc}`;

export function useGetMessagesByRoomIdSubscription<TData = GetMessagesByRoomIdSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetMessagesByRoomIdSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<GetMessagesByRoomIdSubscription, TData>) {
  return Urql.useSubscription<GetMessagesByRoomIdSubscription, TData, GetMessagesByRoomIdSubscriptionVariables>({ query: GetMessagesByRoomIdDocument, ...options }, handler);
};
export const GetOrderConversationsByUserIdDocument = gql`
    query GetOrderConversationsByUserID($userID: bigint!) {
  WUI_ChatRoom(
    where: {type: {_eq: order}, WUI_ChatRoomUsers: {user_id: {_in: [$userID]}}}
  ) {
    WUI_ChatRoomMessages(limit: 1, order_by: {id: desc}) {
      WUI_User {
        id
        username
        first_name
      }
      created_at
      id
      message
      data
    }
    WUI_ChatRoomUsers(where: {user_id: {_neq: $userID}}) {
      WUI_User {
        id
        username
        WUI_File_Profile_Picture {
          id
          url
        }
      }
      id
    }
    id
  }
}
    `;

export function useGetOrderConversationsByUserIdQuery(options: Omit<Urql.UseQueryArgs<GetOrderConversationsByUserIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrderConversationsByUserIdQuery, GetOrderConversationsByUserIdQueryVariables>({ query: GetOrderConversationsByUserIdDocument, ...options });
};
export const GetOrderDetailsMessageDocument = gql`
    query GetOrderDetailsMessage($orderID: bigint!) {
  WUI_ChatRoomOrder(where: {order_id: {_eq: $orderID}}) {
    id
    WUI_ChatRoom {
      id
      WUI_ChatRoomMessages(
        order_by: {id: asc}
        limit: 1
        where: {data: {_is_null: false}}
      ) {
        created_at
        data
        id
        message
        updated_at
        user_id
      }
    }
  }
}
    `;

export function useGetOrderDetailsMessageQuery(options: Omit<Urql.UseQueryArgs<GetOrderDetailsMessageQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrderDetailsMessageQuery, GetOrderDetailsMessageQueryVariables>({ query: GetOrderDetailsMessageDocument, ...options });
};
export const GetOrdersHistoryMetricsDocument = gql`
    query GetOrdersHistoryMetrics($userID: bigint!, $whereClosedAt: timestamptz_comparison_exp!) {
  completed: WUI_Order_aggregate(
    where: {status: {_eq: completed}, _or: [{seller_id: {_eq: $userID}}, {buyer_id: {_eq: $userID}}], closed_at: $whereClosedAt}
  ) {
    aggregate {
      count(columns: id)
      sum {
        price
      }
    }
    nodes {
      id
    }
  }
  cancelled: WUI_Order_aggregate(
    where: {status: {_eq: rejected}, _or: [{seller_id: {_eq: $userID}}, {buyer_id: {_eq: $userID}}], closed_at: $whereClosedAt}
  ) {
    aggregate {
      count(columns: id)
      sum {
        price
      }
    }
    nodes {
      id
    }
  }
}
    `;

export function useGetOrdersHistoryMetricsQuery(options: Omit<Urql.UseQueryArgs<GetOrdersHistoryMetricsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrdersHistoryMetricsQuery, GetOrdersHistoryMetricsQueryVariables>({ query: GetOrdersHistoryMetricsDocument, ...options });
};
export const GetPackagesRequestDocument = gql`
    query GetPackagesRequest($userID: bigint!) {
  WUI_PackageRequest(where: {seller_id: {_eq: $userID}}, order_by: {status: desc}) {
    id
    updated_at
    requester_id
    seller_id
    status
    WUI_Seller_User {
      id
      first_name
      last_name
      username
      WUI_File_Profile_Picture {
        id
        url
      }
      WUI_File_Cover_Picture {
        id
        url
      }
    }
    WUI_Requester_User {
      id
      first_name
      last_name
      username
      WUI_File_Profile_Picture {
        id
        url
      }
      WUI_File_Cover_Picture {
        id
        url
      }
    }
    WUI_SellerPackage {
      id
      title
    }
  }
}
    `;

export function useGetPackagesRequestQuery(options: Omit<Urql.UseQueryArgs<GetPackagesRequestQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPackagesRequestQuery, GetPackagesRequestQueryVariables>({ query: GetPackagesRequestDocument, ...options });
};
export const GetPackagesRequestByPackageIdDocument = gql`
    query GetPackagesRequestByPackageId($package_id: bigint) {
  WUI_PackageRequest(
    where: {package_id: {_eq: $package_id}}
    order_by: {id: desc}
  ) {
    id
    status
    tries
  }
}
    `;

export function useGetPackagesRequestByPackageIdQuery(options?: Omit<Urql.UseQueryArgs<GetPackagesRequestByPackageIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPackagesRequestByPackageIdQuery, GetPackagesRequestByPackageIdQueryVariables>({ query: GetPackagesRequestByPackageIdDocument, ...options });
};
export const GetPaymentByOrderIdDocument = gql`
    query GetPaymentByOrderId($order_id: bigint!) {
  WUI_Payment(where: {order_id: {_eq: $order_id}}) {
    price
  }
}
    `;

export function useGetPaymentByOrderIdQuery(options: Omit<Urql.UseQueryArgs<GetPaymentByOrderIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPaymentByOrderIdQuery, GetPaymentByOrderIdQueryVariables>({ query: GetPaymentByOrderIdDocument, ...options });
};
export const GetPaymentsDocument = gql`
    query GetPayments($limit: Int!, $offset: Int!) {
  WUI_Payment(order_by: {updated_at: desc}, limit: $limit, offset: $offset) {
    id
    price
    statement_description
    description
    status
    WUI_Order {
      id
      description
      package_type
      status
      buyer_id
      seller_id
      WUI_User_Seller {
        id
        first_name
        last_name
        username
        WUI_File_Profile_Picture {
          url
          id
          file_type
          created_at
        }
      }
      WUI_User_Buyer {
        id
        first_name
        last_name
        username
        WUI_File_Profile_Picture {
          url
          id
          file_type
          created_at
        }
      }
    }
    updated_at
    created_at
    seller_fees
    buyer_fees
  }
}
    `;

export function useGetPaymentsQuery(options: Omit<Urql.UseQueryArgs<GetPaymentsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPaymentsQuery, GetPaymentsQueryVariables>({ query: GetPaymentsDocument, ...options });
};
export const GetProfileViewsDocument = gql`
    query GetProfileViews($userID: bigint!, $whereCreatedAt: timestamptz_comparison_exp!) {
  WUI_SessionEvent_aggregate(
    where: {target_user_id: {_eq: $userID}, event_type: {_eq: page_view}, created_at: $whereCreatedAt}
  ) {
    aggregate {
      count(columns: id)
    }
    nodes {
      id
      created_at
    }
  }
}
    `;

export function useGetProfileViewsQuery(options: Omit<Urql.UseQueryArgs<GetProfileViewsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetProfileViewsQuery, GetProfileViewsQueryVariables>({ query: GetProfileViewsDocument, ...options });
};
export const GetReviewByOrderIdDocument = gql`
    query GetReviewByOrderID($orderID: bigint!) {
  WUI_OrderReview(where: {order_id: {_eq: $orderID}}) {
    WUI_UserReview {
      comment
      created_at
      from_user_id
      id
      stars
      to_user_id
      WUI_User_To {
        id
        first_name
        last_name
        username
        WUI_File_Profile_Picture {
          url
          id
          file_type
          created_at
        }
      }
      WUI_User_From {
        id
        first_name
        last_name
        username
        WUI_File_Profile_Picture {
          url
          id
          file_type
          created_at
        }
      }
    }
  }
}
    `;

export function useGetReviewByOrderIdQuery(options: Omit<Urql.UseQueryArgs<GetReviewByOrderIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetReviewByOrderIdQuery, GetReviewByOrderIdQueryVariables>({ query: GetReviewByOrderIdDocument, ...options });
};
export const GetSentPackagesRequestDocument = gql`
    query GetSentPackagesRequest($userID: bigint!) {
  WUI_PackageRequest(
    where: {requester_id: {_eq: $userID}}
    order_by: {status: desc}
  ) {
    id
    updated_at
    requester_id
    seller_id
    status
    WUI_Seller_User {
      id
      first_name
      last_name
      username
      WUI_File_Profile_Picture {
        id
        url
      }
      WUI_File_Cover_Picture {
        id
        url
      }
    }
    WUI_Requester_User {
      id
      first_name
      last_name
      username
      WUI_File_Profile_Picture {
        id
        url
      }
      WUI_File_Cover_Picture {
        id
        url
      }
    }
    WUI_SellerPackage {
      id
      title
    }
  }
}
    `;

export function useGetSentPackagesRequestQuery(options: Omit<Urql.UseQueryArgs<GetSentPackagesRequestQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSentPackagesRequestQuery, GetSentPackagesRequestQueryVariables>({ query: GetSentPackagesRequestDocument, ...options });
};
export const GetSessionDocument = gql`
    query GetSession($where: WUI_Session_bool_exp!) {
  WUI_Session(where: $where) {
    id
    session_id
    user_id
  }
}
    `;

export function useGetSessionQuery(options: Omit<Urql.UseQueryArgs<GetSessionQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSessionQuery, GetSessionQueryVariables>({ query: GetSessionDocument, ...options });
};
export const GetSocialMediaDemographicsDocument = gql`
    query GetSocialMediaDemographics($userID: bigint!) {
  SocialMediaDemographic(where: {user_id: {_eq: $userID}}) {
    id
    demographic
    source
    user_id
    value
  }
}
    `;

export function useGetSocialMediaDemographicsQuery(options: Omit<Urql.UseQueryArgs<GetSocialMediaDemographicsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSocialMediaDemographicsQuery, GetSocialMediaDemographicsQueryVariables>({ query: GetSocialMediaDemographicsDocument, ...options });
};
export const GetSocialMediaLinksDocument = gql`
    query GetSocialMediaLinks($userID: bigint!) {
  SocialMediaLink(where: {user_id: {_eq: $userID}}, order_by: {order: asc}) {
    id
    primary
    title
    url
    user_id
    enabled
    solid
    order
  }
}
    `;

export function useGetSocialMediaLinksQuery(options: Omit<Urql.UseQueryArgs<GetSocialMediaLinksQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSocialMediaLinksQuery, GetSocialMediaLinksQueryVariables>({ query: GetSocialMediaLinksDocument, ...options });
};
export const GetStartedAndEndedAtDocument = gql`
    query GetStartedAndEndedAt($voteUserCategoryId: uuid!) {
  VoteUserCategory_by_pk(id: $voteUserCategoryId) {
    VoteUser {
      started_at
      ended_at
    }
  }
}
    `;

export function useGetStartedAndEndedAtQuery(options: Omit<Urql.UseQueryArgs<GetStartedAndEndedAtQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStartedAndEndedAtQuery, GetStartedAndEndedAtQueryVariables>({ query: GetStartedAndEndedAtDocument, ...options });
};
export const GetSubscriptionByOrderIdDocument = gql`
    query GetSubscriptionByOrderId($id: bigint!) {
  WUI_OrderSubscription(where: {order_id: {_eq: $id}}) {
    id
    next_billing_date
    recurring_period
  }
}
    `;

export function useGetSubscriptionByOrderIdQuery(options: Omit<Urql.UseQueryArgs<GetSubscriptionByOrderIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSubscriptionByOrderIdQuery, GetSubscriptionByOrderIdQueryVariables>({ query: GetSubscriptionByOrderIdDocument, ...options });
};
export const GetSupportedCountriesDocument = gql`
    query GetSupportedCountries {
  getCountrySupported {
    data
  }
}
    `;

export function useGetSupportedCountriesQuery(options?: Omit<Urql.UseQueryArgs<GetSupportedCountriesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSupportedCountriesQuery, GetSupportedCountriesQueryVariables>({ query: GetSupportedCountriesDocument, ...options });
};
export const GetUserReviewsDocument = gql`
    query GetUserReviews($userID: bigint!, $limit: Int!, $offset: Int!) {
  WUI_UserReview(
    where: {to_user_id: {_eq: $userID}}
    order_by: {created_at: desc}
    limit: $limit
    offset: $offset
  ) {
    WUI_User_From {
      id
      first_name
      last_name
      username
      WUI_File_Profile_Picture {
        url
        id
        file_type
      }
    }
    id
    to_user_id
    from_user_id
    created_at
    comment
    stars
  }
}
    `;

export function useGetUserReviewsQuery(options: Omit<Urql.UseQueryArgs<GetUserReviewsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserReviewsQuery, GetUserReviewsQueryVariables>({ query: GetUserReviewsDocument, ...options });
};
export const LegalInformationDocument = gql`
    query LegalInformation {
  legalInformation {
    type
    data
    address
  }
}
    `;

export function useLegalInformationQuery(options?: Omit<Urql.UseQueryArgs<LegalInformationQueryVariables>, 'query'>) {
  return Urql.useQuery<LegalInformationQuery, LegalInformationQueryVariables>({ query: LegalInformationDocument, ...options });
};
export const ManageOrdersDocument = gql`
    query ManageOrders($limit: Int!, $offset: Int!, $where: WUI_Order_bool_exp!) {
  WUI_Order(
    limit: $limit
    offset: $offset
    where: $where
    order_by: {updated_at: desc}
  ) {
    id
    package_type
    title
    description
    price
    status
    created_by
    created_at
    items
    updated_at
    buyer_id
    seller_id
    metadata
    WUI_User_Buyer {
      ...UserDetails
      WUI_File_Profile_Picture {
        id
        url
      }
    }
    WUI_User_Seller {
      ...UserDetails
      WUI_File_Profile_Picture {
        id
        url
      }
    }
    WUI_Payment {
      price
    }
  }
}
    ${UserDetailsFragmentDoc}`;

export function useManageOrdersQuery(options: Omit<Urql.UseQueryArgs<ManageOrdersQueryVariables>, 'query'>) {
  return Urql.useQuery<ManageOrdersQuery, ManageOrdersQueryVariables>({ query: ManageOrdersDocument, ...options });
};
export const ManagePackagesDocument = gql`
    query ManagePackages($userID: bigint!) {
  WUI_SellerPackage(where: {user_id: {_eq: $userID}}) {
    description
    enabled
    id
    package_type
    price
    title
    metadata
    hide_price
    WUI_SellerPackageItems {
      id
      title
      comment
      quantity
    }
  }
}
    `;

export function useManagePackagesQuery(options: Omit<Urql.UseQueryArgs<ManagePackagesQueryVariables>, 'query'>) {
  return Urql.useQuery<ManagePackagesQuery, ManagePackagesQueryVariables>({ query: ManagePackagesDocument, ...options });
};
export const ManageProjectsDocument = gql`
    query ManageProjects($userID: bigint!) {
  WUI_SellerProject(where: {user_id: {_eq: $userID}}) {
    description
    enabled
    id
    price
    title
    WUI_SellerProjectItems {
      details
    }
    demographics {
      demographic
      id
      project_id
      source
      value
    }
    WUI_SellerProjectFiles {
      WUI_File {
        url
      }
    }
  }
}
    `;

export function useManageProjectsQuery(options: Omit<Urql.UseQueryArgs<ManageProjectsQueryVariables>, 'query'>) {
  return Urql.useQuery<ManageProjectsQuery, ManageProjectsQueryVariables>({ query: ManageProjectsDocument, ...options });
};
export const MostRecentOpenOrdersDocument = gql`
    query MostRecentOpenOrders {
  WUI_Order(
    limit: 1
    order_by: {updated_at: desc}
    where: {_or: [{status: {_eq: waitingForContent}}, {status: {_eq: inProgress}}, {status: {_eq: delivered}}]}
  ) {
    id
    package_type
    title
    description
    price
    status
    created_by
    created_at
    items
    buyer_id
    WUI_User_Buyer {
      ...UserDetails
      WUI_File_Profile_Picture {
        id
        url
      }
    }
    WUI_User_Seller {
      ...UserDetails
      WUI_File_Profile_Picture {
        id
        url
      }
    }
  }
}
    ${UserDetailsFragmentDoc}`;

export function useMostRecentOpenOrdersQuery(options?: Omit<Urql.UseQueryArgs<MostRecentOpenOrdersQueryVariables>, 'query'>) {
  return Urql.useQuery<MostRecentOpenOrdersQuery, MostRecentOpenOrdersQueryVariables>({ query: MostRecentOpenOrdersDocument, ...options });
};
export const MostRecentPendingOrdersDocument = gql`
    query MostRecentPendingOrders {
  WUI_Order(
    limit: 1
    order_by: {updated_at: desc}
    where: {_or: [{status: {_eq: open}}, {status: {_eq: pending}}]}
  ) {
    id
    package_type
    title
    description
    price
    status
    created_by
    created_at
    items
    buyer_id
    WUI_User_Buyer {
      ...UserDetails
      WUI_File_Profile_Picture {
        url
      }
    }
    WUI_User_Seller {
      ...UserDetails
      WUI_File_Profile_Picture {
        url
      }
    }
  }
}
    ${UserDetailsFragmentDoc}`;

export function useMostRecentPendingOrdersQuery(options?: Omit<Urql.UseQueryArgs<MostRecentPendingOrdersQueryVariables>, 'query'>) {
  return Urql.useQuery<MostRecentPendingOrdersQuery, MostRecentPendingOrdersQueryVariables>({ query: MostRecentPendingOrdersDocument, ...options });
};
export const OrderByIdDocument = gql`
    query OrderByID($orderID: bigint!) {
  WUI_Order_by_pk(id: $orderID) {
    id
    package_type
    title
    description
    price
    status
    created_by
    created_at
    items
    seller_id
    buyer_id
  }
}
    `;

export function useOrderByIdQuery(options: Omit<Urql.UseQueryArgs<OrderByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<OrderByIdQuery, OrderByIdQueryVariables>({ query: OrderByIdDocument, ...options });
};
export const PackageDetailsDocument = gql`
    query PackageDetails($id: bigint!) {
  WUI_SellerPackage_by_pk(id: $id) {
    id
    enabled
    description
    package_type
    price
    title
    user_id
    metadata
    hide_price
    WUI_SellerPackageItems {
      id
      quantity
      title
      comment
    }
    WUI_User {
      ...UserDetails
      WUI_File_Profile_Picture {
        url
      }
    }
  }
}
    ${UserDetailsFragmentDoc}`;

export function usePackageDetailsQuery(options: Omit<Urql.UseQueryArgs<PackageDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<PackageDetailsQuery, PackageDetailsQueryVariables>({ query: PackageDetailsDocument, ...options });
};
export const PackageRequestsByPackageIdDocument = gql`
    query PackageRequestsByPackageId($packageId: bigint!) {
  WUI_PackageRequest(where: {package_id: {_eq: $packageId}}) {
    status
  }
}
    `;

export function usePackageRequestsByPackageIdQuery(options: Omit<Urql.UseQueryArgs<PackageRequestsByPackageIdQueryVariables>, 'query'>) {
  return Urql.useQuery<PackageRequestsByPackageIdQuery, PackageRequestsByPackageIdQueryVariables>({ query: PackageRequestsByPackageIdDocument, ...options });
};
export const PlatformAlertsDocument = gql`
    query PlatformAlerts {
  PlatformAlert(order_by: {updated_at: asc}) {
    id
    created_at
    updated_at
    message
    message_es
    type
  }
}
    `;

export function usePlatformAlertsQuery(options?: Omit<Urql.UseQueryArgs<PlatformAlertsQueryVariables>, 'query'>) {
  return Urql.useQuery<PlatformAlertsQuery, PlatformAlertsQueryVariables>({ query: PlatformAlertsDocument, ...options });
};
export const PortfolioPicturesDocument = gql`
    query PortfolioPictures($userID: bigint!) {
  WUI_UserFilePortfolio(
    where: {user_id: {_eq: $userID}, WUI_File: {file_type: {_eq: image}}}
    order_by: {order: asc}
  ) {
    id
    WUI_File {
      id
      file_type
      url
    }
  }
}
    `;

export function usePortfolioPicturesQuery(options: Omit<Urql.UseQueryArgs<PortfolioPicturesQueryVariables>, 'query'>) {
  return Urql.useQuery<PortfolioPicturesQuery, PortfolioPicturesQueryVariables>({ query: PortfolioPicturesDocument, ...options });
};
export const ProPlansDocument = gql`
    query ProPlans {
  WUI_SubscriptionPlan(order_by: {order: asc}) {
    id
    name
    price
    original_price
    frequency
  }
}
    `;

export function useProPlansQuery(options?: Omit<Urql.UseQueryArgs<ProPlansQueryVariables>, 'query'>) {
  return Urql.useQuery<ProPlansQuery, ProPlansQueryVariables>({ query: ProPlansDocument, ...options });
};
export const ProfilePackagesDocument = gql`
    query ProfilePackages($userID: bigint!) {
  WUI_SellerPackage(where: {user_id: {_eq: $userID}}) {
    description
    enabled
    id
    package_type
    price
    title
    user_id
    metadata
    hide_price
    WUI_SellerPackageItems {
      id
      title
      comment
      quantity
    }
  }
}
    `;

export function useProfilePackagesQuery(options: Omit<Urql.UseQueryArgs<ProfilePackagesQueryVariables>, 'query'>) {
  return Urql.useQuery<ProfilePackagesQuery, ProfilePackagesQueryVariables>({ query: ProfilePackagesDocument, ...options });
};
export const ProfileSettingsDocument = gql`
    query ProfileSettings($id: bigint!) {
  WUI_User_by_pk(id: $id) {
    ...UserDetails
    ...UserContact
    about_me
    is_seller
    theme_color
    allow_contact_without_order
    custom_color
    country
    WUI_File_Profile_Picture {
      id
      url
    }
    WUI_File_Cover_Picture {
      id
      url
    }
    industry
    UserVideos {
      id
      url
    }
    language
  }
}
    ${UserDetailsFragmentDoc}
${UserContactFragmentDoc}`;

export function useProfileSettingsQuery(options: Omit<Urql.UseQueryArgs<ProfileSettingsQueryVariables>, 'query'>) {
  return Urql.useQuery<ProfileSettingsQuery, ProfileSettingsQueryVariables>({ query: ProfileSettingsDocument, ...options });
};
export const PublicProfileDocument = gql`
    query PublicProfile($userID: bigint!) {
  SocialMediaDemographic(where: {user_id: {_eq: $userID}}) {
    id
    demographic
    source
    user_id
    value
  }
  WUI_SellerPackage(where: {user_id: {_eq: $userID}}) {
    description
    enabled
    id
    package_type
    price
    title
    user_id
    metadata
    hide_price
    WUI_SellerPackageItems {
      id
      title
      comment
      quantity
    }
  }
  WUI_UserFilePortfolio(
    where: {user_id: {_eq: $userID}, WUI_File: {file_type: {_eq: image}}}
  ) {
    id
    order
    WUI_File {
      id
      file_type
      url
    }
  }
  SocialMediaLink(where: {user_id: {_eq: $userID}}, order_by: {order: asc}) {
    id
    primary
    title
    url
    user_id
    enabled
    solid
  }
  WUI_UserReview_aggregate(where: {to_user_id: {_eq: $userID}}) {
    aggregate {
      avg {
        stars
      }
      count(columns: id)
    }
  }
  UserVideo(where: {user_id: {_eq: $userID}}) {
    id
    url
  }
  WUI_Affiliate_aggregate(where: {user_id: {_eq: $userID}}) {
    aggregate {
      count
    }
  }
  WUI_UserSubscription_aggregate(
    where: {_and: [{user_id: {_eq: $userID}}, {status: {_eq: "active"}}]}
  ) {
    aggregate {
      count
    }
  }
  VoteUser_by_pk(user_id: $userID) {
    started_at
    ended_at
    primary_category_id
    VoteUserCategories {
      id
      category_id
    }
  }
}
    `;

export function usePublicProfileQuery(options: Omit<Urql.UseQueryArgs<PublicProfileQueryVariables>, 'query'>) {
  return Urql.useQuery<PublicProfileQuery, PublicProfileQueryVariables>({ query: PublicProfileDocument, ...options });
};
export const SearchInfluencersDocument = gql`
    query SearchInfluencers($filters: SearchInfluencersFilters!) {
  searchInfluencers(filters: $filters) {
    user_id
    picture
    first_name
    last_name
    username
    review_avg
    social_media
    highlights
    from_price
    is_seller
  }
}
    `;

export function useSearchInfluencersQuery(options: Omit<Urql.UseQueryArgs<SearchInfluencersQueryVariables>, 'query'>) {
  return Urql.useQuery<SearchInfluencersQuery, SearchInfluencersQueryVariables>({ query: SearchInfluencersDocument, ...options });
};
export const SocialMediaDocument = gql`
    query SocialMedia($userID: bigint!) {
  SocialMedia(where: {user_id: {_eq: $userID}}) {
    id
    user_id
    platform
    handle
    followers
    following
    posts
    engagement
  }
}
    `;

export function useSocialMediaQuery(options: Omit<Urql.UseQueryArgs<SocialMediaQueryVariables>, 'query'>) {
  return Urql.useQuery<SocialMediaQuery, SocialMediaQueryVariables>({ query: SocialMediaDocument, ...options });
};
export const SubscriptionPlanByIdDocument = gql`
    query SubscriptionPlanByID($planID: uuid!) {
  WUI_SubscriptionPlan_by_pk(id: $planID) {
    stripe_product_id
    stripe_price_id
    price
  }
}
    `;

export function useSubscriptionPlanByIdQuery(options: Omit<Urql.UseQueryArgs<SubscriptionPlanByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<SubscriptionPlanByIdQuery, SubscriptionPlanByIdQueryVariables>({ query: SubscriptionPlanByIdDocument, ...options });
};
export const TotalAffiliateUsersDocument = gql`
    query TotalAffiliateUsers {
  WUI_AffiliateUsers_aggregate {
    aggregate {
      count
    }
  }
}
    `;

export function useTotalAffiliateUsersQuery(options?: Omit<Urql.UseQueryArgs<TotalAffiliateUsersQueryVariables>, 'query'>) {
  return Urql.useQuery<TotalAffiliateUsersQuery, TotalAffiliateUsersQueryVariables>({ query: TotalAffiliateUsersDocument, ...options });
};
export const UpdateOrderDetailsDocument = gql`
    mutation UpdateOrderDetails($messageID: bigint!, $data: jsonb!) {
  update_WUI_ChatRoomMessage_by_pk(
    pk_columns: {id: $messageID}
    _set: {data: $data}
  ) {
    id
    data
  }
}
    `;

export function useUpdateOrderDetailsMutation() {
  return Urql.useMutation<UpdateOrderDetailsMutation, UpdateOrderDetailsMutationVariables>(UpdateOrderDetailsDocument);
};
export const UpdateUserIsTypingDocument = gql`
    mutation UpdateUserIsTyping($set: WUI_ChatRoomUser_set_input!, $userID: bigint!, $roomID: bigint!) {
  update_WUI_ChatRoomUser(
    where: {user_id: {_eq: $userID}, room_id: {_eq: $roomID}}
    _set: $set
  ) {
    affected_rows
  }
}
    `;

export function useUpdateUserIsTypingMutation() {
  return Urql.useMutation<UpdateUserIsTypingMutation, UpdateUserIsTypingMutationVariables>(UpdateUserIsTypingDocument);
};
export const UserNameAndPictureDocument = gql`
    query UserNameAndPicture($userID: bigint!) {
  WUI_User_by_pk(id: $userID) {
    id
    first_name
    last_name
    WUI_File_Profile_Picture {
      id
      url
    }
  }
}
    `;

export function useUserNameAndPictureQuery(options: Omit<Urql.UseQueryArgs<UserNameAndPictureQueryVariables>, 'query'>) {
  return Urql.useQuery<UserNameAndPictureQuery, UserNameAndPictureQueryVariables>({ query: UserNameAndPictureDocument, ...options });
};
export const UserPictureDocument = gql`
    query UserPicture($userID: bigint!) {
  WUI_User_by_pk(id: $userID) {
    id
    WUI_File_Profile_Picture {
      id
      url
    }
  }
}
    `;

export function useUserPictureQuery(options: Omit<Urql.UseQueryArgs<UserPictureQueryVariables>, 'query'>) {
  return Urql.useQuery<UserPictureQuery, UserPictureQueryVariables>({ query: UserPictureDocument, ...options });
};
export const UserSourceInfoDocument = gql`
    query UserSourceInfo($userId: bigint!) {
  WUI_User_by_pk(id: $userId) {
    ...UserDetails
    type
    WUI_File_Profile_Picture {
      url
    }
    WUI_UserSubscriptions_aggregate(
      where: {_and: [{user_id: {_eq: $userId}}, {status: {_eq: "active"}}]}
    ) {
      aggregate {
        count
      }
    }
  }
}
    ${UserDetailsFragmentDoc}`;

export function useUserSourceInfoQuery(options: Omit<Urql.UseQueryArgs<UserSourceInfoQueryVariables>, 'query'>) {
  return Urql.useQuery<UserSourceInfoQuery, UserSourceInfoQueryVariables>({ query: UserSourceInfoDocument, ...options });
};
export const UserStripeCustomerIdDocument = gql`
    query UserStripeCustomerID($userID: bigint!) {
  WUI_User_by_pk(id: $userID) {
    stripe_customer_id
  }
}
    `;

export function useUserStripeCustomerIdQuery(options: Omit<Urql.UseQueryArgs<UserStripeCustomerIdQueryVariables>, 'query'>) {
  return Urql.useQuery<UserStripeCustomerIdQuery, UserStripeCustomerIdQueryVariables>({ query: UserStripeCustomerIdDocument, ...options });
};
export const UserSubscriptionsByUserIdDocument = gql`
    query UserSubscriptionsByUserId($userId: bigint!) {
  WUI_UserSubscription(
    where: {user_id: {_eq: $userId}}
    limit: 1
    order_by: {created_at: desc}
  ) {
    id
    expires_at
    status
    external_id
    cancel_at
  }
}
    `;

export function useUserSubscriptionsByUserIdQuery(options: Omit<Urql.UseQueryArgs<UserSubscriptionsByUserIdQueryVariables>, 'query'>) {
  return Urql.useQuery<UserSubscriptionsByUserIdQuery, UserSubscriptionsByUserIdQueryVariables>({ query: UserSubscriptionsByUserIdDocument, ...options });
};
export const VoteUserInfoDocument = gql`
    query VoteUserInfo($voteUserCategoryId: uuid!) {
  VoteUserCategory_by_pk(id: $voteUserCategoryId) {
    category_id
    VoteUser {
      started_at
      ended_at
      WUI_User {
        id
        first_name
        last_name
        username
        WUI_File_Profile_Picture {
          url
        }
      }
    }
  }
}
    `;

export function useVoteUserInfoQuery(options: Omit<Urql.UseQueryArgs<VoteUserInfoQueryVariables>, 'query'>) {
  return Urql.useQuery<VoteUserInfoQuery, VoteUserInfoQueryVariables>({ query: VoteUserInfoDocument, ...options });
};
export const VotesByVoterIdDocument = gql`
    query VotesByVoterID($voterId: bigint!) {
  Vote(where: {voter_id: {_eq: $voterId}}) {
    category_id
    user_category_id
    VoteUserCategory {
      VoteUser {
        WUI_User {
          id
          first_name
          last_name
          username
          WUI_File_Profile_Picture {
            url
          }
        }
      }
    }
  }
}
    `;

export function useVotesByVoterIdQuery(options: Omit<Urql.UseQueryArgs<VotesByVoterIdQueryVariables>, 'query'>) {
  return Urql.useQuery<VotesByVoterIdQuery, VotesByVoterIdQueryVariables>({ query: VotesByVoterIdDocument, ...options });
};
export const ChatRoomsSubscriptionDocument = gql`
    subscription ChatRoomsSubscription($filter: WUI_ChatRoom_bool_exp) {
  WUI_ChatRoom(order_by: {updated_at: desc}, limit: 1, where: $filter) {
    id
    type
    LastMessage {
      created_at
      message
    }
    WUI_ChatRoomUsers {
      id
      seen
      WUI_User {
        id
        username
        first_name
        last_name
      }
    }
  }
}
    `;

export function useChatRoomsSubscriptionSubscription<TData = ChatRoomsSubscriptionSubscription>(options: Omit<Urql.UseSubscriptionArgs<ChatRoomsSubscriptionSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<ChatRoomsSubscriptionSubscription, TData>) {
  return Urql.useSubscription<ChatRoomsSubscriptionSubscription, TData, ChatRoomsSubscriptionSubscriptionVariables>({ query: ChatRoomsSubscriptionDocument, ...options }, handler);
};
export const CheckUserIsTypingDocument = gql`
    subscription CheckUserIsTyping($roomID: bigint!, $userID: bigint!) {
  WUI_ChatRoomUser(where: {user_id: {_neq: $userID}, room_id: {_eq: $roomID}}) {
    typing
    user_id
    last_typing
    WUI_User {
      id
      username
      first_name
      last_name
      WUI_File_Profile_Picture {
        id
        url
      }
    }
  }
}
    `;

export function useCheckUserIsTypingSubscription<TData = CheckUserIsTypingSubscription>(options: Omit<Urql.UseSubscriptionArgs<CheckUserIsTypingSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<CheckUserIsTypingSubscription, TData>) {
  return Urql.useSubscription<CheckUserIsTypingSubscription, TData, CheckUserIsTypingSubscriptionVariables>({ query: CheckUserIsTypingDocument, ...options }, handler);
};
export const CountChatMessagesDocument = gql`
    subscription CountChatMessages($userID: bigint!) {
  WUI_ChatRoomMessage_aggregate(
    where: {WUI_ChatRoom: {WUI_ChatRoomUsers: {user_id: {_eq: $userID}}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

export function useCountChatMessagesSubscription<TData = CountChatMessagesSubscription>(options: Omit<Urql.UseSubscriptionArgs<CountChatMessagesSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<CountChatMessagesSubscription, TData>) {
  return Urql.useSubscription<CountChatMessagesSubscription, TData, CountChatMessagesSubscriptionVariables>({ query: CountChatMessagesDocument, ...options }, handler);
};
export const CountPricesRequestsDocument = gql`
    subscription CountPricesRequests($userID: bigint) {
  WUI_PackageRequest_aggregate(
    where: {_or: [{seller_id: {_eq: $userID}}, {requester_id: {_eq: $userID}}], status: {_eq: pending}}
  ) {
    aggregate {
      count(columns: created_at)
    }
  }
}
    `;

export function useCountPricesRequestsSubscription<TData = CountPricesRequestsSubscription>(options: Omit<Urql.UseSubscriptionArgs<CountPricesRequestsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<CountPricesRequestsSubscription, TData>) {
  return Urql.useSubscription<CountPricesRequestsSubscription, TData, CountPricesRequestsSubscriptionVariables>({ query: CountPricesRequestsDocument, ...options }, handler);
};
export const CountReviewsByOrderIdDocument = gql`
    subscription CountReviewsByOrderId($userID: bigint!, $orderId: bigint!) {
  WUI_OrderReview_aggregate(
    where: {order_id: {_eq: $orderId}, WUI_UserReview: {from_user_id: {_eq: $userID}}}
    limit: 1
  ) {
    aggregate {
      count
    }
  }
}
    `;

export function useCountReviewsByOrderIdSubscription<TData = CountReviewsByOrderIdSubscription>(options: Omit<Urql.UseSubscriptionArgs<CountReviewsByOrderIdSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<CountReviewsByOrderIdSubscription, TData>) {
  return Urql.useSubscription<CountReviewsByOrderIdSubscription, TData, CountReviewsByOrderIdSubscriptionVariables>({ query: CountReviewsByOrderIdDocument, ...options }, handler);
};
export const CountUnSeenChatRoomsDocument = gql`
    subscription CountUnSeenChatRooms($userID: bigint!) {
  WUI_ChatRoomUser_aggregate(where: {user_id: {_eq: $userID}, seen: {_eq: false}}) {
    aggregate {
      count
    }
  }
}
    `;

export function useCountUnSeenChatRoomsSubscription<TData = CountUnSeenChatRoomsSubscription>(options: Omit<Urql.UseSubscriptionArgs<CountUnSeenChatRoomsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<CountUnSeenChatRoomsSubscription, TData>) {
  return Urql.useSubscription<CountUnSeenChatRoomsSubscription, TData, CountUnSeenChatRoomsSubscriptionVariables>({ query: CountUnSeenChatRoomsDocument, ...options }, handler);
};
export const CountUnseenNotificationsDocument = gql`
    subscription CountUnseenNotifications($userId: bigint!) {
  WUI_Notification_aggregate(
    where: {seen: {_is_null: true}, user_id: {_eq: $userId}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

export function useCountUnseenNotificationsSubscription<TData = CountUnseenNotificationsSubscription>(options: Omit<Urql.UseSubscriptionArgs<CountUnseenNotificationsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<CountUnseenNotificationsSubscription, TData>) {
  return Urql.useSubscription<CountUnseenNotificationsSubscription, TData, CountUnseenNotificationsSubscriptionVariables>({ query: CountUnseenNotificationsDocument, ...options }, handler);
};
export const GetChatUserByUserIdDocument = gql`
    subscription GetChatUserByUserId($userID: bigint!, $roomID: bigint!) {
  WUI_ChatRoomUser(where: {user_id: {_eq: $userID}, room_id: {_eq: $roomID}}) {
    seen
  }
}
    `;

export function useGetChatUserByUserIdSubscription<TData = GetChatUserByUserIdSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetChatUserByUserIdSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<GetChatUserByUserIdSubscription, TData>) {
  return Urql.useSubscription<GetChatUserByUserIdSubscription, TData, GetChatUserByUserIdSubscriptionVariables>({ query: GetChatUserByUserIdDocument, ...options }, handler);
};
export const GetUserLastSeenDocument = gql`
    subscription GetUserLastSeen($id: bigint!) {
  wui_userlastseen(where: {id: {_eq: $id}}) {
    last_seen_date
  }
}
    `;

export function useGetUserLastSeenSubscription<TData = GetUserLastSeenSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetUserLastSeenSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<GetUserLastSeenSubscription, TData>) {
  return Urql.useSubscription<GetUserLastSeenSubscription, TData, GetUserLastSeenSubscriptionVariables>({ query: GetUserLastSeenDocument, ...options }, handler);
};
export const NotificationsDocument = gql`
    subscription Notifications($userId: bigint!, $offset: Int!, $limit: Int!) {
  WUI_Notification(
    offset: $offset
    limit: $limit
    order_by: {created_at: desc}
    where: {user_id: {_eq: $userId}}
  ) {
    id
    created_at
    message
    metadata
    seen
    title
  }
}
    `;

export function useNotificationsSubscription<TData = NotificationsSubscription>(options: Omit<Urql.UseSubscriptionArgs<NotificationsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<NotificationsSubscription, TData>) {
  return Urql.useSubscription<NotificationsSubscription, TData, NotificationsSubscriptionVariables>({ query: NotificationsDocument, ...options }, handler);
};
export const OrderByIdSubscriptionDocument = gql`
    subscription OrderByIDSubscription($orderID: bigint!) {
  WUI_Order_by_pk(id: $orderID) {
    id
    package_type
    title
    description
    price
    status
    created_by
    created_at
    items
    seller_id
    buyer_id
    updated_at
    WUI_User_Buyer {
      id
      first_name
      last_name
      username
      WUI_File_Profile_Picture {
        id
        url
      }
    }
    WUI_User_Seller {
      id
      first_name
      last_name
      username
      WUI_File_Profile_Picture {
        id
        url
      }
    }
    WUI_Payment {
      price
    }
    metadata
  }
}
    `;

export function useOrderByIdSubscriptionSubscription<TData = OrderByIdSubscriptionSubscription>(options: Omit<Urql.UseSubscriptionArgs<OrderByIdSubscriptionSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<OrderByIdSubscriptionSubscription, TData>) {
  return Urql.useSubscription<OrderByIdSubscriptionSubscription, TData, OrderByIdSubscriptionSubscriptionVariables>({ query: OrderByIdSubscriptionDocument, ...options }, handler);
};