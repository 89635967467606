import { useState, useEffect } from 'react'
import Menu from 'components/Menu'
import Link from 'next/link'
import { useAuth } from 'clients/auth.client'
import { useGlobalModal } from 'modals/GlobalModal'
import BackgroundImage from 'components/BackgroundImage'
import ShareModal from 'modals/ShareModal'
import { useFeatureTesting } from 'stores/featureTesting.store'
import {
  useCountUnSeenChatRoomsSubscription,
  useCountUnseenNotificationsSubscription,
} from 'generated/graphql'
import { PUBLIC_PROFILE_URL } from 'globalConstants'
import copyTextToClipboard from 'utils/copyTextToClpboard'
import { useToast } from 'components/ToastMessage'
import useSound from 'use-sound'
import Image from 'next/legacy/image'
import InboxIcon from 'assets/icons/inbox.svg'
import NotificationIcon from 'assets/icons/notifications.svg'
import ShareIcon from 'assets/icons/share.svg'
import NavIcon from 'assets/icons/nav.svg'
import { hasFlag } from 'country-flag-icons'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import Logo from 'components/Logo'
import { PLACEHOLDER_EMPTY_AVATAR } from 'config/constants'
import ProBadge from 'components/ProBadge'

const Header = () => {
  const [menu, setMenu] = useState(false)
  const { isAuthenticated, account } = useAuth()
  const searchFeatureTesting = useFeatureTesting('search')
  const proFeatureTesting = useFeatureTesting('pro')

  if (!isAuthenticated) return null

  return (
    <>
      <header
        data-testid="page-header"
        className="sticky top-0 bg-white z-3 h-14 backdrop-filter backdrop-blur bg-opacity-50"
      >
        <div className="max-w-screen-2xl mx-auto w-full h-full flex justify-between items-center">
          <div className="flex items-center">
            {/* Menu */}
            <button
              onClick={() => {
                setMenu((current) => !current)
              }}
              className="py-5 px-3 sm:p-5"
              data-testid="button-menu"
            >
              <NavIcon width="20" height="20" />
            </button>

            {/* Logo */}
            <Link href="/" className="inline-block">
              <Logo />
              {proFeatureTesting.enabled && !!account.subscription?.active && (
                <ProBadge $inverted />
              )}
            </Link>

            {account?.country &&
              account.country !== 'US' &&
              hasFlag(account.country) && (
                <div>{getUnicodeFlagIcon(account.country)}</div>
              )}
          </div>
          <div className="ml-3 sm:ml-0 mr-4">
            <div className="flex justify-center items-center space-x-1.5">
              <ShareLink />

              <NotificationsIcons />

              {searchFeatureTesting.enabled && (
                <Link href="/search" legacyBehavior passHref>
                  <a className="inline-block">
                    <Image
                      src="/assets/icons/search.svg"
                      alt="Search"
                      className="m-2 mt-3"
                      width="22"
                      height="22"
                    />
                  </a>
                </Link>
              )}

              <Link
                href={
                  account?.username
                    ? `/user/${account.username}`
                    : `/profile/edit`
                }
                data-testid="header-picture"
              >
                <BackgroundImage
                  image={account?.picture || PLACEHOLDER_EMPTY_AVATAR}
                  width={30}
                  height={30}
                  className="rounded-full"
                />
              </Link>
            </div>
          </div>
        </div>
      </header>

      <Menu
        isOpen={menu}
        toggle={() => {
          setMenu(false)
        }}
      />
    </>
  )
}

export default Header

const ShareLink = () => {
  const { isAuthenticated, account } = useAuth()
  const toast = useToast()
  const modal = useGlobalModal()

  if (!isAuthenticated || !account.hasCompletedProfile || !account.username) {
    return null
  }

  return (
    <>
      <div className="flex gap-2">
        <button
          className="text-sm hidden sm:block"
          onClick={() => {
            copyTextToClipboard(account?.username || '')

            toast.notify({
              message: 'Your link has been copied!',
              title: 'Copied!',
              type: 'success',
            })
          }}
        >
          {PUBLIC_PROFILE_URL}
          <span className="text-primary">/</span>
          <span className="font-bold">{account?.username}</span>
        </button>
        <button
          onClick={() =>
            modal.open(
              <ShareModal
                profile={{
                  firstName: account.firstName,
                  lastName: account.lastName,
                  id: account.id,
                  picture: account.picture,
                  username: account.username,
                }}
              />,
              {
                $size: 'sm',
              },
            )
          }
        >
          <ShareIcon height="25" width="25" />
        </button>
      </div>{' '}
    </>
  )
}

const NotificationsIcons = () => {
  const { account } = useAuth()
  const [notificationState] = useCountUnseenNotificationsSubscription({
    variables: {
      userId: account?.id,
    },
  })
  const [chatMessagesState] = useCountUnSeenChatRoomsSubscription({
    variables: {
      userID: account?.id,
    },
  })
  const notificationCount =
    notificationState.data?.WUI_Notification_aggregate.aggregate?.count || 0
  const chatMessagesCount =
    chatMessagesState.data?.WUI_ChatRoomUser_aggregate.aggregate?.count || 0
  const [prevCountNotification, setPrevCountNotification] =
    useState(notificationCount)
  const [prevCountChatMessages, setPrevChatMessages] =
    useState(chatMessagesCount)
  const [play] = useSound('/assets/swiftly-610.mp3')
  const [loaded, setLoaded] = useState(false)
  const showDot = !!notificationCount
  const showInboxDot = !!chatMessagesCount

  useEffect(() => {
    const canPlay =
      loaded &&
      (notificationCount > prevCountNotification ||
        chatMessagesCount > prevCountChatMessages)

    if (!loaded && !notificationState.fetching && !chatMessagesState.fetching) {
      setLoaded(true)
    }

    if (canPlay) {
      play()
    }

    setPrevCountNotification(
      notificationState.data?.WUI_Notification_aggregate.aggregate?.count || 0,
    )
    setPrevChatMessages(
      chatMessagesState.data?.WUI_ChatRoomUser_aggregate.aggregate?.count || 0,
    )
  }, [
    notificationState.data,
    notificationState.fetching,
    chatMessagesState.data,
    chatMessagesState.fetching,
  ])

  return (
    <>
      <Link href="/notifications#notifications" className="relative">
        <NotificationIcon width="30" height="30" />
        {showDot && ( // just for testing
          <div className="-top-0.5 left-2 ml-1.5 rounded-full absolute border-2 border-white">
            <div className="bg-primary w-2 h-2 rounded-full" />
          </div>
        )}
      </Link>

      <Link href="/notifications#inbox" className="relative hidden sm:block">
        <InboxIcon width="30" height="30" className="mb-1" />
        {showInboxDot && ( // just for testing
          <div
            className="-top-0.5 left-2 ml-1.5 rounded-full 
         border-2 absolute border-white"
          >
            <div className="bg-primary w-2 h-2 rounded-full" />
          </div>
        )}
      </Link>
    </>
  )
}
