import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef } from 'react'

type TidioIdentity = {
  id: string
  email: string
  name: string
  phone?: string
}

export type TidioChatApi = {
  on: (event: 'ready', cb: () => void) => void
  open: () => void
  setVisitorData: (
    identity: Omit<TidioIdentity, 'id'> & {
      distinct_id: string
    },
  ) => void
}

const useTidio = () => {
  const router = useRouter()
  const clientRef = useRef<TidioChatApi | null>(null)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(document as any).tidioChatLang = router.locale
  }, [router.locale])

  useEffect(() => {
    function onTidioChatApiReady() {
      const { tidioChatApi } = window

      clientRef.current = tidioChatApi
    }

    if (window.tidioChatApi) {
      clientRef.current = window.tidioChatApi

      window.tidioChatApi.on('ready', onTidioChatApiReady)
    } else {
      document.addEventListener('tidioChat-ready', onTidioChatApiReady)
    }

    return () => {
      document.removeEventListener('tidioChat-ready', onTidioChatApiReady)
    }
  }, [])

  const open = useCallback(() => {
    if (clientRef.current) {
      clientRef.current.open()

      window.tidioChatApi.display(true)
      window.tidioChatApi.chatDisplay(true)
    } else {
      window.open('https//influexer.com/customer-service', '_blank')
    }
  }, [clientRef.current])

  const identify = ({ id, email, name, phone }: TidioIdentity) => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(document as any).tidioIdentify = {
      distinct_id: id,
      email,
      name,
      phone,
    }

    if (clientRef.current) {
      clientRef.current.setVisitorData({
        distinct_id: id,
        email,
        name,
        phone,
      })
    }
  }

  return {
    open,
    identify,
  }
}

export default useTidio
