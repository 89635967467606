import appendQuery from 'append-query'

const appendQueryToCallbackUrl = (
  url: string,
  {
    callbackUrl,
    ...query
  }: appendQuery.Query & {
    callbackUrl: string
  },
  options?: appendQuery.Options,
) =>
  appendQuery(
    url,
    {
      ...query,
      callbackUrl: appendQuery(callbackUrl, query),
    },
    options,
  )

export default appendQueryToCallbackUrl
