import React, { useRef } from 'react'
import cc from 'classcat'
import useIsMounted from 'hooks/useIsMounted'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

import styles from './index.module.scss'

const cache: Record<string, boolean> = {}

type Props = {
  image: string
  width?: number | string
  height?: number | string
  className?: string
  children?: any
  style?: any
  innerRef?: any
  loadHandler?: boolean
  alt?: string
  $type?: 'div' | 'image'
  $noTransition?: boolean
  onClick?: () => void
}

const BackgroundImage = ({
  image,
  width,
  height,
  className,
  children,
  style,
  innerRef,
  loadHandler,
  alt,
  $type,
  $noTransition,
  onClick,
}: Props) => {
  const isMounted = useIsMounted()
  // prevents flickering when we re-render the component
  const hasBeenLoadedBefore = !!cache[image]
  const [loaded, setLoaded] = React.useState(hasBeenLoadedBefore)
  const transitionRef = useRef(null)

  React.useEffect(() => {
    if (loadHandler && !hasBeenLoadedBefore) {
      const img = new Image()

      img.onload = () => {
        cache[image] = true
        if (isMounted.current) {
          setLoaded(true)
        }
      }

      img.src = image
      img.referrerPolicy = 'no-referrer'
    }
  }, [loadHandler, hasBeenLoadedBefore, image])

  const props = {
    className: cc([
      styles.root,
      'block bg-gray-400',
      {
        'transition-all': !$noTransition,
        'hover:opacity-90 hover:cursor-pointer': !!onClick,
      },
      className,
    ]),
    style: {
      ...style,
      backgroundImage: !loadHandler || loaded ? `url(${image})` : 'none',
      width,
      height,
    },
    ref: innerRef,
  }

  const element =
    $type === 'div' || ($type == null && children) ? (
      <div {...props} onClick={onClick}>
        {children}
      </div>
    ) : (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        alt={alt || 'Background Image'}
        {...props}
        src="/assets/dot.png"
        referrerPolicy="no-referrer" // solves issue with google images no loading
        onClick={onClick}
      />
    )

  if (!loadHandler) {
    return element
  }

  return (
    <SwitchTransition>
      <CSSTransition
        key={!loadHandler || loaded ? 'loaded' : 'not-loaded'}
        classNames={styles}
        timeout={200}
        appear
        nodeRef={transitionRef}
      >
        {element}
      </CSSTransition>
    </SwitchTransition>
  )
}

BackgroundImage.defaultProps = {
  style: {},
  loadHandler: true,
}

export default BackgroundImage
