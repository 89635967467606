import { env } from 'env/client.mjs'

export const getEnv = (key: string, safe?: boolean) => {
  const k = key.toUpperCase()
  const value = (env as any)[k] || (env as any)[`NEXT_PUBLIC_${k}`]

  if (!safe && value == null) {
    throw new Error(`Missing required environment variable ${key}`)
  }

  return value as string
}

export const getAllEnvs = () => process.env
