import Cookies from 'js-cookie'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { env } from 'env/client.mjs'
import logger from './logger'
import { v4 as uuidv4 } from 'uuid'

const FINGERPRINT_KEY = 'FINGERPRINT_VISITOR_ID'

const getCachedId = (): string | null => {
  const stored = Cookies.get(FINGERPRINT_KEY)

  if (stored) {
    return stored
  }

  return null
}

const setCachedId = (id: string) => {
  Cookies.set(FINGERPRINT_KEY, id, {
    expires: 30,
  })
}

const getVisitorId = async () => {
  try {
    if (env.NEXT_PUBLIC_FINGERPRINT_API_KEY) {
      // const fp = await FingerprintJS.load({
      //   apiKey: env.NEXT_PUBLIC_FINGERPRINT_API_KEY,
      // })
      const fp = await FingerprintJS.load()

      // TODO: Check result values such as 'confidence' and 'visitorFound'
      const result = await fp.get()

      return result.visitorId
    }
  } catch (error) {
    logger.warn('Error while getting fingerprint', {
      error,
    })
  }

  return uuidv4()
}

const getFingerPrintId = async (): Promise<string> => {
  const cached = getCachedId()

  if (cached) {
    return cached
  }

  const visitorId = await getVisitorId()

  setCachedId(visitorId)

  return visitorId
}

export default getFingerPrintId
