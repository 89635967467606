import { useGlobalStore } from 'stores/global.store'
import LogoInfluexer from 'assets/logo.svg'
import Image from 'next/legacy/image'
import patternMatching from 'utils/patternMatching'

const LogoIconoPay = '/assets/logo-iconopay.png'

type Size = 'sm' | 'md'

const Logo = ({ $size }: { $size: Size }) => {
  const { platform } = useGlobalStore()

  return (
    <>
      {platform === 'influexer' && (
        <LogoInfluexer
          width={patternMatching<Size, number>([
            ['sm', 140],
            ['md', 180],
          ])($size)}
          height={patternMatching<Size, number>([
            ['sm', 40],
            ['md', 50],
          ])($size)}
          className="inline-block"
          data-testid="logo-influexer"
        />
      )}
      {platform === 'iconopay' && (
        <Image
          src={LogoIconoPay}
          width={patternMatching<Size, number>([
            ['sm', 140],
            ['md', 180],
          ])($size)}
          height={patternMatching<Size, number>([
            ['sm', 30],
            ['md', 40],
          ])($size)}
          alt="IconoPay"
          data-testid="logo-iconopay"
        />
      )}
    </>
  )
}

Logo.defaultProps = {
  $size: 'sm',
}

export default Logo
