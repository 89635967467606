import { createContext, useContext } from 'react'
import { event } from 'nextjs-google-analytics'
import getFingerPrintId from 'utils/fingerprint'
import useLogger from './useLogger'
import { trpcVanillaClient } from 'utils/trpc'

type ContextValue = {
  profileView: (userProfileID: number) => Promise<void>
  linkClick: (userProfileID: number, linkId: number) => Promise<void>
  beginCheckout: (data: {
    totalCost: number
    id: number | string
    name: string
    price: number
    from?: string
  }) => void
  purchase: (data: {
    transactionID: string | number
    amount: number
    id: number | string
    name: string
    price: number
    from?: string
  }) => void
  event: (action: string, options?: Record<string, any>) => void
}

const Context = createContext<ContextValue>({
  profileView: () => Promise.resolve(),
  linkClick: () => Promise.resolve(),
  beginCheckout: () => undefined,
  purchase: () => undefined,
  event: () => undefined,
})

export const AnalyticsSessionProvider = ({ children }: { children: any }) => {
  const { log } = useLogger()

  const profileView = async (userProfileID: number) => {
    try {
      const visitorId = await getFingerPrintId()

      if (!visitorId) {
        return log('warning', `Analytics Profile View missing 'visitorId'`)
      }

      await trpcVanillaClient.analytics.profileView.mutate({
        targetUserId: userProfileID,
        visitorId,
      })
    } catch (error) {
      log('error', `Analytics Profile View`, { error })
    }
  }

  const linkClick = async (userProfileID: number, linkId: number) => {
    try {
      const visitorId = await getFingerPrintId()

      if (!visitorId) {
        return log('warning', `Analytics Link Click missing 'visitorId'`)
      }

      await trpcVanillaClient.analytics.linkClick.mutate({
        targetUserId: userProfileID,
        visitorId,
        linkId,
      })
    } catch (error) {
      log('error', `Analytics Link Click`, { error })
    }
  }

  const beginCheckout = ({
    totalCost,
    id,
    name,
    price,
    from,
  }: {
    totalCost: number
    id: number | string
    name: string
    price: number
    from?: string
  }) => {
    event('begin_checkout', {
      currency: 'usd',
      value: totalCost,
      items: [
        {
          item_id: id,
          item_name: name,
          price,
        },
      ],
      from,
    })
  }

  const purchase = ({
    transactionID,
    amount,
    id,
    name,
    price,
    from,
  }: {
    transactionID: string | number
    amount: number
    id: number | string
    name: string
    price: number
    from?: string
  }) => {
    event('purchase', {
      currency: 'usd', // TODO: Make it dynamic
      transaction_id: transactionID,
      value: amount,
      items: [
        {
          item_id: id,
          item_name: name,
          price,
        },
      ],
      from,
    })
  }

  return (
    <Context.Provider
      value={{ profileView, linkClick, beginCheckout, purchase, event }}
    >
      {children}
    </Context.Provider>
  )
}

const useAnalytics = () => useContext(Context)

export default useAnalytics
