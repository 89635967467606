import React, { useState } from 'react'
import Button from 'components/Button'
import { PUBLIC_PROFILE_URL } from 'globalConstants'
import IconCopy from 'assets/icons/copy-paste.svg'
import copyTextToClipboard from 'utils/copyTextToClpboard'
import ms from 'ms'
import cc from 'classcat'
import useIsMounted from 'hooks/useIsMounted'

function ShareInputField({ username }: { username: string }) {
  const [showCopied, setShowCopied] = useState(false)
  const isMounted = useIsMounted()

  return (
    <>
      <div
        className="rounded-md border py-2 px-3 bg-white placeholder-gray-600 border-gray-600"
        data-testid="component-ShareInputField"
      >
        <div className="flex w-full items-center justify-between">
          <div className="overflow-hidden truncate">
            <span>{PUBLIC_PROFILE_URL}</span>
            <span className="text-primary font-bold">/</span>
            <span className="flex-grow border-none font-bold">{username}</span>
          </div>
          <Button
            $size="sm"
            onClick={() => {
              setShowCopied(copyTextToClipboard(username || ''))
              setTimeout(() => {
                if (isMounted.current) {
                  setShowCopied(false)
                }
              }, ms('5s'))
            }}
            type="button"
          >
            <IconCopy className="fill-white" width={22} />
          </Button>
        </div>
      </div>
      <div
        className={cc([
          'text-right w-full text-sm text-gray-700 px-3',
          {
            'opacity-0': !showCopied,
            'opacity-1': showCopied,
          },
        ])}
      >
        Copied!
      </div>
    </>
  )
}

export default ShareInputField
