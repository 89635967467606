import cc from 'classcat'
import IconX from 'assets/icons/x.svg'

export type Size = 'full' | 'md' | 'sm'

export type BodySize = 'auto' | 'full'

type Props = {
  isOpen: boolean
  toggle: () => void
  children: React.ReactNode
  $size: Size
  $bodySize: BodySize
}

const Modal = ({ isOpen, toggle, children, $size, $bodySize }: Props) => {
  if (!isOpen) {
    return null
  }

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-70 z-9 backdrop-filter backdrop-blur" />
      <div className="fixed inset-0 z-9 m-3">
        <div
          className={cc([
            'absolute bg-white z-12 overflow-hidden flex flex-col rounded-md',
            {
              'inset-0 max-h-full md:overflow-y-auto': $size === 'full',
              'w-11/12 md:w-1/2 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2':
                $size === 'md',
              'max-w-2xl w-full max-h-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2':
                $size === 'sm',
            },
          ])}
        >
          <button
            type="button"
            onClick={() => {
              toggle()
            }}
            className="absolute mt-2 ml-2 p-2.5 sticky top-0"
          >
            <IconX width="20" />
          </button>
          <div
            className={cc([
              'relative overflow-auto',
              {
                'mx-auto py-10 h-full md:w-1/2 lg:w-2/5 xl:w-1/3':
                  $bodySize === 'auto' && $size === 'full',
                'h-screen': $bodySize === 'full',
              },
            ])}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

Modal.defaultProps = {
  $size: 'full',
  $bodySize: 'auto',
}

export default Modal
