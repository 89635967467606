// TODO: This got pulled from CRA + React Router, we need to find a better way for nextjs

import { useRouter } from 'next/router'
import { useEffect } from 'react'

type Props = {
  to: string
}

const Redirect = ({ to }: Props) => {
  const router = useRouter()

  useEffect(() => {
    setTimeout(() => {
      router.push(to)
    })
  }, [])

  return null
}

export default Redirect
