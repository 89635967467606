import Image from 'next/legacy/image'
import { ReactNode } from 'react'

export type Size = 'xs' | 'sm' | 'md' | 'lg'

type Type = 'dots' | 'logo'

type Color = 'primary' | 'white'
interface Props {
  $size: Size
  $color: Color
  $type: Type
  context?: string
  children?: ReactNode
}

const widthMap = {
  xs: 25,
  sm: 35,
  md: 60,
  lg: 100,
}

const heightMap = {
  xs: 25,
  sm: 35,
  md: 60,
  lg: 100,
}

const heightMapDots = {
  xs: 11,
  sm: 16,
  md: 27,
  lg: 45,
}

export const LoadingSpinner = ({
  $size,
  $color,
  $type,
}: {
  $size: Size
  $color: Color
  $type: Type
}) => {
  const loadingWhite = '/assets/loading-white.gif'
  const loadingPrimary = '/assets/loading-primary.gif'
  const loadingLogo = '/assets/Influexer-Icon-GIF.gif'

  return (
    <Image
      src={
        $type === 'dots'
          ? $color === 'primary'
            ? loadingPrimary
            : loadingWhite
          : loadingLogo
      }
      alt="Loading"
      className="inline-block"
      width={widthMap[$size]}
      height={$type === 'logo' ? heightMap[$size] : heightMapDots[$size]}
    />
  )
}

const Loading = ({ $size, $color, $type, context, children }: Props) => {
  if (context && process.env.NEXT_PUBLIC_DEBUG) {
    // eslint-disable-next-line no-console
    console.debug(`Loading Component :: ${context}`)
  }

  return (
    <div className="text-center p-2 py-24">
      <LoadingSpinner $size={$size} $color={$color} $type={$type} />
      {children && (
        <div className="body-2 text-green" style={{ marginTop: -10 }}>
          {children}
        </div>
      )}
    </div>
  )
}

Loading.defaultProps = {
  $size: 'md',
  $color: 'primary',
  $type: 'dots',
}

export default Loading
