import snakeCase from 'lodash/fp/snakeCase'
import { createContext, useState } from 'react'
import { getEnv } from 'utils/env'

type FeatureRecord = Record<string, boolean>

type Mode = 'env' | 'abtest'

const Context = createContext<{
  mode: Mode
  features: FeatureRecord | null
  setFeatures: (newFeatures: FeatureRecord) => void
}>({
  mode: 'env',
  features: null,
  setFeatures: () => undefined,
})

type ProviderProps = {
  children: any
  mode: Mode
}

export const FeatureTestingProvider = ({ children, mode }: ProviderProps) => {
  const [features, setFeatures] = useState<FeatureRecord | null>(null)

  // TODO: "fetching" and "features" are states meant to be use for external feature testing (e.g. features stored in a BE or A/B Testing service)
  if (mode === 'abtest') {
    throw new Error(`Mode "${mode}" not supported`)
  }

  return (
    <Context.Provider
      value={{
        mode,
        features,
        setFeatures,
      }}
    >
      {children}
    </Context.Provider>
  )
}

FeatureTestingProvider.defaultProps = {
  mode: 'env',
}

type UseFeatureTesting = {
  fetching: boolean
  enabled: boolean
}

const isFeatureEnabled = (feature: string): boolean => {
  const featureValue = getEnv(
    `FEATURE_TESTING_${snakeCase(feature).toUpperCase()}`,
  )
  const enabled =
    !!featureValue &&
    `${featureValue}` !== '0' &&
    `${featureValue}` !== 'false' &&
    `${featureValue}` !== 'FALSE'

  return enabled
}

export const useFeatureTesting = (feature: string): UseFeatureTesting => {
  return {
    fetching: false, // TODO: This is for runtime feature testing
    enabled: isFeatureEnabled(feature),
  }
}
