import type { ParsedUrlQuery } from 'querystring'
import type { Query } from 'append-query'
import getSingleQueryParam from './getSingleQueryParam'

const routerQueryToAppendQuery = (routerQuery: ParsedUrlQuery): Query =>
  Object.keys(routerQuery).reduce(
    (obj, key) => ({
      ...obj,
      [key]: getSingleQueryParam(routerQuery[key]) || null,
    }),
    {},
  )

export default routerQueryToAppendQuery
