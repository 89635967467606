import cc from 'classcat'
import RouterLink from 'next/link'

type Type = 'primary' | 'secondary' | 'logout'

type Props = {
  to: string
  children: React.ReactNode
  className?: string
  active?: boolean
  $type?: Type
  ['data-testid']?: string
  onClick?: () => void
  target?: HTMLAnchorElement['target']
}

const classMap: {
  [key: string]: {
    active: string
    notActive: string
  }
} = {
  primary: {
    active: 'text-primary font-medium',
    notActive: 'text-black hover:text-gray-700',
  },
  secondary: {
    active: 'text-primary font-medium ml-6',
    notActive: 'text-gray-600 hover:text-gray-800 ml-6',
  },
  logout: {
    active: 'text-primary font-medium',
    notActive: 'text-gray-600 hover:text-gray-800',
  },
}

const Link = ({ to, children, className, active, $type, ...rest }: Props) => {
  return (
    <RouterLink
      href={to}
      {...rest}
      className={cc([
        'inline-block text-left',
        $type && classMap?.[$type]?.[active ? 'active' : 'notActive'],
        className,
      ])}
    >
      {children}
    </RouterLink>
  )
}

Link.defaultProps = {
  $type: 'primary',
}

export default Link
