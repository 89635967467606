import { useEffect, useRef } from 'react'

type UserChoice = {
  outcome: 'accepted' | 'dismissed'
  platform: string
}

interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[]
  readonly userChoice: Promise<UserChoice>
  prompt(): Promise<UserChoice>
}

export function useAddToHomescreenPrompt(
  onReady: () => void,
): [IBeforeInstallPromptEvent | null, () => Promise<UserChoice>] {
  const prompt = useRef<IBeforeInstallPromptEvent | null>(null)

  const promptToInstall = (): Promise<UserChoice> => {
    if (prompt.current) {
      return prompt.current.prompt()
    }

    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event',
      ),
    )
  }

  useEffect(() => {
    const ready = (e: IBeforeInstallPromptEvent) => {
      e.preventDefault()

      prompt.current = e

      onReady()
    }

    window.addEventListener(
      'beforeinstallprompt',
      ready as EventListenerOrEventListenerObject,
    )

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        ready as EventListenerOrEventListenerObject,
      )
    }
  }, [onReady])

  return [prompt.current, promptToInstall]
}
