// src/pages/_app.tsx
import 'swiper/css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'tailwindcss/tailwind.css'
import 'react-tooltip/dist/react-tooltip.css'
import 'react-input-range/lib/css/index.css'
import 'react-phone-input-2/lib/bootstrap.css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'nprogress/nprogress.css'
import '../styles/globals.scss'
import {
  SessionProvider as NextAuthSessionProvider,
  useSession,
} from 'next-auth/react'
import { GlobalModalProvider, useGlobalModal } from 'modals/GlobalModal'
import { ToastProvider } from 'components/ToastMessage'
import {
  LoadingScreenProvider,
  useLoadingScreen,
} from 'components/LoadingScreen'
import { FeatureTestingProvider } from 'stores/featureTesting.store'
import { getEnv } from 'utils/env'
import { PropsWithChildren, useEffect, useState } from 'react'
import AuthProvider, { useAuth } from 'clients/auth.client'
import { useClient } from 'urql'
import {
  UpdateLastSeenDocument,
  UpdateLastSeenMutation,
} from 'generated/graphql'
import ms from 'ms'
import { useFeatureTesting } from 'stores/featureTesting.store'
import { useToast } from 'components/ToastMessage'
import PopUpTermsAndConditions from 'components/PopUpTermsAndConditions'
import { env } from 'env/client.mjs'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import { GraphqlProvider } from 'clients/graphql.client'
import { AnalyticsSessionProvider } from 'hooks/useAnalytics'
import { GoogleAnalytics, usePageViews } from 'nextjs-google-analytics'
import Loading from 'components/Loading'
import PageLayout from 'layouts/PageLayout'
import combineComponents from 'utils/combineComponents'
import { trpc } from 'utils/trpc'
import DetectDeviceConnection from 'components/DetectDeviceConnection'
import Router, { useRouter } from 'next/router'
import { Analytics } from '@vercel/analytics/react'
import Head from 'next/head'
import Script from 'next/script'
import { appWithTranslation } from 'next-i18next'
import NProgress from 'nprogress'
import GlobalStoreProvider, { useGlobalStore } from 'stores/global.store'
import Redirect from 'components/Redirect'
import cc from 'classcat'
import patternMatching from 'utils/patternMatching'
import { H } from 'highlight.run'
import { ErrorBoundary } from '@highlight-run/react'
import Cookies from 'js-cookie'
import updateLocale from 'dayjs/plugin/updateLocale'
import 'dayjs/locale/es'
import appendQuery from 'append-query'
import routerQueryToAppendQuery from 'utils/routerQueryToAppendQuery'
import { ref, onValue } from 'firebase/database'
import { database } from 'clients/firebase.client'
import { AddToHomeScreen } from 'components/AddToHomeScreen/AddToHomeScreen'
import posthog from 'posthog-js'
import { PostHogProvider, usePostHog } from 'posthog-js/react'

const PUBLIC_ROUTES = [
  '/auth/login',
  '/auth/signup',
  '/auth/verify-request',
  '/auth/invalid-email',
  '/[username]',
  '/user/[username]',
  '/vote/[voteUserCategoryId]',
  '/vote/[voteUserCategoryId]/details',
  '/vote/results', // Note: needs to be public since we need to set IconoPay as platform and redirect from that page if the user is anonymous
  '/terms-and-conditions',
  '/privacy-policy',
]

if (env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID) {
  H.init(env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID, {
    tracingOrigins: true,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        // insert full or partial urls that you don't want to record here
      ],
    },
    version: env.NEXT_PUBLIC_COMMIT_HASH,
  })
}

if (typeof window !== 'undefined' && env.NEXT_PUBLIC_POSTHOG_KEY) {
  posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
    ui_host: 'https://us.posthog.com',
    person_profiles: 'identified_only',
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug()
    },
  })
}

// Note: do not remove since we need it at the app level
dayjs.extend(updateLocale)
dayjs.extend(calendar)

dayjs.updateLocale('es', {
  calendar: {
    lastDay: '[AYER at] LT',
    sameDay: '[HOY at] LT',
    nextDay: '[Tomorrow at] LT',
    lastWeek: '[last] dddd [at] LT',
    nextWeek: 'dddd [at] LT',
    sameElse: 'L',
  },
})
;(Router as any).onRouteChangeStart = () => {
  NProgress.start()
}
;(Router as any).onRouteChangeComplete = () => NProgress.done()
;(Router as any).onRouteChangeError = () => NProgress.done()

if (typeof window !== 'undefined' && typeof document !== 'undefined') {
  if (env.NEXT_PUBLIC_RAYGUN_API_KEY) {
    import('raygun4js').then(({ default: rg4js }) => {
      rg4js('apiKey', env.NEXT_PUBLIC_RAYGUN_API_KEY as string)
      rg4js('enableCrashReporting', true)
    })
  }

  if (env.NEXT_PUBLIC_CLARITY_KEY) {
    import('clarity-js').then(({ clarity }) => {
      clarity.start({
        projectId: env.NEXT_PUBLIC_CLARITY_KEY,
        upload: 'https://m.clarity.ms/collect',
        track: true,
        content: true,
      })
    })
  }
}

// AppType<{ session?: Session | null }>
const MyApp = ({ Component, pageProps: { session, ...pageProps } }: any) => {
  const router = useRouter()

  useEffect(() => {
    dayjs.locale(router.locale)
  }, [router.locale])

  return (
    <>
      <ErrorBoundary>
        {env.NEXT_PUBLIC_CLARITY_KEY && (
          <Script
            id="clarity-js"
            dangerouslySetInnerHTML={{
              __html: `(function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "${env.NEXT_PUBLIC_CLARITY_KEY}");`,
            }}
          />
        )}

        {env.NEXT_PUBLIC_TIDIO_KEY && (
          <Script
            src={`//code.tidio.co/${env.NEXT_PUBLIC_TIDIO_KEY}.js`}
            async
          />
        )}

        {env.NEXT_PUBLIC_GA_MEASUREMENT_ID && <GoogleAnalytics />}
        {process.env.NODE_ENV === 'production' && <Analytics />}

        <NextAuthSessionProvider session={session} refetchOnWindowFocus={false}>
          <AppWithLoader Component={Component} pageProps={pageProps} />
        </NextAuthSessionProvider>

        <DetectDeviceConnection />
      </ErrorBoundary>
    </>
  )
}

export default trpc.withTRPC(MyApp)

const AppWithLoader = appWithTranslation(({ Component, pageProps }: any) => {
  const session = useSession()
  const router = useRouter()

  if (!pageProps.ssr && session.status === 'loading') {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-25">
        <Loading $size="lg" $type="logo" />
      </div>
    )
  }

  if (
    session.status !== 'authenticated' &&
    !PUBLIC_ROUTES.includes(router.route)
  ) {
    return (
      <Redirect
        to={`/auth/login?callbackUrl=${
          !['/logout'].includes(router.route) ? router.asPath : '/'
        }`}
      />
    )
  }

  return (
    <AppContextProvider>
      <AppContainer Component={Component} pageProps={pageProps} />
    </AppContextProvider>
  )
})

const PostHogProviderWithClient = ({ children }: PropsWithChildren) => (
  <PostHogProvider client={posthog}>{children}</PostHogProvider>
)

const AppContextProvider = combineComponents(
  AuthProvider,
  GlobalStoreProvider,
  GraphqlProvider,
  AnalyticsSessionProvider,
  PostHogProviderWithClient,
  FeatureTestingProvider,
  LoadingScreenProvider,
  ToastProvider,
  GlobalModalProvider,
)

// TODO: This has to be converted into a Layout
const AppContainer = ({ Component, pageProps }: any) => {
  usePageViews()

  const router = useRouter()
  const loadingScreen = useLoadingScreen()
  const modal = useGlobalModal()
  const { account, isAuthenticated, impersonator } = useAuth()
  const client = useClient()
  const lastSeenFeatureTesting = useFeatureTesting('lastSeen')
  const { platformFriendly } = useGlobalStore()

  useEffect(() => {
    const cookieLocale = Cookies.get('NEXT_LOCALE')

    if (!cookieLocale) {
      const locale = account?.language || router.locale || 'en'

      Cookies.set('NEXT_LOCALE', locale)

      // Let's make sure the locale isn't equal to the current locale
      if (router.locale !== locale) {
        router.push(router.asPath, router.asPath, { locale })
      }
    }
  }, [])

  const updateUserLastSeen = async () => {
    if (lastSeenFeatureTesting.enabled && account) {
      await client
        .mutation<UpdateLastSeenMutation>(UpdateLastSeenDocument, {
          id: account?.id,
          lastSeen: new Date().toISOString(),
        })
        .toPromise()
    }
  }

  useEffect(() => {
    updateUserLastSeen()
  }, [account])

  useEffect(() => {
    const interval = setInterval(async () => {
      await updateUserLastSeen()
    }, ms(getEnv('LAST_SEEN_INTERVAL', true) || '20s'))

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const handleComplete = () => {
      loadingScreen.close()
      modal.close()
    }

    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleComplete)

    return () => {
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleComplete)
    }
  })

  const injectHead = (
    <>
      {router.route !== '/user/[username]' && router.route !== '/[username]' && (
        <Head>
          <title>{platformFriendly}</title>

          <meta name="application-name" content={platformFriendly} />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta
            name="apple-mobile-web-app-status-bar-style"
            content="default"
          />
          <meta name="apple-mobile-web-app-title" content={platformFriendly} />
          <meta
            name="description"
            content="All-in-one platform to run a successful Influencer business"
          />
          <meta name="format-detection" content="telephone=no" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="theme-color" content="#4084ff" />

          <link
            rel="apple-touch-icon"
            href="/app-icons/touch-icon-iphone.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="152x152"
            href="/app-icons/touch-icon-ipad.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/app-icons/touch-icon-iphone-retina.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="167x167"
            href="/app-icons/touch-icon-ipad-retina.png"
          />

          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/app-icons/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/app-icons/favicon-16x16.png"
          />
          <link rel="manifest" href="/manifest.json" />
          <link
            rel="mask-icon"
            href="/app-icons/safari-pinned-tab.svg"
            color="#4084ff"
          />
          <link rel="shortcut icon" href="/favicon.ico" />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:url" content="https://influexer.com" />
          <meta name="twitter:title" content={platformFriendly} />
          <meta
            name="twitter:description"
            content="All-in-one platform to run a successful Influencer business"
          />
          <meta
            name="twitter:image"
            content="https://influexer.com/app-icons/android-chrome-192x192.png"
          />
          <meta name="twitter:creator" content="@influexer" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={platformFriendly} />
          <meta
            property="og:description"
            content="All-in-one platform to run a successful Influencer business"
          />
          <meta property="og:site_name" content={platformFriendly} />
          <meta property="og:url" content="https://influexer.com" />
          <meta
            property="og:image"
            content="https://influexer.com/app-icons/apple-touch-icon.png"
          />
        </Head>
      )}
    </>
  )

  if (!router.isReady) return <Loading />

  if (
    isAuthenticated &&
    account &&
    !impersonator &&
    !account.hasCompletedProfile &&
    (!(Component as any).skipCompleteProfile || router.query.context)
  ) {
    return (
      <>
        {injectHead}

        <PlatformAlert />

        {router.route === '/complete-signup' ? (
          <Component {...pageProps} />
        ) : (
          <Redirect
            to={appendQuery('/complete-signup', {
              callbackUrl: router.asPath.replace(/(\?.*)|(#.*)/g, ''),
              ...routerQueryToAppendQuery(router.query),
            })}
          />
        )}
      </>
    )
  }

  const Layout = (Component as any).Layout || PageLayout

  return (
    <>
      {injectHead}

      <PlatformAlert />

      <Layout pageProps={pageProps} extra={(Component as any).extra}>
        <Component {...pageProps} />
        <CheckTermsAndConditions />
      </Layout>

      {isAuthenticated && <AddToHomeScreen />}
    </>
  )
}

// Note: this is a component because, when using 'toast' in AppContainer, makes the app rerender
const CheckTermsAndConditions = () => {
  const { account, isAuthenticated, impersonator } = useAuth()
  const toast = useToast()

  useEffect(() => {
    if (
      isAuthenticated &&
      !impersonator &&
      account.hasCompletedProfile &&
      !account.hasAcceptedTerms
    ) {
      toast.notify({
        type: 'warning',
        preventAutoClose: true,
        preventClose: true,
        message: () => {
          return <PopUpTermsAndConditions />
        },
      })
    }
  }, [isAuthenticated, account])

  return null
}

const PlatformAlert = () => {
  const posthog = usePostHog()
  const { locale } = useRouter()
  const [data, setData] = useState<any>(
    posthog.isFeatureEnabled('maintenance')
      ? [
          {
            message:
              'We are currently under maintenance, you may experience issues and/or delays.',
            type: 'urgent',
          },
        ]
      : null,
  )
  const platformAlertsRef = ref(
    database,
    [
      process.env.NODE_ENV === 'development' ? 'development' : undefined,
      'platformAlerts',
    ]
      .filter(Boolean)
      .join('/'),
  )

  useEffect(() => {
    const detach = onValue(platformAlertsRef, (snapshot) => {
      const data = snapshot.val()

      setData((d: any) => [...(d || []), ...(data || [])])
    })

    return () => {
      detach()
    }
  }, [])

  if (Array.isArray(data) && data.length > 0) {
    return (
      <ul>
        {data.map(({ updated_at, type, message, message_es }, index) => (
          <li
            key={`platform-alert-${updated_at}-${index}`}
            className={cc([
              'p-2 flex flex-col space-y-1 md:block md:space-y-0 md:space-x-4',
              {
                'border-2 border-action-fail bg-white text-action-fail':
                  type === 'normal',
                'border-2 border-action-fail bg-action-fail text-white':
                  type === 'urgent',
              },
            ])}
          >
            {updated_at && (
              <span className="text-sm">
                <strong>{dayjs(updated_at).calendar()}</strong>
              </span>
            )}
            <span>
              {patternMatching<string, string>(
                [['es', message_es]],
                message,
              )(locale || 'en')}
            </span>
          </li>
        ))}
      </ul>
    )
  }

  return null
}
