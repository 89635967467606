import React, { useEffect, useState } from 'react'
import BackgroundImage from 'components/BackgroundImage'
import cc from 'classcat'
import { useGetUserLastSeenSubscription } from 'generated/graphql'
import dayjs from 'dayjs'
import ms from 'ms'
import { PLACEHOLDER_EMPTY_AVATAR } from 'config/constants'

type Props = {
  image?: string | null
  userID: number
  size: 'sm' | 'md' | 'lg'
  $showDot?: boolean
  className?: string
  $pictureBorder?: boolean
  children?: React.ReactNode
  onClick?: () => void
}

const ProfilePicture = ({
  image,
  userID,
  size,
  $showDot,
  className,
  children,
  $pictureBorder,
  onClick,
}: Props) => {
  return (
    <div
      className={cc([
        'relative mx-auto',
        {
          'w-10': size === 'sm',
          'w-16': size === 'md',
          'w-32 h-32': size === 'lg',
        },
        className,
      ])}
    >
      <BackgroundImage
        image={image || PLACEHOLDER_EMPTY_AVATAR}
        className={cc([
          'inline-block rounded-full ',
          {
            'w-10 h-10': size === 'sm',
            'w-16 h-16': size === 'md',
            'w-32 h-32': size === 'lg',
          },
          { 'border-2 border-white rounded-full': $pictureBorder },
        ])}
        onClick={onClick}
      >
        {children}
      </BackgroundImage>
      {$showDot && <OnlineDot id={userID} size={size} />}
    </div>
  )
}

type onlineDotProps = {
  id?: number
  size?: string
}

const OnlineDot = ({ id, size }: onlineDotProps) => {
  const [{ data, fetching }] = useGetUserLastSeenSubscription({
    variables: {
      id: id,
    },
  })
  const [showDot, setShowDot] = useState(false)
  const lastSeen = data?.wui_userlastseen?.[0]?.last_seen_date

  useEffect(() => {
    const today = dayjs(new Date().toUTCString())
    if (data) {
      today.diff(dayjs(lastSeen), 'minutes') < 1
        ? setShowDot(true)
        : setShowDot(false)
    }
  }, [data])

  useEffect(() => {
    let interval: any
    if (showDot) {
      interval = setInterval(async () => {
        const today = dayjs(new Date().toUTCString())
        today.diff(dayjs(lastSeen), 'minutes') >= 1 && setShowDot(false)
      }, ms('1s'))
    }
    return () => {
      clearInterval(interval)
    }
  })

  if (fetching || !showDot) {
    return null
  }

  return (
    <div
      className={cc([
        'rounded-full absolute border-2 border-white',
        {
          'bottom-0 -right-0': size === 'sm',
          'bottom-0 -right-0.5': size === 'md',
          'bottom-2 right-1': size === 'lg',
        },
      ])}
      data-testid="online-dot"
    >
      <div
        className={cc([
          'bg-action-success  rounded-full',
          {
            'w-2 h-2': size === 'sm',
            'w-3 h-3': size === 'md',
            'w-5 h-5': size === 'lg',
          },
        ])}
      />
    </div>
  )
}

export default ProfilePicture
