import { useAuth } from 'clients/auth.client'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { createContext, useContext, useEffect, useState } from 'react'
import getSingleQueryParam from 'utils/getSingleQueryParam'
import { PLATFORM_BRAND } from 'utils/localStorageKeys'
import patternMatching from 'utils/patternMatching'

type PlatformBrand = 'influexer' | 'iconopay'

export const stringToPlatformBrand = patternMatching<string, PlatformBrand>(
  [
    ['influexer', 'influexer'],
    ['iconopay', 'iconopay'],
  ],
  'influexer',
)

export const getFriendlyPlatform = (platform: PlatformBrand | string) => {
  const FRIENDLY_PLATFORM_BRAND: Record<PlatformBrand, string> = {
    influexer: 'Influexer',
    iconopay: 'IconoPay',
  }

  return FRIENDLY_PLATFORM_BRAND[
    typeof platform === 'string' ? stringToPlatformBrand(platform) : platform
  ]
}

const Context = createContext<{
  platform: PlatformBrand
  platformFriendly: string
  setPlatform: (newPlatform: PlatformBrand) => void
}>({
  platform: 'influexer',
  platformFriendly: 'Influexer',
  setPlatform: () => undefined,
})

export const useGlobalStore = () => useContext(Context)

const maybePlatformOrFallback = (platform: string | null): PlatformBrand => {
  return platform && (platform === 'influexer' || platform === 'iconopay')
    ? platform
    : 'influexer'
}

const GlobalStoreProvider = ({ children }: any) => {
  const router = useRouter()
  const { query, isReady } = router
  const brand = getSingleQueryParam(query.brand)
  const { isAuthenticated, account } = useAuth()
  const [platform, setPlatformSTATE] = useState<PlatformBrand>(() => {
    if (brand) {
      return stringToPlatformBrand(brand)
    }

    if (typeof window === 'undefined') {
      return 'influexer'
    }

    return stringToPlatformBrand(
      localStorage.getItem(PLATFORM_BRAND) || 'influexer',
    )
  })

  const setPlatform = (newPlatform: PlatformBrand) => {
    localStorage.setItem(PLATFORM_BRAND, newPlatform)

    setPlatformSTATE(newPlatform)
  }

  useEffect(() => {
    if (isAuthenticated) {
      setPlatform(account.platformBrand)
    } else if (isReady && !platform) {
      const parsedPlatform = maybePlatformOrFallback(
        localStorage.getItem(PLATFORM_BRAND),
      )
      // eslint-disable-next-line no-console
      console.log('--useEffect auth change parsedPlatform', parsedPlatform)

      setPlatform(parsedPlatform)
    }
  }, [isReady, isAuthenticated, account?.platformBrand])

  useEffect(() => {
    localStorage.setItem(PLATFORM_BRAND, platform)
    Cookies.set('next-auth.platform', platform)
  }, [platform])

  return (
    <Context.Provider
      value={{
        platform,
        platformFriendly: getFriendlyPlatform(platform),
        setPlatform: (newPlatform: PlatformBrand) => {
          if (!isAuthenticated) {
            const parsedPlatform = maybePlatformOrFallback(newPlatform)

            setPlatform(parsedPlatform)
          }
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default GlobalStoreProvider
