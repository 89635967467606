import { useAddToHomescreenPrompt } from 'hooks/useAddToHomescreenPrompt'
import { useState } from 'react'

import icon from 'assets/Influexer-Icon.png'
import Image from 'next/image'
import X from 'assets/icons/x.svg'
import dayjs from 'dayjs'
import { ADD_TO_HOME_SCREEN } from 'utils/localStorageKeys'

export const AddToHomeScreen = () => {
  const [show, setShow] = useState(false)
  const [, promptToInstall] = useAddToHomescreenPrompt(() => {
    const key = window.localStorage.getItem(ADD_TO_HOME_SCREEN)

    if (key) {
      const date = dayjs(key)
      const diff = dayjs().diff(date, 'day')

      if (diff >= 1) {
        window.localStorage.setItem(
          ADD_TO_HOME_SCREEN,
          new Date().toISOString(),
        )
        setShow(true)
      }
    } else {
      window.localStorage.setItem(ADD_TO_HOME_SCREEN, new Date().toISOString())
      setShow(true)
    }
  })

  if (show) {
    return (
      <>
        <div id="testing" className="fixed inset-0 z-[9000] bg-black/50" />
        <div className="fixed z-[9001] bottom-0 left-0 right-0 flex justify-between items-center shadow-lg bg-white">
          <button
            onClick={async () => {
              try {
                const result = await promptToInstall()

                if (result.outcome === 'accepted') {
                  window.localStorage.setItem(
                    ADD_TO_HOME_SCREEN,
                    new Date().toISOString(),
                  )
                  setShow(false)
                }
              } catch (err) {}
            }}
            className="flex justify-center items-center p-4"
          >
            <Image src={icon} alt="Influexer" width={32} height={32} />
            <span className="pl-2 text-lg">Add Influexer to Home Screen</span>
          </button>

          <button
            className="p-4"
            onClick={() => {
              setShow(false)
            }}
          >
            <X className="fill-black w-4 h-4" />
          </button>
        </div>
      </>
    )
  }

  return null
}
