export const AUTH_SESSION_TOKEN = 'AUTH_SESSION_TOKEN'

export const AUTH_SESSION_USER_ID = 'AUTH_SESSION_USER_ID'

export const HIDE_BIO_BANNER = 'HIDE_BIO_BANNER'

export const VOTE_INFO = 'VOTE_INFO_2024'

export const PLATFORM_BRAND = 'PLATFORM_BRAND'

export const ADD_TO_HOME_SCREEN = 'ADD_TO_HOME_SCREEN'
