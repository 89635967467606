import type { User } from 'types/next-auth'
import { env } from 'env/client.mjs'
import { H } from 'highlight.run'

export type Ctx = Record<
  string,
  string | number | boolean | undefined | null | User | unknown
> & {
  error?: Error | unknown
  origin?: 'front-end' | 'urql' | 'trpc' | 'nextjs'
}

export type Level = 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug'

export type Auth = {
  userId?: number
  isAuthenticated: boolean
  authenticating?: boolean
  name: string
  email?: string | null
  username?: string | null
  type?: string | null
  role?: string
  country?: string | null
  platformBrand?: string
  isSeller?: boolean
  isImpersonator?: boolean
  hasSubscription?: boolean
}

const _log = (level: Level, msg: string, ctx?: Ctx, auth?: Auth) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('::LOGGER::', level, msg, ctx)
  } else {
    if (['error', 'fatal'].includes(level)) {
      // SERVER
      if (typeof window === 'undefined' && level === 'fatal') {
        import('../server/slackClient').then(({ send }) => {
          send(msg, {
            type: 'hard',
            extra: {
              level,
              ctx: JSON.stringify(ctx),
            },
          })
        })
      }
      // CLIENT
      else {
        const params = ctx
          ? Object.keys(ctx).reduce((obj, key) => {
              const value = ctx[key]

              if (
                typeof value === 'string' ||
                typeof value === 'number' ||
                typeof value === 'boolean'
              ) {
                return {
                  ...obj,
                  [key]: value,
                }
              }

              return obj
            }, {})
          : {}
        const parsedAuth = auth
          ? Object.keys(auth).reduce((obj, key) => {
              const value = (auth as any)[key]

              if (value) {
                return {
                  ...obj,
                  [key]: value?.toString?.(),
                }
              }

              return obj
            }, {})
          : undefined

        if (env.NEXT_PUBLIC_APPSIGNAL_API_KEY) {
          import('@appsignal/javascript').then(({ default: Appsignal }) => {
            const appsignal = new Appsignal({
              key: env.NEXT_PUBLIC_APPSIGNAL_API_KEY,
            })
            appsignal.sendError(new Error(msg), (span) => {
              span.setTags({
                ...parsedAuth,
                level,
              })

              if (ctx) {
                if (typeof ctx.action === 'string') {
                  span.setAction(ctx.action)
                }
                if (typeof ctx.namespace === 'string') {
                  span.setNamespace(ctx.namespace)
                }

                span.setParams({
                  ...params,
                  msg,
                })
              }
            })
          })
        }

        H.consumeError(
          ctx?.error instanceof Error ? ctx.error : new Error(msg),
          msg,
          {
            ...parsedAuth,
            ...params,
          },
        )
      }
    }
  }
}

const logger = {
  info: (msg: string, ctx?: Ctx) => _log('info', msg, ctx),
  warn: (msg: string, ctx?: Ctx) => _log('warning', msg, ctx),
  error: (msg: string, ctx?: Ctx) => {
    // eslint-disable-next-line no-console
    console.error(msg, ctx)
    _log('error', msg, ctx)
  },
  fatal: (msg: string, ctx?: Ctx) => _log('fatal', msg, ctx),
  debug: (msg: string, ctx?: Ctx) => {
    // eslint-disable-next-line no-console
    console.debug(msg, ctx)
  },
  log: _log,
}

export default logger
