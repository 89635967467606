import Loading from 'components/Loading'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const withRouterReady = (
  Component: any,
  opts?: {
    hideLoading: boolean
  },
) => {
  function RenderComponent(props: any) {
    const [render, setRender] = useState(false)
    const router = useRouter()

    useEffect(() => {
      setRender(router.isReady)
    }, [router.isReady])

    if (render) {
      return <Component {...props} />
    } else {
      if (opts?.hideLoading) return null
      return (
        <div className="text-center p-4">
          <Loading />
        </div>
      )
    }
  }

  RenderComponent.extra = Component.extra
  RenderComponent.skipCompleteProfile = Component.skipCompleteProfile

  return RenderComponent
}

export default withRouterReady
