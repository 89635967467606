import cc from 'classcat'
import { FC, useState, useEffect } from 'react'

type Position = 'left' | 'right'
type MobilePosition = 'top' | 'bottom'

type Options = {
  position: Position
  asideClass?: string
  staticPosition?: boolean //This when is set to true will prevent the ImageViewer bug
  $showOnMobile?: boolean
  mobilePosition?: MobilePosition
  props?: any
}

export const WithAside = ({
  Aside,
  children,
  position,
  asideClass,
  staticPosition,
  $showOnMobile,
  mobilePosition,
  props,
}: Options & {
  Aside: FC<any>
  children: any
}) => {
  const [hasHeader, setHasHeader] = useState(false)
  const asideElement = (
    <div
      className={cc([
        'md:w-1/3',
        {
          'hidden md:block': !$showOnMobile,
          'order-last': mobilePosition === 'bottom',
        },
      ])}
    >
      <div
        className={cc([
          asideClass,
          { sticky: !staticPosition },
          {
            'top-0': !hasHeader,
            'top-14': hasHeader,
          },
        ])}
      >
        <Aside {...props} />
      </div>
    </div>
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (document.querySelector('[data-testid="page-header"]')) {
        clearInterval(interval)
        setHasHeader(true)
      }
    }, 500)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <div
        className={cc([
          'flex flex-row flex-grow w-full justify-center',
          { 'flex-col md:flex-row': $showOnMobile },
        ])}
      >
        {position === 'left' && asideElement}

        <div className="w-full md:w-2/3 md:px-4">{children}</div>

        {position === 'right' && asideElement}
      </div>
    </>
  )
}

WithAside.defaultProps = {
  position: 'left',
}

function withAside<Props = unknown>(Page: FC, Aside: FC, opts?: Options) {
  // eslint-disable-next-line react/display-name
  return (props: Props) => {
    return (
      <WithAside Aside={Aside} {...opts} props={props}>
        <Page {...props} />
      </WithAside>
    )
  }
}

export default withAside
