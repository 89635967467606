import IconClose from 'assets/icons/x.svg'
import { useEffect, useState } from 'react'

const DetectDeviceConnection = () => {
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    setShowAlert(!window.navigator.onLine)

    const onlineHandler = () => {
      setShowAlert(false)
    }
    const offlineHandler = () => {
      setShowAlert(true)
    }

    window.addEventListener('online', onlineHandler)
    window.addEventListener('offline', offlineHandler)

    return () => {
      window.removeEventListener('online', onlineHandler)
      window.removeEventListener('offline', offlineHandler)
    }
  }, [])

  if (!showAlert) {
    return null
  }

  return <DismissibleMessage />
}

export default DetectDeviceConnection

const DismissibleMessage = () => {
  const [show, setShow] = useState(true)

  if (!show) {
    return null
  }

  return (
    <div className="z-20 fixed top-0 bg-gray-800 w-full p-3 text-white">
      🚫 You are not connected to Internet
      <button
        type="button"
        className="absolute top-0 right-0 p-3"
        data-testid="button-close-no-connection"
        onClick={() => {
          setShow(false)
        }}
      >
        <IconClose className="w-5 h-5 fill-white" />
      </button>
    </div>
  )
}
