import cc from 'classcat'
import Button from 'components/Button'
import UserCard from 'components/UserCard'
import Link from 'components/Link'
import { useSpring, config, animated } from 'react-spring'
import { isOfType } from 'utils/types.util'
import { useAuth } from 'clients/auth.client'
import useToggle from 'hooks/useToggle'
import { useFeatureTesting } from 'stores/featureTesting.store'
import Image from 'next/legacy/image'
import { useSettingsMenu, useProfileMenu } from 'components/AsidePageMenu'
import { FC } from 'react'
import withRouterReady from 'hocs/withRouterReady'
import { useRouter } from 'next/router'
import NextLink from 'next/link'
import SupportLink from 'components/SupportLink'
import IconBadge from 'assets/icons/badge.svg'
import { useTranslation } from 'next-i18next'

type Props = {
  isOpen: boolean
  toggle: () => void
}

type Type = 'primary' | 'secondary' | 'logout'

type MenuLink = {
  to?: string | MenuLink[]
  onClick?: () => void
  text: string
  $type?: Type
  initialOpenState?: boolean
}

type MenuCustomLink = {
  Component: FC
}

const Menu = ({ isOpen, toggle }: Props) => {
  const { t } = useTranslation()
  const settingsMenu = useSettingsMenu()
  const profileMenu = useProfileMenu()
  const overlayStyle = useSpring({
    from: { display: 'none', opacity: 0 },
    to: [
      {
        display: 'block',
        opacity: isOpen ? 1 : 0,
      },
      {
        display: isOpen ? 'block' : 'none',
      },
    ],
    config: config.stiff,
  })
  const sideStyle = useSpring({
    left: isOpen ? 0 : -300,
  })
  const { isAuthenticated, account } = useAuth()
  const searchFeatureTesting = useFeatureTesting('search')
  const proposalsFeatureTesting = useFeatureTesting('proposals')
  const subscriptionsFeatureTesting = useFeatureTesting(
    'packages_subscriptions',
  )
  const affiliateFeatureTesting = useFeatureTesting('affiliate')
  const proFeatureTesting = useFeatureTesting('pro')

  if (!isAuthenticated) {
    return null
  }

  return (
    <>
      {/* Overlay */}
      <animated.div
        className="z-7 fixed inset-0 bg-black bg-opacity-60 backdrop-filter backdrop-blur"
        style={overlayStyle}
        onClick={() => {
          toggle()
        }}
      />

      {/* Container */}
      <animated.div
        className="z-8 fixed top-0 left-0 flex flex-col w-72 h-full bg-white py-5 px-6 overflow-y-auto"
        style={sideStyle}
      >
        {/* TOP */}
        <div className="flex-grow">
          <button
            onClick={() => {
              toggle()
            }}
          >
            <Image
              src="/assets/icons/x.svg"
              alt="Close"
              width="20"
              height="20"
            />
          </button>

          {/* User */}
          <div className="py-2">
            <UserCard
              name={[account?.firstName, account?.lastName]
                .filter(Boolean)
                .join(' ')}
              username={account?.username || ''}
              picture={account?.picture || ''}
              onClick={() => {
                toggle()
              }}
            />
          </div>

          <div className="py-2">
            <Button
              href={`/user/${account?.username}`}
              $fluid
              onClick={() => {
                toggle()
              }}
            >
              {t('menu:buttonViewProfile')}
            </Button>
          </div>

          {proFeatureTesting.enabled && !account.subscription?.active && (
            <NextLink
              href="/pro"
              className="block bg-action-warning boujee-pro p-3 text-center font-bold text-white rounded-md uppercase flex items-center justify-center mt-2 mb-4"
              onClick={() => {
                toggle()
              }}
            >
              <IconBadge className="fill-white w-4 h-4 block" />
              <span className="pl-1 uppercase">{t('menu:buttonGetPro')}</span>
            </NextLink>
          )}

          {account.role === 'controller' && (
            <div className="py-2">
              <Button
                href="/admin/users"
                $type="secondary"
                $fluid
                onClick={() => {
                  toggle()
                }}
              >
                Admin
              </Button>
            </div>
          )}

          {/* Menu (Top) */}
          <MenuLinks
            onClick={() => toggle()}
            links={
              [
                {
                  to: '/',
                  text: t<string>('menu:linkDashboard'),
                },
                {
                  to: '/notifications#notifications',
                  text: t<string>('menu:linkNotifications'),
                },
                searchFeatureTesting.enabled && {
                  to: '/search#influencers',
                  text: t<string>('menu:linkSearch'),
                },
                searchFeatureTesting.enabled && {
                  to: '/search#projects',
                  text: t<string>('menu:linkProjects'),
                },
                {
                  to: '/orders#current',
                  text: t<string>('menu:linkOrders'),
                },
                {
                  to: '/price-requests#received',
                  text: t<string>('menu:linkPriceRequests'),
                },
                subscriptionsFeatureTesting.enabled && {
                  to: '/subscriptions',
                  text: t<string>('menu:linkSubscriptions'),
                },
                {
                  to: '/reviews',
                  text: t<string>('menu:linkReviews'),
                },
                {
                  to: '/marketplace',
                  text: (
                    <>
                      Marketplace{' '}
                      <span className="bg-primary rounded-sm text-xs text-white px-1 py-0.5 ml-2">
                        BETA
                      </span>
                    </>
                  ),
                },
                {
                  to: profileMenu(),
                  text: t<string>('menu:linkProfileSettings'),
                },
                {
                  to: '/billing#transactions',
                  text: t<string>('menu:linkBilling'),
                },
                affiliateFeatureTesting.enabled && {
                  to: '/partner',
                  text: t<string>('menu:linkPartnerProgram'),
                },
                {
                  to: settingsMenu(),
                  text: t<string>('menu:linkSettings'),
                },
              ].filter(Boolean) as (MenuLink | MenuCustomLink)[]
            }
          />
        </div>

        {/* BOTTOM */}
        <div>
          <MenuLinks
            onClick={() => toggle()}
            links={
              [
                proposalsFeatureTesting.enabled && {
                  Component: () => (
                    <div className="py-5">
                      <Button $type="secondary" $fluid href="/create-proposal">
                        {t('menu:linkCreateProposal')}
                      </Button>
                    </div>
                  ),
                },
                {
                  Component: () => (
                    <SupportLink className="inline-block text-left w-full py-3 text-gray-600 hover:text-gray-800" />
                  ),
                },
                {
                  to: '/logout',
                  text: t<string>('menu:linkLogout'),
                  $type: 'logout',
                },
              ].filter(Boolean) as (MenuLink | MenuCustomLink)[]
            }
          />
        </div>
      </animated.div>
    </>
  )
}

type MenuLinksProps = {
  links: (MenuLink | MenuCustomLink)[]
  $type?: Type
  onClick?: () => void
}

const MenuLinks = ({ links, $type, onClick }: MenuLinksProps) => {
  const router = useRouter()

  return (
    <ul>
      {links.map((link, index) => (
        <li
          key={`menu-link-${index}`}
          className={cc([
            {
              'border-b border-gray-400':
                index < links.length - 1 || $type !== 'secondary',
            },
          ])}
        >
          {isOfType(link, 'Component') ? (
            <link.Component />
          ) : Array.isArray(link.to) ? (
            <SubMenu
              text={link.text}
              links={link.to.filter(Boolean)}
              onClick={onClick}
              initialOpenState={link.initialOpenState}
            />
          ) : link.to ? (
            <Link
              to={link.to}
              className={`w-full py-3`}
              active={router.asPath === link.to}
              $type={link.$type || $type}
              data-testid={`link`}
              data-value={link.to}
              onClick={onClick}
              target={link.to.includes('http') ? '_blank' : '_self'}
            >
              {link.text}
            </Link>
          ) : (
            <div />
          )}
        </li>
      ))}
    </ul>
  )
}

type SubMenuProps = {
  text: string
  links: (MenuLink | MenuCustomLink)[]
  onClick?: () => void
  initialOpenState?: boolean
}

const SubMenu = ({ text, links, onClick, initialOpenState }: SubMenuProps) => {
  const [isOpen, toggle] = useToggle(initialOpenState)

  const openAnimation = useSpring({
    from: { opacity: '0', maxHeight: '0' },
    to: { opacity: '1', maxHeight: isOpen ? `${links.length * 50}px` : '0' },
  })

  return (
    <>
      <button
        className="w-full py-3"
        onClick={() => {
          toggle()
        }}
        data-testid="button-settings"
      >
        <span className="flex items-center justify-between">
          <span>{text}</span>
          <Image
            src="/assets/icons/arrow-payment.svg"
            alt="Expand"
            width="20"
            height="20"
            className="w-2 transition-all opacity-60"
            style={{
              transform: isOpen ? `rotate(270deg)` : `rotate(90deg)`,
            }}
          />
        </span>
      </button>

      <animated.div className="overflow-hidden" style={openAnimation}>
        <MenuLinks links={links} $type="secondary" onClick={onClick} />
      </animated.div>
    </>
  )
}

export default withRouterReady(Menu, { hideLoading: true })
