import cc from 'classcat'

export type ContainerProps = {
  children: React.ReactNode
  topAndBottom?: boolean
  leftAndRight?: boolean
  className?: string
  $type?: 'normal' | 'narrow' | 'narrower'
  'data-testid'?: string
}

const Container = ({
  children,
  topAndBottom,
  leftAndRight,
  className,
  $type,
  ...rest
}: ContainerProps) => {
  return (
    <div
      {...rest}
      className={cc([
        'w-full mx-auto',
        {
          'max-w-2xl': $type === 'narrow',
          'max-w-xl': $type === 'narrower',
          'max-w-4xl lg:max-w-5xl xl:max-w-6xl md:max-w-7xl':
            !$type || $type === 'normal',
          'py-5': topAndBottom,
          'px-4': leftAndRight,
        },
        className,
      ])}
    >
      {children}
    </div>
  )
}

Container.defaultProps = {
  leftAndRight: true,
  $type: 'normal',
}

export default Container
