import cc from 'classcat'
import { LoadingSpinner } from 'components/Loading'
import Link from 'next/link'
import { forwardRef } from 'react'

export type Type =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'link'
  | 'danger'
  | 'info'
  | 'confirm'
  | 'vote'

export interface Props {
  children: any
  $type?: Type
  $fluid?: boolean
  $inverted?: boolean
  disabled?: boolean
  loading?: boolean
  $loadingColor?: 'primary' | 'white'
  onClick?: () => void
  className?: string
  form?: string
  type?: string
  href?: string
  $size?: 'sm' | 'md'
  $rounded?: boolean
  target?: string
  $shadow?: boolean
  useATag?: boolean
}

// eslint-disable-next-line react/display-name
const Button = forwardRef<any, Props>(function Button(
  {
    children,
    $type,
    $fluid,
    $inverted,
    className,
    disabled,
    loading,
    $size,
    $loadingColor,
    $rounded,
    $shadow,
    useATag,
    ...rest
  }: Props,
  ref,
) {
  const Render = (rest.href ? (useATag ? 'a' : Link) : 'button') as any
  const classMap: {
    [key: string]: {
      normal: {
        enabled: string
        disabled: string
      }
      inverted: {
        enabled: string
        disabled: string
      }
    }
  } = {
    primary: {
      normal: {
        enabled:
          'text-white bg-gradient-to-r from-primary to-secondary border-2 border-primary',
        disabled:
          'cursor-not-allowed text-white bg-gray-600 border-2 border-gray-600',
      },
      inverted: {
        enabled: 'text-green bg-white border-2 border-white',
        disabled:
          'cursor-not-allowed text-green bg-gray-300 border-2 border-gray-300 opacity-75',
      },
    },
    secondary: {
      normal: {
        enabled: 'bg-transparent text-primary border-2 border-primary',
        disabled:
          'cursor-not-allowed bg-transparent text-gray-600 border-2 border-gray-600',
      },
      inverted: {
        enabled: 'bg-transparent text-white border-2 border-white',
        disabled:
          'bg-transparent text-gray-300 border-2 border-gray-300 opacity-75',
      },
    },
    link: {
      normal: {
        enabled: 'text-primary',
        disabled: 'cursor-not-allowed text-gray-600',
      },
      inverted: {
        enabled: 'text-white',
        disabled: 'cursor-not-allowed text-white text-opacity-75',
      },
    },

    confirm: {
      normal: {
        enabled: 'text-white bg-action-success',
        disabled: 'cursor-not-allowed text-white bg-gray-600',
      },
      inverted: {
        enabled: 'text-white bg-gray-500',
        disabled:
          'cursor-not-allowed text-bg-action-success bg-gray-300 opacity-75',
      },
    },

    danger: {
      normal: {
        enabled: 'bg-action-fail text-white',
        disabled: 'cursor-not-allowed text-action-fail bg-gray-400',
      },
      inverted: {
        enabled: 'bg-white text-action-fail border-2 border-action-fail',
        disabled: 'cursor-not-allowed bg-white text-action-fail opacity-75',
      },
    },

    tertiary: {
      normal: {
        enabled: 'bg-transparent text-gray-800',
        disabled: 'cursor-not-allowed bg-transparent text-gray-800',
      },
      inverted: {
        enabled: 'bg-transparent text-white',
        disabled: 'cursor-not-allowed text-gray-300 text-opacity-75',
      },
    },
    // *** WIP ***

    // info: {
    //   normal: {
    //     enabled: 'bg-primary text-white',
    //     disabled: 'cursor-not-allowed text-gray-700 bg-gray-500',
    //   },
    //   inverted: {
    //     enabled: 'bg-white text-primary',
    //     disabled: 'cursor-not-allowed bg-white text-primary opacity-75',
    //   },
    // },

    vote: {
      normal: {
        enabled: 'text-gray-900 border-2 border-voteBright bg-voteBright',
        disabled:
          'cursor-not-allowed text-white bg-gray-600 border-2 border-gray-600',
      },
      inverted: {
        enabled: 'text-green bg-white border-2 border-white',
        disabled:
          'cursor-not-allowed text-green bg-gray-300 border-2 border-gray-300 opacity-75',
      },
    },
  }

  $type = $type || 'primary'
  $size = $size || 'md'
  $rounded = $rounded || true

  const render = (
    <Render
      className={cc([
        'inline-block tracking-wide leading-none font-bold uppercase text-center',
        {
          'w-full': $fluid,
          'p-3 pt-3.5 text-xs': !loading && $size === 'sm',
          'p-4': !loading && $size === 'md',
          'px-2 py-2.5': loading && $size === 'sm',
          'px-5 py-4': loading && $size === 'md',
          'rounded-md': $rounded,
          'hover:opacity-90': !disabled,
        },
        {
          'bg-transparent text-primary border-2 border-primary':
            $type === 'secondary' && !$inverted && !disabled,
          'bg-transparent text-gray-600 border-2 border-gray-600':
            $type === 'secondary' && !$inverted && disabled,
          'bg-transparent text-white border-2 border-white':
            $type === 'secondary' && $inverted && !disabled,
          'bg-transparent text-gray-300 border-2 border-gray-300 opacity-75':
            $type === 'secondary' && $inverted && disabled,
        },
        $type &&
          classMap?.[$type]?.[$inverted ? 'inverted' : 'normal'][
            disabled ? 'disabled' : 'enabled'
          ],
        {
          'shadow-lg shadow-black': $shadow,
        },

        // custom classes
        className,
      ])}
      disabled={disabled || loading}
      ref={ref}
      {...rest}
    >
      {loading ? (
        <LoadingSpinner
          $size={$size === 'sm' ? 'xs' : 'sm'}
          $color={$loadingColor || $type === 'link' ? 'primary' : 'white'}
          $type="dots"
        />
      ) : (
        children
      )}
    </Render>
  )

  return render
})

export default Button
