import { useTranslation } from 'next-i18next'

const SupportLink = ({ className }: { className?: string }) => {
  const { t } = useTranslation()

  return (
    <a
      href="https://influexer.com/customer-service/"
      target="_blank"
      rel="noreferrer"
      className={className}
    >
      {t('menu:linkSupport', 'Support')}
    </a>
  )
}

export default SupportLink
