import BackgroundImage from 'components/BackgroundImage'
import { PLACEHOLDER_EMPTY_AVATAR } from 'config/constants'
import Link from 'next/link'

type Props = {
  name: string
  username?: string
  picture?: string
  onClick?: () => void
}

const UserCard = ({ picture, name, username, onClick }: Props) => {
  return (
    <Link
      href={`/user/${username}`}
      passHref
      className="flex items-center"
      onClick={() => {
        onClick?.()
      }}
    >
      <BackgroundImage
        width={55}
        height={55}
        image={picture || PLACEHOLDER_EMPTY_AVATAR}
        className="rounded-full"
      />
      <div className="ml-3">
        <div className="font-bold text-lg leading-none">{name}</div>
        <div className="text-gray-500 text-sm">{username}</div>
      </div>
    </Link>
  )
}

export default UserCard
