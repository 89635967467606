import {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
  useState,
} from 'react'
import cc from 'classcat'
import IconCheck from 'assets/icons/checkmark.svg'

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string | ReactElement
  $labelClassName?: string
}

// eslint-disable-next-line react/display-name
const FormCheckbox = forwardRef(
  ({ label, $labelClassName, ...props }: Props, ref: any) => {
    const id = props.id || `checkbox-${props.name}-${props.value}`
    const [isChecked, setIsChecked] = useState(false)

    return (
      <>
        <input
          {...props}
          id={id}
          ref={
            ref
              ? (element) => {
                  setTimeout(() => {
                    setIsChecked(element?.checked || false)
                  })
                  return ref(element)
                }
              : undefined
          }
          className="hidden"
          type="checkbox"
          onChange={(e) => {
            setIsChecked(e.target.checked)
            props.onChange?.(e)
          }}
        />

        <label htmlFor={id} className="inline-flex cursor-pointer">
          <div
            className={cc([
              'relative min-w-6 block w-6 h-6 border border-gray-500 rounded-md',
              {
                'bg-white': !props!.disabled,
                'bg-gray-400': props.disabled,
              },
            ])}
          >
            {isChecked && (
              <div
                className="absolute block bg-gradient-to-r from-primary to-secondary rounded-sm top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                style={{
                  width: '74%',
                  height: '74%',
                }}
              >
                <IconCheck className="fill-white p-0.5" />
              </div>
            )}
          </div>
          {label && (
            <div className={cc(['pl-2 text-gray-700', $labelClassName])}>
              {label}
            </div>
          )}
        </label>
      </>
    )
  },
)

export default FormCheckbox
