import FormCheckbox from 'forms/FormCheckbox'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import TermsAndConditions from 'components/TermsAndConditions'
import { useGlobalModal } from 'modals/GlobalModal'
import Button from 'components/Button'
import { useAcceptTermsAndConditionsMutation } from 'generated/graphql'
import { useAuth } from 'clients/auth.client'
import { useToast } from 'components/ToastMessage'
import { useEffect } from 'react'

//TODO we can change this component to accepted other components
function PopUpTermsAndConditions() {
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      accepts_terms: '',
    },
    resolver: yupResolver(
      yup.object({
        accepts_terms: yup
          .string()
          .required('To continue please accept our terms and conditions'),
      }),
    ),
  })
  const { errors } = formState
  const modal = useGlobalModal()
  const [termsState, UpdateTermsAndConditions] =
    useAcceptTermsAndConditionsMutation()
  const { account, setPartialAccount } = useAuth()
  const toast = useToast()

  useEffect(() => {
    if (!account || account?.hasAcceptedTerms) {
      toast.dismiss()
    }
  }, [account])

  return (
    <>
      <div>
        <form
          className="my-5 space-y-2 text-blackf text-center flex flex-col items-center mx-auto "
          onSubmit={handleSubmit(async (values) => {
            if (values.accepts_terms) {
              const response = await UpdateTermsAndConditions({
                id: account?.id,
              })
              if (response.data?.update_WUI_User) {
                if (response.data.update_WUI_User?.affected_rows) {
                  setPartialAccount({
                    hasAcceptedTerms: true,
                  })
                  toast.dismiss()
                }
              }
            }
          })}
        >
          <div className="text-xl text-black font-bold ">
            Our Terms {`&`} Conditions have changed
          </div>
          <div className="flex gap-2 items-center">
            <FormCheckbox
              {...register('accepts_terms')}
              value="accepted_terms"
              id={`terms-and-conditions-modal`}
            />
            <div
              className="text-primary font-bold cursor-pointer"
              onClick={() => {
                modal.open(() => <TermsAndConditions />, { $bodySize: 'full' })
              }}
            >
              Please read and accepted to continue
            </div>
          </div>
          {errors.accepts_terms && (
            <div className="text-center text-action-fail text-sm mt-4">
              {errors.accepts_terms?.message}
            </div>
          )}
          <div className="pt-2">
            <Button $fluid loading={termsState.fetching}>
              Accept
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}

export default PopUpTermsAndConditions
