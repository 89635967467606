import Container from 'components/Container'
import Title from 'components/Title'
import { PUBLIC_PROFILE_URL } from 'globalConstants'
import ProfilePicture from 'components/ProfilePicture'
import QRCode from 'react-qr-code'
import { Profile } from 'clients/auth.client'
import ShareButtons from 'components/ShareButtons'
import ShareInputField from 'components/ShareInputField'
import { useGlobalStore } from 'stores/global.store'
import Logo from 'components/Logo'

type Props = {
  profile: Profile
}

const ShareModal = ({ profile }: Props) => {
  const SHARE_URL = `https://${PUBLIC_PROFILE_URL}/${profile.username}`
  const { platformFriendly } = useGlobalStore()

  return (
    <Container topAndBottom>
      <div className="flex items-center flex-col gap-4">
        <ProfilePicture userID={profile.id} image={profile.picture} size="lg" />

        {profile.publicName && <Title title={profile.publicName} />}

        <div className="text-lg text-center">
          Share your profile with your <br />
          customers and followers
        </div>

        <div className="w-full max-w-sm">
          {profile.username && <ShareInputField username={profile.username} />}
        </div>
      </div>

      <ShareButtons
        title={`Support me on ${platformFriendly}!`}
        url={SHARE_URL}
      />
      <QRCode value={SHARE_URL} className="mx-auto my-6" size={120} />

      <div className="text-center mt-10">
        <Logo />
      </div>
    </Container>
  )
}

export default ShareModal
