import type { NextUrqlClientConfig, WithUrqlClientOptions } from 'next-urql'
import {
  dedupExchange,
  fetchExchange,
  errorExchange,
  subscriptionExchange,
  Exchange,
  makeOperation,
  createClient,
  Provider,
} from 'urql'
import { cacheExchange } from '@urql/exchange-graphcache'
import { withUrqlClient } from 'next-urql'
import {
  AddCreditCardMutation,
  CreateBankAccountMutation,
  CreditCardsDocument,
  DeleteBankAccountMutation,
  DeleteCreditCardMutation,
  GetSocialMediaLinksDocument,
  GetBankAccountsDocument,
  MakeBankAccountPrimaryMutation,
  DeleteLinkMutation,
  UpdateSocialMediaLinksMutation,
  InsertLinksMutation,
  GetSocialMediaDemographicsDocument,
  UpdateSocialMediaDataMutation,
  UpdatePackageMutation,
  ManagePackagesDocument,
  UpdatePackageItemsMutation,
  CreatePackageMutation,
  GetSentPackagesRequestDocument,
  UpdatePackageRequestStatusMutation,
  Wui_RequestStatus_Enum,
  GetPackagesRequestDocument,
  UpdateProfileSettingsMutation,
  ProfileSettingsDocument,
  GetPackagesRequestByPackageIdDocument,
  GetPackagesRequestByPackageIdQuery,
  CreateRequestPackagePriceMutation,
  Mutation_RootUpdate_Wui_PackageRequest_By_PkArgs,
  GetMarketplaceWaitlistDocument,
  InsertMarketplaceWaitlistMutation,
  ProfileSettingsQuery,
} from 'generated/graphql'
import { env } from 'env/client.mjs'
import { authExchange } from '@urql/exchange-auth'
import cookie from 'cookie'
import { NextPage } from 'next'
import { getToken, getUserID, useAuth } from './auth.client'
import Loading from 'components/Loading'
import { createClient as createWSClient } from 'graphql-ws'
import logger, { Auth } from 'utils/logger'
import { useCallback } from 'react'
import { useSession } from 'next-auth/react'
import getFullName from 'utils/getFullName'
import { print } from 'graphql'

const errorHandler = (authContext: Auth | undefined) =>
  errorExchange({
    onError: async (error, op) => {
      const IGNORE_MSGS = [
        '[Network]', // This happens when there's a network request failure
        'User has already visit this url',
      ]

      if (error.message && IGNORE_MSGS.includes(error.message)) {
        // eslint-disable-next-line no-console
        console.log('Soft Error', error)
      } else {
        logger.error(`GRAPHQL ERROR "${error.message}"`, {
          ...authContext,
          origin: 'urql',
          error,
          errorStr: error.toString(),
          cause: error.cause,
          graphQLErrors: error.graphQLErrors.map((e) => e.message).join('\n'),
          message: error.message,
          name: error.name,
          networkError: error.networkError,
          response: error.response,
          query: print(op.query),
          variables: JSON.stringify(op.variables, null, 2),
        })
      }
    },
  })

const cache = () => {
  return cacheExchange({
    // "Entities without keys will be embedded directly on the parent entity. If this is intentional, create a `keys` config for `WUI_AffiliateTransaction_aggregate` that always returns null."
    // This is for queries without "id" field
    keys: {
      WUI_Order_aggregate: () => null,
      WUI_Order_aggregate_fields: () => null,
      WUI_Order_sum_fields: () => null,
      WUI_SessionEvent_aggregate: () => null,
      WUI_SessionEvent_aggregate_fields: () => null,
      WUI_UserReview_aggregate_fields: () => null,
      WUI_UserReview_aggregate: () => null,
      WUI_UserReview_avg_fields: () => null,
      WUI_Payment: () => null,
      WUI_AffiliateTransaction_aggregate: () => null,
      WUI_AffiliateTransaction_aggregate_fields: () => null,
      WUI_AffiliateTransaction_sum_fields: () => null,
    },
    updates: {
      Mutation: {
        create_WUI_PaymentMethodCard(
          result: AddCreditCardMutation,
          _args,
          cache,
        ) {
          cache.updateQuery(
            {
              query: CreditCardsDocument,
            },
            (data) => {
              if (
                data &&
                result.create_WUI_PaymentMethodCard &&
                Array.isArray(data.WUI_PaymentMethodCard)
              ) {
                return {
                  ...data,
                  WUI_PaymentMethodCard: [
                    ...data.WUI_PaymentMethodCard,
                    {
                      brand: result.create_WUI_PaymentMethodCard.brand,
                      id: result.create_WUI_PaymentMethodCard.id,
                      exp_year: result.create_WUI_PaymentMethodCard.exp_year,
                      exp_month: result.create_WUI_PaymentMethodCard.exp_month,
                      last4: result.create_WUI_PaymentMethodCard.last4,
                      country_code:
                        result.create_WUI_PaymentMethodCard.country_code,
                    },
                  ],
                }
              } else {
                return data
              }
            },
          )
        },
        remove_WUI_PaymentMethodCard(
          result: DeleteCreditCardMutation,
          _args,
          cache,
        ) {
          cache.updateQuery(
            {
              query: CreditCardsDocument,
            },
            (data) => {
              if (
                data &&
                result.remove_WUI_PaymentMethodCard &&
                Array.isArray(data.WUI_PaymentMethodCard)
              ) {
                return {
                  ...data,
                  WUI_PaymentMethodCard: data.WUI_PaymentMethodCard.filter(
                    ({ id }: any) =>
                      id !== result.remove_WUI_PaymentMethodCard?.id,
                  ),
                }
              } else {
                return data
              }
            },
          )
        },
        removeBankAccount(result: DeleteBankAccountMutation, _args, cache) {
          cache.updateQuery(
            {
              query: GetBankAccountsDocument,
            },
            (data) => {
              if (
                data &&
                Array.isArray(data.getBankAccounts) &&
                result.removeBankAccount &&
                result.removeBankAccount?.id
              ) {
                return {
                  ...data,
                  getBankAccounts: data.getBankAccounts.filter(
                    (bank: any) => bank.id !== result.removeBankAccount?.id,
                  ),
                }
              } else {
                return data
              }
            },
          )
        },
        createBankAccount(result: CreateBankAccountMutation, _args, cache) {
          cache.updateQuery(
            {
              query: GetBankAccountsDocument,
            },
            (data) => {
              if (
                data &&
                Array.isArray(data.getBankAccounts) &&
                result.createBankAccount
              ) {
                return {
                  ...data,
                  getBankAccounts: [
                    ...data.getBankAccounts,
                    result.createBankAccount,
                  ],
                }
              } else {
                return data
              }
            },
          )
        },
        defaultBankAccount(
          result: MakeBankAccountPrimaryMutation,
          _args,
          cache,
        ) {
          cache.updateQuery(
            {
              query: GetBankAccountsDocument,
            },
            (data) => {
              if (
                data &&
                Array.isArray(data.getBankAccounts) &&
                result.defaultBankAccount &&
                result.defaultBankAccount?.id
              ) {
                return {
                  ...data,
                  getBankAccounts: data.getBankAccounts.map((bank: any) => {
                    return {
                      ...bank,
                      metadata: {
                        ...bank.metadata,
                        isDefault:
                          bank?.id && bank.id === result.defaultBankAccount?.id,
                      },
                    }
                  }),
                }
              } else {
                return data
              }
            },
          )
        },
        update_SocialMediaLink(
          _result: UpdateSocialMediaLinksMutation,
          args: any,
          cache,
        ) {
          cache.updateQuery(
            {
              query: GetSocialMediaLinksDocument,
              variables: { userID: getUserID() },
            },

            (data) => {
              if (
                data &&
                args.where &&
                args._set &&
                args.where.id &&
                Array.isArray(data.SocialMediaLink)
              ) {
                const newData = data.SocialMediaLink.map((link: any) =>
                  link.id === args.where?.id?._eq
                    ? {
                        id: link.id,
                        title: args._set.title,
                        primary: args._set.primary,
                        url: args._set.url,
                        solid: args._set.solid,
                      }
                    : link,
                ).sort((a: any, b: any) => b.primary - a.primary)
                return {
                  ...data,
                  SocialMediaLinks: newData.sort(
                    (a: any, b: any) => b.primary - a.primary,
                  ),
                }
              } else return data
            },
          )
        },
        insert_SocialMediaLink(result: InsertLinksMutation, _args, cache) {
          cache.updateQuery(
            {
              query: GetSocialMediaLinksDocument,
              variables: { userID: getUserID() },
            },
            (data) => {
              if (
                data &&
                result.insert_SocialMediaLink &&
                result.insert_SocialMediaLink.returning &&
                Array.isArray(data.SocialMediaLink) &&
                Array.isArray(result.insert_SocialMediaLink.returning)
              ) {
                return {
                  ...data,
                  SocialMediaLink: [
                    ...data.SocialMediaLink,
                    ...result.insert_SocialMediaLink.returning,
                  ].sort((a: any, b: any) => b.primary - a.primary),
                }
              } else {
                return data
              }
            },
          )
        },
        delete_SocialMediaLink(result: DeleteLinkMutation, _args, cache) {
          cache.updateQuery(
            {
              query: GetSocialMediaLinksDocument,
              variables: { userID: getUserID() },
            },
            (data) => {
              if (
                data &&
                result.delete_SocialMediaLink &&
                result.delete_SocialMediaLink.returning &&
                Array.isArray(data.SocialMediaLink) &&
                Array.isArray(result.delete_SocialMediaLink?.returning)
              ) {
                return {
                  ...data,
                  SocialMediaLink: data.SocialMediaLink.filter(
                    ({ id }: any) =>
                      id !==
                      result.delete_SocialMediaLink?.returning.find(
                        (result) => result.id === id,
                      )?.id,
                  ).sort((a: any, b: any) => b.primary - a.primary),
                }
              } else {
                return data
              }
            },
          )
        },
        insert_SocialMediaDemographic(
          result: UpdateSocialMediaDataMutation,
          _args,
          cache,
        ) {
          cache.updateQuery(
            {
              query: GetSocialMediaDemographicsDocument,
              variables: { userID: getUserID() },
            },
            (data) => {
              if (
                data &&
                Array.isArray(data.SocialMediaDemographic) &&
                result.insert_SocialMediaDemographic?.returning &&
                Array.isArray(result.insert_SocialMediaDemographic?.returning)
              ) {
                return {
                  ...data,
                  SocialMediaDemographic:
                    result.insert_SocialMediaDemographic.returning,
                }
              } else {
                return data
              }
            },
          )
        },
        insert_WUI_SellerPackageItem(
          result: UpdatePackageItemsMutation,
          _args,
          cache,
        ) {
          cache.updateQuery(
            {
              query: ManagePackagesDocument,
              variables: { userID: getUserID() },
            },
            (data) => {
              if (
                data &&
                Array.isArray(result.insert_WUI_SellerPackageItem?.returning) &&
                Array.isArray(data.WUI_SellerPackage)
              ) {
                return {
                  ...data,
                  WUI_SellerPackage: data.WUI_SellerPackage.map(
                    (element: any) => {
                      return element.id ===
                        result.insert_WUI_SellerPackageItem?.returning?.[0]
                          ?.WUI_SellerPackage.id
                        ? {
                            ...element,
                            WUI_SellerPackageItems:
                              result.insert_WUI_SellerPackageItem?.returning.map(
                                ({ id, title, comment, quantity }) => ({
                                  id,
                                  title,
                                  comment,
                                  quantity,
                                }),
                              ),
                          }
                        : element
                    },
                  ),
                }
              } else {
                return data
              }
            },
          )
        },
        insert_WUI_SellerPackage_one(
          result: CreatePackageMutation,
          _args,
          cache,
        ) {
          cache.updateQuery(
            {
              query: ManagePackagesDocument,
              variables: { userID: getUserID() },
            },
            (data) => {
              if (
                data &&
                Array.isArray(data.WUI_SellerPackage) &&
                result.insert_WUI_SellerPackage_one
              ) {
                return {
                  ...data,
                  WUI_SellerPackage: [
                    ...data.WUI_SellerPackage,
                    result.insert_WUI_SellerPackage_one,
                  ],
                }
              } else {
                return data
              }
            },
          )
        },
        update_WUI_SellerPackage_by_pk(
          result: UpdatePackageMutation,
          _args,
          cache,
        ) {
          cache.updateQuery(
            {
              query: ManagePackagesDocument,
              variables: { userID: getUserID() },
            },
            (data) => {
              if (data && Array.isArray(data.WUI_SellerPackage)) {
                return {
                  ...data,
                  WUI_SellerPackage: data.WUI_SellerPackage.map((item: any) =>
                    item.id === result.update_WUI_SellerPackage_by_pk?.id
                      ? result.update_WUI_SellerPackage_by_pk
                      : item,
                  ),
                }
              } else {
                return data
              }
            },
          )
        },
        insert_WUI_PackageRequest_one(
          result: CreateRequestPackagePriceMutation,
          _args,
          cache,
        ) {
          if (result.insert_WUI_PackageRequest_one) {
            const { id, status, tries, package_id } =
              result.insert_WUI_PackageRequest_one

            cache.updateQuery<GetPackagesRequestByPackageIdQuery>(
              {
                query: GetPackagesRequestByPackageIdDocument,
                variables: {
                  package_id,
                },
              },
              (data) => {
                if (data?.WUI_PackageRequest) {
                  return {
                    WUI_PackageRequest: [
                      {
                        ...data.WUI_PackageRequest[0],
                        id,
                        status,
                        tries,
                      },
                    ],
                  }
                } else {
                  return {
                    WUI_PackageRequest: [
                      {
                        __typename: 'WUI_PackageRequest',
                        id,
                        status,
                        tries,
                      },
                    ],
                  }
                }
              },
            )
          }
        },
        update_WUI_PackageRequest_by_pk(
          result: UpdatePackageRequestStatusMutation,
          _args: Mutation_RootUpdate_Wui_PackageRequest_By_PkArgs,
          cache,
        ) {
          if (result.update_WUI_PackageRequest_by_pk) {
            const { id, status, tries } = result.update_WUI_PackageRequest_by_pk

            cache.updateQuery<GetPackagesRequestByPackageIdQuery>(
              {
                query: GetPackagesRequestByPackageIdDocument,
                variables: {
                  package_id: id,
                },
              },
              (data) => {
                if (data?.WUI_PackageRequest) {
                  return {
                    ...data,
                    WUI_PackageRequest: {
                      ...data.WUI_PackageRequest,
                      status,
                      tries,
                    },
                  }
                }

                return data
              },
            )

            cache.updateQuery(
              {
                query:
                  // Only the user that sent the request can "cancel" it, so we assume that we are dealing with the "sent" query
                  status === Wui_RequestStatus_Enum.Cancelled
                    ? GetSentPackagesRequestDocument
                    : GetPackagesRequestDocument,
                variables: { userID: getUserID() },
              },
              (data) => {
                if (data && Array.isArray(data.WUI_PackageRequest)) {
                  return {
                    ...data,
                    WUI_PackageRequest: data.WUI_PackageRequest.map(
                      (request: any) =>
                        request.id === id
                          ? {
                              ...request,
                              status,
                            }
                          : request,
                    ),
                  }
                }

                return data
              },
            )
          }
        },
        update_WUI_User_by_pk(
          result: UpdateProfileSettingsMutation,
          _args,
          cache,
        ) {
          if (result.update_WUI_User_by_pk) {
            cache.updateQuery<ProfileSettingsQuery>(
              {
                query: ProfileSettingsDocument,
                variables: {
                  id: getUserID(),
                },
              },
              (data) => {
                if (data && data.WUI_User_by_pk) {
                  return {
                    ...data,
                    WUI_User_by_pk: {
                      ...data.WUI_User_by_pk,
                      contact_email:
                        result.update_WUI_User_by_pk?.contact_email ||
                        data.WUI_User_by_pk.contact_email,
                      first_name:
                        result.update_WUI_User_by_pk?.first_name ||
                        data.WUI_User_by_pk.first_name,
                      last_name:
                        result.update_WUI_User_by_pk?.last_name ||
                        data.WUI_User_by_pk.last_name,
                      entity_name:
                        result.update_WUI_User_by_pk?.entity_name ||
                        data.WUI_User_by_pk.entity_name,
                      phone_number:
                        result.update_WUI_User_by_pk?.phone_number ||
                        data.WUI_User_by_pk.phone_number,
                      username:
                        result.update_WUI_User_by_pk?.username ||
                        data.WUI_User_by_pk.username,
                      about_me:
                        result.update_WUI_User_by_pk?.about_me ||
                        data.WUI_User_by_pk.about_me,
                      is_seller:
                        result.update_WUI_User_by_pk?.is_seller ||
                        data.WUI_User_by_pk.is_seller ||
                        false,
                      theme_color:
                        result.update_WUI_User_by_pk?.theme_color ||
                        data.WUI_User_by_pk.theme_color,
                      // TODO: Should we update `UserVideos`?
                      // video_url:
                      //   result.update_WUI_User_by_pk?.video_url ||
                      //   data.WUI_User_by_pk/,
                      allow_contact_without_order:
                        result.update_WUI_User_by_pk
                          ?.allow_contact_without_order ||
                        data.WUI_User_by_pk.allow_contact_without_order,
                      custom_color:
                        result.update_WUI_User_by_pk?.custom_color ||
                        data.WUI_User_by_pk.custom_color,
                    },
                  }
                }

                return data
              },
            )
          }
        },
        insert_MarketplaceWaitlist_one(
          result: InsertMarketplaceWaitlistMutation,
          _args,
          cache,
        ) {
          cache.updateQuery(
            {
              query: GetMarketplaceWaitlistDocument,
            },
            (data) => {
              if (
                data?.MarketplaceWaitlist &&
                result.insert_MarketplaceWaitlist_one
              ) {
                return {
                  ...data,
                  MarketplaceWaitlist: [
                    {
                      id: result.insert_MarketplaceWaitlist_one.id,
                    },
                  ],
                }
              }

              return data
            },
          )
        },
      },
    },
  })
}

const subscription = () => {
  if (typeof window !== 'undefined') {
    const wsClient = createWSClient({
      url: env.NEXT_PUBLIC_GRAPHQL_WS_URL,
      connectionParams: async () => {
        const token = getToken()

        return token
          ? {
              headers: {
                authorization: `Bearer ${token}`,
              },
            }
          : {}
      },
      shouldRetry: (errOrCloseEvent) => {
        logger.warn(`WS Subscription error`, {
          errOrCloseEvent,
        })

        return true
      },
    })

    return subscriptionExchange({
      forwardSubscription: (operation) => ({
        subscribe: (sink) => ({
          unsubscribe: wsClient.subscribe(operation, sink),
        }),
      }),
    })
  }

  return null
}

export const getExchanges: (
  ssrExchange?: Exchange,
  ctx?: any,
  authContext?: Auth,
  authToken?: string,
) => Exchange[] = (ssrExchange, ctx, authContext, authToken) =>
  [
    dedupExchange,
    errorHandler(authContext),
    cache(),
    ssrExchange,
    authExchange({
      getAuth: async ({ authState }) => {
        if (authState) return AuthenticatorAttestationResponse

        if (typeof window !== 'undefined') {
          const token = authToken || getToken()

          if (token) {
            return { token }
          }
          return null
        }

        if (typeof window === 'undefined' && ctx) {
          const cookies = ctx?.req?.headers?.cookie

          if (cookies) {
            return {
              token: cookie.parse(cookies)['next-auth.session-token'] || null,
            }
          }

          return null
        }

        return null
      },
      addAuthToOperation: ({ authState, operation }: any) => {
        if (!authState || !authState.token) {
          return operation
        }

        const fetchOptions =
          typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {}

        return makeOperation(operation.kind, operation, {
          ...operation.context,
          fetchOptions: {
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              Authorization: `Bearer ${authState.token}`,
            },
          },
        })
      },
    }),
    fetchExchange,
    subscription(),
  ].filter(Boolean) as Exchange[]

export const urqlClientHocHandler: NextUrqlClientConfig = (
  ssrExchange,
  ctx,
) => {
  return {
    url: env.NEXT_PUBLIC_GRAPHQL_URL,
    exchanges: getExchanges(ssrExchange, ctx),
    maskTypename: true,
  }
}

export const withUrql = (
  Page: NextPage<any, any>,
  opts: WithUrqlClientOptions = { ssr: false },
) => {
  const WithUrql = withUrqlClient(urqlClientHocHandler, opts)(Page)
  const EnforceToken: NextPage<any, any> = (props) => {
    const { authenticating } = useAuth()

    if (authenticating) {
      return (
        <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-25">
          <Loading $size="lg" $type="logo" />
        </div>
      )
    }

    return <WithUrql {...props} />
  }

  if (opts.ssr) {
    return WithUrql
  } else {
    return EnforceToken
  }
}

export const GraphqlProvider = ({ children }: any) => {
  const session = useSession()
  const client = useCallback(() => {
    return createClient({
      url: env.NEXT_PUBLIC_GRAPHQL_URL,
      exchanges: getExchanges(
        undefined,
        undefined,
        session.data?.user
          ? {
              userId: session.data.user.id,
              isAuthenticated: session.status === 'authenticated',
              email: session.data.user.email,
              name: getFullName(
                session.data.user.firstName,
                session.data.user.lastName,
              ),
              country: session.data.user.country,
              isSeller: session.data.user.isSeller,
              type: session.data.user.type,
              username: session.data.user.username,
              role: session.data.user.role,
              platformBrand: session.data.user.platformBrand,
              isImpersonator: !!session.data.user.impersonator,
              hasSubscription: !!session.data.user.subscription,
            }
          : undefined,
        session.data?.token,
      ),
    })
  }, [session.data?.user?.id])

  return <Provider value={client()}>{children}</Provider>
}
