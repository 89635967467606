import cc from 'classcat'
import { ReactElement } from 'react'

type Props = {
  title: string
  subtitle?: string | ReactElement
  $size: 'sm' | 'md' | 'lg'
}

const Title = ({ title, subtitle, $size }: Props) => {
  return (
    <>
      <h1
        className={cc([
          'font-bold',
          {
            'text-lg': $size === 'sm',
            'text-xl': $size === 'md',
            'text-xxl': $size === 'lg',
          },
        ])}
      >
        {title}
      </h1>
      {subtitle && (
        <h2
          className={cc([
            'text-gray-600',
            {
              'text-xs': $size === 'sm',
            },
          ])}
        >
          {subtitle}
        </h2>
      )}
    </>
  )
}

Title.defaultProps = {
  $size: 'md',
}

export default Title
